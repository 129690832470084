import React from "react";
import {Checkbox, InputGroup, InputGroupLabel, Select} from "#lib/components/index.ts";
import {useSheetReference} from "../../../../common/sheet/sheet-reference-context.ts";
import {useSheetEffects} from "./dnd-5e-action/use-sheet-effects.ts";
import {useGlobalFeatures} from "./dnd-5e-action/use-global-features.ts";
import {Dnd5eEffectFn, Dnd5eEffectID} from "common/legends/asset/sheet/dnd-5e/dnd-5e-effect/index.ts";
import {useSheetSignal} from "../../../../common/sheet/use-sheet-signal.ts";
import {QuickAccessConditionsMenu} from "../../../nav/common/quick-access-menu/dnd-5e/quick-access-conditions-menu.tsx";
import {useSetEffectGroup} from "../../../nav/common/quick-access-menu/dnd-5e/use-set-effect-group.ts";
import {useRefValue} from "#lib/signal/index.ts";

export function Dnd5eSheetEffectsView({}: {
}) {
  const sheetReferenceRef = useSheetReference();
  const sheetRef = useSheetSignal(sheetReferenceRef);
  const effects = useRefValue(useSheetEffects(sheetRef));

  const groups = new Set(effects.map(Dnd5eEffectFn.getGroup));
  const selectedGroupValue = Object.fromEntries([...groups.values()].map(group => {
    const enabledEffects = effects.filter(effect => Dnd5eEffectFn.getGroup(effect) === group).filter(effect => effect.enabled);
    return enabledEffects.length > 0 ? [group!, enabledEffects[0]] : [group!, undefined];
  }));
  const groupValues = Object.fromEntries([...groups.values()].map(group => {
    const enabledEffects = effects.filter(effect => Dnd5eEffectFn.getGroup(effect) === group);
    return [group!, enabledEffects];
  }));

  const globalFeaturesRef = useGlobalFeatures();
  const setEffectGroup = useSetEffectGroup(sheetRef, globalFeaturesRef);

  return (<div className="flex flex-col gap-1">
    <QuickAccessConditionsMenu sheetReferenceRef={sheetReferenceRef} />
    {[...groups.values()].map(group => <InputGroup key={group}>
      {groupValues[group].length === 1 &&
          <Checkbox checked={selectedGroupValue[group] !== undefined} onChange={() => setEffectGroup(group, selectedGroupValue[group] === undefined ? groupValues[group][0].effectID : undefined)} />
      }
      <InputGroupLabel>{group}</InputGroupLabel>
      {groupValues[group].length > 1 && <Select className="-mr-4" value={selectedGroupValue[group]?.effectID || ""} aria-placeholder="N/A" onChange={ev => {
        setEffectGroup(group, ev.target.value === "" ? undefined : ev.target.value as Dnd5eEffectID);
      }}>
          <option value=""></option>
        {[...groupValues[group]].map(effect => <option key={effect.effectID} value={effect.effectID}>{effect.name.substring(group.length + 1).trim()}</option>)}
      </Select>}
    </InputGroup>)}
  </div>)
}



import {MutableRef} from "common/ref";
import {
  Dnd5eActionTemplateModifier,
  Dnd5eActionTemplateModifierOperation,
  Dnd5eActionTemplateModifierTypes
} from "common/legends/asset/sheet/dnd-5e/dnd-5e-action-definition/modifier/dnd-5e-action-template-modifier.ts";
import {ListOperation} from "common/types/generic/index.ts";
import {Button, ButtonBar} from "#lib/components/index.ts";
import {FaPlus} from "react-icons/fa";
import {InputList, InputListItemProps, useDragListItem} from "#lib/components/list/input-list.tsx";
import React, {useState} from "react";
import {generateDnd5eModifierID} from "common/legends/asset/sheet/dnd-5e/dnd-5e-modifier/dnd-5e-modifier-i-d.ts";
import {useTypedRef} from "../../../../../../common/use-typed-ref.ts";
import {Dnd5eActionTemplateConditionModifierListItem} from "./dnd-5e-action-template-condition-modifier-list-item.tsx";
import {usePopper} from "react-popper";
import {Menu} from "@headlessui/react";
import {ExternalPortal} from "#lib/container/react/external-window/external-portal.tsx";
import {DiceExpression} from "common/dice/dice-expression.ts";
import {Dnd5eActionTemplateSegmentAttackRollHitModifierListItem} from "../action-template-segment/attack-roll/dnd-5e-action-template-segment-attack-roll-hit-modifier-view.tsx";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowProgress, faCirclesOverlap, faDice, faLambda, faShield, faSword} from "@awesome.me/kit-c9bc6845cd/icons/classic/solid";
import {Dnd5eActionTemplateSegmentRollExpressionModifierListItem} from "../action-template-segment/roll/dnd-5e-action-template-segment-roll-expression-modifier-view.tsx";
import {
  Dnd5eActionTemplateSegmentSavingThrowDifficultyClassModifierListItem
} from "../action-template-segment/saving-throw/dnd-5e-action-template-segment-saving-throw-difficulty-class-modifier-view.tsx";
import {MathExpression} from "common/math/math-expression.ts";
import {Dnd5eVariableModifierListItem} from "../action-template-segment/dnd-5e-variable-modifier-view.tsx";
import {Dnd5eActionTriggerActivationModifierListItem} from "../action-template-segment/dnd-5e-action-trigger-activation-modifier-list-item.tsx";
import {SectionHeader} from "#lib/components/panel-header.tsx";

export function Dnd5eActionTemplateEffectModifierView({
  item,
  remove,
  duplicate
}: InputListItemProps<Dnd5eActionTemplateModifier, Dnd5eActionTemplateModifierOperation>) {
  const [type, typedRef] = useTypedRef<Dnd5eActionTemplateModifierTypes>(item);
  const [dragHandlerRef, dragRefPreview] = useDragListItem("legends/action-template-effect-modifier", item, remove);
  if (type === "variable") return <Dnd5eVariableModifierListItem item={typedRef} remove={remove} duplicate={duplicate} dragHandlerRef={dragHandlerRef} dragRefPreview={dragRefPreview} />;
  if (type === "action::condition") return <Dnd5eActionTemplateConditionModifierListItem item={typedRef} remove={remove} duplicate={duplicate} dragHandlerRef={dragHandlerRef} dragRefPreview={dragRefPreview} />
  if (type === "action::attack-roll::hit-modifier") return <Dnd5eActionTemplateSegmentAttackRollHitModifierListItem item={typedRef} remove={remove} duplicate={duplicate} dragHandlerRef={dragHandlerRef} dragRefPreview={dragRefPreview} />;
  if (type === "action::roll::expression") return <Dnd5eActionTemplateSegmentRollExpressionModifierListItem item={typedRef} remove={remove} duplicate={duplicate} dragHandlerRef={dragHandlerRef} dragRefPreview={dragRefPreview} />;
  if (type === "action::trigger::activation") return <Dnd5eActionTriggerActivationModifierListItem item={typedRef} remove={remove} duplicate={duplicate} dragHandlerRef={dragHandlerRef} dragRefPreview={dragRefPreview} />;
  if (type === "action::saving-throw::difficulty-class") return <Dnd5eActionTemplateSegmentSavingThrowDifficultyClassModifierListItem item={typedRef} remove={remove} duplicate={duplicate} dragHandlerRef={dragHandlerRef} dragRefPreview={dragRefPreview} />;
  return <div></div>
}

export function Dnd5eActionTemplateModifiersView({valueRef}: {
  valueRef: MutableRef<Dnd5eActionTemplateModifier[], ListOperation<Dnd5eActionTemplateModifier, Dnd5eActionTemplateModifierOperation>[]>
}) {
  const [referenceElement, setReferenceElement] = useState<HTMLElement | null>(null);
  const [popperElement, setPopperElement] = useState<HTMLElement | null>(null);
  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    placement: "bottom-end"
  });

  const addActionEffectModifier = (modifier: Dnd5eActionTemplateModifier) => {
    valueRef.apply(prev => ListOperation.insert(prev.length, modifier));
  };
  const addConditionModifier = () => {
    addActionEffectModifier({
      type: "action::condition",
      data: {
        modifierID: generateDnd5eModifierID(),
        condition: undefined,
        modifiers: []
      }
    })
  };
  const addAttackRollHitModifierModifier = () => {
    addActionEffectModifier({
      type: "action::attack-roll::hit-modifier",
      data: {
        modifierID: generateDnd5eModifierID(),
        condition: undefined,
        expression: DiceExpression.parse("0")
      }
    })
  };
  const addDamageModifier = () => {
    addActionEffectModifier({
      type: "action::roll::expression",
      data: {
        modifierID: generateDnd5eModifierID(),
        condition: undefined,
        rollType: undefined,
        expression: DiceExpression.parse("0")
      }
    })
  };
  const addDifficultyClass = () => {
    addActionEffectModifier({
      type: "action::saving-throw::difficulty-class",
      data: {
        modifierID: generateDnd5eModifierID(),
        condition: undefined,
        expression: DiceExpression.parse("0")
      }
    })
  };
  const addVariable = () => {
    addActionEffectModifier({
      type: "variable",
      data: {
        modifierID: generateDnd5eModifierID(),
        name: "",
        expression: MathExpression.parse("0")
      }
    })
  };
  const addTriggerActivation = () => {
    addActionEffectModifier({
      type: "action::trigger::activation",
      data: {
        modifierID: generateDnd5eModifierID(),
        processes: []
      }
    })
  };

  return (<div className="flex flex-col gap-1">
    <div className="flex flex-row gap-0.5">
      <SectionHeader className="bg-zinc-800/50 flex-1">Modifiers</SectionHeader>
      <ButtonBar>
        <Menu ref={ref => setReferenceElement(ref)} as="div">
          <Menu.Button as={Button}>
            <FaPlus /> Add Modifier
          </Menu.Button>
          <Menu.Items as={ExternalPortal} ref={ref => setPopperElement(ref)} style={styles.popper} {...attributes.popper} className="text-white flex flex-col items-stretch rounded-md m-0.5 overflow-hidden">
            <Menu.Item as={Button} className="justify-start" onClick={addConditionModifier}>
              <FontAwesomeIcon icon={faCirclesOverlap} /> <span>Condition</span>
            </Menu.Item>
            <Menu.Item as={Button} className="justify-start" onClick={addAttackRollHitModifierModifier}>
              <FontAwesomeIcon icon={faSword} /> <span>Hit</span>
            </Menu.Item>
            <Menu.Item as={Button} className="justify-start" onClick={addDamageModifier}>
              <FontAwesomeIcon icon={faDice} /> <span>Roll</span>
            </Menu.Item>
            <Menu.Item as={Button} className="justify-start" onClick={addDifficultyClass}>
              <FontAwesomeIcon icon={faShield} /> <span>Difficulty Class</span>
            </Menu.Item>
            <Menu.Item as={Button} className="justify-start" onClick={addVariable}>
              <FontAwesomeIcon icon={faLambda} /> <span>Variable</span>
            </Menu.Item>
            <Menu.Item as={Button} className="justify-start" onClick={addTriggerActivation}>
              <FontAwesomeIcon icon={faArrowProgress} /> <span>Activation Trigger</span>
            </Menu.Item>
          </Menu.Items>
        </Menu>
      </ButtonBar>
    </div>

    <InputList<Dnd5eActionTemplateModifier, Dnd5eActionTemplateModifierOperation>
      accept="legends/action-template-effect-modifier"
      items={valueRef}
      itemKey={item => item.data.modifierID}
      copy={item => item}
      ListItem={Dnd5eActionTemplateEffectModifierView} />
  </div>);
}
import React, {useMemo} from "react";
import {useArrayBuffer, useAttribLocation, useBindVertexArribArray, useProgram, useShader, useUniformLocation, useVertexBuffer} from "#lib/gl-react/index.ts";
import {Color, HSLA} from "common/types/index.ts";

const vertexShader = `#version 300 es
precision highp float;
in vec2 vPosition;
void main() {
  gl_Position = vec4(vPosition, 0.0, 1.0);
}
`;
const fragmentShader = `#version 300 es
precision mediump float;
uniform vec4 u_color;

layout(location = 0) out vec4 color;
layout(location = 1) out vec4 normal;

void main() {
  color = u_color;
  normal = vec4(0.5, 0.5, 1.0, 1.0);
}
`;

export function BackgroundShader(props: {
  color: HSLA;
}) {
  const program = useProgram(
    useShader(WebGL2RenderingContext.VERTEX_SHADER, vertexShader),
    useShader(WebGL2RenderingContext.FRAGMENT_SHADER, fragmentShader)
  );

  const vbo = useArrayBuffer(useMemo(() => new Float32Array([
    -1, -1, 1, -1, 1, 1,
    1, 1, -1, 1, -1, -1
  ]), []));

  const vao = useVertexBuffer();
  useBindVertexArribArray(vao, useAttribLocation(program, "vPosition"), vbo, 2, WebGL2RenderingContext.FLOAT, false, 2 * 4, 0);
  const colorLocation = useUniformLocation(program, "u_color");
  const color = useMemo(() => new Float32Array(Color.toRGBA(props.color)), [props.color]);

  return (
    <program value={program}>
      <uniform4fv location={colorLocation} data={color}/>
      <vertexArray value={vao}>
        <drawArrays mode={WebGL2RenderingContext.TRIANGLES} first={0} count={6} />
      </vertexArray>
    </program>
  );
}

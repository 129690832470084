import {
  Button,
  ButtonBar,
  IconButton,
  InputGroup,
  InputGroupLabel,
  InputNumber,
  useTempRef
} from "#lib/components/index.ts";
import {FaDiceD20} from "react-icons/fa6";
import React, {useState} from "react";
import ReactDOM from "react-dom";
import {usePopper} from "react-popper";
import {Popper} from "#lib/components/popper/popper.tsx";
import {useSendTextMessage} from "../../../../../../routes/game/use-send-text-message.ts";
import {numberType} from "common/types/index.ts";
import {Popover} from "@headlessui/react";
import {useRefValue} from "#lib/signal/index.ts";
import {usePortal} from "#lib/container/react/external-window/external-portal.tsx";

function getExpression(diceCount:number, diceSides: number, diceModifier: number) {
  if (diceCount <= 0) return `${diceModifier}`;
  if (diceModifier !== 0) {
    return `${diceCount}d${diceSides} + ${diceModifier}`.replaceAll("+-", "-");
  }
  return `${diceCount}d${diceSides}`;
}

type QuickAccessDiceButtonProps = {
  diceSides: number;
};

function QuickAccessDiceButton({diceSides}: QuickAccessDiceButtonProps) {
  const sendTextMessage = useSendTextMessage();
  const diceCount = useTempRef(numberType, 1);
  const diceModifier = useTempRef(numberType, 0);
  return (<ButtonBar>
    <InputGroup className="basis-24 px-0 gap-2">
      <InputNumber className="flex-1 text-right" onFocus={ev => ev.target.select()} value={diceCount} />
      <InputGroupLabel className="basis-12 text-left px-0">d{diceSides}</InputGroupLabel>
    </InputGroup>
    <InputGroup className="basis-12">
      <InputGroupLabel>+</InputGroupLabel>
      <InputNumber className="basis-5 w-5" onFocus={ev => ev.target.select()} value={diceModifier} />
    </InputGroup>
    <Button className="pl-4 flex-1 justify-start whitespace-nowrap overflow-hidden" onClick={() => {
      sendTextMessage(`[[${getExpression(diceCount.value, diceSides, diceModifier.value)}]]`)
    }}>
      <div className="min-w-8">
        <FaDiceD20 />
      </div>
      <span className="text-white/20">[[</span>
      {getExpression(useRefValue(diceCount), diceSides, useRefValue(diceModifier))}
      <span className="text-white/20">]]</span>
    </Button>
  </ButtonBar>);
}

export function QuickAccessDiceMenu() {
  const [referenceElement, setReferenceElement] = useState<HTMLElement | null>(null);
  const [popperElement, setPopperElement] = useState<HTMLElement | null>(null);
  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    placement: "bottom-start",
  });

  return (<Popover className="relative">
    <Popover.Button as={IconButton} ref={ref => setReferenceElement(ref)} title="Roll Dice" className="flex items-center justify-center">
      <FaDiceD20 />
    </Popover.Button>
    <Popover.Panel>
      {ReactDOM.createPortal(<Popper ref={ref => setPopperElement(ref)} style={styles.popper} {...attributes.popper} className="mt-4 w-96">
        <QuickAccessDiceButton diceSides={4} />
        <QuickAccessDiceButton diceSides={6} />
        <QuickAccessDiceButton diceSides={8} />
        <QuickAccessDiceButton diceSides={10} />
        <QuickAccessDiceButton diceSides={12} />
        <QuickAccessDiceButton diceSides={20} />
        <QuickAccessDiceButton diceSides={100} />
      </Popper>, usePortal())}
    </Popover.Panel>
  </Popover>);
}
import {Arc, Transform} from "common/types/generic/index.ts";
import {useMemo} from "react";
import {CircleMeasurement} from "common/legends/measurement/index.ts";
import {Grid} from "common/legends/scene/index.ts";
import {TextShader} from "#lib/gl-react/component/text-shader.tsx";
import {useGrid} from "../../context/grid-context.ts";
import {ArcShader} from "../../shader/shape/arc-shader.tsx";
import {ModelProvider, usePVM} from "../../context/pvm-context.ts";

export function CircleMeasurementView({measurement: {origin, radius, color}}: {
  measurement: CircleMeasurement
}) {
  const {projection, view} = usePVM();
  const grid = useGrid();

  const model: Transform = useMemo(() => ({
    scale: 1,
    position: origin,
    rotation: 0
  }), [origin]);

  const radiusTextModel: Transform = useMemo(() => ({
    scale: 1 / view.scale,
    position: [origin[0], origin[1] + radius],
    rotation: 0
  }), [origin, radius]);

  const circle = useMemo((): Arc => ({
    radius: radius,
    startAngle: 0,
    endAngle: 360
  }), [radius]);

  const distance = Grid.distance(grid, origin, [origin[0], origin[1] + radius]);

  return <ModelProvider value={model}>
    <ArcShader origin={[0,0]} color={color} arc={circle} />
    <TextShader projection={projection} view={view} model={radiusTextModel} value={`${distance} ${grid.unit[1]}`} size={32} vTextAlign="bottom" />
  </ModelProvider>;
}

import React, {useMemo, useState} from "react";
import {HSLA, Point} from "common/types/generic/index.ts";
import {LightShapeSprite} from "common/legends/node/light/light-shape-sprite.ts";
import {ImageLightShader} from "../../../shader/light/image-light-shader.tsx";
import {useOpacity} from "../../../context/opacity-context.ts";
import {useImageTexture} from "../../../context/use-image-texture.ts";
import {WallTintShader} from "../../../shader/wall-tint-shader.tsx";
import {WallMaskFramebuffer} from "../../../shader/wall-mask-framebuffer.tsx";
import {TextureMaskShader} from "../../../shader/texture-mask-shader.tsx";
import {Framebuffer} from "../../../shader/framebuffer.tsx";
import {LightTextureShader} from "./light-texture-shader.tsx";

export function LightShapeSpriteLightPass({value, origin}: {
  value: LightShapeSprite,
  origin: Point
}) {
  const [loaded, texture] = useImageTexture(value.file);
  const opacity = useOpacity();
  const tint = useMemo(() => [value.color[0], value.color[1], value.color[2], value.color[3] * opacity * value.intensity] as HSLA, [value.color, value.intensity, opacity]);
  const [maskTexture, setMaskTexture] = useState<WebGLTexture | null>(null);
  const [lightTexture, setLightTexture] = useState<WebGLTexture | null>(null);
  if (!loaded) return <></>;
  return (<binder>
    <Framebuffer ref={setLightTexture}>
      <ImageLightShader size={value.size} origin={origin} texture={texture} tint={tint}/>
      <WallTintShader origin={[0, 0]} />
    </Framebuffer>

    <WallMaskFramebuffer ref={setMaskTexture} origin={[0, 0]} />

    {lightTexture && maskTexture && <LightTextureShader>
      <TextureMaskShader texture={lightTexture} mask={maskTexture} />
    </LightTextureShader>}
  </binder>);
}


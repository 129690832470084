import {z} from "zod";
import {
  BooleanOperation,
  booleanType,
  ConstantOperation,
  constantType,
  ListPropertyRef,
  ListType,
  ObjectType,
  PropertyRef,
  RichText,
  RichTextOperation,
  richTextType,
  StringOperation,
  stringType,
  Type,
  ZodListOperation
} from "#common/types/index.ts";
import {Dnd5eResource, Dnd5eResourceOperation, dnd5eResourceType} from "#common/legends/asset/sheet/dnd-5e/dnd-5e-resource/dnd-5e-resource.ts";
import {Dnd5eModifier, Dnd5eModifierOperation, dnd5eModifierType} from "#common/legends/asset/sheet/dnd-5e/dnd-5e-modifier/dnd-5e-modifier.ts";
import {Dnd5eFeatureID, generateDnd5eFeatureID} from "#common/legends/asset/sheet/dnd-5e/dnd-5e-feature/dnd-5e-feature-id.ts";
import {Dnd5eEffect, Dnd5eEffectOperation, dnd5eEffectType} from "#common/legends/asset/sheet/dnd-5e/dnd-5e-effect/index.ts";
import {generateDnd5eModifierID} from "#common/legends/asset/sheet/dnd-5e/dnd-5e-modifier/dnd-5e-modifier-i-d.ts";
import {MutableRef} from "#common/ref";
import {Dnd5eActionTemplate, Dnd5eActionTemplateOperation, dnd5eActionTemplateType} from "../dnd-5e-action-definition/template/dnd-5e-action-template.ts";

export const Dnd5eFeature = z.object({
  featureID: Dnd5eFeatureID,
  enabled: z.boolean(),
  title: z.string(),
  source: z.string(),
  description: RichText,
  actions: z.array(Dnd5eActionTemplate),
  modifiers: z.array(Dnd5eModifier),
  resources: z.array(Dnd5eResource),
  effects: z.array(Dnd5eEffect)
});
export type Dnd5eFeature = z.infer<typeof Dnd5eFeature>;

export const Dnd5eFeatureOperation = z.discriminatedUnion("type", [
  z.object({type: z.literal("update-feature-i-d"), operations: z.array(ConstantOperation)}),
  z.object({type: z.literal("update-enabled"), operations: z.array(BooleanOperation)}),
  z.object({type: z.literal("update-title"), operations: z.array(StringOperation)}),
  z.object({type: z.literal("update-source"), operations: z.array(StringOperation)}),
  z.object({type: z.literal("update-description"), operations: z.array(RichTextOperation)}),
  z.object({type: z.literal("update-actions"), operations: z.array(ZodListOperation(Dnd5eActionTemplate, Dnd5eActionTemplateOperation))}),
  z.object({type: z.literal("update-modifiers"), operations: z.array(ZodListOperation(Dnd5eModifier, Dnd5eModifierOperation))}),
  z.object({type: z.literal("update-resources"), operations: z.array(ZodListOperation(Dnd5eResource, Dnd5eResourceOperation))}),
  z.object({type: z.literal("update-effects"), operations: z.array(ZodListOperation(Dnd5eEffect, Dnd5eEffectOperation))}),
]);
export type Dnd5eFeatureOperation = z.infer<typeof Dnd5eFeatureOperation>;

export const dnd5eFeatureType: Type<Dnd5eFeature, Dnd5eFeatureOperation> = new ObjectType({
  featureID: constantType,
  enabled: booleanType,
  title: stringType,
  source: stringType,
  description: richTextType,
  actions: new ListType(dnd5eActionTemplateType),
  modifiers: new ListType(dnd5eModifierType),
  resources: new ListType(dnd5eResourceType),
  effects: new ListType(dnd5eEffectType)
}, (value: any): Dnd5eFeature => {
  if (value.featureID === undefined) {
    value.featureID = generateDnd5eFeatureID();
  }
  if (value.enabled === undefined) {
    value.enabled = true;
  }
  if (value.effects === undefined) {
    value.effects = [];
  }
  if (value.variables !== undefined) {
    if (value.variables.length > 0) {
      value.modifiers.push(...value.variables.map((variable: any) => ({
        type: "variable",
        data: {
          modifierID: generateDnd5eModifierID(),
          name: variable.name,
          expression: variable.expression
        }
      }) satisfies Dnd5eModifier));
    }
    delete value["variables"];
  }
  return value;
});

export function Dnd5eFeatureSignal(signal: MutableRef<Dnd5eFeature, Dnd5eFeatureOperation[]>) {
  return {
    enabled: PropertyRef<Dnd5eFeature, Dnd5eFeatureOperation, boolean, BooleanOperation>(
      value => value.enabled,
      operations => [{type: "update-enabled", operations}]
    )(signal),
    title: PropertyRef<Dnd5eFeature, Dnd5eFeatureOperation, string, StringOperation>(
      value => value.title,
      operations => [{type: "update-title", operations}]
    )(signal),
    description: PropertyRef<Dnd5eFeature, Dnd5eFeatureOperation, RichText, RichTextOperation>(
      value => value.description,
      operations => [{type: "update-description", operations}]
    )(signal),
    source: PropertyRef<Dnd5eFeature, Dnd5eFeatureOperation, string, StringOperation>(
      value => value.source,
      operations => [{type: "update-source", operations}]
    )(signal),
    actionsRef: ListPropertyRef<Dnd5eFeature, Dnd5eFeatureOperation, Dnd5eActionTemplate, Dnd5eActionTemplateOperation>(
      value => value.actions,
      operations => [{type: "update-actions", operations}]
    )(signal),
    modifiers: ListPropertyRef<Dnd5eFeature, Dnd5eFeatureOperation, Dnd5eModifier, Dnd5eModifierOperation>(
      value => value.modifiers,
      operations => [{type: "update-modifiers", operations}]
    )(signal),
    resources: ListPropertyRef<Dnd5eFeature, Dnd5eFeatureOperation, Dnd5eResource, Dnd5eResourceOperation>(
      value => value.resources,
      operations => [{type: "update-resources", operations}]
    )(signal),
    effects: ListPropertyRef<Dnd5eFeature, Dnd5eFeatureOperation, Dnd5eEffect, Dnd5eEffectOperation>(
      value => value.effects,
      operations => [{type: "update-effects", operations}]
    )(signal)
  };
}

export function getFeatureID(value: Dnd5eFeature): Dnd5eFeatureID {
  return value.featureID;
}

import {PlayerStackOperation, PlayerStackValue} from "./player-stack-type.ts";
import {usePlayerStackContent} from "./use-player-stack-content.ts";
import {Apply, Optional} from "common/types/index.ts";
import {PlayerSheetStack, PlayerSheetStackOperation, PlayerSheetStackView} from "../../sheet/view";
import {PlayerTurnTrackerView} from "../../turn-tracker/view/player-turn-tracker-view.tsx";
import {PlayerTurnTracker, PlayerTurnTrackerOperation} from "../../turn-tracker/view/player-turn-tracker.ts";
import {CharacterPanelView} from "../../characters/character-panel-view.tsx";
import {CharacterPanel, CharacterPanelOperation} from "../../characters/character-panel.tsx";
import {ChatStackView} from "../../chat";

const baseCss = "flex flex-col gap-2 h-full text-white flex-1";

export type StackItemContentProps = {
  value: PlayerStackValue;
  apply: Apply<Optional<PlayerStackValue>, PlayerStackOperation[]>;
};

export function PlayerStackView({value, apply}: StackItemContentProps) {
  const [content, applyToContent] = usePlayerStackContent(value.type, value, apply);
  if (value.type === "chat") return <div className={baseCss}><ChatStackView /></div>
  else if (value.type === "sheet") return <div className={baseCss}><PlayerSheetStackView value={content as PlayerSheetStack} apply={applyToContent as Apply<PlayerSheetStack, PlayerSheetStackOperation[]>} /></div>
  else if (value.type === "turn-tracker") return <div className={baseCss}><PlayerTurnTrackerView value={content as PlayerTurnTracker} apply={applyToContent as Apply<PlayerTurnTracker, PlayerTurnTrackerOperation[]>} /></div>
  else if (value.type === "characters") return <div className={baseCss}><CharacterPanelView value={content as CharacterPanel} apply={applyToContent as Apply<CharacterPanel, CharacterPanelOperation[]>} /></div>
  else return <>Not Found</>
}
import {WebGL2Node} from "./web-g-l-2-node.ts";

export class WebGL2Uniform3FV extends WebGL2Node {
  private location?: WebGLUniformLocation;
  private data?: Float32List;
  private srcOffset?: GLuint;
  private srcLength?: GLuint;

  constructor(private context: WebGL2RenderingContext) {
    super();
  }

  action(): void {
    if (this.location === undefined) throw new Error("location must be provided.");
    if (this.data === undefined) throw new Error("data must be provided.");

    this.context.uniform3fv(this.location, this.data, this.srcOffset, this.srcLength);
  }
}

import {z} from "zod";
import {Dnd5eModifierID} from "../../../dnd-5e-modifier/dnd-5e-modifier-i-d.ts";
import {Dnd5eAttribute} from "../../../character/dnd-5e-attribute.ts";
import {ConstantOperation, constantType, ObjectType, Optional, Type, ValueOperation, ValuePropertyRef, ValueType} from "../../../../../../../types/index.ts";
import {MutableRef} from "#common/ref";

export const Dnd5eActionTemplateSegmentAttackRollAttributeOverrideModifier = z.object({
  modifierID: Dnd5eModifierID,
  attribute: z.optional(Dnd5eAttribute)
});
export type Dnd5eActionTemplateSegmentAttackRollAttributeOverrideModifier = z.infer<typeof Dnd5eActionTemplateSegmentAttackRollAttributeOverrideModifier>;
export const Dnd5eActionTemplateSegmentAttackRollAttributeOverrideModifierOperation = z.discriminatedUnion("type", [
  z.object({type: z.literal("update-modifier-i-d"), operations: z.array(ConstantOperation)}),
  z.object({type: z.literal("update-attribute"), operations: z.array(ValueOperation(z.optional(Dnd5eAttribute), ConstantOperation))})
]);
export type Dnd5eActionTemplateSegmentAttackRollAttributeOverrideModifierOperation = z.infer<typeof Dnd5eActionTemplateSegmentAttackRollAttributeOverrideModifierOperation>;
export const dnd5eActionTemplateSegmentAttackRollAttributeOverrideModifierType: Type<Dnd5eActionTemplateSegmentAttackRollAttributeOverrideModifier, Dnd5eActionTemplateSegmentAttackRollAttributeOverrideModifierOperation> = new ObjectType({
  modifierID: constantType,
  attribute: new ValueType(constantType)
});

export function Dnd5eActionTemplateSegmentAttackRollAttributeOverrideModifierSignals(valueRef: MutableRef<Dnd5eActionTemplateSegmentAttackRollAttributeOverrideModifier, Dnd5eActionTemplateSegmentAttackRollAttributeOverrideModifierOperation[]>) {
  return {
    attributeRef: ValuePropertyRef<Dnd5eActionTemplateSegmentAttackRollAttributeOverrideModifier, Dnd5eActionTemplateSegmentAttackRollAttributeOverrideModifierOperation, Optional<Dnd5eAttribute>, ConstantOperation>(
      value => value.attribute,
      operations => [{type: "update-attribute", operations}]
    )(valueRef)
  };
}
import {z} from "zod";
import {ConstantOperation, constantType, Encrypted, FileReference, ObjectType, RichText, Type, ValueOperation, ValueType} from "../../../types/index.ts";
import {RollRequestID, RollRequests, RollResults} from "../roll-request/index.ts";
import {Dnd5eDamageType} from "../../../legends/asset/sheet/dnd-5e/dnd-5e-damage-type.ts";
import {Dnd5eAbilityCheckType} from "../../../legends/asset/sheet/dnd-5e/dnd-5e-modifier/dnd-5e-ability-check-modifier.ts";
import {Dnd5eSavingThrowType} from "../../../legends/asset/sheet/dnd-5e/dnd-5e-modifier/dnd-5e-saving-throw-modifier.ts";
import {NodeID, UserID} from "../../../legends/index.ts";
import {QLabMessageID} from "../q-lab-message-id.ts";

export const Dnd5eActionSection = z.lazy(() => z.object({
  name: z.string(),
  segments: z.array(Dnd5eActionSegment)
}));
export type Dnd5eActionSection = z.infer<typeof Dnd5eActionSection>;

export const Dnd5eActionDiceRoll = z.object({
  rollType: z.optional(Dnd5eDamageType),
  rollID: RollRequestID
});
export type Dnd5eActionDiceRoll = z.infer<typeof Dnd5eActionDiceRoll>;

export const Dnd5eActionAbilityCheck: z.ZodType<{
  rollID: RollRequestID;
  abilityCheckTypes: Dnd5eAbilityCheckType[];
  onSuccess: Dnd5eActionSegment[];
  onFailure: Dnd5eActionSegment[];
}> = z.object({
  rollID: RollRequestID,
  abilityCheckTypes: z.array(Dnd5eAbilityCheckType),
  onSuccess: z.lazy(() => z.array(Dnd5eActionSegment)),
  onFailure: z.lazy(() => z.array(Dnd5eActionSegment))
});
export type Dnd5eActionAbilityCheck = z.infer<typeof Dnd5eActionAbilityCheck>;

export const Dnd5eActionAttackRoll: z.ZodType<{
  attackRollID: RollRequestID;
  onCriticalHit: Dnd5eActionSegment[];
  onHit: Dnd5eActionSegment[];
  onMiss: Dnd5eActionSegment[];
}> = z.object({
  attackRollID: RollRequestID,
  onCriticalHit: z.lazy(() => z.array(Dnd5eActionSegment)),
  onHit: z.lazy(() => z.array(Dnd5eActionSegment)),
  onMiss: z.lazy(() => z.array(Dnd5eActionSegment))
});
export type Dnd5eActionAttackRoll = z.infer<typeof Dnd5eActionAttackRoll>;

export const Dnd5eActionSavingThrow: z.ZodType<{
  difficultyClassRollID: RollRequestID,
  savingThrowTypes: Dnd5eSavingThrowType[],
  onSuccess: Dnd5eActionSegment[];
  onMiss: Dnd5eActionSegment[];
}> =  z.object({
  difficultyClassRollID: RollRequestID,
  savingThrowTypes: z.array(Dnd5eSavingThrowType),
  onSuccess: z.lazy(() => z.array(Dnd5eActionSegment)),
  onMiss: z.lazy(() => z.array(Dnd5eActionSegment))
});
export type Dnd5eActionSavingThrow = z.infer<typeof Dnd5eActionSavingThrow>;

export const Dnd5eActionSegment: z.ZodType<
  | {type: "ability-check", data: Dnd5eActionAbilityCheck}
  | {type: "attack-roll", data: Dnd5eActionAttackRoll}
  | {type: "saving-throw", data: Dnd5eActionSavingThrow}
  | {type: "roll", data: Dnd5eActionDiceRoll[]}
  | {type: "section", data: Dnd5eActionSection}
  | {type: "text", data: RichText}
> = z.lazy(() => z.discriminatedUnion("type", [
  z.object({type: z.literal("ability-check"), data: Dnd5eActionAbilityCheck}),
  z.object({type: z.literal("attack-roll"), data: Dnd5eActionAttackRoll}),
  z.object({type: z.literal("saving-throw"), data: Dnd5eActionSavingThrow}),
  z.object({type: z.literal("roll"), data: z.array(Dnd5eActionDiceRoll)}),
  z.object({type: z.literal("section"), data: Dnd5eActionSection}),
  z.object({type: z.literal("text"), data: RichText})
]));
export type Dnd5eActionSegment = z.infer<typeof Dnd5eActionSegment>;

export const Dnd5eActionMessage = z.object({
  title: z.string(),
  userID: UserID,
  nodeID: z.optional(NodeID),
  icon: FileReference,
  referenceMessageID: z.optional(QLabMessageID),
  rollRequests: Encrypted(RollRequests),
  rollResults: z.optional(Encrypted(RollResults)),
  segments: z.array(Dnd5eActionSegment)
});
export type Dnd5eActionMessage = z.infer<typeof Dnd5eActionMessage>;

export const Dnd5eActionMessageOperation = z.discriminatedUnion("type", [
  z.object({type: z.literal("update-title"), operations: z.array(ConstantOperation)}),
  z.object({type: z.literal("update-user-i-d"), operations: z.array(ConstantOperation)}),
  z.object({type: z.literal("update-node-i-d"), operations: z.array(ConstantOperation)}),
  z.object({type: z.literal("update-icon"), operations: z.array(ConstantOperation)}),
  z.object({type: z.literal("update-reference-message-i-d"), operations: z.array(ConstantOperation)}),
  z.object({type: z.literal("update-roll-requests"), operations: z.array(ConstantOperation)}),
  z.object({type: z.literal("update-roll-results"), operations: z.array(ValueOperation(z.optional(Encrypted(RollResults)), ConstantOperation))}),
  z.object({type: z.literal("update-segments"), operations: z.array(ConstantOperation)}),
]);
export type Dnd5eActionMessageOperation = z.infer<typeof Dnd5eActionMessageOperation>;

export const dnd5eActionMessageType: Type<Dnd5eActionMessage, Dnd5eActionMessageOperation> = new ObjectType({
  title: constantType,
  userID: constantType,
  nodeID: constantType,
  icon: constantType,
  referenceMessageID: constantType,
  rollRequests: constantType,
  rollResults: new ValueType(constantType),
  segments: constantType
}, v => {
  return {
    ...v,
    segments: v.segments.map((segment: any): Dnd5eActionSegment => {
      if (segment.type === "damage") {
        return ({
          type: "roll",
          data: segment.data.map((roll: any) => ({
            rollID: roll.damageRollID,
            rollType: roll.damageType
          }))
        }) satisfies Dnd5eActionSegment;
      } else if (segment.type === "dice-roll") {
        return ({...segment, type: "roll"});
      } else {
        return segment;
      }
    })
  };
});

import {useDatabase} from "../../../routes/game/model/store-context.tsx";
import {useComputedValue} from "#lib/signal/index.ts";
import {Optional, Tree} from "common/types/generic/index.ts";
import {AssetID} from "common/legends/asset/index.ts";

export function useAssetName(assetID: Optional<AssetID>) {
  const databaseRef = useDatabase();
  return useComputedValue(databaseRef, database => {
    if (!assetID) return "";
    const store = database.store;
    if (store?.type === "game") {
      const game = store.data;
      const item = Tree.getItemById(game.assets, assetID);
      return item?.data.name || "";
    }
    return "";
  }, [assetID]);
}

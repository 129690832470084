import {RenderedRoll} from "#lib/beyond20/beyond-20-rendered-roll.ts";
import {Beyond20UpdateHP} from "#lib/beyond20/beyond-20-update-h-p.ts";

const apiListeners: {[event: string]: ((message: any) => void)} = {};
const eventListeners: {[event: string]: ((message: any) => void)[]} = {};

export function sendBeyond20Event(event: string, ...args: any) {
  document.dispatchEvent(new CustomEvent("Beyond20_" + event, { "detail": args }));
}

export function addBeyond20Listener(event: "UpdateHP", listener: (message: [Beyond20UpdateHP, string, number, number, number]) => void): () => void;
export function addBeyond20Listener(event: "RenderedRoll", listener: (message: RenderedRoll<any>[]) => void): () => void;
export function addBeyond20Listener(event: string, listener: (message: any) => void): () => void {
  if (eventListeners[event] === undefined) eventListeners[event] = [];
  if (apiListeners[event] === undefined) {
    apiListeners[event] = (message) => {
      if (eventListeners[event]) {
        for (const listener of eventListeners[event]) {
          setTimeout(() => listener(message.detail), 0);
        }
      }
    };
    document.addEventListener(`Beyond20_${event}`, apiListeners[event], true);
  }
  eventListeners[event].push(listener);

  return () => {
    const index = eventListeners[event].indexOf(listener);
    if (index !== -1) {
      eventListeners[event].splice(index, 1);
    }

    if (eventListeners[event].length === 0) {
      document.removeEventListener(`Beyond20_${event}`, apiListeners[event], true);
      delete eventListeners[event];
      delete apiListeners[event];
    }
  };
}

export function setupBeyond20() {
}



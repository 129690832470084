import {ListOperation, Optional} from "common/types/index.ts";
import React, {useMemo} from "react";
import {InputList, InputListItemProps, useDragListItem} from "#lib/components/list/input-list.tsx";
import {MutableRef, Ref} from "common/ref";
import {
  Dnd5eActionTemplateSegment,
  Dnd5eActionTemplateSegmentFn,
  Dnd5eActionTemplateSegmentOperation,
  Dnd5eActionTemplateSegmentTypes
} from "common/legends/asset/sheet/dnd-5e/dnd-5e-action-definition/segment/dnd-5e-action-template-segment.ts";
import {useTypedRef} from "../../../../../common/use-typed-ref.ts";
import {Dnd5eActionTemplateSegmentTextView} from "./action-template-segment/dnd-5e-action-template-segment-text-view.tsx";
import {Dnd5eActionTemplateSegmentRollView} from "./action-template-segment/roll/dnd-5e-action-template-segment-roll-view.tsx";
import {Dnd5eActionTemplateSegmentAttackRollView} from "./action-template-segment/attack-roll/dnd-5e-action-template-segment-attack-roll-view.tsx";
import {Dnd5eActionTemplateSegmentSavingThrowView} from "./action-template-segment/saving-throw/dnd-5e-action-template-segment-saving-throw-view.tsx";
import {useVariables, VariablesProvider} from "./dnd-5e-variables.ts";
import {useSheetSignal} from "../../../../../common/sheet/use-sheet-signal.ts";
import {useSheetReference} from "../../../../../common/sheet/sheet-reference-context.ts";
import {useGlobalFeatures} from "../../dnd-5e-character/dnd-5e-action/use-global-features.ts";
import {useActionRef} from "./action-context.ts";
import {getActiveSegmentVariables} from "../../dnd-5e-character/dnd-5e-action/get-active-variables.ts";
import {MathExpressionFn} from "common/math/math-expression.ts";
import {RollVariables} from "common/qlab/message/index.ts";
import {Dnd5eFeature, Sheet} from "common/legends/asset/index.ts";
import {Dnd5eActionTemplate} from "common/legends/asset/sheet/dnd-5e/dnd-5e-action-definition/template/dnd-5e-action-template.ts";
import {Dnd5eActionTemplateSegmentSectionView} from "./action-template-segment/dnd-5e-action-template-segment-section-view.tsx";
import {Dnd5eActionTemplateSegmentAbilityCheckView} from "./action-template-segment/ability-check/dnd-5e-action-template-segment-ability-check-view.tsx";


function Dnd5eActionTemplateSegmentView({item, remove, duplicate}: InputListItemProps<Dnd5eActionTemplateSegment, Dnd5eActionTemplateSegmentOperation>) {
  const [type, typedRef] = useTypedRef<Dnd5eActionTemplateSegmentTypes>(item);
  const [dragHandlerRef, dragRefPreview] = useDragListItem("legends/action-template-segment", item, remove);

  const sheetRef = useSheetSignal(useSheetReference());
  const globalFeaturesRef = useGlobalFeatures();
  const actionRef = useActionRef()!;

  const variablesRef = useVariables();
  const segmentVariables = useMemo((): Ref<RollVariables> => {
    const valueFn = (sheet: Optional<Sheet>, globalFeatures: Dnd5eFeature[], action: Dnd5eActionTemplate, segment: Dnd5eActionTemplateSegment, variables: RollVariables): RollVariables => {
      const segmentVariables: RollVariables = {...variables};
      for (const modifier of getActiveSegmentVariables(sheet, globalFeatures, action, segment)) {
        segmentVariables[modifier.name.toUpperCase()] = MathExpressionFn.executeMathExpression(modifier.expression, segmentVariables);
      }
      return segmentVariables;
    }
    return new MutableRef({
      value: () => valueFn(sheetRef.value, globalFeaturesRef.value, actionRef.value, item.value, variablesRef.value),
      observe: MutableRef.all(sheetRef, globalFeaturesRef, actionRef, item, variablesRef)
        .map(([sheet, globalFeatures, action, segment, variables]) => valueFn(sheet, globalFeatures, action, segment, variables))
        .observe,
      apply: () => {throw new Error("Unsupported")}
    });
  }, [sheetRef, globalFeaturesRef, actionRef, item, variablesRef]);

  return (<VariablesProvider value={segmentVariables}>
    {type === "text" && <Dnd5eActionTemplateSegmentTextView item={typedRef} remove={remove} duplicate={duplicate} dragHandlerRef={dragHandlerRef} dragRefPreview={dragRefPreview} />}
    {type === "section" && <Dnd5eActionTemplateSegmentSectionView item={typedRef} remove={remove} duplicate={duplicate} dragHandlerRef={dragHandlerRef} dragRefPreview={dragRefPreview} />}
    {type === "roll" && <Dnd5eActionTemplateSegmentRollView item={typedRef} remove={remove} duplicate={duplicate} dragHandlerRef={dragHandlerRef} dragRefPreview={dragRefPreview} />}
    {type === "attack-roll" && <Dnd5eActionTemplateSegmentAttackRollView item={typedRef} remove={remove} duplicate={duplicate} dragHandlerRef={dragHandlerRef} dragRefPreview={dragRefPreview} />}
    {type === "saving-throw" && <Dnd5eActionTemplateSegmentSavingThrowView item={typedRef} remove={remove} duplicate={duplicate} dragHandlerRef={dragHandlerRef} dragRefPreview={dragRefPreview} />}
    {type === "ability-check" && <Dnd5eActionTemplateSegmentAbilityCheckView item={typedRef} remove={remove} duplicate={duplicate} dragHandlerRef={dragHandlerRef} dragRefPreview={dragRefPreview} />}
  </VariablesProvider>)
}

export function Dnd5eActionTemplateSegmentsView({valueRef}: {
  valueRef: MutableRef<Dnd5eActionTemplateSegment[], ListOperation<Dnd5eActionTemplateSegment, Dnd5eActionTemplateSegmentOperation>[]>;
}) {
  return <InputList<Dnd5eActionTemplateSegment, Dnd5eActionTemplateSegmentOperation>
      accept="legends/action-template-segment"
      items={valueRef}
      itemKey={Dnd5eActionTemplateSegmentFn.getActionSegmentID}
      copy={Dnd5eActionTemplateSegmentFn.copyActionSegment}
      ListItem={Dnd5eActionTemplateSegmentView} />;
}

import {ListOperation, ListSignal, Optional} from "common/types/index.ts";
import {useMemo} from "react";
import {pipe} from "common/pipe";
import {distinct, map} from "common/observable";
import {MutableRef} from "common/ref";
import {Dnd5eActionTemplate, Dnd5eActionTemplateOperation} from "common/legends/asset/sheet/dnd-5e/dnd-5e-action-definition/template/dnd-5e-action-template.ts";
import {Dnd5eActionTemplateID} from "common/legends/asset/sheet/dnd-5e/dnd-5e-action-definition/template/dnd-5e-action-template-i-d.ts";

const valueFn = (actions: Dnd5eActionTemplate[], actionID: Optional<Dnd5eActionTemplateID>): Optional<Dnd5eActionTemplate> => {
  return actionID ? actions.find(action => action.data.actionTemplateID === actionID) : undefined;
};

export function useActionTemplateByID(items: ListSignal<Dnd5eActionTemplate, Dnd5eActionTemplateOperation>, actionTemplateID: Dnd5eActionTemplateID | undefined): MutableRef<Optional<Dnd5eActionTemplate>, Dnd5eActionTemplateOperation[]> {
  return useMemo((): MutableRef<Optional<Dnd5eActionTemplate>, Dnd5eActionTemplateOperation[]> => {
    return new MutableRef({
      value(): Optional<Dnd5eActionTemplate> {
        return valueFn(items.value, actionTemplateID);
      },
      observe: pipe(
        items.observe,
        map(v => valueFn(v, actionTemplateID)),
        distinct()
      ),
      apply: fn => items.apply(prev => {
        const index = prev.findIndex(action => action.data.actionTemplateID === actionTemplateID);
        if (index === -1) return [];
        return ListOperation.apply(index, fn(prev[index]));
      }).then(value => valueFn(value, actionTemplateID))
    });
  }, [items, actionTemplateID]);
}

import {z} from "zod";
import {Dnd5eModifierID} from "../../../dnd-5e-modifier/dnd-5e-modifier-i-d.ts";
import {Dnd5eAttribute} from "../../../character/dnd-5e-attribute.ts";
import {ConstantOperation, constantType, ObjectType, Optional, Type, ValueOperation, ValuePropertyRef, ValueType} from "../../../../../../../types/index.ts";
import {MutableRef} from "#common/ref";

export const Dnd5eActionTemplateSegmentSavingThrowAttributeOverrideModifier = z.object({
  modifierID: Dnd5eModifierID,
  attribute: z.optional(Dnd5eAttribute)
});
export type Dnd5eActionTemplateSegmentSavingThrowAttributeOverrideModifier = z.infer<typeof Dnd5eActionTemplateSegmentSavingThrowAttributeOverrideModifier>;
export const Dnd5eActionTemplateSegmentSavingThrowAttributeOverrideModifierOperation = z.discriminatedUnion("type", [
  z.object({type: z.literal("update-modifier-i-d"), operations: z.array(ConstantOperation)}),
  z.object({type: z.literal("update-attribute"), operations: z.array(ValueOperation(z.optional(Dnd5eAttribute), ConstantOperation))})
]);
export type Dnd5eActionTemplateSegmentSavingThrowAttributeOverrideModifierOperation = z.infer<typeof Dnd5eActionTemplateSegmentSavingThrowAttributeOverrideModifierOperation>;
export const dnd5eActionTemplateSegmentSavingThrowAttributeOverrideModifierType: Type<Dnd5eActionTemplateSegmentSavingThrowAttributeOverrideModifier, Dnd5eActionTemplateSegmentSavingThrowAttributeOverrideModifierOperation> = new ObjectType({
  modifierID: constantType,
  attribute: new ValueType(constantType)
});

export function Dnd5eActionTemplateSegmentSavingThrowAttributeOverrideModifierSignals(valueRef: MutableRef<Dnd5eActionTemplateSegmentSavingThrowAttributeOverrideModifier, Dnd5eActionTemplateSegmentSavingThrowAttributeOverrideModifierOperation[]>) {
  return {
    attributeRef: ValuePropertyRef<Dnd5eActionTemplateSegmentSavingThrowAttributeOverrideModifier, Dnd5eActionTemplateSegmentSavingThrowAttributeOverrideModifierOperation, Optional<Dnd5eAttribute>, ConstantOperation>(
      value => value.attribute,
      operations => [{type: "update-attribute", operations}]
    )(valueRef)
  };
}
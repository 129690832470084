import {MutableRef, Ref} from "common/ref";
import {EditorVision} from "../../scene/editor-vision.ts";
import {NodeId} from "common/legends/node/index.ts";
import {useMemo} from "react";
import {Vision, VisionFn} from "common/legends/asset/token/vision/vision.ts";
import {Optional} from "common/types/generic/index.ts";
import {useNodeTreeIDs} from "../../token/use-node-tree-i-ds.ts";
import {getNode} from "../../../common/node/use-get-node.ts";
import {useDatabase} from "../../../../routes/game/model/store-context.tsx";
import {listIdentity} from "common/observable";

export function useVision(editorVisionRef: Ref<EditorVision>, activeNodeIDRef: Ref<Optional<NodeId>>): Ref<Vision[]> {
  const visibilityNodeIDsRef = useNodeTreeIDs(activeNodeIDRef);
  const databaseRef = useDatabase();

  return useMemo(() => MutableRef.all(databaseRef, editorVisionRef, activeNodeIDRef, visibilityNodeIDsRef)
    .map(([database, editorVision, activeNodeID, visibilityNodeIDs]): Vision[] => {
      const defaultVision: Vision[] = [{
        offset: [0, 0],
        accessMask: editorVision.senseID ? ["ALL", "GM", editorVision.senseID] : ["ALL", "GM"],
        accessibleNodeIDs: visibilityNodeIDs,
        visibilityNodeIDs,
        grayscale: false
      }];
      if (editorVision.override || activeNodeID === undefined) {
        return defaultVision;
      }

      const node = getNode(database, activeNodeID);
      if (node?.type === "token") {
        const {assetID, tokenID} = node.data.tokenReference;
        const asset = database.resources[assetID];
        if (asset?.type !== "asset") return defaultVision;
        const token = asset.data.tokens.find(token => token.tokenID === tokenID);
        if (!token) return defaultVision;
        if (token.vision.length === 0) return defaultVision;

        return token.vision.map(vision => ({
          offset: vision.offset,
          accessMask: vision.senseID === undefined ? ["ALL"] : ["ALL", vision.senseID],
          accessibleNodeIDs: visibilityNodeIDs,
          visibilityNodeIDs,
          grayscale: vision.grayscale,
          limit: vision.limit
        }));
      } else {
        return defaultVision;
      }
    })
    .distinct((a, b) => listIdentity(a, b, VisionFn.equals)), [databaseRef, editorVisionRef, activeNodeIDRef, visibilityNodeIDsRef]);
}

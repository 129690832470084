import {Button, ButtonBar, InputGroup, TagListInput} from "#lib/components/index.ts";
import React, {useMemo} from "react";
import {SceneTreeFile, SceneTreeFileFn, SceneTreeFileOperation} from "common/legends/index.ts";
import {Modal, ModalBody, ModalTitle} from "#lib/components/modal/index.ts";
import {InputString} from "#lib/components/input/input-string.tsx";
import {MutableRef} from "common/ref";
import {Field, FieldLabel} from "#lib/components/panel-header.tsx";

export type EditSceneModalProps = {
  value: MutableRef<SceneTreeFile, SceneTreeFileOperation[]>;
  onClose: () => void;
};

export function EditSceneModal({onClose, value}: EditSceneModalProps) {
  const {name, tags} = useMemo(() => SceneTreeFileFn(value), [value]);

  return (<Modal onClose={onClose}>
    <ModalTitle>Edit Scene</ModalTitle>
    <ModalBody>
      <Field>
        <FieldLabel>Name</FieldLabel>
        <InputGroup className="mx-2 rounded-md overflow-hidden">
          <InputString autoFocus value={name} />
        </InputGroup>
      </Field>
      <Field>
        <FieldLabel>Tags</FieldLabel>
        <TagListInput value={tags} />
      </Field>
    </ModalBody>
    <ButtonBar>
      <Button type="button" className="grow" variant="tertiary" onClick={onClose}>Close</Button>
    </ButtonBar>
  </Modal>);
}
import {ButtonBar, IconButton, InputGroup} from "#lib/components/index.ts";
import {FaMapPin} from "react-icons/fa";
import {useObservable} from "#lib/qlab/index.ts";
import {ConstantOperation, Optional, StringOperation, ValueOperation} from "common/types/index.ts";
import {useCallback} from "react";
import {pipe} from "common/pipe";
import {distinct, from, map} from "common/observable";
import {InputString} from "#lib/components/input/input-string.tsx";
import {MutableRef} from "common/ref";
import {Field, FieldLabel} from "#lib/components/panel-header.tsx";
import {twMerge} from "tailwind-merge";

export type NameFieldProps<T> = {
  value: MutableRef<string, StringOperation[]>;
  reference: T;
  pinned?: MutableRef<Optional<T>, ValueOperation<Optional<T>, ConstantOperation>[]>;
  className?: string;
};

export function NameField({value, reference, pinned, className}: NameFieldProps<any>) {
  const togglePinned = useCallback(() => {
    pinned?.apply(prevValue => {
      if (prevValue === reference) return [{type: "set", prevValue, nextValue: undefined}];
      return [{
        type: "set",
        prevValue: prevValue,
        nextValue: reference
      }];
    });
  }, [reference, pinned?.apply]);

  const isPinned = useObservable(pipe(pinned?.observe || from(undefined), map(r => r === reference), distinct()), false, [reference, pinned?.observe]);

  return (<Field>
    <FieldLabel>Name</FieldLabel>
    <ButtonBar className={twMerge("bg-transparent mx-2 rounded-md overflow-hidden", className)}>
      <InputGroup className="flex-1">
        <InputString value={value} onFocus={ev => ev.target.select()} />
      </InputGroup>
      {pinned?.apply && <IconButton onClick={togglePinned} variant={isPinned ? "primary" : "tertiary"} title="Pin"><FaMapPin/></IconButton>}
    </ButtonBar>
  </Field>)
}

import {ObjectType, Transform, Type} from "#common/types/index.ts";
import {MutableRef} from "#common/ref";
import {defaultLocalNode, LocalNode, LocalNodeOperation, LocalNodeSignals, localNodeTypePropTypes, localNodeUpdater} from "./local-node.ts";

export type ParallaxNode = LocalNode & {};

export type ParallaxNodeOperation =
  | LocalNodeOperation
  ;


export const parallaxNodeType: Type<ParallaxNode, ParallaxNodeOperation> = new ObjectType(() => ({
  ...localNodeTypePropTypes()
}), (value) => {
  value = localNodeUpdater(value);
  return value;
});

export function defaultParallaxNode(): ParallaxNode {
  return {
    ...defaultLocalNode(),
    transform: {
      ...Transform.DEFAULT,
      scale: 2
    },
    name: "Parallax"
  };
}

export function ParallaxNodeRef(value: MutableRef<ParallaxNode, ParallaxNodeOperation[]>) {
  return {
    ...LocalNodeSignals(value)
  };
}

import {TokenReference} from "common/legends/node/index.ts";
import {MutableRef, Ref} from "common/ref";
import {Optional} from "common/types/generic/index.ts";
import {AssetID, Token, TokenID} from "common/legends/asset/index.ts";
import {useDatabase} from "../../../routes/game/model/store-context.tsx";
import {QLabDatabase} from "common/qlab/q-lab-database.ts";
import {useMemo} from "react";

export function getToken(database: QLabDatabase, assetID: Optional<AssetID>, tokenID: Optional<TokenID>): Optional<Token> {
  if (assetID === undefined) return undefined;
  if (tokenID === undefined) return undefined;
  const asset = database.resources[assetID];
  if (asset?.type !== "asset") return undefined;
  return asset.data.tokens.find(token => token.tokenID === tokenID);
}

export function useToken(valueRef: Ref<Optional<TokenReference>>): Ref<Optional<Token>> {
  const databaseRef = useDatabase();
  return useMemo(() => MutableRef.all(databaseRef, valueRef)
    .map(([database, value]) => getToken(database, value?.assetID, value?.tokenID)).distinct(), [databaseRef, valueRef]);
}
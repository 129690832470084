import {z} from "zod";
import {MultiType, Type} from "../../../../../../../types/index.ts";
import {Dnd5eVariableModifier, Dnd5eVariableModifierOperation, dnd5eVariableModifierType} from "../../../dnd-5e-modifier/dnd-5e-variable-modifier.ts";
import {
  Dnd5eActionTemplateSegmentRollConditionModifier,
  Dnd5eActionTemplateSegmentRollConditionModifierOperation,
  dnd5eActionTemplateSegmentRollConditionModifierType
} from "./dnd-5e-action-template-segment-roll-condition-modifier.ts";
import {
  Dnd5eActionTemplateSegmentRollExpressionModifier,
  Dnd5eActionTemplateSegmentRollExpressionModifierOperation,
  dnd5eActionTemplateSegmentRollExpressionType
} from "./dnd-5e-action-template-segment-roll-expression-modifier.ts";
import {generateDnd5eModifierID} from "../../../dnd-5e-modifier/dnd-5e-modifier-i-d.ts";

export const Dnd5eActionTemplateSegmentRollModifier = z.discriminatedUnion("type", [
  z.object({type: z.literal("variable"), data: Dnd5eVariableModifier}),
  z.object({type: z.literal("action::roll::condition"), data: Dnd5eActionTemplateSegmentRollConditionModifier}),
  z.object({type: z.literal("action::roll::expression"), data: Dnd5eActionTemplateSegmentRollExpressionModifier})
]);
export type Dnd5eActionTemplateSegmentRollModifier = z.infer<typeof Dnd5eActionTemplateSegmentRollModifier>;
export const Dnd5eActionTemplateSegmentRollModifierOperation = z.discriminatedUnion("type", [
  z.object({type: z.literal("variable"), operations: z.array(Dnd5eVariableModifierOperation)}),
  z.object({type: z.literal("action::roll::condition"), operations: z.array(Dnd5eActionTemplateSegmentRollConditionModifierOperation)}),
  z.object({type: z.literal("action::roll::expression"), operations: z.array(Dnd5eActionTemplateSegmentRollExpressionModifierOperation)})
]);
export type Dnd5eActionTemplateSegmentRollModifierOperation = z.infer<typeof Dnd5eActionTemplateSegmentRollModifierOperation>;

export type Dnd5eActionTemplateSegmentRollModifierTypes = {
  "variable": Type<Dnd5eVariableModifier, Dnd5eVariableModifierOperation>;
  "action::roll::condition": Type<Dnd5eActionTemplateSegmentRollConditionModifier, Dnd5eActionTemplateSegmentRollConditionModifierOperation>;
  "action::roll::expression": Type<Dnd5eActionTemplateSegmentRollExpressionModifier, Dnd5eActionTemplateSegmentRollExpressionModifierOperation>;
};

export const dnd5eActionTemplateSegmentRollModifierType: Type<Dnd5eActionTemplateSegmentRollModifier, Dnd5eActionTemplateSegmentRollModifierOperation> = new MultiType<Dnd5eActionTemplateSegmentRollModifierTypes>({
  "variable": dnd5eVariableModifierType,
  "action::roll::condition": dnd5eActionTemplateSegmentRollConditionModifierType,
  "action::roll::expression": dnd5eActionTemplateSegmentRollExpressionType
}, v => {
  if (v?.type === "action::dice-roll::expression") return {...v, type: "action::roll::expression"};
  if (v?.type === "action::dice-roll::condition") return {...v, type: "action::roll::condition"};
  if (v.type === "action::damage::damage-modifier") {
    return ({
      type: "action::roll::expression",
      data: {
        modifierID: v.data.modifierID,
        condition: v.data.condition,
        rollType: v.data.damageType,
        expression: v.data.expression
      }
    }) satisfies Dnd5eActionTemplateSegmentRollModifier
  } else if (v?.type === "action::damage::condition") {
    return ({
      type: "action::roll::condition",
      data: {
        modifierID: v.data.modifierID,
        condition: v.data.condition,
        modifiers: v.data.modifiers
      }
    }) satisfies Dnd5eActionTemplateSegmentRollModifier
  }
  return v;
});

export const Dnd5eActionTemplateSegmentRollModifierFn = {
  copyDnd5eActionTemplateSegmentRollModifier: (value: Dnd5eActionTemplateSegmentRollModifier): Dnd5eActionTemplateSegmentRollModifier => {
    switch (value.type) {
      case "variable": return {...value, data: {...value.data, modifierID: generateDnd5eModifierID()}};
      case "action::roll::expression": return {...value, data: {...value.data, modifierID: generateDnd5eModifierID()}};
      case "action::roll::condition": return {...value, data: {...value.data, modifierID: generateDnd5eModifierID(), modifiers: value.data.modifiers.map(Dnd5eActionTemplateSegmentRollModifierFn.copyDnd5eActionTemplateSegmentRollModifier)}};
    }
  }
}
import {z} from "zod";
import {Dnd5eProcessAdjustSpellSlot, Dnd5eProcessAdjustSpellSlotOperation, dnd5eProcessAdjustSpellSlotType} from "./dnd-5e-process-adjust-spell-slot.ts";
import {Dnd5eProcessConsumeItem, Dnd5eProcessConsumeItemOperation, dnd5eProcessConsumeItemType} from "./dnd-5e-process-consume-item.ts";
import {Dnd5eProcessAdjustCustomResource, Dnd5eProcessAdjustCustomResourceOperation, dnd5eProcessAdjustCustomResourceType} from "./dnd-5e-process-adjust-custom-resource.ts";
import {MultiType, Type} from "../../../../../../types/index.ts";
import {generateDnd5eProcessID} from "./dnd-5e-process-i-d.ts";

export const Dnd5eProcess = z.discriminatedUnion("type", [
  z.object({type: z.literal("adjust-custom-resource"), data: Dnd5eProcessAdjustCustomResource}),
  z.object({type: z.literal("adjust-spell-slot"), data: Dnd5eProcessAdjustSpellSlot}),
  z.object({type: z.literal("consume-item"), data: Dnd5eProcessConsumeItem}),
]);
export type Dnd5eProcess = z.infer<typeof Dnd5eProcess>;

export const Dnd5eProcessOperation = z.discriminatedUnion("type", [
  z.object({type: z.literal("adjust-custom-resource"), operations: z.array(Dnd5eProcessAdjustCustomResourceOperation)}),
  z.object({type: z.literal("adjust-spell-slot"), operations: z.array(Dnd5eProcessAdjustSpellSlotOperation)}),
  z.object({type: z.literal("consume-item"), operations: z.array(Dnd5eProcessConsumeItemOperation)}),
]);
export type Dnd5eProcessOperation = z.infer<typeof Dnd5eProcessOperation>;

export type Dnd5eProcessTypes = {
  adjustCustomResource: Type<Dnd5eProcessAdjustCustomResource, Dnd5eProcessAdjustCustomResourceOperation>;
  adjustSpellSlot: Type<Dnd5eProcessAdjustSpellSlot, Dnd5eProcessAdjustSpellSlotOperation>;
  consumeItem: Type<Dnd5eProcessConsumeItem, Dnd5eProcessConsumeItemOperation>;
};

export const dnd5eProcessType: Type<Dnd5eProcess, Dnd5eProcessOperation> = new MultiType<Dnd5eProcessTypes>({
  adjustCustomResource: dnd5eProcessAdjustCustomResourceType,
  adjustSpellSlot: dnd5eProcessAdjustSpellSlotType,
  consumeItem: dnd5eProcessConsumeItemType,
}, v => {
  if (v.type === "adjust-item") v.type = "consume-item";
  return v;
});

export const Dnd5eProcessFn = {
  getProcessID: (v: Dnd5eProcess) => v.data.processID,
  copyProcess: (v: Dnd5eProcess): Dnd5eProcess => {
    switch (v.type) {
      case "adjust-custom-resource": return {...v, data: {...v.data, processID: generateDnd5eProcessID()}};
      case "adjust-spell-slot": return {...v, data: {...v.data, processID: generateDnd5eProcessID()}};
      case "consume-item": return {...v, data: {...v.data, processID: generateDnd5eProcessID()}};
    }
  }
};
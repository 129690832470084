import {Button, ButtonBar, IconButton, InputGroup, InputGroupLabel, useToggle} from "#lib/components/index.ts";
import {FaPlus} from "react-icons/fa";
import {ListOperation, ListSignal} from "common/types/index.ts";
import React, {useMemo, useState} from "react";
import {SectionHeader} from "#lib/components/panel-header.tsx";
import {twMerge} from "tailwind-merge";
import {DragIndicator} from "#lib/components/tree-view/drag-indicator.tsx";
import {useRefValue} from "#lib/signal/index.ts";
import {InputList, InputListItemProps, useDragListItem} from "#lib/components/list/input-list.tsx";
import {copyInteraction, getInteractionID, Interaction, InteractionOperation, InteractionSignals} from "common/legends/node/interaction/interaction.ts";
import {generateInteractionID, InteractionID} from "common/legends/node/interaction/interaction-i-d.ts";
import {InteractionEditor} from "./interaction-editor.tsx";
import {useInteractionByID} from "./use-interaction-by-i-d.ts";
import {usePresent} from "../../../../common/use-optional-signal.ts";

export type InputInteractionProps = InputListItemProps<Interaction, InteractionOperation>;
export function InputInteraction({item, remove}: InputInteractionProps) {
  const {title} = useMemo(() => InteractionSignals(item), [item]);
  const [dragHandlerRef, dragRefPreview] = useDragListItem("legends/interaction", item, remove);
  const [expanded, toggleExpanded] = useToggle(false);
  return (<div role="list-item" ref={dragRefPreview} className={twMerge("flex flex-col")}>
    <ButtonBar>
      <IconButton ref={dragHandlerRef} title="Move"><DragIndicator /></IconButton>
      <InputGroup className="flex-1 cursor-pointer" onClick={toggleExpanded}>
        <InputGroupLabel>{useRefValue(title)}</InputGroupLabel>
      </InputGroup>
    </ButtonBar>
    {expanded && <InteractionEditor value={item} onClose={toggleExpanded} remove={remove} />}
  </div>);
}

export type InputInteractionsProps = {
  value: ListSignal<Interaction, InteractionOperation>;
};
export function InputInteractions({value}: InputInteractionsProps) {
  const [editInteractionID, setEditInteractionID] = useState<InteractionID | undefined>(undefined);
  const onAddInteraction = () => {
    const interactionID = generateInteractionID();
    value.apply(prev => ListOperation.insert(
      prev.length,
      {interactionID, title: "", triggers: [], actions: []} satisfies Interaction
    )).then(_ => setEditInteractionID(interactionID));
  };

  const editSignal = usePresent(useInteractionByID(value, editInteractionID));
  return (<div className="flex flex-col gap-1">
    <div className="flex flex-row gap-0.5 mr-2">
      <SectionHeader className="flex-1">Interactions</SectionHeader>
      <Button className="rounded-md" onClick={onAddInteraction}><FaPlus /> New Interaction</Button>
    </div>

    <div className="mx-2 rounded-md overflow-hidden">
      <InputList<Interaction, InteractionOperation>
        accept="legends/interaction"
        items={value}
        itemKey={getInteractionID}
        copy={copyInteraction}
        ListItem={InputInteraction} />
    </div>

    {editSignal && <InteractionEditor value={editSignal} onClose={() => setEditInteractionID(undefined)} />}
  </div>);
}

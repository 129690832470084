import {z} from "zod";
import {Dnd5eActionTemplateSegmentID} from "../dnd-5e-action-template-segment-i-d.ts";
import {
  BooleanOperation,
  booleanType,
  ConstantOperation,
  constantType,
  ListPropertyRef,
  ListType,
  ObjectType,
  Optional,
  PropertyRef,
  SetOperation,
  SetType,
  Type,
  ValueOperation,
  ValuePropertyRef,
  ValueType,
  ZodListOperation
} from "../../../../../../../types/index.ts";
import {Dnd5eAttribute} from "../../../character/dnd-5e-attribute.ts";
import {DiceExpression} from "../../../../../../../dice/index.ts";
import {Dnd5eActionTemplateSegment, Dnd5eActionTemplateSegmentOperation, dnd5eActionTemplateSegmentType} from "../dnd-5e-action-template-segment.ts";
import {MutableRef} from "#common/ref";
import {Dnd5eSavingThrowType} from "../../../dnd-5e-modifier/dnd-5e-saving-throw-modifier.ts";
import {
  Dnd5eActionTemplateSegmentSavingThrowModifier,
  Dnd5eActionTemplateSegmentSavingThrowModifierOperation,
  dnd5eActionTemplateSegmentSavingThrowModifierType
} from "./dnd-5e-action-template-segment-saving-throw-modifier.ts";

export const Dnd5eActionTemplateSegmentSavingThrow = z.lazy(() => z.object({
  actionSegmentID: Dnd5eActionTemplateSegmentID,
  savingThrowTypes: z.array(Dnd5eSavingThrowType),
  attribute: z.optional(Dnd5eAttribute),
  proficient: z.boolean(),
  expression: DiceExpression,
  modifiers: z.array(Dnd5eActionTemplateSegmentSavingThrowModifier),
  onSuccess: z.array(Dnd5eActionTemplateSegment),
  onFailure: z.array(Dnd5eActionTemplateSegment)
}));
export type Dnd5eActionTemplateSegmentSavingThrow = z.infer<typeof Dnd5eActionTemplateSegmentSavingThrow>;

export const Dnd5eActionTemplateSegmentSavingThrowOperation = z.lazy(() => z.discriminatedUnion("type", [
  z.object({type: z.literal("update-action-segment-i-d"), operations: z.array(ConstantOperation)}),
  z.object({type: z.literal("update-saving-throw-types"), operations: z.array(SetOperation(Dnd5eSavingThrowType))}),
  z.object({type: z.literal("update-attribute"), operations: z.array(ValueOperation<Optional<Dnd5eAttribute>, ConstantOperation>(z.optional(Dnd5eAttribute), ConstantOperation))}),
  z.object({type: z.literal("update-proficient"), operations: z.array(BooleanOperation)}),
  z.object({type: z.literal("update-expression"), operations: z.array(ValueOperation(DiceExpression, ConstantOperation))}),
  z.object({type: z.literal("update-modifiers"), operations: z.array(ZodListOperation(Dnd5eActionTemplateSegmentSavingThrowModifier, Dnd5eActionTemplateSegmentSavingThrowModifierOperation))}),
  z.object({type: z.literal("update-on-success"), operations: z.array(ZodListOperation(Dnd5eActionTemplateSegment, Dnd5eActionTemplateSegmentOperation))}),
  z.object({type: z.literal("update-on-failure"), operations: z.array(ZodListOperation(Dnd5eActionTemplateSegment, Dnd5eActionTemplateSegmentOperation))})
]));
export type Dnd5eActionTemplateSegmentSavingThrowOperation = z.infer<typeof Dnd5eActionTemplateSegmentSavingThrowOperation>;

export const dnd5eActionTemplateSegmentSavingThrowType: Type<Dnd5eActionTemplateSegmentSavingThrow, Dnd5eActionTemplateSegmentSavingThrowOperation> = new ObjectType(() => ({
  actionSegmentID: constantType,
  savingThrowTypes: new SetType<Dnd5eSavingThrowType>(),
  attribute: new ValueType(constantType),
  proficient: booleanType,
  expression: new ValueType(constantType),
  modifiers: new ListType(dnd5eActionTemplateSegmentSavingThrowModifierType),
  onSuccess: new ListType(dnd5eActionTemplateSegmentType),
  onFailure: new ListType(dnd5eActionTemplateSegmentType)
}));

export function Dnd5eActionTemplateSegmentSavingThrowSignals(valueRef: MutableRef<Dnd5eActionTemplateSegmentSavingThrow, Dnd5eActionTemplateSegmentSavingThrowOperation[]>) {
  return {
    savingThrowTypesRef: PropertyRef<Dnd5eActionTemplateSegmentSavingThrow, Dnd5eActionTemplateSegmentSavingThrowOperation, Dnd5eSavingThrowType[], SetOperation<Dnd5eSavingThrowType>>(
      value => value.savingThrowTypes,
      operations => [{type: "update-saving-throw-types", operations}]
    )(valueRef),
    attributeRef: ValuePropertyRef<Dnd5eActionTemplateSegmentSavingThrow, Dnd5eActionTemplateSegmentSavingThrowOperation, Optional<Dnd5eAttribute>, ConstantOperation>(
      value => value.attribute,
      operations => [{type: "update-attribute", operations}]
    )(valueRef),
    proficientRef: PropertyRef<Dnd5eActionTemplateSegmentSavingThrow, Dnd5eActionTemplateSegmentSavingThrowOperation, boolean, BooleanOperation>(
      value => value.proficient,
      operations => [{type: "update-proficient", operations}]
    )(valueRef),
    expressionRef: ValuePropertyRef<Dnd5eActionTemplateSegmentSavingThrow, Dnd5eActionTemplateSegmentSavingThrowOperation, DiceExpression, ConstantOperation>(
      value => DiceExpression.parse(value.expression),
      operations => [{type: "update-expression", operations}]
    )(valueRef),
    modifiersRef: ListPropertyRef<Dnd5eActionTemplateSegmentSavingThrow, Dnd5eActionTemplateSegmentSavingThrowOperation, Dnd5eActionTemplateSegmentSavingThrowModifier, Dnd5eActionTemplateSegmentSavingThrowModifierOperation>(
      value => value.modifiers,
      operations => [{type: "update-modifiers", operations}]
    )(valueRef),
    onSuccessRef: ListPropertyRef<Dnd5eActionTemplateSegmentSavingThrow, Dnd5eActionTemplateSegmentSavingThrowOperation, Dnd5eActionTemplateSegment, Dnd5eActionTemplateSegmentOperation>(
      value => value.onSuccess,
      operations => [{type: "update-on-success", operations}]
    )(valueRef),
    onFailureRef: ListPropertyRef<Dnd5eActionTemplateSegmentSavingThrow, Dnd5eActionTemplateSegmentSavingThrowOperation, Dnd5eActionTemplateSegment, Dnd5eActionTemplateSegmentOperation>(
      value => value.onFailure,
      operations => [{type: "update-on-failure", operations}]
    )(valueRef)
  }
}

import {ConstantOperation, constantType, ObjectType, PropertyRef, RichText, RichTextOperation, richTextType, Type} from "../../../../../../types/index.ts";
import {z} from "zod";
import {Dnd5eActionTemplateSegmentID} from "./dnd-5e-action-template-segment-i-d.ts";
import {MutableRef} from "#common/ref";

export const Dnd5eActionTemplateSegmentText: z.ZodType<{
  actionSegmentID: string,
  content: RichText
}> = z.object({
  actionSegmentID: Dnd5eActionTemplateSegmentID,
  content: RichText
});
export type Dnd5eActionTemplateSegmentText = z.infer<typeof Dnd5eActionTemplateSegmentText>;
export const Dnd5eActionTemplateSegmentTextOperation = z.discriminatedUnion("type", [
  z.object({type: z.literal("update-action-segment-i-d"), operations: z.array(ConstantOperation)}),
  z.object({type: z.literal("update-content"), operations: z.array(RichTextOperation)})
]);
export type Dnd5eActionTemplateSegmentTextOperation = z.infer<typeof Dnd5eActionTemplateSegmentTextOperation>;

export const dnd5eActionTemplateSegmentTextType: Type<Dnd5eActionTemplateSegmentText, Dnd5eActionTemplateSegmentTextOperation> = new ObjectType({
  actionSegmentID: constantType,
  content: richTextType
});

export function Dnd5eActionTemplateSegmentTextSignals(valueRef: MutableRef<Dnd5eActionTemplateSegmentText, Dnd5eActionTemplateSegmentTextOperation[]>) {
  return {
    contentRef: PropertyRef<Dnd5eActionTemplateSegmentText, Dnd5eActionTemplateSegmentTextOperation, RichText, RichTextOperation>(
      value => value.content,
      operations => [{type: "update-content", operations}]
    )(valueRef)
  }
}
import {z} from "zod";
import {NodeConditionID} from "./node-condition-i-d.ts";
import {ConstantOperation, constantType} from "../../../types/index.ts";

export const NodeConditionSelf = z.object({
  conditionID: NodeConditionID
});
export type NodeConditionSelf = z.infer<typeof NodeConditionSelf>;

export const NodeConditionSelfOperation = ConstantOperation;
export type NodeConditionSelfOperation = z.infer<typeof NodeConditionSelfOperation>;

export const nodeConditionSelfType = constantType;

import {InputList, InputListItemProps, useDragListItem} from "#lib/components/list/input-list.tsx";
import {useTypedRef} from "../../../../../../common/use-typed-ref.ts";
import React from "react";
import {Dnd5eProcess, Dnd5eProcessFn, Dnd5eProcessOperation, Dnd5eProcessTypes} from "common/legends/asset/sheet/dnd-5e/dnd-5e-action-definition/process/dnd-5e-process.ts";
import {Dnd5eProcessAdjustSpellSlotListItem} from "./dnd-5e-process-adjust-spell-slot-list-item.tsx";
import {MutableRef} from "common/ref";
import {ListOperation} from "common/types/generic/index.ts";
import {Dnd5eProcessAdjustCustomResourceListItem} from "./dnd-5e-process-adjust-custom-resource-list-item.tsx";
import {Dnd5eProcessConsumeItemListItem} from "./dnd-5e-process-consume-item.tsx";

function Dnd5eProcessListItem({item, remove, duplicate}: InputListItemProps<Dnd5eProcess, Dnd5eProcessOperation>){
  const [type, typedRef] = useTypedRef<Dnd5eProcessTypes>(item);
  const [dragHandlerRef, dragRefPreview] = useDragListItem("legends/dnd-5e-process", item, remove);
  if (type === "adjust-spell-slot") return <Dnd5eProcessAdjustSpellSlotListItem item={typedRef} remove={remove} duplicate={duplicate} dragHandlerRef={dragHandlerRef} dragRefPreview={dragRefPreview} />;
  if (type === "adjust-custom-resource") return <Dnd5eProcessAdjustCustomResourceListItem item={typedRef} remove={remove} duplicate={duplicate} dragHandlerRef={dragHandlerRef} dragRefPreview={dragRefPreview} />;
  if (type === "consume-item") return <Dnd5eProcessConsumeItemListItem item={typedRef} remove={remove} duplicate={duplicate} dragHandlerRef={dragHandlerRef} dragRefPreview={dragRefPreview} />;
  return <>{type}</>
}


export function Dnd5eProcessList({valueRef}: {
  valueRef: MutableRef<Dnd5eProcess[], ListOperation<Dnd5eProcess, Dnd5eProcessOperation>[]>
}) {
  return <InputList accept="legends/dnd-5e-trigger-process" items={valueRef} itemKey={Dnd5eProcessFn.getProcessID} copy={Dnd5eProcessFn.copyProcess} ListItem={Dnd5eProcessListItem} />
}

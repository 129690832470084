import {useSheetEffects} from "../../../panel/sheet/editor/dnd-5e-character/dnd-5e-action/use-sheet-effects.ts";
import {useSheetSignal} from "../../../common/sheet/use-sheet-signal.ts";
import {useSheetReference} from "../../../common/sheet/sheet-reference-context.ts";
import {useRefValue} from "#lib/signal/index.ts";
import {useMemo} from "react";
import {NodeCondition} from "common/legends/node/condition/node-condition.ts";
import {useCurrentTokenNode} from "./token-node-context.ts";
import {useControllerNodeID} from "./controller-node-context.ts";

export function useConditionsMet(conditions: NodeCondition[]) {
  const sheetEffects = useSheetEffects(useSheetSignal(useSheetReference()));

  const activeControllerID = useControllerNodeID();
  const currentTokenID = useCurrentTokenNode()?.id;

  return useRefValue(useMemo(() => sheetEffects.map(effects => {
    if (conditions.length === 0) return true;
    return conditions.every(condition => {
      switch (condition.type) {
        case "effect": {
          return effects
            .filter(effect => effect.effectID === condition.data.effectID && effect.enabled)
            .length > 0;
        }
        case "self": {
          return activeControllerID === currentTokenID;
        }
      }
    });
  }).distinct(), [conditions, sheetEffects, activeControllerID, currentTokenID]));
}

import {MutableRef} from "common/ref";
import {LightShapeGlobal, LightShapeGlobalOperation, LightShapeGlobalRef} from "common/legends/node/light/light-shape-global.ts";
import {InputGroup, InputNumber} from "#lib/components/index.ts";
import {useMemo} from "react";
import {ColorField} from "#lib/components/input/color-field.tsx";
import {Field, FieldLabel, SectionBody} from "#lib/components/panel-header.tsx";

export function LightShapeGlobalFields({valueRef}: {
  valueRef: MutableRef<LightShapeGlobal, LightShapeGlobalOperation[]>
}) {
  const {colorRef, intensityRef} = useMemo(() => LightShapeGlobalRef(valueRef), [valueRef]);

  return <SectionBody className="pt-2">
    <ColorField label="Color" value={colorRef} />
    <Field>
      <FieldLabel>Intensity</FieldLabel>
      <InputGroup className="mx-2 rounded-md">
        <InputNumber value={intensityRef} />
      </InputGroup>
    </Field>
  </SectionBody>
}
import React, {useMemo} from "react";
import {ButtonBar, InputGroup, InputGroupLabel} from "#lib/components/index.ts";
import {useObservable} from "#lib/qlab/index.ts";
import {useRollAbilityCheck} from "./dnd-5e-action/use-roll-ability-check.ts";
import {useSelectedNodeID} from "./use-selected-sheet.ts";
import {useSheetReference} from "../../../../common/sheet/sheet-reference-context.ts";
import {useGlobalFeatures} from "./dnd-5e-action/use-global-features.ts";
import {dnd5eSheetAbilityCheckModifiers} from "../dnd-5e/common/dnd-5e-sheet-ability-check-modifiers.ts";
import {Dice} from "common/dice/index.ts";
import {DiceButton} from "#lib/components/button/dice-button.tsx";
import {DND_5E_ABILITY_CHECK_TITLE} from "common/legends/asset/sheet/dnd-5e/dnd-5e-modifier/dnd-5e-ability-check-modifier.ts";
import {InputGroupIcon} from "#lib/components/input/input-group-icon.tsx";
import {FaClock} from "react-icons/fa";
import {useSheetSignal} from "../../../../common/sheet/use-sheet-signal.ts";
import {sheetVariablesSignal} from "common/legends/asset/sheet/dnd-5e/dnd-5e-variable/sheet-variable-signal.ts";
import {useRefValue} from "#lib/signal/index.ts";
import {dnd5eSheetAbilityCheckHasAdvantage, dnd5eSheetAbilityCheckHasDisadvantage} from "../dnd-5e/common/dnd-5e-sheet-ability-check-proficiency.ts";

export type InitiativeFieldProps = {};

export function InitiativeField({}: InitiativeFieldProps) {
  const sheet = useSheetSignal(useSheetReference());
  const globalEffects = useGlobalFeatures();
  const onClick = useRollAbilityCheck(useSelectedNodeID(), sheet);
  const hasAdvantage = useObservable(dnd5eSheetAbilityCheckHasAdvantage(sheet, globalEffects.observe, "initiative"), false, [sheet, globalEffects]);
  const hasDisadvantage = useObservable(dnd5eSheetAbilityCheckHasDisadvantage(sheet, globalEffects.observe, "initiative"), false, [sheet, globalEffects]);

  const skillModifier = useObservable(dnd5eSheetAbilityCheckModifiers(sheet, globalEffects.observe, "initiative"), undefined, [sheet, globalEffects]);
  const contextSignal = useMemo(() => sheetVariablesSignal(sheet, globalEffects), [sheet, globalEffects]);
  const contextValue = useRefValue(contextSignal);
  const [min, max] = useMemo(() => skillModifier ? Dice.getRollRange(skillModifier, contextValue) : [0, 0], [skillModifier, contextValue]);
  const resolvedSkillModifier = useMemo(() => skillModifier ? Dice.toResolvedExpression(skillModifier, contextValue) : undefined, [skillModifier, contextValue]);

  return (<ButtonBar>
    <InputGroup className="flex-1">
      <InputGroupIcon><FaClock /></InputGroupIcon>
      <InputGroupLabel className="flex-1 basis-0 shrink text-ellipsis overflow-hidden whitespace-nowrap text-lg">{DND_5E_ABILITY_CHECK_TITLE["initiative"]}</InputGroupLabel>
      <span className="shrink-0 basis-6 text-xs italic font-bold text-white/50 text-right whitespace-nowrap" title={resolvedSkillModifier ? resolvedSkillModifier : "0"}>
        {(min === max)
          ? min
          : `${min}-${max}`
        }
      </span>
    </InputGroup>
    <DiceButton hasAdvantage={hasAdvantage} hasDisadvantage={hasDisadvantage} onClick={ev => onClick("initiative", ev.shiftKey, ev.ctrlKey)} />
  </ButtonBar>);
}

import {ButtonBar, ExpandableLabel, IconButton, useToggle} from "#lib/components/index.ts";
import {SectionHeader} from "#lib/components/panel-header.tsx";
import {InputListItemProps} from "#lib/components/list/input-list.tsx";
import {Fieldset} from "#lib/components/fieldset/fieldset.tsx";
import {ConnectDragPreview, ConnectDragSource} from "react-dnd";
import {DragIndicator} from "#lib/components/tree-view/drag-indicator.tsx";
import React, {useMemo} from "react";
import {FaCopy, FaTrash} from "react-icons/fa";
import {
  Dnd5eActionTriggerActivationModifier,
  Dnd5eActionTriggerActivationModifierOperation,
  Dnd5eActionTriggerActivationModifierSignals
} from "common/legends/asset/sheet/dnd-5e/dnd-5e-action-definition/modifier/dnd-5e-action-trigger-activation-modifier.ts";
import {Dnd5eAddProcessButton} from "../process/dnd-5e-add-process-button.tsx";
import {Dnd5eProcessList} from "../process/dnd-5e-process-list.tsx";


export function Dnd5eActionTriggerActivationModifierListItem({item, remove, duplicate, dragRefPreview, dragHandlerRef}: InputListItemProps<Dnd5eActionTriggerActivationModifier, Dnd5eActionTriggerActivationModifierOperation> & {
  dragHandlerRef: ConnectDragSource | ConnectDragPreview,
  dragRefPreview: ConnectDragSource | ConnectDragPreview
}) {
  const [expanded, toggleExpanded] = useToggle(false);
  const {processesRef} = useMemo(() => Dnd5eActionTriggerActivationModifierSignals(item), [item]);
  return <div className="flex flex-col">
    <ButtonBar ref={dragRefPreview}>
      <IconButton ref={dragHandlerRef} title="Move"><DragIndicator /></IconButton>
      <ExpandableLabel expanded={expanded} toggleExpand={toggleExpanded}>
        <SectionHeader className="flex-1 bg-zinc-800/50" onClick={toggleExpanded}>Activation Trigger</SectionHeader>
      </ExpandableLabel>
      {duplicate && <IconButton title="Duplicate" onClick={duplicate}><FaCopy /></IconButton>}
      <IconButton variant="destructive" title="Remove Effect" onClick={remove}><FaTrash /></IconButton>
    </ButtonBar>

    {expanded && <Fieldset>
      <ButtonBar>
        <SectionHeader className="flex-1 bg-zinc-800/50">Processes</SectionHeader>
        <Dnd5eAddProcessButton valueRef={processesRef} />
      </ButtonBar>
      <Dnd5eProcessList valueRef={processesRef} />
    </Fieldset>}
  </div>
}
import {PointSignals, Transform, TransformOperation, TransformSignals} from "common/types/index.ts";
import {InputGroup, InputNumber} from "#lib/components/index.ts";
import {Fieldset} from "#lib/components/fieldset/fieldset.tsx";
import {useMemo} from "react";
import {MutableRef} from "common/ref";
import {Field, FieldLabel} from "#lib/components/panel-header.tsx";
import {InputGroupIcon} from "#lib/components/input/input-group-icon.tsx";

export type TransformOptionsProps = {
  value: MutableRef<Transform, TransformOperation[]>;
};

export function TransformFields({value}: TransformOptionsProps) {
  const {position, scale, rotation} = useMemo(() => TransformSignals(value), [value]);
  const {x, y} = useMemo(() => PointSignals(position), [position]);

  return <Fieldset>
    <Field>
      <FieldLabel>Position</FieldLabel>
      <div className="flex flex-row gap-0.5 mx-2 rounded-md overflow-hidden">
        <InputGroup className="flex-1">
          <InputGroupIcon>X</InputGroupIcon>
          <InputNumber value={x} />
        </InputGroup>
        <InputGroup className="flex-1">
          <InputGroupIcon>Y</InputGroupIcon>
          <InputNumber value={y} />
        </InputGroup>
      </div>
    </Field>
    <Field>
      <FieldLabel>Rotation</FieldLabel>
      <InputGroup className="mx-2 rounded-md overflow-hidden">
        <InputNumber value={rotation} />
      </InputGroup>
    </Field>
    <Field>
      <FieldLabel>Scale</FieldLabel>
      <InputGroup className="mx-2 rounded-md overflow-hidden">
        <InputNumber value={scale} />
      </InputGroup>
    </Field>
  </Fieldset>
}
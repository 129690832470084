import {
  MessageOperation,
  MessageValue,
  QLabMessage,
  QLabMessageChangeset,
  QLabMessageChangesetID,
  QLabMessageID,
  qlabMessageType,
  QLabResource,
  QLabResourceChangeset,
  QLabResourceChangesetID,
  QLabResourceID,
  qlabResourceType,
  QLabStore,
  QLabStoreChangeset,
  QLabStoreChangesetID,
  QLabStoreID,
  qlabStoreType,
  ResourceOperation,
  resourceType,
  ResourceValue,
  StoreOperation,
  storeType,
  StoreValue,
} from "common/qlab/index.ts";
import {applyAll, Optional, transformAll, Type, ValueOperation} from "common/types/index.ts";
import {UserID} from "common/legends/index.ts";

import {QLabInstanceListener} from "./q-lab-instance-listener.ts";
import {QLabClient, RequestMessagesParams} from "./index.ts";
import {ApplyAction} from "../apply-action.ts";
import {Unsubscribe} from "common/subscription";
import {decodeTime, ulid} from "ulid";
import {QLabStream} from "common/qlab/stream/q-lab-stream.ts";
import {ConnectionID} from "common/qlab/api/connection-id.ts";

const DEFAULT_STORE: TypedState<QLabStore, QLabStoreChangeset> = reduceServiceInitialized({value: undefined, revision: 0});
const DEFAULT_RESOURCE: TypedState<QLabResource, QLabResourceChangeset> = reduceServiceInitialized({value: undefined, revision: 0});
const DEFAULT_MESSAGE: TypedState<QLabMessage, QLabMessageChangeset> = reduceServiceInitialized({value: undefined, revision: 0});

type TypedState<Value, Changeset> = {
  service: Value;
  client: Value;
  outstandingClientChangesets: Changeset[];
  instance: Value;
  outstandingInstanceChangesets: Changeset[];
};
type InstanceState = {
  connected: boolean;
  store: TypedState<QLabStore, QLabStoreChangeset>;
  resources: {[resourceId: QLabResourceID]: TypedState<QLabResource, QLabResourceChangeset>};
  messages: {[messageId: QLabMessageID]: TypedState<QLabMessage, QLabMessageChangeset>};
  connections: {[connectionID: ConnectionID]: UserID};
  timeOffset: number
};

function reduceServiceInitialized<Value, Changeset>(value: Value): TypedState<Value, Changeset> {
  return {
    service: value,
    client: value,
    outstandingClientChangesets: [],
    instance: value,
    outstandingInstanceChangesets: []
  };
}

function reduceServiceChangesetApplied<Value extends {revision: number}, Changeset extends {revision: number}>(type: Type<Value, Changeset>, value: TypedState<Value, Changeset>, changesets: Changeset[]): TypedState<Value, Changeset> {
  let {service, outstandingClientChangesets, outstandingInstanceChangesets} = value;
  service = applyAll(type, service, changesets);
  try {
    [changesets, outstandingClientChangesets] = transformAll(type, changesets, outstandingClientChangesets, true);
    let client = applyAll(type, service, outstandingClientChangesets);
    try {
      [, outstandingInstanceChangesets] = transformAll(type, changesets, outstandingInstanceChangesets, true);
      let instance = applyAll(type, client, outstandingInstanceChangesets);
      return {service, client, outstandingClientChangesets, instance, outstandingInstanceChangesets};
    } catch (e) {
      console.error(e);
      return {service, client, outstandingClientChangesets, instance: client, outstandingInstanceChangesets: []};
    }
  } catch (e) {
    console.error(e);
    return {service, client: service, outstandingClientChangesets: [], instance: service, outstandingInstanceChangesets: []};
  }
}

function reduceClientChangesetApplied<Value extends {revision: number}, Changeset extends {revision: number}>(type: Type<Value, Changeset>, value: TypedState<Value, Changeset>, changesets: Changeset[]): TypedState<Value, Changeset> {
  let {service, client, outstandingClientChangesets, outstandingInstanceChangesets} = value;
  client = applyAll(type, client, changesets);
  try {
    outstandingClientChangesets = [...outstandingClientChangesets, ...changesets];
    [, outstandingInstanceChangesets] = transformAll(type, changesets, outstandingInstanceChangesets, true);
    let instance = applyAll(type, client, outstandingInstanceChangesets);
    return {service, client, outstandingClientChangesets, instance, outstandingInstanceChangesets};
  } catch (e) {
    console.error(e);
    return {service, client: service, instance: service, outstandingClientChangesets: [], outstandingInstanceChangesets: []};
  }
}

function reduceInstanceChangesetApplied<Value extends {revision: number}, Changeset extends {revision: number}>(type: Type<Value, Changeset>, value: TypedState<Value, Changeset>, changesets: Changeset[]): TypedState<Value, Changeset> {
  let {service, client, outstandingClientChangesets, instance, outstandingInstanceChangesets} = value;
  try {
    [, changesets] = transformAll(type, outstandingInstanceChangesets.filter(changeset => changeset.revision > changesets[0].revision), changesets, true);
    instance = applyAll(type, instance, changesets);
    outstandingInstanceChangesets = [...outstandingInstanceChangesets, ...changesets];
    return {service, client, outstandingClientChangesets, instance, outstandingInstanceChangesets};
  } catch (e) {
    console.error(e);
    return {service, client, outstandingClientChangesets, instance: client, outstandingInstanceChangesets: []};
  }
}

function reduceChangesetRejected<Value extends {revision: number}, Changeset extends {revision: number}>(type: Type<Value, Changeset>, value: TypedState<Value, Changeset>): TypedState<Value, Changeset> {
  let {service} = value;
  return {service, client: service, outstandingClientChangesets: [], instance: service, outstandingInstanceChangesets: []};
}

export class QLabInstanceObserver {
  constructor(
    private readonly userId: UserID,
    private readonly client: QLabClient,
    private readonly storeId: QLabStoreID
  ) {
  }

  private close: Unsubscribe | undefined;
  private instanceState: InstanceState | undefined = undefined;
  private listeners: QLabInstanceListener[] = [];
  private checkOutstandingChangesets() {
    if (this.instanceState === undefined) return;
    const hasOutstandingChangesets = (
      this.instanceState.store.outstandingInstanceChangesets.length > 0 ||
      this.instanceState.store.outstandingClientChangesets.length > 0 ||
      Object.values(this.instanceState.resources).findIndex(r =>
        r.outstandingInstanceChangesets.length > 0 || r.outstandingClientChangesets.length > 0
      ) !== -1);
    for (const listener of this.listeners) {
      listener.onUpdate({type: "outstanding-changesets", value: hasOutstandingChangesets});
    }
  }

  subscribe = (listener: QLabInstanceListener): Unsubscribe => {
    if (this.listeners.length === 0) {
      this.close = this.client.subscribe(this.storeId, {
        onInitial: (event) => {
          let timeOffset = 0;
          const lastMessageTime = Math.max(...Object.keys(event.messages).map(decodeTime));
          if (Number.isNaN(lastMessageTime)) {
            let delta = lastMessageTime - (Date.now() + timeOffset);
            if (delta > 0) timeOffset += delta;
          }

          this.instanceState = {
            connected: event.connected,
            store: {
              service: event.store.service,
              client: event.store.client,
              outstandingClientChangesets: event.store.outstandingClientChangesets,
              instance: event.store.client,
              outstandingInstanceChangesets: []
            },
            resources: Object.fromEntries(Object.entries(event.resources)
              .map(([resourceId, resource]) => [resourceId, {
                service: resource.service,
                client: resource.client,
                outstandingClientChangesets: resource.outstandingClientChangesets,
                instance: resource.client,
                outstandingInstanceChangesets: []
              }])
            ),
            messages: Object.fromEntries(Object.entries(event.messages)
              .map(([messageId, message]) => [messageId, {
                service: message.service,
                client: message.client,
                outstandingClientChangesets: message.outstandingClientChangesets,
                instance: message.client,
                outstandingInstanceChangesets: []
              }])),
            connections: event.connections,
            timeOffset
          };
          for (const listener of this.listeners) {
            listener.onInitial({
              connected: this.instanceState.connected,
              outstandingChangesets:(
                this.instanceState.store.outstandingInstanceChangesets.length > 0 ||
                this.instanceState.store.outstandingClientChangesets.length > 0 ||
                Object.values(this.instanceState.resources).findIndex(r =>
                  r.outstandingInstanceChangesets.length > 0 || r.outstandingClientChangesets.length > 0
                ) !== -1),
              store: this.instanceState.store.instance.value,
              resources: Object.fromEntries(Object.entries(this.instanceState.resources)
                .map(([resourceId, resource]) => [resourceId, resource.instance.value])
              ),
              messages: Object.fromEntries(Object.entries(this.instanceState.messages)
                .map(([messageId, message]) => [messageId, message.instance.value])
              ),
              connections: event.connections,
              timeOffset: this.instanceState.timeOffset
            });
          }
        },
        onUpdate: (event) => {
          if (!this.instanceState) return;
          switch (event.type) {
            case "SERVICE-CONNECTED": {
              this.instanceState.connected = true;
              for (const listener of this.listeners) {
                listener.onUpdate({type: "connected", value: true});
              }
              break;
            }
            case "SERVICE-DISCONNECTED": {
              this.instanceState.connected = false;
              for (const listener of this.listeners) {
                listener.onUpdate({type: "connected", value: false});
              }
              break;
            }
            case "SERVICE-STORE-INITIALIZED": {
              const store = reduceServiceInitialized<QLabStore, QLabStoreChangeset>(event.store);
              this.instanceState.store = store;
              for (const listener of this.listeners) {
                listener.onUpdate({type: "store", store: store.instance.value});
              }
              break;
            }
            case "SERVICE-STORE-CHANGESET-APPLIED": {
              let store = this.instanceState.store || DEFAULT_STORE;
              store = reduceServiceChangesetApplied(qlabStoreType, store, event.changesets);
              this.instanceState.store = store;
              for (const listener of this.listeners) {
                listener.onUpdate({type: "store", store: store.instance.value});
              }
              break;
            }
            case "STORE-CHANGESET-REJECTED": {
              let store = this.instanceState.store || DEFAULT_STORE;
              store = reduceChangesetRejected(qlabStoreType, store);
              this.instanceState.store = store;
              for (const listener of this.listeners) {
                listener.onUpdate({type: "store", store: store.instance.value});
              }
              break;
            }
            case "CLIENT-STORE-CHANGESET-APPLIED": {
              let store = this.instanceState.store || DEFAULT_STORE;
              store = reduceClientChangesetApplied(qlabStoreType, store, event.changesets);
              this.instanceState.store = store;
              for (const listener of this.listeners) {
                listener.onUpdate({type: "store", store: store.instance.value});
              }
              break;
            }
            case "SERVICE-RESOURCE-INITIALIZED": {
              const resource = reduceServiceInitialized<QLabResource, QLabResourceChangeset>(event.resource);
              this.instanceState.resources[event.resourceId] = resource;
              for (const listener of this.listeners) {
                listener.onUpdate({type: "resource", resourceId: event.resourceId, resource: resource.instance.value});
              }
              break;
            }
            case "SERVICE-RESOURCE-CHANGESET-APPLIED": {
              let resource = this.instanceState.resources[event.resourceId] || DEFAULT_RESOURCE;
              resource = reduceServiceChangesetApplied(qlabResourceType, resource, event.changesets);
              this.instanceState.resources[event.resourceId] = resource;
              for (const listener of this.listeners) {
                listener.onUpdate({type: "resource", resourceId: event.resourceId, resource: resource.instance.value});
              }
              break;
            }
            case "CLIENT-RESOURCE-CHANGESET-APPLIED": {
              let resource = this.instanceState.resources[event.resourceId] || DEFAULT_RESOURCE;
              resource = reduceClientChangesetApplied(qlabResourceType, resource, event.changesets);
              this.instanceState.resources[event.resourceId] = resource;
              for (const listener of this.listeners) {
                listener.onUpdate({type: "resource", resourceId: event.resourceId, resource: resource.instance.value});
              }
              break;
            }
            case "RESOURCE-CHANGESET-REJECTED": {
              let resource = this.instanceState.resources[event.resourceId] || DEFAULT_RESOURCE;
              resource = reduceChangesetRejected(qlabResourceType, resource);
              this.instanceState.resources[event.resourceId] = resource;
              for (const listener of this.listeners) {
                listener.onUpdate({type: "resource", resourceId: event.resourceId, resource: resource.instance.value});
              }
              break;
            }
            case "SERVICE-MESSAGE-INITIALIZED": {
              const message = reduceServiceInitialized<QLabMessage, QLabMessageChangeset>(event.message);
              if (!this.instanceState.messages) this.instanceState.messages = {};

              const delta = decodeTime(event.messageId) - (Date.now() + this.instanceState.timeOffset);
              if (delta > 0) this.instanceState.timeOffset += delta;

              this.instanceState.messages[event.messageId] = message;
              for (const listener of this.listeners) {
                listener.onUpdate({type: "message", messageId: event.messageId, message: message.instance.value});
              }
              break;
            }
            case "SERVICE-MESSAGE-CHANGESET-APPLIED": {
              const channel = this.instanceState.messages || {};
              let message = channel[event.messageId] || DEFAULT_MESSAGE;
              message = reduceServiceChangesetApplied(qlabMessageType, message, event.changesets);
              if (!this.instanceState.messages) this.instanceState.messages = {};
              this.instanceState.messages[event.messageId] = message;
              for (const listener of this.listeners) {
                listener.onUpdate({type: "message", messageId: event.messageId, message: message.instance.value});
              }
              break;
            }
            case "CLIENT-MESSAGE-CHANGESET-APPLIED": {
              const channel = this.instanceState.messages || {};
              let message = channel[event.messageId] || DEFAULT_MESSAGE;
              message = reduceClientChangesetApplied(qlabMessageType, message, event.changesets);
              if (!this.instanceState.messages) this.instanceState.messages = {};
              this.instanceState.messages[event.messageId] = message;
              for (const listener of this.listeners) {
                listener.onUpdate({type: "message", messageId: event.messageId, message: message.instance.value});
              }
              break;
            }
            case "MESSAGE-CHANGESET-REJECTED": {
              const channel = this.instanceState.messages || {};
              let message = channel[event.messageId] || DEFAULT_MESSAGE;
              message = reduceChangesetRejected(qlabMessageType, message);
              if (!this.instanceState.messages) this.instanceState.messages = {};
              this.instanceState.messages[event.messageId] = message;
              for (const listener of this.listeners) {
                listener.onUpdate({type: "message", messageId: event.messageId, message: message.instance.value});
              }
              break;
            }
            case "STREAM": {
              for (const listener of this.listeners) {
                listener.onUpdate({type: "stream", stream: event.stream});
              }
              break;
            }
            case "CONNECTION-ESTABLISHED": {
              this.instanceState.connections = {
                ...this.instanceState.connections,
                [event.connectionId]: event.userId
              };
              for (const listener of this.listeners) {
                listener.onUpdate({type: "connection", connectionId: event.connectionId, userId: event.userId});
              }
              break;
            }
            case "CONNECTION-CLOSED": {
              this.instanceState.connections = {...this.instanceState.connections};
              delete this.instanceState.connections[event.connectionId];
              for (const listener of this.listeners) {
                listener.onUpdate({type: "connection", connectionId: event.connectionId, userId: undefined});
              }
              break;
            }
          }
          this.checkOutstandingChangesets();
        },
        onError: (error) => {
          for (const listener of this.listeners) {
            listener.onError(error);
          }
        },
        onClose: () => {
          for (const listener of this.listeners) {
            listener.onClose();
          }
        }
      });
    }

    this.listeners.push(listener);
    if (this.instanceState) {
      listener.onInitial({
        connected: this.instanceState.connected,
        outstandingChangesets: (
          this.instanceState.store.outstandingInstanceChangesets.length > 0 ||
          this.instanceState.store.outstandingClientChangesets.length > 0 ||
          Object.values(this.instanceState.resources).findIndex(r =>
            r.outstandingInstanceChangesets.length > 0 || r.outstandingClientChangesets.length > 0
          ) !== -1),
        store: this.instanceState.store.instance.value,
        resources: Object.fromEntries(Object.entries(this.instanceState.resources)
          .map(([resourceId, resource]) => [resourceId, resource.instance.value])
        ),
        messages: Object.fromEntries(Object.entries(this.instanceState.messages)
          .map(([messageId, message]) => [messageId, message.instance.value])
        ),
        connections: this.instanceState.connections,
        timeOffset: this.instanceState.timeOffset
      });
    }

    return () => {
      const index = this.listeners.indexOf(listener);
      if (index !== -1) {
        this.listeners.splice(index, 1);
      }

      if (this.listeners.length === 0 && this.close) {
        this.close();
      }
    };
  }

  private getQLabStore = (): Promise<QLabStore> => {
    return new Promise<QLabStore>((resolve, reject) => {
      if (this.instanceState) {
        resolve((this.instanceState.store || DEFAULT_STORE).instance);
      } else {
        const close = this.client.subscribe(this.storeId, {
          onInitial: (event) => {
            resolve((event.store || DEFAULT_STORE).client);
            close();
          },
          onUpdate: () => {},
          onError: reject,
          onClose: () => {}
        });
      }
    });
  }

  getStore = (): Promise<Optional<StoreValue>> => {
    return this.getQLabStore().then(store => store.value);
  }

  applyToStore = async (fn: ApplyAction<Optional<StoreValue>, ValueOperation<Optional<StoreValue>, StoreOperation>[]>): Promise<Optional<StoreValue>> => {
    if (this.instanceState) {
      let qlabStore = this.instanceState.store || DEFAULT_STORE;
      const store = qlabStore.instance;
      const operations = typeof fn === "function" ? fn(store.value) : fn;
      if (operations.length === 0) return qlabStore.instance.value;
      const changesets: QLabStoreChangeset[] = [{
        changesetId: ulid() as QLabStoreChangesetID,
        userId: this.userId,
        revision: store.revision,
        operations
      }];

      qlabStore = reduceInstanceChangesetApplied(qlabStoreType, qlabStore, changesets);
      this.instanceState.store = qlabStore;
      this.checkOutstandingChangesets();
      return this.client.applyToStore(this.storeId, qlabStore.outstandingInstanceChangesets)
        .then(() => qlabStore.instance.value);
    } else {
      const store = await this.getQLabStore();
      const operations = typeof fn === "function" ? fn(store.value) : fn;
      if (operations.length === 0) return store.value;
      const changesets: QLabStoreChangeset[] = [{
        changesetId: ulid() as QLabStoreChangesetID,
        userId: this.userId,
        revision: store.revision,
        operations
      }];
      this.checkOutstandingChangesets();
      return this.client.applyToStore(this.storeId, changesets)
        .then(() => operations.reduce(storeType.apply, store.value));
    }
  }

  private getQLabResource = (resourceId: QLabResourceID): Promise<QLabResource> => {
    return new Promise<QLabResource>((resolve, reject) => {
      if (this.instanceState) {
        resolve((this.instanceState.resources[resourceId] || DEFAULT_RESOURCE).instance);
      } else {
        const close = this.client.subscribe(this.storeId, {
          onInitial: (event) => {
            resolve((event.resources[resourceId] || DEFAULT_RESOURCE).client);
            close();
          },
          onUpdate: () => {},
          onError: reject,
          onClose: () => {}
        });
      }
    })
  }
  getResource = async (resourceId: QLabResourceID): Promise<Optional<ResourceValue>> => {
    return this.getQLabResource(resourceId).then(resource => resource.value);
  }
  applyToResource = async (resourceId: QLabResourceID, fn: ApplyAction<Optional<ResourceValue>, ValueOperation<Optional<ResourceValue>, ResourceOperation>[]>): Promise<Optional<ResourceValue>> => {
    if (this.instanceState) {
      let qlabResource = this.instanceState.resources[resourceId] || DEFAULT_RESOURCE;
      const operations = typeof fn === "function" ? fn(qlabResource.instance.value) : fn;
      if (operations.length === 0) return qlabResource.instance.value;
      const changesets: QLabResourceChangeset[] = [{
        changesetId: ulid() as QLabResourceChangesetID,
        revision: qlabResource.instance.revision,
        userId: this.userId,
        operations
      }];

      qlabResource = reduceInstanceChangesetApplied(qlabResourceType, qlabResource, changesets);
      this.instanceState.resources[resourceId] = qlabResource;
      this.checkOutstandingChangesets();
      return this.client.applyToResource(this.storeId, resourceId, qlabResource.outstandingInstanceChangesets)
        .then(() => qlabResource.instance.value);
    } else {
      const resource = await this.getQLabResource(resourceId);
      const operations = typeof fn === "function" ? fn(resource?.value) : fn;
      if (operations.length === 0) return resource.value;
      const changesets: QLabResourceChangeset[] = [{
        changesetId: ulid() as QLabResourceChangesetID,
        revision: resource.revision,
        userId: this.userId,
        operations
      }];
      this.checkOutstandingChangesets();
      return this.client.applyToResource(this.storeId, resourceId, changesets)
        .then(() => operations.reduce(resourceType.apply, resource.value));
    }
  }

  private getQLabMessage = (messageId: QLabMessageID): Promise<QLabMessage> => {
    return new Promise<QLabMessage>((resolve, reject) => {
      if (this.instanceState) {
        resolve((this.instanceState.messages[messageId] || DEFAULT_MESSAGE).instance);
      } else {
        const close = this.client.subscribe(this.storeId, {
          onInitial: (event) => {
            resolve((event.messages[messageId] || DEFAULT_MESSAGE).client);
            close();
          },
          onUpdate: () => {},
          onError: reject,
          onClose: () => {}
        });
      }
    });
  };
  getMessage = (messageId: QLabMessageID): Promise<Optional<MessageValue>> => {
    return this.getQLabMessage(messageId).then(message => message.value);
  }
  applyToMessage = async (messageId: QLabMessageID, fn: ApplyAction<Optional<MessageValue>, ValueOperation<Optional<MessageValue>, MessageOperation>[]>): Promise<void> => {
    if (this.instanceState) {
      let qlabMessage = this.instanceState.messages[messageId] || DEFAULT_MESSAGE;

      const message = qlabMessage.instance;
      const operations = typeof fn === "function" ? fn(message.value) : fn;
      if (operations.length === 0) return;
      const changesets: QLabMessageChangeset[] = [{
        changesetId: ulid() as QLabMessageChangesetID,
        revision: message.revision,
        userId: this.userId,
        operations
      }];

      qlabMessage = reduceInstanceChangesetApplied(qlabMessageType, qlabMessage, changesets);
      this.instanceState.messages[messageId] = qlabMessage;
      this.checkOutstandingChangesets();
      return this.client.applyToMessage(this.storeId, messageId, qlabMessage.outstandingInstanceChangesets);
    } else {
      const message = await this.getQLabMessage(messageId);
      const operations = typeof fn === "function" ? fn(message.value) : fn;
      if (operations.length === 0) return;
      const changesets: QLabMessageChangeset[] = [{
        changesetId: ulid() as QLabMessageChangesetID,
        revision: message.revision,
        userId: this.userId,
        operations
      }];
      this.checkOutstandingChangesets();
      return this.client.applyToMessage(this.storeId, messageId, changesets);
    }
  }
  stream(stream: QLabStream) {
    return this.client.stream(this.storeId, stream);
  }

  requestMessages = (params: RequestMessagesParams): Promise<void> => {
    return this.client.requestMessages(this.storeId, params);
  }
}

import {ConstantOperation, Optional, ValueFn, ValueOperation} from "common/types/index.ts";
import {Select} from "#lib/components/index.ts";
import React from "react";
import {Dnd5eAbilityCheckType, DND_5E_ABILITY_CHECK_TITLE, DND_5E_ABILITY_CHECK_TYPES} from "common/legends/asset/sheet/dnd-5e/dnd-5e-modifier/dnd-5e-ability-check-modifier.ts";
import {MutableRef} from "common/ref";
import {useRefValue} from "#lib/signal/index.ts";

export function InputDnd5eOptionalAbilityCheck({valueRef}: {
  valueRef: MutableRef<Optional<Dnd5eAbilityCheckType>, ValueOperation<Optional<Dnd5eAbilityCheckType>, ConstantOperation>[]>;
}) {
  const value = useRefValue(valueRef);
  return <Select value={value} onChange={ev => {
    const value = ev.target.value;
    valueRef.apply(prev => ValueFn.set(prev,
      value === "" ? undefined : Dnd5eAbilityCheckType.parse(value)
    ));
  }}>
    <option></option>
    {DND_5E_ABILITY_CHECK_TYPES.map(abilityCheck => <option key={abilityCheck} value={abilityCheck}>{DND_5E_ABILITY_CHECK_TITLE[abilityCheck]}</option>)}
  </Select>;
}

import {Apply} from "#lib/qlab/index.ts";
import {EditorStackOperation, EditorStackTypes, EditorStackValue} from "./editor-stack.ts";
import React, {useMemo} from "react";
import "./editor-stack-view.css";
import {Optional} from "common/types/index.ts";
import {MutableRef} from "common/ref";
import {useTypedRef} from "../../../common/use-typed-ref.ts";
import {useSuspendPresent} from "../../../common/use-optional-signal.ts";
import {SettingsStackView} from "../../game-settings";
import {SceneNavigatorStackView} from "../../scene-navigator";
import {AssetNavigatorStackView} from "../../asset-navigator/asset-navigator-stack-view.tsx";
import {EditorPropertiesStackView} from "../../properties";
import {ChatStackView} from "../../chat";
import {EditorSheetView} from "../../sheet/editor/editor-sheet-view.tsx";
import {EditorTurnTrackerView} from "../../turn-tracker/editor";

export type StackItemContentProps = {
  value: EditorStackValue;
  apply: Apply<Optional<EditorStackValue>, EditorStackOperation[]>;
};

export const EditorStackView = React.memo(function EditorStackView({value, apply}: StackItemContentProps) {
  const [type, typeRef] = useTypedRef<EditorStackTypes>(useSuspendPresent(useMemo(() => new MutableRef<Optional<EditorStackValue>, EditorStackOperation[]>({
    value() {return value},
    observe: (observer) => {
      observer.next(value);
      return () => {}
    },
    apply: apply
  }), [value, apply])));

  if (type === "settings") return <div className="editor-stack-view"><SettingsStackView /></div>;
  else if (type === "scene-navigator") return <div className="editor-stack-view"><SceneNavigatorStackView valueRef={typeRef} /></div>;
  else if (type === "character-navigator") return <div className="editor-stack-view"><AssetNavigatorStackView valueRef={typeRef} /></div>;
  else if (type === "properties") return <div className="editor-stack-view"><EditorPropertiesStackView valueRef={typeRef} /></div>;
  else if (type === "chat") return <div className="editor-stack-view"><ChatStackView /></div>
  else if (type === "sheet") return <div className="editor-stack-view"><EditorSheetView valueRef={typeRef} /></div>
  else if (type === "turn-tracker") return <div className="editor-stack-view"><EditorTurnTrackerView /></div>
  else return <>Not Found</>
});

import {useObservable} from "#lib/qlab/index.ts";
import {pipe} from "common/pipe";
import {distinct, map} from "common/observable";
import {NodeId} from "common/legends/index.ts";
import {useSelectedNodeIDs} from "./selected-node-i-ds.ts";

export function useIsSelected(nodeID: NodeId) {
  const selectedNodeIDs = useSelectedNodeIDs();
  return useObservable(pipe(
    selectedNodeIDs.observe,
    map(nodeIDs => nodeIDs.map(node => node.nodeId).includes(nodeID)),
    distinct()
  ), false, [selectedNodeIDs.observe, nodeID]);
}

import {
  Dnd5eActionTemplateConditionModifier,
  Dnd5eActionTemplateConditionModifierOperation,
  Dnd5eActionTemplateConditionModifierSignals
} from "common/legends/asset/sheet/dnd-5e/dnd-5e-action-definition/modifier/dnd-5e-action-template-condition-modifier.ts";
import {SectionHeader} from "#lib/components/panel-header.tsx";
import {ButtonBar, ExpandableLabel, IconButton, InputGroup, InputGroupLabel, useToggle} from "#lib/components/index.ts";
import {Fieldset} from "#lib/components/fieldset/fieldset.tsx";
import {InputListItemProps} from "#lib/components/list/input-list.tsx";
import {InputEffectID} from "../action-template-segment/input-effect-i-d.tsx";
import React, {useMemo} from "react";
import {useConditionEffectIDRef} from "../action-template-segment/roll/use-condition-effect-i-d-ref.ts";
import {Dnd5eActionTemplateModifiersView} from "./dnd-5e-action-template-modifiers-view.tsx";
import {FaCopy, FaTrash} from "react-icons/fa";
import {ConnectDragPreview, ConnectDragSource} from "react-dnd";
import {DragIndicator} from "#lib/components/tree-view/drag-indicator.tsx";

export function Dnd5eActionTemplateConditionModifierListItem({item, remove, duplicate, dragHandlerRef, dragRefPreview}: InputListItemProps<Dnd5eActionTemplateConditionModifier, Dnd5eActionTemplateConditionModifierOperation> & {
  dragHandlerRef: ConnectDragSource | ConnectDragPreview,
  dragRefPreview: ConnectDragSource | ConnectDragPreview
}) {
  const [expanded, toggleExpanded] = useToggle(false);
  const {conditionRef, modifiersRef} = useMemo(() => Dnd5eActionTemplateConditionModifierSignals(item), [item])
  const effectIDRef = useConditionEffectIDRef(conditionRef);

  return <div className="flex flex-col">
    <ButtonBar ref={dragRefPreview}>
      <IconButton ref={dragHandlerRef} title="Move"><DragIndicator /></IconButton>
      <ExpandableLabel expanded={expanded} toggleExpand={toggleExpanded}>
        <SectionHeader className="bg-zinc-800/50 flex-1" onClick={toggleExpanded}>Condition</SectionHeader>
      </ExpandableLabel>
      {duplicate && <IconButton title="Duplicate" onClick={duplicate}><FaCopy /></IconButton>}
      <IconButton variant="destructive" title="Remove Effect" onClick={remove}><FaTrash /></IconButton>
    </ButtonBar>

    {expanded && <Fieldset>
      <InputGroup>
        <InputGroupLabel>Condition</InputGroupLabel>
        <InputGroupLabel>Effect</InputGroupLabel>
        <InputEffectID valueRef={effectIDRef} />
      </InputGroup>

      <Dnd5eActionTemplateModifiersView valueRef={modifiersRef} />
    </Fieldset>}
  </div>
}
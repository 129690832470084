import React, {useMemo, useState} from "react";
import {HSLA, Point, Transform} from "common/types/generic/index.ts";
import {LightShapeFreeform} from "common/legends/node/light/light-shape-freeform.ts";
import {Vector2} from "common/math/vector/vector2.ts";
import {Spline, SplineFn} from "common/types/generic/spline/index.ts";
import {Framebuffer} from "../../../shader/framebuffer.tsx";
import {WallMaskFramebuffer} from "../../../shader/wall-mask-framebuffer.tsx";
import {ModelProvider, useModel} from "../../../context/pvm-context.ts";
import {AreaPolyLightShader} from "../../../shader/light/area-poly-light-shader.tsx";
import {DSFLineLightShader} from "../../../shader/light/d-s-f-line-light-shader.tsx";
import {WallTintShader} from "../../../shader/wall-tint-shader.tsx";
import {BlendFuncSeperate} from "#lib/gl-react/component/opengl/blend-func-seperate.tsx";
import {BlendEquationSeparate} from "#lib/gl-react/component/opengl/blend-equation-separate.tsx";
import {TextureShader} from "../../../shader/texture-shader.tsx";
import {TextureMaskShader} from "../../../shader/texture-mask-shader.tsx";
import {LightTextureShader} from "./light-texture-shader.tsx";


export function LightShapeFreeformAreaLightPass({area, color, origin, falloff, falloffStrength}: {
  area: Spline,
  color: HSLA,
  origin: Point,
  falloff: number,
  falloffStrength: number
}) {
  const center = useMemo(() => {
    const splineOrigin = Vector2.subtract(SplineFn.getSplineOrigin(area), origin);
    const splineSize = Vector2.multiply(SplineFn.getSplineSize(area), 0.5);
    return Vector2.add(splineOrigin, splineSize);
  }, [area, origin]);
  const model = useModel();
  const areaModel = useMemo(() => Transform.divide({position: center, scale: 1, rotation: 0}, model), [model, center]);
  const [lightTexture, setLightTexture] = useState<WebGLTexture | null>(null);
  const [wallTexture, setWallTexture] = useState<WebGLTexture | null>(null);
  return <binder>
    <ModelProvider value={areaModel}>
      <Framebuffer ref={setLightTexture}>
        <BlendEquationSeparate rgb={WebGL2RenderingContext.FUNC_ADD} alpha={WebGL2RenderingContext.MAX}>
          <BlendFuncSeperate
            srcRGB={WebGL2RenderingContext.SRC_COLOR} dstRGB={WebGL2RenderingContext.ONE_MINUS_SRC_COLOR}
            srcAlpha={WebGL2RenderingContext.SRC_ALPHA} dstAlpha={WebGL2RenderingContext.DST_ALPHA}>
              <AreaPolyLightShader spline={area} origin={center} color={color}/>
              <DSFLineLightShader spline={area} origin={center} color={color} fallout={falloff} falloffStrength={falloffStrength}/>
              <WallTintShader origin={[0, 0]}/>
          </BlendFuncSeperate>
        </BlendEquationSeparate>
      </Framebuffer>

      <WallMaskFramebuffer ref={setWallTexture} origin={[0, 0]}/>
    </ModelProvider>

    {lightTexture && wallTexture && <BlendEquationSeparate rgb={WebGL2RenderingContext.MAX} alpha={WebGL2RenderingContext.MAX}>
      <BlendFuncSeperate srcRGB={WebGL2RenderingContext.ONE} dstRGB={WebGL2RenderingContext.ONE} srcAlpha={WebGL2RenderingContext.ONE} dstAlpha={WebGL2RenderingContext.ONE}>
        <TextureMaskShader texture={lightTexture} mask={wallTexture}/>
      </BlendFuncSeperate>
    </BlendEquationSeparate>}
  </binder>;
}

export function LightShapeFreeformLightPass({value, origin}: {
  value: LightShapeFreeform,
  origin: Point
}) {
  const color = [value.color[0], value.color[1], value.color[2], Math.sqrt(value.intensity)] as HSLA
  const [texture, setTexture] = useState<WebGLTexture | null>(null);

  return <binder>
    <Framebuffer ref={setTexture}>
        {value.areas.map((area, index) => <LightShapeFreeformAreaLightPass key={index} area={area} color={color} origin={origin} falloff={value.falloff} falloffStrength={value.falloffStrength} />)}
    </Framebuffer>

    {texture && <LightTextureShader>
        <TextureShader texture={texture}/>
    </LightTextureShader>}
  </binder>
}

import React, {PropsWithChildren, useCallback, useContext, useMemo} from "react";
import {useRenderingContext} from "#lib/gl-react/index.ts";

const BlendEquationContext = React.createContext<{rgb: GLenum, alpha: GLenum}>({
  rgb: WebGL2RenderingContext.FUNC_ADD,
  alpha: WebGL2RenderingContext.FUNC_ADD
});

export function BlendEquationSeparate({children, rgb, alpha}: PropsWithChildren<{
  rgb: GLenum,
  alpha: GLenum
}>) {
  const context = useRenderingContext();
  const onBind = useCallback(() => {
    context.blendEquationSeparate(rgb, alpha);
  }, [context, rgb, alpha]);

  const previous = useContext(BlendEquationContext);
  const onUnbind = useCallback(() => {
    context.blendEquationSeparate(previous.rgb, previous.alpha);
  }, [previous]);

  return <BlendEquationContext.Provider value={useMemo(() => ({rgb, alpha}), [rgb, alpha])}>
    <binder onBind={onBind} onUnbind={onUnbind}>
      {children}
    </binder>
  </BlendEquationContext.Provider>
}

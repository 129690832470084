import {MutableRef} from "common/ref";
import {Dnd5eAttribute, DND_5E_ATTRIBUTE_TITLE, DND_5E_ATTRIBUTES} from "common/legends/asset/index.ts";
import {ConstantOperation, Optional, ValueFn, ValueOperation} from "common/types/generic/index.ts";
import {Select} from "#lib/components/index.ts";
import React, {ChangeEvent} from "react";
import {useRefValue} from "#lib/signal/index.ts";

export function InputOptionalAttribute({valueRef}: {valueRef: MutableRef<Optional<Dnd5eAttribute>, ValueOperation<Optional<Dnd5eAttribute>, ConstantOperation>[]>}) {
  const value = useRefValue(valueRef);
  return (<Select value={value ?? ""} onChange={(ev: ChangeEvent<HTMLSelectElement>) => {
    const next = ev.target.value as Dnd5eAttribute;
    const isAttribute = DND_5E_ATTRIBUTES.includes(next as Dnd5eAttribute);
    valueRef.apply(prev => ValueFn.set(prev, isAttribute ? next : undefined));
  }}>
    <option value="">N/A</option>
    {DND_5E_ATTRIBUTES.map(attribute => <option key={attribute} value={attribute}>{DND_5E_ATTRIBUTE_TITLE[attribute]}</option>)}
  </Select>);
}

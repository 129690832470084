import {Transform, TransformOperation} from "common/types/index.ts";
import {TransformFields} from "./transform-fields.tsx";
import {ExpandableLabel, useToggle} from "#lib/components/index.ts";
import React from "react";
import {MutableRef} from "common/ref";
import {SectionHeader} from "#lib/components/panel-header.tsx";

export type TransformFieldProps = {
  value: MutableRef<Transform, TransformOperation[]>;
};

export function InputTransform({value}: TransformFieldProps) {
  const [expand, toggleExpand] = useToggle(false);
  return (<div className="mx-2 rounded-md overflow-hidden gap-0 bg-zinc-800/50">
    <ExpandableLabel className="w-full" expanded={expand} toggleExpand={toggleExpand}>
      <SectionHeader onClick={toggleExpand} className="flex-1 cursor-pointer bg-zinc-800/50">Transform</SectionHeader>
    </ExpandableLabel>
    {expand && <TransformFields value={value} />}
  </div>);
}
import {InputListItemProps} from "#lib/components/list/input-list.tsx";
import {
  Dnd5eActionTemplateSegmentAbilityCheck,
  Dnd5eActionTemplateSegmentAbilityCheckOperation,
  Dnd5eActionTemplateSegmentAbilityCheckSignals
} from "common/legends/asset/sheet/dnd-5e/dnd-5e-action-definition/segment/ability-check/dnd-5e-action-template-segment-ability-check.ts";
import {ConnectDragPreview, ConnectDragSource} from "react-dnd";
import React, {useMemo} from "react";
import {ButtonBar, ExpandableLabel, IconButton, InputGroup, InputGroupLabel, useToggle} from "#lib/components/index.ts";
import {DragIndicator} from "#lib/components/tree-view/drag-indicator.tsx";
import {SectionHeader} from "#lib/components/panel-header.tsx";
import {FaCopy, FaTrash} from "react-icons/fa";
import {Fieldset} from "#lib/components/fieldset/fieldset.tsx";
import {InputDiceExpression} from "../../../../dice-expression/input-dice-expression.tsx";
import {Dnd5eActionTemplateSegmentsView} from "../../dnd-5e-action-template-segments-view.tsx";
import {Dnd5eActionTemplateSegmentAbilityCheckModifiersView} from "./dnd-5e-action-template-segment-ability-check-modifiers-view.tsx";
import {Dnd5eActionTemplateAddAbilityCheckModifierButton} from "./dnd-5e-action-template-add-ability-check-modifier-button.tsx";
import {Dnd5eActionTemplateAddSegmentButton} from "../dnd-5e-action-template-add-segment-button.tsx";
import {InputDnd5eAbilityChecks} from "../../../dnd-5e-modifier/input-dnd-5e-ability-checks.tsx";
import {InputDnd5eOptionalAbilityCheck} from "../../../dnd-5e-modifier/input-dnd-5e-optional-ability-check.tsx";

export function Dnd5eActionTemplateSegmentAbilityCheckView({item, remove, duplicate, dragRefPreview, dragHandlerRef}: InputListItemProps<Dnd5eActionTemplateSegmentAbilityCheck, Dnd5eActionTemplateSegmentAbilityCheckOperation> & {
  dragHandlerRef: ConnectDragSource | ConnectDragPreview,
  dragRefPreview: ConnectDragSource | ConnectDragPreview
}) {
  const {abilityCheckRef, abilityCheckTypesRef, expressionRef, modifiersRef, onFailureRef, onSuccessRef} = useMemo(() => Dnd5eActionTemplateSegmentAbilityCheckSignals(item), [item])
  const [expanded, toggleExpanded] = useToggle(false);

  const [onFailureExpanded, toggleOnFailureExpanded, setOnFailureExpanded] = useToggle(true);
  const [onSuccessExpanded, toggleOnSuccessExpanded, setOnSuccessExpanded] = useToggle(true);
  const [modifiersExpanded, toggleModifiersExpanded, setModifiersExpanded] = useToggle(true);

  return <div role="list-item" className="flex flex-col">
    <ButtonBar ref={dragRefPreview}>
      <IconButton ref={dragHandlerRef} title="Move"><DragIndicator /></IconButton>
      <ExpandableLabel expanded={expanded} toggleExpand={toggleExpanded}>
        <SectionHeader className="flex-1 bg-zinc-800/50 cursor-pointer" onClick={toggleExpanded}>Ability Check</SectionHeader>
      </ExpandableLabel>
      {duplicate && <IconButton title="Duplicate" onClick={duplicate}><FaCopy /></IconButton>}
      <IconButton variant="destructive" title="Remove Effect" onClick={remove}><FaTrash /></IconButton>
    </ButtonBar>
    {expanded && <Fieldset>
      <InputGroup>
        <InputGroupLabel>Ability Check</InputGroupLabel>
      </InputGroup>
      <InputGroup>
        <InputDnd5eOptionalAbilityCheck valueRef={abilityCheckRef} />
      </InputGroup>

      <InputGroup>
        <InputGroupLabel>Expression</InputGroupLabel>
        <InputDiceExpression value={expressionRef} />
      </InputGroup>

      <InputGroup>
        <InputGroupLabel>Contested Ability Check</InputGroupLabel>
      </InputGroup>

      <InputDnd5eAbilityChecks item={abilityCheckTypesRef} />

      <div className="flex flex-col">
        <ExpandableLabel expanded={modifiersExpanded} toggleExpand={toggleModifiersExpanded}>
            <SectionHeader onClick={toggleModifiersExpanded} className="flex-1 bg-zinc-800/50 cursor-pointer">Modifiers</SectionHeader>
          <Dnd5eActionTemplateAddAbilityCheckModifierButton valueRef={modifiersRef} onAddedModifier={() => setModifiersExpanded(true)} />
        </ExpandableLabel>
        {modifiersExpanded && <Fieldset>
          <Dnd5eActionTemplateSegmentAbilityCheckModifiersView valueRef={modifiersRef} />
        </Fieldset>}
      </div>
      <div className="flex flex-col">
        <ExpandableLabel expanded={onFailureExpanded} toggleExpand={toggleOnFailureExpanded}>
          <SectionHeader onClick={toggleModifiersExpanded} className="flex-1 bg-zinc-800/50 cursor-pointer">On Failure</SectionHeader>
          <Dnd5eActionTemplateAddSegmentButton valueRef={onFailureRef}  onAddedSegment={() => setOnFailureExpanded(true)} />
        </ExpandableLabel>
        {onFailureExpanded && <Fieldset>
          <Dnd5eActionTemplateSegmentsView valueRef={onFailureRef}/>
        </Fieldset>}
      </div>
      <div className="flex flex-col">
        <ExpandableLabel expanded={onSuccessExpanded} toggleExpand={toggleOnSuccessExpanded}>
          <SectionHeader onClick={toggleModifiersExpanded} className="flex-1 bg-zinc-800/50 cursor-pointer">On Success</SectionHeader>
          <Dnd5eActionTemplateAddSegmentButton valueRef={onSuccessRef} onAddedSegment={() => setOnSuccessExpanded(true)} />
        </ExpandableLabel>
        {onSuccessExpanded && <Fieldset>
          <Dnd5eActionTemplateSegmentsView valueRef={onSuccessRef}/>
        </Fieldset>}
      </div>
    </Fieldset>}
  </div>;
}

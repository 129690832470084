import {Dnd5eStatBlockSignals} from "common/legends/asset/sheet/dnd-5e/dnd-5e-stat-block/index.ts";
import {ConstantOperation, MapFn, Optional, ValueFn, ValueOperation} from "common/types/index.ts";
import React, {useCallback, useMemo} from "react";
import {ButtonBar, Input, InputGroup, InputGroupLabel, InputNumber} from "#lib/components/index.ts";
import {BaseComponent} from "#lib/components/BaseComponent.tsx";
import {SheetReference} from "../../../../common/sheet/sheet-reference.ts";
import {SheetNameField} from "../dnd-5e-character/sheet-name-field.tsx";
import {ArmorClass} from "../dnd-5e-character/armor-class.tsx";
import {HitPoints} from "../dnd-5e-character/hit-points.tsx";
import {HitDiceInput} from "../dnd-5e-character/hit-dice-input.tsx";
import {MovementSpeeds} from "../dnd-5e-character/movement-speeds.tsx";
import {Attributes} from "./attributes.tsx";
import {SavingThrowProficienciesView} from "./saving-throw-proficiencies-view.tsx";
import {SkillProficienciesView} from "./skill-proficiencies-view.tsx";
import {PanelHeader} from "#lib/components/panel-header.tsx";
import {useSheetSignal, useTypedSheetSignal} from "../../../../common/sheet/use-sheet-signal.ts";
import {Dnd5eDamageVulnerabilitiesView} from "../dnd-5e-damage-vulnerabilities-view.tsx";
import {Dnd5eDamageImmunitiesView} from "../dnd-5e-damage-immunities-view.tsx";
import {Dnd5eDamageResistancesView} from "../dnd-5e-damage-resistances-view.tsx";
import {useSuspendPresent} from "../../../../common/use-optional-signal.ts";
import {useRefValue} from "#lib/signal/index.ts";
import {InputDnd5eSpells} from "../dnd-5e/dnd-5e-spell/input-dnd-5e-spells.tsx";
import {Dnd5eSpellLevel, DND_5E_SPELL_LEVEL} from "common/legends/index.ts";
import {getMaxSpellSlotsBySpellCasterLevel} from "common/legends/asset/sheet/dnd-5e/character/get-max-spell-slots-by-classes.ts";
import {InputGroupIcon} from "#lib/components/input/input-group-icon.tsx";
import {InputDnd5eFeatures} from "../dnd-5e/dnd-5e-feature/input-dnd-5e-features.tsx";
import {Dnd5eSheetEffectsView} from "../dnd-5e-character/dnd-5e-character-effects.tsx";
import {MutableRef, Ref} from "common/ref";

export function Dnd5eStatBlockView({reference, pinned}: {
  reference: Ref<Optional<SheetReference>>;
  pinned?: MutableRef<Optional<SheetReference>, ValueOperation<Optional<SheetReference>, ConstantOperation>[]>;
}) {
  const sheetRef = useSheetSignal(reference);
  const value = useSuspendPresent(useTypedSheetSignal("dnd-5e-stat-block", reference));
  const {name, proficiencyBonus, attributes, features, hitDice, movementSpeeds, spellCasterLevel, spells, spellSlots} = useMemo(() => Dnd5eStatBlockSignals(value), [value]);

  const spellSlotsValue = useRefValue(spellSlots);
  const setSpellSlots = useCallback((level: Dnd5eSpellLevel, value: number) => {
    spellSlots.apply(prev => {
      return MapFn.apply(level, ValueFn.set(prev[level] || 0, value));
    });
  }, [spellSlots]);

  const casterLevel = useRefValue(spellCasterLevel);

  return <div className="tab-content flex flex-col gap-2 backdrop-blur-sm pointer-events-auto py-2">
    <BaseComponent><SheetNameField value={name} reference={reference} pinned={pinned} /></BaseComponent>
    <div className="flex flex-col gap-1">
      <ArmorClass />
      <HitPoints sheetRef={sheetRef} />
      <BaseComponent><HitDiceInput value={hitDice} /></BaseComponent>
      <MovementSpeeds value={movementSpeeds} />
    </div>
    <Attributes value={attributes} />
    <BaseComponent>
      <InputGroup>
        <InputGroupIcon />
        <InputGroupLabel>Proficiency Bonus</InputGroupLabel>
        <InputNumber value={proficiencyBonus} />
      </InputGroup>
    </BaseComponent>
    <SavingThrowProficienciesView />
    <SkillProficienciesView />

    <div className="flex flex-col gap-1">
      <Dnd5eDamageImmunitiesView />
      <Dnd5eDamageResistancesView />
      <Dnd5eDamageVulnerabilitiesView />
    </div>

    <BaseComponent>
      <InputGroup>
        <InputGroupIcon />
        <InputGroupLabel>Spell Caster Level</InputGroupLabel>
        <InputNumber min={0} max={20} value={spellCasterLevel} />
      </InputGroup>
    </BaseComponent>

    {casterLevel > 0 && <BaseComponent>
      <PanelHeader>Spell Slots</PanelHeader>
      {DND_5E_SPELL_LEVEL.filter(level => level !== "cantrip")
        .filter(level => getMaxSpellSlotsBySpellCasterLevel(casterLevel, level) !== 0)
        .map(level => <ButtonBar key={level}>
            <InputGroup className="basis-40">
              <InputGroupLabel>Level {level}</InputGroupLabel>
            </InputGroup>
            <InputGroup className="basis-20">
              <Input title="Spells Slots" type="number" className="text-right" value={spellSlotsValue[level] || 0} onChange={ev => setSpellSlots(level, Number.parseInt(ev.target.value) || 0)} />
            </InputGroup>
            <InputGroup><InputGroupLabel>/</InputGroupLabel></InputGroup>
            <InputGroup className="basis-20">
              <Input readOnly title="Max Spell Slots" type="number" className="text-left" value={getMaxSpellSlotsBySpellCasterLevel(casterLevel, level)} />
            </InputGroup>
            <InputGroup className="flex-1">
              <InputGroupLabel/>
            </InputGroup>
          </ButtonBar>
        )}
    </BaseComponent>}
    {casterLevel > 0 && <InputDnd5eSpells value={spells} />}

    <BaseComponent>
      <InputDnd5eFeatures value={features} />
    </BaseComponent>

    <BaseComponent>
      <Dnd5eSheetEffectsView />
    </BaseComponent>
  </div>
}

import React, {ForwardedRef, HTMLAttributes, PropsWithChildren, RefObject, useEffect, useRef} from "react";
import {WebGL2Container, WebGL2ReactReconciler} from "../reconciler/index.ts";
import {OpaqueRoot} from "react-reconciler";
import {RenderingContextProvider} from "../hooks/index.ts";
import {ResolutionProvider} from "../hooks/resolution-context.ts";

export type WebGL2CanvasProps = HTMLAttributes<HTMLCanvasElement> & PropsWithChildren<{
  width: number | string;
  height: number | string;
  options?: WebGLContextAttributes;
}>;

export type WebGL2CanvasState = {resolution: [number, number]};

class BaseWebGL2Canvas extends React.Component<WebGL2CanvasProps & {forwardedRef: RefObject<HTMLCanvasElement>}, WebGL2CanvasState> {
  private root?: OpaqueRoot;
  private container?: WebGL2Container;
  private resizeObserver?: ResizeObserver;

  constructor(props: WebGL2CanvasProps & {forwardedRef: RefObject<HTMLCanvasElement>}) {
    super(props);
    this.state = {resolution: [0, 0]};
  }

  componentDidMount(): void {
    if (this.props.forwardedRef.current) {
      const canvas = this.props.forwardedRef.current;
      // canvas.width = canvas.clientWidth;
      // canvas.height = canvas.clientHeight;
      this.setState({resolution: [canvas.clientWidth, canvas.clientHeight]});
      this.resizeObserver = new ResizeObserver(() => {
        this.setState({resolution: [canvas.clientWidth, canvas.clientHeight]});
      });
      this.resizeObserver.observe(canvas, {box: "content-box"});

      canvas.style.display = "block";
      const context = canvas.getContext("webgl2", {
        premultipliedAlpha: false,
        alpha: false,
        antialias: false,
        ...this.props.options
      });
      if (context === null) {
        throw new Error("Cannot create WebGL2 context.");
      }

      this.container = new WebGL2Container(context);
      this.root = WebGL2ReactReconciler.createContainer(
        this.container,
        0,
        null,
        true,
        null,
        "webgl2",
        console.error,
        null
      );
    }
  }

  componentDidUpdate(): void {
    this.updateContainer();
  }

  private updateContainer() {
    if (this.root && this.container) {
      WebGL2ReactReconciler.updateContainer(<RenderingContextProvider value={this.container.context}>
        <ResolutionProvider value={this.state.resolution}>
          {this.props.children}
        </ResolutionProvider>
      </RenderingContextProvider>, this.root, null);
    }
  }

  private animationFrameHandler: number = -1;

  componentWillUnmount(): void {
    cancelAnimationFrame(this.animationFrameHandler);
    if (this.resizeObserver) {
      this.resizeObserver.disconnect();
      this.resizeObserver = undefined;
    }
    if (this.root) {
      WebGL2ReactReconciler.updateContainer(null, this.root, null);
      this.root = undefined;
    }
  }

  render(): JSX.Element {
    const {children, forwardedRef, ...props} = this.props;
    return <canvas ref={forwardedRef} {...props} />;
  }
}

export const WebGL2Canvas = React.forwardRef((props: WebGL2CanvasProps, ref: ForwardedRef<HTMLCanvasElement>) => {
  const canvasRef = useRef<HTMLCanvasElement>(null);
  useEffect(() => {
    if (!ref) return;
    if (typeof ref === "function") {
      ref(canvasRef.current);
    } else {
      ref.current = canvasRef.current;
    }
  }, [ref]);

  return <BaseWebGL2Canvas {...props} forwardedRef={canvasRef} />
});

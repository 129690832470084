import {NameField} from "../name-field.tsx";
import {VisibilityLayerField} from "../visibility-layer-field.tsx";
import {InputTransform} from "./transform/input-transform.tsx";
import {ConstantOperation, ValueFn, ValueOperation} from "common/types/index.ts";
import {useCallback, useMemo} from "react";
import {AreaNode, AreaNodeOperation, AreaNodeSignals} from "common/legends/node/area-node.ts";
import {InputCheckbox} from "#lib/components/input/input-checkbox.tsx";
import {Button, InputGroup, InputGroupLabel} from "#lib/components/index.ts";
import {FaWrench} from "react-icons/fa6";
import {ColorField} from "#lib/components/input/color-field.tsx";
import {OpacityField} from "../opacity-field.tsx";
import {InputInteractions} from "./interactions/input-interactions.tsx";
import {MutableRef} from "common/ref";
import {SelectionRef} from "../../nav/editor/state/selection-ref.ts";
import {useEditorTool} from "../../nav/common/tool/tool-selector/use-editor-tool.ts";
import {Panel, Section} from "#lib/components/panel-header.tsx";

export type AreaNodePropertiesProps = {
  value: MutableRef<AreaNode, AreaNodeOperation[]>;
  reference: SelectionRef;
  pinned: MutableRef<SelectionRef, ValueOperation<SelectionRef, ConstantOperation>[]>;
};
export function AreaNodeProperties({value, reference, pinned}: AreaNodePropertiesProps) {
  const {name, visibilityLayer, transform, suppressWalls, color, opacity, interactions} = useMemo(() => AreaNodeSignals(value), [value]);
  const tool = useEditorTool();
  const setAreaTool = useCallback(() => {
    tool.apply(tool => ValueFn.set(tool, {
      type: "area",
      data: {nodeID: value.value.id, mode: {type: "create", data: undefined}}
    }));
  }, [tool, value]);

  return <div className="tab-content flex flex-col py-2 gap-1">
    <Panel title="Area Properties">
      <Section>
        <NameField value={name} reference={reference} pinned={pinned}/>
        <VisibilityLayerField value={visibilityLayer}/>
        <InputTransform value={transform}/>

        <InputGroup className="pl-0 mx-2 rounded-md overflow-hidden">
          <InputCheckbox value={suppressWalls}/>
          <InputGroupLabel>Suppress Walls</InputGroupLabel>
        </InputGroup>

        <ColorField label="Area Color" value={color}/>
        <OpacityField value={opacity}/>

        <Button className="mx-2 rounded-md overflow-hidden" onClick={setAreaTool}>
          <FaWrench/> Edit Area
        </Button>
      </Section>
      <div className="flex flex-col gap-2 py-2">
        <Section>
          <InputInteractions value={interactions}/>
        </Section>
      </div>
    </Panel>
  </div>
}
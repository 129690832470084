import {KeyboardEvent, MouseEvent, RefObject, useCallback, useRef} from "react";
import {Point, PointFn, Transform, TransformOperation, ValueFn} from "common/types/index.ts";
import {useSample} from "#lib/components/index.ts";
import {Vector2f} from "#lib/math/index.ts";
import {useGetScreenPosition} from "./use-get-screen-pos.ts";
import {MutableRef} from "common/ref";
import {Vector2} from "common/math/vector/vector2.ts";

export function usePanHandlers(
  canvasRef: RefObject<HTMLCanvasElement>,
  viewRef: MutableRef<Transform, TransformOperation[]>,
  isPanButton: (ev: MouseEvent) => boolean
) {
  const spaceOverride = useRef(false);
  const isDraggingView = useRef(false);
  const lastPanRef = useRef<Point | undefined>(undefined);
  const pan = useSample(useCallback((worldPos: Point) => {
    if (lastPanRef.current) {
      const d = Vector2f.subtract(worldPos, lastPanRef.current);
      viewRef.apply(prevValue => ValueFn.apply([{
        type: "update-position",
        operations: PointFn.set(prevValue.position, Vector2.add(prevValue.position, d))
      }]));
    }
    lastPanRef.current = worldPos;
  }, [viewRef.apply, lastPanRef]), 16);

  const getScreenPos = useGetScreenPosition(canvasRef);
  const onPanMouseDown = useCallback((ev: MouseEvent) => {
    if (!isPanButton(ev)) return true;
    lastPanRef.current = getScreenPos([ev.clientX, ev.clientY]);
    isDraggingView.current = true;
    spaceOverride.current = true;
    return ev.buttons === 2;
  }, [lastPanRef, isPanButton, spaceOverride, isDraggingView]);
  const onPanMouseUp = useCallback((ev: MouseEvent) => {
    ev.buttons =
      (ev.button === 0 ? 1 : 0) +
      (ev.button === 2 ? 2 : 0) +
      (ev.button === 1 ? 4 : 0);
    isDraggingView.current = false;
    lastPanRef.current = undefined;
    return true;
  }, [lastPanRef, isPanButton, spaceOverride, isDraggingView]);
  const onPanContextMenu = useCallback((ev: MouseEvent) => {
    ev.preventDefault();
    return false;
  }, []);

  const onPanMouseMove = useCallback((ev: MouseEvent) => {
    if (!isDraggingView.current) return true;
    pan(getScreenPos([ev.clientX, ev.clientY]));
    return true;
  }, [pan, getScreenPos]);

  const onPanMouseEnter = useCallback((ev: MouseEvent) => {
    if (!isDraggingView.current) return true;
    if (!isPanButton(ev)) {
      isDraggingView.current = false;
    }
    return true;
  }, [isDraggingView, isPanButton, spaceOverride]);

  const onPanWheel = useCallback((ev: WheelEvent) => {
    if (!canvasRef.current) return true;
    if (ev.deltaX === 0 && ev.deltaY === 0) return true;
    if (!ev.ctrlKey) return true;

    let ratio = 1/64;
    if (ev.deltaMode === ev.DOM_DELTA_PIXEL) ratio = (Math.abs(ev.deltaX) + Math.abs(ev.deltaY)) < 16 ? 1/16 : 1/64;
    else if (ev.deltaMode === ev.DOM_DELTA_LINE) ratio = 1/16;
    else if (ev.deltaMode === ev.DOM_DELTA_PAGE) ratio = 1;

    if (ev.deltaX !== 0) {
      const scrollDeltaX = ev.deltaX * ratio;
      viewRef.apply(_ => ValueFn.apply([{
        type: "update-position",
        operations: [{type: "update-x", operations: [{
            type: "increment",
            amount: 64 * -scrollDeltaX
          }]}]
      }]));
    }
    if (ev.deltaY !== 0) {
      const scrollDeltaY = ev.deltaY * ratio;
      viewRef.apply(_ => ValueFn.apply([{
        type: "update-position",
        operations: [{type: "update-y", operations: [{
            type: "increment",
            amount: 64 * scrollDeltaY
          }]}]
      }]));
    }
    return false;
  }, [viewRef]);

  const onPanKeyDown = useCallback((_: KeyboardEvent): boolean => {
    if (spaceOverride.current) return false;
    return true;
  }, [spaceOverride])
  const onPanKeyUp = useCallback((_: KeyboardEvent): boolean => {
    if (spaceOverride.current) {
      if (!isDraggingView.current) {
        spaceOverride.current = false;
      }
      return false;
    }
    return true;
  }, [spaceOverride]);


  return {
    onPanKeyDown,
    onPanKeyUp,
    onPanMouseDown,
    onPanMouseMove,
    onPanMouseUp,
    onPanMouseEnter,
    onPanWheel,
    onPanContextMenu
  };
}

import {WallShader} from "./wall-shader.tsx";
import {Point} from "#lib/math/index.ts";
import {Color} from "common/types/generic/index.ts";
import {Framebuffer} from "./framebuffer.tsx";
import {forwardRef, Ref, useCallback} from "react";
import {BlendFuncSeperate} from "#lib/gl-react/component/opengl/blend-func-seperate.tsx";

export const WallMaskFramebuffer = forwardRef(function WallMaskFramebuffer({origin}: {
  origin: Point
}, ref: Ref<WebGLTexture>) {
  const clearFramebuffer = useCallback((context: WebGL2RenderingContext) => {
    context.clearColor(1, 1, 1, 1);
    context.clear(WebGL2RenderingContext.COLOR_BUFFER_BIT)
  }, []);

  return <Framebuffer ref={ref}>
    <action onAction={clearFramebuffer} />
    <BlendFuncSeperate
      srcRGB={WebGL2RenderingContext.ONE} dstRGB={WebGL2RenderingContext.ZERO}
      srcAlpha={WebGL2RenderingContext.ONE} dstAlpha={WebGL2RenderingContext.ZERO}>
      <WallShader origin={origin} color={Color.CLEAR}/>
    </BlendFuncSeperate>
  </Framebuffer>
});

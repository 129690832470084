import {NameField} from "../name-field.tsx";
import {VisibilityLayerField} from "../visibility-layer-field.tsx";
import {ConstantOperation, ValueOperation} from "common/types/index.ts";
import {useMemo} from "react";
import {ParallaxNode, ParallaxNodeOperation, ParallaxNodeRef} from "common/legends/node/parallax-node.ts";
import {MutableRef} from "common/ref";
import {InputTransform} from "./transform/input-transform.tsx";
import {SelectionRef} from "../../nav/editor/state/selection-ref.ts";
import {Panel, Section} from "#lib/components/panel-header.tsx";

export type ParallaxNodePropertiesProps = {
  value: MutableRef<ParallaxNode, ParallaxNodeOperation[]>;
  reference: SelectionRef;
  pinned: MutableRef<SelectionRef, ValueOperation<SelectionRef, ConstantOperation>[]>;
};
export function ParallaxNodeProperties({value, reference, pinned}: ParallaxNodePropertiesProps) {
  const {name, visibilityLayerRef, transformRef} = useMemo(() => ParallaxNodeRef(value), [value]);

  return <div className="tab-content flex flex-col py-2 gap-1">
    <Panel title="Parallax Properties">
      <Section>
        <NameField value={name} reference={reference} pinned={pinned} />
        <InputTransform value={transformRef} />
        <VisibilityLayerField value={visibilityLayerRef} />
      </Section>
    </Panel>
  </div>
}
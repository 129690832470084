import {Dnd5eFeature, Sheet} from "#common/legends/index.ts";
import {Dnd5eEffect} from "#common/legends/asset/sheet/dnd-5e/dnd-5e-effect/index.ts";
import {getSheetVariables} from "#common/legends/asset/sheet/dnd-5e/dnd-5e-variable/sheet-variable-signal.ts";
import {MathExpressionFn} from "#common/math/index.ts";
import {Dnd5eModifier} from "#common/legends/asset/sheet/dnd-5e/dnd-5e-modifier/dnd-5e-modifier.ts";
import {RollVariables} from "#common/qlab/index.ts";
import {Optional} from "#common/types/index.ts";
import {Dnd5eSheetFn} from "#common/legends/asset/sheet/dnd-5e/dnd-5e-sheet-helper.ts";
import {overrideFeatures} from "#common/legends/game/system/dnd-5e-feature-override.ts";

export function getActiveEffects(sheet: Optional<Sheet>, globalFeatures: Dnd5eFeature[]): Dnd5eEffect[] {
  if (sheet === undefined) return [];
  return [
    ...Dnd5eSheetFn.getEffects(sheet, globalFeatures),
  ].filter(e => e.enabled) || [];
}

export function getActiveFeatures(sheet: Optional<Sheet>, globalFeatures: Dnd5eFeature[]): Dnd5eFeature[] {
  if (sheet?.type === "dnd-5e-character") {
    return [
      ...overrideFeatures(globalFeatures, sheet.data.globalFeatures),
      ...sheet.data.classes
        .flatMap(c => c.features.filter(f => f.level <= c.level).sort((a, b) => a.level - b.level).map(f => f.feature)),
      ...sheet.data.background.features,
      ...sheet.data.race.features,
      ...sheet.data.features
    ].filter(f => f.enabled);
  } else if (sheet?.type === "dnd-5e-stat-block") {
    return [
      ...overrideFeatures(globalFeatures, sheet.data.globalFeatures),
      ...sheet.data.features
    ].filter(f => f.enabled);
  } else {
    return [];
  }
}

export function getActiveModifiers(sheet: Optional<Sheet>, globalFeatures: Dnd5eFeature[]): Dnd5eModifier[] {
  if (sheet === undefined) {
    return getActiveEffects(sheet, globalFeatures).flatMap((e) => e.modifiers);
  } else {
    const activeItems = (sheet.type === "dnd-5e-character" ? sheet.data.inventory : []).filter(i => i.equipped);
    const activeFeatures = getActiveFeatures(sheet, globalFeatures);
    return [
      ...activeFeatures.flatMap(f => f.modifiers),
      ...activeItems.flatMap(i => {
        return [
          ...i.modifiers,
          ...i.effects.filter(e => e.enabled)
            .flatMap(e => e.modifiers)
        ];
      }),
      ...getActiveEffects(sheet, globalFeatures).flatMap((e) => e.modifiers)
    ];
  }
}


export function getActiveVariables(sheet: Optional<Sheet>, globalFeatures: Dnd5eFeature[]): RollVariables {
  const variables = getSheetVariables(sheet);
  const modifiers = getActiveModifiers(sheet, globalFeatures);
  for (const modifier of modifiers) {
    if (modifier.type !== "variable") continue;
    variables[modifier.data.name.toUpperCase()] = MathExpressionFn.executeMathExpression(modifier.data.expression, variables);
  }
  return variables;
}

import {z} from "zod";
import {Type} from "#common/types/type/index.ts";
import {Mask, MaskOperation, maskType} from "#common/types/generic/mask/mask.ts";

export const VisibilityLayer = Mask;
export type VisibilityLayer = z.infer<typeof VisibilityLayer>;

export const VisibilityLayerOperation = MaskOperation;
export type VisibilityLayerOperation = z.infer<typeof VisibilityLayerOperation>;

export const visibilityLayerType: Type<VisibilityLayer, VisibilityLayerOperation> = maskType;

export const VisibilityLayerFn = {
  DEFAULT: ["ALL"]
};

import React, {useContext} from "react";
import {Matrix4f} from "#lib/math/index.ts";
import {Transform} from "common/types/generic/index.ts";

const ProjectionContext = React.createContext<Matrix4f>(Matrix4f.MULTIPLICATIVE_IDENTITY);
export const ProjectionProvider = ProjectionContext.Provider;
export function useProjection() {
  return useContext(ProjectionContext);
}

const ViewContext = React.createContext<Transform>(Transform.DEFAULT);
export const ViewProvider = ViewContext.Provider;
export function useView() {
  return useContext(ViewContext);
}

const ModelContext = React.createContext<Transform>(Transform.DEFAULT);
export const ModelProvider = ModelContext.Provider;
export function useModel() {
  return useContext(ModelContext);
}

export function usePVM() {
  return {
    projection: useProjection(),
    view: useView(),
    model: useModel()
  };
}

import {AssetID, Node, NodeId, Sheet, SheetID, TokenID} from "common/legends/index.ts";
import {useRefValue} from "#lib/signal/index.ts";
import React from "react";
import {Embeddable, Point, Transform, ValueFn} from "common/types/index.ts";
import {Menu} from "@headlessui/react";
import {Button} from "#lib/components/index.ts";
import {useTypedResource} from "#lib/qlab/react/hooks/resource/use-typed-resource.ts";
import {Fieldset} from "#lib/components/fieldset/fieldset.tsx";
import {useUserID} from "#lib/auth/use-get-user-id.ts";
import {MutableRef} from "common/ref";
import {ContextMenu, ContextMenuOperation} from "../../../../../routes/game/context-menu/context-menu.ts";
import {useIsGameMaster} from "../../../../common/game/use-is-game-master.ts";
import {useStoreID} from "../../../../../routes/game/model/store-context.tsx";

export type AssetContextMenuProps = {
  contextMenu: MutableRef<ContextMenu, ContextMenuOperation[]>;
  screenPos: Point;
  assetID: AssetID;
  onDrop: (screenPosition: Point, fn: (worldPosition: Point) => Node) => void;
};

export function AssetContextMenu({contextMenu, screenPos, assetID, onDrop}: AssetContextMenuProps) {
  const gameID = useStoreID();
  const userID = useUserID()!;
  const asset = useRefValue(useTypedResource("asset", assetID));
  const isGameMaster = useIsGameMaster();
  const dropToken = (tokenID: TokenID) => {
    if (asset === undefined) return;
    const token = asset.tokens.find(token => token.tokenID === tokenID);
    if (token === undefined) return;
    onDrop(screenPos, (worldPos): Node => {
      const tokenSheets: {[tokenID: TokenID]: Embeddable<SheetID, Sheet>} = {};
      if (token.sheetPolicy === "copy" && token.sheetId !== undefined) {
        const sheet = asset.sheets[token.sheetId];
        tokenSheets[tokenID] = {type: "copy", data: sheet};
      } else if (token.sheetPolicy === "link" && token.sheetId !== undefined) {
        tokenSheets[tokenID] = {type: "link", data: token.sheetId};
      }
      return ({
        type: "token",
        data: {
          id: NodeId.generate(),
          name: token.name,
          tags: [],
          opacity: 1,
          selectionMask: ["GM"],
          transform: {
            ...Transform.DEFAULT,
            position: worldPos
          },
          pivot: [0, 0],
          origin: [0, 0],
          tokenReference: {gameID, assetID, tokenID},
          tokenSheets: tokenSheets,
          children: [],
          ownerIDs: isGameMaster ? asset.ownerIDs : [userID],
          visibilityLayer: asset.visibilityLayer,
          mountable: token.mountable,
          attachable: token.attachable,
          conditions: []
        }
      })
    })
    contextMenu.apply(prev => ValueFn.set(prev, undefined))
  };

  if (asset === undefined) return <></>;
  return <Menu as="div">
    <Menu.Items as={Fieldset} static className="flex flex-col gap-0.5 pt-0.5 w-[240px]">
      {asset.tokens.map((token) => <Menu.Item key={token.tokenID} as={Button} className={" h-[32px]"} onClick={() => dropToken(token.tokenID)}>{token.name}</Menu.Item>)}
    </Menu.Items>
  </Menu>;
}

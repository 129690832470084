import {z} from "zod";
import {Grammar, Parser} from "nearley";
import {MathAST} from "./math-a-s-t.ts";
import mathGrammar from "./math-grammar.ts";
import {ConstantOperation, constantType, ValueOperation, ValueType} from "../types/index.ts";

export const MathExpression = z.string();//.brand("MathExpression");
export type MathExpression = z.infer<typeof MathExpression>;

export const MathExpressionOperation = ValueOperation<MathExpression, ConstantOperation>(MathExpression, ConstantOperation);
export type MathExpressionOperation = z.infer<typeof MathExpressionOperation>;

export const mathExpressionType = new ValueType<MathExpression, ConstantOperation>(constantType);

function executeMathAST(expression: MathAST, context: {[variable: string]: number}): number {
  if (expression.op === "value") return expression.value;
  else if (expression.op === "variable") return context !== undefined && context[expression.variable.toUpperCase()] !== undefined
    ? context[expression.variable.toUpperCase()]
    : 0;
  else if (expression.op === "add")  return executeMathAST(expression.left, context) + executeMathAST(expression.right, context);
  else if (expression.op === "subtract")  return executeMathAST(expression.left, context) - executeMathAST(expression.right, context);
  else if (expression.op === "multiply")  return executeMathAST(expression.left, context) * executeMathAST(expression.right, context);
  else if (expression.op === "divide")  return Math.floor(executeMathAST(expression.left, context) / executeMathAST(expression.right, context));
  else if (expression.op === "max")  return Math.max(executeMathAST(expression.left, context), executeMathAST(expression.right, context));
  else if (expression.op === "min")  return Math.min(executeMathAST(expression.left, context), executeMathAST(expression.right, context));
  return 0;
}

export const MathExpressionFn = {
  assertMathExpression(expression: string): MathExpression {
    if (MathExpressionFn.isMathExpression(expression)) return expression;
    throw new Error(`Invalid math expression: ${expression}`);
  },
  isMathExpression(expression: string): expression is MathExpression {
    try {
      parseMathExpression(expression);
      return true;
    } catch (e) {
      return false;
    }
  },
  executeMathExpression(expression: MathExpression, context: {[variable: string]: number}): number {
    return executeMathAST(parseMathExpression(expression), context);
  }
} as const;


const compiledGrammar = Grammar.fromCompiled(mathGrammar);
const parseMathExpression = (expression: string): MathAST => {
  try {
    const results = new Parser(compiledGrammar)
      .feed(expression)
      .finish();
    if (results.length >= 1) return results[0];
  } catch (e) {
    throw e;
  }
  throw new Error("Cannot parse math expression: " + expression);
}

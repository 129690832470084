import {z} from "zod";
import {
  ConstantOperation,
  constantType,
  ListOperation,
  ListPropertyRef,
  ListType,
  ObjectType,
  Optional,
  Type,
  ValueOperation,
  ValuePropertyRef,
  ValueType,
  ZodListOperation
} from "../../../../../../types/index.ts";
import {Dnd5eActionCondition} from "../condition/dnd-5e-action-condition.ts";
import {Dnd5eModifierID} from "../../dnd-5e-modifier/dnd-5e-modifier-i-d.ts";
import {Dnd5eActionTemplateModifier, Dnd5eActionTemplateModifierOperation, dnd5eActionTemplateModifierType} from "./dnd-5e-action-template-modifier.ts";
import {MutableRef} from "#common/ref";

export const Dnd5eActionTemplateConditionModifier: z.ZodType<{
  modifierID: string;
  condition?: Optional<Dnd5eActionCondition>;
  modifiers: Dnd5eActionTemplateModifier[];
}> = z.lazy(() => z.object({
  modifierID: Dnd5eModifierID,
  condition: z.optional(Dnd5eActionCondition),
  modifiers: z.array(Dnd5eActionTemplateModifier)
}));
export type Dnd5eActionTemplateConditionModifier = z.infer<typeof Dnd5eActionTemplateConditionModifier>;

export const Dnd5eActionTemplateConditionModifierOperation: z.ZodType<
  | {type: "update-modifier-i-d", operations: ConstantOperation[]}
  | {type: "update-condition", operations: ValueOperation<Optional<Dnd5eActionCondition>, ConstantOperation>[]}
  | {type: "update-modifiers", operations: ListOperation<Dnd5eActionTemplateModifier, Dnd5eActionTemplateModifierOperation>[]}
> = z.lazy(() => z.discriminatedUnion("type", [
  z.object({type: z.literal("update-modifier-i-d"), operations: z.array(ConstantOperation)}),
  z.object({type: z.literal("update-condition"), operations: z.array(ValueOperation(z.optional(Dnd5eActionCondition), ConstantOperation))}),
  z.object({type: z.literal("update-modifiers"), operations: z.array(ZodListOperation(Dnd5eActionTemplateModifier, Dnd5eActionTemplateModifierOperation))})
]));
export type Dnd5eActionTemplateConditionModifierOperation = z.infer<typeof Dnd5eActionTemplateConditionModifierOperation>;

export const dnd5eActionTemplateConditionModifierType: Type<Dnd5eActionTemplateConditionModifier, Dnd5eActionTemplateConditionModifierOperation> = new ObjectType(() => ({
  modifierID: constantType,
  condition: new ValueType(constantType),
  modifiers: new ListType(dnd5eActionTemplateModifierType)
}));

export function Dnd5eActionTemplateConditionModifierSignals(valueRef: MutableRef<Dnd5eActionTemplateConditionModifier, Dnd5eActionTemplateConditionModifierOperation[]>) {
  return {
    conditionRef: ValuePropertyRef<Dnd5eActionTemplateConditionModifier, Dnd5eActionTemplateConditionModifierOperation, Optional<Dnd5eActionCondition>, ConstantOperation>(
      value => value.condition,
      operations => [{type: "update-condition", operations}]
    )(valueRef),
    modifiersRef: ListPropertyRef<Dnd5eActionTemplateConditionModifier, Dnd5eActionTemplateConditionModifierOperation, Dnd5eActionTemplateModifier, Dnd5eActionTemplateModifierOperation>(
      value => value.modifiers,
      operations => [{type: "update-modifiers", operations}]
    )(valueRef)
  }
}

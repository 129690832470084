import {useCallback, useEffect, useMemo} from "react";
import {addBeyond20Listener} from "#lib/beyond20/beyond-20-api.ts";
import {useSendDnd5eAction} from "../../../../legends/panel/sheet/editor/dnd-5e/use-send-dnd-5e-action.ts";
import {encryptValue, FileReference, NumberFn, Optional, walkTree} from "common/types/generic/index.ts";
import {ulid} from "ulid";
import {DiceExpression} from "common/dice/dice-expression.ts";
import {RollRequests} from "common/qlab/message/index.ts";
import {Beyond20Ability, Beyond20DamageType, Beyond20RollType, Beyond20Skill, RenderedRoll} from "#lib/beyond20/beyond-20-rendered-roll.ts";
import {Dnd5eDamageType, DND_5E_DAMAGE_TYPES} from "common/legends/asset/sheet/dnd-5e/dnd-5e-damage-type.ts";
import {Dnd5eActionSegment} from "common/qlab/message/dnd-5e/dnd-5e-action-message.ts";
import {useSelectedNodeIDRef} from "../../../../legends/panel/sheet/editor/dnd-5e-character/use-selected-sheet.ts";
import {useSendAbilityCheck} from "../../../../legends/panel/sheet/editor/dnd-5e-character/dnd-5e-action/use-roll-ability-check.ts";
import {Dnd5eAttribute, SheetInterface} from "common/legends/asset/index.ts";
import {useSendSavingThrow} from "../../../../legends/panel/sheet/editor/dnd-5e-character/dnd-5e-action/use-roll-saving-throw.ts";
import {useSendFeatureMessage} from "../../../../legends/panel/sheet/editor/dnd-5e-character/dnd-5e-action/use-send-feature-message.ts";
import {Dnd5eAbilityCheckType, DND_5E_ABILITY_CHECK_TITLE} from "common/legends/asset/sheet/dnd-5e/dnd-5e-modifier/dnd-5e-ability-check-modifier.ts";
import {useDatabase} from "../../model/store-context.tsx";
import {Node} from "common/legends/node/index.ts";
import {SheetReference, SheetReferenceFn} from "../../../../legends/common/sheet/sheet-reference.ts";
import {SheetRef} from "../../../../legends/common/legends/sheet-ref.ts";
import {listIdentity} from "common/observable";
import {useGlobalFeatures} from "../../../../legends/panel/sheet/editor/dnd-5e-character/dnd-5e-action/use-global-features.ts";

function toDamageType(damageType: Beyond20DamageType): Optional<Dnd5eDamageType> {
  if (damageType === "Piercing") return "piercing (magical)";
  if (damageType === "Bludgeoning") return "bludgeoning (magical)";
  if (damageType === "Slashing") return "slashing (magical)";
  return DND_5E_DAMAGE_TYPES.find(dndDamageType => damageType.toLowerCase().startsWith(dndDamageType));
}

const DEFAULT_VISIBILITY = {audience: {}, default: {canViewExpression: true, canViewResult: true}};

function getBeyond20D20RollType(type: Beyond20RollType) {
  switch (type) {
    case Beyond20RollType.QUERY:
    case Beyond20RollType.NORMAL: return "1d20";
    case Beyond20RollType.ROLL_TWICE:
    case Beyond20RollType.ROLL_ADVANTAGE: return "2d20kh1";
    case Beyond20RollType.ROLL_THRICE:
    case Beyond20RollType.ROLL_SUPER_ADVANTAGE: return "3d20kh1";
    case Beyond20RollType.ROLL_DISADVANTAGE: return "2d20kl1";
    case Beyond20RollType.ROLL_SUPER_DISADVANTAGE: return "3d20kl1";
  }
}

function toDnd5eAttribute(ability: Beyond20Ability): Dnd5eAttribute {
  switch (ability) {
    case "STR": return "str";
    case "DEX": return "dex";
    case "CON": return "con";
    case "WIS": return "wis";
    case "INT": return "int";
    case "CHA": return "cha";
  }
}

function useBeyond20SendAttack() {
  const sendMessage = useSendDnd5eAction();
  const nodeIDRef = useSelectedNodeIDRef();

  return useCallback(async (message: RenderedRoll<"attack" | "spell-attack">) => {
    const attackRollID = ulid();

    const attackRollExpressions = [
      getBeyond20D20RollType(message.request.advantage)
    ];
    if (message.request["to-hit"]) attackRollExpressions.push(message.request["to-hit"]);
    const attackRollExpression = DiceExpression.parse(attackRollExpressions.join("+").replaceAll("++", "+").replaceAll("+-", "-"));

    const onAttackRollRollRequests = {
      [attackRollID]: {
        expression:  attackRollExpression,
        variables: {},
        visibility: DEFAULT_VISIBILITY
      }
    };

    const onHit: Dnd5eActionSegment[] = [{type: "roll", data: message.request["damage-types"].map((damageType, index) => ({
        rollID: `${attackRollID}-hit-${index}`,
        rollType: toDamageType(damageType) ?? message.request["damage-types"].map(toDamageType).find(type => type !== undefined)
      }))}];
    const onHitRollRequests = Object.fromEntries(message.request["damage-types"].map((_, index) => [`${attackRollID}-hit-${index}`, {
      expression: `${message.request.damages[index]}`,
      variables: {},
      visibility: DEFAULT_VISIBILITY
    }]));

    const criticalHitDamageTypes = [...message.request["damage-types"], ...message.request["critical-damage-types"]];
    const criticalHitDamages = [...message.request["damages"], ...message.request["critical-damages"]];
    const onCriticalHit: Dnd5eActionSegment[] = [{type: "roll", data: criticalHitDamageTypes.map((damageType, index) => ({
        rollID: `${attackRollID}-crit-${index}`,
        rollType: toDamageType(damageType) ?? criticalHitDamageTypes.map(toDamageType).find(type => type !== undefined)
      }))}];
    const onCriticalHitRollRequests = Object.fromEntries(criticalHitDamages.map((_, index) => [`${attackRollID}-crit-${index}`, {
      expression: `${criticalHitDamages[index]}`,
      variables: {},
      visibility: DEFAULT_VISIBILITY
    }]));

    const onAttackRoll: Dnd5eActionSegment[] = [{type: "attack-roll", data: {
        attackRollID: attackRollID,
        onMiss: [],
        onHit,
        onCriticalHit
      }}];

    await sendMessage({
      title: message.request.name,
      nodeID: nodeIDRef.value,
      icon: message.request.character.avatar ? FileReference.parse(message.request.character.avatar) : undefined,
      segments: onAttackRoll,
      rollRequests: await encryptValue({}, (): RollRequests => ({
        ...onAttackRollRollRequests,
        ...onHitRollRequests,
        ...onCriticalHitRollRequests,
      }))
    });
  }, [sendMessage])
}

function toDnd5eAbilityCheck(skill: Beyond20Skill): Dnd5eAbilityCheckType {
  return Object.entries(DND_5E_ABILITY_CHECK_TITLE).find(([_, value]) => value === skill)![0] as Dnd5eAbilityCheckType;
}

function useBeyond20SendAbilityCheck() {
  const sendMessage = useSendAbilityCheck();
  const nodeIDRef = useSelectedNodeIDRef();
  return useCallback(async (message: RenderedRoll<"skill" | "ability">) => {
    const expression = [
      getBeyond20D20RollType(message.request.advantage),
      message.request.modifier
    ].join("+").replaceAll("++","+").replaceAll("+-", "-");
    await sendMessage(
      nodeIDRef.value,
      message.request.type === "skill" ? toDnd5eAbilityCheck(message.request.skill) : toDnd5eAttribute(message.request.ability),
      expression,
      undefined,
      {}
    );
  }, [sendMessage])
}

function useBeyond20SendInitiative() {
  const sendMessage = useSendAbilityCheck();
  const nodeIDRef = useSelectedNodeIDRef();
  return useCallback(async (message: RenderedRoll<"initiative">) => {
    const expression = [
      getBeyond20D20RollType(message.request.advantage),
      message.request.initiative
    ].join("+").replaceAll("++","+").replaceAll("+-", "-");
    await sendMessage(
      nodeIDRef.value,
      "initiative",
      expression,
      undefined,
      {}
    );
  }, [sendMessage])
}

function useBeyond20SendDescription() {
  const sendMessage = useSendFeatureMessage();
  const nodeIDRef = useSelectedNodeIDRef();
  return useCallback(async (message: RenderedRoll<"trait" | "spell-card" | "item">) => {
    await sendMessage(
      nodeIDRef.value,
      message.request.name,
      [],
      message.request.description.split("\n").map(text => (
        {children: [{text}]}
      )),
      {}
    );
  }, [sendMessage])
}

function useBeyond20SendSavingThrow() {
  const sendMessage = useSendSavingThrow();
  const nodeIDRef = useSelectedNodeIDRef();
  return useCallback(async (message: RenderedRoll<"saving-throw" | "death-save">) => {
    const expressions = [
      getBeyond20D20RollType(message.request.advantage)
    ];
    if (message.request.modifier.length > 0) expressions.push(message.request.modifier);
    const expression = expressions.join("+").replaceAll("++","+").replaceAll("+-", "-");
    await sendMessage(
      nodeIDRef.value,
      message.request.type === "death-save" ? "death" : toDnd5eAttribute(message.request.ability),
      expression,
      undefined,
      {}
    );
  }, [sendMessage])
}

function useBeyond20SendHitDice() {
  const sendMessage = useSendFeatureMessage();
  const nodeIDRef = useSelectedNodeIDRef();
  return useCallback(async (message: RenderedRoll<"hit-dice">) => {
    await sendMessage(
      nodeIDRef.value,
      `Hit Dice (${message.request["class"]})`,
      [{
        damageType: "healing",
        hitExpression: message.request["hit-dice"]
      }],
      [],
      {}
    );
  }, [sendMessage])
}
export function useSendUpdateHP() {
  const databaseRef = useDatabase();
  const sheetReferencesByNameRef = useMemo(() => {
    return databaseRef.map((database): {[name: string]: SheetReference[]} => {
      // update sheets in scenes
      const sheetRefs: {[name: string]: SheetReference[]} = {};
      for (const [resourceID, resource] of Object.entries(database.resources)) {
        if (resource?.type !== "scene") continue;

        walkTree(resource.data.children, {
          visit(value: Node) {
            if (value.type !== "token") return;
            const tokenSheet = value.data.tokenSheets[value.data.tokenReference.tokenID];
            if (tokenSheet === undefined) return;
            const sheetReference: SheetReference = (tokenSheet.type === "link")
              ? {type: "link", assetID: value.data.tokenReference.assetID, sheetID: tokenSheet.data}
              : {type: "copy", nodeID: value.data.id, tokenID: value.data.tokenReference.tokenID}
              ;

            const name = (tokenSheet.type === "copy")
              ? tokenSheet.data.data.name
              : SheetRef(databaseRef, sheetReference).value?.data.name;
            if (!name) return;

            if (!sheetRefs[name]) sheetRefs[name] = [];
            sheetRefs[name].push(sheetReference);
            return;
          }
        });
      }

      return sheetRefs;
    }).distinct((a, b) => {
      const aEntries = Object.entries(a);
      const bEntries = Object.entries(b);
      if (aEntries.length !== bEntries.length) return false;
      for (const [aKey, aValue] of aEntries) {
        for (const [bKey, bValue] of bEntries) {
          if (aKey !== bKey) return false;
          if (!listIdentity(aValue, bValue, SheetReferenceFn.equals)) return false;
        }
      }
      return true;
    });
  }, [databaseRef]);

  const globalFeaturesRef = useGlobalFeatures();
  return useCallback((name: string, currentHP: number, maxHP: number, tempHP: number) => {
    const sheetReferences = sheetReferencesByNameRef.value[name] ?? [];
    const globalFeatures = globalFeaturesRef.value;
    for (const sheetReference of sheetReferences) {
      SheetRef(databaseRef, sheetReference).apply(prev => {
        if (prev?.type === "dnd-5e-character") {
          const sheetMaxHP = SheetInterface.getMaxHP(prev, globalFeatures);
          return [{type: "dnd-5e-character", operations: [{
            type: "update-hit-points",
            operations: [
              {type: "update-current", operations: NumberFn.set(prev.data.hitPoints.current, currentHP - sheetMaxHP)},
              {type: "update-temp", operations: NumberFn.set(prev.data.hitPoints.temp, tempHP)}
            ]
          }]}];
        } else if (prev?.type === "dnd-5e-stat-block") {
          const sheetMaxHP = SheetInterface.getMaxHP(prev, globalFeatures);
          return [{type: "dnd-5e-stat-block", operations: [{
            type: "update-hit-points",
            operations: [
              {type: "update-current", operations: NumberFn.set(prev.data.hitPoints.current, currentHP - sheetMaxHP)},
              {type: "update-temp", operations: NumberFn.set(prev.data.hitPoints.temp, tempHP)}
            ]
          }]}];
        } else {
          return [];
        }
      })
    }
  }, [databaseRef, sheetReferencesByNameRef]);
}

export function Beyond20Integration() {
  const sendAttack = useBeyond20SendAttack();
  const sendAbilityCheck = useBeyond20SendAbilityCheck();
  const sendSavingThrow = useBeyond20SendSavingThrow();
  const sendDescription = useBeyond20SendDescription();
  const sendInitiative = useBeyond20SendInitiative();
  const sendHitDice = useBeyond20SendHitDice();
  const sendUpdateHP = useSendUpdateHP();

  useEffect(() => {
    const closeRoll = addBeyond20Listener("RenderedRoll", async (messages) => {
      for (const message of messages) {
        if (message.request.type === "attack" || message.request.type === "spell-attack") sendAttack(message);
        else if (message.request.type === "initiative") sendInitiative(message);
        else if (message.request.type === "skill" || message.request.type === "ability") sendAbilityCheck(message);
        else if (message.request.type === "saving-throw" || message.request.type === "death-save") sendSavingThrow(message);
        else if (message.request.type === "item" || message.request.type === "trait" || message.request.type === "spell-card") sendDescription(message);
        else if (message.request.type === "hit-dice") sendHitDice(message);
      }
    });

    const closeUpdateHp = addBeyond20Listener("UpdateHP", async ([_, name, currentHp, maxHP, tempHP]) => {
      sendUpdateHP(name, currentHp, maxHP, tempHP);
    });

    return () => {
      closeRoll();
      closeUpdateHp();
    }
  }, [sendAttack, sendAbilityCheck, sendSavingThrow, sendDescription, sendInitiative, sendHitDice, sendUpdateHP]);
  return <></>
}

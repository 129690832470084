import {
  Dnd5eActionTemplateSegmentAbilityCheckModifier,
  Dnd5eActionTemplateSegmentAbilityCheckModifierOperation,
  Dnd5eActionTemplateSegmentAbilityCheckModifierTypes
} from "common/legends/asset/sheet/dnd-5e/dnd-5e-action-definition/segment/ability-check/dnd-5e-action-template-segment-ability-check-modifier.ts";
import {MutableRef} from "common/ref";
import {ListOperation} from "common/types/generic/index.ts";
import {InputList, InputListItemProps, useDragListItem} from "#lib/components/list/input-list.tsx";
import {useTypedRef} from "../../../../../../../common/use-typed-ref.ts";
import React from "react";
import {Dnd5eActionTemplateSegmentAbilityCheckConditionModifierListItem} from "./dnd-5e-action-template-segment-ability-check-condition-modifier-view.tsx";
import {Dnd5eVariableModifierListItem} from "../dnd-5e-variable-modifier-view.tsx";

function Dnd5eActionTemplateSegmentAbilityCheckModifierListItem({item, remove, duplicate}: InputListItemProps<Dnd5eActionTemplateSegmentAbilityCheckModifier, Dnd5eActionTemplateSegmentAbilityCheckModifierOperation>) {
  const [dragHandlerRef, dragRefPreview] = useDragListItem("legends/action-template-segment-ability-check-modifier", item, remove);
  const [type, typedRef] = useTypedRef<Dnd5eActionTemplateSegmentAbilityCheckModifierTypes>(item);
  if (type === "variable") return <Dnd5eVariableModifierListItem item={typedRef} remove={remove} duplicate={duplicate} dragHandlerRef={dragHandlerRef} dragRefPreview={dragRefPreview} />;
  if (type === "action::ability-check::condition") return <Dnd5eActionTemplateSegmentAbilityCheckConditionModifierListItem item={typedRef} remove={remove} duplicate={duplicate} dragHandlerRef={dragHandlerRef} dragRefPreview={dragRefPreview} />;
  return <>{type}</>
}

export function Dnd5eActionTemplateSegmentAbilityCheckModifiersView({valueRef}: {valueRef: MutableRef<Dnd5eActionTemplateSegmentAbilityCheckModifier[], ListOperation<Dnd5eActionTemplateSegmentAbilityCheckModifier, Dnd5eActionTemplateSegmentAbilityCheckModifierOperation>[]>}) {
  return <InputList
      accept="legends/action-template-segment-ability-check-modifier"
      items={valueRef}
      itemKey={(v) => v.data.modifierID}
      copy={v => v} ListItem={Dnd5eActionTemplateSegmentAbilityCheckModifierListItem} />;
}
import {Dnd5eCharacter, Dnd5eCharacterOperation} from "common/legends/index.ts";
import {InputGroup, InputGroupLabel, InputNumber} from "#lib/components/index.ts";
import {Dnd5eResourceID} from "common/legends/asset/sheet/dnd-5e/dnd-5e-resource/dnd-5e-resource-id.ts";
import {useMemo} from "react";
import {pipe} from "common/pipe";
import {distinct, map} from "common/observable";
import {Dnd5eResource, Dnd5eResourceOperation, Dnd5eResourceSignals} from "common/legends/asset/sheet/dnd-5e/dnd-5e-resource/dnd-5e-resource.ts";
import {ListOperation, Optional, ValueFn} from "common/types/index.ts";
import {InputString} from "#lib/components/input/input-string.tsx";
import {useSheetResources} from "../../dnd-5e-character/dnd-5e-action/use-sheet-resources.ts";
import {useSheetReference} from "../../../../../common/sheet/sheet-reference-context.ts";
import {PanelHeader} from "#lib/components/panel-header.tsx";
import {useSheetSignal} from "../../../../../common/sheet/use-sheet-signal.ts";
import {InputMathExpression} from "#lib/components/input/input-math-expression.tsx";
import {MutableRef} from "common/ref";
import {useRefValue} from "#lib/signal/index.ts";

function useSheetResource(value: MutableRef<Dnd5eCharacter, Dnd5eCharacterOperation[]>, resourceID: Dnd5eResourceID): MutableRef<Dnd5eResource, Dnd5eResourceOperation[]> {
  return useMemo(() => {
    const valueFn = (sheet: Dnd5eCharacter, resourceID: Dnd5eResourceID): Optional<Dnd5eResource> => {
      for (const feature of sheet.race.features) {
        for (const resource of feature.resources) {
          if (resource.resourceID === resourceID) return resource;
        }
      }
      for (const feature of sheet.background.features) {
        for (const resource of feature.resources) {
          if (resource.resourceID === resourceID) return resource;
        }
      }
      for (const clazz of sheet.classes) {
        for (const feature of clazz.features) {
          for (const resource of feature.feature.resources) {
            if (resource.resourceID === resourceID) return resource;
          }
        }
      }
      for (const item of sheet.inventory) {
        for (const resource of item.resources) {
          if (resource.resourceID === resourceID) return resource;
        }
      }
      for (const feature of sheet.features) {
        for (const resource of feature.resources) {
          if (resource.resourceID === resourceID) return resource;
        }
      }
      return undefined;
    };

    return new MutableRef<Dnd5eResource, Dnd5eResourceOperation[]>({
      value(): Dnd5eResource {
        return valueFn(value.value, resourceID) as Dnd5eResource;
      },
      observe: pipe(
        value.observe,
        map(character => valueFn(character, resourceID) as Dnd5eResource),
        distinct()
      ),
      apply: (fn: (prev: Dnd5eResource) => Dnd5eResourceOperation[]) => value.apply(prev => {
        for (const [featureIndex, feature] of prev.race.features.entries()) {
          for (const [resourceIndex, resource] of feature.resources.entries()) {
            if (resource.resourceID !== resourceID) continue;
            return [{
              type: "update-race", operations: ValueFn.apply([{
                type: "update-features", operations: ListOperation.apply(featureIndex, [{
                  type: "update-resources", operations: ListOperation.apply(resourceIndex, fn(resource))
                }])
              }])
            }];
          }
        }
        for (const [featureIndex, feature] of prev.background.features.entries()) {
          for (const [resourceIndex, resource] of feature.resources.entries()) {
            if (resource.resourceID !== resourceID) continue;
            return [{
              type: "update-background", operations: ValueFn.apply([{
                type: "update-features", operations: ListOperation.apply(featureIndex, [{
                  type: "update-resources", operations: ListOperation.apply(resourceIndex, fn(resource))
                }])
              }])
            }];
          }
        }
        for (const [clazzIndex, clazz] of prev.classes.entries()) {
          for (const [featureIndex, feature] of clazz.features.entries()) {
            for (const [resourceIndex, resource] of feature.feature.resources.entries()) {
              if (resource.resourceID !== resourceID) continue;
              return [{
                type: "update-classes", operations: ListOperation.apply(clazzIndex, [{
                  type: "update-features", operations: ListOperation.apply(featureIndex, [{type: "update-feature", operations: [{
                    type: "update-resources", operations: ListOperation.apply(resourceIndex, fn(resource))
                  }]}])
                }])
              }];
            }
          }
        }
        for (const [itemIndex, item] of prev.inventory.entries()) {
          for (const [resourceIndex, resource] of item.resources.entries()) {
            if (resource.resourceID !== resourceID) continue;
            return [{
              type: "update-inventory", operations: ListOperation.apply(itemIndex, [{
                type: "update-resources", operations: ListOperation.apply(resourceIndex, fn(resource))
              }])
            }];
          }
        }
        for (const [featureIndex, feature] of prev.features.entries()) {
          for (const [resourceIndex, resource] of feature.resources.entries()) {
            if (resource.resourceID !== resourceID) continue;
            return [{
              type: "update-features", operations: ListOperation.apply(featureIndex, [{
                type: "update-resources", operations: ListOperation.apply(resourceIndex, fn(resource))
              }])
            }];
          }
        }
        return [];
      }).then(character => valueFn(character, resourceID)!)
    });
  }, [value, resourceID]);
}

type Dnd5eSheetResourceViewProps = {
  value: MutableRef<Dnd5eCharacter, Dnd5eCharacterOperation[]>;
  resourceID: Dnd5eResourceID;
};
function Dnd5eSheetResourceView({value, resourceID}: Dnd5eSheetResourceViewProps) {
  const resource = useSheetResource(value, resourceID);
  const {name, current, max} = useMemo(() => Dnd5eResourceSignals(resource), [resource]);
  return <div className="flex flex-row">
    <InputGroup className="flex-1">
      <InputString className="flex-1" type="text" value={name} readOnly />
    </InputGroup>

    <InputGroup className="basis-20">
      <InputNumber type="number" value={current} />
    </InputGroup>
    <InputGroup>
      <InputGroupLabel>/</InputGroupLabel>
    </InputGroup>
    <InputGroup className="basis-40">
      <InputMathExpression value={max} />
    </InputGroup>
  </div>
}

export type Dnd5eSheetResourcesViewProps = {
  value: MutableRef<Dnd5eCharacter, Dnd5eCharacterOperation[]>;
};
export function Dnd5eSheetResourcesView({value}: Dnd5eSheetResourcesViewProps) {
  const resources = useRefValue(useSheetResources(useSheetSignal(useSheetReference())));
  return (<div className="flex flex-col gap-1">
    <PanelHeader>Resources</PanelHeader>
    <div className="flex flex-col gap-0.5">
      {resources.map(resource => <Dnd5eSheetResourceView key={resource.resourceID} value={value} resourceID={resource.resourceID} />)}
    </div>
  </div>);
}
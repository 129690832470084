import {useIsGameMaster} from "../../common/game/use-is-game-master.ts";
import {useVision} from "../common/context/use-vision.ts";
import {useEditorVision} from "../../panel/nav/editor/use-editor-vision.ts";
import {createValueRef, Ref} from "common/ref";
import {NodeId} from "common/legends/node/index.ts";
import {Optional} from "common/types/generic/index.ts";
import {useComputedValue} from "#lib/signal/index.ts";

export function useIsActingPlayer(activeNodeIdRef: Ref<Optional<NodeId>>) {
  const vision = useIsGameMaster() ? useVision(useEditorVision(), activeNodeIdRef) : createValueRef([]);
  return useComputedValue(vision, v => !v.some(v => v.accessMask.includes("GM")));
}


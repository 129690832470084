import React, {useContext} from "react";
import {MutableRef} from "common/ref";
import {Dnd5eActionTemplate, Dnd5eActionTemplateOperation} from "common/legends/asset/sheet/dnd-5e/dnd-5e-action-definition/template/dnd-5e-action-template.ts";

const ActionRefContext = React.createContext<MutableRef<Dnd5eActionTemplate, Dnd5eActionTemplateOperation[]> | undefined>(undefined);

export const ActionRefProvider = ActionRefContext.Provider;

export function useActionRef(): MutableRef<Dnd5eActionTemplate, Dnd5eActionTemplateOperation[]> | undefined {
  return useContext(ActionRefContext);
}

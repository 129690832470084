import {z} from "zod";

export const Dnd5eActionType = z.union([
  z.literal("action"),
  z.literal("bonus action"),
  z.literal("free action"),
  z.literal("trait"),
  z.literal("reaction"),
  z.literal("lair"),
  z.literal("legendary")
]);
export type Dnd5eActionType = z.infer<typeof Dnd5eActionType>;
export const DND_5E_ACTION_TYPES = [
  "action",
  "bonus action",
  "reaction",
  "free action",
  "trait",
  "lair",
  "legendary"
] satisfies Dnd5eActionType[];

import {MutableRef, Ref} from "common/ref";
import {useCallback} from "react";
import {
  Dnd5eActionTemplate,
  Dnd5eActionTemplateFn,
  Dnd5eActionTemplateOperation
} from "common/legends/asset/sheet/dnd-5e/dnd-5e-action-definition/template/dnd-5e-action-template.ts";
import {NodeId} from "common/legends/node/index.ts";
import {encryptValue, ListOperation, MapFn, NumberFn, Optional, ValueFn} from "common/types/generic/index.ts";
import {Dnd5eCharacterOperation, Dnd5eFeature, Dnd5eSpellLevel, Sheet, SheetOperation} from "common/legends/asset/index.ts";
import {useSendDnd5eAction} from "../../dnd-5e/use-send-dnd-5e-action.ts";
import {useGetNodeIcon} from "../../../../../common/use-get-node-icon.ts";
import {RollRequestID, RollRequests, RollVariables} from "common/qlab/message/index.ts";
import {Dnd5eActionTemplateSegment} from "common/legends/asset/sheet/dnd-5e/dnd-5e-action-definition/segment/dnd-5e-action-template-segment.ts";
import {Dnd5eActionSegment} from "common/qlab/message/dnd-5e/dnd-5e-action-message.ts";
import {DiceExpression} from "common/dice/dice-expression.ts";
import {Dice} from "common/dice/dice.ts";
import {useGlobalFeatures} from "./use-global-features.ts";
import {Dnd5eDamageType} from "common/legends/asset/sheet/dnd-5e/dnd-5e-damage-type.ts";
import {getActiveDiceRollExpressionModifiers} from "./damage/get-active-dice-roll-expression-modifiers.ts";
import {getActiveHitModifiers} from "./attack-roll/get-active-hit-modifiers.ts";
import {getActiveDifficultyClassModifiers} from "./saving-throw/get-active-difficulty-class-modifier.ts";
import {getActiveBaseAttackRoll} from "./attack-roll/get-active-base-attack-roll.ts";
import {getActiveActionVariablesModifiers, getActiveSegmentVariables} from "./get-active-variables.ts";
import {MathExpressionFn} from "common/math/math-expression.ts";
import {getActivationProcesses} from "./process/get-activation-processes.ts";
import {getMaxPactSlots, getMaxSpellSlotsByClasses, getPactSlotLevel} from "common/legends/asset/sheet/dnd-5e/character/get-max-spell-slots-by-classes.ts";
import {Dnd5eStatBlockOperation} from "common/legends/asset/sheet/dnd-5e/dnd-5e-stat-block/index.ts";
import {ApplyAction} from "common/types/apply.ts";
import {Dnd5eResource, Dnd5eResourceOperation} from "common/legends/asset/sheet/dnd-5e/dnd-5e-resource/dnd-5e-resource.ts";
import {getSheetVariables} from "common/legends/asset/sheet/dnd-5e/dnd-5e-variable/sheet-variable-signal.ts";
import {getActiveAttackRollAttributeOverrideModifiers} from "./attack-roll/get-active-attack-roll-attribute-override-modifiers.ts";
import {getActiveSavingThrowAttributeOverrideModifiers} from "./saving-throw/get-active-saving-throw-attribute-override-modifiers.ts";
import {getBaseAbilityCheck} from "./ability-check/get-base-ability-check.ts";

function getRollRequests(
  sheet: Optional<Sheet>,
  globalFeatures: Dnd5eFeature[],
  action: Dnd5eActionTemplate,
  segment: Dnd5eActionTemplateSegment,

  hasAdvantage: boolean,
  hasDisadvantage: boolean,

  variables: RollVariables = {},
  rollRequests: RollRequests = {}
): RollRequests {
  if (segment.type === "section") {
    rollRequests = segment.data.segments.reduce((rollRequests, segment) => getRollRequests(sheet, globalFeatures, action, segment, hasAdvantage, hasDisadvantage, variables, rollRequests), rollRequests);
  }
  if (segment.type === "roll") {
    const modifiers = getActiveDiceRollExpressionModifiers(sheet, globalFeatures, action, segment);
    const rollTypeExpressions = [
      ...segment.data.expressions.map(expression => ({
        rollType: expression.rollType,
        expression: DiceExpression.parse(expression.expression)
      })),
      ...modifiers.map(modifier => ({
        rollType: modifier.rollType,
        expression: DiceExpression.parse(modifier.expression)
      }))
    ];
    const rollTypes: (Dnd5eDamageType | "unknown")[] = [];
    for (const damageTypeExpression of rollTypeExpressions) {
      if (rollTypes.includes(damageTypeExpression.rollType ?? "unknown")) continue;
      rollTypes.push(damageTypeExpression.rollType ?? "unknown");
    }

    const segmentVariables = {...(variables ?? {})};
    for (const modifier of getActiveSegmentVariables(sheet, globalFeatures, action, segment)) {
      segmentVariables[modifier.name.toUpperCase()] = MathExpressionFn.executeMathExpression(modifier.expression, segmentVariables);
    }

    for (const rollType of rollTypes) {
      const expression = DiceExpression.parse(rollTypeExpressions.filter(expression => (expression.rollType ?? "unknown") === rollType).map(expression => expression.expression).join("+").replaceAll("+-", "-"));
      rollRequests[`${segment.data.actionSegmentID}-${rollType}`] = {
        expression: expression,
        variables: segmentVariables,
        visibility: {audience: {}, default: {canViewExpression: true, canViewResult: true}}
      };
    }
  } else if (segment.type === "attack-roll") {
    const expressions: DiceExpression[] = [];

    // attribute
    let attribute = segment.data.attribute;
    for (const modifier of getActiveAttackRollAttributeOverrideModifiers(sheet, globalFeatures, action, segment)) {
      attribute = modifier.attribute ?? attribute;
    }

    expressions.push(getActiveBaseAttackRoll(sheet, globalFeatures, action, segment, attribute, hasAdvantage, hasDisadvantage));
    if (attribute) expressions.push(DiceExpression.parse(`{${attribute.toUpperCase()}}`));

    // proficiency
    if (segment.data.proficient) expressions.push(DiceExpression.parse("{PB}"));
    expressions.push(DiceExpression.parse(segment.data.expression));
    expressions.push(...getActiveHitModifiers(sheet, globalFeatures, action, segment).map(modifier => DiceExpression.parse(modifier.expression)));

    const attackRoll: DiceExpression = Dice.assertDiceExpression(expressions.filter(expression => expression !== "0").join("+").replaceAll("+-", "-"));

    const segmentVariables = {...(variables ?? {})};
    for (const modifier of getActiveSegmentVariables(sheet, globalFeatures, action, segment)) {
      segmentVariables[modifier.name.toUpperCase()] = MathExpressionFn.executeMathExpression(modifier.expression, segmentVariables);
    }

    rollRequests[segment.data.actionSegmentID] = {
      expression: attackRoll,
      variables: segmentVariables,
      visibility: {audience: {}, default: {canViewExpression: true, canViewResult: true}}
    };

    rollRequests = segment.data.onHit.reduce((rollRequests, segment) => getRollRequests(sheet, globalFeatures, action, segment, hasAdvantage, hasDisadvantage, segmentVariables, rollRequests), rollRequests);
    rollRequests = segment.data.onCriticalHit.reduce((rollRequests, segment) => getRollRequests(sheet, globalFeatures, action, segment, hasAdvantage, hasDisadvantage, segmentVariables, rollRequests), rollRequests);
    rollRequests = segment.data.onMiss.reduce((rollRequests, segment) => getRollRequests(sheet, globalFeatures, action, segment, hasAdvantage, hasDisadvantage, segmentVariables, rollRequests), rollRequests);
  } else if (segment.type === "saving-throw") {
    const expressions: DiceExpression[] = [];
    expressions.push(DiceExpression.parse("8")); // base
    let attribute = segment.data.attribute;
    for (const modifier of getActiveSavingThrowAttributeOverrideModifiers(sheet, globalFeatures, action, segment)) {
      attribute = modifier.attribute ?? attribute;
    }

    if (attribute) expressions.push(DiceExpression.parse(`{${attribute.toUpperCase()}}`));
    if (segment.data.proficient) expressions.push(DiceExpression.parse(`{PB}`));
    expressions.push(DiceExpression.parse(segment.data.expression));
    expressions.push(...getActiveDifficultyClassModifiers(sheet, globalFeatures, action, segment).map(modifier => DiceExpression.parse(modifier.expression)));

    const segmentVariables = {...(variables ?? {})};
    for (const modifier of getActiveSegmentVariables(sheet, globalFeatures, action, segment)) {
      segmentVariables[modifier.name.toUpperCase()] = MathExpressionFn.executeMathExpression(modifier.expression, segmentVariables);
    }

    rollRequests[segment.data.actionSegmentID] = {
      expression: Dice.assertDiceExpression(expressions.filter(expression => expression !== "0").join("+").replaceAll("+-", "-")),
      variables: segmentVariables,
      visibility: {audience: {}, default: {canViewExpression: true, canViewResult: true}}
    };
    rollRequests = segment.data.onSuccess.reduce((rollRequests, segment) => getRollRequests(sheet, globalFeatures, action, segment, hasAdvantage, hasDisadvantage, segmentVariables, rollRequests), rollRequests);
    rollRequests = segment.data.onFailure.reduce((rollRequests, segment) => getRollRequests(sheet, globalFeatures, action, segment, hasAdvantage, hasDisadvantage, segmentVariables, rollRequests), rollRequests);
  } else if (segment.type === "ability-check") {
    const expressions: DiceExpression[] = [
    ];

    expressions.push(getBaseAbilityCheck(sheet, globalFeatures, segment.data.abilityCheck, hasAdvantage, hasDisadvantage));
    expressions.push(segment.data.expression);

    const segmentVariables = {...(variables ?? {})};
    for (const modifier of getActiveSegmentVariables(sheet, globalFeatures, action, segment)) {
      segmentVariables[modifier.name.toUpperCase()] = MathExpressionFn.executeMathExpression(modifier.expression, segmentVariables);
    }
    rollRequests[segment.data.actionSegmentID] = {
      expression: Dice.assertDiceExpression(expressions.filter(expression => expression !== "0").join("+").replaceAll("+-", "-")),
      variables: segmentVariables,
      visibility: {audience: {}, default: {canViewExpression: true, canViewResult: true}}
    };
    rollRequests = segment.data.onSuccess.reduce((rollRequests, segment) => getRollRequests(sheet, globalFeatures, action, segment, hasAdvantage, hasDisadvantage, segmentVariables, rollRequests), rollRequests);
    rollRequests = segment.data.onFailure.reduce((rollRequests, segment) => getRollRequests(sheet, globalFeatures, action, segment, hasAdvantage, hasDisadvantage, segmentVariables, rollRequests), rollRequests);
  }
  return rollRequests;
}

function toActionSegment(
  sheet: Optional<Sheet>,
  globalFeatures: Dnd5eFeature[],
  action: Optional<Dnd5eActionTemplate>,
  segment: Optional<Dnd5eActionTemplateSegment>
): Optional<Dnd5eActionSegment> {
  if (segment?.type === "text") return ({
    type: "text",
    data: segment.data.content
  });
  if (segment?.type === "section") return ({
    type: "section",
    data: {
      name: segment.data.name,
      segments: segment.data.segments.map(segment => toActionSegment(sheet, globalFeatures, action, segment)).flatMap(item => item !== undefined ? [item] : [])
    }
  })
  if (segment?.type === "roll") {
    const expressions: {rollType: Optional<Dnd5eDamageType>, rollID: RollRequestID}[] = [];
    expressions.push(...segment.data.expressions.map(expression => ({
      rollType: expression.rollType,
      rollID: RollRequestID.parse(expression.rollID)
    })));
    expressions.push(...getActiveDiceRollExpressionModifiers(sheet, globalFeatures, action, segment).map(modifier => ({
      rollType: modifier.rollType,
      rollID: RollRequestID.parse(modifier.modifierID)
    })));
    const rollTypes: (Dnd5eDamageType | "unknown")[] = [];
    for (const expression of expressions) {
      if (rollTypes.includes(expression.rollType ?? "unknown")) continue;
      rollTypes.push(expression.rollType ?? "unknown");
    }

    return ({
      type: "roll",
      data: rollTypes.map(rollType => ({
        rollType: rollType === "unknown" ? undefined : rollType,
        rollID: `${segment.data.actionSegmentID}-${rollType}`
      }))
    });
  }
  if (segment?.type === "attack-roll") {
    return ({
      type: "attack-roll",
      data: {
        attackRollID: segment.data.actionSegmentID,
        onCriticalHit: segment.data.onCriticalHit.map(segment => toActionSegment(sheet, globalFeatures, action, segment)).flatMap((item) => (item !== undefined) ? [item] : []),
        onHit: segment.data.onHit.map(segment => toActionSegment(sheet, globalFeatures, action, segment)).flatMap((item) => (item !== undefined) ? [item] : []),
        onMiss: segment.data.onMiss.map(segment => toActionSegment(sheet, globalFeatures, action, segment)).flatMap((item) => (item !== undefined) ? [item] : [])
      }
    });
  }
  if (segment?.type === "saving-throw") {
    return ({
      type: "saving-throw",
      data: {
        savingThrowTypes: segment.data.savingThrowTypes,
        difficultyClassRollID: segment.data.actionSegmentID,
        onMiss: segment.data.onFailure.map(segment => toActionSegment(sheet, globalFeatures, action, segment)).flatMap((item) => (item !== undefined) ? [item] : []),
        onSuccess: segment.data.onSuccess.map(segment => toActionSegment(sheet, globalFeatures, action, segment)).flatMap((item) => (item !== undefined) ? [item] : [])
      }
    })
  }
  if (segment?.type === "ability-check") {
    return ({
      type: "ability-check",
      data: {
        abilityCheckTypes: segment.data.abilityCheckTypes,
        rollID: segment.data.actionSegmentID,
        onSuccess: segment.data.onSuccess.map(segment => toActionSegment(sheet, globalFeatures, action, segment)).flatMap((item) => (item !== undefined) ? [item] : []),
        onFailure: segment.data.onFailure.map(segment => toActionSegment(sheet, globalFeatures, action, segment)).flatMap((item) => (item !== undefined) ? [item] : []),
      }
    });
  }

  return undefined;
}

export function useRollActionTemplate(
  nodeIDRef: Ref<Optional<NodeId>>,
  sheetRef: MutableRef<Optional<Sheet>, SheetOperation[]>,
  actionRef: MutableRef<Dnd5eActionTemplate, Dnd5eActionTemplateOperation[]>
) {
  const globalFeaturesRef = useGlobalFeatures();
  const sendMessage = useSendDnd5eAction();
  const getNodeIcon = useGetNodeIcon();
  return useCallback(async (forceAdvantage: boolean, forceDisadvantage: boolean) => {
    const action = actionRef.value;
    const nodeID = nodeIDRef.value;
    const sheet = sheetRef.value;
    const globalFeatures = globalFeaturesRef.value;

    const variables = {...getSheetVariables(sheet)};
    for (const modifier of getActiveActionVariablesModifiers(sheet, globalFeatures, action)) {
      variables[modifier.name.toUpperCase()] = MathExpressionFn.executeMathExpression(modifier.expression, variables);
    }

    const rollRequests = await encryptValue({}, () => {
      return action.data.segments.reduce((rollRequests, segment) => getRollRequests(sheetRef.value, globalFeaturesRef.value, action, segment, forceAdvantage, forceDisadvantage, variables, rollRequests), {});
    });
    sendMessage({
      title: Dnd5eActionTemplateFn.getTitle(action),
      referenceMessageID: undefined,
      nodeID: nodeID,
      icon: getNodeIcon(nodeID),
      segments: action.data.segments.map(segment => toActionSegment(sheet, globalFeatures, action, segment)).flatMap((item) => (item !== undefined) ? [item] : []),
      rollRequests: rollRequests
    });

    // Trigger Activation
    const processes = getActivationProcesses(sheet, globalFeatures, action);
    for (const process of processes) {
      if (process.type === "adjust-spell-slot") {
        const level = Dnd5eSpellLevel.parse(`${MathExpressionFn.executeMathExpression(process.data.level, variables)}`);
        const quantity = MathExpressionFn.executeMathExpression(process.data.quantity, variables);
        adjustSpellSlot(sheetRef, level, quantity);
      } else if (process.type === "adjust-custom-resource") {
        const quantity = MathExpressionFn.executeMathExpression(process.data.quantity, variables);
        adjustResource(sheetRef, process.data.resource, quantity);
      } else if (process.type === "consume-item") {
        consumeItem(sheetRef, process.data.item);
      }
    }
  }, [nodeIDRef, actionRef]);
}

function adjustSpellSlot(sheetRef: MutableRef<Optional<Sheet>, SheetOperation[]>, level: Dnd5eSpellLevel, quantity: number) {
  if (level === "cantrip") return;
  if (quantity === 0) return;
  sheetRef.apply((prev: Sheet | undefined): SheetOperation[] => {
    if (prev?.type === "dnd-5e-character") {
      const operations: Dnd5eCharacterOperation[] = [];
      if (quantity > 0) {
        operations.push({
          type: "update-spell-slots", operations: MapFn.apply(level, ValueFn.set(prev.data.spellSlots[level], prev.data.spellSlots[level] + quantity))
        });
      } else {
        const pactSlotQuantity = getPactSlotLevel(prev.data.classes) === level ? Math.min(prev.data.pactSlots, -quantity) : 0;
        const remainingQuantity = -(quantity + pactSlotQuantity);
        if (pactSlotQuantity > 0) operations.push({type: "update-pact-slots", operations: NumberFn.set(
          prev.data.pactSlots, Math.max(0, Math.min(prev.data.pactSlots - pactSlotQuantity, getMaxPactSlots(prev.data.classes, level)))
        )});
        if (remainingQuantity > 0) operations.push({type: "update-spell-slots", operations: MapFn.apply(level, ValueFn.set(
          prev.data.spellSlots[level], Math.max(0, Math.min(prev.data.spellSlots[level] - remainingQuantity, getMaxSpellSlotsByClasses(prev.data.classes, level)))
        ))});
      }
      return [{type: "dnd-5e-character", operations}];
    } else if (prev?.type === "dnd-5e-stat-block") {
      const operations: Dnd5eStatBlockOperation[] = [];
      operations.push({type: "update-spell-slots", operations: MapFn.apply(level, ValueFn.set(prev.data.spellSlots[level], prev.data.spellSlots[level] + quantity))});
      return [{type: "dnd-5e-stat-block", operations}];
    } else {
      return [];
    }
  });
}

function adjustResource(sheetRef: MutableRef<Optional<Sheet>, SheetOperation[]>, name: string, quantity: number) {
  if (quantity === 0) return;
  sheetRef.apply((prev: Sheet | undefined): SheetOperation[] => {
    if (prev?.type === "dnd-5e-character") {
      const updateResourceName = (fn: ApplyAction<Dnd5eResource, Dnd5eResourceOperation[]>): Dnd5eCharacterOperation[] => {
        for (const [featureIndex, feature] of prev.data.race.features.entries()) {
          for (const [resourceIndex, resource] of feature.resources.entries()) {
            if (resource.name === name) {
              const operations = typeof fn === "function" ? fn(resource) : fn;
              return [{type: "update-race", operations: ValueFn.apply([{
                type: "update-features", operations: ListOperation.apply(featureIndex, [{
                  type: "update-resources", operations: ListOperation.apply(resourceIndex, operations)
                }])
              }])}];
            }
          }
        }

        for (const [featureIndex, feature] of prev.data.background.features.entries()) {
          for (const [resourceIndex, resource] of feature.resources.entries()) {
            if (resource.name === name) {
              const operations = typeof fn === "function" ? fn(resource) : fn;
              return [{type: "update-background", operations: ValueFn.apply([{
                type: "update-features", operations: ListOperation.apply(featureIndex, [{
                  type: "update-resources", operations: ListOperation.apply(resourceIndex, operations)
                }])
              }])}];
            }
          }
        }

        for (const [classIndex, clazz] of prev.data.classes.entries()) {
          for (const [featureIndex, feature] of clazz.features.entries()) {
            for (const [resourceIndex, resource] of feature.feature.resources.entries()) {
              if (resource.name === name) {
                const operations = typeof fn === "function" ? fn(resource) : fn;
                return [{type: "update-classes", operations: ListOperation.apply(classIndex, [{
                  type: "update-features", operations: ListOperation.apply(featureIndex, [{type: "update-feature", operations: [{
                    type: "update-resources", operations: ListOperation.apply(resourceIndex, operations)
                  }]}])
                }])}];
              }
            }
          }
        }

        for (const [itemIndex, item] of prev.data.inventory.entries()) {
          for (const [resourceIndex, resource] of item.resources.entries()) {
            if (resource.name === name) {
              const operations = typeof fn === "function" ? fn(resource) : fn;
              return [{type: "update-inventory", operations: ListOperation.apply(itemIndex, [{
                type: "update-resources", operations: ListOperation.apply(resourceIndex, operations)
              }])}];
            }
          }
        }

        for (const [featureIndex, feature] of prev.data.features.entries()) {
          for (const [resourceIndex, resource] of feature.resources.entries()) {
            if (resource.name === name) {
              const operations = typeof fn === "function" ? fn(resource) : fn;
              return [{type: "update-features", operations: ListOperation.apply(featureIndex, [{
                type: "update-resources", operations: ListOperation.apply(resourceIndex, operations)
              }])}];
            }
          }
        }

        return [];
      };
      const operations = updateResourceName(_ => [{type: "update-current", operations: NumberFn.increment(quantity)}]);
      return [{type: "dnd-5e-character", operations}];
    } else if (prev?.type === "dnd-5e-stat-block") {
      const updateResourceName = (fn: ApplyAction<Dnd5eResource, Dnd5eResourceOperation[]>): Dnd5eStatBlockOperation[] => {
        for (const [featureIndex, feature] of prev.data.features.entries()) {
          for (const [resourceIndex, resource] of feature.resources.entries()) {
            if (resource.name === name) {
              return [{type: "update-features", operations: ListOperation.apply(featureIndex, [{
                type: "update-resources", operations: ListOperation.apply(resourceIndex, fn(resource))
              }])}];
            }
          }
        }
        return [];
      };

      const operations = updateResourceName(_ => [{type: "update-current", operations: NumberFn.increment(quantity)}]);
      return [{type: "dnd-5e-stat-block", operations}];
    } else {
      return [];
    }
  });
}

function consumeItem(sheetRef: MutableRef<Optional<Sheet>, SheetOperation[]>, name: string) {
  sheetRef.apply((prev: Sheet | undefined): SheetOperation[] => {
    if (prev?.type === "dnd-5e-character") {
      const operations: Dnd5eCharacterOperation[] = [];
      const itemIndex = prev.data.inventory.findIndex(i => i.item === name && i.qty > 0);
      if (itemIndex !== -1) {
        operations.push({type: "update-inventory", operations: ListOperation.apply(itemIndex, [{
          type: "update-qty", operations: NumberFn.decrement(1)
        }])});
      }
      if (operations.length > 0) return [{type: "dnd-5e-character", operations}];
    }
    return [];
  });
}

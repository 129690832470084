import {NameField} from "../name-field.tsx";
import {VisibilityLayerField} from "../visibility-layer-field.tsx";
import {OpacityField} from "../opacity-field.tsx";
import {SelectionMaskField} from "../selection-mask-field.tsx";
import {InputTransform} from "./transform/input-transform.tsx";
import {ConstantOperation, ValueOperation} from "common/types/index.ts";
import {TokenNode, TokenNodeOperation, TokenNodeSignals} from "common/legends/index.ts";
import {ActiveTokenField} from "./active-token-field.tsx";
import {useCallback, useMemo} from "react";
import {OwnersInput} from "../../asset-navigator/owners-input.tsx";
import {MountableField} from "../mountable-field.tsx";
import {AttachableField} from "../attachable-field.tsx";
import {MutableRef} from "common/ref";
import {Button} from "#lib/components/index.ts";
import {SelectionRef} from "../../nav/editor/state/selection-ref.ts";
import {useNavigateToAssetEditor} from "../../../viewport/character";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faMap} from "@awesome.me/kit-c9bc6845cd/icons/classic/solid";
import {Panel, Section} from "#lib/components/panel-header.tsx";

export type TokenNodePropertiesProps = {
  value: MutableRef<TokenNode, TokenNodeOperation[]>;
  reference: SelectionRef;
  pinned: MutableRef<SelectionRef, ValueOperation<SelectionRef, ConstantOperation>[]>;
};
export function TokenNodeProperties({value, reference, pinned}: TokenNodePropertiesProps) {
  const {name, visibilityLayerRef, ownerIDsRef, transformRef, opacityRef, selectionMaskRef, mountableRef, attachableRef, tokenReferenceRef} = useMemo(() => TokenNodeSignals(value), [value]);
  const navigateToAsset = useNavigateToAssetEditor();
  const navigateToToken = useCallback(() => {
    const tokenReference = tokenReferenceRef.value;
    if (!tokenReference) return;
    return navigateToAsset(tokenReference.assetID, tokenReference.tokenID);
  }, [tokenReferenceRef, navigateToAsset]);

  return <div className="tab-content flex flex-col py-2 gap-1">
    <Panel title="Token Properties">
      <Section>
        <NameField value={name} reference={reference} pinned={pinned} />
        <Button title="Go to Token Editor" onClick={navigateToToken} className="mx-2 rounded-md overflow-hidden">
          <FontAwesomeIcon icon={faMap} />
          Navigate to Token Editor
        </Button>
        <VisibilityLayerField value={visibilityLayerRef} />
        <SelectionMaskField value={selectionMaskRef} />
        <OpacityField value={opacityRef} />
        <InputTransform value={transformRef} />
        <MountableField value={mountableRef} />
        <AttachableField value={attachableRef} />
        <ActiveTokenField valueRef={value} />
      </Section>
      <Section title="Owners">
        <OwnersInput value={ownerIDsRef} />
      </Section>
    </Panel>
  </div>
}
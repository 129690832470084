import {Button, ButtonBar, Spacer} from "#lib/components/index.ts";
import React, {useCallback, useMemo} from "react";
import {FaTimes} from "react-icons/fa";
import {useSetSelectTool} from "../tool-selector/use-set-select-tool.ts";
import {useEditorAreaTool} from "./use-editor-area-tool.ts";
import {useComputedValue} from "#lib/signal/index.ts";
import {ListOperation, Optional, Point, PointOperation, ValueFn} from "common/types/index.ts";
import {MutableRef, NOOP_SIGNAL} from "common/ref";
import {useAreaMode} from "./use-area-mode.ts";
import {InputAreaVertex} from "./input-area-vertex.tsx";
import {Node, NodeOperation} from "common/legends/index.ts";
import {pipe} from "common/pipe";
import {distinct, map} from "common/observable";
import {SplineFn} from "common/types/generic/spline/index.ts";
import {AreaToolData, AreaToolDataOperation} from "../../../../../common/tool/area/area-tool-data.ts";
import {useNode} from "../../../../../viewport/token/use-node.ts";
import {usePresent} from "../../../../../common/use-optional-signal.ts";
import {AreaToolControlPointSelection, AreaToolEdgeSelection, AreaToolVertexSelection} from "../../../../../common/tool/area/area-tool-select-mode.ts";
import {AreaToolModes} from "../../../../../common/tool/area/area-tool-mode.ts";
import {LegendsConnectionIndicator} from "../../legends-connection-indicator.tsx";

const DEFAULT_TOOL_MODE = {
  "select": undefined,
  "create": undefined
};

type AreaSelectVertexNavigationProps = {
  value: MutableRef<AreaToolData, AreaToolDataOperation[]>;
};
function AreaSelectVertexNavigation({value}: AreaSelectVertexNavigationProps) {
  const node = useNode(useComputedValue(value, select => select.nodeID));
  const select = useAreaMode("select", value);

  const vertexData = useComputedValue(select, select => select?.type === "vertex" ? select : undefined);
  const vertexSignal = usePresent(useMemo((): MutableRef<Optional<Point>, PointOperation[]> => {
    const valueFn = (node: Optional<Node>, vertex: Optional<AreaToolVertexSelection>): Optional<Point> => {
      if (vertex === undefined) return undefined;
      const {areaIndex, vertexIndex} = vertex;
      if (node?.type !== "area") return undefined;
      const area = node.data.areas[areaIndex];
      if (area === undefined) return undefined;
      if (vertexIndex > area.curves.length) return undefined;
      return (vertexIndex === 0) ? area.start : area.curves[vertexIndex - 1]?.end;
    };

    return new MutableRef({
      value(): Optional<Point> {
        return valueFn(node.value, vertexData);
      },
      observe: pipe(node.observe, map(node => valueFn(node, vertexData)), distinct()),
      apply: (fn: (prev: Optional<Point>) => PointOperation[]) => node.apply(prev => {
        if (vertexData === undefined) return [];
        if (prev?.type !== "area") return [];
        const {areaIndex, vertexIndex} = vertexData;
        const area = prev.data.areas[areaIndex];
        if (area === undefined) return [];
        if (vertexIndex > area.curves.length) return [];
        const vertex = vertexIndex === 0 ? area.start : area.curves[vertexIndex - 1].end;
        const operations = fn(vertex);
        if (operations.length === 0) return [];
        return [{type: "area", operations: [{type: "update-areas", operations: ListOperation.apply(areaIndex, vertexIndex === 0
          ? [{type: "update-start", operations}]
          : [{type: "update-curves", operations: ListOperation.apply(vertexIndex - 1, [{type: "update-end", operations}])}]
        )}]}];
      }).then(node => valueFn(node, vertexData))
    });
  }, [node, vertexData]));

  const deleteVertex = useCallback(() => {
    node.apply(prev => {
      if (prev?.type !== "area") return [];
      if (vertexData === undefined) return [];
      const {areaIndex, vertexIndex} = vertexData;
      if (prev.data.areas[areaIndex].curves.length > 2) {
        return [{type: "area", operations: [{type: "update-areas", operations: ListOperation.apply(areaIndex, SplineFn.deleteVertex(prev.data.areas[areaIndex], vertexIndex))}]}]
      } else {
        return [{type: "area", operations: [{type: "update-areas", operations: ListOperation.delete(areaIndex, prev.data.areas[areaIndex])}]}];
      }
    });
  }, [node, vertexData]);

  if (!vertexSignal) return <></>
  return <InputAreaVertex value={vertexSignal} remove={deleteVertex} />
}



type AreaSelectEdgeNavigationProps = {
  value: MutableRef<AreaToolData, AreaToolDataOperation[]>;
};
function AreaSelectEdgeNavigation({value}: AreaSelectEdgeNavigationProps) {
  const node = useNode(useComputedValue(value, select => select.nodeID));
  const select = useAreaMode("select", value);

  const edgeData = useComputedValue(select, select => (select?.type === "edge" || select?.type === "control-point") ? select : undefined);
  const cp1Signal = usePresent(useMemo((): MutableRef<Optional<Point>, PointOperation[]> => {
    const valueFn = (node: Optional<Node>, edge: Optional<AreaToolEdgeSelection | AreaToolControlPointSelection>): Optional<Point> => {
      if (edge === undefined) return undefined;
      const {areaIndex, edgeIndex} = edge;
      if (node?.type !== "area") return undefined;
      const area = node.data.areas[areaIndex];
      if (area === undefined) return undefined;
      if (edgeIndex > area.curves.length) return undefined;
      return edgeIndex === 0 ? area.controlPoint2 : area.curves[edgeIndex - 1].controlPoint2;
    };

    return new MutableRef({
      value(): Optional<Point> {
        return valueFn(node.value, edgeData);
      },
      observe: pipe(node.observe, map(node => valueFn(node, edgeData)), distinct()),
      apply: fn => node.apply((prev: Optional<Node>): NodeOperation[] => {
        if (edgeData === undefined) return [];
        if (prev?.type !== "area") return [];
        const {areaIndex, edgeIndex} = edgeData;
        const area = prev.data.areas[areaIndex];
        if (area === undefined) return [];
        if (edgeIndex > area.curves.length) return [];
        const cp1 = edgeIndex === 0 ? area.controlPoint2 : area.curves[edgeIndex - 1].controlPoint2;
        const operations = fn(cp1);
        if (operations.length === 0) return [];
        return [{type: "area", operations: [{type: "update-areas", operations: ListOperation.apply(areaIndex, edgeIndex === 0
              ? [{type: "update-control-point-2", operations}]
              : [{type: "update-curves", operations: ListOperation.apply(edgeIndex - 1, [{type: "update-control-point-2", operations}])}]
            )}]}];
      }).then(node => valueFn(node, edgeData))
    });
  }, [node, edgeData]));


  const cp2Signal = usePresent(useMemo((): MutableRef<Optional<Point>, PointOperation[]> => {
    const valueFn = (node: Optional<Node>, edge: Optional<AreaToolEdgeSelection | AreaToolControlPointSelection>): Optional<Point> => {
      if (edge === undefined) return undefined;
      const {areaIndex, edgeIndex} = edge;
      if (node?.type !== "area") return undefined;
      const area = node.data.areas[areaIndex];
      if (area === undefined) return undefined;
      if (edgeIndex > area.curves.length) return undefined;
      return edgeIndex === area.curves.length ? area.controlPoint1 : area.curves[edgeIndex].controlPoint1;
    };

    return new MutableRef<Optional<Point>, PointOperation[]>({
      value(): Optional<Point> {
        return valueFn(node.value, edgeData);
      },
      observe: pipe(node.observe, map(node => valueFn(node, edgeData)), distinct()),
      apply: (fn: (prev: Optional<Point>) => PointOperation[]) => node.apply(prev => {
        if (edgeData === undefined) return [];
        if (prev?.type !== "area") return [];
        const {areaIndex, edgeIndex} = edgeData;
        const area = prev.data.areas[areaIndex];
        if (area === undefined) return [];
        if (edgeIndex > area.curves.length) return [];

        const cp1 = edgeIndex === area.curves.length ? area.controlPoint1 : area.curves[edgeIndex].controlPoint1;
        const operations = fn(cp1);
        if (operations.length === 0) return [];
        return [{type: "area", operations: [{type: "update-areas", operations: ListOperation.apply(areaIndex, edgeIndex === area.curves.length
              ? [{type: "update-control-point-1", operations}]
              : [{type: "update-curves", operations: ListOperation.apply(edgeIndex, [{type: "update-control-point-1", operations}])}]
            )}]}];
      }).then(node => valueFn(node, edgeData))
    });
  }, [node, edgeData]));

  if (!cp1Signal || !cp2Signal) return <></>
  return <>
    <InputAreaVertex label="CP1" value={cp1Signal} />
    <InputAreaVertex label="CP2" value={cp2Signal} />
  </>
}

export const AreaEditorNavigation = React.memo(function WallEditorNavigation() {
  const exit = useSetSelectTool();
  const areaTool = usePresent(useEditorAreaTool());
  const setMode = useCallback((mode: keyof AreaToolModes) => {
    areaTool?.apply(prev => {
      if (prev === undefined) return [];
      return [{type: "update-mode", operations: ValueFn.set(prev?.mode, {type: mode, data: DEFAULT_TOOL_MODE[mode]})}];
    });
  }, [areaTool]);

  const currentMode = useComputedValue(areaTool ?? NOOP_SIGNAL, areaTool => areaTool?.mode?.type);

  return (<div className="basis-14 flex flex-row h-14 text-white items-center gap-2 bg-zinc-700/20">
    <LegendsConnectionIndicator />

    {areaTool && <AreaSelectVertexNavigation value={areaTool} />}
    {areaTool && <AreaSelectEdgeNavigation value={areaTool} />}

    <Spacer/>

    <ButtonBar>
      <Button variant={currentMode === "select" ? "primary" : "tertiary"} onClick={() => setMode("select")}>Select</Button>
      <Button variant={currentMode === "create" ? "primary" : "tertiary"} onClick={() => setMode("create")}>Create</Button>
    </ButtonBar>
    <Button className="px-4" size="large" onClick={exit}><FaTimes /> Exit</Button>
  </div>);
});


import {Dnd5eActionSegment} from "common/qlab/message/dnd-5e/dnd-5e-action-message.ts";
import {RollRequests, RollResults} from "common/qlab/message/index.ts";
import {Optional} from "common/types/generic/optional/index.ts";
import {Dnd5eActionTextView} from "./dnd-5e-action-text-view.tsx";
import {Dnd5eActionAttackRollView} from "./dnd-5e-action-attack-roll-view.tsx";
import {Fragment} from "react";
import {Dnd5eActionRollView} from "./dnd-5e-action-roll-view.tsx";
import {Dnd5eActionSavingThrowView} from "./dnd-5e-action-saving-throw-view.tsx";
import {Dnd5eActionSectionView} from "./dnd-5e-action-section-view.tsx";
import {Dnd5eActionAbilityCheckView} from "./dnd-5e-action-ability-check-view.tsx";

export function Dnd5eActionSegmentView({segment, rollRequests, rollResults}: {segment: Dnd5eActionSegment, rollRequests: RollRequests, rollResults: Optional<RollResults>}) {
  switch (segment.type) {
    case "text": return <Dnd5eActionTextView segment={segment.data} rollRequests={rollRequests} rollResults={rollResults} />
    case "section": return <Dnd5eActionSectionView segment={segment.data} rollRequests={rollRequests} rollResults={rollResults} />
    case "saving-throw": return <Dnd5eActionSavingThrowView segment={segment.data} rollRequests={rollRequests} rollResults={rollResults} />
    case "attack-roll": return <Dnd5eActionAttackRollView segment={segment.data} rollRequests={rollRequests} rollResults={rollResults} />
    case "roll": return <Dnd5eActionRollView segment={segment.data} rollRequests={rollRequests} rollResults={rollResults} />
    case "ability-check": return <Dnd5eActionAbilityCheckView segment={segment.data} rollRequests={rollRequests} rollResults={rollResults} />
    default: return <Fragment></Fragment>
  }
}
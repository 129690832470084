import React, {forwardRef, Ref, useMemo} from "react";
import {pipe} from "common/pipe";
import {distinct, map, Observable} from "common/observable";
import {Asset, AssetID, AssetOperation, AssetSignals, Node, Token, TokenID, TokenNode, TokenNodeOperation, TokenOperation, TokenSignals} from "common/legends/index.ts";
import {KeyedListSignal, Optional} from "common/types/index.ts";
import {useObservable} from "#lib/qlab/index.ts";
import {Menu} from "@headlessui/react";
import {twMerge} from "tailwind-merge";
import {FaUser} from "react-icons/fa";
import {useSelectedNodeID} from "../../sheet/editor/dnd-5e-character/use-selected-sheet.ts";
import {useNode} from "../../../viewport/token/use-node.ts";
import {useAsset} from "../../../common/character/use-asset.ts";
import {useSetTokenReference} from "../../properties/node/active-token-field.tsx";
import {useComputedValue, useRefValue} from "#lib/signal/index.ts";
import {MutableRef} from "common/ref";

type TokenButtonProps = {
  value: MutableRef<Token, TokenOperation[]>;
  active: boolean;
  onClick: () => void;
};

const TokenButton = forwardRef(function TokenButton({value, active, onClick}: TokenButtonProps, ref: Ref<HTMLButtonElement>) {
  const {nameRef, icon} = useMemo(() => TokenSignals(value), [value]);
  const name = useRefValue(nameRef);
  const resolvedIcon = useRefValue(icon);

  return <button ref={ref} className={twMerge(
    `flex gap-2 items-center rounded-l-[20px] rounded-r-lg bg-zinc-900/50 backdrop-blur-sm pointer-events-auto w-fit`,
    active && "bg-zinc-900/50"
  )} onClick={onClick}>
    {resolvedIcon && <img alt={name} src={resolvedIcon} crossOrigin="anonymous" className="w-10 h-10 rounded-full shrink-0" />}
    {!resolvedIcon && <div className="w-10 h-10 rounded-full shrink-0 bg-zinc-900 inline-flex items-center justify-center"><FaUser /></div>}
    <span className="font-bold pr-4 whitespace-nowrap">
      {name}
    </span>
  </button>
});

export function PlayerTokenSelector() {
  const selectedAssetID = useSelectedAssetID();
  const assetID = useObservable(selectedAssetID, undefined, [selectedAssetID]);
  if (assetID === undefined) return <></>;
  return <InputTokenSelector assetID={assetID} />
}

function useSelectedNode() {
  const nodeID = useSelectedNodeID();
  return useNode(useObservable(nodeID, undefined, [nodeID]));
}

function useSelectedAssetID(): Observable<Optional<AssetID>> {
  const node = useSelectedNode();
  return useMemo(() => pipe(
    node.observe,
    map(node => node?.type === "token" ? node.data.tokenReference.assetID : undefined),
    distinct()
  ), [node]);
}

type InputTokenSelectorProps = {
  assetID: AssetID;
};

function InputTokenSelector({assetID}: InputTokenSelectorProps) {
  const character = useAsset(assetID);
  const isAsset = useComputedValue(character, character => character !== undefined);
  if (!isAsset) return <></>;
  return <InputAssetTokenSelector value={character as MutableRef<Asset, AssetOperation[]>} />
}

type InputAssetTokenSelectorProps = {
  value: MutableRef<Asset, AssetOperation[]>;
};
function InputAssetTokenSelector({value}: InputAssetTokenSelectorProps) {
  const {tokens} = useMemo(() => AssetSignals(value), [value]);
  const tokenSignals = useRefValue(useMemo(() => KeyedListSignal(tokens, Token.getTokenID, Token.copyToken), [tokens]));

  const node = useSelectedNode();
  const tokenID = useMemo(() => pipe(node.observe, map(node => (node?.type !== "token") ? undefined : node.data.tokenReference.tokenID), distinct()), [node.observe]);
  const resolvedTokenID = useObservable(tokenID, undefined, [tokenID]) || "" as TokenID;

  const tokenNode = useMemo((): MutableRef<Optional<TokenNode>, TokenNodeOperation[]> => {
    const valueFn = (node: Optional<Node>) => {
      if (node?.type !== "token") return undefined;
      return node.data;
    };
    return new MutableRef({
      value() {return valueFn(node.value);},
      observe: pipe(node.observe, map(valueFn), distinct()),
      apply: fn => node.apply(prev => {
        if (prev?.type !== "token") return [];
        return [{type: "token", operations: fn(prev.data)}]
      }).then(valueFn)
    })
  }, [node]);

  const setTokenReference = useSetTokenReference(tokenNode);
  if (tokenSignals.find(([tokenID, _]) => tokenID === resolvedTokenID) === undefined) {
    return <></>
  }

  return <div className="relative">
    <Menu>
      <Menu.Button as={TokenButton} value={tokenSignals.find(([tokenID, _]) => tokenID === resolvedTokenID)![1].itemRef} active={true} onClick={() => {}} />
      <Menu.Items className="absolute z-10 left-0 mt-4 focus:outline-none flex flex-col gap-1 w-fit">
        {tokenSignals.map(([tokenID, token]) => <Menu.Item key={tokenID}>
          <TokenButton value={token!.itemRef} active={tokenID === resolvedTokenID} onClick={() => setTokenReference(tokenID as TokenID)} />
        </Menu.Item>)}
      </Menu.Items>
    </Menu>
  </div>;
}


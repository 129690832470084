import {ColorOperation, HSLA} from "common/types/index.ts";
import {InputGroup} from "./input-group.tsx";
import {InputGroupColorButton} from "../color-button/index.ts";
import {forwardRef, HTMLAttributes, Ref} from "react";
import {MutableRef} from "common/ref";
import {Field, FieldLabel} from "#lib/components/panel-header.tsx";

type ColorFieldProps = HTMLAttributes<HTMLLabelElement> & {
  label?: string;
  value: MutableRef<HSLA, ColorOperation[]>;
  readOnly?: boolean;
  disabled?: boolean;
};
export const ColorField = forwardRef(function ColorField({value, readOnly, disabled, label, ...props}: ColorFieldProps, ref: Ref<HTMLLabelElement>) {
  return <Field>
    {label && <FieldLabel>{label}</FieldLabel>}
    <InputGroup className="mx-2 rounded-md overflow-hidden">
      <InputGroup className="flex-1 bg-transparent px-0" ref={ref} {...props}>
        <InputGroupColorButton readOnly={readOnly} disabled={disabled} valueRef={value} />
      </InputGroup>
    </InputGroup>
  </Field>
});
import {ButtonBar, IconButton, InputGroup, InputGroupLabel} from "#lib/components/index.ts";
import React from "react";
import {FaTrash} from "react-icons/fa";
import {ListControl} from "./list-control.ts";
import {MutableRef} from "common/ref";
import {NodeConditionSelf, NodeConditionSelfOperation} from "common/legends/node/condition/node-condition-self.ts";

export function NodeConditionSelfFields({controls}: {
  value: MutableRef<NodeConditionSelf, NodeConditionSelfOperation[]>;
  controls: ListControl;
}) {
  return <ButtonBar>
    <InputGroup className="flex-1">
      <InputGroupLabel>Self Only</InputGroupLabel>
    </InputGroup>
    {controls.remove && <IconButton title="Remove" variant="destructive" onClick={controls.remove}><FaTrash /></IconButton>}
  </ButtonBar>
}
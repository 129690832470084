import {z} from "zod";
import {NumberOperation, numberType, ObjectType, Optional, Type} from "../../../types/index.ts";
import {MutableRef} from "#common/ref";

export const TokenVisionLimit = z.object({
  distance: z.number(),
  falloff: z.number(),
  falloffStrength: z.number()
});
export type TokenVisionLimit = z.infer<typeof TokenVisionLimit>;
export const TokenVisionLimitOperation = z.discriminatedUnion("type", [
  z.object({type: z.literal("update-distance"), operations: z.array(NumberOperation)}),
  z.object({type: z.literal("update-falloff"), operations: z.array(NumberOperation)}),
  z.object({type: z.literal("update-falloff-strength"), operations: z.array(NumberOperation)})
]);
export type TokenVisionLimitOperation = z.infer<typeof TokenVisionLimitOperation>;

export const tokenVisionLimitType: Type<TokenVisionLimit, TokenVisionLimitOperation> = new ObjectType({
  distance: numberType,
  falloff: numberType,
  falloffStrength: numberType
});

export const TokenVisionLimitFn = {
  expand(valueRef: MutableRef<TokenVisionLimit, TokenVisionLimitOperation[]>) {
    return {
      distanceRef: valueRef.map<number, NumberOperation[]>(value => value.distance, (_, operations) => [{type: "update-distance", operations}]),
      falloffRef: valueRef.map<number, NumberOperation[]>(value => value.falloff, (_, operations) => [{type: "update-falloff", operations}]),
      falloffStrengthRef: valueRef.map<number, NumberOperation[]>(value => value.falloffStrength, (_, operations) => [{type: "update-falloff-strength", operations}])
    };
  },
  equals(a: Optional<TokenVisionLimit>, b: Optional<TokenVisionLimit>): boolean {
    if (a === b) return true;
    if (a === undefined || b === undefined) return false;

    return (
      a.distance === b.distance ||
      a.falloff === b.falloff ||
      a.falloffStrength === b.falloffStrength
    );
  }
};

import {BooleanOperation, booleanType, ConstantOperation, constantType, ObjectType, Optional, ValueOperation, ValueType} from "common/types/generic/index.ts";
import {Type} from "common/types/type/index.ts";
import {MutableRef} from "common/ref";
import {SenseID} from "common/legends/game/sense/sense-i-d.ts";

export type EditorVision = {
  senseID: Optional<SenseID>;
  override: boolean;
};

export type EditorVisionOperation =
  | {type: "update-sense-i-d", operations: ValueOperation<Optional<SenseID>, ConstantOperation>[]}
  | {type: "update-override", operations: BooleanOperation[]}
  ;

export const editorVisionType: Type<EditorVision, EditorVisionOperation> = new ObjectType({
  senseID: new ValueType(constantType),
  override: booleanType
});

export const EditorVisionFn = {
  expand(valueRef: MutableRef<EditorVision, EditorVisionOperation[]>) {
    return {
      senseIDRef: valueRef.map<Optional<SenseID>, ValueOperation<Optional<SenseID>, ConstantOperation>[]>(value => value.senseID, (_, operations) => [{type: "update-sense-i-d", operations}]),
      overrideRef: valueRef.map<boolean, BooleanOperation[]>(value => value.override, (_, operations) => [{type: "update-override", operations}]),
    }
  }
};
import {z} from "zod";
import {Dnd5eActionTemplateSegmentID} from "../dnd-5e-action-template-segment-i-d.ts";
import {ConstantOperation, constantType, ListPropertyRef, ListType, ObjectType, ZodListOperation} from "../../../../../../../types/generic/index.ts";
import {Type} from "../../../../../../../types/type/index.ts";
import {MutableRef} from "#common/ref";
import {
  Dnd5eActionTemplateSegmentRollModifier,
  Dnd5eActionTemplateSegmentRollModifierOperation,
  dnd5eActionTemplateSegmentRollModifierType
} from "./dnd-5e-action-template-segment-roll-modifier.ts";
import {
  Dnd5eActionTemplateSegmentRollExpression,
  Dnd5eActionTemplateSegmentRollExpressionOperation,
  dnd5eActionTemplateSegmentRollExpressionType
} from "./dnd-5e-action-template-segment-roll-expression.ts";


export const Dnd5eActionTemplateSegmentRoll: z.ZodType<{
  actionSegmentID: string,
  expressions: Dnd5eActionTemplateSegmentRollExpression[],
  modifiers: Dnd5eActionTemplateSegmentRollModifier[]
}> = z.object({
  actionSegmentID: Dnd5eActionTemplateSegmentID,
  expressions: z.array(Dnd5eActionTemplateSegmentRollExpression),
  modifiers: z.array(Dnd5eActionTemplateSegmentRollModifier)
});
export type Dnd5eActionTemplateSegmentRoll = z.infer<typeof Dnd5eActionTemplateSegmentRoll>;
export const Dnd5eActionTemplateSegmentRollOperation = z.discriminatedUnion("type", [
  z.object({type: z.literal("update-action-segment-i-d"), operations: z.array(ConstantOperation)}),
  z.object({type: z.literal("update-expressions"), operations: z.array(ZodListOperation(Dnd5eActionTemplateSegmentRollExpression, Dnd5eActionTemplateSegmentRollExpressionOperation))}),
  z.object({type: z.literal("update-modifiers"), operations: z.array(ZodListOperation(Dnd5eActionTemplateSegmentRollModifier, Dnd5eActionTemplateSegmentRollModifierOperation))})
]);
export type Dnd5eActionTemplateSegmentRollOperation = z.infer<typeof Dnd5eActionTemplateSegmentRollOperation>;

export const dnd5eActionTemplateSegmentRollType: Type<Dnd5eActionTemplateSegmentRoll, Dnd5eActionTemplateSegmentRollOperation> = new ObjectType({
  actionSegmentID: constantType,
  expressions: new ListType(dnd5eActionTemplateSegmentRollExpressionType),
  modifiers: new ListType(dnd5eActionTemplateSegmentRollModifierType)
});

export function Dnd5eActionTemplateSegmentRollSignals(valueRef: MutableRef<Dnd5eActionTemplateSegmentRoll, Dnd5eActionTemplateSegmentRollOperation[]>) {
  return {
    expressionsRef: ListPropertyRef<Dnd5eActionTemplateSegmentRoll, Dnd5eActionTemplateSegmentRollOperation, Dnd5eActionTemplateSegmentRollExpression, Dnd5eActionTemplateSegmentRollExpressionOperation>(
      value => value.expressions,
      operations => [{type: "update-expressions", operations}]
    )(valueRef),
    modifiersRef: ListPropertyRef<Dnd5eActionTemplateSegmentRoll, Dnd5eActionTemplateSegmentRollOperation, Dnd5eActionTemplateSegmentRollModifier, Dnd5eActionTemplateSegmentRollModifierOperation>(
      value => value.modifiers,
      operations => [{type: "update-modifiers", operations}]
    )(valueRef)
  }
}

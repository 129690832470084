import {useMemo} from "react";
import {ConstantOperation, constantType, Optional, ValueFn, ValueOperation, ValueType} from "common/types/generic/index.ts";
import {Dnd5eActionCondition} from "common/legends/asset/sheet/dnd-5e/dnd-5e-action-definition/condition/dnd-5e-action-condition.ts";
import {Dnd5eEffectID} from "common/legends/asset/sheet/dnd-5e/dnd-5e-effect/index.ts";
import {MutableRef} from "common/ref";
import {ApplyAction} from "common/types/apply.ts";
import {applyAll} from "common/types/type/index.ts";

export function useConditionEffectIDRef(conditionRef: MutableRef<Optional<Dnd5eActionCondition>, ValueOperation<Optional<Dnd5eActionCondition>, ConstantOperation>[]>) {
  return useMemo(() => {
    const valueFn = (condition: Optional<Dnd5eActionCondition>): Optional<Dnd5eEffectID> => {
      return condition?.type === "effect" ? Dnd5eEffectID.parse(condition.data.effectID) : undefined;
    };
    const effectIDType = new ValueType<Optional<Dnd5eEffectID>, ConstantOperation>(constantType);
    return new MutableRef({
      value: () => valueFn(conditionRef.value),
      observe: conditionRef.map(valueFn).distinct().observe,
      apply: (fn: ApplyAction<Optional<Dnd5eEffectID>, ValueOperation<Optional<Dnd5eEffectID>, ConstantOperation>[]>) => conditionRef.apply(prev => {
        const prevEffectID = valueFn(prev)
        const operations = fn(prevEffectID);
        if (operations.length === 0) return [];
        const nextEffectID = applyAll(effectIDType, prevEffectID, operations);
        return ValueFn.set(prev, {type: "effect", data: {effectID: nextEffectID}});
      }).then(valueFn)
    });
  }, [conditionRef]);
}
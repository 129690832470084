import {SetFn} from "common/types/index.ts";
import {ButtonBar, ExpandableLabel, IconButton, InputGroup, InputGroupLabel, InputNumber, useToggle} from "#lib/components/index.ts";
import React, {Ref, useCallback, useMemo} from "react";
import {useObservable} from "#lib/qlab/index.ts";
import {Dnd5eDamageType, DND_5E_OFFENSIVE_DAMAGE_TYPES} from "common/legends/asset/sheet/dnd-5e/dnd-5e-damage-type.ts";
import {Fieldset} from "#lib/components/fieldset/fieldset.tsx";
import {twMerge} from "tailwind-merge";
import {FaTrash} from "react-icons/fa";
import {
  Dnd5eDamageReductionModifier,
  Dnd5eDamageReductionModifierOperation,
  Dnd5eDamageReductionModifierSignals
} from "common/legends/asset/sheet/dnd-5e/dnd-5e-modifier/dnd-5e-damage-reduction-modifier.ts";
import {FaHashtag} from "react-icons/fa6";
import {DAMAGE_TYPE_IMAGES} from "../../../../../common/systems/dnd5e/damage-types/index.ts";
import {DragIndicator} from "#lib/components/tree-view/drag-indicator.tsx";
import {MutableRef} from "common/ref";

export type Dnd5eDamageReductionModifierViewProps = {
  value: MutableRef<Dnd5eDamageReductionModifier, Dnd5eDamageReductionModifierOperation[]>;
  dragHandlerRef: Ref<HTMLButtonElement>;
  dragRefPreview: Ref<HTMLDivElement>;
  remove: () => void;
  readOnly?: boolean;
};
export function Dnd5eDamageReductionModifierView({value, dragHandlerRef, dragRefPreview, remove, readOnly}: Dnd5eDamageReductionModifierViewProps) {
  const {amount, damageTypes} = useMemo(() => Dnd5eDamageReductionModifierSignals(value), [value]);

  const toggleDamageType = useCallback((damageType: Dnd5eDamageType) => {
    damageTypes.apply(prev => prev.includes(damageType) ? SetFn.delete(damageType) : SetFn.insert(damageType));
  }, [damageTypes.apply]);

  const damageTypesValue = useObservable(damageTypes.observe, [], [damageTypes]);

  const [expanded, toggleExpanded] = useToggle(false);

  return <div role="list-item">
    <ButtonBar ref={dragRefPreview}>
      <ExpandableLabel expanded={expanded} toggleExpand={toggleExpanded}>
        <IconButton ref={dragHandlerRef} title="Move"><DragIndicator /></IconButton>
        <span className="px-4 font-bold flex items-center bg-zinc-800/50">Damage Reduction</span>
        <div className="shrink-0 flex flex-row gap-1 px-2 py-2 flex-1 overflow-hidden bg-zinc-800/50">
          {damageTypesValue.map(damageType =>
            <img key={damageType} className={twMerge(
              "w-6 h-6",
              !damageTypesValue.includes(damageType) ? "opacity-50" : ""
            )} alt={damageType} title={damageType} src={DAMAGE_TYPE_IMAGES[damageType]} />
          )}
        </div>
      </ExpandableLabel>
      {!readOnly && <IconButton title="Remove" variant="destructive" onClick={() => remove()}><FaTrash /></IconButton>}
    </ButtonBar>
    {expanded && <Fieldset className="gap-0.5">
      <InputGroup title="Reduction Amount">
        <InputGroupLabel className="px-0">
          <FaHashtag />
        </InputGroupLabel>
        <InputNumber value={amount} />
      </InputGroup>
      {DND_5E_OFFENSIVE_DAMAGE_TYPES.map(damageType => <ButtonBar key={damageType}>
        <InputGroup className="flex-1 pl-0 rounded-l-full ml-2" onClick={() => toggleDamageType(damageType)}>
          <InputGroupLabel className="flex-1 flex items-center gap-2">
            <img className={twMerge(
              "w-10 h-10",
              !damageTypesValue.includes(damageType) ? "opacity-50" : ""
            )} alt={damageType} src={DAMAGE_TYPE_IMAGES[damageType]} />
            <span className="flex-1">{damageType.toUpperCase()}</span>
          </InputGroupLabel>
        </InputGroup>
      </ButtonBar>)}
    </Fieldset>}
  </div>
}
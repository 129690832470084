import {ShapeNode} from "common/legends/index.ts";
import React from "react";
import {Color, ShapeFn, Transform} from "common/types/index.ts";
import {ElementHUDPass} from "./element-h-u-d-pass.tsx";
import {SelectionIndicator} from "./selection-indicator.tsx";
import {ModelProvider, usePVM} from "../../context/pvm-context.ts";
import {useIsSelected} from "../../context/use-is-selected.ts";

export function ShapeElementHUDPass({value: {id, children, transform, shape, origin, pivot}}: {
  value: ShapeNode;
}) {
  const {projection, view, model} = usePVM();
  const valueModel = Transform.divide(transform, model);
  const isSelected = useIsSelected(id);

  const shapeOrigin = ShapeFn.getShapeOrigin(shape, origin);
  const shapeSize = ShapeFn.getShapeSize(shape);

  return (<binder>
    <ModelProvider value={valueModel}>
      {[...children.reverse()].map((child) => <ElementHUDPass key={child.data.id} value={child} />)}
      {isSelected && <SelectionIndicator projection={projection} view={view} model={valueModel} origin={shapeOrigin} size={shapeSize} color={Color.WHITE} repeatX={1} repeatY={1} />}
      {isSelected && <SelectionIndicator projection={projection} view={view} model={valueModel} origin={[origin[0] - pivot[0] + 2, origin[1] - pivot[1] + 2]} size={[4, 4]} color={Color.GREEN} repeatX={1} repeatY={1} />}
    </ModelProvider>
  </binder>);
}

import {ConstantOperation, ValueOperation} from "common/types/index.ts";
import {ImageNode, ImageNodeOperation, ImageNodeSignals} from "common/legends/index.ts";
import {NameField} from "../name-field.tsx";
import {VisibilityLayerField} from "../visibility-layer-field.tsx";
import {OpacityField} from "../opacity-field.tsx";
import {SelectionMaskField} from "../selection-mask-field.tsx";
import {InputTransform} from "./transform/input-transform.tsx";
import {SizeField} from "../size-field.tsx";
import {useMemo} from "react";
import {InputFile} from "#lib/components/input/input-file.tsx";
import {OriginField} from "./origin-field.tsx";
import {ConditionsSection} from "./conditions/conditions-section.tsx";
import {MountableField} from "../mountable-field.tsx";
import {AttachableField} from "../attachable-field.tsx";
import {MutableRef} from "common/ref";
import {PivotField} from "./pivot-field.tsx";
import {SelectionRef} from "../../nav/editor/state/selection-ref.ts";
import {Field, FieldLabel, Panel, Section} from "#lib/components/panel-header.tsx";
import {RepeatField} from "./input-repeat.tsx";

export type ImageNodePropertiesProps = {
  value: MutableRef<ImageNode, ImageNodeOperation[]>;
  reference: SelectionRef;
  pinned: MutableRef<SelectionRef, ValueOperation<SelectionRef, ConstantOperation>[]>;
};
export function ImageNodeProperties({value, reference, pinned}: ImageNodePropertiesProps) {
  const {name, fileRef, normalRef, sizeRef, opacityRef, selectionMaskRef, transformRef, originRef, pivotRef, mountableRef, attachableRef, visibilityLayerRef, repeatXRef, repeatYRef, conditionsRef} = useMemo(() => ImageNodeSignals(value), [value]);

  return <div className="tab-content flex flex-col py-2 gap-1">
    <Panel title="Image Properties">
      <Section>
        <NameField value={name} reference={reference} pinned={pinned} />
        <Field>
          <FieldLabel>Image</FieldLabel>
          <InputFile className="flex-1" value={fileRef} />
        </Field>
        <Field>
          <FieldLabel>Normal</FieldLabel>
          <InputFile className="flex-1" value={normalRef} />
        </Field>
        <VisibilityLayerField value={visibilityLayerRef} />
        <SelectionMaskField value={selectionMaskRef} />
        <OpacityField value={opacityRef} />
        <InputTransform value={transformRef} />
        <OriginField valueRef={originRef} />
        <PivotField valueRef={pivotRef} />
        <SizeField value={sizeRef} />
        <RepeatField repeatXRef={repeatXRef} repeatYRef={repeatYRef} />
        <MountableField value={mountableRef}/>
        <AttachableField value={attachableRef}/>
      </Section>
      <ConditionsSection value={conditionsRef}/>
    </Panel>
  </div>
}
import {
  useArrayBuffer,
  useAttribLocation,
  useBindVertexArribArray,
  useElementArrayBuffer,
  useProgram,
  useShader,
  useUniformLocation,
  useVertexBuffer
} from "#lib/gl-react/index.ts";
import React, {useMemo} from "react";


const vertexShader = `#version 300 es
precision highp float;

in vec2 a_position;
in vec2 a_tex_coord;

out vec2 fragCoord;
out vec2 v_tex_coord;

void main()
{
  gl_Position = vec4(a_position, 0, 1);
  v_tex_coord = a_tex_coord;
}
`;

const fragmentShader = `#version 300 es
precision highp float;
precision highp sampler2DArray;

in vec2 v_tex_coord;
uniform sampler2D u_texture;
uniform sampler2D u_mask;

out vec4 outColor;

void main() {
  outColor = texture(u_texture, v_tex_coord) * texture(u_mask, v_tex_coord);
}
`;

export function TextureMaskShader({texture, mask}: {
  texture: WebGLTexture;
  mask: WebGLTexture
}) {
  const program = useProgram(
    useShader(WebGL2RenderingContext.VERTEX_SHADER, vertexShader),
    useShader(WebGL2RenderingContext.FRAGMENT_SHADER, fragmentShader)
  );
  const vbo = useArrayBuffer(useMemo(() => {
    return new Float32Array([
      -1, -1, 0, 0,
       1, -1, 1, 0,
       1,  1, 1, 1,
      -1,  1, 0, 1
    ]);
  }, []));
  const vao = useVertexBuffer();
  useBindVertexArribArray(vao, useAttribLocation(program, "a_position"), vbo, 2, WebGL2RenderingContext.FLOAT, false, 4 * 4, 0);
  useBindVertexArribArray(vao, useAttribLocation(program, "a_tex_coord"), vbo, 2, WebGL2RenderingContext.FLOAT, false, 4 * 4, 2 * 4);

  const ebo = useElementArrayBuffer(useMemo(() => new Uint16Array([0, 1, 2, 2, 3, 0]), []));

  return (<program value={program}>
    <activeTexture texture={WebGL2RenderingContext.TEXTURE0}/>
    <texture2d value={texture}>
      <uniform1i location={useUniformLocation(program, "u_texture")} data={0}/>
      <activeTexture texture={WebGL2RenderingContext.TEXTURE1}/>
      <texture2d value={mask}>
        <uniform1i location={useUniformLocation(program, "u_mask")} data={1}/>
        <vertexArray value={vao}>
          <elementArrayBuffer value={ebo}>
            <drawElements mode={WebGL2RenderingContext.TRIANGLES} type={WebGL2RenderingContext.UNSIGNED_SHORT} offset={0} count={6}/>
          </elementArrayBuffer>
        </vertexArray>
      </texture2d>
    </texture2d>
    <activeTexture texture={WebGL2RenderingContext.TEXTURE0}/>
  </program>);
}

import {DiceExpressionTree} from "./parse-dice-expression.ts";

export type DiceRoll = {
  minValue: number;
  maxValue: number;
}

export type DiceRangeResult =
  | {op: "constant", minValue: number, maxValue: number}
  | {op: "add", minValue: number, maxValue: number}
  | {op: "subtract", minValue: number, maxValue: number}
  | {op: "multiply", minValue: number, maxValue: number}
  | {op: "divide", minValue: number, maxValue: number}
  | {op: "remainder", minValue: number, maxValue: number}
  | {op: "variable", minValue: number, maxValue: number}
  | {op: "min", minValue: number, maxValue: number}
  | {op: "max", minValue: number, maxValue: number}
  | {op: "parentheses", minValue: number, maxValue: number}

  | {op: "dice", minValue: number, maxValue: number, minValues: DiceRoll[], maxValues: DiceRoll[]}
  | {op: "reroll", minValue: number, maxValue: number, minValues: DiceRoll[], maxValues: DiceRoll[]}
  | {op: "reroll-once", minValue: number, maxValue: number, minValues: DiceRoll[], maxValues: DiceRoll[]}
  | {op: "keep-highest", minValue: number, maxValue: number, minValues: DiceRoll[], maxValues: DiceRoll[]}
  | {op: "keep-lowest", minValue: number, maxValue: number, minValues: DiceRoll[], maxValues: DiceRoll[]}
  | {op: "drop-highest", minValue: number, maxValue: number, minValues: DiceRoll[], maxValues: DiceRoll[]}
  | {op: "drop-lowest", minValue: number, maxValue: number, minValues: DiceRoll[], maxValues: DiceRoll[]}
  | {op: "explode", minValue: number, maxValue: number, minValues: DiceRoll[], maxValues: DiceRoll[]}
  ;

function rollDice(faces: number): DiceRoll {
  return {minValue: 1, maxValue: faces};
}

export function getDiceRange(diceFormula: DiceExpressionTree, context: {[variable: string]: number}): DiceRangeResult {
  switch (diceFormula.op) {
    case "constant": return {op: "constant", minValue: diceFormula.value, maxValue: diceFormula.value};
    case "variable": return {op: "variable", minValue: context[diceFormula.variable.toUpperCase()] || 0, maxValue: context[diceFormula.variable.toUpperCase()] || 0};
    case "dice": {
      const count = getDiceRange(diceFormula.count, context);
      const faces = getDiceRange(diceFormula.faces, context);
      const minValues = [];
      for (let i = 0; i < count.minValue; i ++) {
        minValues.push(rollDice(faces.minValue));
      }
      const maxValues = [];
      for (let i = 0; i < count.maxValue; i ++) {
        maxValues.push(rollDice(faces.maxValue));
      }
      return {
        op: "dice",
        minValue: minValues.reduce((a,b) => a+b.minValue, 0),
        maxValue: maxValues.reduce((a,b) => a+b.maxValue, 0),
        minValues,
        maxValues
      };
    }
    case "reroll": {
      const dice = getDiceRange(diceFormula.dice, context);
      if (dice.op === "dice" || dice.op === "reroll" || dice.op === "reroll-once" || dice.op === "keep-highest" || dice.op === "keep-lowest" || dice.op === "drop-highest" || dice.op === "drop-lowest" || dice.op === "explode") {
        return dice; // TODO: Not Supported
      } else {
        throw new Error("Unsupported Dice Formula");
      }
    }
    case "reroll-once": {
      return getDiceRange(diceFormula.dice, context);
    }
    case "keep-highest": {
      const dice = getDiceRange(diceFormula.dice, context);
      const count = getDiceRange(diceFormula.count, context);

      if (dice.op === "dice" || dice.op === "reroll" || dice.op === "reroll-once" || dice.op === "keep-highest" || dice.op === "keep-lowest" || dice.op === "drop-highest" || dice.op === "drop-lowest" || dice.op === "explode") {
        dice.minValues.sort((a, b) => b.minValue - a.minValue);
        const minValues = dice.minValues.slice(0, count.minValue);
        dice.maxValues.sort((a, b) => b.maxValue - a.maxValue);
        const maxValues = dice.maxValues.slice(0, count.maxValue);
        return {
          op: "keep-highest",
          minValue: minValues.reduce((a, b) => a + b.minValue, 0),
          maxValue: maxValues.reduce((a, b) => a + b.maxValue, 0),
          minValues,
          maxValues
        };
      } else {
        throw new Error("Unsupported Dice Formula");
      }
    }
    case "drop-highest": {
      const dice = getDiceRange(diceFormula.dice, context);
      const count = getDiceRange(diceFormula.count, context);
      if (dice.op === "dice" || dice.op === "reroll" || dice.op === "reroll-once" || dice.op === "keep-highest" || dice.op === "keep-lowest" || dice.op === "drop-highest" || dice.op === "drop-lowest" || dice.op === "explode") {
        dice.minValues.sort((a, b) => b.minValue - a.minValue);
        const minValues = dice.minValues.slice(count.maxValue);
        dice.maxValues.sort((a, b) => b.maxValue - a.maxValue);
        const maxValues = dice.maxValues.slice(count.minValue);
        return {
          op: "drop-highest",
          minValue: minValues.reduce((a, b) => a + b.minValue, 0),
          maxValue: maxValues.reduce((a, b) => a + b.maxValue, 0),
          minValues,
          maxValues
        };
      } else {
        throw new Error("Unsupported Dice Formula");
      }
    }
    case "keep-lowest": {
      const dice = getDiceRange(diceFormula.dice, context);
      const count = getDiceRange(diceFormula.count, context);
      if (dice.op === "dice" || dice.op === "reroll" || dice.op === "reroll-once" || dice.op === "keep-highest" || dice.op === "keep-lowest" || dice.op === "drop-highest" || dice.op === "drop-lowest" || dice.op === "explode") {
        dice.minValues.sort((a, b) => a.minValue - b.minValue);
        const minValues = dice.minValues.slice(0, count.minValue);
        dice.maxValues.sort((a, b) => a.maxValue - b.maxValue);
        const maxValues = dice.maxValues.slice(0, count.maxValue);
        return {
          op: "keep-lowest",
          minValue: minValues.reduce((a, b) => a + b.minValue, 0),
          maxValue: maxValues.reduce((a, b) => a + b.maxValue, 0),
          minValues,
          maxValues
        };
      } else {
        throw new Error("Unsupported Dice Formula");
      }
    }
    case "drop-lowest": {
      const dice = getDiceRange(diceFormula.dice, context);
      const count = getDiceRange(diceFormula.count, context);

      if (dice.op === "dice" || dice.op === "reroll" || dice.op === "reroll-once" || dice.op === "keep-highest" || dice.op === "keep-lowest" || dice.op === "drop-highest" || dice.op === "drop-lowest" || dice.op === "explode") {
        dice.minValues.sort((a, b) => a.minValue - b.minValue);
        const minValues = dice.minValues.slice(count.maxValue);
        dice.maxValues.sort((a, b) => a.maxValue - b.maxValue);
        const maxValues = dice.maxValues.slice(count.minValue);

        return {
          op: "drop-lowest",
          minValue: minValues.reduce((a, b) => a + b.minValue, 0),
          maxValue: maxValues.reduce((a, b) => a + b.maxValue, 0),
          minValues,
          maxValues
        };
      } else {
        throw new Error("Unsupported Dice Formula");
      }
    }
    case "explode": {
      const dice = getDiceRange(diceFormula.dice, context);
      if (dice.op === "dice" || dice.op === "reroll" || dice.op === "reroll-once" || dice.op === "keep-highest" || dice.op === "keep-lowest" || dice.op === "drop-highest" || dice.op === "drop-lowest" || dice.op === "explode") {
        // TODO: Not Implemented
        return dice;
      } else {
        throw new Error("Unsupported Dice Formula");
      }
    }
    case "add": {
      const left = getDiceRange(diceFormula.left, context);
      const right = getDiceRange(diceFormula.right, context);
      return {op: "add", minValue: left.minValue + right.minValue, maxValue: left.maxValue + right.maxValue};
    }
    case "subtract": {
      const left = getDiceRange(diceFormula.left, context);
      const right = getDiceRange(diceFormula.right, context);
      return {op: "subtract", minValue: left.minValue - right.maxValue, maxValue: left.maxValue - right.minValue};
    }
    case "multiply": {
      const left = getDiceRange(diceFormula.left, context);
      const right = getDiceRange(diceFormula.right, context);
      return {op: "multiply", minValue: left.minValue * right.minValue, maxValue: left.maxValue * right.maxValue};
    }
    case "divide": {
      const left = getDiceRange(diceFormula.left, context);
      const right = getDiceRange(diceFormula.right, context);
      return {op: "divide", minValue: Math.floor(left.minValue / right.maxValue), maxValue: Math.floor(left.maxValue / right.minValue)};
    }
    case "remainder": {
      const left = getDiceRange(diceFormula.left, context);
      const right = getDiceRange(diceFormula.right, context);
      return {
        op: "remainder",
        minValue: Math.min(
          Math.min(left.minValue % right.minValue, left.minValue % right.minValue),
          Math.min(left.maxValue % right.minValue, left.maxValue % right.minValue),
        ),
        maxValue: Math.max(
          Math.max(left.minValue % right.minValue, left.minValue % right.minValue),
          Math.max(left.maxValue % right.minValue, left.maxValue % right.minValue),
        )
      };
    }
    case "min": {
      const left = getDiceRange(diceFormula.left, context);
      const right = getDiceRange(diceFormula.right, context);
      return {op: "min", minValue: Math.min(left.minValue, right.minValue), maxValue: Math.min(left.maxValue, right.maxValue)};
    }
    case "max": {
      const left = getDiceRange(diceFormula.left, context);
      const right = getDiceRange(diceFormula.right, context);
      return {op: "max", minValue: Math.max(left.minValue, right.minValue), maxValue: Math.max(left.maxValue, right.maxValue)};
    }
    case "parentheses": {
      return getDiceRange(diceFormula.expression, context);
    }
    case "count-success": {
      return getDiceRange(diceFormula.dice, context);
    }
    case "count-failure": {
      return getDiceRange(diceFormula.dice, context);
    }
  }
  return {op: "constant", minValue: 0, maxValue: 0};
}

import {Node, NodeId} from "common/legends/index.ts";
import {AccessMaskFn} from "common/legends/visibility/index.ts";
import {PreVisitResult, VisitResult, walkTree} from "common/types/index.ts";
import {Vision} from "common/legends/asset/token/vision/vision.ts";

export function isNodeVisible(node: Node[], nodeID: NodeId, vision: Vision[]) {
  let found = false;
  walkTree(node, {
    preVisit(node) {
      if (!vision.some(vision => AccessMaskFn.canSee(vision.accessMask, node.data.visibilityLayer) || vision.visibilityNodeIDs.includes(node.data.id))) {
        return PreVisitResult.SKIP_VISIT;
      }
    },
    visit(node) {
      if (node.data.id === nodeID) {
        found = true;
        return VisitResult.TERMINATE;
      }
    }
  });
  return found;
}

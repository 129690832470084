import {TokenVisionLimit, TokenVisionLimitFn, TokenVisionLimitOperation} from "common/legends/asset/token/token-vision-limit.ts";
import {MutableRef} from "common/ref";
import {InputGroup, InputNumber} from "#lib/components/index.ts";
import {useMemo} from "react";
import {Field, FieldLabel} from "#lib/components/panel-header.tsx";

export function VisionLimitFields({valueRef}: {
  valueRef: MutableRef<TokenVisionLimit, TokenVisionLimitOperation[]>
}) {
  const {distanceRef, falloffRef, falloffStrengthRef} = useMemo(() => TokenVisionLimitFn.expand(valueRef), [valueRef])

  return <>
    <Field>
      <FieldLabel>Distance</FieldLabel>
      <InputGroup className="mx-2 rounded-md overflow-hidden">
        <InputNumber value={distanceRef} />
      </InputGroup>
    </Field>
    <Field>
      <FieldLabel>Falloff Distance</FieldLabel>
      <InputGroup className="mx-2 rounded-md overflow-hidden">
        <InputNumber value={falloffRef} />
      </InputGroup>
    </Field>
    <Field>
      <FieldLabel>Falloff Strength</FieldLabel>
      <InputGroup className="mx-2 rounded-md overflow-hidden">
        <InputNumber value={falloffStrengthRef} />
      </InputGroup>
    </Field>
  </>;
}

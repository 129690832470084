import {z} from "zod";
import {Dnd5eModifierID} from "#common/legends/asset/sheet/dnd-5e/dnd-5e-modifier/dnd-5e-modifier-i-d.ts";
import {ConstantOperation, constantType, ObjectType, Optional, OptionalType, ValueOperation, ValueType} from "#common/types/index.ts";
import {MutableRef} from "#common/ref";
import {PropertyRef, Type} from "../../../../../types/index.ts";

export const Dnd5eTrait = z.union([
  z.literal("elven-accuracy"),
  z.literal("halfling-luck"),
  z.literal("jack-of-all-trades"),
  z.literal("reliable-talent"),
  z.literal("silver-tongue"),
  z.literal("glibness")
]);
export type Dnd5eTrait = z.infer<typeof Dnd5eTrait>;
export const DND_5E_TRAITS = [
  "elven-accuracy",
  "halfling-luck",
  "jack-of-all-trades",
  "reliable-talent",
  "silver-tongue",
  "glibness"
] as const satisfies readonly Dnd5eTrait[];

export const Dnd5eTraitModifier = z.object({
  modifierID: Dnd5eModifierID,
  trait: z.optional(Dnd5eTrait)
});
export type Dnd5eTraitModifier = z.infer<typeof Dnd5eTraitModifier>;

export const Dnd5eTraitModifierOperation = z.discriminatedUnion("type", [
  z.object({type: z.literal("update-modifier-i-d"), operations: z.array(ConstantOperation)}),
  z.object({type: z.literal("update-trait"), operations: z.array(ValueOperation(z.optional(Dnd5eTrait), ConstantOperation))})
]);
export type Dnd5eTraitModifierOperation = z.infer<typeof Dnd5eTraitModifierOperation>;

export const dnd5eTraitModifierType: Type<Dnd5eTraitModifier, Dnd5eTraitModifierOperation> = new ObjectType({
  modifierID: constantType,
  trait: new ValueType(new OptionalType(constantType))
});

export function Dnd5eTraitModifierRef(value: MutableRef<Dnd5eTraitModifier, Dnd5eTraitModifierOperation[]>) {
  return ({
    trait: PropertyRef<Dnd5eTraitModifier, Dnd5eTraitModifierOperation, Optional<Dnd5eTrait>, ValueOperation<Optional<Dnd5eTrait>, ConstantOperation>>(
      value => value.trait,
      operations => [{type: "update-trait", operations}]
    )(value)
  });
}

import {Dnd5eSpellLevel} from "./dnd-5e-spell-level.ts";
import {
  BooleanOperation,
  booleanType,
  ConstantOperation,
  constantType,
  ListOperation,
  ListType,
  ObjectType,
  RichText,
  RichTextOperation,
  richTextType,
  SetOperation,
  SetPropertySignal,
  SetType,
  StringOperation,
  stringType,
  Type,
  ValueOperation,
  ValueType
} from "#common/types/index.ts";
import {z} from "zod";
import {ulid} from "ulid";
import {Dnd5eSpellComponent} from "#common/legends/asset/sheet/dnd-5e/character/dnd-5e-spell-component.ts";
import {Dnd5eSpellSchool} from "#common/legends/asset/sheet/dnd-5e/character/dnd-5e-spell-school.ts";
import {PropertyRef} from "#common/types/generic/object/property-ref.ts";
import {ValuePropertyRef} from "#common/types/generic/value/value-property-ref.ts";
import {ListPropertyRef} from "#common/types/generic/list/list-property-ref.ts";
import {MutableRef} from "#common/ref";
import {Dnd5eActionTemplate, Dnd5eActionTemplateFn, Dnd5eActionTemplateOperation, dnd5eActionTemplateType} from "../dnd-5e-action-definition/template/dnd-5e-action-template.ts";

export const Dnd5eSpellID = z.string().brand("Dnd5eSpellID");
export type Dnd5eSpellID = z.infer<typeof Dnd5eSpellID>;
export function generateDnd5eSpellID() {
  return ulid() as Dnd5eSpellID;
}

export const Dnd5eSpell = z.object({
  spellID: Dnd5eSpellID,
  name: z.string(),

  ritual: z.boolean(),
  castingTime: z.string(),
  range: z.string(),
  materialComponent: z.string(),
  royaltyComponent: z.string(),
  concentration: z.boolean(),
  duration: z.string(),
  components: z.array(Dnd5eSpellComponent),

  description: RichText,
  school: Dnd5eSpellSchool,
  level: Dnd5eSpellLevel,
  prepared: z.boolean(),
  actions: z.array(Dnd5eActionTemplate)
});
export type Dnd5eSpell = z.infer<typeof Dnd5eSpell>;

export type Dnd5eSpellOperation =
  | {type: "update-spell-i-d", operations: ConstantOperation[]}
  | {type: "update-description", operations: RichTextOperation[]}
  | {type: "update-school", operations: ValueOperation<Dnd5eSpellSchool, ConstantOperation>[]}
  | {type: "update-level", operations: ValueOperation<Dnd5eSpellLevel, ConstantOperation>[]}
  | {type: "update-prepared", operations: BooleanOperation[]}
  | {type: "update-ritual", operations: BooleanOperation[]}
  | {type: "update-name", operations: StringOperation[]}
  | {type: "update-actions", operations: ListOperation<Dnd5eActionTemplate, Dnd5eActionTemplateOperation>[]}

  | {type: "update-casting-time", operations: StringOperation[]}
  | {type: "update-range", operations: StringOperation[]}
  | {type: "update-material-component", operations: StringOperation[]}
  | {type: "update-royalty-component", operations: StringOperation[]}
  | {type: "update-concentration", operations: BooleanOperation[]}
  | {type: "update-duration", operations: StringOperation[]}
  | {type: "update-components", operations: SetOperation<Dnd5eSpellComponent>[]}
  ;

export const dnd5eSpellType: Type<Dnd5eSpell, Dnd5eSpellOperation> = new ObjectType({
  spellID: constantType,
  name: stringType,
  description: richTextType,
  prepared: booleanType,
  ritual: booleanType,
  school: new ValueType(constantType),
  level: new ValueType(constantType),
  actions: new ListType(dnd5eActionTemplateType),
  castingTime: stringType,
  range: stringType,
  materialComponent: stringType,
  royaltyComponent: stringType,
  concentration: booleanType,
  duration: stringType,
  components: new SetType<Dnd5eSpellComponent>(),
}, (value: any) => {
  let v = {...value};
  if (v["castingTime"] === undefined) v["castingTime"] = "";
  if (v["range"] === undefined) v["range"] = "";
  if (v["materialComponent"] === undefined) v["materialComponent"] = "";
  if (v["royaltyComponent"] === undefined) v["royaltyComponent"] = "";
  if (v["concentration"] === undefined) v["concentration"] = false;
  if (v["ritual"] === undefined) v["ritual"] = false;
  if (v["duration"] === undefined) v["duration"] = "";
  if (v["components"] === undefined) v["components"] = [];
  if (v["school"] === undefined) v["school"] = "evocation";
  if (typeof v["level"] === "number") v["level"] = `${v["level"]}`;
  return v;
});

export const Dnd5eSpellFn = {
  getSpellID(item: Dnd5eSpell) {return item.spellID},
  copySpell: (spell: Dnd5eSpell): Dnd5eSpell => ({
    ...spell,
    actions: spell.actions.map(Dnd5eActionTemplateFn.copyActionTemplate),
    spellID: generateDnd5eSpellID()
  })
};

export function Dnd5eSpellSignal(signal: MutableRef<Dnd5eSpell, Dnd5eSpellOperation[]>) {
  return {
    name: PropertyRef<Dnd5eSpell, Dnd5eSpellOperation, string, StringOperation>(
      value => value.name,
      operations => [{type: "update-name", operations}]
    )(signal),
    description: PropertyRef<Dnd5eSpell, Dnd5eSpellOperation, RichText, RichTextOperation>(
      value => value.description,
      operations => [{type: "update-description", operations}]
    )(signal),
    level: ValuePropertyRef<Dnd5eSpell, Dnd5eSpellOperation, Dnd5eSpellLevel, ConstantOperation>(
      value => value.level,
      operations => [{type: "update-level", operations}]
    )(signal),
    prepared: PropertyRef<Dnd5eSpell, Dnd5eSpellOperation, boolean, BooleanOperation>(
      value => value.prepared,
      operations => [{type: "update-prepared", operations}]
    )(signal),
    actionsRef: ListPropertyRef<Dnd5eSpell, Dnd5eSpellOperation, Dnd5eActionTemplate, Dnd5eActionTemplateOperation>(
      value => value.actions,
      operations => [{type: "update-actions", operations}]
    )(signal),
    ritual: PropertyRef<Dnd5eSpell, Dnd5eSpellOperation, boolean, BooleanOperation>(
      value => value.ritual,
      operations => [{type: "update-ritual", operations}]
    )(signal),
    school: ValuePropertyRef<Dnd5eSpell, Dnd5eSpellOperation, Dnd5eSpellSchool, ConstantOperation>(
      value => value.school,
      operations => [{type: "update-school", operations}]
    )(signal),
    castingTime: PropertyRef<Dnd5eSpell, Dnd5eSpellOperation, string, StringOperation>(
      value => value.castingTime,
      operations => [{type: "update-casting-time", operations}]
    )(signal),
    range: PropertyRef<Dnd5eSpell, Dnd5eSpellOperation, string, StringOperation>(
      value => value.range,
      operations => [{type: "update-range", operations}]
    )(signal),
    materialComponent: PropertyRef<Dnd5eSpell, Dnd5eSpellOperation, string, StringOperation>(
      value => value.materialComponent,
      operations => [{type: "update-material-component", operations}]
    )(signal),
    royaltyComponent: PropertyRef<Dnd5eSpell, Dnd5eSpellOperation, string, StringOperation>(
      value => value.royaltyComponent,
      operations => [{type: "update-royalty-component", operations}]
    )(signal),
    concentration: PropertyRef<Dnd5eSpell, Dnd5eSpellOperation, boolean, BooleanOperation>(
      value => value.concentration,
      operations => [{type: "update-concentration", operations}]
    )(signal),
    duration: PropertyRef<Dnd5eSpell, Dnd5eSpellOperation, string, StringOperation>(
      value => value.duration,
      operations => [{type: "update-duration", operations}]
    )(signal),
    components: SetPropertySignal<Dnd5eSpell, Dnd5eSpellOperation, Dnd5eSpellComponent>(
      value => value.components,
      operations => [{type: "update-components", operations}]
    )(signal)
  };
}

import {IconButton, InputGroup, Select} from "#lib/components/index.ts";
import {FaTrash} from "react-icons/fa";
import {ValueFn} from "common/types/index.ts";
import {DND_5E_DAMAGE_TYPE_TITLES, DND_5E_DAMAGE_TYPES, isDamageType} from "common/legends/asset/sheet/dnd-5e/dnd-5e-damage-type.ts";
import React, {useMemo} from "react";
import {twMerge} from "tailwind-merge";
import {InputGroupIcon} from "#lib/components/input/input-group-icon.tsx";
import {useRefValue} from "#lib/signal/index.ts";
import {DragIndicator} from "#lib/components/tree-view/drag-indicator.tsx";
import {
  Dnd5eActionTemplateSegmentRollExpression,
  Dnd5eActionTemplateSegmentRollExpressionOperation,
  Dnd5eActionTemplateSegmentRollExpressionSignals
} from "common/legends/asset/sheet/dnd-5e/dnd-5e-action-definition/segment/damage/dnd-5e-action-template-segment-roll-expression.ts";
import {DAMAGE_TYPE_IMAGES, DAMAGE_TYPE_UNKNOWN} from "../../../../../../../common/systems/dnd5e/damage-types";
import {InputDiceExpression} from "../../../../dice-expression/input-dice-expression.tsx";
import {InputListItemProps, useDragListItem} from "#lib/components/list/input-list.tsx";

export function Dnd5eActionTemplateSegmentRollExpressionListItem({item, remove}: InputListItemProps<Dnd5eActionTemplateSegmentRollExpression, Dnd5eActionTemplateSegmentRollExpressionOperation>) {
  const [dragHandlerRef, dragRefPreview] = useDragListItem("legends/action-template-segment-damage-expression", item, remove);
  const {rollTypeRef, expressionRef} = useMemo(() => Dnd5eActionTemplateSegmentRollExpressionSignals(item), [item]);
  const rollType = useRefValue(rollTypeRef);
  return (<div ref={dragRefPreview} role="list-item" className="flex flex-row">
    <InputGroup className="pl-0">
      <IconButton ref={dragHandlerRef} title="Move"><DragIndicator /></IconButton>
    </InputGroup>
    <InputGroup className="flex-1">
      <InputGroupIcon>
        <img alt={rollType?.toUpperCase() || "Unknown"} className={twMerge(
          "w-8 h-8"
        )} title={"Damage"}
             src={rollType ? DAMAGE_TYPE_IMAGES[rollType] : DAMAGE_TYPE_UNKNOWN}/>
      </InputGroupIcon>
      <Select value={rollType || ""} onChange={(ev) => {
        const next = ev.target.value;
        rollTypeRef.apply(prev => ValueFn.set(prev, isDamageType(next) ? next : undefined));
      }}>
        <option value={""}>N/A</option>
        {DND_5E_DAMAGE_TYPES.map(damageType => <option key={damageType} value={damageType}>{DND_5E_DAMAGE_TYPE_TITLES[damageType]}</option>)}
      </Select>
    </InputGroup>
    <InputGroup className="flex-1">
      <InputDiceExpression value={expressionRef} />
    </InputGroup>
    <IconButton variant="destructive" title="Remove Damage" onClick={() => remove()}><FaTrash /></IconButton>
  </div>);
}

import {Apply, ApplyAction, ExtractOperation, ExtractValue, Optional} from "common/types/index.ts";
import {useCallback} from "react";
import {PlayerStackOperation, PlayerStackType, PlayerStackTypes, PlayerStackValue} from "./player-stack-type.ts";

export function usePlayerStackContent<
  Type extends PlayerStackType,
  Value = ExtractValue<PlayerStackTypes[Type]>,
  Operation = ExtractOperation<PlayerStackTypes[Type]>
>(type: Type, value: PlayerStackValue, apply: Apply<Optional<PlayerStackValue>, PlayerStackOperation[]>): [Value, Apply<Value, Operation[]>] {
  const applyToStackItemContent = useCallback((action: ApplyAction<Value, Operation[]>) => {
    return apply((prev): PlayerStackOperation[] => {
      if (prev?.type !== type) return [];
      const operations = typeof action === "function" ? action(prev.data as unknown as Value) : action;
      return [{
        type,
        operations
      }] as unknown as PlayerStackOperation[];
    });
  }, [apply, type]);
  return [value.data as unknown as Value, applyToStackItemContent as Apply<Value, Operation[]>];
}

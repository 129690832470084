import {GridNode} from "common/legends/index.ts";
import {Transform} from "common/types/index.ts";
import React from "react";
import {useGrid} from "../../context/grid-context.ts";
import {ElementRenderPass} from "./element-render-pass.tsx";
import {ModelProvider, usePVM} from "../../context/pvm-context.ts";
import {OpacityProvider, useOpacity} from "../../context/opacity-context.ts";
import {SquareGridShader} from "../../shader/grid/square-grid-shader.tsx";
import {HexagonHorizontalGridShader} from "../../shader/grid/hexagon-horizontal-grid-shader.tsx";
import {HexagonVerticalGridShader} from "../../shader/grid/hexagon-vertical-grid-shader.tsx";
import {IsometricGridShader} from "../../shader/grid/isometric-grid-shader.tsx";

export function GridElementRenderPass({value}: {
  value: GridNode;
}) {
  const grid = useGrid();
  const {projection, view, model} = usePVM();
  const valueOpacity = value.opacity * useOpacity();
  let valueModel = Transform.divide(value.transform, model);
  return (<binder>
    <OpacityProvider value={valueOpacity}>
      <ModelProvider value={valueModel}>
        {grid.shape === "square" && <SquareGridShader value={value} projection={projection} model={valueModel} view={view} />}
        {grid.shape === "hexagon-horizontal" && <HexagonHorizontalGridShader value={value} projection={projection} model={valueModel} view={view} />}
        {grid.shape === "hexagon-vertical" && <HexagonVerticalGridShader value={value} projection={projection} model={valueModel} view={view} />}
        {grid.shape === "isometric" && <IsometricGridShader value={value} projection={projection} model={valueModel} view={view} />}
        {[...value.children].reverse().map((element) => <ElementRenderPass key={element.data.id} value={element} />)}
      </ModelProvider>
    </OpacityProvider>
  </binder>);
}
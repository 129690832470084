import {Node} from "common/legends/index.ts";
import {useIsElementAccessible, useIsElementVisible} from "../../context/visibility-context.ts";
import {useConditionsMet} from "../../context/use-conditions-met.ts";
import {AreaElementHUDPass} from "./area-element-h-u-d-pass.tsx";
import {GroupElementHUDPass} from "./group-element-h-u-d-pass.tsx";
import {ShapeElementHUDPass} from "./shape-element-h-u-d-pass.tsx";
import {TextElementHUDPass} from "./text-element-h-u-d-pass.tsx";
import {TokenElementHUDPass} from "./token-element-h-u-d-pass.tsx";
import {WallElementHUDPass} from "./wall-element-h-u-d-pass.tsx";
import {ParallaxElementHUDPass} from "./parallax-element-h-u-d-pass.tsx";
import {ImageElementHUDPass} from "./image-element-h-u-d-pass.tsx";
import {LightElementHUDPass} from "./light-element-h-u-d-pass.tsx";
import React from "react";
import {VideoElementHUDPass} from "./video-element-h-u-d-pass.tsx";

export const ElementHUDPass = React.memo(function ElementHUDPass({value}: {
  value: Node;
}) {
  const isVisible = useIsElementVisible()(value);
  const isAccessible = useIsElementAccessible()(value);
  const conditionsMet = useConditionsMet(value.data.conditions);

  if ((!conditionsMet || !isVisible) && !isAccessible) return <></>;

  if (value.type === "area") return <AreaElementHUDPass value={value.data}/>
  // else if (value.type === "grid") return <GridElementHUDPass value={value.data}/>
  else if (value.type === "group") return <GroupElementHUDPass value={value.data} />
  else if (value.type === "image") return <ImageElementHUDPass value={value.data} />
  else if (value.type === "shape") return <ShapeElementHUDPass value={value.data}/>
  else if (value.type === "text") return <TextElementHUDPass value={value.data}/>
  else if (value.type === "token") return <TokenElementHUDPass value={value.data}/>
  else if (value.type === "wall") return <WallElementHUDPass value={value.data}/>
  else if (value.type === "parallax") return <ParallaxElementHUDPass value={value.data}/>
  else if (value.type === "light") return <LightElementHUDPass value={value.data} />
  else if (value.type === "video") return <VideoElementHUDPass value={value.data} />

  return <binder>
    {[...value.data.children].reverse().map(child => <ElementHUDPass key={child.data.id} value={child} />)}
  </binder>
});

import {
  BooleanOperation,
  booleanType,
  ConstantOperation,
  constantType,
  ListOperation,
  ListType,
  NumberOperation,
  numberType,
  ObjectType,
  RichText,
  RichTextFn,
  RichTextOperation,
  richTextType,
  StringOperation,
  stringType,
  Type
} from "#common/types/index.ts";
import {z} from "zod";
import {Dnd5eResource, Dnd5eResourceOperation, dnd5eResourceType} from "#common/legends/asset/sheet/dnd-5e/dnd-5e-resource/dnd-5e-resource.ts";
import {Dnd5eModifier, Dnd5eModifierOperation, dnd5eModifierType} from "#common/legends/asset/sheet/dnd-5e/dnd-5e-modifier/dnd-5e-modifier.ts";
import {Dnd5eItemID, generateDnd5eItemID} from "#common/legends/asset/sheet/dnd-5e/character/dnd-5e-item-i-d.ts";
import {PropertyRef} from "#common/types/generic/object/property-ref.ts";
import {ListPropertyRef} from "#common/types/generic/list/list-property-ref.ts";
import {Dnd5eItemEffect, Dnd5eItemEffectOperation, dnd5eItemEffectType} from "#common/legends/asset/sheet/dnd-5e/character/item/effect/dnd-5e-item-effect.ts";
import {generateDnd5eModifierID} from "#common/legends/asset/sheet/dnd-5e/dnd-5e-modifier/dnd-5e-modifier-i-d.ts";
import {MutableRef} from "#common/ref";
import {Dnd5eActionTemplate, Dnd5eActionTemplateOperation, dnd5eActionTemplateType} from "../dnd-5e-action-definition/template/dnd-5e-action-template.ts";

export const Dnd5eInventoryItem = z.object({
  itemID: Dnd5eItemID,
  equipped: z.boolean(),
  qty: z.number(),
  item: z.string(),
  description: RichText,
  weight: z.number(),
  actions: z.array(Dnd5eActionTemplate),
  modifiers: z.array(Dnd5eModifier),
  resources: z.array(Dnd5eResource),
  effects: z.array(Dnd5eItemEffect)
});
export type Dnd5eInventoryItem = z.infer<typeof Dnd5eInventoryItem>;

export type Dnd5eInventoryItemOperation =
  | {type: "update-item-i-d", operations: ConstantOperation[]}
  | {type: "update-equipped", operations: BooleanOperation[]}
  | {type: "update-qty", operations: NumberOperation[]}
  | {type: "update-item", operations: StringOperation[]}
  | {type: "update-description", operations: RichTextOperation[]}
  | {type: "update-weight", operations: NumberOperation[]}
  | {type: "update-actions", operations: ListOperation<Dnd5eActionTemplate, Dnd5eActionTemplateOperation>[]}
  | {type: "update-modifiers", operations: ListOperation<Dnd5eModifier, Dnd5eModifierOperation>[]}
  | {type: "update-resources", operations: ListOperation<Dnd5eResource, Dnd5eResourceOperation>[]}
  | {type: "update-effects", operations: ListOperation<Dnd5eItemEffect, Dnd5eItemEffectOperation>[]}
  ;

export const Dnd5eInventoryItemFn = {
  DEFAULT(): Dnd5eInventoryItem {
    return {itemID: generateDnd5eItemID(), equipped: true, qty: 1, item: "", description: RichTextFn.EMPTY, weight: 0, actions: [], modifiers: [], resources: [], effects: []}
  },
  getEquipped: (value: Dnd5eInventoryItem) => value.equipped,
  getQty: (value: Dnd5eInventoryItem) => value.qty,
  getItem: (value: Dnd5eInventoryItem) => value.item,
  getWeight: (value: Dnd5eInventoryItem) => value.weight
};
export const Dnd5eInventoryItemOperation = {
  updateEquipped: (operations: BooleanOperation[]): Dnd5eInventoryItemOperation[] => [{type: "update-equipped", operations}],
  updateQty: (operations: NumberOperation[]): Dnd5eInventoryItemOperation[] => [{type: "update-qty", operations}],
  updateItem: (operations: StringOperation[]): Dnd5eInventoryItemOperation[] => [{type: "update-item", operations}],
  updateWeight: (operations: NumberOperation[]): Dnd5eInventoryItemOperation[] => [{type: "update-weight", operations}]
};

export const dnd5eInventoryItemType: Type<Dnd5eInventoryItem, Dnd5eInventoryItemOperation> = new ObjectType({
  itemID: constantType,
  equipped: booleanType,
  qty: numberType,
  item: stringType,
  description: richTextType,
  weight: numberType,
  actions: new ListType(dnd5eActionTemplateType),
  modifiers: new ListType(dnd5eModifierType),
  resources: new ListType(dnd5eResourceType),
  effects: new ListType(dnd5eItemEffectType)
}, (value) => {
  if (value.description === undefined) value.description = RichTextFn.EMPTY;
  if (value.effects === undefined) value.effects = [];
  if (value.variables !== undefined) {
    if (value.variables.length > 0) {
      value.modifiers.push(...value.variables.map((variable: any) => ({
        type: "variable",
        data: {
          modifierID: generateDnd5eModifierID(),
          name: variable.name,
          expression: variable.expression
        }
      }) satisfies Dnd5eModifier));
    }
    delete value["variables"];
  }
  return value;
});

export function Dnd5eInventoryItemSignals(signal: MutableRef<Dnd5eInventoryItem, Dnd5eInventoryItemOperation[]>) {
  return {
    item: PropertyRef<Dnd5eInventoryItem, Dnd5eInventoryItemOperation, string, StringOperation>(
      value => value.item,
      operations => [{type: "update-item", operations}]
    )(signal),
    description: PropertyRef<Dnd5eInventoryItem, Dnd5eInventoryItemOperation, RichText, RichTextOperation>(
      value => value.description,
      operations => [{type: "update-description", operations}]
    )(signal),
    weight: PropertyRef<Dnd5eInventoryItem, Dnd5eInventoryItemOperation, number, NumberOperation>(
      value => value.weight,
      operations => [{type: "update-weight", operations}]
    )(signal),
    equipped: PropertyRef<Dnd5eInventoryItem, Dnd5eInventoryItemOperation, boolean, BooleanOperation>(
      value => value.equipped,
      operations => [{type: "update-equipped", operations}]
    )(signal),
    qty: PropertyRef<Dnd5eInventoryItem, Dnd5eInventoryItemOperation, number, NumberOperation>(
      value => value.qty,
      operations => [{type: "update-qty", operations}]
    )(signal),
    actionsRef: ListPropertyRef<Dnd5eInventoryItem, Dnd5eInventoryItemOperation, Dnd5eActionTemplate, Dnd5eActionTemplateOperation>(
      value => value.actions,
      operations => [{type: "update-actions", operations}]
    )(signal),
    modifiers: ListPropertyRef<Dnd5eInventoryItem, Dnd5eInventoryItemOperation, Dnd5eModifier, Dnd5eModifierOperation>(
      value => value.modifiers,
      operations => [{type: "update-modifiers", operations}]
    )(signal),
    resources: ListPropertyRef<Dnd5eInventoryItem, Dnd5eInventoryItemOperation, Dnd5eResource, Dnd5eResourceOperation>(
      value => value.resources,
      operations => [{type: "update-resources", operations}]
    )(signal),
    effects: ListPropertyRef<Dnd5eInventoryItem, Dnd5eInventoryItemOperation, Dnd5eItemEffect, Dnd5eItemEffectOperation>(
      value => value.effects,
      operations => [{type: "update-effects", operations}]
    )(signal)
  };
}

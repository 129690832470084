import {Optional} from "common/types/generic/index.ts";
import {Dnd5eFeature, Sheet} from "common/legends/asset/index.ts";
import {Dnd5eActionTemplate} from "common/legends/asset/sheet/dnd-5e/dnd-5e-action-definition/template/dnd-5e-action-template.ts";
import {Dnd5eActionTemplateModifier} from "common/legends/asset/sheet/dnd-5e/dnd-5e-action-definition/modifier/dnd-5e-action-template-modifier.ts";
import {conditionEvaluator} from "./condition/condition-evaluator.ts";
import {Dnd5eActionTemplateSegment} from "common/legends/asset/sheet/dnd-5e/dnd-5e-action-definition/segment/dnd-5e-action-template-segment.ts";
import {Dnd5eActionTemplateSegmentRollModifier} from "common/legends/asset/sheet/dnd-5e/dnd-5e-action-definition/segment/damage/dnd-5e-action-template-segment-roll-modifier.ts";
import {
  Dnd5eActionTemplateSegmentAttackRollModifier
} from "common/legends/asset/sheet/dnd-5e/dnd-5e-action-definition/segment/attack-roll/dnd-5e-action-template-segment-attack-roll-modifier.ts";
import {
  Dnd5eActionTemplateSegmentSavingThrowModifier
} from "common/legends/asset/sheet/dnd-5e/dnd-5e-action-definition/segment/saving-throw/dnd-5e-action-template-segment-saving-throw-modifier.ts";
import {Dnd5eModifierID} from "common/legends/asset/sheet/dnd-5e/dnd-5e-modifier/dnd-5e-modifier-i-d.ts";
import {RollVariables} from "common/qlab/message/index.ts";
import {Dnd5eActionTemplateEffect} from "common/legends/asset/sheet/dnd-5e/dnd-5e-action-definition/effect/dnd-5e-action-template-effect.ts";
import {getActiveModifiers, getActiveVariables} from "common/legends/asset/sheet/dnd-5e/dnd-5e-modifier-helper.ts";
import {MathExpressionFn} from "common/math/math-expression.ts";
import {Dnd5eVariableModifier} from "common/legends/asset/sheet/dnd-5e/dnd-5e-modifier/dnd-5e-variable-modifier.ts";
import {getSheetVariables} from "common/legends/asset/sheet/dnd-5e/dnd-5e-variable/sheet-variable-signal.ts";
import {
  Dnd5eActionTemplateSegmentAbilityCheckModifier
} from "common/legends/asset/sheet/dnd-5e/dnd-5e-action-definition/segment/ability-check/dnd-5e-action-template-segment-ability-check-modifier.ts";

export function getActiveActionVariablesModifiers(
  sheet: Optional<Sheet>,
  globalFeatures: Dnd5eFeature[],
  action: Optional<Dnd5eActionTemplate>
): Dnd5eVariableModifier[] {
  const evaluateCondition = conditionEvaluator(sheet, globalFeatures, action);
  const getSheetModifiers = (): Dnd5eVariableModifier[] => {
    return getActiveModifiers(sheet, globalFeatures).flatMap(modifier => modifier.type === "variable" ? [modifier.data] : [])
  };
  const getActionModifiers = (modifier: Dnd5eActionTemplateModifier): Dnd5eVariableModifier[] => {
    if (modifier.type === "action::condition") {
      return evaluateCondition(modifier.data.condition)
        ? modifier.data.modifiers.flatMap(getActionModifiers, [])
        : [];
    } else if (modifier.type === "variable") {
      return [modifier.data];
    } else {
      return [];
    }
  };

  return [
    ...getSheetModifiers(),
    ...(action?.type === "custom" ? [
      ...action.data.modifiers,
      ...action.data.effects.filter(effect => effect.enabled)
        .flatMap(effect => effect.modifiers)
    ].flatMap(getActionModifiers) : [])
  ];
}

export function getActiveSegmentVariables(
  sheet: Optional<Sheet>,
  globalFeatures: Dnd5eFeature[],
  action: Optional<Dnd5eActionTemplate>,
  segment: Optional<Dnd5eActionTemplateSegment>
): Dnd5eVariableModifier[] {
  const evaluateCondition = conditionEvaluator(sheet, globalFeatures, action);
  if (segment?.type === "attack-roll") {
    const getSegmentModifiers = (modifier: Dnd5eActionTemplateSegmentAttackRollModifier): Dnd5eVariableModifier[] => {
      switch (modifier.type) {
        case "action::attack-roll::condition": return evaluateCondition(modifier.data.condition) ? modifier.data.modifiers.flatMap(getSegmentModifiers) : [];
        case "variable": return [modifier.data];
        default: return [];
      }
    };
    return segment.data.modifiers.flatMap(getSegmentModifiers);
  } else if (segment?.type === "roll") {
    const getSegmentModifiers = (modifier: Dnd5eActionTemplateSegmentRollModifier): Dnd5eVariableModifier[] => {
      switch (modifier.type) {
        case "action::roll::condition": return evaluateCondition(modifier.data.condition) ? modifier.data.modifiers.flatMap(getSegmentModifiers) : [];
        case "variable": return [modifier.data];
        default: return [];
      }
    };
    return segment.data.modifiers.flatMap(getSegmentModifiers);
  } else if (segment?.type === "saving-throw") {
    const getSegmentModifiers = (modifier: Dnd5eActionTemplateSegmentSavingThrowModifier): Dnd5eVariableModifier[] => {
      switch (modifier.type) {
        case "action::saving-throw::condition": return evaluateCondition(modifier.data.condition) ? modifier.data.modifiers.flatMap(getSegmentModifiers) : [];
        case "variable": return [modifier.data];
        default: return [];
      }
    };
    return segment.data.modifiers.flatMap(getSegmentModifiers);
  } else {
    return [];
  }
}



function damageModifierContainsModifier(modifier: Dnd5eActionTemplateSegmentRollModifier, modifierID: Dnd5eModifierID): boolean {
  if (modifier.data.modifierID === modifierID) return true;
  return (modifier.type === "action::roll::condition")
    ? modifier.data.modifiers.some(modifier => damageModifierContainsModifier(modifier, modifierID))
    : false;
}
function attackRollModifierContainsModifier(modifier: Dnd5eActionTemplateSegmentAttackRollModifier, modifierID: Dnd5eModifierID): boolean {
  if (modifier.data.modifierID === modifierID) return true;
  return (modifier.type === "action::attack-roll::condition")
    ? modifier.data.modifiers.some(modifier => attackRollModifierContainsModifier(modifier, modifierID))
    : false;
}
function savingThrowModifierContainsModifier(modifier: Dnd5eActionTemplateSegmentSavingThrowModifier, modifierID: Dnd5eModifierID): boolean {
  if (modifier.data.modifierID === modifierID) return true;
  return (modifier.type === "action::saving-throw::condition")
    ? modifier.data.modifiers.some(modifier => savingThrowModifierContainsModifier(modifier, modifierID))
    : false;
}
function abilityCheckModifierContainsModifier(modifier: Dnd5eActionTemplateSegmentAbilityCheckModifier, modifierID: Dnd5eModifierID): boolean {
  if (modifier.data.modifierID === modifierID) return true;
  return (modifier.type === "action::ability-check::condition")
    ? modifier.data.modifiers.some(modifier => abilityCheckModifierContainsModifier(modifier, modifierID))
    : false;
}
function segmentContainsModifier(segment: Dnd5eActionTemplateSegment, modifierID: Dnd5eModifierID): boolean {
  switch (segment.type) {
    case "roll": return segment.data.modifiers.some(modifier => damageModifierContainsModifier(modifier, modifierID));
    case "attack-roll": {
      return (
        segment.data.modifiers.some(modifier => attackRollModifierContainsModifier(modifier, modifierID)) ||
        segment.data.onHit.some(segment => segmentContainsModifier(segment, modifierID)) ||
        segment.data.onCriticalHit.some(segment => segmentContainsModifier(segment, modifierID)) ||
        segment.data.onMiss.some(segment => segmentContainsModifier(segment, modifierID))
      );
    }
    case "saving-throw": {
      return (
        segment.data.modifiers.some(modifier => savingThrowModifierContainsModifier(modifier, modifierID)) ||
        segment.data.onFailure.some(segment => segmentContainsModifier(segment, modifierID)) ||
        segment.data.onSuccess.some(segment => segmentContainsModifier(segment, modifierID))
      );
    }
    case "ability-check": {
      return (
        segment.data.modifiers.some(modifier => abilityCheckModifierContainsModifier(modifier, modifierID)) ||
        segment.data.onFailure.some(segment => segmentContainsModifier(segment, modifierID)) ||
        segment.data.onSuccess.some(segment => segmentContainsModifier(segment, modifierID))
      );
    }
    default: return false;
  }
}

export function getActiveModifierVariables(sheet: Optional<Sheet>, globalFeatures: Dnd5eFeature[], action: Optional<Dnd5eActionTemplate>, modifierID: Dnd5eModifierID): RollVariables {
  const evaluateCondition = conditionEvaluator(sheet, globalFeatures, action);

  const getSheetVariableModifiers = (): Dnd5eVariableModifier[] => {
    return getActiveModifiers(sheet, globalFeatures)
      .flatMap(modifier => modifier.type === "variable" ? [modifier.data] : []);
  };

  const getActionVariableModifiers = (modifier: Dnd5eActionTemplateModifier): Dnd5eVariableModifier[] => {
    switch (modifier.type) {
      case "variable": return [modifier.data];
      case "action::condition": {
        const conditionModifiers = modifier.data.modifiers.flatMap(getActionVariableModifiers);
        if (evaluateCondition(modifier.data.condition)) {
          return conditionModifiers;
        } else {
          return conditionModifiers.findIndex(item => item.modifierID === modifierID) === -1 ? [] : conditionModifiers;
        }
      }
      default: return [];
    }
  };

  const getEffectVariableModifiers = (effect: Dnd5eActionTemplateEffect): Dnd5eVariableModifier[] => {
    const effectModifiers = effect.modifiers.flatMap(getActionVariableModifiers);
    if (effect.enabled) return effectModifiers;
    return effectModifiers.findIndex(item => item.modifierID === modifierID) === -1 ? [] : effectModifiers;
  }

  const getSegmentVariableModifiers = (segment: Dnd5eActionTemplateSegment): Dnd5eVariableModifier[] => {
    if (!segmentContainsModifier(segment, modifierID)) return [];
    const evaluateCondition = conditionEvaluator(sheet, globalFeatures, action);
    if (segment.type === "roll") {
      const getDamageSegmentVariableModifiers = (modifier: Dnd5eActionTemplateSegmentRollModifier): Dnd5eVariableModifier[] => {
        switch (modifier.type) {
          case "action::roll::condition": {
            const conditionModifiers = modifier.data.modifiers.flatMap(getDamageSegmentVariableModifiers);
            if (evaluateCondition(modifier.data.condition)) return conditionModifiers;
            return damageModifierContainsModifier(modifier, modifierID) ? conditionModifiers : [];
          }
          case "variable": return [modifier.data];
          default: return [];
        }
      };
      return segment.data.modifiers.flatMap(getDamageSegmentVariableModifiers);
    } else if (segment.type === "attack-roll") {
      const getAttackRollSegmentVariableModifiers = (modifier: Dnd5eActionTemplateSegmentAttackRollModifier): Dnd5eVariableModifier[] => {
        switch (modifier.type) {
          case "action::attack-roll::condition": {
            const conditionModifiers = modifier.data.modifiers.flatMap(getAttackRollSegmentVariableModifiers);
            if (evaluateCondition(modifier.data.condition)) return conditionModifiers;
            return attackRollModifierContainsModifier(modifier, modifierID) ? conditionModifiers : [];
          }
          case "variable": return [modifier.data];
          default: return [];
        }
      };
      return [
        ...segment.data.modifiers.flatMap(getAttackRollSegmentVariableModifiers),
        ...segment.data.onHit.flatMap(getSegmentVariableModifiers),
        ...segment.data.onCriticalHit.flatMap(getSegmentVariableModifiers),
        ...segment.data.onMiss.flatMap(getSegmentVariableModifiers)
      ];
    } else if (segment.type === "saving-throw") {
      const getSavingThrowSegmentVariableModifiers = (modifier: Dnd5eActionTemplateSegmentSavingThrowModifier): Dnd5eVariableModifier[] => {
        switch (modifier.type) {
          case "action::saving-throw::condition": {
            const conditionModifiers = modifier.data.modifiers.flatMap(getSavingThrowSegmentVariableModifiers);
            if (evaluateCondition(modifier.data.condition)) return conditionModifiers;
            return savingThrowModifierContainsModifier(modifier, modifierID) ? conditionModifiers : [];
          }
          case "variable": return [modifier.data];
          default: return [];
        }
      };
      return [
        ...segment.data.modifiers.flatMap(getSavingThrowSegmentVariableModifiers),
        ...segment.data.onSuccess.flatMap(getSegmentVariableModifiers),
        ...segment.data.onFailure.flatMap(getSegmentVariableModifiers)
      ];
    } else if (segment.type === "ability-check") {
      const getAbilityCheckSegmentVariableModifiers = (modifier: Dnd5eActionTemplateSegmentAbilityCheckModifier): Dnd5eVariableModifier[] => {
        switch (modifier.type) {
          case "action::ability-check::condition": {
            const conditionModifiers = modifier.data.modifiers.flatMap(getAbilityCheckSegmentVariableModifiers);
            if (evaluateCondition(modifier.data.condition)) return conditionModifiers;
            return abilityCheckModifierContainsModifier(modifier, modifierID) ? conditionModifiers : [];
          }
          case "variable": return [modifier.data];
          default: return [];
        }
      };
      return [
        ...segment.data.modifiers.flatMap(getAbilityCheckSegmentVariableModifiers),
        ...segment.data.onSuccess.flatMap(getSegmentVariableModifiers),
        ...segment.data.onFailure.flatMap(getSegmentVariableModifiers)
      ];
    }
    return [];
  }

  const variables: RollVariables = {...getSheetVariables(sheet)};
  const modifiers: Dnd5eVariableModifier[] = [
    ...getSheetVariableModifiers(),
    ...(action !== undefined ? [
      ...action.data.modifiers.flatMap(getActionVariableModifiers),
      ...action.data.effects.flatMap(getEffectVariableModifiers),
      ...action.data.segments.flatMap(getSegmentVariableModifiers)
    ] : [])
  ];

  for (const modifier of modifiers) {
    if (modifier.modifierID === modifierID) break;
    variables[modifier.name.toUpperCase()] = MathExpressionFn.executeMathExpression(modifier.expression, variables);
  }
  return variables;
}

export function getActiveActionVariables(sheet: Optional<Sheet>, globalFeatures: Dnd5eFeature[], action: Dnd5eActionTemplate): RollVariables {
  const evaluateCondition = conditionEvaluator(sheet, globalFeatures, action);
  const getActionVariableModifiers = (modifier: Dnd5eActionTemplateModifier): Dnd5eVariableModifier[] => {
    switch (modifier.type) {
      case "variable": return [modifier.data];
      case "action::condition": return evaluateCondition(modifier.data.condition)
        ? modifier.data.modifiers.flatMap(getActionVariableModifiers)
        : [];
      default: return [];
    }
  };
  const getEffectVariableModifiers = (effect: Dnd5eActionTemplateEffect): Dnd5eVariableModifier[] => {
    return effect.enabled
      ? effect.modifiers.flatMap(getActionVariableModifiers)
      : [];
  }
  const variables = {...getActiveVariables(sheet, globalFeatures)};
  const actionModifiers: Dnd5eVariableModifier[] = [
    ...action.data.modifiers.flatMap(getActionVariableModifiers),
    ...action.data.effects.flatMap(getEffectVariableModifiers)
  ];
  for (const modifier of actionModifiers) {
    variables[modifier.name.toUpperCase()] = MathExpressionFn.executeMathExpression(modifier.expression, variables);
  }
  return variables;
}

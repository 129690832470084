import {useEffect} from "react";
import {Ref} from "common/ref";
import {useTempRef} from "#lib/components/temporary-resource/index.ts";
import {BooleanFn, booleanType} from "common/types/generic/index.ts";

export function useIsKeyDown(key: string): Ref<boolean> {
  const isDownRef = useTempRef(booleanType, () => false);
  useEffect(() => {
    const keydownHandler = (ev: KeyboardEvent) => {
      if (ev.key !== key) return;
      isDownRef.apply(prev => BooleanFn.set(prev, true));
    };
    const keyupHandler = (ev: KeyboardEvent) => {
      if (ev.key !== key) return;
      isDownRef.apply(prev => BooleanFn.set(prev, false));
    };

    document.addEventListener('keydown', keydownHandler);
    document.addEventListener('keyup', keyupHandler);
    return () => {
      document.addEventListener('keydown', keydownHandler);
      document.removeEventListener("keyup", keyupHandler);
    };
  }, [isDownRef, key]);
  return isDownRef;
}

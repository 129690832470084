import {
  Dnd5eActionTemplateSegmentSavingThrowModifier,
  Dnd5eActionTemplateSegmentSavingThrowModifierOperation,
  Dnd5eActionTemplateSegmentSavingThrowModifierTypes
} from "common/legends/asset/sheet/dnd-5e/dnd-5e-action-definition/segment/saving-throw/dnd-5e-action-template-segment-saving-throw-modifier.ts";
import {MutableRef} from "common/ref";
import {ListOperation} from "common/types/generic/index.ts";
import {InputList, InputListItemProps, useDragListItem} from "#lib/components/list/input-list.tsx";
import {useTypedRef} from "../../../../../../../common/use-typed-ref.ts";
import React from "react";
import {Dnd5eActionTemplateSegmentSavingThrowConditionModifierListItem} from "./dnd-5e-action-template-segment-saving-throw-condition-modifier-view.tsx";
import {Dnd5eActionTemplateSegmentSavingThrowDifficultyClassModifierListItem} from "./dnd-5e-action-template-segment-saving-throw-difficulty-class-modifier-view.tsx";
import {Dnd5eVariableModifierListItem} from "../dnd-5e-variable-modifier-view.tsx";
import {Dnd5eActionTemplateSegmentSavingThrowAttributeOverrideModifierListItem} from "./dnd-5e-action-template-segment-saving-throw-attribute-override-modifier-view.tsx";

function Dnd5eActionTemplateSegmentSavingThrowModifierListItem({item, remove, duplicate}: InputListItemProps<Dnd5eActionTemplateSegmentSavingThrowModifier, Dnd5eActionTemplateSegmentSavingThrowModifierOperation>) {
  const [dragHandlerRef, dragRefPreview] = useDragListItem("legends/action-template-segment-saving-throw-modifier", item, remove);
  const [type, typedRef] = useTypedRef<Dnd5eActionTemplateSegmentSavingThrowModifierTypes>(item);
  if (type === "variable") return <Dnd5eVariableModifierListItem item={typedRef} remove={remove} duplicate={duplicate} dragHandlerRef={dragHandlerRef} dragRefPreview={dragRefPreview} />;
  if (type === "action::saving-throw::condition") return <Dnd5eActionTemplateSegmentSavingThrowConditionModifierListItem item={typedRef} remove={remove} duplicate={duplicate} dragHandlerRef={dragHandlerRef} dragRefPreview={dragRefPreview} />;
  if (type === "action::saving-throw::difficulty-class") return <Dnd5eActionTemplateSegmentSavingThrowDifficultyClassModifierListItem item={typedRef} remove={remove} duplicate={duplicate} dragHandlerRef={dragHandlerRef} dragRefPreview={dragRefPreview} />;
  if (type === "action::saving-throw::attribute-override") return <Dnd5eActionTemplateSegmentSavingThrowAttributeOverrideModifierListItem item={typedRef} remove={remove} duplicate={duplicate} dragHandlerRef={dragHandlerRef} dragRefPreview={dragRefPreview} />;
  return <>{type}</>
}

export function Dnd5eActionTemplateSegmentSavingThrowModifiersView({valueRef}: {valueRef: MutableRef<Dnd5eActionTemplateSegmentSavingThrowModifier[], ListOperation<Dnd5eActionTemplateSegmentSavingThrowModifier, Dnd5eActionTemplateSegmentSavingThrowModifierOperation>[]>}) {
  return <InputList
      accept="legends/action-template-segment-saving-throw-modifier"
      items={valueRef}
      itemKey={(v) => v.data.modifierID}
      copy={v => v} ListItem={Dnd5eActionTemplateSegmentSavingThrowModifierListItem} />;
}
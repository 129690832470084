import {InputListItemProps} from "#lib/components/list/input-list.tsx";
import {
  Dnd5eActionTemplateSegmentSavingThrow,
  Dnd5eActionTemplateSegmentSavingThrowOperation,
  Dnd5eActionTemplateSegmentSavingThrowSignals
} from "common/legends/asset/sheet/dnd-5e/dnd-5e-action-definition/segment/saving-throw/dnd-5e-action-template-segment-saving-throw.ts";
import {ConnectDragPreview, ConnectDragSource} from "react-dnd";
import React, {useMemo} from "react";
import {ButtonBar, ExpandableLabel, IconButton, InputGroup, InputGroupLabel, useToggle} from "#lib/components/index.ts";
import {DragIndicator} from "#lib/components/tree-view/drag-indicator.tsx";
import {SectionHeader} from "#lib/components/panel-header.tsx";
import {FaCopy, FaTrash} from "react-icons/fa";
import {Fieldset} from "#lib/components/fieldset/fieldset.tsx";
import {InputOptionalAttribute} from "../../../../dnd-5e-character/input-attribute.tsx";
import {InputCheckbox} from "#lib/components/input/input-checkbox.tsx";
import {InputDiceExpression} from "../../../../dice-expression/input-dice-expression.tsx";
import {Dnd5eActionTemplateSegmentsView} from "../../dnd-5e-action-template-segments-view.tsx";
import {InputDnd5eSavingThrows} from "../../../dnd-5e-modifier/input-dnd-5e-saving-throw.tsx";
import {Dnd5eActionTemplateSegmentSavingThrowModifiersView} from "./dnd-5e-action-template-segment-saving-throw-modifiers-view.tsx";
import {Dnd5eActionTemplateAddSavingThrowModifierButton} from "./dnd-5e-action-template-add-saving-throw-modifier-button.tsx";
import {Dnd5eActionTemplateAddSegmentButton} from "../dnd-5e-action-template-add-segment-button.tsx";

export function Dnd5eActionTemplateSegmentSavingThrowView({item, remove, duplicate, dragRefPreview, dragHandlerRef}: InputListItemProps<Dnd5eActionTemplateSegmentSavingThrow, Dnd5eActionTemplateSegmentSavingThrowOperation> & {
  dragHandlerRef: ConnectDragSource | ConnectDragPreview,
  dragRefPreview: ConnectDragSource | ConnectDragPreview
}) {
  const {savingThrowTypesRef, attributeRef, proficientRef, expressionRef, modifiersRef, onFailureRef, onSuccessRef} = useMemo(() => Dnd5eActionTemplateSegmentSavingThrowSignals(item), [item])
  const [expanded, toggleExpanded] = useToggle(false);

  const [onFailureExpanded, toggleOnFailureExpanded, setOnFailureExpanded] = useToggle(true);
  const [onSuccessExpanded, toggleOnSuccessExpanded, setOnSuccessExpanded] = useToggle(true);
  const [modifiersExpanded, toggleModifiersExpanded, setModifiersExpanded] = useToggle(true);

  return <div role="list-item" className="flex flex-col">
    <ButtonBar ref={dragRefPreview}>
      <IconButton ref={dragHandlerRef} title="Move"><DragIndicator /></IconButton>
      <ExpandableLabel expanded={expanded} toggleExpand={toggleExpanded}>
        <SectionHeader className="flex-1 bg-zinc-800/50 cursor-pointer" onClick={toggleExpanded}>Saving Throw</SectionHeader>
      </ExpandableLabel>
      {duplicate && <IconButton title="Duplicate" onClick={duplicate}><FaCopy /></IconButton>}
      <IconButton variant="destructive" title="Remove Effect" onClick={remove}><FaTrash /></IconButton>
    </ButtonBar>
    {expanded && <Fieldset>
      <InputGroup>
        <InputGroupLabel>Saving Throw</InputGroupLabel>
      </InputGroup>
      <InputDnd5eSavingThrows value={savingThrowTypesRef} />
      <InputGroup>
        <InputGroupLabel>Attribute</InputGroupLabel>
        <InputOptionalAttribute valueRef={attributeRef} />
      </InputGroup>
      <InputGroup className="pl-0">
        <InputCheckbox value={proficientRef} />
        Proficient
      </InputGroup>
      <InputGroup>
        <InputGroupLabel>Difficulty Class Modifier</InputGroupLabel>
        <InputDiceExpression value={expressionRef} />
      </InputGroup>
      <div className="flex flex-col">
        <ExpandableLabel expanded={modifiersExpanded} toggleExpand={toggleModifiersExpanded}>
          <SectionHeader onClick={toggleModifiersExpanded} className="flex-1 bg-zinc-800/50 cursor-pointer">Modifiers</SectionHeader>
          <Dnd5eActionTemplateAddSavingThrowModifierButton valueRef={modifiersRef} onAddedModifier={() => setModifiersExpanded(true)} />
        </ExpandableLabel>
        {modifiersExpanded && <Fieldset>
          <Dnd5eActionTemplateSegmentSavingThrowModifiersView valueRef={modifiersRef} />
        </Fieldset>}
      </div>
      <div className="flex flex-col">
        <ExpandableLabel expanded={onFailureExpanded} toggleExpand={toggleOnFailureExpanded}>
          <SectionHeader onClick={toggleOnFailureExpanded} className="bg-zinc-800/50 cursor-pointer flex-1">On Failure</SectionHeader>
          <Dnd5eActionTemplateAddSegmentButton valueRef={onFailureRef}  onAddedSegment={() => setOnFailureExpanded(true)} />
        </ExpandableLabel>
        {onFailureExpanded && <Fieldset>
          <Dnd5eActionTemplateSegmentsView valueRef={onFailureRef}/>
        </Fieldset>}
      </div>
      <div className="flex flex-col">
        <ExpandableLabel expanded={onSuccessExpanded} toggleExpand={toggleOnSuccessExpanded}>
          <SectionHeader onClick={toggleOnSuccessExpanded} className="bg-zinc-800/50 cursor-pointer flex-1">On Success</SectionHeader>
          <Dnd5eActionTemplateAddSegmentButton valueRef={onSuccessRef} onAddedSegment={() => setOnSuccessExpanded(true)} />
        </ExpandableLabel>
        {onSuccessExpanded && <Fieldset>
          <Dnd5eActionTemplateSegmentsView valueRef={onSuccessRef}/>
        </Fieldset>}
      </div>
    </Fieldset>}
  </div>;
}

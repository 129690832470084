import {z} from "zod";

export const Dnd5eSpellSlotLevel = z.union([
  z.literal("1"),
  z.literal("2"),
  z.literal("3"),
  z.literal("4"),
  z.literal("5"),
  z.literal("6"),
  z.literal("7"),
  z.literal("8"),
  z.literal("9"),
  z.literal("pact-slot")
]);
export type Dnd5eSpellSlotLevel = z.infer<typeof Dnd5eSpellSlotLevel>;
export const DND_5E_SPELL_SLOT_LEVEL = [
  "1",
  "2",
  "3",
  "4",
  "5",
  "6",
  "7",
  "8",
  "9",
  "pact-slot"
] as const satisfies readonly Dnd5eSpellSlotLevel[];

export const DND_5E_SPELL_SLOT_LEVEL_TITLE: {[level in Dnd5eSpellSlotLevel]: string} = {
  "1": "1",
  "2": "2",
  "3": "3",
  "4": "4",
  "5": "5",
  "6": "6",
  "7": "7",
  "8": "8",
  "9": "9",
  "pact-slot": "Pact Slot",
};

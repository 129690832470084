import {InputGroup, InputGroupRange} from "#lib/components/index.ts";
import {NumberOperation} from "common/types/index.ts";
import {MutableRef} from "common/ref";
import {Field, FieldLabel} from "#lib/components/panel-header.tsx";

export type OpacityFieldProps = {
  value: MutableRef<number, NumberOperation[]>;
};

export function OpacityField({value}: OpacityFieldProps) {
  return (<Field>
    <FieldLabel>Opacity</FieldLabel>
    <InputGroup className="mx-2 rounded-md overflow-hidden">
      <InputGroupRange min={0} max={1} value={value} step={0.1} />
    </InputGroup>
  </Field>
  )
}
import {AreaShader} from "./area-shader.tsx";
import {Cone, ConeFn, HSLA, Point} from "common/types/generic/index.ts";
import {Spline} from "common/types/generic/spline/index.ts";
import {useMemo} from "react";
import {Vector2} from "common/math/vector/vector2.ts";

export type ConeShaderProps = {
  origin: Point,
  color: HSLA;
  cone: Cone,
};
export function ConeShader({cone, origin, ...props}: ConeShaderProps) {
  const spline = useMemo((): Spline => {
    const [a, b] = ConeFn.toPoints(cone);
    return ({
      start: Vector2.subtract([0, 0], origin),
      controlPoint1: [0, 0],
      controlPoint2: [0, 0],
      closed: true,
      curves: [
        {end: a, controlPoint1: [0, 0], controlPoint2: [0, 0]},
        {end: b, controlPoint1: [0, 0], controlPoint2: [0, 0]}
      ]
    })
  }, [cone, origin]);
  return <AreaShader {...props} origin={origin} spline={spline} scale={1} />
}

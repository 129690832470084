import {
  useArrayBuffer,
  useAttribLocation,
  useBindVertexArribArray,
  useElementArrayBuffer,
  useProgram,
  useShader,
  useUniformLocation,
  useVertexBuffer
} from "#lib/gl-react/index.ts";
import React, {useMemo} from "react";
import {Matrix4f} from "#lib/math/index.ts";
import {Point} from "common/types/generic/index.ts";
import {TokenVisionLimit} from "common/legends/asset/token/token-vision-limit.ts";
import {usePVM} from "../context/pvm-context.ts";
import {Vector2} from "common/math/vector/vector2.ts";


const vertexShader = `#version 300 es
precision highp float;

in vec2 a_position;
in vec2 a_world_pos;

out vec2 v_world_pos;

void main()
{
  gl_Position = vec4(a_position, 0, 1);
  v_world_pos = a_world_pos;
}
`;

const fragmentShader = `#version 300 es
precision highp float;

in vec2 v_world_pos;

uniform float radius;
uniform float falloff;
uniform float falloffStrength;
uniform vec2 origin;

out vec4 outColor;
void main() {
  float dist = length(origin - v_world_pos);
  float radiusFalloff = pow(1. - min(1., max(0., (dist - radius) / falloff)), falloffStrength);
  outColor = vec4(1., 1., 1., radiusFalloff);
}
`;

export const VisionLimitShader = function VisionLimitShader({value, origin}: {value: TokenVisionLimit, origin: Point}) {
  const {projection, view} = usePVM();

  const program = useProgram(
    useShader(WebGL2RenderingContext.VERTEX_SHADER, vertexShader),
    useShader(WebGL2RenderingContext.FRAGMENT_SHADER, fragmentShader)
  );
  const vbo = useArrayBuffer(useMemo(() => {
    const invertViewTransform = Matrix4f.invert(Matrix4f.transform(view));
    const matrixTransform = Matrix4f.multiplyMatrix(invertViewTransform, Matrix4f.invert(projection));
    const tl = Vector2.multiplyMatrix4x4([-1, -1], matrixTransform);
    const tr = Vector2.multiplyMatrix4x4([ 1, -1], matrixTransform);
    const br = Vector2.multiplyMatrix4x4([ 1,  1], matrixTransform);
    const bl = Vector2.multiplyMatrix4x4([-1,  1], matrixTransform);

    return new Float32Array([
      -1, -1,  ...tl,
       1, -1,  ...tr,
       1,  1,  ...br,
      -1,  1,  ...bl
    ]);
  }, [projection, view]));
  const vao = useVertexBuffer();
  useBindVertexArribArray(vao, useAttribLocation(program, "a_position"), vbo, 2, WebGL2RenderingContext.FLOAT, false, 4 * 4, 0);
  useBindVertexArribArray(vao, useAttribLocation(program, "a_world_pos"), vbo, 2, WebGL2RenderingContext.FLOAT, false, 4 * 4, 2 * 4);
  const ebo = useElementArrayBuffer(useMemo(() => new Uint16Array([0, 1, 2, 2, 3, 0]), []));

  const origin2f = useMemo(() => new Float32Array(origin), [origin]);
  return <program value={program}>
    <uniform1f location={useUniformLocation(program, "radius")} data={value.distance}/>
    <uniform1f location={useUniformLocation(program, "falloff")} data={value.falloff}/>
    <uniform1f location={useUniformLocation(program, "falloffStrength")} data={value.falloffStrength}/>
    <uniform2fv location={useUniformLocation(program, "origin")} data={origin2f} />

    <vertexArray value={vao}>
      <elementArrayBuffer value={ebo}>
        <drawElements mode={WebGL2RenderingContext.TRIANGLES} type={WebGL2RenderingContext.UNSIGNED_SHORT} offset={0} count={6}/>
      </elementArrayBuffer>
    </vertexArray>
  </program>;
}

import {
  useArrayBuffer,
  useAttribLocation,
  useBindVertexArribArray,
  useBindVertexElementArrayBuffer,
  useElementArrayBuffer,
  useProgram,
  useShader,
  useUniformLocation,
  useVertexBuffer
} from "#lib/gl-react/index.ts";
import {useMemo} from "react";
import {GridNode} from "common/legends/index.ts";
import {Color, HSLA, Transform} from "common/types/index.ts";
import {Matrix4f} from "#lib/math/index.ts";
import {PerlinNoiseShaderFragment} from "./noise.ts";
import {useGrid} from "../../context/grid-context.ts";

const vertexShaderSource = `#version 300 es
precision highp float;

in vec2 a_position;

uniform mat4 u_projection;
uniform mat4 u_view;
uniform mat4 u_model;

out vec2 fragCoord;

void main()
{
  fragCoord = (u_model * u_view * u_projection * vec4(a_position, 0, 1)).xy;
  gl_Position = vec4(a_position, 0, 1);
}
`;

const fragmentShaderSource = `#version 300 es

precision highp float;
precision highp sampler2DArray;

in vec2 fragCoord;

layout(location = 0) out vec4 outColor;
layout(location = 1) out vec4 normal;

uniform vec2 u_limits;
uniform vec2 u_gridSize;
uniform vec4 u_color;
uniform float u_thickness;
uniform float u_noise;

${PerlinNoiseShaderFragment}

vec2 coord(vec2 p) {
  return floor(p / u_gridSize);
}

void main() {
  float n = perlin(fragCoord/u_gridSize)*u_noise;
  float thickness = u_thickness + n;

  vec2 du = fwidth(fragCoord);
  
  vec2 xThickness = vec2(thickness / 2. * u_gridSize.x, 0.);
  vec2 xDu = vec2(du.x, 0.);
  vec2 xP1 = coord(fragCoord + u_gridSize/2. + xThickness);
  vec2 xP2 = coord(fragCoord + u_gridSize/2. - xThickness - xDu);
  bool x = xP1.x > xP2.x && xP1.y == xP2.y;
  
  vec2 yThickness = vec2(0., thickness / 2. * u_gridSize.y);
  vec2 yDu = vec2(0., du.y);
  vec2 yP1 = coord(fragCoord + u_gridSize/2. + yThickness);
  vec2 yP2 = coord(fragCoord + u_gridSize/2. - yThickness - yDu);
  bool y = yP1.x == yP2.x && yP1.y > yP2.y;
  
  if (
    (u_limits.x == -1. || u_limits.y == -1.) || (
      fragCoord.x > -u_gridSize.x/2.0 && fragCoord.x < u_limits.x - u_gridSize.x/2.0 &&
      fragCoord.y > -u_gridSize.y/2.0 && fragCoord.y < u_limits.y - u_gridSize.y/2.0
    )) {
    outColor = x || y ? u_color : vec4(0.);
  } else {
    outColor = vec4(0.);
  }
  // outColor = vec4(n, n, n, 1.);
  normal = vec4(0.5, 0.5, 1.0, 1.0);
}
`;

export type SquareGridViewProps = {
  value: GridNode;
  projection: Matrix4f;
  view: Transform;
  model: Transform;
}
export function SquareGridShader({value, projection, view, model}: SquareGridViewProps): JSX.Element {
  const grid = useGrid();
  const program = useProgram(
    useShader(WebGL2RenderingContext.VERTEX_SHADER, vertexShaderSource),
    useShader(WebGL2RenderingContext.FRAGMENT_SHADER, fragmentShaderSource)
  );

  const projectionLocation = useUniformLocation(program, "u_projection");
  const viewLocation = useUniformLocation(program, "u_view");
  const modelLocation = useUniformLocation(program, "u_model");
  const limitsLocation = useUniformLocation(program, "u_limits");
  const gridSizeLocation = useUniformLocation(program, "u_gridSize");
  const colorLocation = useUniformLocation(program, "u_color");
  const thicknessLocation = useUniformLocation(program, "u_thickness");
  const noiseLocation = useUniformLocation(program, "u_noise");

  const positionLocation = useAttribLocation(program, "a_position");

  const vao = useVertexBuffer();
  const position = useArrayBuffer(useMemo((): Float32Array => new Float32Array([-1, -1,  -1, +1,  +1, +1,  +1, -1]), []));
  useBindVertexArribArray(vao, positionLocation, position, 2, WebGL2RenderingContext.FLOAT, false, 0, 0);
  const vbo = useElementArrayBuffer(useMemo((): Uint16Array => new Uint16Array([0, 1, 2,  2, 3, 0]), []));
  useBindVertexElementArrayBuffer(vao, vbo);

  const projectionMatrix4f = useMemo(() => new Float32Array(Matrix4f.invert(projection)), [projection]);
  const viewMatrix4f = useMemo(() => new Float32Array(Matrix4f.invert(Matrix4f.transform(view))), [view]);
  const modelMatrix4f = useMemo(() => new Float32Array(Matrix4f.invert(Matrix4f.transform(model))), [model]);

  const color = useMemo(() => new Float32Array(Color.toRGBA([...value.color, value.opacity] as HSLA)), [value.color, value.opacity]);
  const gridSize = useMemo(() => new Float32Array([grid.width, grid.height]), [grid.width, grid.height]);
  const limits = useMemo(() => new Float32Array(value.size || [-1, -1]), [value.size]);

  return (
    <program value={program}>
      <uniformMat4fv location={projectionLocation} transpose data={projectionMatrix4f} />
      <uniformMat4fv location={viewLocation} transpose data={viewMatrix4f} />
      <uniformMat4fv location={modelLocation} transpose data={modelMatrix4f} />
      <uniform2fv location={limitsLocation} data={limits} />

      <uniform2fv location={gridSizeLocation} data={gridSize} />
      <uniform1f location={thicknessLocation} data={value.thickness} />
      <uniform1f location={noiseLocation} data={value.noise} />

      <uniform4fv location={colorLocation} data={color} />

      <vertexArray value={vao}>
        <drawElements mode={WebGL2RenderingContext.TRIANGLES} type={WebGL2RenderingContext.UNSIGNED_SHORT} offset={0} count={6} />
      </vertexArray>
    </program>
  );
}

import {NameField} from "../name-field.tsx";
import {VisibilityLayerField} from "../visibility-layer-field.tsx";
import {InputTransform} from "./transform/input-transform.tsx";
import {ConstantOperation, ValueOperation} from "common/types/index.ts";
import {useMemo} from "react";
import {TextNode, TextNodeOperation, TextNodeSignals} from "common/legends/node/text-node.ts";
import {ColorField} from "#lib/components/input/color-field.tsx";
import {MutableRef} from "common/ref";
import {InputString} from "#lib/components/input/input-string.tsx";
import {InputGroup, InputNumber} from "#lib/components/index.ts";
import {InputHorizontalAlignment} from "./input-horizontal-alignment.tsx";
import {InputVerticalAlignment} from "./input-vertical-alignment.tsx";
import {SelectionMaskField} from "../selection-mask-field.tsx";
import {PivotField} from "./pivot-field.tsx";
import {ConditionsSection} from "./conditions/conditions-section.tsx";
import {SelectionRef} from "../../nav/editor/state/selection-ref.ts";
import {Field, FieldLabel, Panel, Section} from "#lib/components/panel-header.tsx";

export type TextNodePropertiesProps = {
  value: MutableRef<TextNode, TextNodeOperation[]>;
  reference: SelectionRef;
  pinned: MutableRef<SelectionRef, ValueOperation<SelectionRef, ConstantOperation>[]>;
};
export function TextNodeProperties({value, reference, pinned}: TextNodePropertiesProps) {
  const {name, pivotRef, textRef, sizeRef, visibilityLayerRef, transformRef, selectionMaskRef, fillColorRef, outlineColorRef, hTextAlignRef, vTextAlignRef, conditionsRef} = useMemo(() => TextNodeSignals(value), [value]);

  return <div className="tab-content flex flex-col py-2 gap-1">
    <Panel title="Text Properties">
      <Section>
        <NameField value={name} reference={reference} pinned={pinned} />
        <VisibilityLayerField value={visibilityLayerRef} />
        <SelectionMaskField value={selectionMaskRef} />
        <InputTransform value={transformRef} />
        <PivotField valueRef={pivotRef} />
        <Field>
          <FieldLabel>Text</FieldLabel>
          <InputGroup className="mx-2 rounded-md overflow-hidden">
            <InputString value={textRef} />
          </InputGroup>
        </Field>
        <Field>
          <FieldLabel>Size</FieldLabel>
          <InputGroup className="mx-2 rounded-md overflow-hidden">
            <InputNumber value={sizeRef} />
          </InputGroup>
        </Field>
        <InputHorizontalAlignment value={hTextAlignRef} />
        <InputVerticalAlignment value={vTextAlignRef} />
        <ColorField label="Fill Color" value={fillColorRef} />
        <ColorField label="Outline Color" value={outlineColorRef} />
      </Section>
      <ConditionsSection value={conditionsRef} />
    </Panel>
  </div>
}
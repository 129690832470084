import {ButtonBar, IconButton, InputGroup, InputGroupLabel, InputNumber} from "#lib/components/index.ts";
import React, {Ref, useMemo} from "react";
import {FaTrash} from "react-icons/fa";
import {
  Dnd5eDamageThresholdModifier,
  Dnd5eDamageThresholdModifierOperation,
  Dnd5eDamageThresholdModifierSignals
} from "common/legends/asset/sheet/dnd-5e/dnd-5e-modifier/dnd-5e-damage-threshold-modifier.ts";
import {DragIndicator} from "#lib/components/tree-view/drag-indicator.tsx";
import {MutableRef} from "common/ref";

export type Dnd5eDamageThresholdModifierViewProps = {
  value: MutableRef<Dnd5eDamageThresholdModifier, Dnd5eDamageThresholdModifierOperation[]>;
  dragHandlerRef: Ref<HTMLButtonElement>;
  dragRefPreview: Ref<HTMLDivElement>;
  remove: () => void;
  readOnly?: boolean;
};
export function Dnd5eDamageThresholdModifierView({value, dragHandlerRef, dragRefPreview, remove, readOnly}: Dnd5eDamageThresholdModifierViewProps) {
  const {amount} = useMemo(() => Dnd5eDamageThresholdModifierSignals(value), [value]);

  return <ButtonBar ref={dragRefPreview} role="list-item">
    <IconButton ref={dragHandlerRef} title="Move"><DragIndicator /></IconButton>
    <InputGroup className="flex-1" title="Threshold">
      <InputGroupLabel>Damage Threshold</InputGroupLabel>
      <InputNumber value={amount}/>
    </InputGroup>
    {!readOnly && <IconButton title="Remove" variant="destructive" onClick={() => remove()}><FaTrash /></IconButton>}
  </ButtonBar>
}
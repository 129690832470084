import {useMemo} from "react";
import {Optional, ValueFn} from "common/types/index.ts";
import {pipe} from "common/pipe";
import {distinct, map} from "common/observable";
import {useEditorTool} from "../tool-selector/use-editor-tool.ts";
import {MutableRef} from "common/ref";
import {AreaToolData, AreaToolDataOperation} from "../../../../../common/tool/area/area-tool-data.ts";
import {Tool} from "../../../../../common/tool/tool.ts";

export function useEditorAreaTool() {
  const tool = useEditorTool();
  return useMemo((): MutableRef<Optional<AreaToolData>, AreaToolDataOperation[]> => {
    const valueFn = (tool: Tool): Optional<AreaToolData> => tool.type === "area" ? tool.data : undefined;
    return new MutableRef({
      value() {return valueFn(tool.value)},
      observe: pipe(tool.observe, map(valueFn), distinct()),
      apply: fn => tool.apply(prev => {
        if (prev?.type !== "area") return [];
        const operations = fn(prev.data);
        if (operations.length === 0) return [];
        return ValueFn.apply([{type: "area", operations}]);
      }).then(valueFn)
    })
  }, [tool]);
}
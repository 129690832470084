import {FaBinoculars, FaBox, FaClock, FaDragon, FaEnvelope, FaMap} from "react-icons/fa";
import {EditorStackValue} from "./editor-stack.ts";
import {Stacks} from "#lib/container/react/stack/stacks.ts";
import {LegendsIcon} from "../../../../legends-icon.tsx";

export const EditorStacks: Stacks<EditorStackValue> = {
  "settings": {
    label: "Settings",
    Icon: LegendsIcon,
    defaultContent: {type: "settings", data: {
    }}
  },
  "scene-navigator": {
    label: "Scenes",
    Icon: FaMap,
    defaultContent: {type: "scene-navigator", data: {
      searchTerm: "",
      expanded: {}
    }}
  },
  "character-navigator": {
    label: "Assets",
    Icon: FaBox,
    defaultContent: {type: "character-navigator", data: {
      searchTerm: "",
      selectedCharacterTreeId: undefined,
      expanded: {}
    }}
  },
  "properties": {
    label: "Properties",
    Icon: FaBinoculars,
    defaultContent: {type: "properties", data: {
      reference: undefined
    }}
  },
  "chat": {
    label: "Chat",
    Icon: FaEnvelope,
    defaultContent: {type: "chat", data: {
      channelReference: undefined
    }}
  },
  "sheet": {
    label: "Sheet",
    Icon: FaDragon,
    defaultContent: {type: "sheet", data: {
      reference: undefined
    }}
  },
  "turn-tracker": {
    Icon: FaClock,
    label: "Turn Tracker",
    defaultContent: {type: "turn-tracker", data: {}}
  }
};

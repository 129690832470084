import {z} from "zod";
import {Dnd5eModifierID} from "../../../dnd-5e-modifier/dnd-5e-modifier-i-d.ts";
import {Dnd5eDamageType} from "../../../dnd-5e-damage-type.ts";
import {DiceExpression} from "../../../../../../../dice/index.ts";
import {ConstantOperation, constantType, ObjectType, Optional, Type, ValueOperation, ValuePropertyRef, ValueType} from "../../../../../../../types/index.ts";
import {MutableRef} from "#common/ref";
import {Dnd5eActionCondition} from "../../condition/dnd-5e-action-condition.ts";

export const Dnd5eActionTemplateSegmentRollExpressionModifier: z.ZodType<{
  modifierID: string,
  condition?: Optional<Dnd5eActionCondition>,
  rollType?: Optional<Dnd5eDamageType>,
  expression: string
}> = z.object({
  modifierID: Dnd5eModifierID,
  condition: z.optional(Dnd5eActionCondition),
  rollType: z.optional(Dnd5eDamageType),
  expression: DiceExpression
});
export type Dnd5eActionTemplateSegmentRollExpressionModifier = z.infer<typeof Dnd5eActionTemplateSegmentRollExpressionModifier>;

export const Dnd5eActionTemplateSegmentRollExpressionModifierOperation = z.discriminatedUnion("type", [
  z.object({type: z.literal("update-modifier-i-d"), operations: z.array(ConstantOperation)}),
  z.object({type: z.literal("update-condition"), operations: z.array(ValueOperation(z.optional(Dnd5eActionCondition), ConstantOperation))}),
  z.object({type: z.literal("update-roll-type"), operations: z.array(ValueOperation<Optional<string>, ConstantOperation>(z.optional(Dnd5eDamageType), ConstantOperation))}),
  z.object({type: z.literal("update-expression"), operations: z.array(ValueOperation(DiceExpression, ConstantOperation))})
]);
export type Dnd5eActionTemplateSegmentRollExpressionModifierOperation = z.infer<typeof Dnd5eActionTemplateSegmentRollExpressionModifierOperation>;

export const dnd5eActionTemplateSegmentRollExpressionType: Type<Dnd5eActionTemplateSegmentRollExpressionModifier, Dnd5eActionTemplateSegmentRollExpressionModifierOperation> = new ObjectType({
  modifierID: constantType,
  condition: new ValueType(constantType),
  rollType: new ValueType(constantType),
  expression: new ValueType(constantType)
})

export function Dnd5eActionTemplateSegmentRollExpressionModifierSignals(valueRef: MutableRef<Dnd5eActionTemplateSegmentRollExpressionModifier, Dnd5eActionTemplateSegmentRollExpressionModifierOperation[]>) {
  return {
    conditionRef: ValuePropertyRef<Dnd5eActionTemplateSegmentRollExpressionModifier, Dnd5eActionTemplateSegmentRollExpressionModifierOperation, Optional<Dnd5eActionCondition>, ConstantOperation>(
      value => value.condition,
      operations => [{type: "update-condition", operations}]
    )(valueRef),
    rollTypeRef: ValuePropertyRef<Dnd5eActionTemplateSegmentRollExpressionModifier, Dnd5eActionTemplateSegmentRollExpressionModifierOperation, Optional<Dnd5eDamageType>, ConstantOperation>(
      value => value.rollType,
      operations => [{type: "update-roll-type", operations}]
    )(valueRef),
    expressionRef: ValuePropertyRef<Dnd5eActionTemplateSegmentRollExpressionModifier, Dnd5eActionTemplateSegmentRollExpressionModifierOperation, DiceExpression, ConstantOperation>(
      value => DiceExpression.parse(value.expression),
      operations => [{type: "update-expression", operations}]
    )(valueRef)
  };
}

import {DiceExpression} from "common/dice/dice-expression.ts";
import {Dnd5eSkill, DND_5E_SKILL_TITLE} from "common/legends/asset/index.ts";

export enum Beyond20RollType {
  NORMAL = 0,
  ROLL_TWICE = 1,
  QUERY = 2,
  ROLL_ADVANTAGE = 3,
  ROLL_DISADVANTAGE = 4,
  ROLL_THRICE = 5,
  ROLL_SUPER_ADVANTAGE = 6,
  ROLL_SUPER_DISADVANTAGE = 7
}
enum WhisperType {
  WHISPER_FALSE = 0,
  WHISPER_TRUE = 1,
  WHISPER_QUERY = 2,
  WHISPER_LIMITED = 3
}
export type Beyond20Character = {
  name: string;
  type: string;
  url: string;
  avatar?: string;
};
export type Beyond20Skill = typeof DND_5E_SKILL_TITLE[Dnd5eSkill];
export type Beyond20Ability = "STR" | "DEX" |  "CON" | "WIS" | "INT" | "CHA";

type BaseRoll<Type> = {
  action: "roll";
  type: Type;
  character: Beyond20Character;
  d20: string;
  modifier: string;
  roll: string;
  advantage: Beyond20RollType;
  whisper: WhisperType;
  preview: string
};

export type Beyond20DamageType = string;

export type Beyond20AttackRoll = BaseRoll<"attack" | "spell-attack"> & {
  "attack-source"?: string;
  "attack-type": string;
  "critical-damage-types": Beyond20DamageType[];
  "critical-damages": DiceExpression[];
  "damage-types": Beyond20DamageType[];
  "damages": DiceExpression[];
  description: string
  "is_versatile": boolean
  "item-customizations": any[]
  "item-type": string;
  name: string;
  proficient: boolean;
  properties: string[];
  range: string;
  roll: DiceExpression;
  rollAttack: boolean;
  rollCritical: boolean;
  rollDamage: boolean;
  "to-hit": DiceExpression;
}

export type Beyond20ItemRoll = BaseRoll<"item"> & {
  name: string;
  "item-type"?: string;
  description: string;
}
export type Beyond20AbilityRoll = BaseRoll<"ability"> & {
  ability: Beyond20Ability;
  modifier: string;
}
export type Beyond20InitiativeRoll = BaseRoll<"initiative"> & {
  initiative: string;
}
export type Beyond20SkillRoll = BaseRoll<"skill"> & {
  skill: Beyond20Skill;
  modifier: string;
}
export type Beyond20SavingThrowRoll = BaseRoll<"saving-throw"> & {
  ability: Beyond20Ability;
  modifier: string;
}
export type Beyond20DeathSaveRoll = BaseRoll<"death-save"> & {
  modifier: string;
}

export type Beyond20TraitRoll = BaseRoll<"trait"> & {
  ability: Beyond20Ability;
  name: string;
  description: string;
}

export type Beyond20SpellCardRoll = BaseRoll<"spell-card"> & {
  ability: Beyond20Ability;
  name: string;
  description: string;
}
export type Beyond20HitDiceRoll = BaseRoll<"hit-dice"> & {
  "hit-dice": string;
  "class": string;
}

export type RenderedRollRequest = Beyond20AttackRoll | Beyond20ItemRoll | Beyond20SkillRoll | Beyond20AbilityRoll | Beyond20SavingThrowRoll | Beyond20DeathSaveRoll | Beyond20TraitRoll | Beyond20SpellCardRoll | Beyond20InitiativeRoll | Beyond20HitDiceRoll;

export type RenderedRoll<Type> = {
  action: "rendered-roll";
  request: RenderedRollRequest & BaseRoll<Type>;
  "attack_rolls": {
    "critical-failure": boolean;
    "critical-success": boolean;
    discarded: boolean;
    total: number;
  }[];
  "damage_rolls": [Beyond20DamageType, {
    type: "damage" | "critical-damage";
    discarded: boolean;
    total: number;
  }, number][]
};

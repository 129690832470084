import React, {useState} from "react";
import {usePopper} from "react-popper";
import {Menu} from "@headlessui/react";
import {Button} from "#lib/components/index.ts";
import {createPortal} from "react-dom";
import {Popper} from "#lib/components/popper/popper.tsx";
import {Dnd5eSheetEffectsView} from "../../../../sheet/editor/dnd-5e-character/dnd-5e-character-effects.tsx";
import {usePortal} from "#lib/container/react/external-window/external-portal.tsx";

export function QuickAccessEffectsMenu() {
  const [referenceElement, setReferenceElement] = useState<HTMLElement | null>(null);
  const [popperElement, setPopperElement] = useState<HTMLElement | null>(null);
  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    placement: "bottom-start"
  });

  return (<Menu as="div" ref={ref => setReferenceElement(ref)}>
    <Menu.Button as={Button}>Effects</Menu.Button>
    {createPortal(<Menu.Items as={Popper} ref={ref => setPopperElement(ref)} style={styles.popper} {...attributes.popper} className="mt-4 w-96">
      <Dnd5eSheetEffectsView />
    </Menu.Items>, usePortal())}
  </Menu>);
}

import {Dnd5eAttribute, DND_5E_ATTRIBUTES, DND_5E_SKILLS} from "common/legends/index.ts";
import {SkillField} from "./skill-field.tsx";
import React, {useMemo} from "react";
import {PanelHeader} from "#lib/components/panel-header.tsx";
import {SavingThrowField} from "./saving-throw-field.tsx";
import {InitiativeField} from "./initiative-field.tsx";
import {AttributeField} from "./attribute-field.tsx";
import {MapRef, MapSignals, NumberOperation} from "common/types/index.ts";
import {useRefValue} from "#lib/signal/index.ts";

export type SkillProficienciesProps = {
  attributes: MapRef<Dnd5eAttribute, number, NumberOperation>,
};

export function SkillProficiencies({attributes}: SkillProficienciesProps) {
  const attributeEntities = useRefValue(useMemo(() => MapSignals.expand(attributes), [attributes]));

  return (<div className="flex flex-row gap-2">
    <div className="flex-1 flex flex-col gap-2">
      <div className="flex flex-row gap-1 basis-16 shrink-0 grow-0 justify-between">
        {DND_5E_ATTRIBUTES.map(attribute =>
          <AttributeField key={attribute} attribute={attribute} value={attributeEntities[attribute]!}/>
        )}
      </div>

      <div className="mx-2 rounded-lg overflow-hidden backdrop-blur-sm pointer-events-auto">
        <InitiativeField/>
      </div>

      <div className="flex flex-col gap-0.5 mx-2 rounded-lg overflow-hidden backdrop-blur-sm pointer-events-auto">
        <PanelHeader>Saving Throws</PanelHeader>
        {DND_5E_ATTRIBUTES.map(attribute => <SavingThrowField key={attribute} attribute={attribute}/>)}
      </div>

      <div className="flex flex-col gap-0.5 mx-2 rounded-lg overflow-hidden backdrop-blur-sm pointer-events-auto">
        <PanelHeader>Skills</PanelHeader>
        {DND_5E_SKILLS.map(skill => <SkillField key={skill} skill={skill}/>)}
      </div>
    </div>
  </div>);
}

import {ListOperation} from "common/types/index.ts";
import {Dnd5eCharacterClass, Dnd5eCharacterClassFn, Dnd5eCharacterClassOperation, Dnd5eCharacterClassSignal, dnd5eCharacterClassType} from "common/legends/index.ts";
import {Button, ButtonBar, InputGroup, InputGroupLabel, InputNumber} from "#lib/components/index.ts";
import {FaFileExport, FaPlus, FaTrash} from "react-icons/fa";
import React, {useCallback, useMemo} from "react";
import {InputString} from "#lib/components/input/input-string.tsx";
import {ImportButton} from "#lib/components/button/import-button.tsx";
import {generateDnd5eCharacterClassID} from "common/legends/asset/sheet/dnd-5e/character/dnd-5e-character-class-i-d.ts";
import {HitDiceInput} from "./hit-dice-input.tsx";
import {exportFile} from "../../../../common/export-file.ts";
import {PanelHeader} from "#lib/components/panel-header.tsx";
import {BaseComponent} from "#lib/components/BaseComponent.tsx";
import {ExpandOptions} from "#lib/components/expand-options.tsx";
import {GenericInputList, ListItemProps} from "#lib/components/list/generic-input-list.tsx";
import {SpellSlotProgressionInput} from "./spell-slot-progression-input.tsx";
import {useComputedValue} from "#lib/signal/index.ts";
import {InputDnd5eCharacterClassFeatures} from "../dnd-5e/dnd-5e-feature/input-dnd-5e-character-class-features.tsx";
import {MutableRef} from "common/ref";

type ClassInputProps = {
  value: MutableRef<Dnd5eCharacterClass, Dnd5eCharacterClassOperation[]>;
  remove?: () => void;
};

function ClassInput({value, remove}: ClassInputProps) {
  const {class: clazz, subclass, level, features, hitDice, spellSlotProgression} = useMemo(() => Dnd5eCharacterClassSignal(value), [value]);
  const exportClass = async () => {
    const clazz = value.value;
    exportFile(`CLASS-${clazz.class}${clazz.subclass ? `-${clazz.subclass}` : ""}.lvtt`, new Blob([JSON.stringify(clazz, null, 2)]));
  };
  const name = useComputedValue(value, value => value.class);

  return (<div className="flex flex-col gap-1">
    <div className="flex flex-row gap-0.5">
      <InputGroup className="flex-1">
        <InputGroupLabel>Class</InputGroupLabel>
        <InputString placeholder="Class" value={clazz} />
      </InputGroup>
      <InputGroup className="flex-1">
        <InputGroupLabel>Subclass</InputGroupLabel>
        <InputString placeholder="Subclass" value={subclass} />
      </InputGroup>
      <InputGroup className="flex-1">
        <InputGroupLabel>Level</InputGroupLabel>
        <InputNumber placeholder="Level" title={`${name}-LEVEL`} min={1} max={20} value={level} />
      </InputGroup>
      <ButtonBar>
        <ExpandOptions>
          <Button onClick={exportClass}><FaFileExport /> Export Class</Button>
          <Button variant="destructive" onClick={remove}><FaTrash /> Delete Class</Button>
        </ExpandOptions>
      </ButtonBar>
    </div>
    <HitDiceInput value={hitDice} />
    <SpellSlotProgressionInput value={spellSlotProgression} />
    <InputDnd5eCharacterClassFeatures value={features} level={level} />
  </div>);
}

export type ClassesProps = {
  value: MutableRef<Dnd5eCharacterClass[], ListOperation<Dnd5eCharacterClass, Dnd5eCharacterClassOperation>[]>;
};
export function Classes({value}: ClassesProps) {
  return (<div className="flex flex-col gap-1">
    <BaseComponent>
      <div className="flex flex-row gap-0.5">
        <PanelHeader className="flex-1">Classes</PanelHeader>
        <ButtonBar>
          <Button onClick={() => {
            value.apply(prev => ListOperation.insert(prev.length, {
              id: generateDnd5eCharacterClassID(),
              class: "",
              subclass: "",
              hitDice: {current: 1, diceFace: 8},
              level: 1,
              features: []
            }))
          }}>
            <FaPlus/> New Class
          </Button>
          <ExpandOptions>
            <ImportButton type={dnd5eCharacterClassType} onImport={(next: Dnd5eCharacterClass) => {
              value.apply(prev => ListOperation.insert(prev.length, next))
            }}>Import Class</ImportButton>
          </ExpandOptions>
        </ButtonBar>
      </div>
    </BaseComponent>

    <GenericInputList<Dnd5eCharacterClass, Dnd5eCharacterClassOperation>
      items={value}
      itemKey={Dnd5eCharacterClassFn.getClassID}
      Component={useCallback((props) => <div className="flex flex-col gap-1" {...props} />, [])}
      ListItem={useCallback(({item, itemKey, remove}: ListItemProps<Dnd5eCharacterClass, Dnd5eCharacterClassOperation>) => <BaseComponent key={itemKey}><ClassInput value={item} remove={remove} /></BaseComponent>, [value])} />
  </div>);
}
import {
  Dnd5eActionTemplateSegmentSavingThrowModifier
} from "common/legends/asset/sheet/dnd-5e/dnd-5e-action-definition/segment/saving-throw/dnd-5e-action-template-segment-saving-throw-modifier.ts";
import {
  Dnd5eActionTemplateSegmentSavingThrowDifficultyClassModifier
} from "common/legends/asset/sheet/dnd-5e/dnd-5e-action-definition/segment/saving-throw/dnd-5e-action-template-segment-saving-throw-difficulty-class-modifier.ts";
import {Optional} from "common/types/generic/index.ts";
import {Dnd5eFeature, Sheet} from "common/legends/asset/index.ts";
import {Dnd5eActionTemplate} from "common/legends/asset/sheet/dnd-5e/dnd-5e-action-definition/template/dnd-5e-action-template.ts";
import {Dnd5eActionTemplateSegment} from "common/legends/asset/sheet/dnd-5e/dnd-5e-action-definition/segment/dnd-5e-action-template-segment.ts";
import {conditionEvaluator} from "../condition/condition-evaluator.ts";
import {Dnd5eActionTemplateModifier} from "common/legends/asset/sheet/dnd-5e/dnd-5e-action-definition/modifier/dnd-5e-action-template-modifier.ts";
import {getActiveModifiers} from "common/legends/asset/sheet/dnd-5e/dnd-5e-modifier-helper.ts";

export function getActiveDifficultyClassModifiers(
  sheet: Optional<Sheet>,
  globalFeatures: Dnd5eFeature[],
  action: Optional<Dnd5eActionTemplate>,
  segment: Optional<Dnd5eActionTemplateSegment>
): Dnd5eActionTemplateSegmentSavingThrowDifficultyClassModifier[] {
  const evaluateCondition = conditionEvaluator(sheet, globalFeatures, action);
  const getSheetModifiers = (): Dnd5eActionTemplateSegmentSavingThrowDifficultyClassModifier[] => {
    return getActiveModifiers(sheet, globalFeatures)
      .flatMap(modifier => modifier.type === "action::saving-throw::difficulty-class" ? [modifier.data] : []);
  };
  const getActionModifiers = (modifier: Dnd5eActionTemplateModifier): Dnd5eActionTemplateSegmentSavingThrowDifficultyClassModifier[] => {
    if (modifier.type === "action::condition") {
      return evaluateCondition(modifier.data.condition)
        ? modifier.data.modifiers.flatMap(getActionModifiers, [])
        : [];
    } else if (modifier.type === "action::saving-throw::difficulty-class") {
      return [modifier.data];
    } else {
      return [];
    }
  };
  const getSegmentModifiers = (modifier: Dnd5eActionTemplateSegmentSavingThrowModifier): Dnd5eActionTemplateSegmentSavingThrowDifficultyClassModifier[] => {
    if (modifier.type === "action::saving-throw::condition") {
      return evaluateCondition(modifier.data.condition) ? modifier.data.modifiers.flatMap(getSegmentModifiers) : [];
    } else if (modifier.type === "action::saving-throw::difficulty-class") {
      return [modifier.data];
    } else {
      return [];
    }
  };

  const actionModifiers = action?.type === "custom" ? [
    ...action.data.modifiers,
    ...action.data.effects.filter(effect => effect.enabled).flatMap(effect => effect.modifiers)
  ].flatMap(getActionModifiers) : [];
  const segmentModifiers = segment?.type === "saving-throw" ? segment.data.modifiers.flatMap(getSegmentModifiers) : [];
  return [
    ...getSheetModifiers(),
    ...actionModifiers,
    ...segmentModifiers
  ];
}

import {Menu} from "@headlessui/react";
import {Button} from "#lib/components/index.ts";
import {FaPlus} from "react-icons/fa";
import {ExternalPortal} from "#lib/container/react/external-window/external-portal.tsx";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faDice, faHeader, faMessage, faScrewdriverWrench, faShield, faSword} from "@awesome.me/kit-c9bc6845cd/icons/classic/solid";
import React, {useCallback, useState} from "react";
import {usePopper} from "react-popper";
import {
  Dnd5eActionTemplateSegment,
  Dnd5eActionTemplateSegmentOperation
} from "common/legends/asset/sheet/dnd-5e/dnd-5e-action-definition/segment/dnd-5e-action-template-segment.ts";
import {ListOperation, RichTextFn} from "common/types/generic/index.ts";
import {generateDnd5eActionTemplateSegmentID} from "common/legends/asset/sheet/dnd-5e/dnd-5e-action-definition/segment/dnd-5e-action-template-segment-i-d.ts";
import {generateRollRequestID} from "common/qlab/message/index.ts";
import {DiceExpression} from "common/dice/dice-expression.ts";
import {MutableRef} from "common/ref";

export function Dnd5eActionTemplateAddSegmentButton({valueRef, onAddedSegment}: {
  valueRef: MutableRef<Dnd5eActionTemplateSegment[], ListOperation<Dnd5eActionTemplateSegment, Dnd5eActionTemplateSegmentOperation>[]>,
  onAddedSegment?: (segment: Dnd5eActionTemplateSegment) => void;
}) {
  const [referenceElement, setReferenceElement] = useState<HTMLElement | null>(null);
  const [popperElement, setPopperElement] = useState<HTMLElement | null>(null);
  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    placement: "bottom-end"
  });

  const addActionSegment = useCallback((segment: Dnd5eActionTemplateSegment) => {
    valueRef.apply(prev => ListOperation.insert(prev.length, segment)).then(() => {
      if (onAddedSegment) onAddedSegment(segment);
    });
  }, [valueRef.apply]);
  const addTextSegment = () => addActionSegment({type: "text", data: {
    actionSegmentID: generateDnd5eActionTemplateSegmentID(),
    content: RichTextFn.EMPTY
  }});
  const addSectionSegment = () => addActionSegment({type: "section", data: {
    actionSegmentID: generateDnd5eActionTemplateSegmentID(),
    name: "",
    segments: []
  }});
  const addDamageSegment = () => addActionSegment({type: "roll", data: {
    actionSegmentID: generateDnd5eActionTemplateSegmentID(),
    expressions: [{rollID: generateRollRequestID(), rollType: undefined, expression: DiceExpression.parse("0")}],
    modifiers: []
  }});
  const addAttackRollSegment = () => addActionSegment({type: "attack-roll", data: {
    actionSegmentID: generateDnd5eActionTemplateSegmentID(),
    attribute: undefined,
    criticalRange: DiceExpression.parse("20"),
    expression: DiceExpression.parse("0"),
    proficient: true,
    modifiers: [],
    onHit: [],
    onCriticalHit: [],
    onMiss: []
  }});
  const addSavingThrowSegment = () => addActionSegment({type: "saving-throw", data: {
    actionSegmentID: generateDnd5eActionTemplateSegmentID(),
    savingThrowTypes: [],
    attribute: undefined,
    expression: DiceExpression.parse("0"),
    proficient: true,
    modifiers: [],
    onFailure: [],
    onSuccess: []
  }});
  const addAbilityCheckSegment = () => addActionSegment({type: "ability-check", data: {
    actionSegmentID: generateDnd5eActionTemplateSegmentID(),
    abilityCheck: undefined,
    abilityCheckTypes: [],
    expression: DiceExpression.parse("0"),
    modifiers: [],
    onFailure: [],
    onSuccess: []
  }});
  return (<Menu ref={ref => setReferenceElement(ref)} as="div">
    <Menu.Button as={Button}>
      <FaPlus /> Add Segment
    </Menu.Button>
    <Menu.Items as={ExternalPortal} ref={ref => setPopperElement(ref)} style={styles.popper} {...attributes.popper} className="text-white flex flex-col items-stretch rounded-md m-0.5 overflow-hidden">
      <Menu.Item as={Button} className="justify-start" onClick={addTextSegment}>
        <FontAwesomeIcon icon={faMessage} /> <span>Text</span>
      </Menu.Item>
      {/*<Menu.Item as={Button} className="justify-start" onClick={addSectionSegment}>*/}
      {/*  <FontAwesomeIcon icon={faHeader} /> <span>Section</span>*/}
      {/*</Menu.Item>*/}
      <Menu.Item as={Button} className="justify-start" onClick={addAttackRollSegment}>
        <FontAwesomeIcon icon={faSword} /> <span>Attack Roll</span>
      </Menu.Item>
      <Menu.Item as={Button} className="justify-start" onClick={addSavingThrowSegment}>
        <FontAwesomeIcon icon={faShield} /> <span>Saving Throw</span>
      </Menu.Item>
      <Menu.Item as={Button} className="justify-start" onClick={addAbilityCheckSegment}>
        <FontAwesomeIcon icon={faScrewdriverWrench} /> <span>Ability Check</span>
      </Menu.Item>
      <Menu.Item as={Button} className="justify-start" onClick={addDamageSegment}>
        <FontAwesomeIcon icon={faDice} /> <span>Roll</span>
      </Menu.Item>
    </Menu.Items>
  </Menu>);
}
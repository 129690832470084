import {ConstantOperation, ListOperation, MapFn, MapOperation, NumberFn, NumberOperation, ValueFn, ValueOperation} from "common/types/index.ts";
import {Dnd5eCharacterClass, Dnd5eSpell, Dnd5eSpellLevel, Dnd5eSpellOperation, DND_5E_SPELL_LEVEL} from "common/legends/index.ts";
import {ButtonBar, Input, InputGroup, InputGroupLabel} from "#lib/components/index.ts";
import React from "react";
import {useObservableLoader} from "#lib/qlab/index.ts";
import {BaseComponent} from "#lib/components/BaseComponent.tsx";
import {PanelHeader} from "#lib/components/panel-header.tsx";
import {useComputedValue, useRefValue} from "#lib/signal/index.ts";
import {getMaxPactSlots, getMaxSpellSlotsByClasses} from "common/legends/asset/sheet/dnd-5e/character/get-max-spell-slots-by-classes.ts";
import {InputDnd5eSpells} from "../dnd-5e/dnd-5e-spell/input-dnd-5e-spells.tsx";
import {MutableRef} from "common/ref";


export type InputDnd5eCharacterSpellsProps = {
  classes: MutableRef<Dnd5eCharacterClass[], never[]>;
  spells: MutableRef<Dnd5eSpell[], ListOperation<Dnd5eSpell, Dnd5eSpellOperation>[]>;
  spellSlots: MutableRef<{[level in Dnd5eSpellLevel]?: number}, MapOperation<Dnd5eSpellLevel, number, ValueOperation<number, ConstantOperation>>[]>
  pactSlots: MutableRef<number, NumberOperation[]>;
};
export function InputDnd5eCharacterSpells({classes, spells, spellSlots, pactSlots}: InputDnd5eCharacterSpellsProps) {
  const setSpellSlots = (level: Dnd5eSpellLevel, nextValue: number) => {
    spellSlots.apply(prev => {
      if (prev[level] === undefined) return MapFn.put(level, nextValue);
      else return MapFn.apply(level, ValueFn.set(prev[level]!, nextValue));
    });
  };
  const setPactSlot = (nextValue: number) => {
    pactSlots.apply(prev => NumberFn.set(prev, nextValue));
  };

  const spellSlotsQuery = useObservableLoader(spellSlots.observe);
  const spellSlotLevels = useComputedValue(classes, classes => {
    return Object.fromEntries(DND_5E_SPELL_LEVEL.map(level => [level, getMaxSpellSlotsByClasses(classes, level)]))
  });
  const pactSlotLevels = useComputedValue(classes, classes => {
    return Object.fromEntries(DND_5E_SPELL_LEVEL.map(level => [level, getMaxPactSlots(classes, level)]))
  });
  const pactSlotsValue = useRefValue(pactSlots);

  return <div className="flex flex-col gap-2">
    <BaseComponent>
      <PanelHeader>Spell Slots</PanelHeader>
      {DND_5E_SPELL_LEVEL.filter(level => level !== "cantrip").filter(level => pactSlotLevels[level] !== 0).map((level) => <ButtonBar key={level}>
        <InputGroup className="basis-40">
          <InputGroupLabel>Pact Slots (Level {level})</InputGroupLabel>
        </InputGroup>
        <InputGroup className="basis-20">
          <Input title="Pact Slots" type="number" className="text-right" value={pactSlotsValue || 0} onChange={ev => setPactSlot(Number.parseInt(ev.target.value) || 0)} />
        </InputGroup>
        <InputGroup><InputGroupLabel>/</InputGroupLabel></InputGroup>
        <InputGroup className="basis-20">
          <Input readOnly title="Max Pact Slots" type="number" className="text-left" value={pactSlotLevels[level] || 0} />
        </InputGroup>
        <InputGroup className="flex-1">
          <InputGroupLabel/>
        </InputGroup>
      </ButtonBar>)}
      {spellSlotsQuery.isSuccess && DND_5E_SPELL_LEVEL.filter(level => level !== "cantrip")
        .filter(level => spellSlotLevels[level] !== 0)
        .map(level => <ButtonBar key={level}>
          <InputGroup className="basis-40">
            <InputGroupLabel>Level {level}</InputGroupLabel>
          </InputGroup>
          <InputGroup className="basis-20">
            <Input title="Spells Slots" type="number" className="text-right" value={spellSlotsQuery.data[level] || 0} onChange={ev => setSpellSlots(level, Number.parseInt(ev.target.value) || 0)} />
          </InputGroup>
          <InputGroup><InputGroupLabel>/</InputGroupLabel></InputGroup>
          <InputGroup className="basis-20">
            <Input readOnly title="Max Spell Slots" type="number" className="text-left" value={spellSlotLevels[level] || 0} />
          </InputGroup>
          <InputGroup className="flex-1">
            <InputGroupLabel/>
          </InputGroup>
        </ButtonBar>
      )}
    </BaseComponent>

    <InputDnd5eSpells value={spells} />

  </div>
}
import {useEditorSheetReference} from "./use-editor-sheet-reference.ts";
import {GMSheet} from "./g-m-sheet-type.ts";
import {forwardRef, Ref, useMemo} from "react";
import {StackId} from "#lib/container/index.ts";
import {useSheetName} from "../../../common/sheet/use-sheet.ts";
import {StackItemTab, StackItemTabProps} from "#lib/container/react/stack/stack-item-tab.tsx";
import {createRef} from "common/ref";
import {EditorStacks} from "../../nav/editor/editor-stacks.ts";

export type PropertiesTabViewProps = Omit<StackItemTabProps, "Icon" | "label" | "content"> & {
  id: StackId;
  content: GMSheet;
};

export const EditorSheetTabView = forwardRef(function EditorSheetTabView({id, content, ...props}: PropertiesTabViewProps, ref: Ref<any>) {
  const pinnedSheetReference = useMemo(() => createRef(content.reference), [content.reference]);
  const referenceSheet = useEditorSheetReference(pinnedSheetReference);
  const sheetName = useSheetName(referenceSheet);

  if (sheetName === undefined || content.reference === undefined) {
    return (<StackItemTab
      ref={ref}
      {...props}
      Icon={EditorStacks["sheet"].Icon}
      label={EditorStacks["sheet"].label}
    />);
  } else {
    return (<StackItemTab
      ref={ref}
      {...props}
      Icon={EditorStacks["sheet"].Icon}
      label={`${EditorStacks["sheet"].label} / ${sheetName}`}
    />);
  }
});

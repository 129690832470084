import {ConstantOperation, ValueOperation} from "common/types/index.ts";
import {SheetReferenceProvider} from "../../../common/sheet/sheet-reference-context.ts";
import {NodeProperties} from "../node/node-properties.tsx";
import {useSuspendPresent} from "../../../common/use-optional-signal.ts";
import {MutableRef} from "common/ref";
import {useAssetNode} from "../../../common/asset/use-asset-node.ts";
import {useAssetSheet} from "../../../common/asset/use-asset-sheet.ts";
import {AssetTokenNodeSelectionRef, SelectionRef} from "../../nav/editor/state/selection-ref.ts";


export function AssetNodePropertiesView({reference, pinned}: {
  reference: AssetTokenNodeSelectionRef;
  pinned: MutableRef<SelectionRef, ValueOperation<SelectionRef, ConstantOperation>[]>;
}) {
  const node = useSuspendPresent(useAssetNode(reference.resourceId, reference.tokenId, reference.nodeId));
  const sheet = useAssetSheet(reference.resourceId, reference.tokenId);
  return <SheetReferenceProvider value={sheet}>
    <NodeProperties value={node} reference={reference} pinned={pinned} />
  </SheetReferenceProvider>
}

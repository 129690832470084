import {NumberOperation} from "../number/index.ts";
import {SizeOperation} from "./size-operation.ts";
import {PropertyRef} from "#common/types/index.ts";
import {MutableRef} from "#common/ref";
import {z} from "zod";

export const ZodSize = z.tuple([z.number(), z.number()])
export const ZodSizeOperation = z.discriminatedUnion("type", [
  z.object({type: z.literal("update-width"), operations: z.array(NumberOperation)}),
  z.object({type: z.literal("update-height"), operations: z.array(NumberOperation)})
]);

export type Size = [number, number];

export const Size = {
  getWidth: (value: Size) => value[0],
  updateWidth: (operations: NumberOperation[]): SizeOperation[] => [{type: "update-width", operations}],
  getHeight: (value: Size) => value[1],
  updateHeight: (operations: NumberOperation[]): SizeOperation[] => [{type: "update-height", operations}],
}

export function SizeSignals(value: MutableRef<Size, SizeOperation[]>) {
  return {
    width: PropertyRef<Size, SizeOperation, number, NumberOperation>(
      value => value[0],
      operations => [{type: "update-width", operations}]
    )(value),
    height: PropertyRef<Size, SizeOperation, number, NumberOperation>(
      value => value[1],
      operations => [{type: "update-height", operations}]
    )(value)
  }
}
import {Button} from "#lib/components/index.ts";
import {FaPlus} from "react-icons/fa";
import React, {useCallback, useState} from "react";
import {MutableRef} from "common/ref";
import {Dnd5eProcess, Dnd5eProcessOperation} from "common/legends/asset/sheet/dnd-5e/dnd-5e-action-definition/process/dnd-5e-process.ts";
import {ListOperation} from "common/types/generic/index.ts";
import {Menu} from "@headlessui/react";
import {ExternalPortal} from "#lib/container/react/external-window/external-portal.tsx";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faFlask, faSack, faSparkles} from "@awesome.me/kit-c9bc6845cd/icons/classic/solid";
import {usePopper} from "react-popper";
import {generateDnd5eProcessID} from "common/legends/asset/sheet/dnd-5e/dnd-5e-action-definition/process/dnd-5e-process-i-d.ts";

export function Dnd5eAddProcessButton({valueRef, onAdded}: {
  valueRef: MutableRef<Dnd5eProcess[], ListOperation<Dnd5eProcess, Dnd5eProcessOperation>[]>,
  onAdded?: (process: Dnd5eProcess) => void;
}) {
  const [referenceElement, setReferenceElement] = useState<HTMLElement | null>(null);
  const [popperElement, setPopperElement] = useState<HTMLElement | null>(null);
  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    placement: "bottom-end"
  });

  const addProcess = useCallback((segment: Dnd5eProcess) => {
    valueRef.apply(prev => ListOperation.insert(prev.length, segment)).then(() => {
      if (onAdded) onAdded(segment);
    });
  }, [valueRef.apply, onAdded]);

  const addAdjustSpellSlot = useCallback(() => {
    addProcess({type: "adjust-spell-slot", data: {
      processID: generateDnd5eProcessID(),
      level: "1",
      quantity: "-1"
    }});
  }, [addProcess]);

  const addAdjustItem = useCallback(() => {
    addProcess({type: "consume-item", data: {
      processID: generateDnd5eProcessID(),
      item: ""
    }});
  }, [addProcess]);

  const addAdjustCustomResource = useCallback(() => {
    addProcess({type: "adjust-custom-resource", data: {
      processID: generateDnd5eProcessID(),
      resource: "",
      quantity: "-1"
    }});
  }, [addProcess]);

  return (<Menu ref={ref => setReferenceElement(ref)} as="div">
    <Menu.Button as={Button}>
      <FaPlus /> Add Process
    </Menu.Button>
    <Menu.Items as={ExternalPortal} ref={ref => setPopperElement(ref)} style={styles.popper} {...attributes.popper} className="text-white flex flex-col items-stretch rounded-md m-0.5 overflow-hidden">
      <Menu.Item as={Button} className="justify-start" onClick={addAdjustCustomResource}>
        <FontAwesomeIcon icon={faFlask} />
        <span>Adjust Resource</span>
      </Menu.Item>
      <Menu.Item as={Button} className="justify-start" onClick={addAdjustSpellSlot}>
        <FontAwesomeIcon icon={faSparkles} />
        <span>Adjust Spell Slots</span>
      </Menu.Item>
      <Menu.Item as={Button} className="justify-start" onClick={addAdjustItem}>
        <FontAwesomeIcon icon={faSack} />
        <span>Consume Item</span>
      </Menu.Item>
    </Menu.Items>
  </Menu>);
}
import {
  ConstantOperation,
  constantType,
  ObjectType,
  Set,
  SetOperation,
  SetType,
  Transform,
  TransformOperation,
  transformType,
  Type,
  ValueOperation,
  ValueType
} from "common/types/index.ts";
import {SceneReference} from "common/legends/scene/scene-reference.ts";
import {MutableRef} from "common/ref";
import {Tool, ToolOperation, toolType} from "../../common/tool/tool.ts";
import {NodeSelectionRef, SelectionRef} from "../../panel/nav/editor/state/selection-ref.ts";
import {EditorVision, EditorVisionOperation, editorVisionType} from "./editor-vision.ts";

export type SceneViewportProperties = {
  sceneReference: SceneReference;
  toolMode: Tool;
  selectedNodeIds: Set<NodeSelectionRef>;
  vision: EditorVision;
  view: Transform;
};
export type SceneViewportPropertiesOperation =
  | {type: "update-scene-reference", operations: ValueOperation<SceneReference, ConstantOperation>[]}
  | {type: "update-tool-mode", operations: ToolOperation[]}
  | {type: "update-selected-node-ids", operations: SetOperation<NodeSelectionRef>[]}
  | {type: "update-vision", operations: EditorVisionOperation[]}
  | {type: "update-view", operations: TransformOperation[]}
  ;
export const sceneViewportPropertiesType: Type<SceneViewportProperties, SceneViewportPropertiesOperation> = new ObjectType({
  sceneReference: new ValueType(constantType),
  toolMode: toolType,
  selectedNodeIds: new SetType<NodeSelectionRef>(SelectionRef.equals),
  vision: editorVisionType,
  view: transformType
}, v => {
  if (v["viewConfiguration"]) delete v["viewConfiguration"];
  if (v["visibilityMask"]) {
    v["vision"] = {senseID: undefined, override: false} satisfies EditorVision;
    delete v["visibilityMask"];
  }
  return v;
});

export function SceneViewportPropertiesSignals(value: MutableRef<SceneViewportProperties, SceneViewportPropertiesOperation[]>) {
  return {
    toolMode: value.map<Tool, ToolOperation[]>(
      value => value.toolMode,
      (_, operations) => [{type: "update-tool-mode", operations}]
    ),
    view: value.map<Transform, TransformOperation[]>(
      value => value.view,
      (_, operations) => [{type: "update-view", operations}]
    ),
    sceneReference: value.map<SceneReference, ValueOperation<SceneReference, ConstantOperation>[]>(
      value => value.sceneReference,
      (_, operations) => [{type:"update-scene-reference", operations}]
    ),
    visionRef: value.map<EditorVision, EditorVisionOperation[]>(value => value.vision, (_, operations) => [{type: "update-vision", operations}]),
  };
}
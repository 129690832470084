import {Point, ValueFn} from "common/types/index.ts";
import {Dialog} from "@headlessui/react";
import React, {useCallback, useState} from "react";
import {Node} from "common/legends/index.ts";
import {useRefValue} from "#lib/signal/index.ts";
import {AssetContextMenu} from "./asset-context-menu.tsx";
import {usePopper} from "react-popper";
import {ExternalPortal} from "#lib/container/react/external-window/external-portal.tsx";
import {Popper} from "#lib/components/popper/popper.tsx";
import {TokenContextMenu} from "./token-context-menu.tsx";
import {MutableRef} from "common/ref";
import {ContextMenu, ContextMenuOperation} from "../../../../../routes/game/context-menu/context-menu.ts";
import {useCurrentSceneID} from "../../../../viewport/scene/current-scene-provider.ts";
import {useIsGameMaster} from "../../../../common/game/use-is-game-master.ts";


export type EditorContextMenuProps = {
  value: MutableRef<ContextMenu, ContextMenuOperation[]>;
  onDrop: (screenPosition: Point, fn: (worldPosition: Point) => Node) => void;
};

export function EditorContextMenu({value, onDrop}: EditorContextMenuProps) {
  const [referenceElement, setReferenceElement] = useState<HTMLElement | null>(null);
  const [popperElement, setPopperElement] = useState<HTMLElement | null>(null);
  const { styles, attributes } = usePopper(referenceElement, popperElement, {placement: "bottom-end",
  });
  const sceneID = useCurrentSceneID();
  const isGameMaster = useIsGameMaster();

  const resolvedContextMenu = useRefValue(value);
  const close = useCallback(() => value.apply(prev => ValueFn.set(prev, undefined)), [value.apply]);
  if (!resolvedContextMenu) return <></>
  return (<Dialog static open className="absolute" style={{
    left: resolvedContextMenu.data.clientPos[0],
    bottom: document.documentElement.clientHeight - resolvedContextMenu.data.clientPos[1]
  }} onClose={close} as={ExternalPortal} ref={ref => setReferenceElement(ref)}>
    <Dialog.Panel as={Popper} ref={ref => setPopperElement(ref)}  {...attributes.popper}  style={styles.popper} className="bg-zinc-900/50 rounded-lg backdrop-blur-sm text-white overflow-hidden rounded-bl-none flex flex-col gap-0.5">
      {resolvedContextMenu.type === "token" && <>
        {isGameMaster && sceneID && <TokenContextMenu selectedNodeID={resolvedContextMenu.data.selectedNodeID} targetNodeID={undefined} />}
        {resolvedContextMenu.data.targetNodeIDs.map(targetNodeID => <TokenContextMenu key={targetNodeID} selectedNodeID={resolvedContextMenu.data.selectedNodeID} targetNodeID={targetNodeID} />)}
      </>}
      {resolvedContextMenu.type === "asset" && <AssetContextMenu contextMenu={value} screenPos={resolvedContextMenu.data.screenPos} assetID={resolvedContextMenu.data.assetID} onDrop={onDrop} />}
    </Dialog.Panel>
  </Dialog>);
}

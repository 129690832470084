import {useObservableLoader} from "#lib/qlab/index.ts";
import {useMemo} from "react";
import {FileReference} from "common/types/index.ts";
import {Observer} from "common/observable";
import {useVideoTextureManager} from "#lib/qlab/react/hooks/asset/video-texture-manager.tsx";

export function useVideoTexture(url: FileReference): [boolean, WebGLTexture] {
  const textureManager = useVideoTextureManager();
  const loader = useObservableLoader(useMemo(() => {
    if (url === undefined) return (_: Observer<WebGLTexture>) => {
      return () => {};
    };
    return textureManager.videoTextureObservable(url)
  }, [textureManager, url]));
  if (loader.isLoading) return [false, 0];
  return [true, loader.data];
}

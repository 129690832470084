import {InputGroup, InputGroupColorButton, InputGroupLabel} from "#lib/components/index.ts";
import {useMemo} from "react";
import {MutableRef} from "common/ref";
import {InputCheckbox} from "#lib/components/input/input-checkbox.tsx";
import {MeasurementRectangle, MeasurementRectangleOperation, MeasurementRectangleRef} from "../../../../../common/tool/measurement/measurement-rectangle.ts";

export function MeasurementRectangleProperties({valueRef}: {valueRef: MutableRef<MeasurementRectangle, MeasurementRectangleOperation[]>}) {
  const {stickyRef, fillColorRef} = useMemo(() => MeasurementRectangleRef(valueRef), [valueRef]);

  return <>
    <InputGroup className="pl-0">
      <InputCheckbox value={stickyRef} />
      <InputGroupLabel>Sticky</InputGroupLabel>
    </InputGroup>
    <InputGroup>
      <InputGroupLabel>Color</InputGroupLabel>
      <InputGroupColorButton valueRef={fillColorRef} />
    </InputGroup>
  </>
}
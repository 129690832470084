import {ButtonBar, IconButton} from "#lib/components/index.ts";
import {twMerge} from "tailwind-merge";
import {FaHandPaper, FaMousePointer} from "react-icons/fa";
import React from "react";
import {ValueFn} from "common/types/index.ts";
import {useRefValue} from "#lib/signal/index.ts";
import {MutableRef} from "common/ref";
import {MeasurementButton} from "./measurement/measurement-button.tsx";
import {Tool, ToolOperation} from "../../../../common/tool/tool.ts";

export function ToolModeInput({valueRef}: {
  valueRef: MutableRef<Tool, ToolOperation[]>;
}) {
  const tool = useRefValue(valueRef);
  return (
    <ButtonBar className="overflow-hidden rounded-lg gap-0.5">
      <IconButton title="Pan" className={twMerge(tool.type !== "pan" && "opacity-50 hover:opacity-80")} onClick={() => valueRef.apply(prevValue => ValueFn.set(prevValue, {type: "pan", data: {}}))}>
        <FaHandPaper />
      </IconButton>
      <IconButton title="Select" className={twMerge(tool.type !== "selection" && "opacity-50 hover:opacity-80")} onClick={() => valueRef.apply(prevValue => ValueFn.set(prevValue, {type: "selection", data: {}}))}>
        <FaMousePointer />
      </IconButton>
      <MeasurementButton toolRef={valueRef} />
    </ButtonBar>
  );
}
import {forwardRef, PropsWithChildren, Ref} from "react";
import {twMerge} from "tailwind-merge";

export type BreadcrumbProps = PropsWithChildren<{
  className?: string;
}>;

export const Breadcrumb = forwardRef<HTMLDivElement, BreadcrumbProps>(function Breadcrumb({className, children}: BreadcrumbProps, ref: Ref<HTMLDivElement>) {
  return (
    <div ref={ref} className={twMerge(
      "bg-zinc-800/50",
      "text-white/80 p-0 outline-none w-full",
      "flex flex-row h-12 gap-0.5 items-center",
      className
    )}>
      {children}
    </div>
  );
});

export function BreadcrumbItem({Icon, onClick, title, children}: PropsWithChildren<{
  title?: string,
  Icon: JSX.Element,
  onClick: () => void
}>) {
  return <button className={twMerge(
    "bg-zinc-900/80 h-12 shrink-0 flex items-center justify-center pointer-events-auto p-4 gap-4"
  )} onClick={onClick} title={title}>
    <div className="h-12 flex justify-center items-center">{Icon}</div>
    {children && <span className="font-semibold text-xl">{children}</span>}
  </button>
}
import {LightNode} from "common/legends/node/light/light-node.ts";
import {Transform} from "common/types/generic/index.ts";
import {usePVM} from "./pvm-context.ts";
import {useMemo} from "react";
import {Matrix4f} from "#lib/math/index.ts";
import {Vector2} from "common/math/vector/vector2.ts";
import {LightShapeFn} from "common/legends/node/light/light-shape.ts";

export function useIsLightElementOnScreen(value: LightNode, model: Transform) {
  const {projection, view} = usePVM();
  return useMemo(() => {
    if (value.shape?.type === "global") return true;
    const invertViewTransform = Matrix4f.invert(Matrix4f.transform(view));
    const matrixTransform = Matrix4f.multiplyMatrix(invertViewTransform, Matrix4f.invert(projection));
    const screen = [
      Vector2.multiplyMatrix4x4([-1, 1], matrixTransform),
      Vector2.multiplyMatrix4x4([1, 1], matrixTransform),
      Vector2.multiplyMatrix4x4([1, -1], matrixTransform),
      Vector2.multiplyMatrix4x4([-1, -1], matrixTransform)
    ];
    const minX = Math.min(...screen.map(p => p[0]));
    const maxX = Math.max(...screen.map(p => p[0]));
    const minY = Math.min(...screen.map(p => p[1]));
    const maxY = Math.max(...screen.map(p => p[1]));

    const size = LightShapeFn.getLightSize(value.shape);
    const origin = LightShapeFn.getLightOrigin(value.shape, value.origin);
    const shape = ([
      [-origin[0], -origin[1]],
      [-origin[0], -origin[1] + size[1]],
      [-origin[0] + size[0], -origin[1] + size[1]],
      [-origin[0] + size[0], -origin[1]],
    ] satisfies Vector2[]).map(p => Vector2.multiplyMatrix4x4(p, Matrix4f.transform(model)));

    return !(
      shape.every(([x, _]) => x <= minX) ||
      shape.every(([x, _]) => x >= maxX) ||
      shape.every(([_, y]) => y <= minY) ||
      shape.every(([_, y]) => y >= maxY)
    );
  }, [value.origin, value.shape, projection, view, model]);
}
import {Color, FileReference, Point, Tree} from "common/types/index.ts";
import {useComputedValue, useRefValue} from "#lib/signal/index.ts";
import React, {useMemo} from "react";
import {Node, NodeId} from "common/legends/index.ts";
import {WallLineShader} from "../../shader/wall-line-shader.tsx";
import {Vector2} from "common/math/vector/vector2.ts";
import {LineShader} from "../../shader/line-shader.tsx";
import {MutableRef, Ref} from "common/ref";

import faEyeSlash from "./eye-slash.png";
import faEyeSolid from "./eye-solid.png";
import {ImageShader} from "../../shader/image-shader.tsx";
import {VertexSelectionIndicator} from "../element/vertex-selection-indicator.tsx";
import {useImageTexture} from "../../context/use-image-texture.ts";
import {WallToolSelectMode} from "../../../../common/tool/wall/wall-tool-select-mode.ts";
import {Tool, ToolOperation} from "../../../../common/tool/tool.ts";
import {cubicBezier, lerpBezier, perpendicularBezier} from "common/math/bezier/bezier.ts";
import {WallLineFn} from "../../../scene/scene-view.tsx";
import {SplineFn} from "common/types/generic/spline/index.ts";
import {ModelProvider} from "../../context/pvm-context.ts";
import {getNodeTransform} from "../../../tool/use-node-pos-to-world-pos.ts";
import faCircleStop from "./circle-stop-regular.svg";
import faCircleStopSlash from "./regular-circle-stop-slash.svg";

function WallGraphVertexMode({nodeID, nodes, selection}: {
  nodeID: NodeId,
  selection: WallToolSelectMode;
  nodes: Ref<Node[]>;
}) {
  const point = useComputedValue(nodes, nodes => {
    const node = Tree.getItemById(nodes, nodeID);
    if (node?.type !== "wall") return undefined;
    if (selection?.type === "vertex") {
      return node.data.graph.vertices[selection.vertexIndex];
    }
    return undefined;
  }, [selection?.type === "vertex" && selection.vertexIndex]);
  const valueModel = getNodeTransform(useRefValue(nodes), nodeID);
  return <ModelProvider value={valueModel}>
    {point && <VertexSelectionIndicator origin={point.coordinate} />}
  </ModelProvider>
}

type WallGraphEdgeNodeProps = {
  nodeID: NodeId,
  selection: WallToolSelectMode;
  nodes: Ref<Node[]>;
};

function WallGraphEdgeMode({nodeID, nodes, selection}: WallGraphEdgeNodeProps) {
  const graph = useComputedValue(nodes, nodes => {
    const node = Tree.getItemById(nodes, nodeID);
    if (node?.type !== "wall") return undefined;
    return node.data.graph;
  });
  const edge = useMemo(() => {
    if (graph === undefined) return undefined;
    if (selection?.type === "edge" || selection?.type === "control-point") {
      return graph.edges[selection.edgeIndex];
    } else return undefined;
  }, [graph, selection?.type === "edge" && selection.edgeIndex])
  const [isInvisibleLoaded, invisibleImage] = useImageTexture(faEyeSlash as FileReference);
  const [isVisibleLoaded, visibleImage] = useImageTexture(faEyeSolid as FileReference);

  const [isBlockMovementLoaded, blockMovementImage] = useImageTexture(faCircleStop as FileReference);
  const [isAllowMovementLoaded, allowMovementImage] = useImageTexture(faCircleStopSlash as FileReference);


  const lines = useMemo(() => {
    if (graph === undefined || edge === undefined) return undefined;
    return WallLineFn.toLines(cubicBezier(
      graph.vertices[edge.start].coordinate,
      graph.vertices[edge.end].coordinate,
      edge.controlPoint1,
      edge.controlPoint2,
      edge.resolution
    ), !edge.data.blockVisibilityLeft, !edge.data.blockVisibilityRight, edge.data.blockVisibilityLeft, edge.data.blockVisibilityRight, edge.data.tint);
  }, [graph, edge]);

  const visibilityIndicators = useMemo((): [Point, Point] | undefined => {
    if (graph === undefined || edge === undefined) return undefined;
    const a = graph.vertices[edge.start].coordinate;
    const b = graph.vertices[edge.end].coordinate;
    const mid = lerpBezier(a, b, edge.controlPoint1, edge.controlPoint2, edge.resolution, 0.5);
    const p = perpendicularBezier(a,b, edge.controlPoint1, edge.controlPoint2, edge.resolution, 0.5);
    const u = Vector2.normalize([p[1], -p[0]]);
    return [
      Vector2.add(Vector2.add(mid, Vector2.multiply(u, 18)), Vector2.multiply(p, -48)),
      Vector2.add(Vector2.add(mid, Vector2.multiply(u, 18)), Vector2.multiply(p, 48))
    ];
  }, [graph, edge]);

  const blockMovementIndicators = useMemo((): [Point, Point] | undefined => {
    if (graph === undefined || edge === undefined) return undefined;
    const a = graph.vertices[edge.start].coordinate;
    const b = graph.vertices[edge.end].coordinate;
    const mid = lerpBezier(a, b, edge.controlPoint1, edge.controlPoint2, edge.resolution, 0.5);
    const p = perpendicularBezier(a,b, edge.controlPoint1, edge.controlPoint2, edge.resolution, 0.5);
    const u = Vector2.normalize([p[1], -p[0]]);
    return [
      Vector2.add(Vector2.add(mid, Vector2.multiply(u, -18)), Vector2.multiply(p, -48)),
      Vector2.add(Vector2.add(mid, Vector2.multiply(u, -18)), Vector2.multiply(p, 48))
    ];
  }, [graph, edge]);


  const controlPoints = useMemo((): [Point, Point] | undefined => {
    if (graph === undefined || edge === undefined) return undefined;
    const start = graph.vertices[edge.start].coordinate;
    const end = graph.vertices[edge.end].coordinate;
    return [
      Vector2.add(start, edge.controlPoint1),
      Vector2.add(end, edge.controlPoint2),
    ];
  }, [graph, edge]);

  const valueModel = getNodeTransform(useRefValue(nodes), nodeID);

  return <ModelProvider value={valueModel}>
    {lines && lines.length > 0 && <>
      <WallLineShader origin={[0, 0]} lines={lines} scale={1} color={Color.WHITE} opacity={0.25} editMode/>
      {edge && visibilityIndicators && isVisibleLoaded && isInvisibleLoaded && <ImageShader albedoTexture={edge.data.blockVisibilityLeft ? invisibleImage : visibleImage} size={[32, 32]} origin={
        Vector2.add(
          Vector2.subtract([0, 0], visibilityIndicators[0]),
          [16, 16]
        )} opacity={0.9} repeatX={1} repeatY={1} />}
      {edge && visibilityIndicators && isVisibleLoaded && isInvisibleLoaded && <ImageShader albedoTexture={edge.data.blockVisibilityRight ? invisibleImage : visibleImage} size={[32, 32]} origin={
        Vector2.add(Vector2.subtract([0, 0], visibilityIndicators[1]), [16, 16])} opacity={0.9} repeatX={1} repeatY={1} />}

      {edge && blockMovementIndicators && isAllowMovementLoaded && isBlockMovementLoaded && <ImageShader albedoTexture={edge.data.blockMovementLeft ? blockMovementImage : allowMovementImage} size={[32, 32]} origin={
        Vector2.add(
          Vector2.subtract([0, 0], blockMovementIndicators[0]),
          [16, 16]
        )} opacity={0.9} repeatX={1} repeatY={1} />}
      {edge && blockMovementIndicators && isAllowMovementLoaded && isBlockMovementLoaded && <ImageShader albedoTexture={edge.data.blockMovementRight ? blockMovementImage : allowMovementImage} size={[32, 32]} origin={
        Vector2.add(Vector2.subtract([0, 0], blockMovementIndicators[1]), [16, 16])} opacity={0.9} repeatX={1} repeatY={1} />}

      {graph && edge && controlPoints && <>
        <LineShader origin={[0, 0]} start={graph.vertices[edge.start].coordinate} end={controlPoints[0]} scale={1} color={Color.BLACK75} />
        <LineShader origin={[0, 0]} start={graph.vertices[edge.end].coordinate} end={controlPoints[1]} scale={1} color={Color.BLACK75} />
        <VertexSelectionIndicator origin={controlPoints[0]} />
        <VertexSelectionIndicator origin={controlPoints[1]} />
      </>}
    </>}
  </ModelProvider>
}

export function WallToolView({value, nodes}: {
  value: MutableRef<Tool, ToolOperation[]>;
  nodes: Ref<Node[]>;
}) {
  const wallTool = useComputedValue(value, value => value.type === "wall" ? value.data : undefined);
  const valueModel = getNodeTransform(useRefValue(nodes), wallTool?.nodeID);

  if (wallTool === undefined) return <></>
  if (wallTool.mode?.type === "create") {
    return <>{wallTool.mode.data.spline && <ModelProvider value={valueModel}>
      <WallLineShader origin={[0, 0]} lines={WallLineFn.toLines(SplineFn.getLines(wallTool.mode.data.spline, 8), false, false, true, true, Color.CLEAR)} scale={1} color={Color.WHITE} opacity={0.75} />
    </ModelProvider>}</>;
  } else if (wallTool.mode?.type === "destroy") {
    return <>{wallTool.mode.data.spline && <ModelProvider value={valueModel}>
      <WallLineShader origin={[0, 0]} lines={WallLineFn.toLines(SplineFn.getLines(wallTool.mode.data.spline, 8), false, false, true, true, Color.CLEAR)} scale={1} color={Color.RED} opacity={0.75} />
    </ModelProvider>}</>;
  } else if (wallTool.mode?.type === "slicer") {
    return <>{wallTool.mode.data.spline && <ModelProvider value={valueModel}>
      <WallLineShader origin={[0, 0]} lines={WallLineFn.toLines(SplineFn.getLines(wallTool.mode.data.spline, 8), false, false, true, true, Color.CLEAR)} scale={1} color={Color.BLUE} opacity={0.75} />
    </ModelProvider>}</>;
  } else if (wallTool.mode?.type === "select" && wallTool.mode.data?.type === "vertex") {
    return <WallGraphVertexMode nodeID={wallTool.nodeID} selection={wallTool.mode.data} nodes={nodes} />
  } else if (wallTool.mode?.type === "select" && (wallTool.mode.data?.type === "edge" || wallTool.mode.data?.type === "control-point")) {
    return <WallGraphEdgeMode nodeID={wallTool.nodeID} selection={wallTool.mode.data} nodes={nodes} />
  } else {
    return <></>
  }
}

import {useMemo} from "react";
import {AssetViewportProperties, AssetViewportPropertiesOperation} from "./asset-viewport-properties.ts";
import {ApplyAction, Optional, ValueFn} from "common/types/index.ts";
import {pipe} from "common/pipe";
import {distinct, filter, map} from "common/observable";
import {MutableRef} from "common/ref";
import {useEditor} from "../../panel/nav/editor/editor-context.ts";
import {EditorState} from "../../panel/nav/editor/state";

export function useAssetViewportProperties() {
  const editor = useEditor();
  return useMemo((): MutableRef<Optional<AssetViewportProperties>, AssetViewportPropertiesOperation[]> => {
    const valueFn = (value: EditorState) => value?.type === "asset" ? value.data : undefined;
    return new MutableRef({
      value(): Optional<AssetViewportProperties> {return valueFn(editor.state.value)},
      observe: pipe(editor.state.observe, filter(v => v?.type === "asset"), map(valueFn), distinct()),
      apply: (fn: ApplyAction<AssetViewportProperties, AssetViewportPropertiesOperation[]>) => editor.state.apply(prev => {
        if (prev?.type !== "asset") return [];
        const operations = fn(prev.data);
        if (operations.length === 0) return [];
        return ValueFn.apply([{type: "asset", operations}]);
      }).then(valueFn)
    })
  }, [editor.state]);
}

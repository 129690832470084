import React, {PropsWithChildren} from "react";
import {Dialog} from "@headlessui/react";
import {twMerge} from "tailwind-merge";

export type ModalBodyProps = PropsWithChildren<{
  className?: string;
}>;

export function ModalBody({className, children}: ModalBodyProps) {
  return (<Dialog.Description as={"div"} className={twMerge("flex flex-col gap-2", className)}>
    {children}
  </Dialog.Description>);
}

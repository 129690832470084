import {ConstantOperation, MapFn, MapRef} from "common/types/index.ts";
import {Dnd5eArmor, Dnd5eArmorProficiency, DND_5E_ARMOR_TYPES} from "common/legends/index.ts";
import {ExpandableLabel, InputGroup, InputGroupLabel, useToggle} from "#lib/components/index.ts";
import React from "react";
import {SavingThrowProficiency} from "./saving-throw-proficiency/index.ts";
import {Fieldset} from "#lib/components/fieldset/fieldset.tsx";
import {useRefValue} from "#lib/signal/index.ts";

export type ArmorProficienciesProps = {
  value: MapRef<Dnd5eArmor, Dnd5eArmorProficiency, ConstantOperation>;
};
export function ArmorProficiencies({value}: ArmorProficienciesProps) {
  const valueValue = useRefValue(value);


  const setProficiency = (armor: Dnd5eArmor, nextValue: Dnd5eArmorProficiency) => {
    return value.apply(prevValue => {
      if (prevValue[armor] === nextValue) return [];
      if (nextValue === "proficient") {
        if (prevValue[armor] === undefined) return [{type: "put", key: armor, item: "proficient"}];
        else if (prevValue[armor] === "untrained") return [
          ...MapFn.delete(armor, prevValue[armor]!),
          ...MapFn.put(armor, nextValue)
        ];
      } else {
        if (prevValue[armor] !== undefined) return [{type: "delete", key: armor, item: prevValue[armor]!}];
      }
      return [];
    });
  };

  const [expanded, toggleExpanded] = useToggle(false);

  return <div className="flex flex-col mx-2 rounded-lg overflow-hidden backdrop-blur-sm pointer-events-auto">
    <ExpandableLabel expanded={expanded} toggleExpand={toggleExpanded} className="pr-0">
      <span className="px-4 font-bold bg-zinc-800/50 h-12 flex-1 flex items-center cursor-pointer" onClick={toggleExpanded}>Armor Proficiencies</span>
    </ExpandableLabel>
    {expanded && <Fieldset className="pb-0">
      {DND_5E_ARMOR_TYPES.map(armor => <InputGroup key={armor}>
        <SavingThrowProficiency value={valueValue[armor] || "untrained"} onChange={(nextValue) => {
          setProficiency(armor, nextValue);
        }} />
        <InputGroupLabel>{armor}</InputGroupLabel>
      </InputGroup>)}
    </Fieldset>}
  </div>
}
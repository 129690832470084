import {useCallback} from "react";
import {ValueFn} from "common/types/index.ts";
import {useEditor} from "../../../editor/editor-context.ts";

export function useSetSelectTool() {
  const editor = useEditor();
  return useCallback(() => {
    editor.state.apply(prev => {
      if (prev?.type !== "scene" && prev?.type !== "asset") return [];

      return ValueFn.apply([{type: prev.type, operations: [{
        type: "update-tool-mode", operations: ValueFn.set(prev.data.toolMode, {type: "selection", data: {}})
      }]}]);
    })
  }, [editor]);
}

import {Menu} from "@headlessui/react";
import {ButtonSize, IconButton} from "#lib/components/button/index.ts";
import {Popper} from "#lib/components/popper/popper.tsx";
import React, {PropsWithChildren, useState} from "react";
import {usePopper} from "react-popper";
import * as PopperJS from "@popperjs/core";
import {ExternalPortal} from "#lib/container/react/external-window/external-portal.tsx";
import {FontAwesomeIcon, FontAwesomeIconProps} from "@fortawesome/react-fontawesome";
import {faEllipsis} from "@awesome.me/kit-c9bc6845cd/icons/classic/solid";

export function ExpandOptions({children, size, options, openIcon = faEllipsis, closeIcon = faEllipsis}: PropsWithChildren<{
  openIcon?: FontAwesomeIconProps["icon"],
  closeIcon?: FontAwesomeIconProps["icon"],
  size?: ButtonSize,
  options?: Omit<Partial<PopperJS.Options>, 'modifiers'>
}>) {
  const [referenceElement, setReferenceElement] = useState<HTMLElement | null>(null);
  const [popperElement, setPopperElement] = useState<HTMLElement | null>(null);
  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    placement: "bottom-end",
    ...options
  });

  return (<Menu>
    <Menu.Button as={IconButton} size={size} title={"More Options..."} ref={ref => setReferenceElement(ref)}>
      {({open}) => <FontAwesomeIcon icon={open ? openIcon : closeIcon} />}
    </Menu.Button>
    <Menu.Items as={ExternalPortal} ref={ref => setPopperElement(ref)} style={styles.popper} {...attributes.popper}>
      <Popper className="m-0">
        {children}
      </Popper>
    </Menu.Items>
  </Menu>);
}

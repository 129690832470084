import {
  useArrayBuffer,
  useAttribLocation,
  useBindVertexArribArray,
  useElementArrayBuffer,
  useProgram,
  useShader,
  useUniformLocation,
  useVertexBuffer
} from "#lib/gl-react/index.ts";
import React, {useMemo} from "react";
import {Color, HSLA, Point, Size} from "common/types/index.ts";
import {Matrix4f} from "#lib/math/index.ts";
import {usePVM} from "../context/pvm-context.ts";


const vertexShader = `#version 300 es
precision highp float;

in vec2 a_position;
in vec2 a_tex_coord;

uniform mat4 u_projection;
uniform mat4 u_view;
uniform mat4 u_model;

out vec2 fragCoord;
out vec2 v_tex_coord;

void main()
{
  gl_Position = u_projection * u_view * u_model * vec4(a_position, 0, 1);
  v_tex_coord = a_tex_coord;
}
`;

const fragmentShader = `#version 300 es
precision highp float;
precision highp sampler2DArray;

in vec2 v_tex_coord;
uniform vec4 u_color;

out vec4 outColor;

void main() {
  if (pow(0.5 - v_tex_coord.x, 2.) + pow(0.5 - v_tex_coord.y, 2.) > pow(0.5, 2.0)) {
    outColor = vec4(0.);
  } else {
    outColor = u_color;
  }
}
`;

export type CircleShaderProps = {
  color: HSLA;
  origin: Point;
  size: Size;
};

export function CircleShader({origin, size, color}: CircleShaderProps) {
  const {projection, view, model} = usePVM();

  const program = useProgram(
    useShader(WebGL2RenderingContext.VERTEX_SHADER, vertexShader),
    useShader(WebGL2RenderingContext.FRAGMENT_SHADER, fragmentShader)
  );
  const projectionLocation = useUniformLocation(program, "u_projection");
  const viewLocation = useUniformLocation(program, "u_view");
  const modelLocation = useUniformLocation(program, "u_model");
  const colorLocation = useUniformLocation(program, "u_color");

  const vbo = useArrayBuffer(useMemo(() => {
    const [x, y] = origin;
    const [w, h] = size;
    return new Float32Array([
      0 - x, 0 - y,  0, 1,
      w - x, 0 - y,  1, 1,
      w - x, h - y,  1, 0,
      0 - x, h - y,  0, 0
    ])
  }, [size, origin]));
  const vao = useVertexBuffer();
  useBindVertexArribArray(vao, useAttribLocation(program, "a_position"), vbo, 2, WebGL2RenderingContext.FLOAT, false, 4 * 4, 0);
  useBindVertexArribArray(vao, useAttribLocation(program, "a_tex_coord"), vbo, 2, WebGL2RenderingContext.FLOAT, false, 4 * 4, 2 * 4);

  const ebo = useElementArrayBuffer(useMemo(() => new Uint16Array([
    0, 1, 2,
    2, 3, 0
  ]), []));

  const projectionMatrix4f = useMemo(() => new Float32Array(projection), [projection]);
  const viewMatrix4f = useMemo(() => new Float32Array(Matrix4f.transform(view)), [view]);
  const modelMatrix4f = useMemo(() => new Float32Array(Matrix4f.transform(model)), [model]);
  const colorArray = useMemo(() => new Float32Array(Color.toRGBA(color)), [color]);

  return <program value={program}>
    <uniformMat4fv location={projectionLocation} transpose data={projectionMatrix4f} />
    <uniformMat4fv location={viewLocation} transpose data={viewMatrix4f} />
    <uniformMat4fv location={modelLocation} transpose data={modelMatrix4f} />
    <uniform4fv location={colorLocation} data={colorArray} />
    <vertexArray value={vao}>
      <elementArrayBuffer value={ebo}>
        <drawElements mode={WebGL2RenderingContext.TRIANGLES} type={WebGL2RenderingContext.UNSIGNED_SHORT} offset={0} count={6} />
      </elementArrayBuffer>
    </vertexArray>
  </program>;
}
import {z} from "zod";
import {MultiType, Type} from "../../../../../../../types/index.ts";
import {
  Dnd5eActionTemplateSegmentSavingThrowDifficultyClassModifier,
  Dnd5eActionTemplateSegmentSavingThrowDifficultyClassModifierOperation,
  dnd5eActionTemplateSegmentSavingThrowDifficultyClassModifierType
} from "./dnd-5e-action-template-segment-saving-throw-difficulty-class-modifier.ts";
import {
  Dnd5eActionTemplateSegmentSavingThrowAttributeOverrideModifier,
  Dnd5eActionTemplateSegmentSavingThrowAttributeOverrideModifierOperation,
  dnd5eActionTemplateSegmentSavingThrowAttributeOverrideModifierType
} from "./dnd-5e-action-template-segment-saving-throw-attribute-override-modifier.ts";
import {
  Dnd5eActionTemplateSegmentSavingThrowProficientOverrideModifier,
  Dnd5eActionTemplateSegmentSavingThrowProficientOverrideModifierOperation,
  dnd5eActionTemplateSegmentSavingThrowProficientOverrideModifierType
} from "./dnd-5e-action-template-segment-saving-throw-proficient-override-modifier.ts";
import {
  Dnd5eActionTemplateSegmentSavingThrowConditionModifier,
  Dnd5eActionTemplateSegmentSavingThrowConditionModifierOperation,
  dnd5eActionTemplateSegmentSavingThrowConditionModifierType
} from "./dnd-5e-action-template-segment-saving-throw-condition-modifier.ts";
import {Dnd5eVariableModifier, Dnd5eVariableModifierOperation, dnd5eVariableModifierType} from "../../../dnd-5e-modifier/dnd-5e-variable-modifier.ts";
import {generateDnd5eModifierID} from "../../../dnd-5e-modifier/dnd-5e-modifier-i-d.ts";

export const Dnd5eActionTemplateSegmentSavingThrowModifier = z.discriminatedUnion("type", [
  z.object({type: z.literal("variable"), data: Dnd5eVariableModifier}),
  z.object({type: z.literal("action::saving-throw::difficulty-class"), data: Dnd5eActionTemplateSegmentSavingThrowDifficultyClassModifier}),
  z.object({type: z.literal("action::saving-throw::attribute-override"), data: Dnd5eActionTemplateSegmentSavingThrowAttributeOverrideModifier}),
  z.object({type: z.literal("action::saving-throw::proficient-override"), data: Dnd5eActionTemplateSegmentSavingThrowProficientOverrideModifier}),
  z.object({type: z.literal("action::saving-throw::condition"), data: Dnd5eActionTemplateSegmentSavingThrowConditionModifier})
]);
export type Dnd5eActionTemplateSegmentSavingThrowModifier = z.infer<typeof Dnd5eActionTemplateSegmentSavingThrowModifier>;

export const Dnd5eActionTemplateSegmentSavingThrowModifierOperation = z.discriminatedUnion("type", [
  z.object({type: z.literal("variable"), operations: z.array(Dnd5eVariableModifierOperation)}),
  z.object({type: z.literal("action::saving-throw::difficulty-class"), operations: z.array(Dnd5eActionTemplateSegmentSavingThrowDifficultyClassModifierOperation)}),
  z.object({type: z.literal("action::saving-throw::attribute-override"), operations: z.array(Dnd5eActionTemplateSegmentSavingThrowAttributeOverrideModifierOperation)}),
  z.object({type: z.literal("action::saving-throw::proficient-override"), operations: z.array(Dnd5eActionTemplateSegmentSavingThrowProficientOverrideModifierOperation)}),
  z.object({type: z.literal("action::saving-throw::condition"), operations: z.array(Dnd5eActionTemplateSegmentSavingThrowConditionModifierOperation)})
]);
export type Dnd5eActionTemplateSegmentSavingThrowModifierOperation = z.infer<typeof Dnd5eActionTemplateSegmentSavingThrowModifierOperation>;

export type Dnd5eActionTemplateSegmentSavingThrowModifierTypes = {
  "variable": Type<Dnd5eVariableModifier, Dnd5eVariableModifierOperation>;
  "action::savingThrow::difficultyClass": Type<Dnd5eActionTemplateSegmentSavingThrowDifficultyClassModifier, Dnd5eActionTemplateSegmentSavingThrowDifficultyClassModifierOperation>;
  "action::savingThrow::attributeOverride": Type<Dnd5eActionTemplateSegmentSavingThrowAttributeOverrideModifier, Dnd5eActionTemplateSegmentSavingThrowAttributeOverrideModifierOperation>;
  "action::savingThrow::proficientOverride": Type<Dnd5eActionTemplateSegmentSavingThrowProficientOverrideModifier, Dnd5eActionTemplateSegmentSavingThrowProficientOverrideModifierOperation>;
  "action::savingThrow::condition": Type<Dnd5eActionTemplateSegmentSavingThrowConditionModifier, Dnd5eActionTemplateSegmentSavingThrowConditionModifierOperation>;
};

export const dnd5eActionTemplateSegmentSavingThrowModifierType: Type<Dnd5eActionTemplateSegmentSavingThrowModifier, Dnd5eActionTemplateSegmentSavingThrowModifierOperation> = new MultiType<Dnd5eActionTemplateSegmentSavingThrowModifierTypes>(() => ({
  "variable": dnd5eVariableModifierType,
  "action::savingThrow::difficultyClass": dnd5eActionTemplateSegmentSavingThrowDifficultyClassModifierType,
  "action::savingThrow::attributeOverride": dnd5eActionTemplateSegmentSavingThrowAttributeOverrideModifierType,
  "action::savingThrow::proficientOverride": dnd5eActionTemplateSegmentSavingThrowProficientOverrideModifierType,
  "action::savingThrow::condition": dnd5eActionTemplateSegmentSavingThrowConditionModifierType
}));

export const Dnd5eActionTemplateSegmentSavingThrowModifierFn = {
  copyDnd5eActionTemplateSegmentSavingThrowModifier: (value: Dnd5eActionTemplateSegmentSavingThrowModifier): Dnd5eActionTemplateSegmentSavingThrowModifier => {
    switch (value.type) {
      case "variable": return {...value, data: {...value.data, modifierID: generateDnd5eModifierID()}};
      case "action::saving-throw::difficulty-class": return {...value, data: {...value.data, modifierID: generateDnd5eModifierID()}};
      case "action::saving-throw::attribute-override": return {...value, data: {...value.data, modifierID: generateDnd5eModifierID()}};
      case "action::saving-throw::proficient-override": return {...value, data: {...value.data, modifierID: generateDnd5eModifierID()}};
      case "action::saving-throw::condition": return {...value, data: {
        ...value.data,
        modifierID: generateDnd5eModifierID(),
        modifiers: value.data.modifiers.map(Dnd5eActionTemplateSegmentSavingThrowModifierFn.copyDnd5eActionTemplateSegmentSavingThrowModifier)
      }};
    }
  }
}

import {z} from "zod";
import {Type} from "#common/types/type/index.ts";
import {VisibilityLayer} from "#common/legends/visibility/visibility-layer.ts";
import {Mask, MaskOperation, maskType} from "#common/types/generic/mask/mask.ts";

export const AccessMask = Mask;
export type AccessMask = z.infer<typeof AccessMask>;

export const AccessMaskOperation = MaskOperation;
export type AccessMaskOperation = z.infer<typeof AccessMaskOperation>;

export const accessMaskType: Type<AccessMask, AccessMaskOperation> = maskType;

export const AccessMaskFn = {
  DEFAULT: [],
  canSee: (mask: AccessMask, visibility: VisibilityLayer): boolean => {
    return visibility.some(visionID => mask.includes(visionID)) || visibility.includes("ALL");
  }
};

import {ListOperation, Optional, ValueFn} from "common/types/index.ts";
import {Dnd5eFeatureOperation, Dnd5eInventoryItemOperation, Dnd5eSpellOperation, Sheet, SheetOperation} from "common/legends/index.ts";
import {useSheetSignal} from "../../../../../common/sheet/use-sheet-signal.ts";
import {useValueSignal} from "../../../../../common/use-value-signal.ts";
import {useMemo} from "react";
import {pipe} from "common/pipe";
import {distinct, map} from "common/observable";
import {Dnd5eActionSource} from "./dnd-5e-action-source.ts";
import {MutableRef} from "common/ref";
import {SheetReferenceFn} from "../../../../../common/sheet/sheet-reference.ts";

export function useDnd5eActionSource(reference: Optional<Dnd5eActionSource>): MutableRef<Optional<any>, any> {
  const sheet = useSheetSignal(useValueSignal(reference?.sheet, SheetReferenceFn.equals));
  return useMemo(() => {
    const valueFn = (sheet: Optional<Sheet>) => {
      if (sheet?.type === "dnd-5e-character") {
        if (reference?.type === "item") {
          return sheet.data.inventory.find(item => item.itemID === reference.itemID);
        } else if (reference?.type === "spell") {
          return sheet.data.spells.find(spell => spell.spellID === reference.spellID);
        } else if (reference?.type === "race") {
          return sheet.data.race.features.find(feature => feature.featureID === reference.featureID);
        } else if (reference?.type === "background") {
          return sheet.data.background.features.find(feature => feature.featureID === reference.featureID);
        } else if (reference?.type === "class") {
          return sheet.data.classes.find(c => c.id === reference.classID)?.features.find(feature => feature.feature.featureID === reference.featureID)?.feature;
        } else if (reference?.type === "feature") {
          return sheet.data.features.find(feature => feature.featureID === reference.featureID);
        } else {
          return undefined;
        }
      } else if (sheet?.type === "dnd-5e-stat-block") {
        if (reference?.type === "spell") {
          return sheet.data.spells.find(spell => spell.spellID === reference.spellID)
        } else if (reference?.type === "feature") {
          return sheet.data.features.find(feature => feature.featureID === reference.featureID)
        } else {
          return undefined;
        }
      }
      return undefined;
    };

    return new MutableRef({
      value(): any {
        return valueFn(sheet.value);
      },
      observe: pipe(sheet.observe, map(valueFn), distinct()),
      apply: fn => sheet.apply((sheet): SheetOperation[] => {
        if (sheet?.type === "dnd-5e-character") {
          if (reference?.type === "item") {
            const itemIndex = sheet.data.inventory.findIndex(item => item.itemID === reference.itemID);
            if (itemIndex === -1) return [];
            const item = sheet.data.inventory[itemIndex];
            const operations = fn(item) as Dnd5eInventoryItemOperation[];
            if (operations.length === 0) return [];
            return [{type: "dnd-5e-character", operations: [{
                type: "update-inventory", operations: ListOperation.apply(itemIndex, operations)
              }]}];
          } else if (reference?.type === "spell") {
            const spellIndex = sheet.data.spells.findIndex(spell => spell.spellID === reference.spellID);
            if (spellIndex === -1) return [];
            const spell = sheet.data.spells[spellIndex];
            const operations = fn(spell) as Dnd5eSpellOperation[];
            if (operations.length === 0) return [];
            return [{type: "dnd-5e-character", operations: [{
                type: "update-spells", operations: ListOperation.apply(spellIndex, operations)
              }]}];
          } else if (reference?.type === "race") {
            const featureIndex = sheet.data.race.features.findIndex(feature => feature.featureID === reference.featureID);
            if (featureIndex === -1) return [];
            const feature = sheet.data.race.features[featureIndex];
            const operations = fn(feature) as Dnd5eFeatureOperation[];
            if (operations.length === 0) return [];
            return [{type: "dnd-5e-character", operations: [{
                type: "update-race", operations: ValueFn.apply([{
                  type: "update-features", operations: ListOperation.apply(featureIndex, operations)
                }])
              }]}];
          } else if (reference?.type === "background") {
            const featureIndex = sheet.data.background.features.findIndex(feature => feature.featureID === reference.featureID);
            if (featureIndex === -1) return [];
            const feature = sheet.data.background.features[featureIndex];
            const operations = fn(feature) as Dnd5eFeatureOperation[];
            if (operations.length === 0) return [];
            return [{type: "dnd-5e-character", operations: [{
                type: "update-background", operations: ValueFn.apply([{
                  type: "update-features", operations: ListOperation.apply(featureIndex, operations)
                }])
              }]}];
          } else if (reference?.type === "class") {
            const classIndex = sheet.data.classes.findIndex(c => c.id === reference.classID);
            if (classIndex === -1) return [];
            const featureIndex = sheet.data.classes[classIndex].features.findIndex(feature => feature.feature.featureID === reference.featureID);
            if (featureIndex === -1) return [];
            const feature = sheet.data.classes[classIndex].features[featureIndex].feature;
            const operations = fn(feature) as Dnd5eFeatureOperation[];
            if (operations.length === 0) return [];
            return [{type: "dnd-5e-character", operations: [{
                type: "update-classes", operations: ListOperation.apply(classIndex, [{
                  type: "update-features", operations: ListOperation.apply(featureIndex, [{
                    type: "update-feature", operations
                  }])
                }])
              }]}];
          } else if (reference?.type === "feature") {
            const featureIndex = sheet.data.features.findIndex(feature => feature.featureID === reference.featureID);
            if (featureIndex === -1) return [];
            const feature = sheet.data.features[featureIndex];
            const operations = fn(feature) as Dnd5eFeatureOperation[];
            if (operations.length === 0) return [];
            return [{type: "dnd-5e-character", operations: [{
                type: "update-features", operations: ListOperation.apply(featureIndex, operations)
              }]}];
          } else {
            return [];
          }
        } else if (sheet?.type === "dnd-5e-stat-block") {
          if (reference?.type === "feature") {
            const featureIndex = sheet.data.features.findIndex(feature => feature.featureID === reference.featureID);
            if (featureIndex === -1) return [];
            const feature = sheet.data.features[featureIndex];
            const operations = fn(feature) as Dnd5eFeatureOperation[];
            if (operations.length === 0) return [];
            return [{type: "dnd-5e-stat-block", operations: [{
                type: "update-features", operations: ListOperation.apply(featureIndex, operations)
              }]}];
          } else if (reference?.type === "spell") {
            const spellIndex = sheet.data.spells.findIndex(spell => spell.spellID === reference.spellID);
            if (spellIndex === -1) return [];
            const spell = sheet.data.spells[spellIndex];
            const operations = fn(spell) as Dnd5eSpellOperation[];
            if (operations.length === 0) return [];
            return [{type: "dnd-5e-stat-block", operations: [{
                type: "update-spells", operations: ListOperation.apply(spellIndex, operations)
              }]}];
          } else {
            return [];
          }
        } else {
          return [];
        }
      }).then(valueFn)
    });
  }, [sheet, reference])
}

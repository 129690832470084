import {z} from "zod";
import {ConstantOperation, constantType, ObjectType, PropertyRef, StringOperation, stringType, Type} from "../../../../../../types/index.ts";
import {Dnd5eProcessID} from "./dnd-5e-process-i-d.ts";
import {MutableRef} from "#common/ref";

export const Dnd5eProcessConsumeItem  = z.object({
  processID: Dnd5eProcessID,
  item: z.string()
});
export type Dnd5eProcessConsumeItem = z.infer<typeof Dnd5eProcessConsumeItem>;

export const Dnd5eProcessConsumeItemOperation  = z.discriminatedUnion("type", [
  z.object({type: z.literal("update-process-i-d"), operations: z.array(ConstantOperation)}),
  z.object({type: z.literal("update-item"), operations: z.array(StringOperation)})
]);
export type Dnd5eProcessConsumeItemOperation = z.infer<typeof Dnd5eProcessConsumeItemOperation>;

export const dnd5eProcessConsumeItemType: Type<Dnd5eProcessConsumeItem, Dnd5eProcessConsumeItemOperation> = new ObjectType({
  processID: constantType,
  item: stringType
});

export function Dnd5eProcessConsumeItemSignals(valueRef: MutableRef<Dnd5eProcessConsumeItem, Dnd5eProcessConsumeItemOperation[]>) {
  return {
    itemRef: PropertyRef<Dnd5eProcessConsumeItem, Dnd5eProcessConsumeItemOperation, string, StringOperation>(
      value => value.item,
      operations => [{type: "update-item", operations}]
    )(valueRef)
  }
}
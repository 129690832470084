import {ImageNode} from "common/legends/index.ts";
import React from "react";
import {Transform} from "common/types/index.ts";
import {ImageView} from "./image-view.tsx";
import {ElementRenderPass} from "./element-render-pass.tsx";
import {ModelProvider, usePVM} from "../../context/pvm-context.ts";
import {OpacityProvider, useOpacity} from "../../context/opacity-context.ts";

export function ImageElementRenderPass({value}: {
  value: ImageNode;
}) {
  const {projection, view, model} = usePVM();
  const valueModel = Transform.divide(value.transform, model);
  const valueOpacity = value.opacity * useOpacity();

  return (<binder>
    <OpacityProvider value={valueOpacity}>
      <ModelProvider value={valueModel}>
        <ImageView projection={projection} view={view} model={valueModel} file={value.file} normal={value.normal} size={value.size} origin={value.origin} pivot={value.pivot} opacity={valueOpacity} repeatX={value.repeatX} repeatY={value.repeatY} />
        {[...value.children].reverse().map((element) => <ElementRenderPass key={element.data.id} value={element} />)}
      </ModelProvider>
    </OpacityProvider>
  </binder>);
}
import {Transform} from "common/types/index.ts";
import React from "react";
import {ModelProvider, useModel} from "../../../context/pvm-context.ts";
import {TokenNode} from "common/legends/node/index.ts";
import {ElementLightPass} from "../element-light-pass.tsx";
import {useComputedValue} from "#lib/signal/index.ts";
import {useAsset} from "../../../../../common/character/use-asset.ts";
import {OpacityProvider, useOpacity} from "../../../context/opacity-context.ts";
import {SheetReferenceProvider} from "../../../../../common/sheet/sheet-reference-context.ts";
import {useTokenSheetReference} from "../../../context/use-token-sheet.ts";
import {CurrentTokenNodeProvider} from "../../../context/token-node-context.ts";

export function TokenElementLightPass({value}: {
  value: TokenNode;
}) {
  const model = useModel();
  const assetRef = useAsset(value.tokenReference.assetID);
  const token = useComputedValue(assetRef, asset => asset?.tokens.find(token => token.tokenID === value.tokenReference.tokenID));
  const sheetReference = useTokenSheetReference(value);
  const valueOpacity = value.opacity * useOpacity();
  const valueModel = Transform.divide(value.transform, model);
  return <CurrentTokenNodeProvider value={value}>
    <ModelProvider value={valueModel}>
      <OpacityProvider value={valueOpacity}>
        <SheetReferenceProvider value={sheetReference}>
          <binder>
            {token && [...token.children].reverse().map(element => <ElementLightPass key={element.data.id} value={element} />)}
          </binder>
          <binder>
            {[...value.children].reverse().map((element) => <ElementLightPass key={element.data.id} value={element} />)}
          </binder>
        </SheetReferenceProvider>
      </OpacityProvider>
    </ModelProvider>
  </CurrentTokenNodeProvider>;
}

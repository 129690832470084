import {z} from "zod";
import {
  ConstantOperation,
  constantType,
  ListOperation,
  ListPropertyRef,
  ListType,
  ObjectType,
  Optional,
  Type,
  ValueOperation,
  ValuePropertyRef,
  ValueType,
  ZodListOperation
} from "../../../../../../../types/index.ts";
import {Dnd5eActionCondition} from "../../condition/dnd-5e-action-condition.ts";
import {Dnd5eModifierID} from "../../../dnd-5e-modifier/dnd-5e-modifier-i-d.ts";
import {MutableRef} from "#common/ref/index.ts";
import {
  Dnd5eActionTemplateSegmentRollModifier,
  Dnd5eActionTemplateSegmentRollModifierOperation,
  dnd5eActionTemplateSegmentRollModifierType
} from "./dnd-5e-action-template-segment-roll-modifier.ts";

export const Dnd5eActionTemplateSegmentRollConditionModifier: z.ZodType<{
  modifierID: string,
  condition?: Optional<Dnd5eActionCondition>,
  modifiers: Dnd5eActionTemplateSegmentRollModifier[]
}> = z.lazy(() => z.object({
  modifierID: Dnd5eModifierID,
  condition: z.optional(Dnd5eActionCondition),
  modifiers: z.array(Dnd5eActionTemplateSegmentRollModifier)
}));
export type Dnd5eActionTemplateSegmentRollConditionModifier = z.infer<typeof Dnd5eActionTemplateSegmentRollConditionModifier>;

export const Dnd5eActionTemplateSegmentRollConditionModifierOperation: z.ZodType<
  | {type: "update-modifier-i-d", operations: ConstantOperation[]}
  | {type: "update-condition", operations: ValueOperation<Optional<Dnd5eActionCondition>, ConstantOperation>[]}
  | {type: "update-modifiers", operations: ListOperation<Dnd5eActionTemplateSegmentRollModifier, Dnd5eActionTemplateSegmentRollModifierOperation>[]}
> = z.lazy(() => z.discriminatedUnion("type", [
  z.object({type: z.literal("update-modifier-i-d"), operations: z.array(ConstantOperation)}),
  z.object({type: z.literal("update-condition"), operations: z.array(ValueOperation(z.optional(Dnd5eActionCondition), ConstantOperation))}),
  z.object({type: z.literal("update-modifiers"), operations: z.array(ZodListOperation(Dnd5eActionTemplateSegmentRollModifier, Dnd5eActionTemplateSegmentRollModifierOperation))})
]));
export type Dnd5eActionTemplateSegmentRollConditionModifierOperation = z.infer<typeof Dnd5eActionTemplateSegmentRollConditionModifierOperation>;

export const dnd5eActionTemplateSegmentRollConditionModifierType: Type<Dnd5eActionTemplateSegmentRollConditionModifier, Dnd5eActionTemplateSegmentRollConditionModifierOperation> = new ObjectType(() => ({
  modifierID: constantType,
  condition: new ValueType(constantType),
  modifiers: new ListType(dnd5eActionTemplateSegmentRollModifierType)
}));

export function Dnd5eActionTemplateSegmentRollConditionModifierSignals(valueRef: MutableRef<Dnd5eActionTemplateSegmentRollConditionModifier, Dnd5eActionTemplateSegmentRollConditionModifierOperation[]>) {
  return {
    conditionRef: ValuePropertyRef<Dnd5eActionTemplateSegmentRollConditionModifier, Dnd5eActionTemplateSegmentRollConditionModifierOperation, Optional<Dnd5eActionCondition>, ConstantOperation>(
      value => value.condition,
      operations => [{type: "update-condition", operations}]
    )(valueRef),
    modifiersRef: ListPropertyRef<Dnd5eActionTemplateSegmentRollConditionModifier, Dnd5eActionTemplateSegmentRollConditionModifierOperation, Dnd5eActionTemplateSegmentRollModifier, Dnd5eActionTemplateSegmentRollModifierOperation>(
      value => value.modifiers,
      operations => [{type: "update-modifiers", operations}]
    )(valueRef)
  };
}
import {z} from "zod";
import {
  ConstantOperation,
  constantType,
  ListOperation,
  ListPropertyRef,
  ListType,
  MapOperation,
  MapPropertyRef,
  MapType,
  NumberOperation,
  numberType,
  ObjectType,
  PropertyRef,
  RichTextFn,
  SetOperation,
  SetPropertySignal,
  SetType,
  StringOperation,
  stringType,
  Type,
  ValueOperation,
  ValueType
} from "#common/types/index.ts";
import {
  Dnd5eAttribute,
  dnd5eCharacterHitPointsType,
  Dnd5eCondition,
  Dnd5eFeature,
  Dnd5eFeatureOperation,
  dnd5eFeatureType,
  Dnd5eHealth,
  Dnd5eHealthOperation,
  Dnd5eInventoryItem,
  Dnd5eInventoryItemOperation,
  Dnd5eLanguage,
  Dnd5eMovementType,
  Dnd5eSkill,
  Dnd5eSpell,
  Dnd5eSpellLevel,
  Dnd5eSpellOperation,
  dnd5eSpellType,
  DND_5E_SPELL_LEVEL
} from "#common/legends/index.ts";
import {Dnd5eModifier} from "#common/legends/asset/sheet/dnd-5e/dnd-5e-modifier/dnd-5e-modifier.ts";
import {
  Dnd5eCharacterClassHitDice,
  Dnd5eCharacterClassHitDiceOperation,
  dnd5eCharacterClassHitDiceType
} from "#common/legends/asset/sheet/dnd-5e/character/dnd-5e-character-class-hit-dice.ts";
import {generateDnd5eModifierID} from "#common/legends/asset/sheet/dnd-5e/dnd-5e-modifier/dnd-5e-modifier-i-d.ts";
import {MathExpressionFn} from "#common/math/index.ts";
import {Dnd5eFeatureID, generateDnd5eFeatureID} from "#common/legends/asset/sheet/dnd-5e/dnd-5e-feature/dnd-5e-feature-id.ts";
import {Dnd5eFeatureOverride, Dnd5eFeatureOverrideOperation, dnd5eFeatureOverrideType} from "#common/legends/game/system/dnd-5e-feature-override.ts";
import {Dice} from "#common/dice/index.ts";
import {MutableRef} from "#common/ref";
import {actionTemplate_upgradeDnd5eStatBlock} from "../character/upgrade/dnd-5e-action-template-upgrade.ts";

export const Dnd5eStatBlock = z.object({
  name: z.string(),
  proficiencyBonus: z.number(),
  hitPoints: Dnd5eHealth,
  hitDice: Dnd5eCharacterClassHitDice,
  movementSpeeds: z.record(Dnd5eMovementType, z.number(), {}),
  attributes: z.record(Dnd5eAttribute, z.number(), {}),
  languages: z.array(Dnd5eLanguage),
  features: z.array(Dnd5eFeature),
  spellCasterLevel: z.number(),
  spellSlots: z.object({
    "cantrip": z.number(),
    "1": z.number(),
    "2": z.number(),
    "3": z.number(),
    "4": z.number(),
    "5": z.number(),
    "6": z.number(),
    "7": z.number(),
    "8": z.number(),
    "9": z.number()
  }),
  spells: z.array(Dnd5eSpell),
  globalFeatures: z.record(Dnd5eFeatureID, Dnd5eFeatureOverride),
  conditions: z.array(Dnd5eCondition)
});
export type Dnd5eStatBlock = z.infer<typeof Dnd5eStatBlock>;

export type Dnd5eStatBlockOperation =
  | {type: "update-name", operations: StringOperation[]}
  | {type: "update-proficiency-bonus", operations: NumberOperation[]}
  | {type: "update-hit-points", operations: Dnd5eHealthOperation[]}
  | {type: "update-hit-dice", operations: Dnd5eCharacterClassHitDiceOperation[]}
  | {type: "update-movement-speeds", operations: MapOperation<Dnd5eMovementType, number, NumberOperation>[]}
  | {type: "update-attributes", operations: MapOperation<Dnd5eAttribute, number, NumberOperation>[]}
  | {type: "update-languages", operations: ListOperation<Dnd5eLanguage, ConstantOperation>[]}
  | {type: "update-features", operations: ListOperation<Dnd5eFeature, Dnd5eFeatureOperation>[]}
  | {type: "update-inventory", operations: ListOperation<Dnd5eInventoryItem, Dnd5eInventoryItemOperation>[]}
  | {type: "update-spell-caster-level", operations: NumberOperation[]}
  | {type: "update-spells", operations: ListOperation<Dnd5eSpell, Dnd5eSpellOperation>[]}
  | {type: "update-spell-slots", operations: MapOperation<Dnd5eSpellLevel, number, ValueOperation<number, ConstantOperation>>[]}
  | {type: "update-global-features", operations: MapOperation<Dnd5eFeatureID, Dnd5eFeatureOverride, Dnd5eFeatureOverrideOperation>[]}
  | {type: "update-conditions", operations: SetOperation<Dnd5eCondition>[]}
;

export const dnd5eStatBlockType: Type<Dnd5eStatBlock, Dnd5eStatBlockOperation> = new ObjectType({
  name: stringType,
  proficiencyBonus: numberType,
  hitPoints: dnd5eCharacterHitPointsType,
  hitDice: dnd5eCharacterClassHitDiceType,
  movementSpeeds: new MapType<Dnd5eMovementType, number, NumberOperation>(numberType),
  attributes: new MapType<Dnd5eAttribute, number, NumberOperation>(numberType),
  features: new ListType(dnd5eFeatureType),
  languages: new ListType(constantType),
  spellCasterLevel: numberType,
  spells: new ListType(dnd5eSpellType),
  spellSlots: new MapType<Dnd5eSpellLevel, number, ValueOperation<number, ConstantOperation>>(new ValueType(constantType)),
  globalFeatures: new MapType<Dnd5eFeatureID, Dnd5eFeatureOverride, Dnd5eFeatureOverrideOperation>(dnd5eFeatureOverrideType),
  conditions: new SetType<Dnd5eCondition>()
}, (value) => {
  let v = {...value};
  if (v["globalEffectsOverride"] !== undefined) {
    v.globalEffects = v["globalEffectsOverride"];
    delete v["globalEffectsOverride"]
  }
  if (v["globalEffects"] === undefined) v.globalEffects = [];
  if (v["hitDice"] === undefined) v.hitDice = {current: 4, diceFace: 8};
  if (v["proficiencyBonus"] === undefined) v.proficiencyBonus = 2;
  if (v["resources"] === undefined) v.resources = [];
  if (v["spellCasterLevel"] === undefined) v.spellCasterLevel = 0;

  if (v["savingThrowProficiencies"] !== undefined) {
    Object.keys(v["savingThrowProficiencies"]).forEach(savingThrow => {
      v["modifiers"].push({
        type: "saving-throw",
        data: {
          modifierID: generateDnd5eModifierID(),
          expression: Dice.assertDiceExpression("0"),
          savingThrows: [savingThrow as Dnd5eAttribute],
          proficiency: v["savingThrowProficiencies"][savingThrow],
          hasAdvantage: false,
          hasDisadvantage: false
        }
      } satisfies Dnd5eModifier);
    });
    delete v["savingThrowProficiencies"];
  }

  if (v["skillProficiencies"] !== undefined) {
    Object.keys(v["skillProficiencies"]).forEach(skill => {
      v["modifiers"].push({
        type: "ability-check",
        data: {
          modifierID: generateDnd5eModifierID(),
          abilityChecks: [skill as Dnd5eSkill],
          expression: Dice.assertDiceExpression("0"),
          proficiency: v["skillProficiencies"][skill],
          hasAdvantage: false,
          hasDisadvantage: false
        }
      } satisfies Dnd5eModifier);
    });
    delete v["skillProficiencies"];
  }
  if (v["armorClass"] !== undefined) {
    v["modifiers"].push({
      type: "armor-class-formula",
      data: {
        modifierID: generateDnd5eModifierID(),
        expression: MathExpressionFn.assertMathExpression(`${v["armorClass"]}`)
      }
    });
    delete v["armorClass"];
  }
  if (v["spellSlots"] === undefined) {
    v["spellSlots"] = Object.fromEntries(DND_5E_SPELL_LEVEL.map(level => [level, 0]));
  }
  if (v["features"] === undefined) {
    v["features"] = [];
  }
  if (v["actions"] !== undefined && v["resources"] !== undefined && v["modifiers"] !== undefined) {
    if (v["actions"].length > 0 || v["resources"].length > 0 || v["modifiers"].length > 0) {
      v["features"].push({
        featureID: generateDnd5eFeatureID(),
        title: v["name"],
        source: "",
        actions: v["actions"],
        resources: v["resources"],
        modifiers: v["modifiers"],
        description: RichTextFn.EMPTY,
        effects: [],
        enabled: true
      } satisfies Dnd5eFeature);
    }
    delete v["actions"];
    delete v["resources"];
    delete v["modifiers"];
  }
  if (v["effects"] !== undefined) {
    if (v["effects"].length > 0) {
      v["features"].push({
        featureID: generateDnd5eFeatureID(),
        title: "Effects",
        source: "",
        actions: [],
        resources: [],
        modifiers: [],
        description: RichTextFn.EMPTY,
        effects: v["effects"],
        enabled: true
      } satisfies Dnd5eFeature);
    }
    delete v["effects"];
  }

  if (v["hitPoints"]["max"] !== undefined) {
    v["features"].push({
      featureID: generateDnd5eFeatureID(),
      title: "Hit Points",
      source: "",
      actions: [],
      resources: [],
      modifiers: [{
        type: "max-h-p",
        data: {
          modifierID: generateDnd5eModifierID(),
          expression: MathExpressionFn.assertMathExpression(`${v["hitPoints"]["max"]}`)
        }
      }],
      description: RichTextFn.EMPTY,
      effects: v["effects"],
      enabled: true
    } satisfies Dnd5eFeature);
    delete v["hitPoints"]["max"];
  }
  if (v["globalEffects"]) delete v["globalEffects"];
  if (v["globalFeatures"] === undefined) v["globalFeatures"] = {};

  v = actionTemplate_upgradeDnd5eStatBlock(v);

  return v;
});

export function Dnd5eStatBlockSignals(value: MutableRef<Dnd5eStatBlock, Dnd5eStatBlockOperation[]>) {
  return {
    name: PropertyRef<Dnd5eStatBlock, Dnd5eStatBlockOperation, string, StringOperation>(
      value => value.name,
      operations => [{type: "update-name", operations}]
    )(value),
    proficiencyBonus: PropertyRef<Dnd5eStatBlock, Dnd5eStatBlockOperation, number, NumberOperation>(
      value => value.proficiencyBonus,
      operations => [{type: "update-proficiency-bonus", operations}]
    )(value),
    attributes: MapPropertyRef<Dnd5eStatBlock, Dnd5eStatBlockOperation, Dnd5eAttribute, number, NumberOperation>(
      value => value.attributes,
      operations => [{type: "update-attributes", operations}]
    )(value),
    hitPoints: PropertyRef<Dnd5eStatBlock, Dnd5eStatBlockOperation, Dnd5eHealth, Dnd5eHealthOperation>(
      value => value.hitPoints,
      operations => [{type: "update-hit-points", operations}]
    )(value),
    hitDice: PropertyRef<Dnd5eStatBlock, Dnd5eStatBlockOperation, Dnd5eCharacterClassHitDice, Dnd5eCharacterClassHitDiceOperation>(
      value => value.hitDice,
      operations => [{type: "update-hit-dice", operations}]
    )(value),
    features: ListPropertyRef<Dnd5eStatBlock, Dnd5eStatBlockOperation, Dnd5eFeature, Dnd5eFeatureOperation>(
      value => value.features,
      operations => [{type: "update-features", operations}]
    )(value),
    globalFeatures: PropertyRef<Dnd5eStatBlock, Dnd5eStatBlockOperation, {[featureID in Dnd5eFeatureID]?: Dnd5eFeatureOverride}, MapOperation<Dnd5eFeatureID, Dnd5eFeatureOverride, Dnd5eFeatureOverrideOperation>>(
      sheet => sheet.globalFeatures,
      operations => [{type: "update-global-features", operations}]
    )(value),
    movementSpeeds: MapPropertyRef<Dnd5eStatBlock, Dnd5eStatBlockOperation, Dnd5eMovementType, number, NumberOperation>(
      value => value.movementSpeeds,
      operations => [{type: "update-movement-speeds", operations}]
    )(value),
    spellCasterLevel: PropertyRef<Dnd5eStatBlock, Dnd5eStatBlockOperation, number, NumberOperation>(
      value => value.spellCasterLevel,
      operations => [{type: "update-spell-caster-level", operations}]
    )(value),
    spells: ListPropertyRef<Dnd5eStatBlock, Dnd5eStatBlockOperation, Dnd5eSpell, Dnd5eSpellOperation>(
      value => value.spells,
      operations => [{type: "update-spells", operations}]
    )(value),
    spellSlots: PropertyRef<Dnd5eStatBlock, Dnd5eStatBlockOperation, {[level in Dnd5eSpellLevel]: number}, MapOperation<Dnd5eSpellLevel, number, ValueOperation<number, ConstantOperation>>>(
      value => value.spellSlots,
      operations => [{type: "update-spell-slots", operations}]
    )(value),
    conditions: SetPropertySignal<Dnd5eStatBlock, Dnd5eStatBlockOperation, Dnd5eCondition>(
      value => value.conditions,
      operations => [{type: "update-conditions", operations}]
    )(value)
  };
}

import {z} from "zod";
import {MultiType, Type} from "#common/types/index.ts";
import {generateNodeConditionID, NodeConditionID} from "./node-condition-i-d.ts";
import {NodeConditionEffect, NodeConditionEffectOperation, nodeConditionEffectType} from "./node-condition-effect.ts";
import {NodeConditionSelf, NodeConditionSelfOperation, nodeConditionSelfType} from "./node-condition-self.ts";

export const NodeCondition = z.discriminatedUnion("type", [
  z.object({type: z.literal("effect"), data: NodeConditionEffect}),
  z.object({type: z.literal("self"), data: NodeConditionSelf})
]);
export type NodeCondition = z.infer<typeof NodeCondition>;

export const NodeConditionOperation = z.discriminatedUnion("type", [
  z.object({type: z.literal("effect"), operations: z.array(NodeConditionEffectOperation)}),
  z.object({type: z.literal("self"), operations: z.array(NodeConditionSelfOperation)})
]);
export type NodeConditionOperation = z.infer<typeof NodeConditionOperation>;

export type NodeConditionTypes = {
  effect: Type<NodeConditionEffect, NodeConditionEffectOperation>;
  self: Type<NodeConditionSelf, NodeConditionSelfOperation>;
};
export const nodeConditionType: Type<NodeCondition, NodeConditionOperation> = new MultiType<NodeConditionTypes>({
  effect: nodeConditionEffectType,
  self: nodeConditionSelfType
});

export const NodeConditionFn = {
  getNodeConditionID: (condition: NodeCondition): NodeConditionID => condition.data.conditionID,
  copyNodeCondition: (condition: NodeCondition): NodeCondition => ({
    ...condition,
    data: {
      ...condition.data,
      conditionID: generateNodeConditionID()
    }
  })
}

import {SizeField} from "../size-field.tsx";
import {AssetSignals, TokenSignals} from "common/legends/index.ts";
import {ConstantOperation, ValueOperation} from "common/types/index.ts";
import {NameField} from "../name-field.tsx";
import {SheetSection} from "./sheet-section.tsx";
import {useMemo} from "react";
import {InputFile} from "#lib/components/input/input-file.tsx";
import {useAsset, useAssetToken} from "../../../common/character/use-asset.ts";
import {OriginField} from "../node/origin-field.tsx";
import {MountableField} from "../mountable-field.tsx";
import {AttachableField} from "../attachable-field.tsx";
import {useSuspendPresent} from "../../../common/use-optional-signal.ts";
import {PivotField} from "../node/pivot-field.tsx";
import {MutableRef} from "common/ref";
import {OwnersInput} from "../../asset-navigator/owners-input.tsx";
import {SelectionMaskField} from "../selection-mask-field.tsx";
import {VisibilityLayerField} from "../visibility-layer-field.tsx";
import {Field, FieldLabel, Panel, Section, SectionBody, SectionHeader} from "#lib/components/panel-header.tsx";
import {LightShapeGlobalFields} from "../node/image/light-shape-global-fields.tsx";
import {AssetTokenSelectionRef, SelectionRef} from "../../nav/editor/state/selection-ref.ts";
import {AddVisionButton} from "./add-vision-button.tsx";
import {InputList} from "#lib/components/list/input-list.tsx";
import {TokenVisionFn} from "common/legends/asset/token/token-vision.ts";
import {TokenVisionListItem} from "./token-vision-list-item.tsx";
import {ButtonBar, InputGroup, InputNumber, useToggle} from "#lib/components/index.ts";

export function AssetTokenPropertiesView({reference, pinned}: {
  reference: AssetTokenSelectionRef;
  pinned: MutableRef<SelectionRef, ValueOperation<SelectionRef, ConstantOperation>[]>;
}) {
  const assetRef = useSuspendPresent(useAsset(reference.resourceId));
  const {ownerIDs, visibilityLayer, selectionMask} = useMemo(() => AssetSignals(assetRef), [assetRef]);
  const tokenRef = useSuspendPresent(useAssetToken(assetRef, reference.tokenId));
  const {sheets} = useMemo(() => AssetSignals(assetRef), [assetRef]);

  const {nameRef, icon, size, origin, pivotRef, mountable, attachable, sheetId, sheetPolicy, visionRef, lightRef, interfaceScaleRef} = useMemo(() => TokenSignals(tokenRef), [tokenRef]);

  const [toggled, toggle] = useToggle(true);
  return (<div className="tab-content flex flex-col py-2 gap-4">
    <Panel title="Asset Properties">
      <Section>
        <VisibilityLayerField value={visibilityLayer} />
        <SelectionMaskField value={selectionMask} />
      </Section>
      <Section title="Owners" open={false}>
        <OwnersInput value={ownerIDs} />
      </Section>
    </Panel>
    <Panel title="Token Properties">
      <Section>
        <NameField value={nameRef} reference={reference} pinned={pinned}/>
        <Field>
          <FieldLabel>Icon</FieldLabel>
          <InputFile value={icon}/>
        </Field>
        <OriginField valueRef={origin}/>
        <PivotField valueRef={pivotRef}/>
        <SizeField value={size}/>
        <Field>
          <FieldLabel>Interface Scale</FieldLabel>
          <InputGroup className="mx-2 rounded-md overflow-hidden">
            <InputNumber value={interfaceScaleRef} />
          </InputGroup>
        </Field>
        <MountableField value={mountable}/>
        <AttachableField value={attachable}/>
      </Section>

      <SheetSection sheets={sheets} sheetPolicy={sheetPolicy} sheetID={sheetId}/>

      <div className="flex flex-col gap-2 py-2">
        <ButtonBar className="flex flex-row bg-transparent">
          <SectionHeader onClick={toggle} className="flex-1">Vision</SectionHeader>
          <AddVisionButton valueRef={visionRef}/>
        </ButtonBar>
        {toggled && <SectionBody>
          <div className="mx-2 rounded-md overflow-hidden">
            <InputList accept="legends/token-vision" items={visionRef} itemKey={vision => vision.tokenVisionID} copy={TokenVisionFn.copy} ListItem={TokenVisionListItem}/>
          </div>
        </SectionBody>}
      </div>
    </Panel>
    <Panel title="Asset Editor Properties">
      <Section title="Light">
        <LightShapeGlobalFields valueRef={lightRef} />
      </Section>
    </Panel>
  </div>);
}

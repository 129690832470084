import {z} from "zod";
import {ConstantOperation, constantType, ListPropertyRef, ListType, ObjectType, Type, ZodListOperation} from "../../../../../../types/index.ts";
import {Dnd5eModifierID} from "../../dnd-5e-modifier/dnd-5e-modifier-i-d.ts";
import {Dnd5eProcess, Dnd5eProcessOperation, dnd5eProcessType} from "../process/dnd-5e-process.ts";
import {MutableRef} from "#common/ref";

export const Dnd5eActionTriggerActivationModifier = z.object({
  modifierID: Dnd5eModifierID,
  processes: z.array(Dnd5eProcess)
});
export type Dnd5eActionTriggerActivationModifier = z.infer<typeof Dnd5eActionTriggerActivationModifier>;

export const Dnd5eActionTriggerActivationModifierOperation = z.discriminatedUnion("type", [
  z.object({type: z.literal("update-modifier-i-d"), operations: z.array(ConstantOperation)}),
  z.object({type: z.literal("update-processes"), operations: z.array(ZodListOperation(Dnd5eProcess, Dnd5eProcessOperation))})
]);
export type Dnd5eActionTriggerActivationModifierOperation = z.infer<typeof Dnd5eActionTriggerActivationModifierOperation>;

export const dnd5eActionTriggerActivationType: Type<Dnd5eActionTriggerActivationModifier, Dnd5eActionTriggerActivationModifierOperation> = new ObjectType({
  modifierID: constantType,
  processes: new ListType(dnd5eProcessType)
});

export function Dnd5eActionTriggerActivationModifierSignals(valueRef: MutableRef<Dnd5eActionTriggerActivationModifier, Dnd5eActionTriggerActivationModifierOperation[]>) {
  return {
    processesRef: ListPropertyRef<Dnd5eActionTriggerActivationModifier, Dnd5eActionTriggerActivationModifierOperation, Dnd5eProcess, Dnd5eProcessOperation>(
      value => value.processes,
      operations => [{type: "update-processes", operations}]
    )(valueRef)
  };
}
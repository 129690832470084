import {Asset, AssetOperation, Sheet, SheetID, SheetOperation, Token, TokenID, TokenOperation, UserID} from "#common/legends/index.ts";
import {VisibilityLayer, VisibilityLayerOperation} from "#common/legends/visibility/index.ts";
import {ConstantOperation, ListPropertyRef, MapPropertyRef, PropertyRef, SetPropertySignal, ValueOperation} from "#common/types/index.ts";
import {MutableRef} from "#common/ref";
import {Mask, MaskOperation} from "../../types/generic/mask/mask.ts";

export function AssetSignals(value: MutableRef<Asset, AssetOperation[]>) {
  return {
    initialTokenID: PropertyRef<Asset, AssetOperation, TokenID, ValueOperation<TokenID, ConstantOperation>>(
      value => value.initialTokenId,
      operations => [{type: "update-initial-token-id", operations}]
    )(value),
    ownerIDs: SetPropertySignal<Asset, AssetOperation, UserID>(
      value => value.ownerIDs,
      operations => [{type: "update-owner-i-ds", operations}]
    )(value),
    sheets: MapPropertyRef<Asset, AssetOperation, SheetID, Sheet, SheetOperation>(
      value => value.sheets,
      operations => [{type: "update-sheets", operations}]
    )(value),
    tokens: ListPropertyRef<Asset, AssetOperation, Token, TokenOperation>(
      value => value.tokens,
      operations => [{type: "update-tokens", operations}]
    )(value),
    visibilityLayer: PropertyRef<Asset, AssetOperation, VisibilityLayer, VisibilityLayerOperation>(
      value => value.visibilityLayer,
      operations => [{type: "update-visibility-layer", operations}]
    )(value),
    selectionMask: PropertyRef<Asset, AssetOperation, Mask, MaskOperation>(
      value => value.selectionMask,
      operations => [{type: "update-selection-mask", operations}]
    )(value)
  };
}


import {z} from "zod";
import {Dnd5eModifierID} from "../../../dnd-5e-modifier/dnd-5e-modifier-i-d.ts";
import {BooleanOperation, booleanType, ConstantOperation, constantType, ObjectType, Type} from "../../../../../../../types/index.ts";

export const Dnd5eActionTemplateSegmentAttackRollAdvantageOverrideModifier: z.ZodType<{
  modifierID: string,
  value: boolean
}> = z.object({
  modifierID: Dnd5eModifierID,
  value: z.boolean()
});
export type Dnd5eActionTemplateSegmentAttackRollAdvantageOverrideModifier = z.infer<typeof Dnd5eActionTemplateSegmentAttackRollAdvantageOverrideModifier>;
export const Dnd5eActionTemplateSegmentAttackRollAdvantageOverrideModifierOperation = z.discriminatedUnion("type", [
  z.object({type: z.literal("update-modifier-i-d"), operations: z.array(ConstantOperation)}),
  z.object({type: z.literal("update-value"), operations: z.array(BooleanOperation)})
]);
export type Dnd5eActionTemplateSegmentAttackRollAdvantageOverrideModifierOperation = z.infer<typeof Dnd5eActionTemplateSegmentAttackRollAdvantageOverrideModifierOperation>;
export const dnd5eActionTemplateSegmentAttackRollAdvantageOverrideModifierType: Type<Dnd5eActionTemplateSegmentAttackRollAdvantageOverrideModifier, Dnd5eActionTemplateSegmentAttackRollAdvantageOverrideModifierOperation> = new ObjectType({
  modifierID: constantType,
  value: booleanType
});

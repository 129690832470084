import {z} from "zod";
import {Dnd5eEffectID} from "../../dnd-5e-effect/index.ts";
import {Optional} from "../../../../../../types/index.ts";

const Dnd5eActionConditionEffect = z.object({
  effectID: z.optional(Dnd5eEffectID)
});

export const Dnd5eActionCondition: z.ZodType<
  {type: "effect", data: {effectID?: Optional<string>}}
> = z.discriminatedUnion("type", [
  z.object({type: z.literal("effect"), data: Dnd5eActionConditionEffect})
]);
export type Dnd5eActionCondition = z.infer<typeof Dnd5eActionCondition>;

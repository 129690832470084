import {MutableRef} from "common/ref";
import {LightShapeFreeform, LightShapeFreeformOperation, LightShapeFreeformRef} from "common/legends/node/light/light-shape-freeform.ts";
import {Button, InputGroup, InputNumber} from "#lib/components/index.ts";
import {useCallback, useMemo} from "react";
import {ColorField} from "#lib/components/input/color-field.tsx";
import {FaWrench} from "react-icons/fa6";
import {ValueFn} from "common/types/generic/index.ts";
import {NodeId} from "common/legends/node/index.ts";
import {useEditorTool} from "../../../nav/common/tool/tool-selector/use-editor-tool.ts";
import {Field, FieldLabel, SectionBody} from "#lib/components/panel-header.tsx";

export function LightShapeFreeformFields({nodeID, valueRef}: {
  nodeID: NodeId,
  valueRef: MutableRef<LightShapeFreeform, LightShapeFreeformOperation[]>
}) {
  const {colorRef, intensityRef, falloffRef, falloffStrengthRef} = useMemo(() => LightShapeFreeformRef(valueRef), [valueRef]);

  const toolRef = useEditorTool();
  const setAreaTool = useCallback(() => {
    toolRef.apply(tool => ValueFn.set(tool, {
      type: "area",
      data: {nodeID, mode: {type: "create", data: undefined}}
    }));
  }, [toolRef, valueRef, nodeID]);

  return <SectionBody className="pt-2">
    <ColorField label="Color" value={colorRef}/>

    <div className="flex flex-row gap-0.5">
      <Field className="flex-1">
        <FieldLabel>Intensity</FieldLabel>
        <InputGroup className="ml-2 rounded-l-md overflow-hidden">
          <InputNumber value={intensityRef}/>
        </InputGroup>
      </Field>
      <Field className="flex-1">
        <FieldLabel>Falloff Strength</FieldLabel>
        <InputGroup className="mr-2 rounded-r-md overflow-hidden">
          <InputNumber value={falloffStrengthRef}/>
        </InputGroup>
      </Field>
    </div>

    <Field>
      <FieldLabel>Falloff Radius</FieldLabel>
      <InputGroup className="mx-2 rounded-md overflow-hidden">
        <InputNumber value={falloffRef}/>
      </InputGroup>
    </Field>

    <Button onClick={setAreaTool} className="mx-2 rounded-md overflow-hidden">
      <FaWrench/> Edit Area
    </Button>
  </SectionBody>
}
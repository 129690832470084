import {z} from "zod";
import {DiceExpression} from "../../../../../../../dice/index.ts";
import {ConstantOperation, constantType, ObjectType, Optional, ValueOperation, ValuePropertyRef, ValueType} from "../../../../../../../types/generic/index.ts";
import {Type} from "../../../../../../../types/type/index.ts";
import {MutableRef} from "#common/ref";
import {Dnd5eDamageType} from "../../../dnd-5e-damage-type.ts";

export const Dnd5eActionTemplateSegmentRollExpression: z.ZodType<{
  rollID: string,
  rollType?: Dnd5eDamageType,
  expression: string
}> = z.object({
  rollID: z.string(),
  rollType: z.optional(Dnd5eDamageType),
  expression: DiceExpression
});
export type Dnd5eActionTemplateSegmentRollExpression = z.infer<typeof Dnd5eActionTemplateSegmentRollExpression>;
export const Dnd5eActionTemplateSegmentRollExpressionOperation = z.discriminatedUnion("type", [
  z.object({type: z.literal("update-roll-i-d"), operations: z.array(ConstantOperation)}),
  z.object({type: z.literal("update-roll-type"), operations: z.array(ValueOperation(z.optional(Dnd5eDamageType), ConstantOperation))}),
  z.object({type: z.literal("update-expression"), operations: z.array(ValueOperation(DiceExpression, ConstantOperation))})
]);
export type Dnd5eActionTemplateSegmentRollExpressionOperation = z.infer<typeof Dnd5eActionTemplateSegmentRollExpressionOperation>;
export const dnd5eActionTemplateSegmentRollExpressionType: Type<Dnd5eActionTemplateSegmentRollExpression, Dnd5eActionTemplateSegmentRollExpressionOperation> = new ObjectType({
  rollID: constantType,
  rollType: new ValueType(constantType),
  expression: new ValueType(constantType)
});

export function Dnd5eActionTemplateSegmentRollExpressionSignals(valueRef: MutableRef<Dnd5eActionTemplateSegmentRollExpression, Dnd5eActionTemplateSegmentRollExpressionOperation[]>) {
  return {
    rollTypeRef: ValuePropertyRef<Dnd5eActionTemplateSegmentRollExpression, Dnd5eActionTemplateSegmentRollExpressionOperation, Optional<Dnd5eDamageType>, ConstantOperation>(
      value => value.rollType,
      operations => [{type: "update-roll-type", operations}]
    )(valueRef),
    expressionRef: ValuePropertyRef<Dnd5eActionTemplateSegmentRollExpression, Dnd5eActionTemplateSegmentRollExpressionOperation, DiceExpression, ConstantOperation>(
      value => DiceExpression.parse(value.expression),
      operations => [{type: "update-expression", operations}]
    )(valueRef),
  };
}
import {ButtonBar, ExpandableLabel, IconButton, InputGroup, InputGroupLabel, useToggle} from "#lib/components/index.ts";
import {SectionHeader} from "#lib/components/panel-header.tsx";
import {InputListItemProps} from "#lib/components/list/input-list.tsx";
import {Fieldset} from "#lib/components/fieldset/fieldset.tsx";
import {ConnectDragPreview, ConnectDragSource} from "react-dnd";
import {DragIndicator} from "#lib/components/tree-view/drag-indicator.tsx";
import React, {useMemo} from "react";
import {FaCopy, FaTrash} from "react-icons/fa";
import {InputMathExpression} from "#lib/components/input/input-math-expression.tsx";
import {
  Dnd5eProcessAdjustSpellSlot,
  Dnd5eProcessAdjustSpellSlotOperation,
  Dnd5eProcessAdjustSpellSlotSignals
} from "common/legends/asset/sheet/dnd-5e/dnd-5e-action-definition/process/dnd-5e-process-adjust-spell-slot.ts";

export function Dnd5eProcessAdjustSpellSlotListItem({item, remove, duplicate, dragRefPreview, dragHandlerRef}: InputListItemProps<Dnd5eProcessAdjustSpellSlot, Dnd5eProcessAdjustSpellSlotOperation> & {
  dragHandlerRef: ConnectDragSource | ConnectDragPreview,
  dragRefPreview: ConnectDragSource | ConnectDragPreview
}) {
  const [expanded, toggleExpanded] = useToggle(false);

  const {levelRef, quantityRef} = useMemo(() => Dnd5eProcessAdjustSpellSlotSignals(item), [item]);
  return <div className="flex flex-col">
    <ButtonBar ref={dragRefPreview}>
      <IconButton ref={dragHandlerRef} title="Move"><DragIndicator /></IconButton>
      <ExpandableLabel expanded={expanded} toggleExpand={toggleExpanded}>
        <SectionHeader className="flex-1 bg-zinc-800/50" onClick={toggleExpanded}>Adjust Spell Slot</SectionHeader>
      </ExpandableLabel>
      {duplicate && <IconButton title="Duplicate" onClick={duplicate}><FaCopy /></IconButton>}
      <IconButton variant="destructive" title="Remove Effect" onClick={remove}><FaTrash /></IconButton>
    </ButtonBar>

    {expanded && <Fieldset>
      <InputGroup>
        <InputGroupLabel>Level</InputGroupLabel>
        <InputMathExpression value={levelRef} />
      </InputGroup>
      <InputGroup>
        <InputGroupLabel>Quantity</InputGroupLabel>
        <InputMathExpression value={quantityRef} />
      </InputGroup>
    </Fieldset>}
  </div>
}

import {Node, NodeId} from "common/legends/node/index.ts";
import {MutableRef} from "common/ref";
import {ConstantOperation, Optional, ValueOperation, walkTree} from "common/types/generic/index.ts";
import {InputGroup, InputGroupLabel} from "#lib/components/index.ts";
import {InputOptionalSelect} from "#lib/components/input/input-optional-select.tsx";
import {useComputedValue} from "#lib/signal/index.ts";
import {useDatabase} from "../../../../../../routes/game/model/store-context.tsx";
import {SceneID} from "common/legends/scene/index.ts";
import {SceneTreeItem} from "common/legends/game/index.ts";
import {QLabResourceID} from "common/qlab/resource/index.ts";
import {NodePath} from "../../../../../viewport/common/context/get-nodes-at-point.ts";

function getSceneName(items: SceneTreeItem[], resourceID: QLabResourceID): string | undefined {
  for (const item of items) {
    if (item.type === "folder") {
      const name = getSceneName(item.data.children, resourceID);
      if (name) return name;
    } else if (item.type === "scene") {
      if (item.data.id === resourceID) return item.data.name;
    }
  }
  return undefined;
}

export function NodeIDField({valueRef, predicate}: {
  valueRef: MutableRef<Optional<NodeId>, ValueOperation<Optional<NodeId>, ConstantOperation>[]>,
  predicate: (node: Node, path: NodePath) => boolean
}) {
  const databaseRef = useDatabase();
  const [nodeValues, nodeLabels] = useComputedValue(databaseRef, (database): [NodeId[], {[nodeID: NodeId]: string}] => {
    if (database.store?.type !== "game") return [[], {}];

    const ids: NodeId[] = [];
    const labels: {[nodeID: NodeId]: string} = {};
    for (const [resourceID, resource] of Object.entries(database.resources)) {
      const pathNames = [getSceneName(database.store.data.scenes, resourceID as SceneID)];

      if (resource?.type === "scene") {
        walkTree(resource.data.children, {
          preVisit(value) {
            pathNames.push(value.data.name);
          },
          visit: (value, path) => {
            if (predicate(value, {type: "scene", sceneID: resourceID as SceneID, path})) {
              ids.push(value.data.id);
              labels[value.data.id] = pathNames.join(" > ");
            }
          },
          postVisit() {
            pathNames.pop();
          }
        });
      }
    }
    return [ids, labels];
  }, [databaseRef, predicate]);

  return <InputGroup>
    <InputGroupLabel>Element</InputGroupLabel>
    <InputOptionalSelect<NodeId> value={valueRef} values={nodeValues} labels={nodeLabels} />
  </InputGroup>
}
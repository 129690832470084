import {z} from "zod";
import {Dnd5eEffectID} from "#common/legends/asset/sheet/dnd-5e/dnd-5e-effect/index.ts";
import {ConstantOperation, constantType, ObjectType, Optional, Type, ValueOperation, ValueType} from "#common/types/index.ts";
import {MutableRef} from "#common/ref";
import {generateNodeConditionID, NodeConditionID} from "./node-condition-i-d.ts";

export const NodeConditionEffect = z.object({
  conditionID: NodeConditionID,
  effectID: z.optional(Dnd5eEffectID)
});
export type NodeConditionEffect = z.infer<typeof NodeConditionEffect>;
export const NodeConditionEffectOperation = z.discriminatedUnion("type", [
  z.object({type: z.literal("update-condition-i-d"), operations: z.array(ConstantOperation)}),
  z.object({type: z.literal("update-effect-i-d"), operations: z.array(ValueOperation(z.optional(Dnd5eEffectID), ConstantOperation))})
]);
export type NodeConditionEffectOperation = z.infer<typeof NodeConditionEffectOperation>;
export const nodeConditionEffectType: Type<NodeConditionEffect, NodeConditionEffectOperation> = new ObjectType({
  conditionID: constantType,
  effectID: new ValueType(constantType)
}, v => {
  if (v.conditionID === undefined) v.conditionID = generateNodeConditionID();
  return v;
});

export const NodeConditionEffectFn = {
  expand(valueRef: MutableRef<NodeConditionEffect, NodeConditionEffectOperation[]>) {
    return {
      effectID: valueRef.map<Optional<Dnd5eEffectID>, ValueOperation<Optional<Dnd5eEffectID>, ConstantOperation>[]>(value => value.effectID, (_, operations) => [{type: "update-effect-i-d", operations}])
    }
  }
};


import {Modal, ModalBody, ModalTitle} from "#lib/components/modal/index.ts";
import {Button, ButtonBar, InputGroup, InputGroupLabel, Spacer} from "#lib/components/index.ts";
import React, {useMemo} from "react";
import {FaTag, FaTrash} from "react-icons/fa";
import {FaDiceD20} from "react-icons/fa6";
import {InputString} from "#lib/components/input/input-string.tsx";
import {useSelectedNodeIDRef} from "../../dnd-5e-character/use-selected-sheet.ts";
import {InputGroupIcon} from "#lib/components/input/input-group-icon.tsx";
import {ExpandOptions} from "#lib/components/expand-options.tsx";
import {ExportButton} from "#lib/components/button/export-button.tsx";
import {exportFile} from "../../../../../common/export-file.ts";
import {useSheetSignal} from "../../../../../common/sheet/use-sheet-signal.ts";
import {useSheetReference} from "../../../../../common/sheet/sheet-reference-context.ts";
import {MutableRef} from "common/ref";
import {
  Dnd5eActionTemplate,
  Dnd5eActionTemplateOperation,
  Dnd5eActionTemplateSignals
} from "common/legends/asset/sheet/dnd-5e/dnd-5e-action-definition/template/dnd-5e-action-template.ts";
import {useRollActionTemplate} from "../../dnd-5e-character/dnd-5e-action/use-roll-template.ts";
import {Dnd5eActionTemplateEffectsView} from "./action-effect/dnd-5e-action-template-effects-view.tsx";
import {Dnd5eActionTemplateSegmentsView} from "./dnd-5e-action-template-segments-view.tsx";
import {VariablesProvider} from "./dnd-5e-variables.ts";
import {Optional} from "common/types/generic/index.ts";
import {Dnd5eFeature, Sheet} from "common/legends/asset/index.ts";
import {RollVariables} from "common/qlab/message/index.ts";
import {useGlobalFeatures} from "../../dnd-5e-character/dnd-5e-action/use-global-features.ts";
import {ActionRefProvider} from "./action-context.ts";
import {SectionHeader} from "#lib/components/panel-header.tsx";
import {Dnd5eActionTemplateAddSegmentButton} from "./action-template-segment/dnd-5e-action-template-add-segment-button.tsx";
import {Dnd5eActionTemplateModifiersView} from "./modifier/dnd-5e-action-template-modifiers-view.tsx";
import {DiceButton} from "#lib/components/button/dice-button.tsx";
import {getActiveActionVariablesModifiers} from "../../dnd-5e-character/dnd-5e-action/get-active-variables.ts";
import {MathExpressionFn} from "common/math/math-expression.ts";
import {getSheetVariables} from "common/legends/asset/sheet/dnd-5e/dnd-5e-variable/sheet-variable-signal.ts";

export function Dnd5eActionTemplateEditor({valueRef, onClose, remove}: {
  valueRef: MutableRef<Dnd5eActionTemplate, Dnd5eActionTemplateOperation[]>;
  remove?: () => void;
  onClose: () => void;
}) {
  const {nameRef, segmentsRef, modifiersRef, effectsRef} = useMemo(() => Dnd5eActionTemplateSignals(valueRef), [valueRef]);
  const sheetRef = useSheetSignal(useSheetReference());
  const globalFeaturesRef = useGlobalFeatures();
  const actionVariables = useMemo(() => {
    const valueFn = (sheet: Optional<Sheet>, globalFeatures: Dnd5eFeature[], action: Dnd5eActionTemplate): RollVariables => {
      const variables = {...getSheetVariables(sheet)};
      for (const modifier of getActiveActionVariablesModifiers(sheet, globalFeatures, action)) {
        variables[modifier.name.toUpperCase()] = MathExpressionFn.executeMathExpression(modifier.expression, variables);
      }
      return variables;
    }
    return new MutableRef({
      value: () => valueFn(sheetRef.value, globalFeaturesRef.value, valueRef.value),
      observe: MutableRef.all(sheetRef, globalFeaturesRef, valueRef)
      .map(([sheet, globalFeatures, action]) => valueFn(sheet, globalFeatures, action))
      .observe,
      apply: () => {throw new Error("Unsupported")}
    });
  }, [sheetRef, globalFeaturesRef, valueRef]);

  const rollAction = useRollActionTemplate(useSelectedNodeIDRef(), sheetRef, valueRef);
  return (<ActionRefProvider value={valueRef}>
    <VariablesProvider value={actionVariables}>
      <Modal onClose={onClose} className="max-w-screen-lg">
        <ModalTitle>
          <span>Action Template</span>
          <Spacer/>
          <ButtonBar>
            <Button variant="primary" className="gap-1" onClick={ev => rollAction(ev.shiftKey, ev.ctrlKey)}><FaDiceD20 /><span>Roll</span></Button>
            <ExpandOptions>
              <ExportButton onExport={async () => {
                exportFile(`ACTION-TEMPLATE-${valueRef.value.data.abbreviation}.lvtt`, new Blob([JSON.stringify(valueRef.value, null, 2)]));
              }}> Export Action </ExportButton>
              {remove && <Button variant="destructive" onClick={remove}><FaTrash/> Delete Action</Button>}
            </ExpandOptions>
          </ButtonBar>
        </ModalTitle>
        <ModalBody className="gap-2">
          <InputGroup>
            <InputGroupIcon><FaTag/></InputGroupIcon>
            <InputGroupLabel>Name</InputGroupLabel>
            <InputString value={nameRef}/>
          </InputGroup>

          <Dnd5eActionTemplateModifiersView valueRef={modifiersRef}/>

          <Dnd5eActionTemplateEffectsView valueRef={effectsRef}/>

          <div className="flex flex-col gap-1">
            <ButtonBar>
              <SectionHeader className="flex-1 bg-zinc-800/50">Segments</SectionHeader>
              <Dnd5eActionTemplateAddSegmentButton valueRef={segmentsRef}/>
            </ButtonBar>

            <Dnd5eActionTemplateSegmentsView valueRef={segmentsRef}/>
          </div>

          <DiceButton onClick={ev => rollAction(ev.shiftKey, ev.ctrlKey)}>Roll</DiceButton>
        </ModalBody>
      </Modal>
    </VariablesProvider>
  </ActionRefProvider>);
}

import {InputGroup, InputGroupColorButton, InputGroupLabel} from "#lib/components/index.ts";
import {useMemo} from "react";
import {MutableRef} from "common/ref";
import {InputCheckbox} from "#lib/components/input/input-checkbox.tsx";
import {MeasurementCircle, MeasurementCircleOperation, MeasurementCircleRef} from "../../../../../common/tool/measurement/measurement-circle.ts";

export function MeasurementCircleProperties({valueRef}: {valueRef: MutableRef<MeasurementCircle, MeasurementCircleOperation[]>}) {
  const {stickyRef, fillColorRef} = useMemo(() => MeasurementCircleRef(valueRef), [valueRef]);

  return <>
    <InputGroup className="pl-0">
      <InputCheckbox value={stickyRef} />
      <InputGroupLabel>Sticky</InputGroupLabel>
    </InputGroup>
    <InputGroup>
      <InputGroupLabel>Color</InputGroupLabel>
      <InputGroupColorButton valueRef={fillColorRef} />
    </InputGroup>
  </>
}
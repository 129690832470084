import {ColorOperation, colorType, HSLA, NumberOperation, numberType, ObjectType, Type} from "../../../types/index.ts";
import {MutableRef} from "#common/ref";
import {z} from "zod";

export const LightShapeSpotlight = z.object({
  radius: z.number(),
  angle: z.number(),
  intensity: z.number(),
  color: HSLA,
  falloff: z.number(),
  falloffAngle: z.number(),
  falloffStrength: z.number(),
});
export type LightShapeSpotlight = z.infer<typeof LightShapeSpotlight>;
export const LightShapeSpotlightOperation = z.discriminatedUnion("type", [
  z.object({type: z.literal("update-radius"), operations: z.array(NumberOperation)}),
  z.object({type: z.literal("update-angle"), operations: z.array(NumberOperation)}),
  z.object({type: z.literal("update-intensity"), operations: z.array(NumberOperation)}),
  z.object({type: z.literal("update-color"), operations: z.array(ColorOperation)}),
  z.object({type: z.literal("update-falloff"), operations: z.array(NumberOperation)}),
  z.object({type: z.literal("update-falloff-angle"), operations: z.array(NumberOperation)}),
  z.object({type: z.literal("update-falloff-strength"), operations: z.array(NumberOperation)})
]);
export type LightShapeSpotlightOperation = z.infer<typeof LightShapeSpotlightOperation>;

export type LightShapeSpotlightType = Type<LightShapeSpotlight, LightShapeSpotlightOperation>;

export const lightShapeSpotlightType: LightShapeSpotlightType = new ObjectType({
  radius: numberType,
  angle: numberType,
  intensity: numberType,
  color: colorType,
  falloff: numberType,
  falloffAngle: numberType,
  falloffStrength: numberType
});

export function LightShapeSpotlightRef(valueRef: MutableRef<LightShapeSpotlight, LightShapeSpotlightOperation[]>) {
  return {
    radiusRef: valueRef.map<number, NumberOperation[]>(value => value.radius, (_, operations) => [{type: "update-radius", operations}]),
    angleRef: valueRef.map<number, NumberOperation[]>(value => value.angle, (_, operations) => [{type: "update-angle", operations}]),
    intensityRef: valueRef.map<number, NumberOperation[]>(value => value.intensity, (_, operations) => [{type: "update-intensity", operations}]),
    colorRef: valueRef.map<HSLA, ColorOperation[]>(value => value.color, (_, operations) => [{type: "update-color", operations}]),
    falloffRef: valueRef.map<number, NumberOperation[]>(value => value.falloff, (_, operations) => [{type: "update-falloff", operations}]),
    falloffAngleRef: valueRef.map<number, NumberOperation[]>(value => value.falloffAngle, (_, operations) => [{type: "update-falloff-angle", operations}]),
    falloffStrengthRef: valueRef.map<number, NumberOperation[]>(value => value.falloffStrength, (_, operations) => [{type: "update-falloff-strength", operations}])
  }
}
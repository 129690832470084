import {InputGroup, InputGroupLabel} from "#lib/components/index.ts";
import {BooleanOperation} from "common/types/index.ts";
import {InputCheckbox} from "#lib/components/input/input-checkbox.tsx";
import {MutableRef} from "common/ref";

export type AttachableFieldProps = {
  value: MutableRef<boolean, BooleanOperation[]>;
};

export function AttachableField({value}: AttachableFieldProps) {
  return (
    <InputGroup className="pl-0 mx-2 rounded-md overflow-hidden">
      <InputCheckbox value={value} />
      <InputGroupLabel>Attachable</InputGroupLabel>
    </InputGroup>
  )
}
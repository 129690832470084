import {Transform} from "common/types/index.ts";
import React from "react";
import {ElementRenderPass} from ".//index.ts";
import {AreaNode} from "common/legends/node/area-node.ts";
import {ModelProvider, useModel} from "../../context/pvm-context.ts";
import {OpacityProvider, useOpacity} from "../../context/opacity-context.ts";

export function AreaElementRenderPass({value}: {
  value: AreaNode;
}) {
  const valueOpacity = value.opacity * useOpacity();
  const model = useModel();
  let valueModel = Transform.divide(value.transform, model);
  return (<binder>
    <OpacityProvider value={valueOpacity}>
      <ModelProvider value={valueModel}>
        {[...value.children].reverse().map((child) => <ElementRenderPass key={child.data.id} value={child} />)}
      </ModelProvider>
    </OpacityProvider>
  </binder>);
}
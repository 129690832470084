import {Optional, ValueFn} from "common/types/index.ts";
import {useMemo} from "react";
import {pipe} from "common/pipe";
import {distinct, map} from "common/observable";
import {MutableRef} from "common/ref";
import {AreaToolMode, AreaToolModeOperation, AreaToolModes} from "legends/common/tool/area/area-tool-mode";
import {AreaToolData, AreaToolDataOperation} from "../../../../../common/tool/area/area-tool-data.ts";

export function useAreaMode<Type extends AreaToolMode["type"]>(type: Type, tool: MutableRef<Optional<AreaToolData>, AreaToolDataOperation[]>) {
  return useMemo((): MutableRef<AreaToolModes[Type]["value"], AreaToolModes[Type]["operation"][]> => {
    const valueFn = (toolMode: Optional<AreaToolData>): AreaToolModes[Type]["value"] => {
      return toolMode?.mode?.type === type ? toolMode.mode.data as AreaToolModes[Type]["value"] : undefined;
    };
    return new MutableRef({
      value() {return valueFn(tool.value)},
      observe: pipe(tool.observe, map(value => valueFn(value)), distinct()),
      apply: (fn) => tool.apply((prev): AreaToolDataOperation[] => {
        if (prev?.mode?.type !== type) return [];
        const operations = fn(prev.mode.data as AreaToolModes[Type]["value"]) as AreaToolModes[Type]["operation"][];
        return [{type: "update-mode", operations: ValueFn.apply([
          {type: type, operations: operations} as AreaToolModeOperation
        ])}];
      }).then(valueFn)
    });
  }, [tool]);
}


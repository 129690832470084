import {
  Dnd5eActionTemplateSegmentRollModifier,
  Dnd5eActionTemplateSegmentRollModifierOperation,
  Dnd5eActionTemplateSegmentRollModifierTypes
} from "common/legends/asset/sheet/dnd-5e/dnd-5e-action-definition/segment/damage/dnd-5e-action-template-segment-roll-modifier.ts";
import {MutableRef} from "common/ref";
import {ListOperation} from "common/types/generic/index.ts";
import {InputList, InputListItemProps, useDragListItem} from "#lib/components/list/input-list.tsx";
import {useTypedRef} from "../../../../../../../common/use-typed-ref.ts";
import React from "react";
import {Dnd5eActionTemplateSegmentDiceRollConditionModifierListItem} from "./dnd-5e-action-template-segment-roll-condition-modifier-view.tsx";
import {Dnd5eActionTemplateSegmentRollExpressionModifierListItem} from "./dnd-5e-action-template-segment-roll-expression-modifier-view.tsx";
import {Dnd5eVariableModifierListItem} from "../dnd-5e-variable-modifier-view.tsx";

function Dnd5eActionTemplateSegmentDiceRollModifierListItem({item, remove, duplicate}: InputListItemProps<Dnd5eActionTemplateSegmentRollModifier, Dnd5eActionTemplateSegmentRollModifierOperation>) {
  const [dragHandlerRef, dragRefPreview] = useDragListItem("legends/action-template-segment-dice-roll-expression", item, remove);
  const [type, typedRef] = useTypedRef<Dnd5eActionTemplateSegmentRollModifierTypes>(item);
  if (type === "variable") return <Dnd5eVariableModifierListItem item={typedRef} remove={remove} duplicate={duplicate} dragHandlerRef={dragHandlerRef} dragRefPreview={dragRefPreview} />;
  if (type === "action::roll::condition") return <Dnd5eActionTemplateSegmentDiceRollConditionModifierListItem item={typedRef} remove={remove} duplicate={duplicate} dragHandlerRef={dragHandlerRef} dragRefPreview={dragRefPreview} />;
  if (type === "action::roll::expression") return <Dnd5eActionTemplateSegmentRollExpressionModifierListItem item={typedRef} remove={remove} duplicate={duplicate} dragHandlerRef={dragHandlerRef} dragRefPreview={dragRefPreview} />;
  return <>{type}</>
}

export function Dnd5eActionTemplateSegmentRollModifiersView({valueRef}: {valueRef: MutableRef<Dnd5eActionTemplateSegmentRollModifier[], ListOperation<Dnd5eActionTemplateSegmentRollModifier, Dnd5eActionTemplateSegmentRollModifierOperation>[]>}) {
  return (<InputList
      accept="legends/action-template-segment-roll-expression"
      items={valueRef}
      itemKey={(v) => v.data.modifierID}
      copy={v => v} ListItem={Dnd5eActionTemplateSegmentDiceRollModifierListItem} />);
}
import {
  useArrayBuffer,
  useAttribLocation,
  useBindVertexArribArray,
  useElementArrayBuffer,
  useProgram,
  useShader,
  useUniformLocation,
  useVertexBuffer
} from "#lib/gl-react/index.ts";
import React, {useMemo} from "react";
import {Color, HSLA} from "common/types/index.ts";
import {useRenderPassTexture} from "../../context/render-pass-texture-context.ts";


const vertexShader = `#version 300 es
precision highp float;
in vec2 a_position;
in vec2 a_tex_coord;
out vec2 v_tex_coord;
void main()
{
  gl_Position = vec4(a_position, 0, 1);
  v_tex_coord = a_tex_coord;
}
`;

const fragmentShader = `#version 300 es
precision highp float;
precision highp sampler2DArray;

in vec2 v_tex_coord;

uniform sampler2D u_normal;
uniform vec4 u_color;

out vec4 outColor;

void main() {
  vec4 normal = (texture(u_normal, v_tex_coord) + 1.0) / 2.0;
  float n = max(0.0, normal.z);
  outColor = vec4(u_color.rgb, u_color.a * n);
}
`;

export function GlobalLightShader({color = Color.WHITE}: {
  color?: HSLA
}) {
  const program = useProgram(
    useShader(WebGL2RenderingContext.VERTEX_SHADER, vertexShader),
    useShader(WebGL2RenderingContext.FRAGMENT_SHADER, fragmentShader)
  );
  const vbo = useArrayBuffer(useMemo(() => new Float32Array([
    -1, -1, 0, 0,
     1, -1, 1, 0,
     1,  1, 1, 1,
    -1,  1, 0, 1
  ]), []));
  const vao = useVertexBuffer();
  useBindVertexArribArray(vao, useAttribLocation(program, "a_position"), vbo, 2, WebGL2RenderingContext.FLOAT, false, 4 * 4, 0);
  useBindVertexArribArray(vao, useAttribLocation(program, "a_tex_coord"), vbo, 2, WebGL2RenderingContext.FLOAT, false, 4 * 4, 2 * 4);

  const ebo = useElementArrayBuffer(useMemo(() => new Uint16Array([
    0, 1, 2,
    2, 3, 0
  ]), []));

  const color4f = useMemo(() => new Float32Array(Color.toRGBA(color)), [color]);
  const [_, normalTexture] = useRenderPassTexture();

  return (<program value={program}>
    <uniform4fv location={useUniformLocation(program, "u_color")} data={color4f}/>
    <texture2d value={normalTexture}>
      <vertexArray value={vao}>
        <elementArrayBuffer value={ebo}>
          <drawElements mode={WebGL2RenderingContext.TRIANGLES} type={WebGL2RenderingContext.UNSIGNED_SHORT} offset={0} count={6}/>
        </elementArrayBuffer>
      </vertexArray>
    </texture2d>
    <activeTexture texture={WebGL2RenderingContext.TEXTURE0}/>
  </program>);
}

import {Modal, ModalBody, ModalTitle} from "#lib/components/modal/index.ts";
import {Button, ButtonBar, InputGroup, InputGroupLabel, Spacer} from "#lib/components/index.ts";
import {FaPaperPlane, FaTag, FaTrash} from "react-icons/fa";
import {ExportButton} from "#lib/components/button/export-button.tsx";
import {toPromise} from "common/observable";
import {exportFile} from "../../../../../common/export-file.ts";
import {InputGroupIcon} from "#lib/components/input/input-group-icon.tsx";
import {InputString} from "#lib/components/input/input-string.tsx";
import {RichTextFn} from "common/types/index.ts";
import {Dnd5eFeature, Dnd5eFeatureOperation, Dnd5eFeatureSignal} from "common/legends/index.ts";
import {InputRichText} from "#lib/components/input/input-rich-text.tsx";
import React, {memo, useMemo, useRef} from "react";
import {useSelectedNodeID} from "../../dnd-5e-character/use-selected-sheet.ts";
import {useSendFeatureMessage} from "../../dnd-5e-character/dnd-5e-action/use-send-feature-message.ts";
import {ExpandOptions} from "#lib/components/expand-options.tsx";
import {useComputedValue} from "#lib/signal/index.ts";
import {Dnd5eModifiersView} from "../dnd-5e-modifier/dnd-5e-modifiers-view.tsx";
import {Dnd5eResourcesList} from "../dnd-5e-resource/dnd-5e-resources-list.tsx";
import {Dnd5eEffectsView} from "../dnd-5e-effect/index.ts";
import {MutableRef} from "common/ref";
import {Dnd5eActionTemplatesView} from "../dnd-5e-action/dnd-5e-action-templates-view.tsx";

export type FeatureEditorProps = {
  value: MutableRef<Dnd5eFeature, Dnd5eFeatureOperation[]>,
  onClose: () => void;
  remove?: () => void;
};

export const Dnd5eFeatureEditor = memo(function Dnd5eFeatureEditor({value, remove, onClose}: FeatureEditorProps) {
  const {title, source, modifiers, resources, description, actionsRef, effects} = useMemo(() => Dnd5eFeatureSignal(value), [value]);

  const isDescriptionEmpty = useComputedValue(description, description => RichTextFn.isEmpty(description));

  const selectedNodeId = useSelectedNodeID();
  const sendMessage = useSendFeatureMessage();
  const onSend = async () => {
    const nodeID = await toPromise(selectedNodeId);
    await sendMessage(nodeID!, value.value.title, [], value.value.description);
  };

  const initialFocusRef = useRef<HTMLInputElement>(null);
  return (<Modal onClose={onClose} initialFocus={initialFocusRef}>
    <ModalTitle>
      <span>Feature</span>
      <Spacer />
      <ButtonBar>
        <Button variant="primary" disabled={isDescriptionEmpty} title="Send to Chat" onClick={onSend}><FaPaperPlane/> Send to Chat</Button>
        <ExpandOptions>
          <ExportButton onExport={async () => {
            exportFile(`FEATURE-${value.value.title}.lvtt`, new Blob([JSON.stringify(value.value, null, 2)]));
          }}> Export Feature </ExportButton>
          {remove && <Button variant="destructive" onClick={remove}><FaTrash/> Delete Feature</Button>}
        </ExpandOptions>
      </ButtonBar>
    </ModalTitle>
    <ModalBody className="pb-8">
      <InputGroup className="flex-1" title="Title">
        <InputGroupIcon><FaTag /></InputGroupIcon>
        <InputGroupLabel>Name</InputGroupLabel>
        <InputString ref={initialFocusRef} value={title} />
      </InputGroup>
      <InputGroup>
        <InputGroupIcon />
        <InputGroupLabel>Source</InputGroupLabel>
        <InputString placeholder={"Source"} value={source} />
      </InputGroup>
      <InputRichText value={description} />
      <Dnd5eActionTemplatesView valueRef={actionsRef} />
      <Dnd5eModifiersView value={modifiers} />
      <Dnd5eResourcesList value={resources} />
      <Dnd5eEffectsView value={effects} />
    </ModalBody>
  </Modal>);
});

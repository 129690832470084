import {AreaShader} from "./area-shader.tsx";
import {Arc, ArcFn, HSLA, Point} from "common/types/generic/index.ts";
import {Spline} from "common/types/generic/spline/index.ts";
import {useMemo} from "react";
import {Vector2} from "common/math/vector/vector2.ts";

export type ArcShaderProps = {
  origin: Point,
  color: HSLA;
  arc: Arc,
};
export function ArcShader({arc, origin, ...props}: ArcShaderProps) {
  const spline = useMemo((): Spline => {
    const points = ArcFn.toPoints({...arc, radius: arc.radius});
    return ({
      start: Vector2.subtract(points[0], origin),
      controlPoint1: [0, 0],
      controlPoint2: [0, 0],
      closed: true,
      curves: points.slice(1).map(point => ({end: Vector2.subtract(point, origin), controlPoint1: [0, 0], controlPoint2: [0, 0]}))
    })
  }, [arc, origin]);
  return <AreaShader {...props} origin={origin} spline={spline} scale={1} />
}

import {useMemo} from "react";
import {ValueFn} from "common/types/index.ts";
import {useEditor} from "./editor-context.ts";
import {MutableRef} from "common/ref";
import {EditorVision, EditorVisionOperation} from "../../../viewport/scene/editor-vision.ts";

export function useEditorVision(): MutableRef<EditorVision, EditorVisionOperation[]> {
  const editor = useEditor();
  return useMemo(() => editor.state.map<EditorVision, EditorVisionOperation[]>(
    value => value ? value.data.vision : ({senseID: undefined, override: false}),
    (prev, operations) => {
      if (prev === undefined) return [];
      return ValueFn.apply([{type: prev.type, operations: [{type: "update-vision", operations}]}]);
    }
  ).distinct(), [editor.state]);
}

import {Fragment, useCallback, useRef, useState} from "react";
import {canDecrypt, encryptValue, FileReference} from "common/types/index.ts";
import {DiceExpression} from "common/dice/index.ts";
import {generateMessageID, QLabMessageID, RollRequestID, RollRequests, TextMessageContent, TextMessageParser} from "common/qlab/index.ts";
import {useMessages, useObservable} from "#lib/qlab/index.ts";
import {Button, Input} from "#lib/components/index.ts";
import {useUserID} from "#lib/auth/use-get-user-id.ts";
import {FaPaperPlane} from "react-icons/fa";
import {BareIconButton} from "#lib/components/bare-icon-button/index.ts";
import {TextMessageView} from "./text-messsage-view.tsx";
import {Dnd5eSavingThrowRequestMessageView} from "./dnd-5e/dnd-5e-saving-throw-request-messsage-view.tsx";
import {Dnd5eAbilityCheckMessageView, Dnd5eAttackMessageView} from "./dnd-5e/index.ts";
import {Dnd5eSavingThrowMessageView} from "./dnd-5e/dnd-5e-saving-throw-messsage-view.tsx";
import {Dnd5eFeatureMessageView} from "./dnd-5e/dnd-5e-feature-messsage-view.tsx";
import {InitiativeRequestMessageView} from "./initiative-request-message-view.tsx";
import {usePlayerIcon} from "../../../routes/game/use-player-icon.ts";
import {useSelectedNodeID} from "../../panel/sheet/editor/dnd-5e-character/use-selected-sheet.ts";
import {toPromise} from "common/observable";
import {UserNodeIcon} from "./user-node-icon.tsx";
import {twMerge} from "tailwind-merge";
import {useGameReference} from "../../../routes/game/index.ts";
import {useSeedTime} from "../../../routes/game/use-seed-time.ts";
import {MessageIDProvider} from "./message-context.ts";
import {Dnd5eActionMessageView} from "./dnd-5e/dnd-5e-action/dnd-5e-action-messsage-view.tsx";
import {useGetNodeIcon, useNodeIcon} from "../../common/use-get-node-icon.ts";

export function ChatStackView({}: {
}) {
  const game = useGameReference();
  const userID = useUserID()!;
  const playerIcon = usePlayerIcon(userID);
  const {messages, createMessage, requestMore} = useMessages(game.storeId);
  const selectedNodeID = useSelectedNodeID();
  const getNodeIcon = useGetNodeIcon();
  const time = useSeedTime();
  const onCreateMessageHandler = useCallback(async (message: TextMessageContent, rollRequests: {[rollId: string]: DiceExpression}) => {
    const nodeID = await toPromise(selectedNodeID);
    const nodeIcon = getNodeIcon(nodeID);
    createMessage(generateMessageID(time()), {
      type: "text",
      data: {
        userID,
        nodeID,
        icon: nodeIcon || playerIcon,
        content: await encryptValue({}, () => message),
        rollRequests: await encryptValue({}, () => Object.entries(rollRequests).reduce((rollRequests, [rollId, expression]) => {
          rollRequests[rollId as RollRequestID] = {
            expression: expression,
            variables: {},
            visibility: {
              audience: {},
              default: {
                canViewExpression: true,
                canViewResult: true
              }
            }
          };
          return rollRequests;
        }, {} as RollRequests)),
        rollResults: undefined
      }
    }).catch(console.error);
  }, [createMessage, userID, playerIcon, selectedNodeID, getNodeIcon, time])

  const messagesRef = useRef<HTMLDivElement>(null);
  const [scrolling, setScrolling] = useState<boolean>(false);
  const setRecentlyScrolled = useCallback(() => {
    setScrolling(true);
  }, [setScrolling]);

  return <div className={twMerge(
    "flex flex-col flex-1",
    scrolling && "pointer-events-auto"
  )} onScrollCapture={_ => setRecentlyScrolled()} onMouseLeave={() => setScrolling(false)}>
    <div ref={messagesRef} className="tab-content scroller">
      <div className="flex min-h-full flex-col justify-end">
        <div className="p-2">
          <Button className="w-full bg-zinc-900/50 rounded-lg" onClick={() => requestMore(5).then()}>Request More</Button>
        </div>
        {Object.entries(messages)
          .sort((a,b) => a[0].localeCompare(b[0]))
          .filter(([,message]) => {
            if (message === undefined) return false;
            switch (message.type) {
              case "text": return canDecrypt(userID, message.data.content);
              case "dnd-5e-saving-throw-request-message": return true;
              case "dnd-5e-attack-message": return true;
              case "dnd-5e-ability-check-message": return message.data.referenceMessageID === undefined;
              case "dnd-5e-saving-throw-message": return message.data.referenceMessageID === undefined;
              case "dnd-5e-feature-message": return true;
              case "dnd-5e-action-message": return true;
              case "initiative-request-message": return true;
              default: return false;
            }
          })
          .map(([messageId, message]) => {
            if (message?.type === "text") {
              return <MessageIDProvider key={messageId} value={messageId as QLabMessageID}><TextMessageView messageId={messageId as QLabMessageID} message={message.data} /></MessageIDProvider>
            } else if (message?.type === "dnd-5e-saving-throw-request-message") {
              return <MessageIDProvider key={messageId} value={messageId as QLabMessageID}><Dnd5eSavingThrowRequestMessageView key={messageId} messageId={messageId as QLabMessageID} message={message.data} /></MessageIDProvider>
            } else if (message?.type === "dnd-5e-attack-message") {
              return <MessageIDProvider key={messageId} value={messageId as QLabMessageID}><Dnd5eAttackMessageView key={messageId} messageId={messageId as QLabMessageID} message={message.data} /></MessageIDProvider>
            } else if (message?.type === "dnd-5e-ability-check-message") {
              return <MessageIDProvider key={messageId} value={messageId as QLabMessageID}><Dnd5eAbilityCheckMessageView key={messageId} messageId={messageId as QLabMessageID} message={message.data} /></MessageIDProvider>
            } else if (message?.type === "dnd-5e-saving-throw-message") {
              return <MessageIDProvider key={messageId} value={messageId as QLabMessageID}><Dnd5eSavingThrowMessageView key={messageId} messageId={messageId as QLabMessageID} message={message.data} /></MessageIDProvider>
            } else if (message?.type === "dnd-5e-feature-message") {
              return <MessageIDProvider key={messageId} value={messageId as QLabMessageID}><Dnd5eFeatureMessageView key={messageId} messageId={messageId as QLabMessageID} message={message.data} /></MessageIDProvider>
            } else if (message?.type === "initiative-request-message") {
              return <MessageIDProvider key={messageId} value={messageId as QLabMessageID}><InitiativeRequestMessageView key={messageId} messageId={messageId as QLabMessageID} message={message.data} /></MessageIDProvider>
            } else if (message?.type === "dnd-5e-action-message") {
              return <MessageIDProvider key={messageId} value={messageId as QLabMessageID}><Dnd5eActionMessageView key={messageId} messageId={messageId as QLabMessageID} message={message.data} /></MessageIDProvider>
            }
            return <Fragment key={messageId} />
          })}
      </div>
      <div className="scroller-snap" ref={useCallback((r: HTMLDivElement | null) => {
        r?.scrollIntoView({behavior: "instant", block: "end"});
      }, [])}/>
    </div>
    <ChatMessage onSend={onCreateMessageHandler} />
  </div>;
}


type ChatMessageProps = {
  onSend: (content: TextMessageContent, rollRequests: {[rollId: string]: DiceExpression}) => void;
};
function ChatMessage({onSend}: ChatMessageProps) {
  const [value, setValue] = useState<string>("");
  const sendMessage = () => {
    if (TextMessageParser.isValid(value)) {
      const {content, rollRequests} = TextMessageParser.parse(value);
      onSend(content, rollRequests);
      setValue("");
    }
  };

  const userID = useUserID()!;
  const selectedNodeID = useSelectedNodeID();
  const nodeID = useObservable(selectedNodeID, undefined, [selectedNodeID]);
  const nodeIcon: FileReference = useObservable(useNodeIcon(selectedNodeID), undefined, [selectedNodeID]);

  return <div className="relative mt-7 mb-2 mx-2">
    <div className="bg-zinc-900/80 rounded-lg shadow-lg backdrop-blur-sm">
      <UserNodeIcon userID={userID} nodeID={nodeID} icon={nodeIcon} />
      <div className="pointer-events-auto break-words px-4 pt-5 pb-2">
        <label className="flex flex-row items-center justify-center">
          <Input size="large" placeholder="Message" value={value} onChange={ev => setValue(ev.target.value)} onKeyDown={ev => {
            if (ev.key === "Enter") sendMessage();
          }} />
          <BareIconButton onClick={() => sendMessage()}><FaPaperPlane /></BareIconButton>
        </label>
      </div>
    </div>
  </div>
}
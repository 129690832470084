import {useMemo} from "react";
import {Dnd5eCharacter, Dnd5eFeature, Dnd5eSpellLevel, Sheet} from "common/legends/index.ts";
import {Optional} from "common/types/index.ts";
import {Dnd5eResource} from "common/legends/asset/sheet/dnd-5e/dnd-5e-resource/dnd-5e-resource.ts";
import {Dnd5eStatBlock} from "common/legends/asset/sheet/dnd-5e/dnd-5e-stat-block/index.ts";
import {getMaxPactSlots} from "common/legends/asset/sheet/dnd-5e/character/get-max-spell-slots-by-classes.ts";
import {Dnd5eActionTemplate} from "common/legends/asset/sheet/dnd-5e/dnd-5e-action-definition/template/dnd-5e-action-template.ts";
import {Dnd5eProcess} from "common/legends/asset/sheet/dnd-5e/dnd-5e-action-definition/process/dnd-5e-process.ts";
import {MathExpressionFn} from "common/math/math-expression.ts";
import {useGlobalFeatures} from "./use-global-features.ts";
import {getActiveActionVariables} from "./get-active-variables.ts";
import {MutableRef, Ref} from "common/ref";
import {Dnd5eActionTemplateResource} from "common/legends/asset/sheet/dnd-5e/dnd-5e-action-definition/resource/dnd-5e-action-template-resource.ts";
import {Dnd5eSpellSlotLevel} from "common/legends/asset/sheet/dnd-5e/dnd-5e-modifier/dnd-5e-spell-slot-level.ts";

function getActivationTriggerProcesses(action: Dnd5eActionTemplate): Dnd5eProcess[] {
  const enabledEffects = action.data.effects.filter(effect => effect.enabled);
  const modifiers = [
    ...action.data.modifiers,
    ...enabledEffects.flatMap(e => e.modifiers)
  ];
  return modifiers.filter(m => m.type === "action::trigger::activation")
    .flatMap((m): Dnd5eProcess[] => m.data.processes);
}

export function useSheetActionResources(
  sheetRef: Ref<Optional<Sheet>>,
  actionRef: Ref<Dnd5eActionTemplate>
): Ref<Dnd5eActionTemplateResource[]> {
  const globalFeaturesRef = useGlobalFeatures();
  return useMemo(() => {
    const valueFn = (sheet: Optional<Sheet>, globalFeatures: Dnd5eFeature[], action: Dnd5eActionTemplate) => {
      const variables = getActiveActionVariables(sheet, globalFeatures, action);
      return getActivationTriggerProcesses(action)
        .flatMap((process): Dnd5eActionTemplateResource[] => {
          switch(process.type) {
            case "adjust-spell-slot": return [{type: "spell-slot", data: {
              level: Dnd5eSpellSlotLevel.parse(`${Math.max(1, Math.min(MathExpressionFn.executeMathExpression(process.data.level, variables), 9))}`)
            }}];
            case "consume-item": return [{type: "item", data: {
              name: process.data.item
            }}];
            case "adjust-custom-resource": return [{type: "custom-resource", data: {
              name: process.data.resource
            }}];
            default: return [];
          }
        });
    }

    return MutableRef.all(sheetRef, globalFeaturesRef, actionRef)
        .map(([sheet, globalFeatures, action]) => valueFn(sheet, globalFeatures, action));
  }, [actionRef, sheetRef, globalFeaturesRef]);
}

export function findDnd5eStatBlockSheetResource(sheet: Dnd5eStatBlock, name: string): Optional<Dnd5eResource> {
  const resource = sheet.features.filter(feature => feature.enabled).flatMap(feature => feature.resources).find(r => r.name === name);
  if (resource !== undefined) return resource;
  return undefined;
}

export function findDnd5eCharacterSheetResource(sheet: Dnd5eCharacter, name: string): Optional<Dnd5eResource> {
  for (const feature of sheet.background.features) {
    if (!feature.enabled) continue;
    const resource = feature.resources.find(r => r.name === name);
    if (resource !== undefined) return resource;
  }
  for (const feature of sheet.race.features) {
    if (!feature.enabled) continue;
    const resource = feature.resources.find(r => r.name === name);
    if (resource !== undefined) return resource;
  }
  for (const clazz of sheet.classes) {
    for (const feature of clazz.features) {
      if (feature.level > clazz.level) continue;
      if (!feature.feature.enabled) continue;
      const resource = feature.feature.resources.find(r => r.name === name);
      if (resource !== undefined) return resource;
    }
  }
  for (const item of sheet.inventory) {
    const resource = item.resources.find(r => r.name === name);
    if (resource !== undefined) return resource;
  }
  for (const feature of sheet.features) {
    if (!feature.enabled) continue;
    const resource = feature.resources.find(r => r.name === name);
    if (resource !== undefined) return resource;
  }

  return undefined;
}

export function useSheetActionAvailableResources(sheetRef: Ref<Optional<Sheet>>, actionRef: Ref<Dnd5eActionTemplate>): Ref<boolean> {
  const globalFeaturesRef = useGlobalFeatures();
  return useMemo(() => {
    const valueFn = (sheet: Optional<Sheet>, globalFeatures: Dnd5eFeature[], action: Dnd5eActionTemplate): boolean => {
      const variables = getActiveActionVariables(sheet, globalFeatures, action);

      return getActivationTriggerProcesses(action).every(process => {
        if (process.type === "consume-item") {
          const quantity = -1;
          if (sheet?.type === "dnd-5e-character") {
            return sheet.data.inventory.some(item => item.item === process.data.item && item.qty + quantity >= 0);
          } else {
            return false;
          }
        } else if (process.type === "adjust-spell-slot") {
          const level = Dnd5eSpellLevel.parse(`${Math.max(1, Math.min(MathExpressionFn.executeMathExpression(process.data.level, variables),9))}`);
          const quantity = MathExpressionFn.executeMathExpression(process.data.quantity, variables);
          if (sheet?.type === "dnd-5e-character") {
            const slots = (sheet.data.spellSlots[level] || 0) + (getMaxPactSlots(sheet.data.classes, level) > 0 ? sheet.data.pactSlots : 0);
            return (slots + quantity) >= 0;
          } else if (sheet?.type === "dnd-5e-stat-block") {
            const slots = sheet.data.spellSlots[level] || 0;
            return (slots + quantity) >= 0;
          }
        } else if (process.type === "adjust-custom-resource") {
          const quantity = MathExpressionFn.executeMathExpression(process.data.quantity, variables);
          if (sheet?.type === "dnd-5e-character") {
            const resource = findDnd5eCharacterSheetResource(sheet.data, process.data.resource);
            return ((resource?.current ?? 0) + quantity) >= 0;
          } else if (sheet?.type === "dnd-5e-stat-block") {
            const resource = findDnd5eStatBlockSheetResource(sheet.data, process.data.resource);
            return ((resource?.current ?? 0) + quantity) >= 0;
          }
        }
        return true;
      });
    };

    return MutableRef.all(sheetRef, globalFeaturesRef, actionRef)
      .map(([sheet, globalFeatures, action]) => valueFn(sheet, globalFeatures, action));
  }, [actionRef, sheetRef, globalFeaturesRef]);
}
import {Modal, ModalBody, ModalTitle} from "#lib/components/modal/index.ts";
import {Button, ButtonBar, InputGroup, InputGroupLabel, Spacer} from "#lib/components/index.ts";
import {FaPaperPlane, FaTag, FaTrash} from "react-icons/fa";
import {ExportButton} from "#lib/components/button/export-button.tsx";
import {toPromise} from "common/observable";
import {exportFile} from "../../../../../common/export-file.ts";
import {InputGroupIcon} from "#lib/components/input/input-group-icon.tsx";
import {InputString} from "#lib/components/input/input-string.tsx";
import {BooleanFn, RichTextFn, SetFn} from "common/types/index.ts";
import {InputSelect} from "#lib/components/input/input-select.tsx";
import {DND_5E_SPELL_SCHOOL_LABEL, DND_5E_SPELL_SCHOOLS} from "common/legends/asset/sheet/dnd-5e/character/dnd-5e-spell-school.ts";
import {Dnd5eSpell, Dnd5eSpellOperation, Dnd5eSpellSignal, DND_5E_SPELL_LEVEL, DND_5E_SPELL_LEVEL_LABEL} from "common/legends/index.ts";
import {SectionHeader} from "#lib/components/panel-header.tsx";
import {InputRichText} from "#lib/components/input/input-rich-text.tsx";
import React, {memo, useMemo, useRef} from "react";
import {useSelectedNodeID} from "../../dnd-5e-character/use-selected-sheet.ts";
import {useSendFeatureMessage} from "../../dnd-5e-character/dnd-5e-action/use-send-feature-message.ts";
import {ExpandOptions} from "#lib/components/expand-options.tsx";
import {useComputedValue, useRefValue} from "#lib/signal/index.ts";
import {MutableRef} from "common/ref";
import {Dnd5eActionTemplatesView} from "../dnd-5e-action/dnd-5e-action-templates-view.tsx";

export type SpellEditorProps = {
  value: MutableRef<Dnd5eSpell, Dnd5eSpellOperation[]>,
  onClose: () => void;
  remove?: () => void;
};

export const Dnd5eSpellEditor = memo(function Dnd5eSpellEditor({value, remove, onClose}: SpellEditorProps) {
  const {name, description, school, level, actionsRef, castingTime, duration, components, concentration, materialComponent, royaltyComponent, range, ritual} = useMemo(() => Dnd5eSpellSignal(value), [value]);

  const isDescriptionEmpty = useComputedValue(description, description => RichTextFn.isEmpty(description));
  const concentrationValue = useRefValue(concentration);
  const componentValues = useRefValue(components);
  const ritualValue = useRefValue(ritual);

  const selectedNodeId = useSelectedNodeID();
  const sendMessage = useSendFeatureMessage();
  const onSend = async () => {
    const nodeID = await toPromise(selectedNodeId);
    await sendMessage(nodeID!, value.value.name, [], value.value.description);
  };

  const initialFocusRef = useRef<HTMLInputElement>(null);
  return (<Modal onClose={onClose} initialFocus={initialFocusRef}>
    <ModalTitle>
      <span>Spell</span>
      <Spacer />
      <ButtonBar>
        <Button variant="primary" disabled={isDescriptionEmpty} title="Send to Chat" onClick={onSend}><FaPaperPlane/> Send to Chat</Button>
        <ExpandOptions>
          <ExportButton onExport={async () => {
            exportFile(`SPELL-${value.value.name}.lvtt`, new Blob([JSON.stringify(value.value, null, 2)]));
          }}> Export Spell </ExportButton>
          {remove && <Button variant="destructive" onClick={remove}><FaTrash/> Delete Spell</Button>}
        </ExpandOptions>
      </ButtonBar>
    </ModalTitle>
    <ModalBody className="pb-8">
      <div className="flex flex-row gap-0.5">
        <InputGroup className="flex-1">
          <InputGroupIcon><FaTag/></InputGroupIcon>
          <InputGroupLabel>Name</InputGroupLabel>
          <InputString ref={initialFocusRef} value={name} placeholder="Spell Name"/>
        </InputGroup>
        <ButtonBar>
          <Button variant={concentrationValue ? "primary" : undefined} onClick={() => {
            concentration.apply(prev => BooleanFn.set(prev, !prev));
          }}>Concentration</Button>
          <Button variant={ritualValue ? "primary" : undefined} onClick={() => {
            ritual.apply(prev => BooleanFn.set(prev, !prev));
          }}>Ritual</Button>
        </ButtonBar>
      </div>
      <div className="flex flex-col gap-0.5">
        <div className="flex flex-row gap-0.5">
          <InputGroup className="flex-1">
            <InputGroupIcon />
            <InputGroupLabel>School</InputGroupLabel>
            <InputSelect value={school} values={DND_5E_SPELL_SCHOOLS} labels={DND_5E_SPELL_SCHOOL_LABEL}/>
          </InputGroup>
          <InputGroup className="flex-1">
            <InputGroupLabel>Level</InputGroupLabel>
            <InputSelect value={level} values={DND_5E_SPELL_LEVEL} labels={DND_5E_SPELL_LEVEL_LABEL}/>
          </InputGroup>
        </div>
        <InputGroup className="flex-1">
          <InputGroupIcon />
          <InputGroupLabel>Casting Time</InputGroupLabel>
          <InputString value={castingTime}/>
        </InputGroup>

        <InputGroup className="flex-1">
          <InputGroupIcon/>
          <InputGroupLabel>Range</InputGroupLabel>
          <InputString value={range}/>
        </InputGroup>

        <div className="flex flex-row gap-0.5">
          <InputGroup>
            <InputGroupIcon/>
            <InputGroupLabel>Spell Components</InputGroupLabel>
          </InputGroup>
          <ButtonBar className="flex-1 gap-0.5">
            <Button variant={componentValues.includes("verbal") ? "primary" : undefined} onClick={() => {
              components.apply(prev => prev.includes("verbal") ? SetFn.delete("verbal") : SetFn.insert("verbal"))
            }}>Verbal</Button>
            <Button variant={componentValues.includes("somantic") ? "primary" : undefined} onClick={() => {
              components.apply(prev => prev.includes("somantic") ? SetFn.delete("somantic") : SetFn.insert("somantic"))
            }}>Somantic</Button>
            <Button variant={componentValues.includes("material") ? "primary" : undefined} onClick={() => {
              components.apply(prev => prev.includes("material") ? SetFn.delete("material") : SetFn.insert("material"))
            }}>Material</Button>
            <Button variant={componentValues.includes("royalty") ? "primary" : undefined} onClick={() => {
              components.apply(prev => prev.includes("royalty") ? SetFn.delete("royalty") : SetFn.insert("royalty"))
            }}>Royalty</Button>
          </ButtonBar>
        </div>
        {componentValues.includes("material") && <InputGroup className="flex-1">
          <InputGroupIcon />
          <InputGroupLabel>Material Component</InputGroupLabel>
          <InputString value={materialComponent}/>
        </InputGroup>}
        {componentValues.includes("royalty") && <InputGroup className="flex-1">
          <InputGroupIcon />
          <InputGroupLabel>Royalty Component</InputGroupLabel>
          <InputString value={royaltyComponent}/>
        </InputGroup>}

        <InputGroup className="flex-1">
          <InputGroupIcon />
          <InputGroupLabel>Duration</InputGroupLabel>
          <InputString value={duration}/>
        </InputGroup>
      </div>

      <div className="flex flex-col gap-0.5">
        <SectionHeader className="flex-1 bg-zinc-800/50">Description</SectionHeader>
        <InputRichText placeholder="Description" value={description}/>
      </div>

      <Dnd5eActionTemplatesView valueRef={actionsRef}/>
    </ModalBody>
  </Modal>);
});

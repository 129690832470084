import {Color, HSLA, Optional, TransformFn, Tree} from "common/types/index.ts";
import {useComputedValue, useRefValue} from "#lib/signal/index.ts";
import React, {useMemo} from "react";
import {Node, NodeId} from "common/legends/index.ts";
import {WallLineShader} from "../../shader/wall-line-shader.tsx";
import {AreaShader} from "../../shader/shape/area-shader.tsx";
import {Vector2} from "common/math/vector/vector2.ts";
import {LineShader} from "../../shader/line-shader.tsx";
import {MutableRef, Ref} from "common/ref";
import {Spline} from "common/types/generic/spline/spline.ts";
import {VertexSelectionIndicator} from "../element/vertex-selection-indicator.tsx";
import {getNodeTransform} from "../../../tool/use-node-pos-to-world-pos.ts";
import {AreaToolSelection} from "../../../../common/tool/area/area-tool-select-mode.ts";
import {Tool, ToolOperation} from "../../../../common/tool/tool.ts";
import {WallLineFn} from "../../../scene/scene-view.tsx";
import {cubicBezier} from "common/math/bezier/bezier.ts";
import {ModelProvider} from "../../context/pvm-context.ts";

function useAreaNode(nodesRef: Ref<Node[]>, nodeID: Optional<NodeId>): Optional<Node> {
  return useComputedValue(nodesRef, nodes => {
    if (nodeID === undefined) return undefined;
    const node = Tree.getItemById(nodes, nodeID);
    if (node?.type !== "area" && !(node?.type === "light" && node.data.shape?.type === "freeform")) return undefined;
    return node;
  }, [nodeID]);
}
function useNodeTransform(nodesRef: Ref<Node[]>, nodeID: Optional<NodeId>) {
  return useRefValue(useMemo(() => nodesRef.map(nodes => getNodeTransform(nodes, nodeID)).distinct(TransformFn.equals), [nodesRef, nodeID]));
}

function getNodeArea(node: Optional<Node>, index: number): Optional<Spline> {
  if (node?.type === "area") return node.data.areas[index];
  if (node?.type === "light" && node.data.shape?.type === "freeform") return node.data.shape.data.areas[index];
  return undefined;
}
function getNodeColor(node: Optional<Node>): HSLA {
  if (node?.type === "area") return [node.data.color[0], node.data.color[1], node.data.color[2], node.data.opacity] as HSLA;
  if (node?.type === "light" && node.data.shape?.type === "freeform") {
    const {color: [h,s,l,_], intensity} = node.data.shape.data;
    return [h,s,l,intensity / 2] as HSLA;
  }

  return Color.GREEN;
}


function AreaVertexNode({areaNode, nodes: nodesRef, selection}: {
  areaNode: Node,
  selection: AreaToolSelection;
  nodes: Ref<Node[]>;
}) {
  const nodeTransform = useNodeTransform(nodesRef, areaNode.data.id);

  const point = useMemo(() => {
    if (selection?.type !== "vertex") return undefined;
    const area = getNodeArea(areaNode, selection.areaIndex);
    if (area === undefined) return undefined;
    return selection.vertexIndex === 0 ? area.start : area.curves[selection.vertexIndex - 1].end;
  }, [areaNode, selection]);

  return <ModelProvider value={nodeTransform}>
    {point && <VertexSelectionIndicator origin={Vector2.subtract(point, areaNode.data.origin)} />}
  </ModelProvider>
}

function AreaEdgeNode({areaNode, nodes, selection}: {
  areaNode: Node,
  selection: AreaToolSelection;
  nodes: Ref<Node[]>;
}) {
  const area = getNodeArea(areaNode, selection.areaIndex);
  const nodeTransform = useNodeTransform(nodes, areaNode.data.id);

  const lines = useMemo(() => {
    if (!area) return undefined;
    if (selection?.type !== "edge" && selection?.type !== "control-point") return undefined;
    const {edgeIndex} = selection;
    return WallLineFn.toLines(cubicBezier(
      edgeIndex === 0 ? area.start : area.curves[edgeIndex - 1].end,
      edgeIndex === area.curves.length ? area.start : area.curves[edgeIndex].end,
      edgeIndex === 0 ? area.controlPoint2 : area.curves[edgeIndex - 1].controlPoint2,
      edgeIndex === area.curves.length ? area.controlPoint1 : area.curves[edgeIndex].controlPoint1,
      8
    ), false, false, false, false, Color.WHITE);
  }, [area, selection]);

  const controlPoints = useMemo(() => {
    if (!area) return undefined;
    if (selection?.type !== "edge" && selection?.type !== "control-point") return undefined;
    const {edgeIndex} = selection;

    const start = edgeIndex === 0 ? area.start : area.curves[edgeIndex - 1].end;
    const cp1 = edgeIndex === 0 ? area.controlPoint2 : area.curves[edgeIndex - 1].controlPoint2;

    const end = edgeIndex === area.curves.length ? area.start : area.curves[edgeIndex].end;
    const cp2 = edgeIndex === area.curves.length ? area.controlPoint1 : area.curves[edgeIndex].controlPoint1;

    return [
      start,
      end,
      Vector2.add(start, cp1),
      Vector2.add(end, cp2)
    ];
  }, [area, selection]);

  return <ModelProvider value={nodeTransform}>
    {lines && <WallLineShader origin={areaNode.data.origin} lines={lines} scale={1} color={Color.WHITE} opacity={1} />}
    {controlPoints && <>
      <LineShader origin={areaNode.data.origin} start={controlPoints[0]} end={controlPoints[2]} scale={1} color={Color.BLACK75} />
      <LineShader origin={areaNode.data.origin} start={controlPoints[1]} end={controlPoints[3]} scale={1} color={Color.BLACK75} />
      <VertexSelectionIndicator origin={Vector2.subtract(controlPoints[2], areaNode.data.origin)} />
      <VertexSelectionIndicator origin={Vector2.subtract(controlPoints[3], areaNode.data.origin)} />
    </>}
  </ModelProvider>
}


function AreaAreaNode({areaNode, nodes: nodesRef, selection}: {
  areaNode: Node,
  selection: AreaToolSelection;
  nodes: Ref<Node[]>;
}) {
  const spline = getNodeArea(areaNode, selection.areaIndex);
  const nodeTransform = useNodeTransform(nodesRef, areaNode.data.id);
  return <ModelProvider value={nodeTransform}>
    {spline && (<AreaShader
      origin={areaNode?.data.origin ?? [0, 0]}
      spline={spline}
      scale={1}
      color={Color.WHITE25} />)}
  </ModelProvider>
}

export function AreaToolView({value, nodes}: {
  value: MutableRef<Tool, ToolOperation[]>;
  nodes: Ref<Node[]>;
}) {
  const areaTool = useComputedValue(value, value => value.type === "area" ? value.data : undefined);
  const areaNode = useAreaNode(nodes, areaTool?.nodeID);
  const nodeTransform = useNodeTransform(nodes, areaNode?.data.id);
  if (areaTool === undefined) return <></>
  if (areaTool.mode?.type === "create" && areaNode) {
    return <>{areaTool.mode.data && (<ModelProvider value={nodeTransform}>
      <AreaShader spline={areaTool.mode.data} origin={areaNode.data.origin} scale={1} color={getNodeColor(areaNode)} />
      <AreaShader spline={areaTool.mode.data} origin={areaNode.data.origin} scale={1} color={Color.WHITE25} />
    </ModelProvider>)}
    </>;
  } else if (areaTool.mode?.type === "select" && areaNode) {
    if (areaTool.mode.data?.type === "vertex") {
      return <AreaVertexNode areaNode={areaNode} selection={areaTool.mode.data} nodes={nodes} />
    } else if ((areaTool.mode.data?.type === "edge" || areaTool.mode.data?.type === "control-point")) {
      return <AreaEdgeNode areaNode={areaNode} selection={areaTool.mode.data} nodes={nodes}/>
    } else if (areaTool.mode.data?.type === "area") {
      return <AreaAreaNode areaNode={areaNode} selection={areaTool.mode.data} nodes={nodes}/>
    }
    return <></>
  } else {
    return <></>
  }
}

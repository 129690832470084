import {z} from "zod";
import {MathExpression} from "../../../../../../math/index.ts";
import {ConstantOperation, constantType, ObjectType, PropertyRef, StringOperation, stringType, Type} from "../../../../../../types/index.ts";
import {MathExpressionOperation, mathExpressionType} from "../../../../../../math/math-expression.ts";
import {Dnd5eProcessID} from "./dnd-5e-process-i-d.ts";
import {MutableRef} from "#common/ref";

export const Dnd5eProcessAdjustCustomResource = z.object({
  processID: Dnd5eProcessID,
  resource: z.string(),
  quantity: MathExpression
});
export type Dnd5eProcessAdjustCustomResource = z.infer<typeof Dnd5eProcessAdjustCustomResource>;

export const Dnd5eProcessAdjustCustomResourceOperation = z.discriminatedUnion("type", [
  z.object({type: z.literal("update-process-i-d"), operations: z.array(ConstantOperation)}),
  z.object({type: z.literal("update-resource"), operations: z.array(StringOperation)}),
  z.object({type: z.literal("update-quantity"), operations: z.array(MathExpressionOperation)})
]);
export type Dnd5eProcessAdjustCustomResourceOperation = z.infer<typeof Dnd5eProcessAdjustCustomResourceOperation>;

export const dnd5eProcessAdjustCustomResourceType: Type<Dnd5eProcessAdjustCustomResource, Dnd5eProcessAdjustCustomResourceOperation> = new ObjectType({
  processID: constantType,
  resource: stringType,
  quantity: mathExpressionType
});

export function Dnd5eProcessAdjustCustomResourceSignals(valueRef: MutableRef<Dnd5eProcessAdjustCustomResource, Dnd5eProcessAdjustCustomResourceOperation[]>) {
  return {
    resourceRef: PropertyRef<Dnd5eProcessAdjustCustomResource, Dnd5eProcessAdjustCustomResourceOperation, string, StringOperation>(
      value => value.resource,
      operations => [{type: "update-resource", operations}]
    )(valueRef),
    quantityRef: PropertyRef<Dnd5eProcessAdjustCustomResource, Dnd5eProcessAdjustCustomResourceOperation, MathExpression, MathExpressionOperation>(
      value => value.quantity,
      operations => [{type: "update-quantity", operations}]
    )(valueRef)
  }
}
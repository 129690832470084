import {useObservable} from "#lib/qlab/index.ts";
import {useUserID} from "#lib/auth/use-get-user-id.ts";
import {pipe} from "common/pipe";
import {distinct, map} from "common/observable";
import {useDatabase} from "../../../routes/game/model/store-context.tsx";
import {UserID} from "common/legends/index.ts";
import {QLabDatabase} from "common/qlab/index.ts";
import {useEditor} from "../../panel/nav/editor/editor-context.ts";

function isGameMaster(database: QLabDatabase, userID: UserID) {
  if (database.store?.type !== "game") return false;
  return (database.store.data.owner.id === userID || database.store.data.players[userID]?.gameMaster === true);
}

export function useIsGameMaster() {
  const database = useDatabase();
  const userID = useUserID()!;
  const editor = useEditor();

  return useObservable(pipe(
    database.observe,
    map(database => editor !== undefined && isGameMaster(database, userID)),
    distinct()
  ), editor !== undefined && isGameMaster(database.value, userID), [database, userID, editor]);
}

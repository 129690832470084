import {useDnd5eActionSource} from "./use-dnd-5e-action-source.ts";
import {Dnd5eActionSource} from "./dnd-5e-action-source.ts";
import {Optional} from "common/types/index.ts";
import {usePresent} from "../../../../../common/use-optional-signal.ts";
import {Dnd5eItemEditor} from "../../../../sheet/editor/dnd-5e/dnd-5e-item/dnd-5e-item-editor.tsx";
import {Dnd5eSpellEditor} from "../../../../sheet/editor/dnd-5e/dnd-5e-spell/dnd-5e-spell-editor.tsx";
import {Dnd5eFeatureEditor} from "../../../../sheet/editor/dnd-5e/dnd-5e-feature/dnd-5e-feature-editor.tsx";

export type ActionSourceEditorProps = {
  reference: Optional<Dnd5eActionSource>;
  onClose: () => void;
};

export function ActionSourceEditor({reference, onClose}: ActionSourceEditorProps) {
  const source = usePresent(useDnd5eActionSource(reference));
  if (reference?.type === "item" && source) {
    return <Dnd5eItemEditor value={source} onClose={onClose} />
  } else if (reference?.type === "spell" && source) {
    return <Dnd5eSpellEditor value={source} onClose={onClose} />
  } else if (reference?.type === "background" && source) {
    return <Dnd5eFeatureEditor value={source} onClose={onClose} />
  } else if (reference?.type === "race" && source) {
    return <Dnd5eFeatureEditor value={source} onClose={onClose} />
  } else if (reference?.type === "class" && source) {
    return <Dnd5eFeatureEditor value={source} onClose={onClose} />
  } else if (reference?.type === "feature" && source) {
    return <Dnd5eFeatureEditor value={source} onClose={onClose} />
  }

  return <></>;
}
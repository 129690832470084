import {z} from "zod";
import {
  Dnd5eActionTemplateConditionModifier,
  Dnd5eActionTemplateConditionModifierOperation,
  dnd5eActionTemplateConditionModifierType
} from "./dnd-5e-action-template-condition-modifier.ts";
import {MultiType, Type} from "../../../../../../types/index.ts";
import {
  Dnd5eActionTemplateSegmentAttackRollHitModifier,
  Dnd5eActionTemplateSegmentAttackRollHitModifierOperation,
  dnd5eActionTemplateSegmentAttackRollHitModifierType
} from "../segment/attack-roll/dnd-5e-action-template-segment-attack-roll-hit-modifier.ts";
import {
  Dnd5eActionTemplateSegmentRollExpressionModifier,
  Dnd5eActionTemplateSegmentRollExpressionModifierOperation,
  dnd5eActionTemplateSegmentRollExpressionType
} from "../segment/damage/dnd-5e-action-template-segment-roll-expression-modifier.ts";
import {
  Dnd5eActionTemplateSegmentSavingThrowDifficultyClassModifier,
  Dnd5eActionTemplateSegmentSavingThrowDifficultyClassModifierOperation,
  dnd5eActionTemplateSegmentSavingThrowDifficultyClassModifierType
} from "../segment/saving-throw/dnd-5e-action-template-segment-saving-throw-difficulty-class-modifier.ts";
import {
  Dnd5eActionTriggerActivationModifier,
  Dnd5eActionTriggerActivationModifierOperation,
  dnd5eActionTriggerActivationType
} from "./dnd-5e-action-trigger-activation-modifier.ts";
import {Dnd5eVariableModifier, Dnd5eVariableModifierOperation, dnd5eVariableModifierType} from "../../dnd-5e-modifier/dnd-5e-variable-modifier.ts";
import {generateDnd5eModifierID} from "../../dnd-5e-modifier/dnd-5e-modifier-i-d.ts";
import {Dnd5eProcessFn} from "../process/dnd-5e-process.ts";

export const Dnd5eActionTemplateModifier: z.ZodType<
  | {type: "action::attack-roll::hit-modifier", data: Dnd5eActionTemplateSegmentAttackRollHitModifier}
  | {type: "action::condition", data: Dnd5eActionTemplateConditionModifier}
  | {type: "action::roll::expression", data: Dnd5eActionTemplateSegmentRollExpressionModifier}
  | {type: "action::trigger::activation", data: Dnd5eActionTriggerActivationModifier}
  | {type: "action::saving-throw::difficulty-class", data: Dnd5eActionTemplateSegmentSavingThrowDifficultyClassModifier}
  | {type: "variable", data: Dnd5eVariableModifier}
> = z.lazy(() => z.discriminatedUnion("type", [
  z.object({type: z.literal("action::attack-roll::hit-modifier"), data: Dnd5eActionTemplateSegmentAttackRollHitModifier}),
  z.object({type: z.literal("action::condition"), data: Dnd5eActionTemplateConditionModifier}),
  z.object({type: z.literal("action::roll::expression"), data: Dnd5eActionTemplateSegmentRollExpressionModifier}),
  z.object({type: z.literal("action::saving-throw::difficulty-class"), data: Dnd5eActionTemplateSegmentSavingThrowDifficultyClassModifier}),
  z.object({type: z.literal("action::trigger::activation"), data: Dnd5eActionTriggerActivationModifier}),
  z.object({type: z.literal("variable"), data: Dnd5eVariableModifier})
]));
export type Dnd5eActionTemplateModifier = z.infer<typeof Dnd5eActionTemplateModifier>;

export const Dnd5eActionTemplateModifierOperation: z.ZodType<
  | {type: "action::attack-roll::hit-modifier", operations: Dnd5eActionTemplateSegmentAttackRollHitModifierOperation[]}
  | {type: "action::condition", operations: Dnd5eActionTemplateConditionModifierOperation[]}
  | {type: "action::roll::expression", operations: Dnd5eActionTemplateSegmentRollExpressionModifierOperation[]}
  | {type: "action::saving-throw::difficulty-class", operations: Dnd5eActionTemplateSegmentSavingThrowDifficultyClassModifierOperation[]}
  | {type: "action::trigger::activation", operations: Dnd5eActionTriggerActivationModifierOperation[]}
  | {type: "variable", operations: Dnd5eVariableModifierOperation[]}
> = z.lazy(() => z.discriminatedUnion("type", [
  z.object({type: z.literal("action::attack-roll::hit-modifier"), operations: z.array(Dnd5eActionTemplateSegmentAttackRollHitModifierOperation)}),
  z.object({type: z.literal("action::condition"), operations: z.array(Dnd5eActionTemplateConditionModifierOperation)}),
  z.object({type: z.literal("action::roll::expression"), operations: z.array(Dnd5eActionTemplateSegmentRollExpressionModifierOperation)}),
  z.object({type: z.literal("action::saving-throw::difficulty-class"), operations: z.array(Dnd5eActionTemplateSegmentSavingThrowDifficultyClassModifierOperation)}),
  z.object({type: z.literal("action::trigger::activation"), operations: z.array(Dnd5eActionTriggerActivationModifierOperation)}),
  z.object({type: z.literal("variable"), operations: z.array(Dnd5eVariableModifierOperation)})
]));
export type Dnd5eActionTemplateModifierOperation = z.infer<typeof Dnd5eActionTemplateModifierOperation>;

export type Dnd5eActionTemplateModifierTypes = {
  "action::attackRoll::hitModifier": Type<Dnd5eActionTemplateSegmentAttackRollHitModifier, Dnd5eActionTemplateSegmentAttackRollHitModifierOperation>;
  "action::condition": Type<Dnd5eActionTemplateConditionModifier, Dnd5eActionTemplateConditionModifierOperation>;
  "action::roll::expression": Type<Dnd5eActionTemplateSegmentRollExpressionModifier, Dnd5eActionTemplateSegmentRollExpressionModifierOperation>;
  "action::savingThrow::difficultyClass": Type<Dnd5eActionTemplateSegmentSavingThrowDifficultyClassModifier, Dnd5eActionTemplateSegmentSavingThrowDifficultyClassModifierOperation>;
  "action::trigger::activation": Type<Dnd5eActionTriggerActivationModifier, Dnd5eActionTriggerActivationModifierOperation>;
  "variable": Type<Dnd5eVariableModifier, Dnd5eVariableModifierOperation>;
};

export const dnd5eActionTemplateModifierType: Type<Dnd5eActionTemplateModifier, Dnd5eActionTemplateModifierOperation> = new MultiType<Dnd5eActionTemplateModifierTypes>({
  "action::attackRoll::hitModifier": dnd5eActionTemplateSegmentAttackRollHitModifierType,
  "action::condition": dnd5eActionTemplateConditionModifierType,
  "action::roll::expression": dnd5eActionTemplateSegmentRollExpressionType,
  "action::savingThrow::difficultyClass": dnd5eActionTemplateSegmentSavingThrowDifficultyClassModifierType,
  "action::trigger::activation": dnd5eActionTriggerActivationType,
  "variable": dnd5eVariableModifierType
}, v => {
  if (v?.type === "action::dice-roll::expression") return {...v, type: "action::roll::expression"};
  if (v.type === "action::damage::damage-modifier") {
    return {
      type: "action::roll::expression",
      data: {
        modifierID: v.data.modifierID,
        condition: v.data.condition,
        rollType: v.data.damageType,
        expression: v.data.expression
      }
    } satisfies Dnd5eActionTemplateModifier
  }
  return v;
});

export const Dnd5eActionTemplateModifierFn = {
  copyDnd5eActionTemplateModifier: (value: Dnd5eActionTemplateModifier): Dnd5eActionTemplateModifier => {
    switch (value.type) {
      case "variable": return {...value, data: {...value.data, modifierID: generateDnd5eModifierID()}};
      case "action::saving-throw::difficulty-class": return {...value, data: {...value.data, modifierID: generateDnd5eModifierID()}};
      case "action::roll::expression": return {...value, data: {...value.data, modifierID: generateDnd5eModifierID()}};
      case "action::condition": return {...value, data: {...value.data, modifierID: generateDnd5eModifierID(), modifiers: value.data.modifiers.map(Dnd5eActionTemplateModifierFn.copyDnd5eActionTemplateModifier)}};
      case "action::attack-roll::hit-modifier": return {...value, data: {...value.data, modifierID: generateDnd5eModifierID()}};
      case "action::trigger::activation": return {...value, data: {...value.data, modifierID: generateDnd5eModifierID(), processes: value.data.processes.map(Dnd5eProcessFn.copyProcess)}};
    }
  }
}
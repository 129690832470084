import {Button, ButtonBar} from "#lib/components/index.ts";
import {MutableRef} from "common/ref";
import {VerticalTextAlign} from "common/legends/node/index.ts";
import {ConstantOperation, ValueFn, ValueOperation} from "common/types/generic/index.ts";
import {useRefValue} from "#lib/signal/index.ts";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faObjectsAlignBottom, faObjectsAlignCenterVertical, faObjectsAlignTop} from "@awesome.me/kit-c9bc6845cd/icons/classic/solid";
import {Field, FieldLabel} from "#lib/components/panel-header.tsx";

export function InputVerticalAlignment({value}: {value: MutableRef<VerticalTextAlign, ValueOperation<VerticalTextAlign, ConstantOperation>[]>}) {
  const currentValue = useRefValue(value);

  const onSetTop = () => value.apply(prev => ValueFn.set(prev, "top"));
  const onSetCenter = () => value.apply(prev => ValueFn.set(prev, "middle"));
  const onSetBottom = () => value.apply(prev => ValueFn.set(prev, "bottom"));

  return (
    <Field>
      <FieldLabel>Vertical Alignment</FieldLabel>
      <ButtonBar className="mx-2 rounded-md overflow-hidden">
        <Button className="flex-1" variant={currentValue === "top" ? "primary" : undefined} title="Align Top" onClick={onSetTop}><FontAwesomeIcon icon={faObjectsAlignTop} /> Top</Button>
        <Button className="flex-1" variant={currentValue === "middle" ? "primary" : undefined} title="Align Middle" onClick={onSetCenter}><FontAwesomeIcon icon={faObjectsAlignCenterVertical} /> Middle</Button>
        <Button className="flex-1" variant={currentValue === "bottom" ? "primary" : undefined} title="Align Bottom" onClick={onSetBottom}><FontAwesomeIcon icon={faObjectsAlignBottom} /> Bottom</Button>
      </ButtonBar>
    </Field>
  );
}
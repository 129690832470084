import ReactReconciler, {HostConfig} from "react-reconciler";
import type {WebGL2Props, WebGL2Type} from "../web-g-l-2-types.d.ts";
import {WebGL2Container} from "./web-g-l-2-container.ts";
import {WebGL2Node} from "./web-g-l-2-node.ts";
import {WebGL2Program} from "./web-g-l-2-program.ts";
import {WebGL2DrawElements} from "./web-g-l-2-draw-elements.ts";
import {WebGL2DrawArrays} from "./web-g-l-2-draw-arrays.ts";
import {WebGL2ArrayBuffer} from "./web-g-l-2-buffer.ts";
import {WebGL2VertexArray} from "./web-g-l-2-vertex-array.ts";
import {WebGL2Viewport} from "./web-g-l-2-viewport.ts";
import {WebGL2Texture2D} from "./web-g-l-2-texture-2-d.ts";
import {WebGL2ActiveTexture} from "./web-g-l-2-active-texture.ts";
import {WebGL2UniformMat4FV} from "./web-g-l-2-uniform-mat-4-f-v.ts";
import {WebGL2ElementArrayBuffer} from "./web-g-l-2-element-array-buffer.ts";
import {WebGL2Binder} from "./web-g-l-2-binder.ts";
import {WebGL2Action} from "./web-g-l-2-action.ts";
import {WebGL2Uniform2FV} from "./web-g-l-2-uniform-2-f-v.ts";
import {WebGL2Uniform1I} from "./web-g-l-2-uniform-1-i.ts";
import {WebGL2Uniform1F} from "./web-g-l-2-uniform-1-f.ts";
import {WebGL2Uniform4FV} from "./web-g-l-2-uniform-4-f-v.ts";
import {WebGL2Framebuffer} from "#lib/gl-react/reconciler/web-g-l-2-framebuffer.ts";
import {DefaultEventPriority} from "react-reconciler/constants.js";
import {WebGL2Uniform3FV} from "#lib/gl-react/reconciler/web-g-l-2-uniform-3-f-v.ts";

type Container = WebGL2Container;

export function getPublicInstance(instance: WebGL2Node): any {
  return instance;
}

export function getRootHostContext(rootContainerInstance: Container): any {
  return rootContainerInstance;
}

export function appendChild(parentInstance: WebGL2Node, child: WebGL2Node): void {
  parentInstance.appendChild(child);
}

export function appendChildToContainer(container: Container, child: WebGL2Node): void {
  container.appendChild(child);
}

export function appendInitialChild(parentInstance: WebGL2Node, child: WebGL2Node): void {
  parentInstance.appendChild(child);
}

export function insertBefore(parentInstance: WebGL2Node, child: WebGL2Node, beforeChild: WebGL2Node): void {
  parentInstance.insertBefore(child, beforeChild);
}

export function insertInContainerBefore(container: Container, child: WebGL2Node, beforeChild: WebGL2Node): void {
  container.insertBefore(child, beforeChild);
}

export function removeChild(parentInstance: WebGL2Node, child: WebGL2Node): void {
  parentInstance.removeChild(child);
}

export function removeChildFromContainer(container: Container, child: WebGL2Node): void {
  container.removeChild(child);
}

export function replaceContainerChildren(container: Container, newChildren: WebGL2Node[]): void {
  container.children = [];
  container.children.push(...newChildren);
}

export function clearContainer(container: Container): void {
  container.children = [];
}

export function canHydrateInstance(instance: any, type: WebGL2Type, props: WebGL2Props): null | WebGL2Node {
  return null;
}

export function  canHydrateTextInstance(instance: any, text: string): null {
  return null;
}

export function cloneInstance(instance: WebGL2Node, updatePayload: null | any, type: WebGL2Type, oldProps: WebGL2Props, newProps: WebGL2Props, internalInstanceHandle: ReactReconciler.OpaqueHandle, keepChildren: boolean, recyclableInstance: WebGL2Node): WebGL2Node {
  throw new Error("Unsupported.");
}

export function commitMount(instance: WebGL2Node, type: WebGL2Type, newProps: WebGL2Props, internalInstanceHandle: ReactReconciler.OpaqueHandle): void {
}

export function commitTextUpdate(textInstance: never, oldText: string, newText: string): void {
}

export function commitUpdate(instance: WebGL2Node, updatePayload: any, type: WebGL2Type, oldProps: WebGL2Props, newProps: WebGL2Props, internalInstanceHandle: ReactReconciler.OpaqueHandle): void {
  Object.keys(newProps).forEach(key => {
    if (key === "children") return;
    // @ts-ignore
    instance[key] = newProps[key];
  });
}

export function createContainerChildSet(container: Container): WebGL2Node[] {
  return container.children;
}

export function createInstance(type: WebGL2Type, props: WebGL2Props, rootContainerInstance: Container, hostContext: any, internalInstanceHandle: ReactReconciler.OpaqueHandle): WebGL2Node {
  const {context} = rootContainerInstance;
  switch (type) {
    case "program": {
      const program = new WebGL2Program(context);
      ["value"].forEach(key => {
        // @ts-ignore
        program[key] = props[key];
      });
      return program;
    }
    case "drawElements": {
      const drawElements = new WebGL2DrawElements(context);
      ["mode", "type", "count", "offset"].forEach(key => {
        // @ts-ignore
        drawElements[key] = props[key];
      });
      return drawElements;
    }
    case "drawArrays": {
      const drawArrays = new WebGL2DrawArrays(context);
      ["mode", "first", "count"].forEach(key => {
        // @ts-ignore
        drawArrays[key] = props[key];
      });
      return drawArrays;
    }
    case "arrayBuffer": {
      const arrayBuffer = new WebGL2ArrayBuffer(context);
      ["value"].forEach(key => {
        // @ts-ignore
        arrayBuffer[key] = props[key];
      });
      return arrayBuffer;
    }
    case "vertexArray": {
      const vertexArray = new WebGL2VertexArray(context);
      ["value"].forEach(key => {
        // @ts-ignore
        vertexArray[key] = props[key];
      });
      return vertexArray;
    }
    case "elementArrayBuffer": {
      const elementArray = new WebGL2ElementArrayBuffer(context);
      ["value"].forEach(key => {
        // @ts-ignore
        elementArray[key] = props[key];
      });
      return elementArray;
    }
    case "framebuffer": {
      const framebuffer = new WebGL2Framebuffer(context);
      ["value"].forEach(key => {
        // @ts-ignore
        framebuffer[key] = props[key];
      });
      return framebuffer;
    }
    case "texture2d": {
      const texture2d = new WebGL2Texture2D(context);
      ["value"].forEach(key => {
        // @ts-ignore
        texture2d[key] = props[key];
      });
      return texture2d;
    }
    case "viewport": {
      const viewport = new WebGL2Viewport(context);
      ["x", "y", "width", "height"].forEach(key => {
        // @ts-ignore
        viewport[key] = props[key];
      });
      return viewport;
    }
    case "activeTexture": {
      const activeTexture = new WebGL2ActiveTexture(context);
      ["texture"].forEach(key => {
        // @ts-ignore
        activeTexture[key] = props[key];
      });
      return activeTexture;
    }
    case "uniformMat4fv": {
      const uniformMat4fv = new WebGL2UniformMat4FV(context);
      ["location", "transpose", "data", "srcOffset", "srcLength"].forEach(key => {
        // @ts-ignore
        uniformMat4fv[key] = props[key];
      });
      return uniformMat4fv;
    }
    case "uniform2fv": {
      const uniform2fv = new WebGL2Uniform2FV(context);
      ["location", "data", "srcOffset", "srcLength"].forEach(key => {
        // @ts-ignore
        uniform2fv[key] = props[key];
      });
      return uniform2fv;
    }
    case "uniform3fv": {
      const uniform3fv = new WebGL2Uniform3FV(context);
      ["location", "data", "srcOffset", "srcLength"].forEach(key => {
        // @ts-ignore
        uniform3fv[key] = props[key];
      });
      return uniform3fv;
    }
    case "uniform4fv": {
      const uniform4fv = new WebGL2Uniform4FV(context);
      ["location", "data", "srcOffset", "srcLength"].forEach(key => {
        // @ts-ignore
        uniform4fv[key] = props[key];
      });
      return uniform4fv;
    }
    case "uniform1i": {
      const uniform1i = new WebGL2Uniform1I(context);
      ["location", "data"].forEach(key => {
        // @ts-ignore
        uniform1i[key] = props[key];
      });
      return uniform1i;
    }
    case "uniform1f": {
      const uniform1f = new WebGL2Uniform1F(context);
      ["location", "data"].forEach(key => {
        // @ts-ignore
        uniform1f[key] = props[key];
      });
      return uniform1f;
    }
    case "binder": {
      const binder = new WebGL2Binder(context);
      ["onBind", "onUnbind"].forEach(key => {
        // @ts-ignore
        binder[key] = props[key];
      });
      return binder;
    }
    case "action": {
      const action = new WebGL2Action(context);
      ["onAction"].forEach(key => {
        // @ts-ignore
        action[key] = props[key];
      });
      return action;
    }
    default: {
      throw new Error("Unsupported type.");
    }
  }
}

export function createTextInstance(text: string, rootContainerInstance: Container, hostContext: any, internalInstanceHandle: ReactReconciler.OpaqueHandle): never {
  throw new Error("Text Node not supported.");
}

export function didNotFindHydratableContainerInstance(parentContainer: Container, type: WebGL2Type, props: WebGL2Props): void {}
export function didNotFindHydratableContainerTextInstance(parentContainer: Container, text: string): void {}
export function didNotFindHydratableInstance(parentType: WebGL2Type, parentProps: WebGL2Props, parentInstance: WebGL2Node, type: WebGL2Type, props: WebGL2Props): void {}
export function didNotFindHydratableTextInstance(parentType: WebGL2Type, parentProps: WebGL2Props, parentInstance: WebGL2Node, text: string): void {}
export function didNotHydrateContainerInstance(parentContainer: Container, instance: WebGL2Node): void {}
export function didNotHydrateInstance(parentType: WebGL2Type, parentProps: WebGL2Props, parentInstance: WebGL2Node, instance: WebGL2Node): void {}
export function didNotMatchHydratedContainerTextInstance(parentContainer: Container, textInstance: never, text: string): void {}
export function didNotMatchHydratedTextInstance(parentType: WebGL2Type, parentProps: WebGL2Props, parentInstance: WebGL2Node, textInstance: never, text: string): void {}
export function finalizeContainerChildren(container: Container, newChildren: any): void {}
export function finalizeInitialChildren(parentInstance: WebGL2Node, type: WebGL2Type, props: WebGL2Props, rootContainerInstance: Container, hostContext: any): boolean {return false;}
export function getChildHostContext(parentHostContext: any, type: WebGL2Type, rootContainerInstance: Container): any {return parentHostContext;}
export function getFirstHydratableChild(parentInstance: WebGL2Node | Container): null | any {return undefined;}
export function getNextHydratableSibling(instance: WebGL2Node | any): null | any {return undefined;}
export function hydrateInstance(instance: WebGL2Node, type: WebGL2Type, props: WebGL2Props, rootContainerInstance: Container, hostContext: any, internalInstanceHandle: ReactReconciler.OpaqueHandle): null | any {return undefined;}
export function hydrateTextInstance(textInstance: never, text: string, internalInstanceHandle: ReactReconciler.OpaqueHandle): boolean {return false;}
export const isPrimaryRenderer = false;
export const noTimeout = null;
export function prepareForCommit(containerInfo: Container): null {return null;}
export function prepareUpdate(instance: WebGL2Node, type: WebGL2Type, oldProps: WebGL2Props, newProps: WebGL2Props, rootContainerInstance: Container, hostContext: any): null | any {
  return {};
}
export function resetAfterCommit(containerInfo: Container): void {
  cancelAnimationFrame(containerInfo.handle);
  containerInfo.handle = requestAnimationFrame(() => {
    containerInfo.visit();
  });
}
export function resetTextContent(instance: WebGL2Node): void {
}
export function setTimeout(handler: (...args: any[]) => void, timeout: number): NodeJS.Timeout {
    return setTimeout(handler, timeout);
}
export function clearTimeout(handle: NodeJS.Timeout): void {
    clearTimeout(handle);
}
export function shouldDeprioritizeSubtree(type: WebGL2Type, props: WebGL2Props): boolean {return false;}
export function shouldSetTextContent(type: WebGL2Type, props: WebGL2Props): boolean {return false;}
export const supportsMutation = true;
export const supportsPersistence = true;
export const supportsHydration = false;
export function cancelDeferredCallback(callbackID: any): void {clearTimeout(callbackID);}
export function now(): number {return performance.now();}
export function scheduleDeferredCallback(callback: () => any, options?: { timeout: number }): any { return setTimeout(callback, options?.timeout || 0);}
export function preparePortalMount(containerInfo: Container) {}
export function scheduleTimeout(fn: (...args: unknown[]) => unknown, delay?: number): NodeJS.Timeout {return setTimeout(fn, delay ?? 0);}
export function cancelTimeout(id: NodeJS.Timeout) {clearTimeout(id);}
export function afterActiveInstanceBlur() {}
export function beforeActiveInstanceBlur() {}
export function canHydrateSuspenseInstance(instance: any): any {}
export function cloneHiddenInstance(instance: WebGL2Node, type: WebGL2Type, props: WebGL2Props, internalInstanceHandle: ReactReconciler.OpaqueHandle): WebGL2Node {throw new Error("unsupported");}
export function cloneHiddenTextInstance(instance: WebGL2Node, text: WebGL2Type, internalInstanceHandle: ReactReconciler.OpaqueHandle): never {throw new Error("Unsupported")}

export function getCurrentEventPriority() {return DefaultEventPriority;}
export function getInstanceFromNode() {return null;}
export function prepareScopeUpdate() {}
export function getInstanceFromScope() {return null;}
export function detachDeletedInstance() {}

import {defaultLocalNode, LocalNode, LocalNodeOperation, LocalNodeSignals, localNodeTypePropTypes} from "../local-node.ts";
import {LightShape, LightShapeOperation, lightShapeType} from "./light-shape.ts";
import {ConstantOperation, constantType, NumberOperation, numberType, ObjectType, Optional, OptionalType, Type, ValueOperation, ValueType} from "../../../types/index.ts";
import {MutableRef} from "#common/ref";
import {SenseID} from "../../game/sense/sense-i-d.js";


export type LightNode = LocalNode & {
  accessMask: Optional<SenseID>;
  shape: Optional<LightShape>;
  blinkInterval: number;
  blinkLength: number;
  blinkOffset: number;
};
export type LightNodeOperation =
  | LocalNodeOperation
  | {type: "update-access-mask", operations: ValueOperation<Optional<SenseID>, ConstantOperation>[]}
  | {type: "update-shape", operations: ValueOperation<Optional<LightShape>, LightShapeOperation>[] }
  | {type: "update-blink-interval", operations: NumberOperation[]}
  | {type: "update-blink-length", operations: NumberOperation[]}
  | {type: "update-blink-offset", operations: NumberOperation[]}
  ;

export type LightNodeType = Type<LightNode, LightNodeOperation>;

export const lightNodeType: LightNodeType = new ObjectType(() => ({
  ...localNodeTypePropTypes(),
  accessMask: new ValueType(constantType),
  shape: new ValueType(new OptionalType(lightShapeType)),
  blinkInterval: numberType,
  blinkLength: numberType,
  blinkOffset: numberType
}), value => {
  if (Array.isArray(value.accessMask)) {
    value.accessMask = value.accessMask.length === 0 || value.accessMask[0] === "ALL" ? undefined : value.accessMask[0];
  }
  if (value.blinkInterval === undefined) value.blinkInterval = 1;
  if (value.blinkLength === undefined) value.blinkLength = 1000;
  if (value.blinkOffset === undefined) value.blinkOffset = 0;
  return value;
});

export function defaultLightNode(): LightNode {
  return {
    ...defaultLocalNode(),
    name: "Light",
    accessMask: undefined,
    selectionMask: ["GM"],
    shape: undefined,
    blinkInterval: 1,
    blinkLength: 1000,
    blinkOffset: 0
  }
}

export function LightNodeRef(valueRef: MutableRef<LightNode, LightNodeOperation[]>) {
  return {
    ...LocalNodeSignals(valueRef),
    accessMaskRef: valueRef.map<Optional<SenseID>, ValueOperation<Optional<SenseID>, ConstantOperation>[]>(value => value.accessMask, (_, operations) => [{type: "update-access-mask", operations}]).distinct(),
    shapeRef: valueRef.map<Optional<LightShape>, ValueOperation<Optional<LightShape>, LightShapeOperation>[]>(value => value.shape, (_, operations) => [{type: "update-shape", operations}]).distinct(),
    blinkIntervalRef: valueRef.map<number, NumberOperation[]>(value => value.blinkInterval, (_, operations) => [{type: "update-blink-interval", operations}]),
    blinkLengthRef: valueRef.map<number, NumberOperation[]>(value => value.blinkLength, (_, operations) => [{type: "update-blink-length", operations}]),
    blinkOffsetRef: valueRef.map<number, NumberOperation[]>(value => value.blinkOffset, (_, operations) => [{type: "update-blink-offset", operations}])
  }
}

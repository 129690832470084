import {ColorOperation, colorType, HSLA, NumberOperation, numberType, ObjectType, Type} from "../../../types/index.ts";
import {MutableRef} from "#common/ref";
import {z} from "zod";

export const LightShapeGlobal = z.object({
  intensity: z.number(),
  color: HSLA
});
export type LightShapeGlobal = z.infer<typeof LightShapeGlobal>;
export const LightShapeGlobalOperation = z.discriminatedUnion("type", [
  z.object({type: z.literal("update-intensity"), operations: z.array(NumberOperation)}),
  z.object({type: z.literal("update-color"), operations: z.array(ColorOperation)})
]);
export type LightShapeGlobalOperation = z.infer<typeof LightShapeGlobalOperation>;

export type LightShapeGlobalType = Type<LightShapeGlobal, LightShapeGlobalOperation>;

export const lightShapeGlobalType: LightShapeGlobalType = new ObjectType({
  intensity: numberType,
  color: colorType
});

export function LightShapeGlobalRef(valueRef: MutableRef<LightShapeGlobal, LightShapeGlobalOperation[]>) {
  return {
    intensityRef: valueRef.map<number, NumberOperation[]>(value => value.intensity, (_, operations) => [{type: "update-intensity", operations}]),
    colorRef: valueRef.map<HSLA, ColorOperation[]>(value => value.color, (_, operations) => [{type: "update-color", operations}])
  }
}
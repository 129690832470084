import {ApplyAction, useInstance} from "#lib/qlab/index.ts";
import {useCallback} from "react";
import {Node, NodeId, NodeOperation} from "common/legends/index.ts";
import {Tree, TreeOperation, TreePath, ValueFn} from "common/types/index.ts";
import {useStoreID} from "../../../routes/game/model/store-context.tsx";
import {useGetNodePath} from "./use-get-node-path.ts";

export function useSendNodeToFront() {
  const gameID = useStoreID();
  const instance = useInstance();
  const getNodePath = useGetNodePath();
  return useCallback(async (nodeID: NodeId) => {
    const nodeLocation = await getNodePath(nodeID);

    if (nodeLocation?.type === "scene") {
      const applyToNodes = (fn: ApplyAction<Node[], TreeOperation<Node, NodeOperation>[]>) => instance.applyToResource(gameID, nodeLocation.sceneID, prev => {
        if (prev?.type !== "scene") return [];
        return ValueFn.apply([{type: "scene", operations: [{
          type: "update-children",
          operations: typeof fn === "function" ? fn(prev.data.children) : fn
        }]}]);
      });

      return applyToNodes(prev => {
        const fromPath = Tree.getPath(prev, node => node.data.id === nodeID);
        if (fromPath === undefined) return [];
        const [ancestor, _] = TreePath.splitParent(fromPath);
        const parentNode = Tree.getNode(prev, ancestor);
        const toPath = (parentNode === undefined) ? [0] : [...ancestor, 0];
        return [{type: "move", fromPath, toPath}];
      });
    }
  }, [getNodePath]);
}
import React, {PropsWithChildren, useContext, useMemo} from "react";
import {useRenderingContext} from "#lib/gl-react/index.ts";

export class ProgramManager {
  private shaders: WebGLShader[] = [];
  private programs: {[key: string]: WebGLProgram} = {};
  constructor(private readonly context: WebGL2RenderingContext) {}
  getProgram(vertexShader: WebGLShader, fragmentShader: WebGLShader): WebGLProgram {
    const vertexIndex = this.getShaderIndex(vertexShader);
    const fragmentIndex = this.getShaderIndex(fragmentShader);

    const programKey = `${vertexIndex}-${fragmentIndex}`;
    if (!this.programs[programKey]) {
      const program = this.context.createProgram();
      if (program === null) throw new Error("Cannot create program");
      this.context.attachShader(program, vertexShader);
      this.context.attachShader(program, fragmentShader);
      this.context.linkProgram(program);
      const programError = this.context.getProgramInfoLog(program);
      if (programError) throw new Error(programError);
      this.programs[programKey] = program;
    }
    return this.programs[programKey];
  }

  private getShaderIndex(shader: WebGLShader): number {
    let shaderIndex = this.shaders.indexOf(shader);
    if (shaderIndex === -1) {
      this.shaders.push(shader);
      return this.shaders.indexOf(shader);
    }
    return shaderIndex;
  }
}

const ProgramManagerContext = React.createContext<ProgramManager | undefined>(undefined);
export function ProgramManagerProvider({children}: PropsWithChildren<object>) {
  const context = useRenderingContext();
  const textureManager = useMemo(() => new ProgramManager(context), [context]);
  return <ProgramManagerContext.Provider value={textureManager}>
    {children}
  </ProgramManagerContext.Provider>
}

export function useProgramManager() {
  return useContext(ProgramManagerContext)!;
}

import {z} from "zod";
import {Dnd5eActionTemplateSegmentID} from "../dnd-5e-action-template-segment-i-d.ts";
import {Dnd5eAbilityCheckType} from "../../../dnd-5e-modifier/dnd-5e-ability-check-modifier.ts";
import {Dnd5eActionTemplateSegment, Dnd5eActionTemplateSegmentOperation, dnd5eActionTemplateSegmentType} from "../dnd-5e-action-template-segment.ts";
import {MathExpression} from "../../../../../../../math/index.ts";
import {
  Dnd5eActionTemplateSegmentAbilityCheckModifier,
  Dnd5eActionTemplateSegmentAbilityCheckModifierOperation,
  dnd5eActionTemplateSegmentAbilityCheckModifierType
} from "./dnd-5e-action-template-segment-ability-check-modifier.ts";
import {
  ConstantOperation,
  constantType,
  ListPropertyRef,
  ListType,
  ObjectType,
  Optional,
  SetOperation,
  SetPropertySignal,
  SetType,
  Type,
  ValueOperation,
  ValuePropertyRef,
  ValueType,
  ZodListOperation
} from "../../../../../../../types/index.ts";
import {DiceExpression} from "../../../../../../../dice/index.ts";
import {MutableRef} from "#common/ref";

export const Dnd5eActionTemplateSegmentAbilityCheck = z.lazy(() => z.object({
  actionSegmentID: Dnd5eActionTemplateSegmentID,
  abilityCheck: z.optional(Dnd5eAbilityCheckType),
  abilityCheckTypes: z.array(Dnd5eAbilityCheckType),
  expression: MathExpression,
  modifiers: z.array(Dnd5eActionTemplateSegmentAbilityCheckModifier),
  onSuccess: z.array(Dnd5eActionTemplateSegment),
  onFailure: z.array(Dnd5eActionTemplateSegment)
}));
export type Dnd5eActionTemplateSegmentAbilityCheck = z.infer<typeof Dnd5eActionTemplateSegmentAbilityCheck>;

export const Dnd5eActionTemplateSegmentAbilityCheckOperation = z.lazy(() => z.discriminatedUnion("type", [
  z.object({type: z.literal("update-action-segment-i-d"), operations: z.array(ConstantOperation)}),
  z.object({type: z.literal("update-ability-check"), operations: z.array(ValueOperation(z.optional(Dnd5eAbilityCheckType), ConstantOperation))}),
  z.object({type: z.literal("update-ability-check-types"), operations: z.array(SetOperation(Dnd5eAbilityCheckType))}),
  z.object({type: z.literal("update-expression"), operations: z.array(ValueOperation(DiceExpression, ConstantOperation))}),
  z.object({type: z.literal("update-modifiers"), operations: z.array(ZodListOperation(Dnd5eActionTemplateSegmentAbilityCheckModifier, Dnd5eActionTemplateSegmentAbilityCheckModifierOperation))}),
  z.object({type: z.literal("update-on-success"), operations: z.array(ZodListOperation(Dnd5eActionTemplateSegment, Dnd5eActionTemplateSegmentOperation))}),
  z.object({type: z.literal("update-on-failure"), operations: z.array(ZodListOperation(Dnd5eActionTemplateSegment, Dnd5eActionTemplateSegmentOperation))})
]));
export type Dnd5eActionTemplateSegmentAbilityCheckOperation = z.infer<typeof Dnd5eActionTemplateSegmentAbilityCheckOperation>;

export const dnd5eActionTemplateSegmentAbilityCheckType: Type<Dnd5eActionTemplateSegmentAbilityCheck, Dnd5eActionTemplateSegmentAbilityCheckOperation> = new ObjectType(() => ({
  actionSegmentID: constantType,
  abilityCheck: new ValueType(constantType),
  abilityCheckTypes: new SetType<Dnd5eAbilityCheckType>(),
  expression: new ValueType(constantType),
  modifiers: new ListType(dnd5eActionTemplateSegmentAbilityCheckModifierType),
  onSuccess: new ListType(dnd5eActionTemplateSegmentType),
  onFailure: new ListType(dnd5eActionTemplateSegmentType)
}));

export function Dnd5eActionTemplateSegmentAbilityCheckSignals(valueRef: MutableRef<Dnd5eActionTemplateSegmentAbilityCheck, Dnd5eActionTemplateSegmentAbilityCheckOperation[]>) {
  return {
    abilityCheckRef: ValuePropertyRef<Dnd5eActionTemplateSegmentAbilityCheck, Dnd5eActionTemplateSegmentAbilityCheckOperation, Optional<Dnd5eAbilityCheckType>, ConstantOperation>(
      value => value.abilityCheck,
      operations => [{type: "update-ability-check", operations}]
    )(valueRef),
    abilityCheckTypesRef: SetPropertySignal<Dnd5eActionTemplateSegmentAbilityCheck, Dnd5eActionTemplateSegmentAbilityCheckOperation, Dnd5eAbilityCheckType>(
      value => value.abilityCheckTypes,
      operations => [{type: "update-ability-check-types", operations}]
    )(valueRef),
    expressionRef: ValuePropertyRef<Dnd5eActionTemplateSegmentAbilityCheck, Dnd5eActionTemplateSegmentAbilityCheckOperation, DiceExpression, ConstantOperation>(
      value => DiceExpression.parse(value.expression),
      operations => [{type: "update-expression", operations}]
    )(valueRef),
    modifiersRef: ListPropertyRef<Dnd5eActionTemplateSegmentAbilityCheck, Dnd5eActionTemplateSegmentAbilityCheckOperation, Dnd5eActionTemplateSegmentAbilityCheckModifier, Dnd5eActionTemplateSegmentAbilityCheckModifierOperation>(
      value => value.modifiers,
      operations => [{type: "update-modifiers", operations}]
    )(valueRef),
    onSuccessRef: ListPropertyRef<Dnd5eActionTemplateSegmentAbilityCheck, Dnd5eActionTemplateSegmentAbilityCheckOperation, Dnd5eActionTemplateSegment, Dnd5eActionTemplateSegmentOperation>(
      value => value.onSuccess,
      operations => [{type: "update-on-success", operations}]
    )(valueRef),
    onFailureRef: ListPropertyRef<Dnd5eActionTemplateSegmentAbilityCheck, Dnd5eActionTemplateSegmentAbilityCheckOperation, Dnd5eActionTemplateSegment, Dnd5eActionTemplateSegmentOperation>(
      value => value.onFailure,
      operations => [{type: "update-on-failure", operations}]
    )(valueRef)
  }
}

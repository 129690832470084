import {Dnd5eCharacter} from "#common/legends/asset/sheet/dnd-5e/character/dnd-5e-character.ts";
import {Sheet} from "#common/legends/asset/sheet/sheet.ts";
import {MutableRef, Ref} from "#common/ref";
import {Optional} from "#common/types/index.ts";
import {Dnd5eAttributeFn} from "#common/legends/index.ts";
import {RollVariables} from "#common/qlab/index.ts";
import {Dnd5eStatBlock} from "#common/legends/asset/sheet/dnd-5e/dnd-5e-stat-block/index.ts";
import {getPactSlotLevel} from "#common/legends/asset/sheet/dnd-5e/character/get-max-spell-slots-by-classes.ts";
import {mapIdentity} from "#common/observable";
import {getActiveVariables} from "../dnd-5e-modifier-helper.ts";
import {Dnd5eFeature} from "../dnd-5e-feature/index.ts";

export function getDnd5eCharacterVariables(value: Dnd5eCharacter): RollVariables {
  const variables: RollVariables = {};
  variables["STR_SCORE"] = value.attributes.str;
  variables["DEX_SCORE"] = value.attributes.dex;
  variables["CON_SCORE"] = value.attributes.con;
  variables["INT_SCORE"] = value.attributes.int;
  variables["WIS_SCORE"] = value.attributes.wis;
  variables["CHA_SCORE"] = value.attributes.cha;
  variables["STR"] = Dnd5eAttributeFn.modifier(value.attributes.str);
  variables["DEX"] = Dnd5eAttributeFn.modifier(value.attributes.dex);
  variables["CON"] = Dnd5eAttributeFn.modifier(value.attributes.con);
  variables["INT"] = Dnd5eAttributeFn.modifier(value.attributes.int);
  variables["WIS"] = Dnd5eAttributeFn.modifier(value.attributes.wis);
  variables["CHA"] = Dnd5eAttributeFn.modifier(value.attributes.cha);

  variables["LEVEL"] = value.classes.reduce((a, b) => a + b.level, 0);
  variables["PB"] = Dnd5eCharacter.getProficiencyBonus(value);
  for (const clazz of value.classes) {
    variables[`${clazz.class}-LEVEL`.toUpperCase()] = clazz.level;
  }
  variables[`PACTLEVEL`.toUpperCase()] = Number.parseInt(getPactSlotLevel(value.classes));
  return variables;
}

export function getDnd5eStatBlockVariables(value: Dnd5eStatBlock): RollVariables {
  const variables: RollVariables = {};
  if (value.attributes) {
    variables["STR_SCORE"] = value.attributes.str || 10;
    variables["DEX_SCORE"] = value.attributes.dex || 10;
    variables["CON_SCORE"] = value.attributes.con || 10;
    variables["INT_SCORE"] = value.attributes.int || 10;
    variables["WIS_SCORE"] = value.attributes.wis || 10;
    variables["CHA_SCORE"] = value.attributes.cha || 10;

    variables["STR"] = Dnd5eAttributeFn.modifier(value.attributes.str || 10);
    variables["DEX"] = Dnd5eAttributeFn.modifier(value.attributes.dex || 10);
    variables["CON"] = Dnd5eAttributeFn.modifier(value.attributes.con || 10);
    variables["INT"] = Dnd5eAttributeFn.modifier(value.attributes.int || 10);
    variables["WIS"] = Dnd5eAttributeFn.modifier(value.attributes.wis || 10);
    variables["CHA"] = Dnd5eAttributeFn.modifier(value.attributes.cha || 10);
  }
  variables["PB"] = value.proficiencyBonus;
  return variables;
}

export function getSheetVariables(value: Optional<Sheet>): RollVariables {
  if (value?.type === "dnd-5e-character") {
    return getDnd5eCharacterVariables(value.data);
  } else if (value?.type === "dnd-5e-stat-block") {
    return getDnd5eStatBlockVariables(value.data);
  } else {
    return {};
  }
}

export function sheetVariablesSignal(sheetRef: Ref<Optional<Sheet>>, globalFeaturesRef: Ref<Dnd5eFeature[]>): Ref<RollVariables> {
  return MutableRef.all(sheetRef, globalFeaturesRef)
    .map(([sheet, globalFeatures]) => getActiveVariables(sheet, globalFeatures)).distinct(mapIdentity);
}
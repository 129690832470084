import {createRoot} from "react-dom/client";
import React from 'react';
import './index.css';
import App from './routes/App.tsx';
import {decrypt, encrypt, generateKey, sign, verify} from "common/crypto/index.ts";
import {DefaultQLabClient, DefaultQLabInstance} from "#lib/qlab/index.ts";
import {setupBeyond20} from "#lib/beyond20/beyond-20-api.ts";
import * as Sentry from "@sentry/react";

Sentry.init({
  dsn: "https://383b8c7f4eb5ad1b795a0bf8f757cc6e@o4507791222439936.ingest.us.sentry.io/4508180796801024",
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
  ],
  enabled: import.meta.env.PROD,
  tracesSampleRate: 1.0,
  tracePropagationTargets: ["localhost", /^https:\/\/vtt.legends\.app\//],
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
});


window.global ||= window;

const root = createRoot(document.getElementById('root')!);
root.render(<React.StrictMode><App /></React.StrictMode>);

// @ts-ignore
window.generateKey = generateKey;
// @ts-ignore
window.encrypt = encrypt;
// @ts-ignore
window.decrypt = decrypt;
// @ts-ignore
window.sign = sign;
// @ts-ignore
window.verify = verify;

// @ts-ignore
window.DefaultQLabClient = DefaultQLabClient;

// @ts-ignore
window.DefaultQLabInstance = DefaultQLabInstance;

setupBeyond20();

import React, {ForwardedRef, HTMLProps} from "react";
import {twMerge} from "tailwind-merge";

export const InputGroupIcon = React.forwardRef(function Label({className, ...props}: HTMLProps<HTMLLabelElement>, ref: ForwardedRef<HTMLLabelElement>): JSX.Element {
  return (
    <span ref={ref} className={twMerge(
      "flex shrink-0 items-center justify-center gap-1 py-2 group-focus-within:text-blue-400 w-10 h-10 basis-10 -mx-4 font-bold",
      props.disabled && "text-white/50",
      className
    )} {...props} />
  );
});

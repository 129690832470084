import {useCallback} from "react";
import {NodeId, SceneID} from "common/legends/index.ts";
import {Tree, TreeOperation} from "common/types/index.ts";
import {useInstance} from "#lib/qlab/index.ts";
import {toPromise} from "common/observable";
import {useStoreID} from "../../../routes/game/model/store-context.tsx";

export function useDeleteNode() {
  const instance = useInstance();
  const gameID = useStoreID();
  return useCallback(async (nodeID: NodeId) => {
    const store = await toPromise(instance.observe(gameID));
    const promises = [];
    for (const [resourceID, resource] of Object.entries(store.resources)) {
      if (resource?.type !== "scene") continue;
      const path = Tree.getPath(resource.data.children, node => node.data.id === nodeID);
      if (path === undefined) continue;

      promises.push(instance.applyToResource(gameID, resourceID as SceneID, prev => {
        if (prev?.type !== "scene") return [];
        const path = Tree.getPath(prev.data.children, node => node.data.id === nodeID);
        if (path === undefined) return [];
        const node = Tree.getNode(prev.data.children, path)!;

        return [{type: "apply", operations: [{type: "scene", operations: [{
          type: "update-children",
            operations: TreeOperation.delete(path, node)
          }]}]}]
      }));
    }
    return Promise.allSettled(promises);
  }, [instance, gameID]);
}

import {z} from "zod";
import {Dnd5eAbilityCheckModifier, Dnd5eAbilityCheckModifierOperation, dnd5eAbilityCheckModifierType} from "./dnd-5e-ability-check-modifier.ts";
import {MultiType, Type} from "#common/types/index.ts";
import {
  Dnd5eSavingThrowModifier,
  Dnd5eSavingThrowModifierOperation,
  dnd5eSavingThrowModifierType
} from "#common/legends/asset/sheet/dnd-5e/dnd-5e-modifier/dnd-5e-saving-throw-modifier.ts";
import {
  Dnd5eConditionImmunityModifier,
  Dnd5eConditionImmunityModifierOperation,
  dnd5eConditionImmunityModifierType
} from "#common/legends/asset/sheet/dnd-5e/dnd-5e-modifier/dnd-5e-condition-immunity-modifier.ts";
import {
  Dnd5eDamageResistanceModifier,
  Dnd5eDamageResistanceModifierOperation,
  dnd5eDamageResistanceModifierType
} from "#common/legends/asset/sheet/dnd-5e/dnd-5e-modifier/dnd-5e-damage-resistance-modifier.ts";
import {
  Dnd5eDamageImmunityModifier,
  Dnd5eDamageImmunityModifierOperation,
  dnd5eDamageImmunityModifierType
} from "#common/legends/asset/sheet/dnd-5e/dnd-5e-modifier/dnd-5e-damage-immunity-modifier.ts";
import {
  Dnd5eDamageVulnerabilityModifier,
  Dnd5eDamageVulnerabilityModifierOperation,
  dnd5eDamageVulnerabilityModifierType
} from "#common/legends/asset/sheet/dnd-5e/dnd-5e-modifier/dnd-5e-damage-vulnerability-modifier.ts";
import {
  Dnd5eDamageReductionModifier,
  Dnd5eDamageReductionModifierOperation,
  dnd5eDamageReductionModifierType
} from "#common/legends/asset/sheet/dnd-5e/dnd-5e-modifier/dnd-5e-damage-reduction-modifier.ts";
import {Dnd5eDamageThresholdModifier, dnd5eDamageThresholdModifierType} from "#common/legends/asset/sheet/dnd-5e/dnd-5e-modifier/dnd-5e-damage-threshold-modifier.ts";
import {Dnd5eTraitModifier, Dnd5eTraitModifierOperation, dnd5eTraitModifierType} from "#common/legends/asset/sheet/dnd-5e/dnd-5e-modifier/dnd-5e-trait-modifier.ts";
import {Dnd5eModifierID} from "#common/legends/asset/sheet/dnd-5e/dnd-5e-modifier/dnd-5e-modifier-i-d.ts";
import {
  Dnd5eArmorClassFormulaModifier,
  Dnd5eArmorClassFormulaModifierOperation,
  dnd5eArmorClassFormulaModifierType
} from "#common/legends/asset/sheet/dnd-5e/dnd-5e-modifier/dnd-5e-armor-class-formula-modifier.ts";
import {StatusIndicatorModifier, StatusIndicatorModifierOperation, statusIndicatorModifierType} from "#common/legends/asset/status-indicator/index.ts";
import {Dnd5eMaxHPModifier, Dnd5eMaxHPModifierOperation, dnd5eMaxHPModifierType} from "#common/legends/asset/sheet/dnd-5e/dnd-5e-modifier/dnd-5e-max-h-p-modifier.ts";
import {Dice} from "#common/dice/index.ts";
import {Dnd5eArmorClassModifier, Dnd5eArmorClassModifierOperation, dnd5eArmorClassModifierType} from "./dnd-5e-armor-class-modifier.ts";
import {Dnd5eVariableModifier, Dnd5eVariableModifierOperation, dnd5eVariableModifierType} from "./dnd-5e-variable-modifier.ts";
import {
  Dnd5eActionTemplateSegmentAttackRollHitModifier,
  Dnd5eActionTemplateSegmentAttackRollHitModifierOperation,
  dnd5eActionTemplateSegmentAttackRollHitModifierType
} from "../dnd-5e-action-definition/segment/attack-roll/dnd-5e-action-template-segment-attack-roll-hit-modifier.ts";
import {
  Dnd5eActionTemplateSegmentSavingThrowDifficultyClassModifier,
  Dnd5eActionTemplateSegmentSavingThrowDifficultyClassModifierOperation,
  dnd5eActionTemplateSegmentSavingThrowDifficultyClassModifierType
} from "../dnd-5e-action-definition/segment/saving-throw/dnd-5e-action-template-segment-saving-throw-difficulty-class-modifier.ts";
import {
  Dnd5eActionTemplateSegmentRollExpressionModifier,
  Dnd5eActionTemplateSegmentRollExpressionModifierOperation,
  dnd5eActionTemplateSegmentRollExpressionType
} from "../dnd-5e-action-definition/segment/damage/dnd-5e-action-template-segment-roll-expression-modifier.ts";

export const Dnd5eModifier = z.discriminatedUnion("type", [
  z.object({type: z.literal("ability-check"), data: Dnd5eAbilityCheckModifier}),
  z.object({type: z.literal("action::attack-roll::hit-modifier"), data: Dnd5eActionTemplateSegmentAttackRollHitModifier}),
  z.object({type: z.literal("action::saving-throw::difficulty-class"), data: Dnd5eActionTemplateSegmentSavingThrowDifficultyClassModifier}),
  z.object({type: z.literal("action::roll::expression"), data: Dnd5eActionTemplateSegmentRollExpressionModifier}),
  z.object({type: z.literal("saving-throw"), data: Dnd5eSavingThrowModifier}),
  z.object({type: z.literal("condition-immunity"), data: Dnd5eConditionImmunityModifier}),
  z.object({type: z.literal("damage-resistance"), data: Dnd5eDamageResistanceModifier}),
  z.object({type: z.literal("damage-immunity"), data: Dnd5eDamageImmunityModifier}),
  z.object({type: z.literal("damage-vulnerability"), data: Dnd5eDamageVulnerabilityModifier}),
  z.object({type: z.literal("damage-reduction"), data: Dnd5eDamageReductionModifier}),
  z.object({type: z.literal("damage-threshold"), data: Dnd5eDamageThresholdModifier}),
  z.object({type: z.literal("trait"), data: Dnd5eTraitModifier}),
  z.object({type: z.literal("armor-class-formula"), data: Dnd5eArmorClassFormulaModifier}),
  z.object({type: z.literal("armor-class"), data: Dnd5eArmorClassModifier}),
  z.object({type: z.literal("variable"), data: Dnd5eVariableModifier}),
  z.object({type: z.literal("status-indicator"), data: StatusIndicatorModifier}),
  z.object({type: z.literal("max-h-p"), data: Dnd5eMaxHPModifier})
]);
export type Dnd5eModifier = z.infer<typeof Dnd5eModifier>;

export const Dnd5eModifierOperation = z.discriminatedUnion("type", [
  z.object({type: z.literal("ability-check"), operations: z.array(Dnd5eAbilityCheckModifierOperation)}),
  z.object({type: z.literal("action::attack-roll::hit-modifier"), operations: z.array(Dnd5eActionTemplateSegmentAttackRollHitModifierOperation)}),
  z.object({type: z.literal("action::saving-throw::difficulty-class"), operations: z.array(Dnd5eActionTemplateSegmentSavingThrowDifficultyClassModifierOperation)}),
  z.object({type: z.literal("action::roll::expression"), operations: z.array(Dnd5eActionTemplateSegmentRollExpressionModifierOperation)}),
  z.object({type: z.literal("saving-throw"), operations: z.array(Dnd5eSavingThrowModifierOperation)}),
  z.object({type: z.literal("condition-immunity"), operations: z.array(Dnd5eConditionImmunityModifierOperation)}),
  z.object({type: z.literal("damage-resistance"), operations: z.array(Dnd5eDamageResistanceModifierOperation)}),
  z.object({type: z.literal("damage-immunity"), operations: z.array(Dnd5eDamageImmunityModifierOperation)}),
  z.object({type: z.literal("damage-vulnerability"), operations: z.array(Dnd5eDamageVulnerabilityModifierOperation)}),
  z.object({type: z.literal("damage-reduction"), operations: z.array(Dnd5eDamageReductionModifierOperation)}),
  z.object({type: z.literal("damage-threshold"), operations: z.array(Dnd5eDamageReductionModifierOperation)}),
  z.object({type: z.literal("trait"), operations: z.array(Dnd5eTraitModifierOperation)}),
  z.object({type: z.literal("armor-class-formula"), operations: z.array(Dnd5eArmorClassFormulaModifierOperation)}),
  z.object({type: z.literal("armor-class"), operations: z.array(Dnd5eArmorClassModifierOperation)}),
  z.object({type: z.literal("variable"), operations: z.array(Dnd5eVariableModifierOperation)}),
  z.object({type: z.literal("status-indicator"), operations: z.array(StatusIndicatorModifierOperation)}),
  z.object({type: z.literal("max-h-p"), operations: z.array(Dnd5eMaxHPModifierOperation)})
]);
export type Dnd5eModifierOperation = z.infer<typeof Dnd5eModifierOperation>;

export type Dnd5eModifierTypes = {
  abilityCheck: typeof dnd5eAbilityCheckModifierType,
  "action::attackRoll::hitModifier": typeof dnd5eActionTemplateSegmentAttackRollHitModifierType;
  "action::savingThrow::difficultyClass": typeof dnd5eActionTemplateSegmentSavingThrowDifficultyClassModifierType,
  "action::roll::expression": typeof dnd5eActionTemplateSegmentRollExpressionType,
  savingThrow: typeof dnd5eSavingThrowModifierType,
  conditionImmunity: typeof dnd5eConditionImmunityModifierType,
  damageResistance: typeof dnd5eDamageResistanceModifierType,
  damageImmunity: typeof dnd5eDamageImmunityModifierType,
  damageVulnerability: typeof dnd5eDamageVulnerabilityModifierType,
  damageReduction: typeof dnd5eDamageReductionModifierType,
  damageThreshold: typeof dnd5eDamageThresholdModifierType,
  trait: typeof dnd5eTraitModifierType,
  armorClassFormula: typeof dnd5eArmorClassFormulaModifierType,
  armorClass: typeof dnd5eArmorClassModifierType,
  variable: typeof dnd5eVariableModifierType,
  statusIndicator: typeof statusIndicatorModifierType,
  maxHP: typeof dnd5eMaxHPModifierType,
};

export const dnd5eModifierType: Type<Dnd5eModifier, Dnd5eModifierOperation> = new MultiType<Dnd5eModifierTypes>({
  abilityCheck: dnd5eAbilityCheckModifierType,
  "action::attackRoll::hitModifier": dnd5eActionTemplateSegmentAttackRollHitModifierType,
  "action::savingThrow::difficultyClass": dnd5eActionTemplateSegmentSavingThrowDifficultyClassModifierType,
  "action::roll::expression": dnd5eActionTemplateSegmentRollExpressionType,
  savingThrow: dnd5eSavingThrowModifierType,
  conditionImmunity: dnd5eConditionImmunityModifierType,
  damageResistance: dnd5eDamageResistanceModifierType,
  damageImmunity: dnd5eDamageImmunityModifierType,
  damageVulnerability: dnd5eDamageVulnerabilityModifierType,
  damageReduction: dnd5eDamageReductionModifierType,
  damageThreshold: dnd5eDamageThresholdModifierType,
  trait: dnd5eTraitModifierType,
  armorClassFormula: dnd5eArmorClassFormulaModifierType,
  armorClass: dnd5eArmorClassModifierType,
  variable: dnd5eVariableModifierType,
  statusIndicator: statusIndicatorModifierType,
  maxHP: dnd5eMaxHPModifierType
}, (v) => {
  if (v.type === "skill-proficiency") {
    return ({
      type: "ability-check",
      data: {
        modifierID: v.data.modifierID,
        expression: Dice.assertDiceExpression("0"),
        proficiency: v.data.proficiency,
        abilityChecks: v.data.skills,
        hasAdvantage: false,
        hasDisadvantage: false
      }
    }) satisfies Dnd5eModifier;
  }
  if (v.type === "tool-proficiency") {
    return ({
      type: "ability-check",
      data: {
        modifierID: v.data.modifierID,
        expression: Dice.assertDiceExpression("0"),
        proficiency: v.data.proficiency,
        abilityChecks: v.data.tools,
        hasAdvantage: false,
        hasDisadvantage: false
      }
    }) satisfies Dnd5eModifier;
  }
  if (v.type === "saving-throw-proficiency") {
    return ({
      type: "saving-throw",
      data: {
        modifierID: v.data.modifierID,
        expression: Dice.assertDiceExpression("0"),
        proficiency: v.data.proficiency,
        savingThrows: v.data.attributes,
        hasAdvantage: false,
        hasDisadvantage: false
      }
    }) satisfies Dnd5eModifier;
  }
  if (v.type === "difficulty-class") {
    return ({
      type: "action::saving-throw::difficulty-class",
      data: {
        modifierID: v.data.modifierID,
        condition: undefined,
        expression: v.data.expression
      }
    }) satisfies Dnd5eModifier
  }
  if (v.type === "attack-roll") {
    return ({
      type: "action::attack-roll::hit-modifier",
      data: {
        modifierID: v.data.modifierID,
        condition: undefined,
        expression: v.data.expression
      }
    }) satisfies Dnd5eModifier;
  }
  if (v.type === "variable-override") {
    return ({
      type: "variable",
      data: {
        modifierID: v.data.modifierID,
        name: v.data.name,
        expression: v.data.expression
      }
    }) satisfies Dnd5eModifier;
  }
  if (v.type === "damage-roll") {
    return ({
      type: "action::roll::expression",
      data: {
        modifierID: v.data.modifierID,
        rollType: v.data.damageType,
        expression: v.data.hitExpression
      }
    }) satisfies Dnd5eModifier;
  }
  return v;
});

export function getModifierID(value: Dnd5eModifier): Dnd5eModifierID {
  return value.data.modifierID;
}

import {NameField} from "../name-field.tsx";
import {VisibilityLayerField} from "../visibility-layer-field.tsx";
import {InputTransform} from "./transform/input-transform.tsx";
import {ConstantOperation, ValueOperation} from "common/types/index.ts";
import {useMemo} from "react";
import {WallNode, WallNodeOperation, WallNodeSignals} from "common/legends/node/wall-node.ts";
import {InputWallGraph} from "./wall-segment/input-wall-graph.tsx";
import {ColorField} from "#lib/components/input/color-field.tsx";
import {useRefValue} from "#lib/signal/index.ts";
import {OpacityField} from "../opacity-field.tsx";
import {MutableRef} from "common/ref";
import {SelectionRef} from "../../nav/editor/state/selection-ref.ts";
import {Panel, Section} from "#lib/components/panel-header.tsx";

export type WallNodePropertiesProps = {
  value: MutableRef<WallNode, WallNodeOperation[]>;
  reference: SelectionRef;
  pinned: MutableRef<SelectionRef, ValueOperation<SelectionRef, ConstantOperation>[]>;
};
export function WallNodeProperties({value, reference, pinned}: WallNodePropertiesProps) {
  const {id, name, visibilityLayer, transform, opacity, color} = useMemo(() => WallNodeSignals(value), [value]);

  return <div className="tab-content flex flex-col py-2 gap-1">
    <Panel title="Wall Properties">
      <Section>
        <NameField value={name} reference={reference} pinned={pinned} />
        <VisibilityLayerField value={visibilityLayer} />
        <InputTransform value={transform} />
        <ColorField label="Wall Color" value={color} />
        <OpacityField value={opacity}/>
        <InputWallGraph nodeID={useRefValue(id)} />
      </Section>
    </Panel>
  </div>
}
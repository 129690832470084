import {z} from "zod";
import {Dnd5eActionTemplateSegment, Dnd5eActionTemplateSegmentOperation, dnd5eActionTemplateSegmentType} from "../dnd-5e-action-template-segment.ts";
import {
  ConstantOperation,
  constantType,
  ListPropertyRef,
  ListType,
  ObjectType,
  PropertyRef,
  StringOperation,
  stringType,
  Type,
  ZodListOperation
} from "../../../../../../../types/index.ts";
import {Dnd5eActionTemplateSegmentID} from "../dnd-5e-action-template-segment-i-d.ts";
import {MutableRef} from "#common/ref";

export const Dnd5eActionTemplateSegmentSection = z.lazy(() => z.object({
  actionSegmentID: Dnd5eActionTemplateSegmentID,
  name: z.string(),
  segments: z.array(Dnd5eActionTemplateSegment)
}));
export type Dnd5eActionTemplateSegmentSection = z.infer<typeof Dnd5eActionTemplateSegmentSection>;

export const Dnd5eActionTemplateSegmentSectionOperation = z.lazy(() => z.discriminatedUnion("type", [
  z.object({type: z.literal("update-action-segment-i-d"), operations: z.array(ConstantOperation)}),
  z.object({type: z.literal("update-name"), operations: z.array(StringOperation)}),
  z.object({type: z.literal("update-segments"), operations: z.array(ZodListOperation(Dnd5eActionTemplateSegment, Dnd5eActionTemplateSegmentOperation))})
]));
export type Dnd5eActionTemplateSegmentSectionOperation = z.infer<typeof Dnd5eActionTemplateSegmentSectionOperation>;

export const dnd5eActionTemplateSegmentSectionType: Type<Dnd5eActionTemplateSegmentSection, Dnd5eActionTemplateSegmentSectionOperation> = new ObjectType(() => ({
  actionSegmentID: constantType,
  name: stringType,
  segments: new ListType(dnd5eActionTemplateSegmentType)
}));

export function Dnd5eActionTemplateSegmentSectionSignals(valueRef: MutableRef<Dnd5eActionTemplateSegmentSection, Dnd5eActionTemplateSegmentSectionOperation[]>) {
  return {
    nameRef: PropertyRef<Dnd5eActionTemplateSegmentSection, Dnd5eActionTemplateSegmentSectionOperation, string, StringOperation>(
      value => value.name,
      operations => [{type: "update-name", operations}]
    )(valueRef),
    segmentsRef: ListPropertyRef<Dnd5eActionTemplateSegmentSection, Dnd5eActionTemplateSegmentSectionOperation, Dnd5eActionTemplateSegment, Dnd5eActionTemplateSegmentOperation>(
      value => value.segments,
      operations => [{type: "update-segments", operations}]
    )(valueRef)
  };
}

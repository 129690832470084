import {ButtonBar, ButtonBarProps} from "./button/index.ts";
import {LabelHTMLAttributes, PropsWithChildren, ReactNode} from "react";
import {twMerge} from "tailwind-merge";
import {BaseComponent} from "#lib/components/BaseComponent.tsx";
import {useToggle} from "#lib/components/toggle/index.ts";

export function PanelHeader({children, className, ...props}: PropsWithChildren<ButtonBarProps>) {
  return (<ButtonBar {...props} className={twMerge("flex bg-zinc-900/50 py-2 items-center", className)}>
    <span className="px-4 text-h500">{children}</span>
  </ButtonBar>);
}
export function PanelBody({className, children}: PropsWithChildren<{className?: string}>) {
  return (<div className={twMerge("flex flex-col divide-y-2 divide-zinc-800/50", className)}>
    {children}
  </div>);
}

export function Panel({title, children}: PropsWithChildren<{
  title: string
}>) {
  return (
    <BaseComponent className="flex flex-col gap-0">
      <PanelHeader>{title}</PanelHeader>
      <PanelBody>{children}</PanelBody>
    </BaseComponent>
  );
}

export function SectionBody({className, children}: PropsWithChildren<{className?: string}>) {
  return (<div className={twMerge("flex flex-col gap-2 pb-2", className)}>
    {children}
  </div>);
}


export function SectionHeader({children, className, ...props}: PropsWithChildren<ButtonBarProps>) {
  return (<ButtonBar {...props} className={twMerge("flex", props.onClick && "cursor-pointer", className)}>
    <span className="flex-1 px-4 h-12 text-h400 items-center flex">{children}</span>
  </ButtonBar>);
}

export function Section({title, open = true, children, options}: PropsWithChildren<{
  open?: boolean,
  title?: ReactNode,
  options?: ReactNode
}>) {
  const [toggled, toggle] = useToggle(open);
  return (
    <div className="flex flex-col gap-2 py-2">
      {title && <SectionHeader>
        <span className="flex-1" onClick={toggle}>{title}</span>
        {options}
      </SectionHeader>}
      {toggled && <SectionBody>{children}</SectionBody>}
    </div>
  );
}




export function Field({children, className, ...props}: PropsWithChildren<LabelHTMLAttributes<HTMLLabelElement>>) {
  return (<label className={twMerge("flex flex-col gap-1", className)} {...props}>
    {children}
  </label>);
}

export function FieldLabel({children, className, ...props}: PropsWithChildren<LabelHTMLAttributes<HTMLLabelElement>>) {
  return (<span className={twMerge("px-4 py-1 text-sm font-bold", className)} {...props}>{children}</span>);
}


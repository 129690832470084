import {Point} from "common/types/index.ts";
import {Vector2} from "../vector/vector2.ts";
import {LineFn} from "./line.ts";

export function cubicBezier(p1: Point, p2: Point, controlPoint1: Vector2, controlPoint2: Vector2, resolution: number): Point[] {
  if (controlPoint2[0] === 0 && controlPoint2[1] === 0 && controlPoint1[0] === 0 && controlPoint1[1] === 0) {
    return [p1, p2];
  }
  const cp1 = Vector2.add(p1, controlPoint1);
  const cp2 = Vector2.add(p2, controlPoint2);
  let lastPoint = p1;
  let lines: Point[] = [];
  lines.push(lastPoint);
  for (let i = 1; i <= resolution; i ++) {
    const t = i / resolution;
    const a = Vector2.lerp(p1, cp1, t);
    const b = Vector2.lerp(cp1, cp2, t);
    const c = Vector2.lerp(cp2, p2, t);

    const d = Vector2.lerp(a, b, t);
    const e = Vector2.lerp(b, c, t);
    const f = Vector2.lerp(d, e, t);
    lines.push(f);
    lastPoint = f;
  }
  return lines;
}

export function lerpBezier(p1: Point, p2: Point, controlPoint1: Vector2, controlPoint2: Vector2, resolution: number, t: number): Point {
  const lines = cubicBezier(p1, p2, controlPoint1, controlPoint2, resolution);
  const totalLength = LineFn.distance(lines);
  const targetLength = totalLength * t;
  let acc = 0;
  for (let i = 0; i < lines.length - 1; i ++) {
    const dist = Vector2.distance(lines[i], lines[i + 1]);
    const remaining = targetLength - acc;
    if (remaining < dist) {
      const t = remaining / dist;
      return Vector2.lerp(lines[i], lines[i + 1], t);
    }
    acc += dist;
  }
  return lines[lines.length - 1];
}

export function perpendicularBezier(p1: Point, p2: Point, controlPoint1: Vector2, controlPoint2: Vector2, resolution: number, t: number) {
  const lines = cubicBezier(p1, p2, controlPoint1, controlPoint2, resolution);
  const totalLength = LineFn.distance(lines);
  const targetLength = totalLength * t;
  let acc = 0;
  for (let i = 0; i < lines.length - 1; i ++) {
    const dist = Vector2.distance(lines[i], lines[i+1]);
    const remaining = targetLength - acc;
    if (remaining < dist) {
      return Vector2.perpendicular(lines[i], lines[i+1]);
    }
    acc += dist;
  }
  return Vector2.perpendicular(lines[lines.length - 2], lines[lines.length - 1]);
}

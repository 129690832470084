import {
  AssetFn,
  AssetID,
  AssetTreeFile,
  AssetTreeFileOperation,
  AssetTreeFolder,
  AssetTreeFolderOperation,
  AssetTreeId,
  AssetTreeItem,
  GameSignals,
  generateAssetID,
  SceneTreeId,
  TokenID
} from "common/legends/index.ts";
import {ApplyAction, ConstantOperation, MapOperation, Optional, Tree, TreeOperation, ValueFn} from "common/types/index.ts";
import {AssetNavigator, AssetNavigatorOperation} from "./asset-navigator.tsx";
import {Breadcrumb, BreadcrumbItem, Button, ButtonBar, IconButton, Spacer, useToggle} from "#lib/components/index.ts";
import {FaCopy, FaDragon, FaEdit, FaFileExport, FaFolderPlus, FaHome, FaMap, FaPlus, FaTrash} from "react-icons/fa";
import {Suspense, useCallback, useMemo, useState} from "react";
import {CreateAssetModal} from "./create-asset-modal.tsx";
import {CreateFolderModal} from "./create-folder-modal.tsx";
import {TreeView} from "#lib/components/tree-view/index.ts";
import {useDeleteResource, useObservable} from "#lib/qlab/index.ts";
import {EditAssetModal} from "./edit-asset-modal.tsx";
import {EditFolderModal} from "./edit-folder-modal.tsx";
import {from} from "common/observable";
import {ImportButton} from "#lib/components/button/import-button.tsx";
import {useGame} from "../../../routes/game";
import {useNavigateToAssetEditor} from "../../viewport/character";
import {useExtractCharacter} from "../../common/scene/use-export.ts";
import {useImportFile} from "../../common/scene/use-import-file.ts";
import {legendsFileType} from "../../common/scene/legends-file.ts";
import {BaseComponent} from "#lib/components/BaseComponent.tsx";
import {ExpandOptions} from "#lib/components/expand-options.tsx";
import {useComputedValue, useRefValue} from "#lib/signal/index.ts";
import {getTreeItemSignal} from "common/types/generic/tree/get-tree-item-signal.ts";
import {Menu} from "@headlessui/react";
import {MutableRef} from "common/ref";
import {useAssetViewportProperties} from "../../viewport/character/use-asset-viewport-properties.ts";
import {EditorAssetOutlinerView} from "./editor-asset-outliner-view.tsx";
import {useDatabase} from "../../../routes/game/model/store-context.tsx";
import {FaLocationCrosshairs} from "react-icons/fa6";
import {AssetTokenSelector} from "./asset-token-selector.tsx";
import {useAssetName} from "../../common/asset/use-asset-name.ts";

function AssetBreadcrumbItem({assetID, onClick}: {assetID: AssetID, onClick: () => void}) {
  const name = useAssetName(assetID);
  return <BreadcrumbItem Icon={<FaMap />} onClick={onClick}>
    {name}
  </BreadcrumbItem>
}
function AssetTokenBreadcrumbItem({assetID, tokenID, onClick}: {assetID: AssetID, tokenID: TokenID, onClick: () => void}) {
  const databaseRef = useDatabase();
  const name = useComputedValue(databaseRef, database => {
    const asset = database.resources[assetID];
    if (asset?.type !== "asset") return "";
    const token = asset.data.tokens.find(token => token.tokenID === tokenID);
    return token?.name ?? "";
  }, [assetID, tokenID]);
  return <BreadcrumbItem Icon={<FaDragon />} onClick={onClick}>
    {name}
  </BreadcrumbItem>
}


export function AssetSelector({valueRef, navigateToAsset}: {
  navigateToAsset: (assetID: AssetID) => void;
  valueRef: MutableRef<AssetNavigator, AssetNavigatorOperation[]>;
}) {
  const value = useRefValue(valueRef);
  const apply = valueRef.apply;
  const expanded = useMemo(() => ({
    observe: from(value.expanded),
    apply: (fn: ApplyAction<{[id in string]?: boolean}, MapOperation<string, boolean, ConstantOperation>[]>) => {
      apply(prev => [{type: "update-expanded", operations: typeof fn === "function" ? fn(prev.expanded) : fn}])
    }
  }), [value.expanded, apply]);

  const game = useGame();
  const {assets} = useMemo(() => GameSignals(game), [game]);

  const isVisible = useCallback((_: AssetTreeItem) => true, []);

  const [isCreateAssetTreeFileOpen, toggleCreateAssetOpen] = useToggle(false);
  const [isCreateAssetTreeFolderOpen, toggleCreateFolderOpen] = useToggle(false);

  const createAsset = (item: AssetTreeFile) => {
    assets.apply((prev) => [{type: "insert", item: {type: "asset", data: item}, path: [prev.length]}]);
    toggleCreateAssetOpen();
    navigateToAsset(item.id);
  };
  const createFolder = (item: AssetTreeFolder) => {
    assets.apply((characters) => [{
      type: "insert",
      item: {type: "folder", data: item},
      path: [characters.length]
    }]);
    toggleCreateFolderOpen();
  };

  const toggleExpanded = useCallback((id: SceneTreeId) => {
    return expanded.apply(prev => {
      if (prev[id] === undefined) return [{type: "put", key: id, item: true}];
      if (prev[id] === false) {
        return [{type: "delete", key:id, item: false}, {type: "put", key: id, item: true}];
      } else return [{type: "delete", key:id, item: true}, {type: "put", key: id, item: false}];
    });
  }, [expanded.apply]);

  const deleteResource = useDeleteResource();

  const deleteItem = useCallback(async (itemId: AssetTreeId) => {
    const item = Tree.getItemById(assets.value, itemId);
    if (item === undefined) return;
    const recursiveDelete = async (item: AssetTreeItem): Promise<void> => {
      if (item.type === "folder") {
        await Promise.all(item.data.children.map(recursiveDelete));
      } else {
        await deleteResource("asset", item.data.id);
      }
    };

    await recursiveDelete(item);
    await assets.apply(prev => {
      const path = Tree.getPath(prev, item => item.data.id === itemId);
      if (path === undefined) return [];
      const prevItem = Tree.getNode(prev, path);
      return [{type: "delete", path, prevItem}];
    });
  }, [assets, deleteResource]);

  const navigateToCharacter = (item: AssetTreeItem) => {
    if (item && item.type === "asset") {
      navigateToAsset(item.data.id);
    }
  };

  const exportCharacter = useExtractCharacter();
  const exportFile = useCallback(async (itemId: AssetTreeId) => {
    const item = Tree.getItemById(assets.value, itemId);
    if (item?.type !== "asset") return;
    const file = await exportCharacter(item.data.id);
    const blob = new Blob([JSON.stringify(file, null, 2)]);
    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    link.setAttribute('download', `${item.data.name}.lvtt`);
    document.body.appendChild(link);
    link.click();
    link.parentNode!.removeChild(link);
  }, [assets]);

  const importFile = useImportFile();
  const resolvedExpanded = useObservable(expanded.observe, {}, [expanded.observe]);

  const getFileSignal = useCallback((id: AssetTreeId): MutableRef<AssetTreeFile, AssetTreeFileOperation[]> => {
    const itemSignal = getTreeItemSignal(assets, id);
    return itemSignal
      .map<AssetTreeFile, AssetTreeFileOperation[]>(
        (value) => {
          if (value?.type === "asset") return value.data;
          else throw new Error(`Unsupported type: ${value?.type}.`);
        },
        (prev, operations) => (prev?.type !== "asset") ? [] : [{type: "asset", operations}]
      );
  }, [assets]);

  const getFolderSignal = useCallback((id: AssetTreeId): MutableRef<AssetTreeFolder, AssetTreeFolderOperation[]> => {
    const itemSignal = getTreeItemSignal(assets, id);
    return itemSignal
      .map<AssetTreeFolder, AssetTreeFolderOperation[]>(
        (value) => {
          if (value?.type === "folder") return value.data;
          else throw new Error(`Unsupported type: ${value?.type}.`);
        },
        (prev, operations) => (prev?.type !== "folder") ? [] : [{type: "folder", operations}]
      );
  }, [assets]);

  const [editFileSignal, setEditFileSignal] = useState<MutableRef<AssetTreeFile, AssetTreeFileOperation[]> | undefined>(undefined);
  const [editFolderSignal, setEditFolderSignal] = useState<MutableRef<AssetTreeFolder, AssetTreeFolderOperation[]> | undefined>(undefined);
  const assetsValue = useRefValue(assets);
  const databaseRef = useDatabase();
  const duplicateAsset = useCallback(async (assetID: Optional<AssetID>) => {
    if (!assetID) return;
    const newAssetID = generateAssetID();
    const newAsset = (await databaseRef.apply(database => {
      const asset = database.resources[assetID];
      if (asset?.type !== "asset") return [];
      return [{type: "resource", resourceID: newAssetID, operations: ValueFn.set(undefined, {
        type: "asset",
        data: AssetFn.copyAsset(asset.data)
      })}];
    })).resources[assetID];
    if (!newAsset) return;

    assets.apply((prev) => {
      const assetItem = Tree.getItemById<AssetTreeItem>(prev, assetID);
      if (assetItem?.type !== "asset") return [];
      const item: AssetTreeFile = {
        id: newAssetID,
        name: `${assetItem.data.name} (Copy)`,
        tags: assetItem.data.tags
      };
      return TreeOperation.insert([prev.length], {type: "asset", data: item});
    });

    navigateToAsset(newAssetID);
  }, [databaseRef, assets]);

  return <>
    <ButtonBar>
      <Spacer/>
      <Button onClick={toggleCreateAssetOpen}><FaPlus /> Add Asset</Button>
      <ExpandOptions options={{placement: "right-start"}}>
          <Button onClick={toggleCreateFolderOpen}><FaFolderPlus /> Add Folder</Button>
          <ImportButton type={legendsFileType} onImport={importFile}>Import Asset</ImportButton>
      </ExpandOptions>
    </ButtonBar>

    <TreeView type="asset" items={assetsValue} isVisible={isVisible} move={(fromPath, toPath) => assets.apply(_ => [{type: "move", fromPath, toPath}])} expanded={resolvedExpanded} onExpand={toggleExpanded} onCollapse={toggleExpanded} ItemActions={(item) => {
      return <>
        {item.type === "asset" && <IconButton className="flex-grow-0" title={`Navigate to ${item.data.name}`} variant="primary" onClick={() => navigateToCharacter(item)}><FaMap /></IconButton>}
        <ExpandOptions options={{placement: "right-start"}}>
          {item.type === "folder" && <>
            <Menu.Item as={Button} title="Edit" onClick={() => setEditFolderSignal(getFolderSignal(item.data.id))} className="justify-start">
              <FaEdit /> Edit Folder
            </Menu.Item>
            <Menu.Item as={Button} title="Delete" variant="destructive" onClick={() => deleteItem(item.data.id)} className="justify-start">
              <FaTrash /> Delete Folder
            </Menu.Item>
          </>}
          {item.type === "asset" && <>
            <Menu.Item as={Button} className="justify-start" title="Export Asset" onClick={() => exportFile(item.data.id)}>
              <FaFileExport /> Export Asset
            </Menu.Item>
            <Menu.Item as={Button} className="justify-start" title="Edit Asset" onClick={() => setEditFileSignal(getFileSignal(item.data.id))}>
              <FaEdit /> Edit Asset
            </Menu.Item>
            <Menu.Item as={Button} className="justify-start" title="Edit Asset" onClick={() => duplicateAsset(item.data.id)}>
              <FaCopy /> Duplicate Asset
            </Menu.Item>
            <Menu.Item as={Button} className="justify-start" title="Delete Asset" variant="destructive" onClick={() => deleteItem(item.data.id)}>
              <FaTrash /> Delete Asset
            </Menu.Item>
          </>}
        </ExpandOptions>
      </>
    }} />

    {isCreateAssetTreeFileOpen && <CreateAssetModal onClose={toggleCreateAssetOpen} onCreate={createAsset} />}
    {isCreateAssetTreeFolderOpen && <CreateFolderModal onClose={toggleCreateFolderOpen} onCreate={createFolder} />}
    {editFileSignal && <EditAssetModal value={editFileSignal} onClose={() => setEditFileSignal(undefined)}/>}
    {editFolderSignal && <EditFolderModal value={editFolderSignal} onClose={() => setEditFolderSignal(undefined)}/>}
  </>
}

export function AssetNavigatorStackView({valueRef}: {
  valueRef: MutableRef<AssetNavigator, AssetNavigatorOperation[]>;
}) {
  const tokenReference = useComputedValue(useAssetViewportProperties(), assetViewportProperties => assetViewportProperties?.tokenReference);
  const [assetSelection, setAssetSelection] = useState(tokenReference === undefined);
  const [tokenSelection, setTokenSelection] = useState<Optional<AssetID>>(undefined);
  const databaseRef = useDatabase();
  const navigateToAssetToken = useNavigateToAssetEditor();

  return <div className="tab-content flex flex-col gap-1 py-2">
    <BaseComponent>
      <Breadcrumb>
        <BreadcrumbItem Icon={<FaHome/>} onClick={() => setAssetSelection(true)}>Assets</BreadcrumbItem>
        {(tokenReference && !assetSelection) && <AssetBreadcrumbItem assetID={tokenSelection ?? tokenReference.assetID} onClick={() => {
          setAssetSelection(false);
          setTokenSelection(tokenReference!.assetID);
        }} />}
        {(!assetSelection && !tokenSelection && tokenReference !== undefined) && <AssetTokenBreadcrumbItem assetID={tokenReference.assetID} tokenID={tokenReference.tokenID} onClick={() => {
          setTokenSelection(undefined);
          setAssetSelection(false);
        }} />}
        {(assetSelection || tokenSelection) && tokenReference !== undefined && <>
          <Spacer/>
          <BreadcrumbItem title={"Go to Current Scene"} Icon={<FaLocationCrosshairs/>} onClick={() => {
            setTokenSelection(undefined);
            setAssetSelection(false);
          }}/>
        </>}
      </Breadcrumb>

      {assetSelection && <AssetSelector navigateToAsset={(assetID) => {
        const asset = databaseRef.value.resources[assetID];
        if (asset?.type !== "asset") return;
        if (asset.data.tokens.length === 1) {
          navigateToAssetToken(assetID);
          setTokenSelection(undefined);
          setAssetSelection(false);
        } else {
          setTokenSelection(assetID);
          setAssetSelection(false);
        }
      }} valueRef={valueRef} />}
      {!assetSelection && tokenSelection && <AssetTokenSelector assetID={tokenSelection} navigateToAssetToken={(assetID, tokenID) => {
        navigateToAssetToken(assetID, tokenID);
        setTokenSelection(undefined);
        setAssetSelection(false);
      }} />}
      {!assetSelection && !tokenSelection && <Suspense fallback={<></>}><EditorAssetOutlinerView valueRef={valueRef} /></Suspense>}
    </BaseComponent>
  </div>
}
import {z} from "zod";
import {Dnd5eModifierID} from "../../../dnd-5e-modifier/dnd-5e-modifier-i-d.ts";
import {BooleanOperation, booleanType, ConstantOperation, constantType, ObjectType, Type} from "../../../../../../../types/index.ts";

export const Dnd5eActionTemplateSegmentSavingThrowProficientOverrideModifier: z.ZodType<{
  modifierID: string,
  proficient: boolean
}> = z.object({
  modifierID: Dnd5eModifierID,
  proficient: z.boolean()
});
export type Dnd5eActionTemplateSegmentSavingThrowProficientOverrideModifier = z.infer<typeof Dnd5eActionTemplateSegmentSavingThrowProficientOverrideModifier>;
export const Dnd5eActionTemplateSegmentSavingThrowProficientOverrideModifierOperation = z.discriminatedUnion("type", [
  z.object({type: z.literal("update-modifier-i-d"), operations: z.array(ConstantOperation)}),
  z.object({type: z.literal("update-proficient"), operations: z.array(BooleanOperation)})
]);
export type Dnd5eActionTemplateSegmentSavingThrowProficientOverrideModifierOperation = z.infer<typeof Dnd5eActionTemplateSegmentSavingThrowProficientOverrideModifierOperation>;
export const dnd5eActionTemplateSegmentSavingThrowProficientOverrideModifierType: Type<Dnd5eActionTemplateSegmentSavingThrowProficientOverrideModifier, Dnd5eActionTemplateSegmentSavingThrowProficientOverrideModifierOperation> = new ObjectType({
  modifierID: constantType,
  proficient: booleanType
});


import {ShapeNode} from "common/legends/index.ts";
import React from "react";
import {Transform} from "common/types/index.ts";
import {ElementRenderPass} from "./element-render-pass.tsx";
import {RectangleShader} from "../../shader/shape/rectangle-shader.tsx";
import {ConeShader} from "../../shader/shape/cone-shader.tsx";
import {ArcShader} from "../../shader/shape/arc-shader.tsx";
import {ModelProvider, useModel} from "../../context/pvm-context.ts";

export function ShapeElementRenderPass({value: {children, transform, shape, fillColor, origin}}: {
  value: ShapeNode;
}) {
  const model = useModel();
  const valueModel = Transform.divide(transform, model);
  return (<binder>
    <ModelProvider value={valueModel}>
      {shape.type === "rectangle" && <RectangleShader origin={origin} color={fillColor} rectangle={shape.data} />}
      {shape.type === "cone" && <ConeShader origin={origin} color={fillColor} cone={shape.data} />}
      {shape.type === "arc" && <ArcShader origin={origin} color={fillColor} arc={shape.data} />}
      {[...children].reverse().map((element) => <ElementRenderPass key={element.data.id} value={element} />)}
    </ModelProvider>
  </binder>);
}
import {Ref} from "common/ref";
import {Optional} from "common/types/generic/index.ts";
import {SheetReference} from "../sheet/sheet-reference.ts";
import {useDatabase, useStoreID} from "../../../routes/game/model/store-context.tsx";
import {useMemo} from "react";
import {QLabDatabase} from "common/qlab/q-lab-database.ts";
import {AssetID, TokenID} from "common/legends/asset/index.ts";

export function useAssetSheet(assetID: AssetID, tokenID: TokenID): Ref<Optional<SheetReference>> {
  const storeID = useStoreID();
  const database = useDatabase();
  return useMemo(() => {
    return database.map((database: QLabDatabase): Optional<SheetReference> => {
      const resource = database.resources[assetID];
      if (resource?.type !== "asset") return undefined;
      const token = resource.data.tokens.find(token => token.tokenID === tokenID);
      if (token === undefined) return undefined;
      if (token.sheetId === undefined) return undefined;
      return ({type: "link", assetID: assetID, sheetID: token.sheetId});
    }).distinct();
  }, [database, storeID, assetID, tokenID]);
}
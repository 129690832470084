import {z} from "zod";
import {MathExpression} from "../../../../../../math/index.ts";
import {MathExpressionOperation, mathExpressionType} from "../../../../../../math/math-expression.ts";
import {ConstantOperation, constantType, ObjectType, PropertyRef, Type} from "../../../../../../types/index.ts";
import {Dnd5eProcessID} from "./dnd-5e-process-i-d.ts";
import {MutableRef} from "#common/ref";

export const Dnd5eProcessAdjustSpellSlot = z.object({
  processID: Dnd5eProcessID,
  level: MathExpression,
  quantity: MathExpression
});
export type Dnd5eProcessAdjustSpellSlot = z.infer<typeof Dnd5eProcessAdjustSpellSlot>;

export const Dnd5eProcessAdjustSpellSlotOperation = z.discriminatedUnion("type", [
  z.object({type: z.literal("update-process-i-d"), operations: z.array(ConstantOperation)}),
  z.object({type: z.literal("update-level"), operations: z.array(MathExpressionOperation)}),
  z.object({type: z.literal("update-quantity"), operations: z.array(MathExpressionOperation)})
]);
export type Dnd5eProcessAdjustSpellSlotOperation = z.infer<typeof Dnd5eProcessAdjustSpellSlotOperation>;

export const dnd5eProcessAdjustSpellSlotType: Type<Dnd5eProcessAdjustSpellSlot, Dnd5eProcessAdjustSpellSlotOperation> = new ObjectType({
  processID: constantType,
  level: mathExpressionType,
  quantity: mathExpressionType
});

export function Dnd5eProcessAdjustSpellSlotSignals(valueRef: MutableRef<Dnd5eProcessAdjustSpellSlot, Dnd5eProcessAdjustSpellSlotOperation[]>) {
  return {
    levelRef: PropertyRef<Dnd5eProcessAdjustSpellSlot, Dnd5eProcessAdjustSpellSlotOperation, MathExpression, MathExpressionOperation>(
      value => value.level,
      operations => [{type: "update-level", operations}]
    )(valueRef),
    quantityRef: PropertyRef<Dnd5eProcessAdjustSpellSlot, Dnd5eProcessAdjustSpellSlotOperation, MathExpression, MathExpressionOperation>(
      value => value.quantity,
      operations => [{type: "update-quantity", operations}]
    )(valueRef)
  }
}
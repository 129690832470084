import {Mask, MaskOperation} from "common/types/generic/mask/mask.ts";
import {MutableRef} from "common/ref";
import {useGameSenses} from "../../../legends/common/game/use-game-senses.ts";
import {useRefValue} from "#lib/signal/index.ts";
import {ButtonBar, ButtonBarProps, Checkbox, InputGroup, InputGroupLabel} from "#lib/components/index.ts";
import {ExpandOptions} from "#lib/components/expand-options.tsx";
import {SetFn} from "common/types/generic/index.ts";
import {twMerge} from "tailwind-merge";
import {Sense, SenseFn} from "common/legends/game/sense/sense.ts";
import {SenseID} from "common/legends/game/sense/sense-i-d.ts";
import {faChevronDown, faChevronUp} from "@awesome.me/kit-c9bc6845cd/icons/classic/solid";

export function InputMultiSelectSense({valueRef, ...props}: {
  valueRef: MutableRef<Mask, MaskOperation[]>;
} & ButtonBarProps) {
  const value = useRefValue(valueRef);
  const sensesRef = useGameSenses();
  const senses: Sense[] = [
    ...SenseFn.DEFAULT_SENSES,
    ...useRefValue(sensesRef)
  ];
  const setSense = (visionID: SenseID, checked: boolean) => {
    if (visionID === "ALL") {
      valueRef.apply(prev => {
        return checked ? SetFn.set(prev, ["ALL"]) : SetFn.set(prev, [...sensesRef.value.map(sense => sense.senseID), "GM"]);
      });
    } else {
      valueRef.apply(prev => {
        if (prev.includes("ALL")) {
          if (checked) return [];
          const all = ["GM", ...sensesRef.value.map(vision => vision.senseID)];
          return SetFn.set(prev, all.filter(id => id !== visionID))
        }
        return checked ? SetFn.insert(visionID) : SetFn.delete(visionID)
      });
    }
  };
  return <ButtonBar {...props} className={twMerge("px-0 rounded-md overflow-hidden w-fit flex flex-row gap-0.5", props.className)}>
    {senses.map(vision => <InputGroup key={vision.senseID} size="small" className="px-3" title={vision.name} style={{backgroundColor: `hsla(${vision.color[0] * 360}, ${vision.color[1] * 100}%, ${vision.color[2] * 100}%, 25%)`}}>
      <Checkbox size="small" checked={value.includes(vision.senseID) || value.includes("ALL")} onChange={ev => setSense(vision.senseID, ev.target.checked)}/>
    </InputGroup>)}
    <ExpandOptions size="small" openIcon={faChevronUp} closeIcon={faChevronDown}>
      {senses.map(vision => <InputGroup key={vision.senseID} className="pl-0" style={{backgroundColor: `hsla(${vision.color[0] * 360}, ${vision.color[1] * 100}%, ${vision.color[2] * 100}%, 25%)`}}>
        <InputGroup>
          <Checkbox checked={value.includes(vision.senseID) || value.includes("ALL")} onChange={ev => setSense(vision.senseID, ev.target.checked)} style={{backgroundColor: `hsl(${vision.color[0]}, ${vision.color[1]}, ${vision.color[2]})`}} />
        </InputGroup>
        <InputGroupLabel>
          {vision.name}
        </InputGroupLabel>
      </InputGroup>)}
    </ExpandOptions>
  </ButtonBar>;
}

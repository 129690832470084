import {NodeId} from "common/legends/index.ts";
import React, {useEffect} from "react";
import {CharacterSelectionPanel} from "../common/character-selection-panel.tsx";
import {PlayerTokenSelector} from "../common/player-token-selector.tsx";
import {useCharactersNodeSceneIDs} from "../../../viewport/token/use-controller-node-ids.ts";
import {useUserID} from "#lib/auth/use-get-user-id.ts";
import {usePlayerController} from "./player-controller-provider.ts";
import {useRefValue} from "#lib/signal/index.ts";
import {useGoToNode} from "../editor/use-go-to-node.ts";

export function PlayerCharacterSelector() {
  const playerController = usePlayerController();
  const value = useRefValue(playerController.state);

  const userID = useUserID()!;
  const nodeScenes = useRefValue(useCharactersNodeSceneIDs(userID));
  const controllerNodeIds = Object.keys(nodeScenes);

  const gotoNode = useGoToNode();
  useEffect(() => {
    const timer = setTimeout(() => {
      const controllableNodeIds = Object.keys(nodeScenes) as NodeId[];
      if ((value.activeController === undefined || nodeScenes[value.activeController.controllerNodeID] === undefined) && controllableNodeIds.length > 0) {
        gotoNode(controllableNodeIds[0]);
      }
    }, value.activeController?.controllerNodeID === undefined ? 0 : 500);
    return () => clearTimeout(timer);
  }, [value.activeController?.controllerNodeID, gotoNode, nodeScenes]);

  return (<div className="inline-flex gap-1 items-center">
    {controllerNodeIds.length > 0 && <CharacterSelectionPanel values={controllerNodeIds} userID={userID}
                                                              selected={value.activeController?.controllerNodeID}
                                                              onChange={gotoNode}/>}
    <PlayerTokenSelector/>
  </div>);
}
import {useMemo} from "react";
import {pipe} from "common/pipe";
import {distinct, map} from "common/observable";
import {ValueFn} from "common/types/index.ts";
import {MutableRef} from "common/ref";
import {Tool, ToolFn, ToolOperation} from "../../../../../common/tool/tool.ts";
import {usePlayerController} from "../../../player/player-controller-provider.ts";
import {useEditor} from "../../../editor/editor-context.ts";
import {TokenViewportProperties, TokenViewportPropertiesOperation} from "../../../../../viewport/token/token-viewport-properties.ts";
import {Editor, EditorOperation, EditorState, EditorStateOperation} from "../../../editor/state";

export function useEditorTool(): MutableRef<Tool, ToolOperation[]> {
  const playerController = usePlayerController();
  const editor = useEditor();
  return useMemo(() => {
    if (playerController) {
      const valueFn = (value: TokenViewportProperties) => {return value?.toolMode || ToolFn.DEFAULT;};
      return new MutableRef({
        value() {return valueFn(playerController.state.value)},
        observe: pipe(playerController.state.observe, map(valueFn), distinct()),
        apply: fn => playerController.state.apply((prev): TokenViewportPropertiesOperation[] => {
          if (prev === undefined) return [];
          return [{
            type: "update-tool-mode", operations: fn(prev.toolMode)
          }];
        }).then(valueFn)
      })
    } else {
      const valueFn = (value: EditorState) => {return value?.data.toolMode || ToolFn.DEFAULT;};
      return new MutableRef({
        value() {return valueFn(editor.state.value)},
        observe: pipe(editor.state.observe, map(valueFn), distinct()),
        apply: fn => editor.state.apply((prev): EditorStateOperation[] => {
          if (prev === undefined) return [];
          return ValueFn.apply<Editor, EditorOperation>([{
            type: prev.type,
            operations: [{
              type: "update-tool-mode",
              operations: fn(prev.data.toolMode)
            }]
          }]);
        }).then(valueFn)
      })
    }
  }, [playerController, editor]);
}

import {getDnd5eSheetActiveModifiers} from "../../../dnd-5e/common/dnd-5e-sheet-active-modifiers.ts";
import {getDnd5eSheetAbilityCheckModifiers} from "../../../dnd-5e/common/dnd-5e-sheet-ability-check-modifiers.ts";
import {getDnd5eAbilityCheckProficiency} from "../../../dnd-5e/common/get-dnd5e-ability-check-proficiency.ts";
import {Dnd5eFeature, Sheet} from "common/legends/asset/index.ts";
import {Optional} from "common/types/generic/index.ts";
import {Dnd5eAbilityCheckType} from "common/legends/asset/sheet/dnd-5e/dnd-5e-modifier/dnd-5e-ability-check-modifier.ts";
import {DiceExpression} from "common/dice/dice-expression.ts";
import {Dice} from "common/dice/dice.ts";

export function getBaseAbilityCheck(
  sheetValue: Optional<Sheet>,
  globalEffectsValue: Dnd5eFeature[],
  abilityCheckType: Optional<Dnd5eAbilityCheckType>,
  forceAdvantage: boolean,
  forceDisadvantage: boolean
): DiceExpression {
  const activeModifiers = getDnd5eSheetActiveModifiers(sheetValue, globalEffectsValue);
  const expression = getDnd5eSheetAbilityCheckModifiers(sheetValue, globalEffectsValue, abilityCheckType);
  const proficiency = getDnd5eAbilityCheckProficiency(sheetValue, globalEffectsValue, abilityCheckType);

  const hasReliableTalent = activeModifiers.some(m => m.type === "trait" && m.data.trait === "reliable-talent");
  const hasHalflingLuck = activeModifiers.some(m => m.type === "trait" && m.data.trait === "halfling-luck");
  const hasSilverTongue = activeModifiers.some(m => m.type === "trait" && m.data.trait === "silver-tongue");
  const hasGlibness = activeModifiers.some(m => m.type === "trait" && m.data.trait === "glibness");
  const hasAdvantage = activeModifiers.some(m => m.type === "ability-check" && (abilityCheckType !== undefined && m.data.abilityChecks.includes(abilityCheckType)) && m.data.hasAdvantage);
  const hasDisadvantage = activeModifiers.some(m => m.type === "ability-check" && (abilityCheckType !== undefined && m.data.abilityChecks.includes(abilityCheckType)) && m.data.hasDisadvantage);

  let formula = "1d20";
  if ((hasAdvantage && !forceDisadvantage) || (forceAdvantage && !forceDisadvantage)) formula = `2d20${hasHalflingLuck ?"ro1" :""}kh1`;
  else if ((hasDisadvantage && !forceAdvantage) || (!forceAdvantage && forceDisadvantage)) formula = "2d20kl1";

  // min 10
  if (hasGlibness) formula = `max(15, ${formula})[Glibness]`;
  else if (proficiency !== "untrained" && hasReliableTalent) formula = `max(10, ${formula})[Reliable Talent]`;
  else if (hasSilverTongue && (abilityCheckType === "deception" || abilityCheckType === "persuasion")) {
    formula = `max(10, ${formula})[Silver Tongue]`;
  }

  if (expression && expression.length > 0 && expression !== "0") formula += `+${expression}`;
  formula = formula.replaceAll("+-", "-");
  return Dice.assertDiceExpression(formula);
}

import {useMemo} from "react";
import {MutableRef, Ref} from "common/ref";
import {Optional} from "common/types/generic/index.ts";
import {NodeId} from "common/legends/node/index.ts";
import {NodeSelectionRef} from "../../../panel/nav/editor/state/selection-ref.ts";
import {EditorVision} from "../../scene/editor-vision.ts";
import {useDatabase} from "../../../../routes/game/model/store-context.tsx";
import {getNode} from "../../../common/node/use-get-node.ts";

export function useActiveNodeID(editorVisionRef: Ref<EditorVision>, selectedNodeIDsRef: Ref<NodeSelectionRef[]>): Ref<Optional<NodeId>> {
  const databaseRef = useDatabase();

  return useMemo(() => MutableRef.all(databaseRef, editorVisionRef, selectedNodeIDsRef)
    .map(([database, editorVision, selectedNodeIDs]): Optional<NodeId> => {
      if (editorVision.override || selectedNodeIDs.length === 0) {
        return undefined;
      }
      return selectedNodeIDs.find(({nodeId}): boolean => {
        const node = getNode(database, nodeId);
        if (node?.type === "token") {
          const {assetID, tokenID} = node.data.tokenReference;
          const asset = database.resources[assetID];
          if (asset?.type !== "asset") return false;
          const token = asset.data.tokens.find(token => token.tokenID === tokenID);
          if (!token) return false;
          if (token.vision.length === 0) return false;
          return true;
        } else {
          return false;
        }
      })?.nodeId;
    })
    .distinct(), [databaseRef, editorVisionRef, selectedNodeIDsRef]);
}

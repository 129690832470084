import {
  ConstantOperation,
  ConstantType,
  NumberOperation,
  numberType,
  ObjectType,
  Point,
  Type,
  ValueOperation,
  ValueType
} from "../../../types/index.ts";
import {GridRuler, GridRulerFn, GridRulerOperation, gridRulerType} from "./grid-ruler.ts";
import {Vector2} from "#common/math/vector/vector2.ts";

export type GridShape = "square" | "hexagon-horizontal" | "hexagon-vertical" | "isometric";
export const gridShapeType = new ValueType(new ConstantType<GridShape>());
export type GridShapeOperation = ValueOperation<GridShape, ConstantOperation>;

export type GridUnit = [number, string];
export const gridUnitType = new ValueType(new ConstantType<GridUnit>());
export type GridUnitOperation = ValueOperation<GridUnit, ConstantOperation>;

export type Grid = {
  shape: GridShape,
  width: number,
  height: number,
  unit: GridUnit,
  subdivisions: number,
  ruler: GridRuler
};
export type GridOperation =
  | {type: "update-shape", operations: GridShapeOperation[]}
  | {type: "update-width", operations: NumberOperation[]}
  | {type: "update-height", operations: NumberOperation[]}
  | {type: "update-unit", operations: GridUnitOperation[]}
  | {type: "update-subdivisions", operations: NumberOperation[]}
  | {type: "update-ruler", operations: GridRulerOperation[]}
  ;

export const gridType: Type<Grid, GridOperation> = new ObjectType({
  shape: gridShapeType,
  width: numberType,
  height: numberType,
  unit: gridUnitType,
  subdivisions: numberType,
  ruler: gridRulerType
});

function cubeRound(frac: [number, number, number]): [number, number, number] {
  let q = Math.round(frac[0]);
  let r = Math.round(frac[1]);
  let s = Math.round(frac[2]);
  const qDiff = Math.abs(q - frac[0]);
  const rDiff = Math.abs(r - frac[1]);
  const sDiff = Math.abs(s - frac[2]);
  if (qDiff > rDiff && qDiff > sDiff) {
    q = -r - s;
  } else if (rDiff > sDiff) {
    r = -q - s;
  } else {
    s = -q - r;
  }

  return [q, r, s];
}


export const Grid = {
  DEFAULT: {
    shape: "square",
    width: 64,
    height: 64,
    subdivisions: 1,
    unit: [5, "ft"],
    ruler: "dnd-standard"
  } satisfies Grid,
  coord: ({shape, width, height, subdivisions}: Grid, [x, y]: Point): Point => {
    const s = subdivisions + 1;
    switch (shape) {
      case "square": {

        const q: Point = [
          Math.round(x / width * s) / s,
          Math.round(y / height * s) / s
        ];
        return q;
      }
      case "hexagon-horizontal": {
        const q: Point = [
          Math.round(x / (3/4*width) * s),
          Math.round(y / height * s - x/(3/(4/2)*width) * s)
        ];
        const c = cubeRound([q[0], q[1], -q[0] - q[1]]);
        return [c[0] / s, c[1] / s];
      }
      case "hexagon-vertical": {
        const q: Point = [
          Math.round(x / width * s - y / ((3/2)*height) * s),
          Math.round(y / (3/4*height) * s)
        ];
        const c = cubeRound([q[0], q[1], -q[0]-q[1]]);
        return [c[0] / s, c[1] / s];
      }
      case "isometric": {
        const q: Point = [
          Math.round( x / width * s - y / height * s) / s,
          Math.round( y / height * s + x / width * s) / s
        ];
        return q;
      }
      default: return [x, y];
    }
  },
  snap: (grid: Grid, p: Point): Point => {
    switch (grid.shape) {
      case "square": {
        const [gx, gy] = Grid.coord(grid, p);
        return [
          gx * grid.width,
          gy * grid.height
        ];
      }
      case "hexagon-horizontal": {
        const [gx, gy] = Grid.coord(grid, p);
        return [
          (0.75 * gx)*grid.width,
          (gy + 0.5 * gx)*grid.height
        ];
      }
      case "hexagon-vertical": {
        const [gx, gy] = Grid.coord(grid, p);
        return [
          (gx + 0.5 * gy) * grid.width,
          (0.75 * gy) * grid.height
        ];
      }
      case "isometric": {
        const [gx, gy] = Grid.coord(grid, p);
        return [
          (0.5 * gx + 0.5 * gy) * grid.width,
          (0.5 * gy - 0.5 * gx) * grid.height
        ];
      }
    }
    return p;
  },
  distance: (grid: Grid, startPos: Point, endPos: Point): number => {
    const sCoord = Grid.coord(grid, startPos);
    const eCoord = Grid.coord(grid, endPos);
    return GridRulerFn.distance(grid.ruler, sCoord, eCoord) * grid.unit[0];
  },
  ring: function* ring(grid: Grid, origin: Point, distance: number): Iterator<Point> {
    if (distance === 0) {
      yield origin;
      return;
    }

    switch (grid.shape) {
      case "square": {
        const directions: Vector2[] = [
          [0, -grid.height],
          [-grid.width, 0],
          [0, grid.height],
          [grid.width, 0]
        ];
        let point = Vector2.add(origin, [grid.width * distance, grid.height * distance]);
        for (const d of directions) {
          for (let i = 0; i < 2 * distance; i ++) {
            point = Vector2.add(point, d);
            yield point;
          }
        }
        return;
      }
      case "hexagon-horizontal": {
        const directions: Vector2[] = [
          [0, -grid.height],
          [-(3/4) * grid.width, -(1/2) * grid.height],
          [-(3/4) * grid.width, (1/2) * grid.height],
          [0, grid.height],
          [(3/4) * grid.width, (1/2) * grid.height],
          [(3/4) * grid.width, -(1/2) * grid.height]
        ];

        let point = Vector2.add(origin, Vector2.multiply(directions[4], distance));
        for (const d of directions) {
          for (let i = 0; i < distance; i ++) {
            point = Vector2.add(point, d);
            yield point;
          }
        }
        return;
      }
      case "hexagon-vertical": {
        const directions: Vector2[] = [
          [(1/2) * grid.width, -(3/4) * grid.height],
          [-(1/2) * grid.width, -(3/4) * grid.height],
          [-grid.width, 0],
          [-(1/2) * grid.width, (3/4) * grid.height],
          [(1/2) * grid.width, (3/4) * grid.height],
          [grid.width, 0]
        ];

        let point = Vector2.add(origin, Vector2.multiply(directions[4], distance));
        for (const d of directions) {
          for (let i = 0; i < distance; i ++) {
            point = Vector2.add(point, d);
            yield point;
          }
        }
        return;
      }
      case "isometric": {
        const directions: Vector2[] = [
          [ grid.width / 2, -grid.height / 2],
          [-grid.width / 2, -grid.height / 2],
          [-grid.width / 2,  grid.height / 2],
          [ grid.width / 2,  grid.height / 2],
        ];
        let point = Vector2.add(origin, Vector2.multiply([0, grid.height], distance));
        for (const d of directions) {
          for (let i = 0; i < 2 * distance; i ++) {
            point = Vector2.add(point, d);
            yield point;
          }
        }
        return;
      }
      default: {
        yield origin;
        return;
      }
    }
  },
  spiral: function* spiral(grid: Grid, origin: Point): Iterator<Point> {
    let n = 0;
    while (true) {
      const iterator = Grid.ring(grid, origin, n ++);
      let next = iterator.next();
      while (!next.done) {
        yield next.value;
        next = iterator.next()
      }
    }
  }
} as const;

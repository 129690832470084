import {Dnd5eActionDiceRoll} from "common/qlab/message/dnd-5e/dnd-5e-action-message.ts";
import {RollRequests, RollResults} from "common/qlab/message/index.ts";
import {Optional} from "common/types/generic/index.ts";
import {RollRequestView} from "../../roll-request-view.tsx";
import {Button} from "#lib/components/button/index.ts";
import {FaSpinner} from "react-icons/fa";
import {useSelectedSheet} from "../../../../panel/sheet/editor/dnd-5e-character/use-selected-sheet.ts";
import {useComputedValue} from "#lib/signal/index.ts";
import {useApplyDamageToSelectedCharacter, useApplyHealingToSelectedCharacter, useApplyTempHPToSelectedCharacter} from "../use-apply-to-selected-character.ts";
import {MouseEvent, useState} from "react";
import {useIsAltDown} from "#lib/is-alt-down.ts";
import {useIsShiftDown} from "#lib/components/use-is-shift-down.ts";
import {useIsCtrlDown} from "#lib/components/use-is-ctrl-down.ts";
import {DND_5E_DAMAGE_TYPES} from "common/legends/asset/sheet/dnd-5e/dnd-5e-damage-type.ts";

export function Dnd5eActionRollView({segment, rollRequests, rollResults}: {segment: Dnd5eActionDiceRoll[], rollRequests: RollRequests, rollResults: Optional<RollResults>}) {
  const selectedSheet = useSelectedSheet();
  const isSheetSelected = useComputedValue(selectedSheet, selectedSheet => selectedSheet !== undefined);
  const applyDamage = useApplyDamageToSelectedCharacter(selectedSheet);
  const applyTempHP = useApplyTempHPToSelectedCharacter(selectedSheet);
  const applyHealing = useApplyHealingToSelectedCharacter(selectedSheet);

  const [isProcessing, setIsProcessing] = useState(false);

  const altDown = useIsAltDown();
  const shiftDown = useIsShiftDown();
  const ctrlDown = useIsCtrlDown();

  const hasHealing = (altDown && segment.some(roll => roll.rollType !== undefined && roll.rollType !== "temp hp")) || segment.some(roll => roll.rollType === "healing");
  const hasTempHP = segment.every(roll => roll.rollType === "temp hp") && !hasHealing;
  const hasDamage = segment.every(roll => roll.rollType !== undefined && DND_5E_DAMAGE_TYPES.includes(roll.rollType)) && !hasTempHP && !hasHealing;

  const applyNormalDamage = (ev: MouseEvent) => {
    if (hasHealing) {
      setIsProcessing(true);
      applyHealing(segment.map(dr => (rollResults ? rollResults[dr.rollID] : undefined)))
        .finally(() => setTimeout(() => setIsProcessing(false), 500));
    } else if (hasTempHP) {
      setIsProcessing(true);
      applyTempHP(segment.map(dr => (rollResults ? rollResults[dr.rollID] : undefined)))
        .finally(() => setTimeout(() => setIsProcessing(false), 500));
    } else {
      setIsProcessing(true);
      applyDamage(
        segment.map(dr => ({rollResult: rollResults ? rollResults[dr.rollID] : undefined, damageType: dr.rollType})),
        ev.ctrlKey, ev.shiftKey
      ).finally(() => setTimeout(() => setIsProcessing(false), 500));
    }
  };

  if (segment.length === 0) return <></>;
  return <>
    <div className="flex flex-wrap gap-2 justify-center">
      {segment.map((roll, index) => <div key={index} className="m-0.5">
        <RollRequestView
          rollType={hasDamage ? "damage" : "roll"}
          damageType={roll.rollType}
          rollRequest={rollRequests[roll.rollID]}
          rollResult={rollResults ? rollResults[roll.rollID] :undefined } />
      </div>)}
    </div>

    {hasHealing && <Button variant="primary" disabled={isProcessing || !isSheetSelected} className="w-full" onClick={applyNormalDamage}>
      {isProcessing && <FaSpinner className="animate-spin"/>}
      <span>Apply Healing</span>
    </Button>}
    {hasTempHP && <Button variant="primary" disabled={isProcessing || !isSheetSelected} className="w-full" onClick={applyNormalDamage}>
      {isProcessing && <FaSpinner className="animate-spin"/>}
      <span>Apply Temp HP</span>
    </Button>}
    {hasDamage && <Button variant="primary" disabled={isProcessing || !isSheetSelected} className="w-full" onClick={applyNormalDamage}>
      {isProcessing && <FaSpinner className="animate-spin"/>}
      <span>Apply {shiftDown && !ctrlDown ? "Double " : ""}{!shiftDown && ctrlDown ? "Half " : ""}Damage</span>
    </Button>}
  </>
}
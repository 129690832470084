import {Node} from "common/legends/index.ts";
import {TokenElementRenderPass} from "./token-element-render-pass.tsx";
import {ImageElementRenderPass} from "./image-element-render-pass.tsx";
import {GroupElementRenderPass} from "./group-element-render-pass.tsx";
import {WallElementRenderPass} from "./wall-element-render-pass.tsx";
import {AreaElementRenderPass} from "./area-element-render-pass.tsx";
import {TextElementRenderPass} from "./text-element-render-pass.tsx";
import {ShapeElementRenderPass} from "./shape-element-render-pass.tsx";
import {ParallaxElementRenderPass} from "./parallax-element-render-pass.tsx";
import {useIsElementAccessible, useIsElementVisible} from "../../context/visibility-context.ts";
import {LightElementRenderPass} from "./light-element-render-pass.tsx";
import {useConditionsMet} from "../../context/use-conditions-met.ts";
import {GridElementRenderPass} from "./grid-element-render-pass.tsx";
import React from "react";
import {VideoElementRenderPass} from "./video-element-render-pass.tsx";

export const ElementRenderPass = React.memo(function ElementRenderPass({value}: {
  value: Node;
}) {
  const isVisible = useIsElementVisible()(value);
  const isAccessible = useIsElementAccessible()(value);
  const conditionsMet = useConditionsMet(value.data.conditions);

  if ((!conditionsMet || !isVisible) && !isAccessible) return <></>;
  if (value.type === "area") return <AreaElementRenderPass value={value.data}/>
  else if (value.type === "grid") return <GridElementRenderPass value={value.data}/>
  else if (value.type === "group") return <GroupElementRenderPass value={value.data} />
  else if (value.type === "image") return <ImageElementRenderPass value={value.data} />
  else if (value.type === "shape") return <ShapeElementRenderPass value={value.data}/>
  else if (value.type === "text") return <TextElementRenderPass value={value.data}/>
  else if (value.type === "token") return <TokenElementRenderPass value={value.data}/>
  else if (value.type === "wall") return <WallElementRenderPass value={value.data}/>
  else if (value.type === "parallax") return <ParallaxElementRenderPass value={value.data}/>
  else if (value.type === "light") return <LightElementRenderPass value={value.data}/>
  else if (value.type === "video") return <VideoElementRenderPass value={value.data}/>
  else return <></>;
});

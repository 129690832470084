import {Node, NodeOperation, NodeTypes} from "common/legends/index.ts";
import {ConstantOperation, ValueOperation} from "common/types/index.ts";
import {ImageNodeProperties} from "./image-node-properties.tsx";
import {GroupNodeProperties} from "./group-node-properties.tsx";
import {TokenNodeProperties} from "./token-node-properties.tsx";
import {GridNodeProperties} from "./grid-node-properties.tsx";
import {WallNodeProperties} from "./wall-node-properties.tsx";
import {AreaNodeProperties} from "./area-node-properties.tsx";
import {TextNodeProperties} from "./text-node-properties.tsx";
import {ShapeNodeProperties} from "./shape-node-properties.tsx";
import {ParallaxNodeProperties} from "./parallax-node-properties.tsx";
import {useTypedRef} from "../../../common/use-typed-ref.ts";
import {MutableRef} from "common/ref";
import {LightNodeProperties} from "./light-node-properties.tsx";
import {SelectionRef} from "../../nav/editor/state/selection-ref.ts";
import {VideoNodeProperties} from "./video-node-properties.tsx";

export type NodePropertiesProps = {
  value: MutableRef<Node, NodeOperation[]>;
  reference: SelectionRef;
  pinned: MutableRef<SelectionRef, ValueOperation<SelectionRef, ConstantOperation>[]>;
};

export function NodeProperties({value, reference, pinned}: NodePropertiesProps) {
  const [nodeType, typedNode] = useTypedRef<NodeTypes>(value);
  if (nodeType === "image") return <ImageNodeProperties value={typedNode} reference={reference} pinned={pinned} />;
  else if (nodeType === "group") return <GroupNodeProperties value={typedNode} reference={reference} pinned={pinned}  />;
  else if (nodeType === "token") return <TokenNodeProperties value={typedNode} reference={reference} pinned={pinned} />;
  else if (nodeType === "grid") return <GridNodeProperties value={typedNode} reference={reference} pinned={pinned} />;
  else if (nodeType === "wall") return <WallNodeProperties value={typedNode} reference={reference} pinned={pinned} />
  else if (nodeType === "area") return <AreaNodeProperties value={typedNode} reference={reference} pinned={pinned} />
  else if (nodeType === "shape") return <ShapeNodeProperties value={typedNode} reference={reference} pinned={pinned} />
  else if (nodeType === "text") return <TextNodeProperties value={typedNode} reference={reference} pinned={pinned} />
  else if (nodeType === "parallax") return <ParallaxNodeProperties value={typedNode} reference={reference} pinned={pinned} />
  else if (nodeType === "light") return <LightNodeProperties valueRef={typedNode} reference={reference} pinned={pinned} />
  else if (nodeType === "video") return <VideoNodeProperties valueRef={typedNode} reference={reference} pinned={pinned} />
  else return <></>
}

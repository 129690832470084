import {DND_5E_SKILLS} from "common/legends/asset/sheet/dnd-5e/character/dnd-5e-skill.ts";
import {Sheet, SheetOperation} from "common/legends/asset/sheet/sheet.ts";
import {DND_5E_SAVING_THROW_TYPES} from "common/legends/asset/sheet/dnd-5e/dnd-5e-modifier/dnd-5e-saving-throw-modifier.ts";
import {useSelectedNodeID} from "../../../../sheet/editor/dnd-5e-character/use-selected-sheet.ts";
import {Menu} from "@headlessui/react";
import {Button, ButtonBar, IconButton, InputGroup, InputGroupLabel, InputNumber, useTempRef} from "#lib/components/index.ts";
import {FaDiceD20, FaEllipsis, FaHashtag, FaRegHeart} from "react-icons/fa6";
import {distinct, from, map, Observable, toPromise} from "common/observable";
import React, {useMemo, useState} from "react";
import {usePopper} from "react-popper";
import {createPortal} from "react-dom";
import {useLongRest, useShortRest} from "../../../../sheet/editor/dnd-5e-character/dnd-5e-action/use-rest.ts";
import {FaSpinner} from "react-icons/fa";
import {ApplyAction, ListOperation, numberType, Optional} from "common/types/index.ts";
import {useRollHitDice} from "../../../../sheet/editor/dnd-5e-character/dnd-5e-action/use-roll-hit-dice.ts";
import {
  Dnd5eCharacterClassHitDice,
  Dnd5eCharacterClassHitDiceOperation,
  Dnd5eCharacterClassHitDiceSignals
} from "common/legends/asset/sheet/dnd-5e/character/dnd-5e-character-class-hit-dice.ts";
import {pipe} from "common/pipe";
import {useObservable} from "#lib/qlab/index.ts";
import {Popper} from "#lib/components/popper/popper.tsx";
import {SheetReference} from "../../../../../common/sheet/sheet-reference.ts";
import {InitiativeField} from "../../../../sheet/editor/dnd-5e-character/initiative-field.tsx";
import {PanelHeader} from "#lib/components/panel-header.tsx";
import {SavingThrowField} from "../../../../sheet/editor/dnd-5e-character/saving-throw-field.tsx";
import {SkillField} from "../../../../sheet/editor/dnd-5e-character/skill-field.tsx";
import {BaseComponent} from "#lib/components/BaseComponent.tsx";
import {Dnd5eCharacter, DND_5E_ATTRIBUTES, isDnd5eAttribute} from "common/legends/index.ts";
import {Dnd5eStatBlock} from "common/legends/asset/sheet/dnd-5e/dnd-5e-stat-block/index.ts";
import {useSheetSignal} from "../../../../../common/sheet/use-sheet-signal.ts";
import {usePortal} from "#lib/container/react/external-window/external-portal.tsx";
import {MutableRef, Ref} from "common/ref";
import {QuickAccessAbilityCheck} from "./quick-access-ability-check.tsx";

export type QuickAccessAbilityChecksMenuProps = {
  sheetRef: Ref<Optional<SheetReference>>;
};

export function QuickAccessAbilityChecksMenu({sheetRef}: QuickAccessAbilityChecksMenuProps) {
  const sheet = useSheetSignal(sheetRef);

  const [referenceElement, setReferenceElement] = useState<HTMLElement | null>(null);
  const [popperElement, setPopperElement] = useState<HTMLElement | null>(null);
  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    placement: "bottom-start"
  });

  const shortRest = useShortRest(sheet);
  const [shortRestClicked, setShortRestClicked] = useState(false);

  const longRest = useLongRest(sheet);
  const [longRestClicked, setLongRestClicked] = useState(false);

  return (<Menu as="div" ref={ref => setReferenceElement(ref)}>
    <Menu.Button title="Misc" as={IconButton}>
      <FaEllipsis/>
    </Menu.Button>
    {createPortal(<Menu.Items as={Popper} ref={ref => setPopperElement(ref)}
                              style={styles.popper} {...attributes.popper}
                              className="mt-4 w-full max-w-screen-md flex flex-row py-2 gap-0">
      <div className="flex-1 shrink-0 flex flex-col gap-2">
        <BaseComponent><InitiativeField/></BaseComponent>
        <BaseComponent>
          <PanelHeader>Skills</PanelHeader>
          {DND_5E_SKILLS.map(skill => <SkillField key={skill} skill={skill}/>)}
        </BaseComponent>
      </div>
      <div className="flex-1 shrink-0 flex flex-col gap-2">
        <BaseComponent>
          <PanelHeader>Ability Checks</PanelHeader>
          <ButtonBar>
            {DND_5E_ATTRIBUTES.map(attribute => <QuickAccessAbilityCheck key={attribute} attribute={attribute} />)}
          </ButtonBar>
        </BaseComponent>
        <BaseComponent>
          <PanelHeader>Saving Throws</PanelHeader>
          {DND_5E_ATTRIBUTES.map(attribute => <SavingThrowField key={attribute} attribute={attribute}/>)}
        </BaseComponent>
        {DND_5E_SAVING_THROW_TYPES.filter(savingThrow => !isDnd5eAttribute(savingThrow)).map(attribute => <BaseComponent key={attribute}>
          <SavingThrowField attribute={attribute}/>
        </BaseComponent>)}

        <BaseComponent>
          <ButtonBar>
            <Button disabled={shortRestClicked} className="flex-1" onClick={() => {
              shortRest()
              setShortRestClicked(true);
              setTimeout(() => setShortRestClicked(false), 1000);
            }}>
              {shortRestClicked
                && <FaSpinner className="animate-spin"/>
                || "Short Rest"
              }
            </Button>
            <Button disabled={longRestClicked} className="flex-1" onClick={() => {
              longRest()
              setLongRestClicked(true);
              setTimeout(() => setLongRestClicked(false), 1000);
            }}>
              {longRestClicked
                && <FaSpinner className="animate-spin"/>
                || "Long Rest"
              }
            </Button>
          </ButtonBar>
          <QuickAccessHitDiceButton sheet={sheet}/>
        </BaseComponent>
      </div>
    </Menu.Items>, usePortal())}
  </Menu>);
}

function useHitDice(value: MutableRef<Optional<Sheet>, SheetOperation[]>): MutableRef<Dnd5eCharacterClassHitDice, Dnd5eCharacterClassHitDiceOperation[]>[] {
  return useObservable(pipe(
    value.observe,
    map(sheet => {
      if (sheet?.type === "dnd-5e-character") {
        return sheet.data.classes.map((clazz, clazzIndex): MutableRef<Dnd5eCharacterClassHitDice, Dnd5eCharacterClassHitDiceOperation[]> => new MutableRef({
          value() {return clazz.hitDice},
          observe: from(clazz.hitDice),
          apply: (fn) => value.apply(prev => {
            if (prev?.type === "dnd-5e-character") {
              return [{
                type: "dnd-5e-character",
                operations: [{
                  type: "update-classes",
                  operations: ListOperation.apply(clazzIndex, [{
                    type: "update-hit-dice",
                    operations: fn(prev.data.classes[clazzIndex].hitDice)
                  }])
                }]
              }];
            } else return [];
          }).then(response => (response!.data as Dnd5eCharacter).classes[clazzIndex].hitDice)
        }));
      } else if (sheet?.type === "dnd-5e-stat-block") {
        return [new MutableRef({
          value() {return sheet.data.hitDice},
          observe: from(sheet.data.hitDice),
          apply: (fn: ApplyAction<Dnd5eCharacterClassHitDice, Dnd5eCharacterClassHitDiceOperation[]>): Promise<Dnd5eCharacterClassHitDice> => value.apply(prev => {
            if (prev?.type === "dnd-5e-stat-block") {
              return [{
                type: "dnd-5e-stat-block",
                operations: [{
                  type: "update-hit-dice",
                  operations: fn(prev.data.hitDice)
                }]
              }];
            }
            return [];
          }).then(prev => (prev!.data as Dnd5eStatBlock).hitDice)
        })];
      }
      return [];
    }),
    distinct()
  ), [], [value]);
}

type QuickAccessHitDiceButtonProps = {
  sheet: MutableRef<Optional<Sheet>, SheetOperation[]>,
};

function QuickAccessHitDiceButton({sheet}: QuickAccessHitDiceButtonProps) {
  const con = useMemo(() => pipe(
    sheet.observe,
    map(sheet => {
      if (sheet?.type === "dnd-5e-character") return sheet.data.attributes.con || 10;
      else if (sheet?.type === "dnd-5e-stat-block") return sheet.data.attributes.con || 10;
      else return 10;
    }),
    distinct()
  ), [sheet]);
  const hitDices = useHitDice(sheet);
  return (
    <>
      {hitDices.map((hitDice, index) => <QuickAccessHitDiceRowButton key={index} value={hitDice} con={con} />)}
    </>
  );
}

type QuickAccessHitDiceRowButtonProps = {
  value: MutableRef<Dnd5eCharacterClassHitDice, Dnd5eCharacterClassHitDiceOperation[]>;
  con: Observable<number>
};

function QuickAccessHitDiceRowButton({value, con}: QuickAccessHitDiceRowButtonProps) {
  const rollHitDice = useRollHitDice();
  const qty = useTempRef(numberType, 1);
  const nodeID = useSelectedNodeID();
  const {current, hitDice} = useMemo(() => Dnd5eCharacterClassHitDiceSignals(value), [value]);

  return (<div className="inline-flex flex-row">
    <InputGroup className="flex-1" title="Hit Dice">
      <InputGroupLabel><FaHashtag /></InputGroupLabel>
      <InputNumber value={qty} />
    </InputGroup>
    <InputGroup className="px-0 gap-0">
      <InputGroupLabel className="px-0"><FaRegHeart /></InputGroupLabel>
      <InputNumber disabled value={current} className="w-8 text-center" />
      <InputGroupLabel className="w-2">d</InputGroupLabel>
      <InputNumber disabled value={hitDice} className="w-8 text-center" />
    </InputGroup>
    <IconButton title="Roll Hit Dice" onClick={async () => {
      await rollHitDice(
        value,
        await toPromise(nodeID),
        await toPromise(qty.observe),
        await toPromise(con)
      )
    }}><FaDiceD20 /></IconButton>
  </div>)
}
import {z} from "zod";
import {MultiType, Type} from "../../../../../../../types/index.ts";
import {
  Dnd5eActionTemplateSegmentAttackRollHitModifier,
  Dnd5eActionTemplateSegmentAttackRollHitModifierOperation,
  dnd5eActionTemplateSegmentAttackRollHitModifierType
} from "./dnd-5e-action-template-segment-attack-roll-hit-modifier.ts";
import {
  Dnd5eActionTemplateSegmentAttackRollCriticalRangeModifier,
  Dnd5eActionTemplateSegmentAttackRollCriticalRangeModifierOperation,
  dnd5eActionTemplateSegmentAttackRollCriticalRangeType
} from "./dnd-5e-action-template-segment-attack-roll-critical-range-modifier.ts";
import {
  Dnd5eActionTemplateSegmentAttackRollAttributeOverrideModifier,
  Dnd5eActionTemplateSegmentAttackRollAttributeOverrideModifierOperation,
  dnd5eActionTemplateSegmentAttackRollAttributeOverrideModifierType
} from "./dnd-5e-action-template-segment-attack-roll-attribute-override-modifier.ts";
import {
  Dnd5eActionTemplateSegmentAttackRollProficientOverrideModifier,
  Dnd5eActionTemplateSegmentAttackRollProficientOverrideModifierOperation,
  dnd5eActionTemplateSegmentAttackRollProficientOverrideModifierType
} from "./dnd-5e-action-template-segment-attack-roll-proficient-override-modifier.ts";
import {
  Dnd5eActionTemplateSegmentAttackRollAdvantageOverrideModifier,
  Dnd5eActionTemplateSegmentAttackRollAdvantageOverrideModifierOperation,
  dnd5eActionTemplateSegmentAttackRollAdvantageOverrideModifierType
} from "./dnd-5e-action-template-segment-attack-roll-advantage-override.ts";
import {
  Dnd5eActionTemplateSegmentAttackRollDisadvantageOverrideModifier,
  Dnd5eActionTemplateSegmentAttackRollDisadvantageOverrideModifierOperation,
  dnd5eActionTemplateSegmentAttackRollDisadvantageOverrideModifierType
} from "./dnd-5e-action-template-segment-attack-roll-disadvantage-override.ts";
import {
  Dnd5eActionTemplateSegmentAttackRollConditionModifier,
  Dnd5eActionTemplateSegmentAttackRollConditionModifierOperation,
  dnd5eActionTemplateSegmentAttackRollConditionModifierType
} from "./dnd-5e-action-template-segment-attack-roll-condition-modifier.ts";
import {Dnd5eVariableModifier, Dnd5eVariableModifierOperation, dnd5eVariableModifierType} from "../../../dnd-5e-modifier/dnd-5e-variable-modifier.ts";
import {generateDnd5eModifierID} from "../../../dnd-5e-modifier/dnd-5e-modifier-i-d.ts";


export const Dnd5eActionTemplateSegmentAttackRollModifier = z.discriminatedUnion("type", [
  z.object({type: z.literal("variable"), data: Dnd5eVariableModifier}),
  z.object({type: z.literal("action::attack-roll::condition"), data: Dnd5eActionTemplateSegmentAttackRollConditionModifier}),
  z.object({type: z.literal("action::attack-roll::hit-modifier"), data: Dnd5eActionTemplateSegmentAttackRollHitModifier}),
  z.object({type: z.literal("action::attack-roll::critical-range"), data: Dnd5eActionTemplateSegmentAttackRollCriticalRangeModifier}),
  z.object({type: z.literal("action::attack-roll::attribute-override"), data: Dnd5eActionTemplateSegmentAttackRollAttributeOverrideModifier}),
  z.object({type: z.literal("action::attack-roll::proficient-override"), data: Dnd5eActionTemplateSegmentAttackRollProficientOverrideModifier}),
  z.object({type: z.literal("action::attack-roll::advantage-override"), data: Dnd5eActionTemplateSegmentAttackRollAdvantageOverrideModifier}),
  z.object({type: z.literal("action::attack-roll::disadvantage-override"), data: Dnd5eActionTemplateSegmentAttackRollDisadvantageOverrideModifier})
]);
export type Dnd5eActionTemplateSegmentAttackRollModifier = z.infer<typeof Dnd5eActionTemplateSegmentAttackRollModifier>;
export const Dnd5eActionTemplateSegmentAttackRollModifierOperation = z.discriminatedUnion("type", [
  z.object({type: z.literal("variable"), operations: z.array(Dnd5eVariableModifierOperation)}),
  z.object({type: z.literal("action::attack-roll::condition"), operations: z.array(Dnd5eActionTemplateSegmentAttackRollConditionModifierOperation)}),
  z.object({type: z.literal("action::attack-roll::hit-modifier"), operations: z.array(Dnd5eActionTemplateSegmentAttackRollHitModifierOperation)}),
  z.object({type: z.literal("action::attack-roll::critical-range"), operations: z.array(Dnd5eActionTemplateSegmentAttackRollCriticalRangeModifierOperation)}),
  z.object({type: z.literal("action::attack-roll::attribute-override"), operations: z.array(Dnd5eActionTemplateSegmentAttackRollAttributeOverrideModifierOperation)}),
  z.object({type: z.literal("action::attack-roll::proficient-override"), operations: z.array(Dnd5eActionTemplateSegmentAttackRollProficientOverrideModifierOperation)}),
  z.object({type: z.literal("action::attack-roll::advantage-override"), operations: z.array(Dnd5eActionTemplateSegmentAttackRollAdvantageOverrideModifierOperation)}),
  z.object({type: z.literal("action::attack-roll::disadvantage-override"), operations: z.array(Dnd5eActionTemplateSegmentAttackRollDisadvantageOverrideModifierOperation)})
]);
export type Dnd5eActionTemplateSegmentAttackRollModifierOperation = z.infer<typeof Dnd5eActionTemplateSegmentAttackRollModifierOperation>;

export type Dnd5eActionTemplateSegmentAttackRollModifierTypes = {
  "variable": Type<Dnd5eVariableModifier, Dnd5eVariableModifierOperation>;
  "action::attackRoll::condition": Type<Dnd5eActionTemplateSegmentAttackRollConditionModifier, Dnd5eActionTemplateSegmentAttackRollConditionModifierOperation>,
  "action::attackRoll::hitModifier": Type<Dnd5eActionTemplateSegmentAttackRollHitModifier, Dnd5eActionTemplateSegmentAttackRollHitModifierOperation>,
  "action::attackRoll::criticalRange": Type<Dnd5eActionTemplateSegmentAttackRollCriticalRangeModifier, Dnd5eActionTemplateSegmentAttackRollCriticalRangeModifierOperation>,
  "action::attackRoll::attributeOverride": Type<Dnd5eActionTemplateSegmentAttackRollAttributeOverrideModifier, Dnd5eActionTemplateSegmentAttackRollAttributeOverrideModifierOperation>,
  "action::attackRoll::proficientOverride": Type<Dnd5eActionTemplateSegmentAttackRollProficientOverrideModifier, Dnd5eActionTemplateSegmentAttackRollProficientOverrideModifierOperation>,
  "action::attackRoll::advantageOverride": Type<Dnd5eActionTemplateSegmentAttackRollAdvantageOverrideModifier, Dnd5eActionTemplateSegmentAttackRollAdvantageOverrideModifierOperation>,
  "action::attackRoll::disadvantageOverride": Type<Dnd5eActionTemplateSegmentAttackRollDisadvantageOverrideModifier, Dnd5eActionTemplateSegmentAttackRollDisadvantageOverrideModifierOperation>
};

export const dnd5eActionTemplateSegmentAttackRollModifierType: Type<Dnd5eActionTemplateSegmentAttackRollModifier, Dnd5eActionTemplateSegmentAttackRollModifierOperation> = new MultiType<Dnd5eActionTemplateSegmentAttackRollModifierTypes>({
  "variable": dnd5eVariableModifierType,
  "action::attackRoll::condition": dnd5eActionTemplateSegmentAttackRollConditionModifierType,
  "action::attackRoll::hitModifier": dnd5eActionTemplateSegmentAttackRollHitModifierType,
  "action::attackRoll::criticalRange": dnd5eActionTemplateSegmentAttackRollCriticalRangeType,
  "action::attackRoll::attributeOverride": dnd5eActionTemplateSegmentAttackRollAttributeOverrideModifierType,
  "action::attackRoll::proficientOverride": dnd5eActionTemplateSegmentAttackRollProficientOverrideModifierType,
  "action::attackRoll::advantageOverride": dnd5eActionTemplateSegmentAttackRollAdvantageOverrideModifierType,
  "action::attackRoll::disadvantageOverride": dnd5eActionTemplateSegmentAttackRollDisadvantageOverrideModifierType
});

export const Dnd5eActionTemplateSegmentAttackRollModifierFn = {
  copyDnd5eActionTemplateSegmentAttackRollModifier: (value: Dnd5eActionTemplateSegmentAttackRollModifier): Dnd5eActionTemplateSegmentAttackRollModifier => {
    switch (value.type) {
      case "variable": return {...value, data: {...value.data, modifierID: generateDnd5eModifierID()}};
      case "action::attack-roll::condition": return {...value, data: {...value.data, modifierID: generateDnd5eModifierID(), modifiers: value.data.modifiers.map(Dnd5eActionTemplateSegmentAttackRollModifierFn.copyDnd5eActionTemplateSegmentAttackRollModifier)}};
      case "action::attack-roll::hit-modifier": return {...value, data: {...value.data, modifierID: generateDnd5eModifierID()}};
      case "action::attack-roll::attribute-override": return {...value, data: {...value.data, modifierID: generateDnd5eModifierID()}};
      case "action::attack-roll::advantage-override": return {...value, data: {...value.data, modifierID: generateDnd5eModifierID()}};
      case "action::attack-roll::critical-range": return {...value, data: {...value.data, modifierID: generateDnd5eModifierID()}};
      case "action::attack-roll::disadvantage-override": return {...value, data: {...value.data, modifierID: generateDnd5eModifierID()}};
      case "action::attack-roll::proficient-override": return {...value, data: {...value.data, modifierID: generateDnd5eModifierID()}};
    }
  }
}
import React, {forwardRef, useState} from "react";
import {usePopper} from "react-popper";
import {Menu} from "@headlessui/react";
import {Button, ButtonBar, IconButton, Input, InputGroup, InputGroupLabel} from "#lib/components/index.ts";
import {createPortal} from "react-dom";
import {Popper} from "#lib/components/popper/popper.tsx";
import {FaFilter} from "react-icons/fa6";
import {twMerge} from "tailwind-merge";
import {FaSearch, FaTimes} from "react-icons/fa";
import {fuzzySearch} from "#lib/components/fuzzy-search/index.ts";
import {BareIconButton} from "#lib/components/bare-icon-button/index.ts";
import {SheetReference} from "../../../../../common/sheet/sheet-reference.ts";
import {getActionSignal, useSheetActions} from "../../../../sheet/editor/dnd-5e-character/dnd-5e-action/get-action-signal.ts";
import {LoadingDnd5eCharacterCombatAction} from "../../../../sheet/editor/dnd-5e-character/combat/actions/dnd-5e-character-combat-actions.tsx";
import {Optional} from "common/types/index.ts";
import {useSheetSignal} from "../../../../../common/sheet/use-sheet-signal.ts";
import {usePortal} from "#lib/container/react/external-window/external-portal.tsx";
import {MutableRef, Ref} from "common/ref";
import {useRefValue} from "#lib/signal/index.ts";
import {Dnd5eActionTemplate, Dnd5eActionTemplateOperation} from "common/legends/asset/sheet/dnd-5e/dnd-5e-action-definition/template/dnd-5e-action-template.ts";
import {Dnd5eActionType} from "common/legends/asset/sheet/dnd-5e/dnd-5e-action-definition/dnd-5e-action-type.ts";

export type QuickAccessActionsSubMenuProps = {
  sheetRef: Ref<Optional<SheetReference>>;
  root: string;
  options: (Dnd5eActionType|"other")[];
  searchTerm: string;
  expanded: boolean;
};
export const QuickAccessActionsSubMenu = forwardRef(function ({sheetRef, root, options, searchTerm, expanded}: QuickAccessActionsSubMenuProps, ref: React.Ref<HTMLDivElement>) {
  const sheet = useSheetSignal(sheetRef);
  const actions = useRefValue(useSheetActions(sheet))
    .filter(action => action.data.name.startsWith(root))
    .filter(action => isActionType(action, options));

  const subSections = new Set(actions
    .filter(action => action.data.name.substring(root.length).includes("/"))
    .filter(action => fuzzySearch(searchTerm.toLowerCase(), action.data.name.toLowerCase()))
    .map(action => {
      let remaining = action.data.name.substring(root.length);
      remaining = remaining.substring(0, remaining.indexOf("/"));
      return remaining;
    }));

  if (actions.length === 0) return <></>;
  return (<Menu ref={ref} as="div" className="flex flex-col">
    <Menu.Button as={Button} className="flex-1 text-h300">{root.substring(root.lastIndexOf("/", root.lastIndexOf("/") - 1) + 1, root.lastIndexOf("/")).trim()}</Menu.Button>
    <Menu.Items static={expanded} className={"flex flex-col mt-0.5 mb-1 gap-0.5"}>
      {[...subSections.values()]
        .map(subSection => <Menu.Item as={QuickAccessActionsSubMenu} key={subSection} sheetRef={sheetRef} root={root + subSection + "/"} options={options} searchTerm={searchTerm} expanded={expanded} />)}
      {actions
        .filter(action => fuzzySearch(searchTerm.toLowerCase(), action.data.name.toLowerCase()))
        .filter(action => !(action.data.name.substring(root.length).includes("/")))
        .map(action => <Menu.Item key={action.data.actionTemplateID} as={LoadingDnd5eCharacterCombatAction} sheetRef={sheet} actionRef={getActionSignal(sheet, action.data.actionTemplateID)} />)}
    </Menu.Items>
  </Menu>);
});

function isActionType(action: Dnd5eActionTemplate, options: (Dnd5eActionType | "other")[]) {
  return (
    (options.includes("action") && action.data.actionType === "action") ||
    (options.includes("bonus action") && action.data.actionType === "bonus action") ||
    (options.includes("reaction") && action.data.actionType === "reaction") ||
    (options.includes("other") && !(["action", "bonus action", "reaction"].includes(action.data.actionType)))
  );
}

export type QuickAccessActionsMenuProps = {
  sheetRef: Ref<Optional<SheetReference>>;
};
export function QuickAccessActionsMenu({sheetRef}: QuickAccessActionsMenuProps) {
  const root = "";
  const [searchTerm, setSearchTerm] = useState("");
  const sheet = useSheetSignal(sheetRef);
  const actions = useRefValue(useSheetActions(sheet))
    .filter(action => action.data.name.startsWith(root));

  const favoriteActions = actions.filter(action => action.data.favorite);

  const subSections = new Set(actions
    .filter(action => fuzzySearch(searchTerm.toLowerCase(), action.data.name.toLowerCase()))
    .filter(action => action.data.name.substring(root.length).includes("/")).map(action => {
      let remaining = action.data.name.substring(root.length);
      remaining = remaining.substring(0, remaining.indexOf("/"));
      return remaining;
    }));

  const [referenceElement, setReferenceElement] = useState<HTMLElement | null>(null);
  const [popperElement, setPopperElement] = useState<HTMLElement | null>(null);
  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    placement: "bottom-start"
  });

  const [options, setOptions] = useState<(Dnd5eActionType | "other")[]>(["action", "bonus action", "reaction", "other"]);
  const toggleOption = (option: (Dnd5eActionType | "other")) => {
    setOptions(prev => prev.includes(option) ? prev.filter(a => a !== option) : [...prev, option]);
  };
  const isSelected = (option: (Dnd5eActionType | "other")) => {
    return options.includes(option);
  };

  const portal = usePortal();

  if (actions.length === 0) return <></>;
  return (<Menu as="div" ref={ref => setReferenceElement(ref)}>
    <Menu.Button as={Button}>Actions</Menu.Button>
    {createPortal(<Menu.Items as={Popper} ref={ref => setPopperElement(ref)} style={styles.popper} {...attributes.popper} className="mt-4 min-w-[640px] max-w-screen-2xl">
      <ButtonBar>
        <Menu>
          <Menu.Button as={IconButton} title="Filter" className={twMerge("shrink-0", options.length === 4 && "text-white bg-blue-950 hover:bg-blue-900 focus:bg-blue-900")}>
            <FaFilter />
          </Menu.Button>
          <Menu.Items as="div" className="flex flex-row gap-0.5">
            <IconButton title="Action" className={twMerge("shrink-0", isSelected("action") && "text-white bg-blue-950 hover:bg-blue-900 focus:bg-blue-900")} onClick={() => toggleOption("action")}>A</IconButton>
            <IconButton title="Bonus Action" className={twMerge("shrink-0", isSelected("bonus action") && "text-white bg-blue-950 hover:bg-blue-900 focus:bg-blue-900")} onClick={() => toggleOption("bonus action")}>BA</IconButton>
            <IconButton title="Reaction" className={twMerge("shrink-0", isSelected("reaction") && "text-white bg-blue-950 hover:bg-blue-900 focus:bg-blue-900")} onClick={() => toggleOption("reaction")}>R</IconButton>
            <IconButton title="Other" className={twMerge("shrink-0", isSelected("other") && "text-white bg-blue-950 hover:bg-blue-900 focus:bg-blue-900")} onClick={() => toggleOption("other")}>O</IconButton>
          </Menu.Items>
        </Menu>
        <InputGroup title="Search" className="px-2 gap-2 flex-1">
          <InputGroupLabel className="px-0"><FaSearch /></InputGroupLabel>
          <Input type="text" autoFocus placeholder="Search..." value={searchTerm} onChange={ev => setSearchTerm(ev.target.value)} />
          {searchTerm !== "" && <BareIconButton title="Clear Search" disabled={searchTerm === ""} onClick={() => setSearchTerm("")}><FaTimes /></BareIconButton>}
        </InputGroup>
      </ButtonBar>

      {favoriteActions
        .filter(action => isActionType(action, options))
        .filter(action => fuzzySearch(searchTerm.toLowerCase(), action.data.name.toLowerCase()))
        .map(action => <Menu.Item key={action.data.actionTemplateID} as={LoadingDnd5eCharacterCombatAction} sheetRef={sheet} actionRef={getActionSignal(sheet, action.data.actionTemplateID) as MutableRef<Dnd5eActionTemplate, Dnd5eActionTemplateOperation[]>} />)}

      {[...subSections.values()]
        .map(subSection => <Menu.Item as={QuickAccessActionsSubMenu} key={subSection} sheetRef={sheetRef} root={root + subSection + "/"} options={options} searchTerm={searchTerm} expanded={searchTerm.length > 0} />)}
      {actions
        .filter(action => !action.data.name.substring(root.length).includes("/"))
        .filter(action => !action.data.favorite)
        .filter(action => fuzzySearch(searchTerm.toLowerCase(), action.data.name.toLowerCase()))
        .filter(action => isActionType(action, options))
        .map(action => <Menu.Item key={action.data.actionTemplateID} as={LoadingDnd5eCharacterCombatAction} sheetRef={sheet} actionRef={getActionSignal(sheet, action.data.actionTemplateID) as MutableRef<Dnd5eActionTemplate, Dnd5eActionTemplateOperation[]>} />)}
    </Menu.Items>, portal)}
  </Menu>);
}



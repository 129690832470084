import {useMemo} from "react";
import {PointSignals} from "common/types/index.ts";
import {IconButton, InputGroup, InputGroupLabel, InputNumber} from "#lib/components/index.ts";
import {FaTrash} from "react-icons/fa";
import {WallGraphEdge, WallGraphEdgeOperation, WallGraphEdgeSignals} from "common/legends/node/wall-node.ts";
import {MutableRef} from "common/ref";
import {useRefValue} from "#lib/signal/index.ts";
import {ColorField} from "#lib/components/input/color-field.tsx";

export type InputWallGraphEdgeProps = {
  value: MutableRef<WallGraphEdge, WallGraphEdgeOperation[]>;
  remove?: () => void;
}
export function InputWallGraphEdge({value, remove}: InputWallGraphEdgeProps) {
  const {controlPoint1, controlPoint2, resolution, blockVisibilityLeft, blockVisibilityRight, tintRef} = useMemo(() => WallGraphEdgeSignals(value), [value]);
  const controlPoints1Signals = useMemo(() => PointSignals(controlPoint1), [controlPoint1]);
  const controlPoints2Signals = useMemo(() => PointSignals(controlPoint2), [controlPoint2]);

  const blockRight = useRefValue(blockVisibilityRight);
  const blockLeft = useRefValue(blockVisibilityLeft);
  const isWindow = !(blockLeft && blockRight);

  return <div className="flex flex-row rounded-md overflow-hidden">
    <InputGroup>
      <InputGroupLabel>CP1</InputGroupLabel>
    </InputGroup>
    <InputGroup className="pr-0">
      <InputGroupLabel>X</InputGroupLabel>
      <InputNumber className="w-12 px-0" value={controlPoints1Signals.x}/>
    </InputGroup>
    <InputGroup className="pr-0">
      <InputGroupLabel>Y</InputGroupLabel>
      <InputNumber className="w-12 px-0" value={controlPoints1Signals.y} />
    </InputGroup>

    <InputGroup>
      <InputGroupLabel>CP2</InputGroupLabel>
    </InputGroup>
    <InputGroup className="pr-0">
      <InputGroupLabel>X</InputGroupLabel>
      <InputNumber className="w-12 px-0" value={controlPoints2Signals.x}/>
    </InputGroup>
    <InputGroup className="pr-0">
      <InputGroupLabel>Y</InputGroupLabel>
      <InputNumber className="w-12 px-0" value={controlPoints2Signals.y} />
    </InputGroup>
    <InputGroup className="pr-0">
      <InputGroupLabel>Resolution</InputGroupLabel>
      <InputNumber className="w-12 px-0" value={resolution} />
    </InputGroup>

    {isWindow && <>
      <ColorField className="min-w-24" value={tintRef} />
    </>}

    {remove && <IconButton variant="destructive" onClick={remove}>
      <FaTrash />
    </IconButton>}
  </div>
}

import {InputGroup, InputNumber} from "#lib/components/index.ts";
import {Point, PointOperation, PointSignals} from "common/types/index.ts";
import {useMemo} from "react";
import {MutableRef} from "common/ref";
import {Field, FieldLabel} from "#lib/components/panel-header.tsx";
import {InputGroupIcon} from "#lib/components/input/input-group-icon.tsx";

export function OffsetFields({valueRef}: {
  valueRef: MutableRef<Point, PointOperation[]>;
}) {
  const {x, y} = useMemo(() => PointSignals(valueRef), [valueRef]);

  return (<Field>
    <FieldLabel>Offset</FieldLabel>
    <div className="mx-2 flex flex-row gap-1 rounded-md overflow-hidden">
      <InputGroup className="flex-1">
        <InputGroupIcon>X</InputGroupIcon>
        <InputNumber value={x} />
      </InputGroup>
      <InputGroup className="flex-1">
        <InputGroupIcon>Y</InputGroupIcon>
        <InputNumber value={y} />
      </InputGroup>
    </div>
  </Field>);
}
import {LightShapeSpotlight} from "common/legends/node/light/light-shape-spotlight.ts";
import React, {useState} from "react";
import {Point} from "common/types/generic/index.ts";
import {LightTextureShader} from "./light-texture-shader.tsx";
import {LightSpotlightShader} from "../../../shader/light/light-spotlight-shader.tsx";
import {WallTintShader} from "../../../shader/wall-tint-shader.tsx";
import {BlendEquationSeparate} from "#lib/gl-react/component/opengl/blend-equation-separate.tsx";
import {BlendFuncSeperate} from "#lib/gl-react/component/opengl/blend-func-seperate.tsx";
import {TextureMaskShader} from "../../../shader/texture-mask-shader.tsx";
import {Framebuffer} from "../../../shader/framebuffer.tsx";
import {WallMaskFramebuffer} from "../../../shader/wall-mask-framebuffer.tsx";

export function LightShapeSpotlightLightPass({value, origin}: {
  value: LightShapeSpotlight
  origin: Point
}) {
  const [lightTexture, setLightTexture] = useState<WebGLTexture | null>(null);
  const [wallTexture, setWallTexture] = useState<WebGLTexture | null>(null);

  return (<binder>
    <Framebuffer ref={setLightTexture}>
      <BlendEquationSeparate rgb={WebGL2RenderingContext.FUNC_ADD} alpha={WebGL2RenderingContext.MAX}>
        <BlendFuncSeperate
          srcRGB={WebGL2RenderingContext.SRC_COLOR} dstRGB={WebGL2RenderingContext.ONE_MINUS_SRC_COLOR}
          srcAlpha={WebGL2RenderingContext.SRC_ALPHA} dstAlpha={WebGL2RenderingContext.DST_ALPHA}>
          <LightSpotlightShader value={value} origin={origin}/>
          <WallTintShader origin={[0, 0]} />
        </BlendFuncSeperate>
      </BlendEquationSeparate>
    </Framebuffer>

    <WallMaskFramebuffer ref={setWallTexture} origin={[0, 0]}/>

    <LightTextureShader>
      {lightTexture && wallTexture && <TextureMaskShader texture={lightTexture} mask={wallTexture}/>}
    </LightTextureShader>
  </binder>);
}
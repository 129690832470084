import {Spline, SplineOperation, splineType} from "../../../types/generic/spline/index.ts";
import {ColorOperation, constantType, HSLA, ListOperation, ListType, NumberOperation, numberType, ObjectType, Type, ValueType, ZodListOperation} from "../../../types/index.ts";
import {z} from "zod";
import {MutableRef} from "#common/ref";

export const LightShapeFreeform = z.object({
  areas: z.array(Spline),
  intensity: z.number(),
  color: HSLA,
  falloff: z.number(),
  falloffStrength: z.number()
});
export type LightShapeFreeform = z.infer<typeof LightShapeFreeform>;
export const LightShapeFreeformOperation = z.discriminatedUnion("type", [
  z.object({type: z.literal("update-areas"), operations: z.array(ZodListOperation(Spline, SplineOperation))}),
  z.object({type: z.literal("update-intensity"), operations: z.array(NumberOperation)}),
  z.object({type: z.literal("update-color"), operations: z.array(ColorOperation)}),
  z.object({type: z.literal("update-falloff"), operations: z.array(NumberOperation)}),
  z.object({type: z.literal("update-falloff-strength"), operations: z.array(NumberOperation)})
]);
export type LightShapeFreeformOperation = z.infer<typeof LightShapeFreeformOperation>;

export type LightShapeFreeformType = Type<LightShapeFreeform, LightShapeFreeformOperation>;

export const lightShapeFreeformType: LightShapeFreeformType = new ObjectType({
  areas: new ListType(splineType),
  intensity: numberType,
  color: new ValueType(constantType),
  falloff: numberType,
  falloffStrength: numberType
});

export function LightShapeFreeformRef(valueRef: MutableRef<LightShapeFreeform, LightShapeFreeformOperation[]>) {
  return {
    areasRef: valueRef.map<Spline[], ListOperation<Spline, SplineOperation>[]>(value => value.areas, (_, operations) => [{type: "update-areas", operations}]),
    intensityRef: valueRef.map<number, NumberOperation[]>(value => value.intensity, (_, operations) => [{type: "update-intensity", operations}]),
    colorRef: valueRef.map<HSLA, ColorOperation[]>(value => value.color, (_, operations) => [{type: "update-color", operations}]),
    falloffRef: valueRef.map<number, NumberOperation[]>(value => value.falloff, (_, operations) => [{type: "update-falloff", operations}]),
    falloffStrengthRef: valueRef.map<number, NumberOperation[]>(value => value.falloffStrength, (_, operations) => [{type: "update-falloff-strength", operations}])
  };
}

import {FileReference, Point} from "common/types/index.ts";
import React, {useMemo} from "react";
import {Matrix4f} from "#lib/math/index.ts";
import {usePVM} from "../../context/pvm-context.ts";
import {useVideoTexture} from "../../context/use-video-texture.ts";
import {ImageShader} from "../../shader/image-shader.tsx";

function useIsVideoOnScreen(origin: Point, size: Point) {
  const {projection, view, model} = usePVM();
  return useMemo(() => {
    if (projection.some(v => Number.isNaN(v) || !Number.isFinite(v))) return undefined;
    const pvm = Matrix4f.multiplyMatrix(Matrix4f.multiplyMatrix(
      projection,
      Matrix4f.transform(view),
    ), Matrix4f.transform(model));

    const points = [
      [        - origin[0],         - origin[1]],
      [size[0] - origin[0],         - origin[1]],
      [size[0] - origin[0], size[1] - origin[1]],
      [        - origin[0], size[1] - origin[1]]
    ]
      .map(point => Matrix4f.multiplyVector(pvm, [point[0], point[1], 0, 1]));
    return (
      points.every(p => p[0] < -1.25) ||
      points.every(p => p[0]  > 1.25) ||
      points.every(p => p[1] < -1.25) ||
      points.every(p => p[1]  > 1.25)
    );
  }, [projection, view, model, origin, size]);
}

export function VideoView({file, normal, repeatX = 1, repeatY = 1, ...props}: {
  file: FileReference;
  normal?: FileReference;
  size: Point;
  origin: Point;
  pivot: Point;
  opacity: number;
  repeatX?: number | null;
  repeatY?: number | null;
}) {
  // is image on screen?
  const isOnScreen = useIsVideoOnScreen(props.origin, props.size);

  const [isLoaded, imageTexture] = useVideoTexture(file);
  const [isNormalLoaded, normalTexture] = useVideoTexture(normal);
  if (!isLoaded || (normal && !isNormalLoaded) || isOnScreen) return <></>;
  return <ImageShader {...props} albedoTexture={imageTexture} normalTexture={normal ? normalTexture : undefined} repeatX={repeatX} repeatY={repeatY} />;
}


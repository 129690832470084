import {useDatabase} from "../../../routes/game/model/store-context.tsx";
import {useCallback} from "react";
import {Node, NodeOperation} from "common/legends/index.ts";
import {ListOperation, Tree, TreeOperation, ValueFn} from "common/types/index.ts";
import {NodePath} from "../common/context/get-nodes-at-point.ts";

export function useApplyToNode() {
  const database = useDatabase();
  return useCallback((nodePath: NodePath, fn: (node: Node) => NodeOperation[]) => {
    return database.apply(database => {
      if (nodePath?.type === "scene") {
        const sceneID = nodePath.sceneID;
        const resource = database.resources[sceneID];
        if (resource?.type !== "scene") return [];
        const node = Tree.getItemByPath(resource.data.children, nodePath.path);
        if (node === undefined) return [];
        const operations = fn(node);
        if (operations.length === 0) return [];
        return [{
          type: "resource", resourceID: sceneID, operations: ValueFn.apply([{
            type: "scene", operations: [{
              type: "update-children",
              operations: TreeOperation.apply(nodePath.path, operations)
            }]
          }])
        }];
      } else if (nodePath?.type === "asset") {
        const assetID = nodePath.assetID;
        const resource = database.resources[assetID];
        if (resource?.type !== "asset") return [];
        const tokenID = nodePath.tokenID;
        const tokenIndex = resource.data.tokens.findIndex(token => token.tokenID === tokenID);
        if (tokenIndex === -1) return [];
        const token = resource.data.tokens[tokenIndex];
        const path = nodePath.path;
        const node = Tree.getItemByPath(token.children, path);
        if (node === undefined) return [];
        const operations = fn(node);
        if (operations.length === 0) return [];
        return [{type: "resource", resourceID: assetID, operations: ValueFn.apply([{
            type: "asset", operations: [{
              type: "update-tokens", operations: ListOperation.apply(tokenIndex, [{
                type: "update-children", operations: TreeOperation.apply(path, operations)
              }])
            }]
          }])}];
      } else {
        return [];
      }
    });
  }, [database]);
}

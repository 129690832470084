import {SetOperation} from "common/types/index.ts";
import React, {useContext} from "react";
import {MutableRef} from "common/ref";
import {NodeSelectionRef} from "../../../panel/nav/editor/state/selection-ref.ts";

const SelectedNodeIDsContext = React.createContext<MutableRef<NodeSelectionRef[], SetOperation<NodeSelectionRef>[]> | undefined>(undefined);

export const SelectedNodeIDsProvider = SelectedNodeIDsContext.Provider;

export function useSelectedNodeIDs(): MutableRef<NodeSelectionRef[], SetOperation<NodeSelectionRef>[]> {
  return useContext(SelectedNodeIDsContext)!;
}

import React, {useMemo} from "react";
import {InputGroup, InputGroupLabel, InputNumber} from "#lib/components/index.ts";
import {FaHeart} from "react-icons/fa6";
import {SheetReference} from "../../../../../common/sheet/sheet-reference.ts";
import {HealthIndicatorRef, useSheetHitPointsSignals} from "../../../../sheet/editor/dnd-5e-character/dnd-5e-action/use-sheet-hit-points.ts";
import {useSheetSignal} from "../../../../../common/sheet/use-sheet-signal.ts";
import {Ref} from "common/ref";
import {useComputedValue} from "#lib/signal/index.ts";

export type QuickAccessHitPointsMenuProps = {
  sheetRef: Ref<SheetReference | undefined>;
};
export function QuickAccessHitPointsMenu({sheetRef}: QuickAccessHitPointsMenuProps) {
  const sheet = useSheetSignal(sheetRef);
  const healthIndicator = useSheetHitPointsSignals(sheet);
  const {currentRef, tempRef, maxRef} = useMemo(() => HealthIndicatorRef(healthIndicator), [healthIndicator]);
  const hasHealth = useComputedValue(healthIndicator, healthIndicator => healthIndicator.max > 0);
  if (!hasHealth) return <></>;
  return (<>
    <InputGroup className="pl-0 pr-2 gap-0">
      <InputGroupLabel title="Hit Points" className="w-8 flex items-center justify-center text-red-700 group-focus-within:text-red-400"><FaHeart /></InputGroupLabel>
      <div className="flex flex-row gap-2">
        <InputNumber className="w-8 text-center" value={currentRef} />
        <InputGroupLabel>/</InputGroupLabel>
        <InputNumber readOnly className="w-8 text-center" value={maxRef} />
      </div>
    </InputGroup>
    <InputGroup className="pl-0 pr-2 gap-0">
      <InputGroupLabel title="Temp" className="w-8 flex items-center justify-center">T</InputGroupLabel>
      <InputNumber className="w-8 text-center" value={tempRef} />
    </InputGroup>
  </>);
}

import {useMemo} from "react";
import {ListOperation, Optional, Tree, TreeOperation, ValueFn} from "common/types/index.ts";
import {Node, NodeId, NodeOperation} from "common/legends/index.ts";
import {MutableRef} from "common/ref";
import {Spline, SplineOperation} from "common/types/generic/spline/index.ts";
import {AreaToolData, AreaToolDataOperation} from "../../../../../common/tool/area/area-tool-data.ts";

export function useEditorAreaNode(
  areaToolRef: MutableRef<Optional<AreaToolData>, AreaToolDataOperation[]>,
  nodesRef: MutableRef<Node[], TreeOperation<Node, NodeOperation>[]>
) {
  return useMemo((): MutableRef<Optional<Spline[]>, ListOperation<Spline, SplineOperation>[]> => {
    const valueFn = (nodes: Node[], nodeID: Optional<NodeId>): Optional<Spline[]> => {
      const node = nodeID ? Tree.getItemById(nodes, nodeID) : undefined;
      if (node?.type === "area") return node?.data.areas;
      if (node?.type === "light" && node.data.shape?.type === "freeform") return node.data.shape.data.areas;
      return undefined;
    };

    const ref = MutableRef.all(areaToolRef, nodesRef).map(([areaTool, nodes]) => valueFn(nodes, areaTool?.nodeID));

    return new MutableRef<Optional<Spline[]>, ListOperation<Spline, SplineOperation>[]>({
      value: () => ref.value,
      observe: ref.observe,
      apply: (fn) => {
        const nodeID = areaToolRef.value?.nodeID;
        return nodesRef.apply((prev): TreeOperation<Node, NodeOperation>[] => {
          const nodePath = Tree.getPath(prev, node => node.data.id === nodeID);
          if (nodePath === undefined) return [];
          const node = Tree.getNode(prev, nodePath);
          if (node?.type === "area") {
            const operations = fn(node.data.areas);
            if (operations.length === 0) return [];
            return TreeOperation.apply(nodePath, [{type: "area", operations: [{type: "update-areas", operations}]}]);
          } else if (node?.type === "light" && node.data.shape?.type === "freeform") {
            const operations = fn(node.data.shape.data.areas);
            if (operations.length === 0) return [];
            return TreeOperation.apply(nodePath, [{type: "light", operations: [{
              type: "update-shape", operations: ValueFn.apply([{
                type: "freeform", operations: [{type: "update-areas", operations}]
              }])
            }]}]);
          } else return [];
        }).then(nodes => valueFn(nodes, nodeID))
      }
    });
  }, [nodesRef, areaToolRef]);
}

import {ExpandableLabel, IconButton, InputGroup, InputGroupLabel, useToggle} from "#lib/components/index.ts";
import {Fieldset} from "#lib/components/fieldset/fieldset.tsx";
import {FaTrash} from "react-icons/fa";
import React, {Ref, useMemo} from "react";
import {DND_5E_ATTRIBUTE_TITLE, DND_5E_ATTRIBUTES, DND_5E_SKILL_ATTRIBUTE, DND_5E_SKILLS, DND_5E_TOOLS, isDnd5eAttribute, isDnd5eSkill, isDnd5eTool} from "common/legends/index.ts";
import {
  Dnd5eAbilityCheckModifier,
  Dnd5eAbilityCheckModifierOperation,
  Dnd5eAbilityCheckModifierSignals
} from "common/legends/asset/sheet/dnd-5e/dnd-5e-modifier/dnd-5e-ability-check-modifier.ts";
import {InputDiceExpression} from "../../dice-expression/input-dice-expression.tsx";
import {DragIndicator} from "#lib/components/tree-view/drag-indicator.tsx";
import {InputDnd5eAbilityChecks} from "./input-dnd-5e-ability-checks.tsx";
import {useRefValue} from "#lib/signal/index.ts";
import {InputDnd5eAbilityCheckProficiency} from "./input-dnd-5e-ability-check-proficiency.tsx";
import {InputCheckbox} from "#lib/components/input/input-checkbox.tsx";
import {MutableRef} from "common/ref";

export type Dnd5eAbilityCheckModifierViewProps = {
  item: MutableRef<Dnd5eAbilityCheckModifier, Dnd5eAbilityCheckModifierOperation[]>;
  dragHandlerRef: Ref<HTMLButtonElement>;
  dragRefPreview: Ref<HTMLDivElement>;
  remove: () => void;
  readOnly?: boolean;
};

export function Dnd5eAbilityCheckModifierView({item, remove, dragHandlerRef, dragRefPreview, readOnly}: Dnd5eAbilityCheckModifierViewProps) {
  const {abilityChecks, expression, proficiency, hasAdvantage, hasDisadvantage} = useMemo(() => Dnd5eAbilityCheckModifierSignals(item), [item]);
  const [expanded, toggleExpand] = useToggle(false);

  const abilityChecksValue = useRefValue(abilityChecks);
  const allAbilityChecks = DND_5E_ATTRIBUTES.every(attribute => abilityChecksValue.includes(attribute));
  const allSkills = DND_5E_SKILLS.every(skill => abilityChecksValue.includes(skill));
  const allToolsChecks = DND_5E_TOOLS.every(attribute => abilityChecksValue.includes(attribute));

  let summary = [];
  if (allAbilityChecks) {
    summary.push("All Checks");
  } else {
    summary.push(...abilityChecksValue.filter(isDnd5eAttribute).map(attribute => DND_5E_ATTRIBUTE_TITLE[attribute]));
    if (allSkills) summary.push("All Skills");
    else summary.push(...abilityChecksValue.filter(isDnd5eSkill).filter(skill => !abilityChecksValue.includes(DND_5E_SKILL_ATTRIBUTE[skill])));
    if (allToolsChecks) summary.push("All Tools");
    else summary.push(...abilityChecksValue.filter(isDnd5eTool));
    if (!abilityChecksValue.includes("dex") && abilityChecksValue.includes("initiative")) summary.push("Initiative");
  }

  return <div role="list-item" className="flex flex-col">
    <ExpandableLabel ref={dragRefPreview} expanded={expanded} toggleExpand={readOnly ? undefined : toggleExpand}>
      <IconButton ref={dragHandlerRef} title="Move"><DragIndicator /></IconButton>
      <InputGroup>
        <InputGroupLabel title={summary.length > 0 ? summary.join(", ") : "None"}>Ability Check</InputGroupLabel>
      </InputGroup>
      <InputGroup className="flex-1">
        <InputGroupLabel title={summary.length > 0 ? summary.join(", ") : "None"}>
            <span className="overflow-hidden overflow-ellipsis whitespace-nowrap capitalize">
              {summary.length > 0 ? summary.join(", ") : "None"}
            </span>
        </InputGroupLabel>
      </InputGroup>
    {!readOnly && <IconButton title="Remove" variant="destructive" onClick={() => remove()}><FaTrash/></IconButton>}
    </ExpandableLabel>
    {expanded && <Fieldset className="flex flex-col bg-zinc-800/80">
      <InputDnd5eAbilityChecks item={abilityChecks} />
      <InputGroup className="pr-0">
        <InputGroupLabel>Proficiency</InputGroupLabel>
        <InputDnd5eAbilityCheckProficiency value={proficiency} />
      </InputGroup>
      <InputGroup>
        <InputGroupLabel>Modifier</InputGroupLabel>
        <InputDiceExpression readOnly={readOnly} value={expression} />
      </InputGroup>
      <InputGroup className="pl-0">
        <InputCheckbox value={hasAdvantage} />
        <InputGroupLabel>Has Advantage</InputGroupLabel>
      </InputGroup>
      <InputGroup className="pl-0">
        <InputCheckbox value={hasDisadvantage} />
        <InputGroupLabel>Has Disadvantage</InputGroupLabel>
      </InputGroup>
    </Fieldset>}
  </div>;
}

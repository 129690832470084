import {Optional} from "common/types/generic/index.ts";
import {Dnd5eAttribute, Dnd5eFeature, Sheet} from "common/legends/asset/index.ts";
import {DiceExpression} from "common/dice/dice-expression.ts";
import {Dnd5eActionTemplateSegment} from "common/legends/asset/sheet/dnd-5e/dnd-5e-action-definition/segment/dnd-5e-action-template-segment.ts";
import {getActiveModifiers} from "common/legends/asset/sheet/dnd-5e/dnd-5e-modifier-helper.ts";
import {Dnd5eActionTemplate} from "common/legends/asset/sheet/dnd-5e/dnd-5e-action-definition/template/dnd-5e-action-template.ts";
import {getActiveCriticalRange} from "./get-active-critical-range.ts";

export function getActiveBaseAttackRoll(
  sheet: Optional<Sheet>,
  globalFeatures: Dnd5eFeature[],
  action: Optional<Dnd5eActionTemplate>,
  segment: Optional<Dnd5eActionTemplateSegment>,

  attribute: Optional<Dnd5eAttribute>,
  hasAdvantage: boolean,
  hasDisadvantage: boolean
): DiceExpression {
  const modifiers = getActiveModifiers(sheet, globalFeatures);
  const hasElvenAccuracy = modifiers.some(m => m.type === "trait" && m.data.trait === "elven-accuracy");
  const hasHalflingLuck = modifiers.some(m => m.type === "trait" && m.data.trait === "halfling-luck");

  const criticalRange =
    DiceExpression.parse([
        (segment?.type === "attack-roll" ? segment.data.criticalRange : 20),
        getActiveCriticalRange(sheet, globalFeatures, action, segment)
    ].filter(expression => expression !== "0").join("+").replaceAll("+-", "-"));

  let baseRoll = `1d20${hasHalflingLuck ? "ro1" : ""}`;
  if (hasAdvantage && !hasDisadvantage) baseRoll = `${hasElvenAccuracy && attribute !== undefined && ["dex", "int", "wis", "cha"].includes(attribute) ? "3" : "2"}d20kh1${hasHalflingLuck ? "ro1" : ""}`;
  else if (!hasAdvantage && hasDisadvantage) baseRoll = `2d20kl1${hasHalflingLuck ? "ro1" : ""}`;
  return DiceExpression.parse(`${baseRoll}cs>=(${criticalRange})cf=1`);
}

import {
  ConstantOperation,
  constantType,
  ListOperation,
  ListPropertyRef,
  ListType,
  MapOperation,
  MapPropertyRef,
  MapType,
  NumberOperation,
  numberType,
  ObjectType,
  PropertyRef,
  RichTextFn,
  SetOperation,
  SetPropertySignal,
  SetType,
  StringOperation,
  stringType,
  Type,
  ValueOperation,
  ValuePropertyRef,
  ValueType
} from "#common/types/index.ts";
import {Dnd5eInventoryItem, Dnd5eInventoryItemOperation, dnd5eInventoryItemType} from "./dnd-5e-item.ts";
import {Dnd5eArmor} from "./dnd-5e-armor.ts";
import {Dnd5eArmorProficiency} from "./dnd-5e-armor-proficiency.ts";
import {Dnd5eWeapon} from "./dnd-5e-weapon.ts";
import {Dnd5eWeaponProficiency} from "./dnd-5e-weapon-proficiency.ts";
import {Dnd5eTool} from "./dnd-5e-tool.ts";
import {Dnd5eAttribute, Dnd5eAttributeFn} from "./dnd-5e-attribute.ts";
import {Dnd5eSkill} from "./dnd-5e-skill.ts";
import {Dnd5eMovementType} from "./dnd-5e-movement-type.ts";
import {Dnd5eSpellLevel} from "./dnd-5e-spell-level.ts";
import {Dnd5eSpell, Dnd5eSpellOperation, dnd5eSpellType} from "./dnd-5e-spell.ts";
import {Dnd5eAlignment} from "./dnd-5e-alignment.ts";
import {Dnd5eCharacterExperience, Dnd5eCharacterExperienceOperation, dnd5eCharacterExperienceType} from "./dnd-5e-character-experience.ts";
import {dnd5eCharacterHitPointsType, Dnd5eHealth, Dnd5eHealthOperation} from "../dnd-5e-health.ts";
import {Dnd5eExhaustionLevel, exhaustionLevelType} from "./dnd-5e-exhaustion-level.ts";
import {Dnd5eCharacterClass, Dnd5eCharacterClassOperation, dnd5eCharacterClassType} from "./dnd-5e-character-class.ts";
import {Dnd5eBackground, Dnd5eBackgroundOperation, dnd5eBackgroundType} from "./dnd-5e-background.ts";
import {Dnd5eLanguage} from "./dnd-5e-language.ts";
import {Dnd5eLanguageProficiency} from "./dnd-5e-language-proficiency.ts";
import {Dnd5eRace, Dnd5eRaceOperation, dnd5eRaceType} from "./dnd-5e-race.ts";
import {Dnd5eCharacterDeathSaves, Dnd5eCharacterDeathSavesOperation, dnd5eCharacterDeathSavesType} from "./dnd-5e-character-death-saves.ts";
import {Dnd5eCondition} from "#common/legends/asset/sheet/dnd-5e/dnd-5e-condition/index.ts";
import {Dnd5eModifier} from "#common/legends/asset/sheet/dnd-5e/dnd-5e-modifier/dnd-5e-modifier.ts";
import {generateDnd5eModifierID} from "#common/legends/asset/sheet/dnd-5e/dnd-5e-modifier/dnd-5e-modifier-i-d.ts";
import {MathExpressionFn} from "#common/math/index.ts";
import {Dnd5eFeature, Dnd5eFeatureOperation, dnd5eFeatureType} from "#common/legends/index.ts";
import {Dnd5eFeatureID, generateDnd5eFeatureID} from "#common/legends/asset/sheet/dnd-5e/dnd-5e-feature/dnd-5e-feature-id.ts";
import {Dnd5eFeatureOverride, Dnd5eFeatureOverrideOperation, dnd5eFeatureOverrideType} from "#common/legends/game/system/dnd-5e-feature-override.ts";
import {Dice} from "#common/dice/index.ts";
import {MutableRef} from "#common/ref";
import {actionTemplate_upgradeDnd5eCharacter} from "./upgrade/dnd-5e-action-template-upgrade.ts";

export type Dnd5eCharacter = {
  name: string;
  alignment: Dnd5eAlignment;
  race: Dnd5eRace;
  background: Dnd5eBackground;
  classes: Dnd5eCharacterClass[];
  experience: Dnd5eCharacterExperience;
  hitPoints: Dnd5eHealth;
  deathSaves: Dnd5eCharacterDeathSaves;
  exhaustionLevel: Dnd5eExhaustionLevel;
  movementSpeeds: {[movementType in Dnd5eMovementType]?: number};
  attributes: Record<Dnd5eAttribute, number>;
  weaponProficiencies: {[weapon in Dnd5eWeapon]?: Dnd5eWeaponProficiency};
  armorProficiencies: {[armor in Dnd5eArmor]?: Dnd5eArmorProficiency};
  languageProficiencies: {[language in Dnd5eLanguage]?: Dnd5eLanguageProficiency};
  inventory: Dnd5eInventoryItem[];
  spells: Dnd5eSpell[];
  spellSlots: {[level in Dnd5eSpellLevel]: number};
  pactSlots: number;
  features: Dnd5eFeature[];
  globalFeatures: {[featureID in Dnd5eFeatureID]: Dnd5eFeatureOverride};
  conditions: Dnd5eCondition[];
};

export const Dnd5eCharacter = {
  getName: (value: Dnd5eCharacter) => value.name,
  getAlignment: (value: Dnd5eCharacter) => value.alignment,
  getBackground: (value: Dnd5eCharacter) => value.background,
  getRace: (value: Dnd5eCharacter) => value.race,
  getClasses: (value: Dnd5eCharacter) => value.classes,
  getExperience: (value: Dnd5eCharacter) => value.experience,
  getHitPoints: (value: Dnd5eCharacter) => value.hitPoints,
  getDeathSaves: (value: Dnd5eCharacter) => value.deathSaves,
  getExhaustionLevel: (value: Dnd5eCharacter) => value.exhaustionLevel,
  getMovementSpeeds: (value: Dnd5eCharacter) => value.movementSpeeds,
  getAttributes: (value: Dnd5eCharacter) => value.attributes,
  getWeaponProficiencies: (value: Dnd5eCharacter) => value.weaponProficiencies,
  getArmorProficiencies: (value: Dnd5eCharacter) => value.armorProficiencies,
  getLanguageProficiencies: (value: Dnd5eCharacter) => value.languageProficiencies,
  getInventory: (value: Dnd5eCharacter) => value.inventory,
  getSpells: (value: Dnd5eCharacter) => value.spells,
  getSpellSlots: (value: Dnd5eCharacter) => value.spellSlots,

  getAttributeModifier: (value: Dnd5eCharacter, attribute: Dnd5eAttribute) => {
    return Dnd5eAttributeFn.modifier(value.attributes[attribute]);
  },
  getLevel: (value: Dnd5eCharacter) => {
    return value.classes.reduce((l, c) => l + c.level, 0)
  },
  getProficiencyBonus: (value: Dnd5eCharacter) => {
    const level = Dnd5eCharacter.getLevel(value);
    if (level < 5) return 2;
    if (level < 9) return 3;
    if (level < 13) return 4;
    if (level < 17) return 5;
    else return 6;
  }
};

export type Dnd5eCharacterOperation =
  | {type: "update-name", operations: StringOperation[]}
  | {type: "update-alignment", operations: ValueOperation<Dnd5eAlignment, ConstantOperation>[]}
  | {type: "update-background", operations: ValueOperation<Dnd5eBackground, Dnd5eBackgroundOperation>[]}
  | {type: "update-race", operations: ValueOperation<Dnd5eRace, Dnd5eRaceOperation>[]}
  | {type: "update-classes", operations: ListOperation<Dnd5eCharacterClass, Dnd5eCharacterClassOperation>[]}
  | {type: "update-experience", operations: Dnd5eCharacterExperienceOperation[]}
  | {type: "update-hit-points", operations: Dnd5eHealthOperation[]}
  | {type: "update-death-saves", operations: Dnd5eCharacterDeathSavesOperation[]}
  | {type: "update-exhaustion-level", operations: NumberOperation[]}
  | {type: "update-armor-class", operations: NumberOperation[]}
  | {type: "update-movement-speeds", operations: MapOperation<Dnd5eMovementType, number, NumberOperation>[]}
  | {type: "update-attributes", operations: MapOperation<Dnd5eAttribute, number, NumberOperation>[]}
  | {type: "update-weapon-proficiencies", operations: MapOperation<Dnd5eWeapon, Dnd5eWeaponProficiency, ConstantOperation>[]}
  | {type: "update-armor-proficiencies", operations: MapOperation<Dnd5eArmor, Dnd5eArmorProficiency, ConstantOperation>[]}
  | {type: "update-language-proficiencies", operations: MapOperation<Dnd5eLanguage, Dnd5eLanguageProficiency, ConstantOperation>[]}
  | {type: "update-inventory", operations: ListOperation<Dnd5eInventoryItem, Dnd5eInventoryItemOperation>[]}
  | {type: "update-spells", operations: ListOperation<Dnd5eSpell, Dnd5eSpellOperation>[]}
  | {type: "update-spell-slots", operations: MapOperation<Dnd5eSpellLevel, number, ValueOperation<number, ConstantOperation>>[]}
  | {type: "update-pact-slots", operations: NumberOperation[]}
  | {type: "update-features", operations: ListOperation<Dnd5eFeature, Dnd5eFeatureOperation>[]}
  | {type: "update-global-features", operations: MapOperation<Dnd5eFeatureID, Dnd5eFeatureOverride, Dnd5eFeatureOverrideOperation>[]}
  | {type: "update-conditions", operations: SetOperation<Dnd5eCondition>[]}
  ;
export const Dnd5eCharacterOperation = {
  updateName: (operations: StringOperation[]): Dnd5eCharacterOperation[] => [{type: "update-name", operations}],
  updateClasses: (operations: ListOperation<Dnd5eCharacterClass, Dnd5eCharacterClassOperation>[]): Dnd5eCharacterOperation[] => [{type: "update-classes", operations}],
  updateExperience: (operations: Dnd5eCharacterExperienceOperation[]): Dnd5eCharacterOperation[] => [{type: "update-experience", operations}],
  updateHitPoints: (operations: Dnd5eHealthOperation[]): Dnd5eCharacterOperation[] => [{type: "update-hit-points", operations}],
  updateDeathSaves: (operations: Dnd5eCharacterDeathSavesOperation[]): Dnd5eCharacterOperation[] => [{type: "update-death-saves", operations}],
  updateExhaustionLevel: (operations: NumberOperation[]): Dnd5eCharacterOperation[] => [{type: "update-exhaustion-level", operations}],
  updateMovementSpeeds: (operations: MapOperation<Dnd5eMovementType, number, NumberOperation>[]): Dnd5eCharacterOperation[] => [{type: "update-movement-speeds", operations}],
  updateAttributes: (operations: MapOperation<Dnd5eAttribute, number, NumberOperation>[]): Dnd5eCharacterOperation[] => [{type: "update-attributes", operations}],
  updateWeaponProficiencies: (operations: MapOperation<Dnd5eWeapon, Dnd5eWeaponProficiency, ConstantOperation>[]): Dnd5eCharacterOperation[] => [{type: "update-weapon-proficiencies", operations}],
  updateLanguageProficiencies: (operations: MapOperation<Dnd5eLanguage, Dnd5eLanguageProficiency, ConstantOperation>[]): Dnd5eCharacterOperation[] => [{type: "update-language-proficiencies", operations}],
  updateInventory: (operations: ListOperation<Dnd5eInventoryItem, Dnd5eInventoryItemOperation>[]): Dnd5eCharacterOperation[] => [{type: "update-inventory", operations}],
  updateSpellSlots: (operations: MapOperation<Dnd5eSpellLevel, number, ValueOperation<number, ConstantOperation>>[]): Dnd5eCharacterOperation[] => [{type: "update-spell-slots", operations}]
};

export const dnd5eCharacterType: Type<Dnd5eCharacter, Dnd5eCharacterOperation> = new ObjectType({
  name: stringType,
  alignment: new ValueType(constantType),
  background: new ValueType(dnd5eBackgroundType),
  race: new ValueType(dnd5eRaceType),
  classes: new ListType(dnd5eCharacterClassType),
  experience: dnd5eCharacterExperienceType,
  hitPoints: dnd5eCharacterHitPointsType,
  deathSaves: dnd5eCharacterDeathSavesType,
  exhaustionLevel: exhaustionLevelType,
  movementSpeeds: new MapType<Dnd5eMovementType, number, NumberOperation>(numberType),
  attributes: new MapType<Dnd5eAttribute, number, NumberOperation>(numberType),
  weaponProficiencies: new MapType<Dnd5eWeapon, Dnd5eWeaponProficiency, ConstantOperation>(constantType),
  armorProficiencies: new MapType<Dnd5eArmor, Dnd5eArmorProficiency, ConstantOperation>(constantType),
  languageProficiencies: new MapType<Dnd5eLanguage, Dnd5eLanguageProficiency, ConstantOperation>(constantType),
  inventory: new ListType(dnd5eInventoryItemType),
  spells: new ListType(dnd5eSpellType),
  spellSlots: new MapType<Dnd5eSpellLevel, number, ValueOperation<number, ConstantOperation>>(new ValueType(constantType)),
  pactSlots: numberType,
  features: new ListType(dnd5eFeatureType),
  globalFeatures: new MapType<Dnd5eFeatureID, Dnd5eFeatureOverride, Dnd5eFeatureOverrideOperation>(dnd5eFeatureOverrideType),
  conditions: new SetType<Dnd5eCondition>()
}, (value) => {
  let v = {...value};
  if (v["globalEffectsOverride"] !== undefined) delete v["globalEffectsOverride"]
  if (v["savingThrowProficiencies"] !== undefined) {
    Object.keys(v["savingThrowProficiencies"]).forEach(attribute => {
      v.modifiers.push({
        type: "saving-throw",
        data: {
          modifierID: generateDnd5eModifierID(),
          expression: Dice.assertDiceExpression("0"),
          savingThrows: [attribute as Dnd5eAttribute],
          proficiency: v["savingThrowProficiencies"][attribute],
          hasAdvantage: false,
          hasDisadvantage: false
        }
      } satisfies Dnd5eModifier);
    });
    delete v["savingThrowProficiencies"]
  }
  if (v["skillProficiencies"] !== undefined) {
    Object.keys(v["skillProficiencies"]).forEach(skill => {
      v.modifiers.push({
        type: "ability-check",
        data: {
          modifierID: generateDnd5eModifierID(),
          expression: Dice.assertDiceExpression("0"),
          abilityChecks: [skill as Dnd5eSkill],
          proficiency: v["skillProficiencies"][skill],
          hasAdvantage: false,
          hasDisadvantage: false
        }
      } satisfies Dnd5eModifier);
    });
    delete v["skillProficiencies"]
  }
  if (v["toolProficiencies"] !== undefined) {
    Object.keys(v["toolProficiencies"]).forEach(tool => {
      v.modifiers.push({
        type: "ability-check",
        data: {
          modifierID: generateDnd5eModifierID(),
          expression: Dice.assertDiceExpression("0"),
          abilityChecks: [tool as Dnd5eTool],
          proficiency: v["toolProficiencies"][tool],
          hasAdvantage: false,
          hasDisadvantage: false
        }
      } satisfies Dnd5eModifier);
    });
    delete v["toolProficiencies"]
  }
  if (v["pactSlots"] === undefined) {
    v["pactSlots"] = 0;
  }
  v["spellSlots"] = Object.fromEntries(Object.entries(v["spellSlots"])
    .filter(([level, spellSlot]) => spellSlot !== undefined && level !== "cantrip")
    .map(([level, spellSlot]: [string, any]) => {
    if (typeof spellSlot !== "number") return [level, spellSlot["current"] || 0];
    else return [level, spellSlot];
  }));
  if (v["armorClass"]) {
    v["modifiers"].push({
      type: "armor-class-formula",
      data: {
        modifierID: generateDnd5eModifierID(),
        expression: MathExpressionFn.assertMathExpression(`${v["armorClass"]}`)
      }
    });
    delete v["armorClass"];
  }
  if (v["features"] === undefined) {
    v["features"] = [];
  }

  if (v["actions"] !== undefined && v["modifiers"] !== undefined) {
    if (v["actions"].length > 0 || v["modifiers"].length > 0) {
      v["features"].push({
        featureID: generateDnd5eFeatureID(),
        enabled: true,
        description: RichTextFn.EMPTY,
        source: "",
        actions: v["actions"],
        modifiers: v["modifiers"],
        title: "Unknown",
        resources: [],
        effects: []
      } satisfies Dnd5eFeature);
    }
    delete v["actions"];
    delete v["modifiers"];
  }
  if (v["effects"] !== undefined) {
    if (v["effects"].length > 0) {
      v["features"].push(...v["effects"].map((effect: any) => ({
        featureID: generateDnd5eFeatureID(),
        title: effect.name,
        enabled: true,
        description: RichTextFn.EMPTY,
        source: "",
        actions: [],
        modifiers: [],
        resources: [],
        effects: [effect]
      })));
    }
    delete v["effects"];
  }
  if (v["hitPoints"]["max"] !== undefined) {
    v["features"].push({
      featureID: generateDnd5eFeatureID(),
      title: "Hit Points",
      source: "",
      actions: [],
      resources: [],
      modifiers: [{
        type: "max-h-p",
        data: {
          modifierID: generateDnd5eModifierID(),
          expression: MathExpressionFn.assertMathExpression(`${v["hitPoints"]["max"]}`)
        }
      }],
      description: RichTextFn.EMPTY,
      effects: v["effects"],
      enabled: true
    } satisfies Dnd5eFeature);
    delete v["hitPoints"]["max"];
  }
  if (v["globalEffects"]) delete v["globalEffects"];
  if (v["globalFeatures"] === undefined) v["globalFeatures"] = {};

  v = actionTemplate_upgradeDnd5eCharacter(v);

  return v;
});


export function Dnd5eCharacterSignals(value: MutableRef<Dnd5eCharacter, Dnd5eCharacterOperation[]>) {
  return {
    name: PropertyRef<Dnd5eCharacter, Dnd5eCharacterOperation, string, StringOperation>(
      value => value.name,
      operations => [{type: "update-name", operations}]
    )(value),
    race: PropertyRef<Dnd5eCharacter, Dnd5eCharacterOperation, Dnd5eRace, ValueOperation<Dnd5eRace, Dnd5eRaceOperation>>(
      value => value.race,
      operations => [{type: "update-race", operations}]
    )(value),
    background: PropertyRef<Dnd5eCharacter, Dnd5eCharacterOperation, Dnd5eBackground, ValueOperation<Dnd5eBackground, Dnd5eBackgroundOperation>>(
      value => value.background,
      operations => [{type: "update-background", operations}]
    )(value),
    spells: ListPropertyRef<Dnd5eCharacter, Dnd5eCharacterOperation, Dnd5eSpell, Dnd5eSpellOperation>(
      c => c.spells,
      operations => [{type: "update-spells", operations}]
    )(value),
    spellSlots: PropertyRef<Dnd5eCharacter, Dnd5eCharacterOperation, {[level in Dnd5eSpellLevel]: number}, MapOperation<Dnd5eSpellLevel, number, ValueOperation<number, ConstantOperation>>>(
      value => value.spellSlots,
      operations => [{type: "update-spell-slots", operations}]
    )(value),
    pactSlots: PropertyRef<Dnd5eCharacter, Dnd5eCharacterOperation, number, NumberOperation>(
      value => value.pactSlots,
      operations => [{type: "update-pact-slots", operations}]
    )(value),
    inventory: ListPropertyRef<Dnd5eCharacter, Dnd5eCharacterOperation, Dnd5eInventoryItem, Dnd5eInventoryItemOperation>(
      sheet => sheet.inventory,
      operations => [{type: "update-inventory", operations}]
    )(value),
    features: ListPropertyRef<Dnd5eCharacter, Dnd5eCharacterOperation, Dnd5eFeature, Dnd5eFeatureOperation>(
      sheet => sheet.features,
      operations => [{type: "update-features", operations}]
    )(value),
    globalFeatures: PropertyRef<Dnd5eCharacter, Dnd5eCharacterOperation, {[effectID in Dnd5eFeatureID]: Dnd5eFeatureOverride}, MapOperation<Dnd5eFeatureID, Dnd5eFeatureOverride, Dnd5eFeatureOverrideOperation>>(
      sheet => sheet.globalFeatures,
      operations => [{type: "update-global-features", operations}]
    )(value),
    attributes: MapPropertyRef<Dnd5eCharacter, Dnd5eCharacterOperation, Dnd5eAttribute, number, NumberOperation>(
      sheet => sheet.attributes,
      operations => [{type: "update-attributes", operations}]
    )(value),
    conditions: SetPropertySignal<Dnd5eCharacter, Dnd5eCharacterOperation, Dnd5eCondition>(
      value => value.conditions,
      operations => [{type: "update-conditions", operations}]
    )(value),
    hitPoints: PropertyRef<Dnd5eCharacter, Dnd5eCharacterOperation, Dnd5eHealth, Dnd5eHealthOperation>(
      value => value.hitPoints,
      operations => [{type: "update-hit-points", operations}]
    )(value),
    movementSpeeds: MapPropertyRef<Dnd5eCharacter, Dnd5eCharacterOperation, Dnd5eMovementType, number, NumberOperation>(
      sheet => sheet.movementSpeeds,
      operations => [{type: "update-movement-speeds", operations}]
    )(value),
    deathSaves: PropertyRef<Dnd5eCharacter, Dnd5eCharacterOperation, Dnd5eCharacterDeathSaves, Dnd5eCharacterDeathSavesOperation>(
      sheet => sheet.deathSaves,
      operations => [{type: "update-death-saves", operations}]
    )(value),
    alignment: ValuePropertyRef<Dnd5eCharacter, Dnd5eCharacterOperation, Dnd5eAlignment, ConstantOperation>(
      sheet => sheet.alignment,
      operations => [{type: "update-alignment", operations}]
    )(value),
    experience: PropertyRef<Dnd5eCharacter, Dnd5eCharacterOperation, Dnd5eCharacterExperience, Dnd5eCharacterExperienceOperation>(
      sheet => sheet.experience,
      operations => [{type: "update-experience", operations}]
    )(value),
    classes: ListPropertyRef<Dnd5eCharacter, Dnd5eCharacterOperation, Dnd5eCharacterClass, Dnd5eCharacterClassOperation>(
      sheet => sheet.classes,
      operations => [{type: "update-classes", operations}]
    )(value),
    armorProficiencies: MapPropertyRef<Dnd5eCharacter, Dnd5eCharacterOperation, Dnd5eArmor, Dnd5eArmorProficiency, ConstantOperation>(
      sheet => sheet.armorProficiencies,
      operations => [{type: "update-armor-proficiencies", operations}]
    )(value),
    weaponProficiencies: MapPropertyRef<Dnd5eCharacter, Dnd5eCharacterOperation, Dnd5eWeapon, Dnd5eWeaponProficiency, ConstantOperation>(
      value => value.weaponProficiencies,
      operations => [{type: "update-weapon-proficiencies", operations}]
    )(value),
    languageProficiencies: MapPropertyRef<Dnd5eCharacter, Dnd5eCharacterOperation, Dnd5eLanguage, Dnd5eLanguageProficiency, ConstantOperation>(
      value => value.languageProficiencies,
      operations => [{type: "update-language-proficiencies", operations}]
    )(value),
    exhaustionLevel: PropertyRef<Dnd5eCharacter, Dnd5eCharacterOperation, Dnd5eExhaustionLevel, NumberOperation>(
      value => value.exhaustionLevel,
      operations => [{type: "update-exhaustion-level", operations}]
    )(value)
  };
}

import {Node} from "common/legends/node/index.ts";
import React, {useContext} from "react";

const VisibilityContext = React.createContext<(element: Node) => boolean>(() => false);
export const VisibilityProvider = VisibilityContext.Provider;
export function useIsElementVisible(): (element: Node) => boolean {
  return useContext(VisibilityContext);
}


const AccessibilityContext = React.createContext<(element: Node) => boolean>(() => false);
export const AccessibilityProvider = AccessibilityContext.Provider;
export function useIsElementAccessible(): (element: Node) => boolean {
  return useContext(AccessibilityContext);
}

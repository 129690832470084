import {Button, ButtonBar, Input, InputGroup, Select} from "#lib/components/index.ts";
import {SquareGridTypeIcon} from "./square-grid-type-icon.tsx";
import {HexagonHorizontalGridTypeIcon} from "./hexagon-horizontal-grid-type-icon.tsx";
import {HexagonVerticalGridTypeIcon} from "./hexagon-vertical-grid-type-icon.tsx";
import {IsometricGridTypeIcon} from "./isometric-grid-type-icon.tsx";
import {NumberOperation} from "common/types/index.ts";
import {Grid, GridOperation, GridRuler, GridRulerOperation, GridShape, GridUnitOperation} from "common/legends/index.ts";
import {useCallback} from "react";
import {useApplyCallback, useObservableLoader, useSetNumberCallback, useSetValueCallback} from "#lib/qlab/index.ts";
import {MutableRef} from "common/ref";
import {Field, FieldLabel} from "#lib/components/panel-header.tsx";
import {InputGroupIcon} from "#lib/components/input/input-group-icon.tsx";

const defaultDimensions: {[shape: string]: {width: number, height: number}} = {
  "square": {width: 64, height: 64},
  "hexagon-vertical": {width: 56, height: 64},
  "hexagon-horizontal": {width: 64, height: 56},
  "isometric": {width: 128, height: 64}
};

const getWidth = (value: Grid) => value.width;
const updateWidth = (operations: NumberOperation[]): GridOperation[] => [{type: "update-width", operations}];

const getHeight = (value: Grid) => value.height;
const updateHeight = (operations: NumberOperation[]): GridOperation[] => [{type: "update-height", operations}];

const getSubdivisions = (value: Grid) => value.subdivisions;
const updateSubdivisions = (operations: NumberOperation[]): GridOperation[] => [{type: "update-subdivisions", operations}];

const getGridUnit = (value: Grid) => value.unit;
const updateGridUnit = (operations: GridUnitOperation[]): GridOperation[] => [{type: "update-unit", operations}];

const getGridRuler = (value: Grid) => value.ruler;
const updateGridRuler = (operations: GridRulerOperation[]): GridOperation[] => [{type: "update-ruler", operations}];

export function GridOptions({value}: {
  value: MutableRef<Grid, GridOperation[]>;
}) {
  const setGridShape = useCallback((shape: GridShape) => {
    value.apply(prevValue => [{
      type: "update-shape",
      operations: [{type: "set", prevValue: prevValue.shape, nextValue: shape}]
    }, {
      type: "update-width",
      operations: [{type: "set", prevValue: prevValue.width, nextValue: defaultDimensions[shape].width}]
    }, {
      type: "update-height",
      operations: [{type: "set", prevValue: prevValue.height, nextValue: defaultDimensions[shape].height}]
    }]);
  }, [value.apply]);
  const setGridWidth = useSetNumberCallback(useApplyCallback(value.apply, getWidth, updateWidth));
  const setGridHeight = useSetNumberCallback(useApplyCallback(value.apply, getHeight, updateHeight));
  const setGridSubdivisions = useSetNumberCallback(useApplyCallback(value.apply, getSubdivisions, updateSubdivisions));
  const setGridUnit = useSetValueCallback(useApplyCallback(value.apply, getGridUnit, updateGridUnit));
  const setGridRuler = useSetValueCallback(useApplyCallback(value.apply, getGridRuler, updateGridRuler));

  const loader = useObservableLoader(value.observe);

  if (loader.isLoading) return <></>

  return (
    <>
      <Field>
        <FieldLabel>Shape</FieldLabel>
        <ButtonBar className="mx-2 rounded-md overflow-hidden">
          <Button className="flex-1" variant={loader.data.shape === "square" ? "primary" : "tertiary"} onClick={() => setGridShape("square")}>
            <SquareGridTypeIcon size={"40px"} />
          </Button>
          <Button className="flex-1" variant={loader.data.shape === "hexagon-horizontal" ? "primary" : "tertiary"} onClick={() => setGridShape("hexagon-horizontal")}>
            <HexagonHorizontalGridTypeIcon size={"40px"} />
          </Button>
          <Button className="flex-1" variant={loader.data.shape === "hexagon-vertical" ? "primary" : "tertiary"} onClick={() => setGridShape("hexagon-vertical")}>
            <HexagonVerticalGridTypeIcon size={"40px"} />
          </Button>
          <Button className="flex-1" variant={loader.data.shape === "isometric" ? "primary" : "tertiary"} onClick={() => setGridShape("isometric")}>
            <IsometricGridTypeIcon size={"40px"} />
          </Button>
        </ButtonBar>
      </Field>

      <Field>
        <FieldLabel>Size</FieldLabel>
        <div className="flex gap-0.5 mx-2 rounded-md overflow-hidden">
          <InputGroup className="flex-1">
            <InputGroupIcon>W</InputGroupIcon>
            <Input type="number" min={1} max={300} step={1} value={loader.data.width} onChange={ev => setGridWidth(parseInt(ev.target.value))}/>
          </InputGroup>
          <InputGroup className="flex-1">
            <InputGroupIcon>H</InputGroupIcon>
            <Input type="number" min={1} max={300} step={1} value={loader.data.height} onChange={ev => setGridHeight(parseInt(ev.target.value))}/>
          </InputGroup>
        </div>
      </Field>

      <Field>
        <FieldLabel>Subdivisions</FieldLabel>
        <InputGroup className="mx-2 rounded-md overflow-hidden">
          <Input type="number" value={loader.data.subdivisions} onChange={ev => setGridSubdivisions(Math.min(Math.max(0, parseInt(ev.target.value)), 4))}/>
        </InputGroup>
      </Field>

      <Field>
        <FieldLabel>Units</FieldLabel>
        <div className="flex flex-row gap-0.5 mx-2 rounded-md overflow-hidden">
          <InputGroup className="flex-[3]">
            <Input type="number" value={loader.data.unit[0]} onChange={ev => setGridUnit([parseInt(ev.target.value), loader.data.unit[1]])}/>
          </InputGroup>
          <InputGroup className="flex-1">
            <Input type="text" value={loader.data.unit[1]} onChange={ev => setGridUnit([loader.data.unit[0], ev.target.value])}/>
          </InputGroup>
        </div>
      </Field>

      <Field>
        <FieldLabel>Rulers</FieldLabel>
        <InputGroup className="mx-2 rounded-md overflow-hidden pr-0">
          <Select value={loader.data.ruler} onChange={ev => setGridRuler(ev.target.value as GridRuler)}>
            <option value="manhattan">Manhattan</option>
            <option value="euclidean">Euclidean</option>
            <option value="dnd-standard">D&D Standard</option>
            <option value="dnd-variant">D&D Variant</option>
          </Select>
        </InputGroup>
      </Field>
    </>
  )
}
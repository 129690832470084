import {Dnd5eActionSavingThrow} from "common/qlab/message/dnd-5e/dnd-5e-action-message.ts";
import {Dnd5eSavingThrowMessage, QLabMessageID, RollRequests, RollResults} from "common/qlab/message/index.ts";
import {Optional} from "common/types/generic/optional/index.ts";
import {RollRequestView} from "../../roll-request-view.tsx";
import {MessageSectionHeader} from "./message-section-header.tsx";
import {DiceButton} from "#lib/components/button/dice-button.tsx";
import {useSelectedNodeIDRef, useSelectedSheet} from "../../../../panel/sheet/editor/dnd-5e-character/use-selected-sheet.ts";
import {useObservable, useStore} from "#lib/qlab/index.ts";
import {pipe} from "common/pipe";
import {distinct, map} from "common/observable";
import {useRollSavingThrow} from "../../../../panel/sheet/editor/dnd-5e-character/dnd-5e-action/use-roll-saving-throw.ts";
import {DND_5E_SAVING_THROW_TITLE} from "common/legends/asset/sheet/dnd-5e/dnd-5e-modifier/dnd-5e-saving-throw-modifier.ts";
import {useRefValue} from "#lib/signal/index.ts";
import {useReferencedMessages} from "../../use-referenced-messages.ts";
import {useMessageID} from "../../message-context.ts";
import {useUserID} from "#lib/auth/use-get-user-id.ts";
import {useDecryptValue, useOptionalDecryptValue} from "../../use-decrypt-value.ts";
import {UserNodeIcon} from "../../user-node-icon.tsx";
import {Dnd5eActionSegmentView} from "./dnd-5e-action-segment-view.tsx";

function Dnd5eSavingThrowMessageResponse({message}: {messageID: QLabMessageID, message: Dnd5eSavingThrowMessage}) {
  const userId = useUserID()!;
  const user = useStore("user", userId);
  const rollRequests = useDecryptValue(message.rollRequests, userId, user.data?.privateKey);
  const rollResults = useOptionalDecryptValue<RollResults>(message.rollResults, userId, user.data?.privateKey);

  return <div className="inline-flex items-center relative py-2">
    <UserNodeIcon userID={message.userID} nodeID={message.nodeID} icon={message.icon} />
    <RollRequestView rollType="saving-throw" rollRequest={rollRequests.isSuccess ? rollRequests.data[message.savingThrowRollID] : undefined} rollResult={rollResults.isSuccess && rollResults.data ? rollResults.data[message.savingThrowRollID] : undefined} />
  </div>;
}

export function Dnd5eActionSavingThrowView({segment, rollRequests, rollResults}: {
  segment: Dnd5eActionSavingThrow,
  rollRequests: RollRequests,
  rollResults: Optional<RollResults>
}) {
  const selectedNodeIDRef = useSelectedNodeIDRef();
  const selectedCharacter = useSelectedSheet();
  const isCharacterSelected = useObservable(pipe(selectedCharacter.observe, map(c => c !== undefined), distinct()), false, [selectedCharacter.observe]);
  const rollSave = useRollSavingThrow(selectedNodeIDRef.observe, selectedCharacter);

  const messages = useRefValue(useReferencedMessages(useMessageID()!));

  return <>
    <MessageSectionHeader>SAVING THROW</MessageSectionHeader>
    <RollRequestView rollType="saving-throw" rollRequest={rollRequests ? rollRequests[segment.difficultyClassRollID] : undefined}
                     rollResult={rollResults && rollResults ? rollResults[segment.difficultyClassRollID] : undefined}/>
    <div className="flex flex-row gap-1 w-full">
      {segment.savingThrowTypes.map((defense) => <DiceButton key={defense} variant="primary" disabled={!isCharacterSelected} className="flex-1 min-w-0 overflow-hidden" onClick={ev => rollSave(defense, ev.shiftKey, ev.ctrlKey)}>
        <span className="whitespace-nowrap min-w-0">Roll {DND_5E_SAVING_THROW_TITLE[defense]} Saving Throw</span>
      </DiceButton>)}
    </div>

    <div className="flex gap-2 justify-center flex-wrap pt-7 pb-2 gap-y-1 min-h-16 box-content">
      {Object.entries(messages).toSorted((a, b) => a[0].localeCompare(b[0]))
        .filter(([_, message]) => message.type === "dnd-5e-saving-throw-message")
        .map(([messageID, message]) => (message.type === "dnd-5e-saving-throw-message")
          ? <Dnd5eSavingThrowMessageResponse key={messageID} messageID={messageID as QLabMessageID} message={message.data}/>
          : <></>)}
      {Object.entries(messages).length === 0 && <span className="text-white/80 text-sm italic">
        Awaiting saving throws.
      </span>}
    </div>

    {(segment.onSuccess.length > 0 || segment.onMiss.length > 0) && <div className="w-full flex flex-row gap-0.5">
      {segment.onSuccess.length > 0 && <div className="flex flex-col flex-1 items-center gap-2">
        <MessageSectionHeader>On Success</MessageSectionHeader>
        {segment.onSuccess.map((segment, index) => <Dnd5eActionSegmentView key={index} segment={segment} rollRequests={rollRequests} rollResults={rollResults}/>)}
      </div>}
      {segment.onMiss.length > 0 && <div className="flex flex-col flex-1 items-center gap-2">
        <MessageSectionHeader>On Failure</MessageSectionHeader>
        {segment.onMiss.map((segment, index) => <Dnd5eActionSegmentView key={index} segment={segment} rollRequests={rollRequests} rollResults={rollResults}/>)}
      </div>}
    </div>}
  </>
}

import {Optional} from "common/types/generic/index.ts";
import {Dnd5eFeature, Sheet} from "common/legends/asset/index.ts";
import {Dnd5eActionTemplate} from "common/legends/asset/sheet/dnd-5e/dnd-5e-action-definition/template/dnd-5e-action-template.ts";
import {conditionEvaluator} from "../condition/condition-evaluator.ts";
import {Dnd5eActionTemplateModifier} from "common/legends/asset/sheet/dnd-5e/dnd-5e-action-definition/modifier/dnd-5e-action-template-modifier.ts";
import {Dnd5eProcess} from "common/legends/asset/sheet/dnd-5e/dnd-5e-action-definition/process/dnd-5e-process.ts";

export function getActivationProcesses(
  sheet: Optional<Sheet>,
  globalFeatures: Dnd5eFeature[],
  action: Optional<Dnd5eActionTemplate>
): Dnd5eProcess[] {
  const evaluateCondition = conditionEvaluator(sheet, globalFeatures, action);
  const getActionModifiers = (modifier: Dnd5eActionTemplateModifier): Dnd5eProcess[] => {
    if (modifier.type === "action::condition") {
      return evaluateCondition(modifier.data.condition)
        ? modifier.data.modifiers.flatMap(getActionModifiers, [])
        : [];
    } else if (modifier.type === "action::trigger::activation") {
      return modifier.data.processes;
    } else {
      return [];
    }
  };

  return action?.type === "custom" ? [
    ...action.data.modifiers,
    ...action.data.effects.filter(effect => effect.enabled).flatMap(effect => effect.modifiers)
  ].flatMap(getActionModifiers) : [];
}


import {PropsWithChildren} from "react";
import {twMerge} from "tailwind-merge";
import {ButtonSize, Checkbox} from "#lib/components/index.ts";

export type ExpandableCheckboxProps = PropsWithChildren<{
  className?: string;
  expanded: boolean;
  size?: ButtonSize;
  readOnly?: boolean;
  disabled?: boolean;
  toggleExpand?: () => void;
}>;

export function ExpandableCheckbox({expanded, size = "medium", toggleExpand, readOnly, disabled, className, children}: ExpandableCheckboxProps) {
  return (
    <div className={twMerge(
      "flex-1",
      "text-white/80 p-0 outline-none",
      "flex flex-row h-12",
      size === "small" && "h-10",
      className
    )}>
      <label className={twMerge(
        "px-2 bg-zinc-900/80 w-12 h-12 shrink-0 flex items-center justify-center",
        size === "small" && "w-10 h-10",
        toggleExpand === undefined ? "cursor-default text-white/25" : "cursor-pointer"
      )}>
        <Checkbox checked={expanded} readOnly={readOnly} disabled={disabled} onChange={readOnly ? undefined : toggleExpand} />
      </label>
      {children}
    </div>
  );
}

import React from "react";
import {Color, HSLA, Transform} from "common/types/index.ts";
import {ElementHUDPass} from "./element-h-u-d-pass.tsx";
import {SelectionIndicator} from "./selection-indicator.tsx";
import {ModelProvider, usePVM} from "../../context/pvm-context.ts";
import {LightNode} from "common/legends/node/light/light-node.ts";
import {LightShapeFn} from "common/legends/node/light/light-shape.ts";
import {useIsSelected} from "../../context/use-is-selected.ts";
import {AreaShader} from "../../shader/shape/area-shader.tsx";
import {SplineFn} from "common/types/generic/spline/index.ts";
import {Vector2} from "common/math/vector/vector2.ts";
import {CircleShader} from "../../shader/circle-shader.tsx";

export function LightElementHUDPass({value: {id, children, transform, shape, origin}}: {
  value: LightNode;
}) {
  const {projection, view, model} = usePVM();
  const valueModel = Transform.divide(transform, model);
  const isSelected = useIsSelected(id);

  const shapeOrigin = LightShapeFn.getLightOrigin(shape, origin);
  const shapeSize = LightShapeFn.getLightSize(shape);
  const color = shape?.type !== undefined ? [shape.data.color[0], shape.data.color[1], shape.data.color[2], shape.data.intensity] as HSLA : Color.GREEN;

  return (<binder>
    <ModelProvider value={valueModel}>
      {isSelected && shape?.type === "freeform" && shape.data.areas.map((area, index) => {
        return <AreaShader key={index} spline={area} origin={origin} scale={1} color={color} />
      })}

      {[...children].reverse().map((element) => <ElementHUDPass key={element.data.id} value={element} />)}

      {isSelected && <SelectionIndicator projection={projection} view={view} model={valueModel} origin={shapeOrigin} size={shapeSize} color={Color.WHITE} repeatX={1} repeatY={1} />}
      {isSelected && <>
        {shape?.type === "freeform" && shape.data.areas.map((area, index) => {
          const splineOrigin = Vector2.subtract(origin, SplineFn.getSplineOrigin(area));
          const splineSize = Vector2.multiply(SplineFn.getSplineSize(area), 0.5);
          const center = Vector2.subtract(splineOrigin, splineSize);
          return <CircleShader key={index} color={color} origin={Vector2.add(center, [4, 4])} size={[8, 8]} />
        })}
        {(shape?.type === "spotlight" || shape?.type === "sprite") && <CircleShader color={color} origin={[4, 4]} size={[8, 8]} />}
      </>}
    </ModelProvider>
  </binder>);
}
import {
  applyAll,
  ListOperation,
  NumberFn,
  Optional,
  PointFn,
  SetOperation,
  Transform,
  TransformFn,
  TransformOperation,
  transformType,
  Tree,
  TreeOperation,
  ValueFn
} from "common/types/index.ts";
import {useGetAsset, useInstance} from "#lib/qlab/index.ts";
import {KeyboardEvent, useCallback} from "react";
import {AssetOperationFn, Grid, Node, NodeId, NodeOperation, TokenOperation} from "common/legends/index.ts";
import {MutableRef, Ref} from "common/ref";
import {Vector2} from "common/math/vector/vector2.ts";
import {TriggerContext, useApplyInteractionAction} from "./use-apply-interaction-action.ts";
import {getMoveInteractions} from "./get-move-interactions.ts";
import {useDatabase} from "../../../routes/game/model/store-context.tsx";
import {useNodePosToWorldPos} from "./use-node-pos-to-world-pos.ts";
import {getParentNode} from "../../common/legends/get-parent-node.ts";
import {NodeSelectionRef} from "../../panel/nav/editor/state/selection-ref.ts";
import {useGoToNode} from "../../panel/nav/editor/use-go-to-node.ts";

export function onMovementHandlers(
  activeNodeIdRef: Ref<Optional<NodeId>>,
  nodeSelectionRef: MutableRef<NodeSelectionRef[], SetOperation<NodeSelectionRef>[]>,
  grid: Grid,
  nodesRef: MutableRef<Node[], TreeOperation<Node, NodeOperation>[]>,
  isAccessible: (node: Node) => boolean,
  isVisible: (node: Node) => boolean,
  viewRef: MutableRef<Transform, TransformOperation[]>
) {
  const instance = useInstance();
  const deleteSelectedNodes = useCallback(async () => {
    const nodes = nodeSelectionRef.value;
    for (const node of nodes) {
      if (node.type === "scene-node") {
        await instance.applyToResource(node.storeId, node.resourceId, prev => {
          if (prev?.type !== "scene") return [];
          const nodePath = Tree.getPath(prev.data.children, n => n.data.id === node.nodeId);
          if (nodePath === undefined) return [];
          return ValueFn.apply([{type: "scene", operations: [{type: "update-children", operations: TreeOperation.delete(
            nodePath,
            Tree.getNode(prev.data.children, nodePath)
          )}]}]);
        })
      } else if (node.type === "asset-token-node") {
        await instance.applyToResource(node.storeId, node.resourceId, prev => {
          if (prev?.type !== "asset") return [];
          const tokenIndex = prev.data.tokens.findIndex(token => token.tokenID === node.tokenId);
          if (tokenIndex === -1) return [];
          const token = prev.data.tokens[tokenIndex];
          const nodePath = Tree.getPath(token.children, n => n.data.id === node.nodeId);
          if (nodePath === undefined) return [];
          return ValueFn.apply([{type: "asset", operations: AssetOperationFn.updateTokens(ListOperation.apply(
            tokenIndex,
            TokenOperation.updateChildren(TreeOperation.delete(
              nodePath,
              Tree.getNode(token.children, nodePath)
            ))
          ))}]);
        });
      }
    }
  }, [instance, nodeSelectionRef]);

  const databaseRef = useDatabase();
  const nodePosToWorldPos = useNodePosToWorldPos(nodesRef);

  const applyInteractionAction = useApplyInteractionAction();
  const moveNode = useCallback(async (fn: (node: Node, transform: Transform, grid: Grid) => TransformOperation[]) => {
    const nodes = nodeSelectionRef.value;
    for (const node of nodes) {
      if (node.type === "scene-node") {
        const interactions: TriggerContext[] = [];
        nodesRef.apply(nodes => {
          const nodePath = Tree.getPath(nodes, n => n.data.id === node.nodeId);
          if (nodePath === undefined) return [];
          const n = Tree.getNode(nodes, nodePath);
          const operations = fn(n, n.data.transform, grid);
          const parentNode = getParentNode(nodes, n.data.id);

          const prevPos = n.data.transform.position;
          const nextPos = applyAll(transformType, n.data.transform, operations).position;

          interactions.push(...getMoveInteractions(
            databaseRef.value,
            {type: "scene", sceneID: node.resourceId, path: []},
            activeNodeIdRef.value,
            isAccessible,
            isVisible,
            viewRef.value,
            nodePosToWorldPos(parentNode?.data.id, prevPos),
            nodePosToWorldPos(parentNode?.data.id, nextPos)
          ));

          return TreeOperation.apply(nodePath, [{type: n.type, operations: [{
            type: "update-transform", operations
          }]}]);
        });
        interactions.forEach(applyInteractionAction)
      }
    }
  }, [instance, nodesRef, nodeSelectionRef, databaseRef, isAccessible, isVisible, nodePosToWorldPos, applyInteractionAction, activeNodeIdRef, viewRef]);

  const gotoNode = useGoToNode();
  const getAsset = useGetAsset();

  const onMovementKeyUp = useCallback((event: KeyboardEvent): boolean => {
    if (event.key === "Delete" || event.key === "Backspace") {
      deleteSelectedNodes();
      return false;
    } else if (event.key == "ArrowUp" || event.key === "w" || event.key === "W") {
      moveNode((_, transform, grid) => {
        const a = transform.rotation / 180 * Math.PI;
        return TransformFn.updatePosition([
          {type: "update-x", operations: NumberFn.increment(-Math.sin(a) * grid.height)},
          {type: "update-y", operations: NumberFn.increment(Math.cos(a) * grid.height)}
        ])
      });
      return false;
    } else if (event.key === "ArrowDown" || event.key === "s" || event.key === "S") {
      moveNode((_, transform, grid) => {
        const a = transform.rotation / 180 * Math.PI;
        return TransformFn.updatePosition([
          {type: "update-x", operations: NumberFn.decrement(-Math.sin(a) * grid.height)},
          {type: "update-y", operations: NumberFn.decrement(Math.cos(a) * grid.height)}
        ])
      });
      return false;
    } else if (event.key === "ArrowLeft" || event.key === "a" || event.key === "A") {
      moveNode((_, transform, grid) => {
        const a = transform.rotation / 180 * Math.PI;
        return TransformFn.updatePosition([
          {type: "update-x", operations: NumberFn.decrement(Math.cos(a) * grid.width)},
          {type: "update-y", operations: NumberFn.decrement(Math.sin(a) * grid.width)}
        ])
      });
      return false;
    } else if (event.key === "ArrowRight" || event.key === "d" || event.key === "D") {
      moveNode((_, transform, grid) => {
        const a = transform.rotation / 180 * Math.PI;
        return TransformFn.updatePosition([
          {type: "update-x", operations: NumberFn.increment(Math.cos(a) * grid.width)},
          {type: "update-y", operations: NumberFn.increment(Math.sin(a) * grid.width)}
        ])
      });
      return false;
    } else if (event.key === "q" || event.key === "Q") {
      moveNode((node: Node) => {
        let {pivot, origin} = node.data;
        if (node.type === "token") {
          const asset = getAsset(node.data.tokenReference.assetID);
          const token = asset?.tokens.find(token => token.tokenID === node.data.tokenReference.tokenID);
          if (!token) return [];
          pivot = token.pivot;
          origin = token.origin;
        }

        const rotation = 90/4;
        const prevPivot = Vector2.rotate(
          Vector2.multiply(Vector2.subtract(pivot, origin), node.data.transform.scale),
          node.data.transform.rotation * Math.PI / 180
        );
        const nextPivot = Vector2.rotate(
          Vector2.multiply(Vector2.subtract(pivot, origin), node.data.transform.scale),
          (node.data.transform.rotation + rotation) * Math.PI / 180
        );
        return [
          ...TransformFn.updatePosition(PointFn.set(node.data.transform.position, Vector2.add(
            node.data.transform.position,
            Vector2.subtract(prevPivot, nextPivot)
          ))),
          ...TransformFn.updateRotation(NumberFn.increment(rotation))
        ];
      });
      return false;
    } else if (event.key === "e" || event.key === "E") {
      moveNode((node: Node) => {
        let {pivot, origin} = node.data;
        if (node.type === "token") {
          const asset = getAsset(node.data.tokenReference.assetID);
          const token = asset?.tokens.find(token => token.tokenID === node.data.tokenReference.tokenID);
          if (!token) return [];
          pivot = token.pivot;
          origin = token.origin;
        }

        const rotation = -90/4;
        const prevPivot = Vector2.rotate(
          Vector2.multiply(Vector2.subtract(pivot, origin), node.data.transform.scale),
          node.data.transform.rotation * Math.PI / 180
        );
        const nextPivot = Vector2.rotate(
          Vector2.multiply(Vector2.subtract(pivot, origin), node.data.transform.scale),
          (node.data.transform.rotation + rotation) * Math.PI / 180
        );
        return [
          ...TransformFn.updatePosition(PointFn.set(node.data.transform.position, Vector2.add(
            node.data.transform.position,
            Vector2.subtract(prevPivot, nextPivot)
          ))),
          ...TransformFn.updateRotation(NumberFn.increment(rotation))
        ];
      });
      return false;
    } else if (event.code === "Space") {
      const activeNodeId = activeNodeIdRef.value;
      if (activeNodeId) {
        gotoNode(activeNodeId);
      } else {
        const nodes = nodeSelectionRef.value;
        if (nodes.length > 0) {
          gotoNode(nodes[0].nodeId);
        }
      }
      return false;
    } else {
      return true;
    }
  }, [deleteSelectedNodes, moveNode, gotoNode, nodeSelectionRef, activeNodeIdRef]);

  return {onMovementKeyUp};
}

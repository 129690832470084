import {z} from "zod";
import {Dnd5eActionTemplateSegmentText, Dnd5eActionTemplateSegmentTextOperation, dnd5eActionTemplateSegmentTextType} from "./dnd-5e-action-template-segment-text.ts";
import {Dnd5eActionTemplateSegmentID, generateDnd5eActionTemplateSegmentID} from "./dnd-5e-action-template-segment-i-d.ts";
import {
  Dnd5eActionTemplateSegmentAttackRoll,
  Dnd5eActionTemplateSegmentAttackRollOperation,
  dnd5eActionTemplateSegmentAttackRollType
} from "./attack-roll/dnd-5e-action-template-segment-attack-roll.ts";
import {MultiType, Type} from "../../../../../../types/index.ts";
import {
  Dnd5eActionTemplateSegmentSavingThrow,
  Dnd5eActionTemplateSegmentSavingThrowOperation,
  dnd5eActionTemplateSegmentSavingThrowType
} from "./saving-throw/dnd-5e-action-template-segment-saving-throw.ts";
import {
  Dnd5eActionTemplateSegmentSection,
  Dnd5eActionTemplateSegmentSectionOperation,
  dnd5eActionTemplateSegmentSectionType
} from "./section/dnd-5e-action-template-segment-section.ts";
import {Dnd5eActionTemplateSegmentRoll, Dnd5eActionTemplateSegmentRollOperation, dnd5eActionTemplateSegmentRollType} from "./damage/dnd-5e-action-template-segment-roll.ts";
import {
  Dnd5eActionTemplateSegmentAbilityCheck,
  Dnd5eActionTemplateSegmentAbilityCheckOperation,
  dnd5eActionTemplateSegmentAbilityCheckType
} from "./ability-check/dnd-5e-action-template-segment-ability-check.ts";
import {Dnd5eActionTemplateSegmentAttackRollModifierFn} from "./attack-roll/dnd-5e-action-template-segment-attack-roll-modifier.ts";
import {Dnd5eActionTemplateSegmentRollModifierFn} from "./damage/dnd-5e-action-template-segment-roll-modifier.ts";
import {generateRollRequestID} from "../../../../../../qlab/index.ts";
import {Dnd5eActionTemplateSegmentSavingThrowModifierFn} from "./saving-throw/dnd-5e-action-template-segment-saving-throw-modifier.ts";
import {Dnd5eActionTemplateSegmentAbilityCheckModifierFn} from "./ability-check/dnd-5e-action-template-segment-ability-check-modifier.ts";

export const Dnd5eActionTemplateSegment: z.ZodType<
  | {type: "text", data: Dnd5eActionTemplateSegmentText}
  | {type: "section", data: Dnd5eActionTemplateSegmentSection}
  | {type: "roll", data: Dnd5eActionTemplateSegmentRoll}
  | {type: "attack-roll", data: Dnd5eActionTemplateSegmentAttackRoll}
  | {type: "saving-throw", data: Dnd5eActionTemplateSegmentSavingThrow}
  | {type: "ability-check", data: Dnd5eActionTemplateSegmentAbilityCheck}
> = z.lazy(() => z.discriminatedUnion("type", [
  z.object({type: z.literal("text"), data: Dnd5eActionTemplateSegmentText}),
  z.object({type: z.literal("section"), data: Dnd5eActionTemplateSegmentSection}),
  z.object({type: z.literal("roll"), data: Dnd5eActionTemplateSegmentRoll}),
  z.object({type: z.literal("attack-roll"), data: Dnd5eActionTemplateSegmentAttackRoll}),
  z.object({type: z.literal("saving-throw"), data: Dnd5eActionTemplateSegmentSavingThrow}),
  z.object({type: z.literal("ability-check"), data: Dnd5eActionTemplateSegmentAbilityCheck})
]));
export type Dnd5eActionTemplateSegment = z.infer<typeof Dnd5eActionTemplateSegment>;

export const Dnd5eActionTemplateSegmentOperation: z.ZodType<
  | {type: "text", operations: Dnd5eActionTemplateSegmentTextOperation[]}
  | {type: "section", operations: Dnd5eActionTemplateSegmentSectionOperation[]}
  | {type: "roll", operations: Dnd5eActionTemplateSegmentRollOperation[]}
  | {type: "attack-roll", operations: Dnd5eActionTemplateSegmentAttackRollOperation[]}
  | {type: "saving-throw", operations: Dnd5eActionTemplateSegmentSavingThrowOperation[]}
  | {type: "ability-check", operations: Dnd5eActionTemplateSegmentAbilityCheckOperation[]}
> = z.lazy(() => z.discriminatedUnion("type", [
  z.object({type: z.literal("text"), operations: z.array(Dnd5eActionTemplateSegmentTextOperation)}),
  z.object({type: z.literal("section"), operations: z.array(Dnd5eActionTemplateSegmentSectionOperation)}),
  z.object({type: z.literal("roll"), operations: z.array(Dnd5eActionTemplateSegmentRollOperation)}),
  z.object({type: z.literal("attack-roll"), operations: z.array(Dnd5eActionTemplateSegmentAttackRollOperation)}),
  z.object({type: z.literal("saving-throw"), operations: z.array(Dnd5eActionTemplateSegmentSavingThrowOperation)}),
  z.object({type: z.literal("ability-check"), operations: z.array(Dnd5eActionTemplateSegmentAbilityCheckOperation)})
]));
export type Dnd5eActionTemplateSegmentOperation = z.infer<typeof Dnd5eActionTemplateSegmentOperation>;

export type Dnd5eActionTemplateSegmentTypes = {
  text: Type<Dnd5eActionTemplateSegmentText, Dnd5eActionTemplateSegmentTextOperation>;
  section: Type<Dnd5eActionTemplateSegmentSection, Dnd5eActionTemplateSegmentSectionOperation>;
  roll: Type<Dnd5eActionTemplateSegmentRoll, Dnd5eActionTemplateSegmentRollOperation>;
  attackRoll: Type<Dnd5eActionTemplateSegmentAttackRoll, Dnd5eActionTemplateSegmentAttackRollOperation>;
  savingThrow: Type<Dnd5eActionTemplateSegmentSavingThrow, Dnd5eActionTemplateSegmentSavingThrowOperation>;
  abilityCheck: Type<Dnd5eActionTemplateSegmentAbilityCheck, Dnd5eActionTemplateSegmentAbilityCheckOperation>;
};
export const dnd5eActionTemplateSegmentType: Type<Dnd5eActionTemplateSegment, Dnd5eActionTemplateSegmentOperation> = new MultiType<Dnd5eActionTemplateSegmentTypes>(() => ({
  text: dnd5eActionTemplateSegmentTextType,
  section: dnd5eActionTemplateSegmentSectionType,
  roll: dnd5eActionTemplateSegmentRollType,
  attackRoll: dnd5eActionTemplateSegmentAttackRollType,
  savingThrow: dnd5eActionTemplateSegmentSavingThrowType,
  abilityCheck: dnd5eActionTemplateSegmentAbilityCheckType
}), v => {
  if (v?.type === "dice-roll") return {...v, type: "roll"};
  if (v?.type === "damage") {
    return {
      type: "roll",
      data: {
        actionSegmentID: v.data.actionSegmentID,
        expressions: v.data.expressions.map((expression: any) => ({
          rollID: expression.damageID,
          rollType: expression.damageType,
          expression: expression.expression
        })),
        modifiers: v.data.modifiers
      }
    } satisfies Dnd5eActionTemplateSegment;
  }
  return v;
});

export const Dnd5eActionTemplateSegmentFn = {
  getActionSegmentID(value: Dnd5eActionTemplateSegment): Dnd5eActionTemplateSegmentID {
    return Dnd5eActionTemplateSegmentID.parse(value.data.actionSegmentID);
  },
  copyActionSegment(value: Dnd5eActionTemplateSegment): Dnd5eActionTemplateSegment {
    if (value.type === "text") {
      return ({
        ...value,
        data: {
          ...value.data,
          actionSegmentID: generateDnd5eActionTemplateSegmentID(),
        }
      });
    } else if (value.type === "roll") {
      return ({
        ...value,
        data: {
          ...value.data,
          actionSegmentID: generateDnd5eActionTemplateSegmentID(),
          modifiers: value.data.modifiers.map(Dnd5eActionTemplateSegmentRollModifierFn.copyDnd5eActionTemplateSegmentRollModifier),
          expressions: value.data.expressions.map(expression => ({...expression, rollID: generateRollRequestID()}))
        }
      });
    } else if (value.type === "attack-roll") {
      return ({
        ...value,
        data: {
          ...value.data,
          actionSegmentID: generateDnd5eActionTemplateSegmentID(),
          modifiers: value.data.modifiers.map(Dnd5eActionTemplateSegmentAttackRollModifierFn.copyDnd5eActionTemplateSegmentAttackRollModifier),
          onHit: value.data.onHit.map(Dnd5eActionTemplateSegmentFn.copyActionSegment),
          onCriticalHit: value.data.onCriticalHit.map(Dnd5eActionTemplateSegmentFn.copyActionSegment),
          onMiss: value.data.onMiss.map(Dnd5eActionTemplateSegmentFn.copyActionSegment)
        }
      });
    } else if (value.type === "section") {
      return ({
        ...value,
        data: {
          ...value.data,
          actionSegmentID: generateDnd5eActionTemplateSegmentID(),
          segments: value.data.segments.map(Dnd5eActionTemplateSegmentFn.copyActionSegment)
        }
      });
    } else if (value.type === "saving-throw") {
      return ({
        ...value,
        data: {
          ...value.data,
          actionSegmentID: generateDnd5eActionTemplateSegmentID(),
          onSuccess: value.data.onSuccess.map(Dnd5eActionTemplateSegmentFn.copyActionSegment),
          onFailure: value.data.onFailure.map(Dnd5eActionTemplateSegmentFn.copyActionSegment),
          modifiers: value.data.modifiers.map(Dnd5eActionTemplateSegmentSavingThrowModifierFn.copyDnd5eActionTemplateSegmentSavingThrowModifier)
        }
      });
    } else {
      return ({
        ...value,
        data: {
          ...value.data,
          actionSegmentID: generateDnd5eActionTemplateSegmentID(),
          onFailure: value.data.onFailure.map(Dnd5eActionTemplateSegmentFn.copyActionSegment),
          onSuccess: value.data.onSuccess.map(Dnd5eActionTemplateSegmentFn.copyActionSegment),
          modifiers: value.data.modifiers.map(Dnd5eActionTemplateSegmentAbilityCheckModifierFn.copyDnd5eActionTemplateSegmentAbilityCheckModifier)
        }
      });
    }
  }
};
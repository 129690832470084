import {GameSystems, GameSystemsOperation, SystemSignals} from "common/legends/game/system/game-systems.ts";
import {useMemo} from "react";
import {Dnd5eSystemSignals} from "common/legends/game/system/dnd-5e-system.ts";
import {InputDnd5eFeatures} from "../sheet/editor/dnd-5e/dnd-5e-feature/input-dnd-5e-features.tsx";
import {MutableRef} from "common/ref";
import {Section} from "#lib/components/panel-header.tsx";

export type SystemsViewProps = {
  value: MutableRef<GameSystems, GameSystemsOperation[]>;
};

export function SystemsView({value}: SystemsViewProps) {
  const {dND5e} = useMemo(() => SystemSignals(value), [value]);
  const {globalFeatures} = useMemo(() => Dnd5eSystemSignals(dND5e), [dND5e]);
  return <div className="flex flex-col gap-1">
    <Section title="DND 5e" open={false}>
      <div className="mx-2 rounded-md overflow-hidden">
        <InputDnd5eFeatures label="Global Features" value={globalFeatures} />
      </div>
    </Section>
  </div>;
}
import React, {PropsWithChildren, useCallback, useContext, useMemo} from "react";
import {useRenderingContext} from "#lib/gl-react/index.ts";

const BlendFuncContext = React.createContext<{
  srcRGB: GLenum,
  dstRGB: GLenum,
  srcAlpha: GLenum,
  dstAlpha: GLenum
}>({
  srcRGB: WebGL2RenderingContext.SRC_ALPHA,
  dstRGB: WebGL2RenderingContext.ONE_MINUS_SRC_ALPHA,
  srcAlpha: WebGL2RenderingContext.SRC_ALPHA,
  dstAlpha: WebGL2RenderingContext.ONE_MINUS_SRC_ALPHA
});

export function BlendFuncSeperate({srcRGB, dstRGB, srcAlpha, dstAlpha, children}: PropsWithChildren<{
  srcRGB: GLenum,
  dstRGB: GLenum,
  srcAlpha: GLenum,
  dstAlpha: GLenum
}>) {
  const context = useRenderingContext();
  const onBind = useCallback(() => {
    context.blendFuncSeparate(srcRGB, dstRGB, srcAlpha, dstAlpha);
  }, [context, srcRGB, dstRGB, srcAlpha, dstAlpha]);

  const previous = useContext(BlendFuncContext);
  const onUnbind = useCallback(() => {
    context.blendFuncSeparate(
      previous.srcRGB,
      previous.dstRGB,
      previous.srcAlpha,
      previous.dstAlpha,
    );
  }, [context, previous]);

  return <BlendFuncContext.Provider value={useMemo(() => ({srcRGB, dstRGB, srcAlpha, dstAlpha}), [])}>
    <binder onBind={onBind} onUnbind={onUnbind}>
      {children}
    </binder>
  </BlendFuncContext.Provider>
}

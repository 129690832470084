import {z} from "zod";
import {ulid} from "ulid";

export const SenseID = z.union([z.literal("ALL"), z.literal("GM"), z.string()]);
export type SenseID  = z.infer<typeof SenseID>;

export const SenseIDFn = {
  generate: () => {
    return SenseID.parse(ulid());
  }
}
import React, {forwardRef, PropsWithChildren, Ref, useImperativeHandle, useMemo} from "react";
import {useResolution} from "#lib/gl-react/hooks/resolution-context.ts";
import {useRenderingContext, useTexture2D} from "#lib/gl-react/index.ts";
import {useFramebuffer} from "#lib/gl-react/hooks/use-framebuffer.ts";

export const Framebuffer = forwardRef(function Framebuffer({children, onBind, onUnbind}: PropsWithChildren<{
  onBind?: (context: WebGL2RenderingContext) => void,
  onUnbind?: (context: WebGL2RenderingContext) => void,
}>, ref: Ref<WebGLTexture>) {
  const texture = useTexture2D();

  useImperativeHandle(ref, () => texture, [texture]);

  const size = useResolution();
  const context = useRenderingContext();
  useMemo(() => {
    if (size[0] > 0 && size[1] > 0) {
      context.bindTexture(WebGL2RenderingContext.TEXTURE_2D, texture);
      context.texImage2D(WebGL2RenderingContext.TEXTURE_2D, 0, WebGL2RenderingContext.RGBA, size[0], size[1], 0, WebGL2RenderingContext.RGBA, WebGL2RenderingContext.UNSIGNED_BYTE, null);
      context.texParameteri(WebGL2RenderingContext.TEXTURE_2D, WebGL2RenderingContext.TEXTURE_MIN_FILTER, WebGL2RenderingContext.LINEAR);
      context.texParameteri(WebGL2RenderingContext.TEXTURE_2D, WebGL2RenderingContext.TEXTURE_MAG_FILTER, WebGL2RenderingContext.LINEAR);
      context.bindTexture(WebGL2RenderingContext.TEXTURE_2D, null);
    }
  }, [texture, size]);

  const framebuffer = useFramebuffer();
  useMemo(() => {
    if (size[0] > 0 && size[1] > 0) {
      context.bindFramebuffer(WebGL2RenderingContext.FRAMEBUFFER, framebuffer);
      context.framebufferTexture2D(WebGL2RenderingContext.FRAMEBUFFER, WebGL2RenderingContext.COLOR_ATTACHMENT0, WebGL2RenderingContext.TEXTURE_2D, texture, 0);
      context.bindFramebuffer(WebGL2RenderingContext.FRAMEBUFFER, null);
    }
  }, [framebuffer, texture, size]);
  if (size[0] === 0 || size[1] === 0) return <></>
  return <framebuffer value={framebuffer}>
    <viewport x={0} y={0} width={size[0]} height={size[1]}/>
    <action onAction={(context) => {
      context.clearColor(0, 0, 0, 0);
      context.clear(WebGL2RenderingContext.COLOR_BUFFER_BIT);
    }}/>
    <binder onBind={onBind} onUnbind={onUnbind} />
    {children}
  </framebuffer>;
});

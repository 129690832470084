import {MutableRef} from "common/ref";
import {ButtonBar, ButtonBarProps, Checkbox, InputGroup, InputGroupLabel} from "#lib/components/index.ts";
import {useRefValue} from "#lib/signal/index.ts";
import {useGameSenses} from "../../../legends/common/game/use-game-senses.ts";
import {ConstantOperation, Optional, ValueFn, ValueOperation} from "common/types/generic/index.ts";
import {twMerge} from "tailwind-merge";
import {ExpandOptions} from "#lib/components/expand-options.tsx";
import {Sense} from "common/legends/game/sense/sense.ts";
import {SenseID} from "common/legends/game/sense/sense-i-d.ts";
import {faChevronDown, faChevronUp} from "@awesome.me/kit-c9bc6845cd/icons/classic/solid";

export function InputSelectSense({valueRef, ...props}: {
  valueRef: MutableRef<Optional<SenseID>, ValueOperation<Optional<SenseID>, ConstantOperation>[]>;
} & ButtonBarProps) {
  const value = useRefValue(valueRef);
  const sensesRef = useGameSenses();
  const senses: Sense[] = useRefValue(sensesRef);
  const setSense = (senseID: Optional<SenseID>, checked: boolean) => {
    valueRef.apply(prev => ValueFn.set(prev, checked ? senseID : undefined));
  };
  return <ButtonBar {...props} className={twMerge("px-0 rounded-md overflow-hidden w-fit flex flex-row gap-0.5", props.className)}>
    <InputGroup size="small" className="px-3" title="None">
      <Checkbox type="radio" size="small" checked={value === undefined} onChange={ev => setSense(undefined, ev.target.checked)}/>
    </InputGroup>
    {senses.map(vision => <InputGroup key={vision.senseID} size="small" className="px-3" title={vision.name} style={{backgroundColor: `hsla(${vision.color[0] * 360}, ${vision.color[1] * 100}%, ${vision.color[2] * 100}%, 25%)`}}>
      <Checkbox type="radio" size="small" checked={value === vision.senseID} onChange={ev => setSense(vision.senseID, ev.target.checked)}/>
    </InputGroup>)}

    <ExpandOptions size="small" openIcon={faChevronUp} closeIcon={faChevronDown}>
      <InputGroup className="pl-0">
        <InputGroup>
          <Checkbox type="radio" checked={value === undefined} onChange={ev => setSense(undefined, ev.target.checked)} />
        </InputGroup>
        <InputGroupLabel>
          None
        </InputGroupLabel>
      </InputGroup>
      {senses.map(vision => <InputGroup key={vision.senseID} className="pl-0" style={{backgroundColor: `hsla(${vision.color[0] * 360}, ${vision.color[1] * 100}%, ${vision.color[2] * 100}%, 25%)`}}>
        <InputGroup>
          <Checkbox type="radio" checked={value === vision.senseID} onChange={ev => setSense(vision.senseID, ev.target.checked)} style={{backgroundColor: `hsl(${vision.color[0]}, ${vision.color[1]}, ${vision.color[2]})`}} />
        </InputGroup>
        <InputGroupLabel>
          {vision.name}
        </InputGroupLabel>
      </InputGroup>)}
    </ExpandOptions>
  </ButtonBar>;
}

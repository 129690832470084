import {RefObject, useCallback} from "react";
import {HSLA, Optional, Point, SetOperation, Transform, TransformOperation, TreeOperation} from "common/types/index.ts";
import {Measurement, MeasurementOperation} from "common/legends/measurement/index.ts";
import {Grid, Node, NodeId, NodeOperation} from "common/legends/index.ts";
import {ContextMenu, ContextMenuOperation} from "../../../routes/game/context-menu/context-menu.ts";
import {usePanHandlers} from "./use-pan-handlers.ts";
import {usePingHandlers} from "./use-ping-handlers.ts";
import {useZoomHandlers} from "./use-zoom-tool-handlers.ts";
import {useDropHandlers} from "./use-drop-handlers.ts";
import {useMeasureHandlers} from "./use-measure-handlers.ts";
import {useTouchHandlers} from "./use-touch-handlers.ts";
import {useSelectToolHandlers} from "./use-select-tool-handlers.ts";
import {onMovementHandlers} from "./use-movement-handlers.ts";
import {MutableRef, Ref} from "common/ref";
import {Tool, ToolOperation} from "../../common/tool/tool.ts";
import {AssetTokenSelectionRef, NodeSelectionRef, SceneSelectionRef} from "../../panel/nav/editor/state/selection-ref.ts";
import {useMouseEventHandlers, useWheelEventHandlers} from "../../panel/nav/common/tool/tool-selector/use-mouse-event-handlers.ts";
import {useIsSpaceDown} from "#lib/components/use-is-space-down.ts";
import {useKeyEventHandlers} from "../../panel/nav/common/tool/tool-selector/use-key-event-handlers.ts";

export function useMeasurementToolHandlers(
  canvasRef: RefObject<HTMLCanvasElement>,
  rootSelectionRef: SceneSelectionRef | AssetTokenSelectionRef,
  tool: MutableRef<Tool, ToolOperation[]>,
  view: MutableRef<Transform, TransformOperation[]>,
  measurement: MutableRef<Measurement, MeasurementOperation[]>,
  grid: Grid,
  nodes: MutableRef<Node[], TreeOperation<Node, NodeOperation>[]>,
  selectedNodeIds: MutableRef<NodeSelectionRef[], SetOperation<NodeSelectionRef>[]>,
  activeNodeIdRef: Ref<Optional<NodeId>>,
  ping: (p: Point, focus: boolean, color: HSLA) => void,
  color: HSLA,
  contextMenu: MutableRef<ContextMenu, ContextMenuOperation[]>,
  isAccessible: (node: Node) => boolean,
  isVisible: (node: Node) => boolean
) {
  const spaceDownRef = useIsSpaceDown();
  const {onPanMouseDown, onPanMouseMove, onPanMouseUp, onPanKeyDown, onPanKeyUp, onPanMouseEnter, onPanWheel, onPanContextMenu} = usePanHandlers(canvasRef, view,
    useCallback(ev => (ev.buttons === 1 && spaceDownRef.value) || ev.buttons === 2 || ev.buttons === 4, [spaceDownRef])
  );
  const {onPingMouseDown, onPingMouseMove, onPingMouseUp} = usePingHandlers(canvasRef, view, ping, color);
  const {onSelectMouseDown, onSelectMouseUp, onSelectContextMenu} = useSelectToolHandlers(canvasRef, rootSelectionRef, activeNodeIdRef, view, measurement, grid, nodes, selectedNodeIds, color, contextMenu, isAccessible, isVisible);
  const {onMeasureMouseDown, onMeasureMouseMove, onMeasureMouseUp, onMeasureMouseLeave, onMeasureKeyUp, onMeasureContextMenu} = useMeasureHandlers(canvasRef, rootSelectionRef, view, grid, tool, measurement, color);
  const {onZoomWheel, onZoomKeyDown} = useZoomHandlers(canvasRef, view);
  const {onTouchStart, onTouchMove, onTouchEnd} = useTouchHandlers(canvasRef, view);
  const {onMovementKeyUp} = onMovementHandlers(activeNodeIdRef, selectedNodeIds, grid, nodes, isAccessible, isVisible, view);

  const onMouseDown = useMouseEventHandlers(onMeasureMouseDown, onSelectMouseDown, onPanMouseDown, onPingMouseDown);
  const onMouseMove = useMouseEventHandlers(onMeasureMouseMove, onPingMouseMove, onPanMouseMove);
  const onMouseUp = useMouseEventHandlers(onMeasureMouseUp,onPingMouseUp, onPanMouseUp, onSelectMouseUp);
  const onMouseLeave = useMouseEventHandlers(onMeasureMouseLeave);
  const onMouseEnter = useMouseEventHandlers(onPanMouseEnter);

  const onKeyDown = useKeyEventHandlers(onZoomKeyDown, onPanKeyDown);
  const onKeyUp = useKeyEventHandlers(onMeasureKeyUp, onPanKeyUp, onMovementKeyUp);
  const onContextMenu = useMouseEventHandlers(onMeasureContextMenu, onPanContextMenu, onSelectContextMenu);
  const onWheel = useWheelEventHandlers(onZoomWheel, onPanWheel);

  const {onDrop} = useDropHandlers(view, nodes);
  return {
    onMouseUp,
    onMouseMove,
    onMouseDown,
    onMouseLeave,
    onMouseEnter,
    onTouchStart,
    onTouchMove,
    onTouchEnd,
    onWheel,
    onContextMenu,
    onKeyDown,
    onKeyUp,
    onDrop
  };
}
import {Optional} from "common/types/generic/index.ts";
import {Dnd5eFeature, Sheet} from "common/legends/asset/index.ts";
import {Dnd5eActionTemplate} from "common/legends/asset/sheet/dnd-5e/dnd-5e-action-definition/template/dnd-5e-action-template.ts";
import {Dnd5eActionTemplateSegment} from "common/legends/asset/sheet/dnd-5e/dnd-5e-action-definition/segment/dnd-5e-action-template-segment.ts";
import {
  Dnd5eActionTemplateSegmentAttackRollModifier
} from "common/legends/asset/sheet/dnd-5e/dnd-5e-action-definition/segment/attack-roll/dnd-5e-action-template-segment-attack-roll-modifier.ts";
import {conditionEvaluator} from "../condition/condition-evaluator.ts";
import {getActiveModifiers} from "common/legends/asset/sheet/dnd-5e/dnd-5e-modifier-helper.ts";
import {Dnd5eActionTemplateModifier} from "common/legends/asset/sheet/dnd-5e/dnd-5e-action-definition/modifier/dnd-5e-action-template-modifier.ts";

export function getActiveAttackRollModifiers(
  sheet: Optional<Sheet>,
  globalFeatures: Dnd5eFeature[],
  action: Optional<Dnd5eActionTemplate>,
  segment: Optional<Dnd5eActionTemplateSegment>
): Dnd5eActionTemplateSegmentAttackRollModifier[] {
  const evaluateCondition = conditionEvaluator(sheet, globalFeatures, action);
  const getSheetModifiers = (): Dnd5eActionTemplateSegmentAttackRollModifier[] => {
    return getActiveModifiers(sheet, globalFeatures)
      .flatMap(modifier => modifier.type === "action::attack-roll::hit-modifier" ? [modifier] : []);
  };
  const getActionModifiers = (modifier: Dnd5eActionTemplateModifier): Dnd5eActionTemplateSegmentAttackRollModifier[] => {
    if (modifier.type === "action::condition") {
      return evaluateCondition(modifier.data.condition)
        ? modifier.data.modifiers.flatMap(getActionModifiers)
        : [];
    } else if (modifier.type === "variable" || modifier.type === "action::attack-roll::hit-modifier") {
      return [modifier];
    }
    return [];
  };
  const getSegmentModifiers = (modifier: Dnd5eActionTemplateSegmentAttackRollModifier): Dnd5eActionTemplateSegmentAttackRollModifier[] => {
    if (modifier.type === "action::attack-roll::condition") {
      return evaluateCondition(modifier.data.condition)
        ? [modifier, ...modifier.data.modifiers.flatMap(getSegmentModifiers)]
        : [];
    } else {
      return [modifier];
    }
  };

  const actionModifiers = action?.type === "custom" ? ([
    ...action.data.modifiers,
    ...action.data.effects.filter(effect => effect.enabled).flatMap(effect => effect.modifiers)
  ]).flatMap(getActionModifiers) : [];
  const segmentModifiers = segment?.type === "attack-roll" ? segment.data.modifiers.flatMap(getSegmentModifiers) : [];
  return [
    ...getSheetModifiers(),
    ...actionModifiers,
    ...segmentModifiers
  ];
}


import {z} from "zod";
import {Dnd5eModifierID} from "../../../dnd-5e-modifier/dnd-5e-modifier-i-d.ts";
import {BooleanOperation, booleanType, ConstantOperation, constantType, ObjectType, Type} from "../../../../../../../types/index.ts";

export const Dnd5eActionTemplateSegmentAttackRollProficientOverrideModifier: z.ZodType<{
  modifierID: string,
  proficient: boolean
}> = z.object({
  modifierID: Dnd5eModifierID,
  proficient: z.boolean()
});
export type Dnd5eActionTemplateSegmentAttackRollProficientOverrideModifier = z.infer<typeof Dnd5eActionTemplateSegmentAttackRollProficientOverrideModifier>;
export const Dnd5eActionTemplateSegmentAttackRollProficientOverrideModifierOperation = z.discriminatedUnion("type", [
  z.object({type: z.literal("update-modifier-i-d"), operations: z.array(ConstantOperation)}),
  z.object({type: z.literal("update-proficient"), operations: z.array(BooleanOperation)})
]);
export type Dnd5eActionTemplateSegmentAttackRollProficientOverrideModifierOperation = z.infer<typeof Dnd5eActionTemplateSegmentAttackRollProficientOverrideModifierOperation>;
export const dnd5eActionTemplateSegmentAttackRollProficientOverrideModifierType: Type<Dnd5eActionTemplateSegmentAttackRollProficientOverrideModifier, Dnd5eActionTemplateSegmentAttackRollProficientOverrideModifierOperation> = new ObjectType({
  modifierID: constantType,
  proficient: booleanType
});


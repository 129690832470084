import {z} from "zod";
import {
  Dnd5eActionTemplateSegmentAbilityCheckModifier,
  Dnd5eActionTemplateSegmentAbilityCheckModifierOperation,
  dnd5eActionTemplateSegmentAbilityCheckModifierType
} from "./dnd-5e-action-template-segment-ability-check-modifier.ts";
import {
  ConstantOperation,
  constantType,
  ListPropertyRef,
  ListType,
  ObjectType,
  Type,
  ValueOperation,
  ValuePropertyRef,
  ValueType,
  ZodListOperation
} from "../../../../../../../types/index.ts";
import {Dnd5eActionCondition} from "../../condition/dnd-5e-action-condition.ts";
import {Dnd5eModifierID} from "../../../dnd-5e-modifier/dnd-5e-modifier-i-d.ts";
import {MutableRef} from "#common/ref";

export const Dnd5eActionTemplateSegmentAbilityCheckConditionModifier = z.lazy(() => z.object({
  modifierID: Dnd5eModifierID,
  condition: Dnd5eActionCondition,
  modifiers: z.array(Dnd5eActionTemplateSegmentAbilityCheckModifier)
}));
export type Dnd5eActionTemplateSegmentAbilityCheckConditionModifier = z.infer<typeof Dnd5eActionTemplateSegmentAbilityCheckConditionModifier>;

export const Dnd5eActionTemplateSegmentAbilityCheckConditionModifierOperation = z.lazy(() => z.discriminatedUnion("type", [
  z.object({type: z.literal("update-modifier-i-d"), operations: z.array(ConstantOperation)}),
  z.object({type: z.literal("update-condition"), operations: z.array(ValueOperation(Dnd5eActionCondition, ConstantOperation))}),
  z.object({type: z.literal("update-modifiers"), operations: z.array(ZodListOperation(Dnd5eActionTemplateSegmentAbilityCheckModifier, Dnd5eActionTemplateSegmentAbilityCheckModifierOperation))})
]));
export type Dnd5eActionTemplateSegmentAbilityCheckConditionModifierOperation = z.infer<typeof Dnd5eActionTemplateSegmentAbilityCheckConditionModifierOperation>;

export const dnd5eActionTemplateSegmentAbilityCheckConditionModifierType: Type<Dnd5eActionTemplateSegmentAbilityCheckConditionModifier, Dnd5eActionTemplateSegmentAbilityCheckConditionModifierOperation> = new ObjectType(() => ({
  modifierID: constantType,
  condition: new ValueType(constantType),
  modifiers: new ListType(dnd5eActionTemplateSegmentAbilityCheckModifierType)
}));

export function Dnd5eActionTemplateSegmentAbilityCheckConditionModifierSignals(valueRef: MutableRef<Dnd5eActionTemplateSegmentAbilityCheckConditionModifier, Dnd5eActionTemplateSegmentAbilityCheckConditionModifierOperation[]>) {
  return {
    conditionRef: ValuePropertyRef<Dnd5eActionTemplateSegmentAbilityCheckConditionModifier, Dnd5eActionTemplateSegmentAbilityCheckConditionModifierOperation, Dnd5eActionCondition, ConstantOperation>(
      value => value.condition,
      operations => [{type: "update-condition", operations}]
    )(valueRef),
    modifiersRef: ListPropertyRef<Dnd5eActionTemplateSegmentAbilityCheckConditionModifier, Dnd5eActionTemplateSegmentAbilityCheckConditionModifierOperation, Dnd5eActionTemplateSegmentAbilityCheckModifier, Dnd5eActionTemplateSegmentAbilityCheckModifierOperation>(
      value => value.modifiers,
      operations => [{type: "update-modifiers", operations}]
    )(valueRef)
  };
}
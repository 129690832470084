import React, {useCallback} from "react";

export type ClearCanvasProps = {
  width: number;
  height: number;
};

export function ClearCanvas({width, height}: ClearCanvasProps) {
  return (<>
    <viewport x={0} y={0} width={width} height={height} />
    <action onAction={useCallback((context: WebGL2RenderingContext) => {
      context.clearColor(0, 0, 0, 1);
      context.clear(WebGL2RenderingContext.COLOR_BUFFER_BIT | WebGL2RenderingContext.DEPTH_BUFFER_BIT);
      context.enable(WebGL2RenderingContext.BLEND);
      context.disable(WebGL2RenderingContext.DEPTH_TEST);
      context.depthFunc(WebGL2RenderingContext.ALWAYS);
    }, [])}/>
  </>);
}
import {IconButton} from "../button/index.ts";
import React from "react";
import {HSLA} from "common/types/index.ts";

export const TAG_COLORS: HSLA[] = [
  [0.00, 0.00, 0.40, 1] as HSLA,
  [0.09, 0.80, 0.46, 1] as HSLA,
  [0.02, 0.80, 0.57, 1] as HSLA,
  [0.58, 0.85, 0.53, 1] as HSLA,
  [0.40, 0.76, 0.35, 1] as HSLA
];

export type TagListInputColorPickerProps = {
  onSelect: (value: HSLA) => void;
};

export function TagListInputColorPicker(props: TagListInputColorPickerProps) {
  return <div className="z-10 flex flex-row gap-1 absolute top-0 left-[52px]">
    {TAG_COLORS.map((color, index) => <IconButton
      key={index}
      style={{backgroundColor: `hsl(${color[0]*360}, ${color[1] * 100}%, ${color[2] * 100}%)`}}
      onClick={ev => {
        props.onSelect(color);
        ev.preventDefault();
        return false;
      }} />)}
  </div>;
}
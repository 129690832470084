import {Optional} from "common/types/generic/index.ts";
import {Dnd5eFeature, Sheet} from "common/legends/asset/index.ts";
import {DiceExpression} from "common/dice/dice-expression.ts";
import {Dnd5eActionTemplateSegment} from "common/legends/asset/sheet/dnd-5e/dnd-5e-action-definition/segment/dnd-5e-action-template-segment.ts";
import {Dnd5eActionTemplate} from "common/legends/asset/sheet/dnd-5e/dnd-5e-action-definition/template/dnd-5e-action-template.ts";
import {conditionEvaluator} from "../condition/condition-evaluator.ts";
import {Dnd5eActionTemplateModifier} from "common/legends/asset/sheet/dnd-5e/dnd-5e-action-definition/modifier/dnd-5e-action-template-modifier.ts";
import {
  Dnd5eActionTemplateSegmentAttackRollCriticalRangeModifier
} from "common/legends/asset/sheet/dnd-5e/dnd-5e-action-definition/segment/attack-roll/dnd-5e-action-template-segment-attack-roll-critical-range-modifier.ts";
import {
  Dnd5eActionTemplateSegmentAttackRollModifier
} from "common/legends/asset/sheet/dnd-5e/dnd-5e-action-definition/segment/attack-roll/dnd-5e-action-template-segment-attack-roll-modifier.ts";

export function getActiveCriticalRange(
  sheet: Optional<Sheet>,
  globalFeatures: Dnd5eFeature[],
  action: Optional<Dnd5eActionTemplate>,
  segment: Optional<Dnd5eActionTemplateSegment>
): DiceExpression {
  const evaluateCondition = conditionEvaluator(sheet, globalFeatures, action);
  const getActionModifiers = (modifier: Dnd5eActionTemplateModifier): Dnd5eActionTemplateSegmentAttackRollCriticalRangeModifier[] => {
    if (modifier.type === "action::condition") {
      return evaluateCondition(modifier.data.condition)
        ? modifier.data.modifiers.flatMap(getActionModifiers, [])
        : [];
    // } else if (modifier.type === "action::attack-roll::critical-range") {
    //   return [modifier.data];
    } else {
      return [];
    }
  };
  const getSegmentModifiers = (modifier: Dnd5eActionTemplateSegmentAttackRollModifier): Dnd5eActionTemplateSegmentAttackRollCriticalRangeModifier[] => {
    if (modifier.type === "action::attack-roll::condition") {
      return evaluateCondition(modifier.data.condition) ? modifier.data.modifiers.flatMap(getSegmentModifiers) : [];
    } else if (modifier.type === "action::attack-roll::critical-range") {
      return [modifier.data];
    } else {
      return [];
    }
  };

  const effectModifiers = action?.type === "custom" ? [
    ...action.data.modifiers,
    ...action.data.effects.filter(effect => effect.enabled).flatMap(effect => effect.modifiers)
  ].flatMap(getActionModifiers) : [];
  const segmentModifiers = segment?.type === "attack-roll" ? segment.data.modifiers.flatMap(getSegmentModifiers) : [];
  if ((effectModifiers.length + segmentModifiers.length) === 0) return DiceExpression.parse("0");
  return DiceExpression.parse([
    ...effectModifiers,
    ...segmentModifiers
  ].map(modifier => modifier.expression).join("+").replaceAll("+-", "-"));
}

import {
  Dnd5eActionTemplateSegmentAttackRollHitModifier
} from "common/legends/asset/sheet/dnd-5e/dnd-5e-action-definition/segment/attack-roll/dnd-5e-action-template-segment-attack-roll-hit-modifier.ts";
import {Optional} from "common/types/generic/index.ts";
import {Dnd5eFeature, Sheet} from "common/legends/asset/index.ts";
import {Dnd5eActionTemplate} from "common/legends/asset/sheet/dnd-5e/dnd-5e-action-definition/template/dnd-5e-action-template.ts";
import {Dnd5eActionTemplateSegment} from "common/legends/asset/sheet/dnd-5e/dnd-5e-action-definition/segment/dnd-5e-action-template-segment.ts";
import {getActiveAttackRollModifiers} from "./get-active-attack-roll-modifiers.ts";

export function getActiveHitModifiers(
  sheet: Optional<Sheet>,
  globalFeatures: Dnd5eFeature[],
  action: Optional<Dnd5eActionTemplate>,
  segment: Optional<Dnd5eActionTemplateSegment>
): Dnd5eActionTemplateSegmentAttackRollHitModifier[] {
  return getActiveAttackRollModifiers(sheet, globalFeatures, action, segment)
    .flatMap(modifier => modifier.type === "action::attack-roll::hit-modifier" ? [modifier.data] : []);
}

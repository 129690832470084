import {Dnd5eEffect, Dnd5eEffectFn, Dnd5eEffectOperation, Dnd5eEffectSignals, generateDnd5eEffectID} from "common/legends/asset/sheet/dnd-5e/dnd-5e-effect/index.ts";
import {BooleanFn, ListOperation, ListSignal, StringFn} from "common/types/index.ts";
import React, {useCallback, useMemo} from "react";
import {Button, ButtonBar, Checkbox, ExpandableLabel, IconButton, Input, InputGroup, useToggle} from "#lib/components/index.ts";
import {Fieldset} from "#lib/components/fieldset/fieldset.tsx";
import {FaPlus, FaTrash} from "react-icons/fa";
import {Dnd5eModifiersView} from "../dnd-5e-modifier/dnd-5e-modifiers-view.tsx";
import {SectionHeader} from "#lib/components/panel-header.tsx";
import {useRefValue} from "#lib/signal/index.ts";
import {InputList, useDragListItem} from "#lib/components/list/input-list.tsx";
import {copyDnd5eEffect} from "common/legends/asset/sheet/dnd-5e/dnd-5e-effect/copy-dnd-5e-effect.ts";
import {twMerge} from "tailwind-merge";
import {DragIndicator} from "#lib/components/tree-view/drag-indicator.tsx";
import {MutableRef} from "common/ref";

export type Dnd5eEffectViewProps = {
  item: MutableRef<Dnd5eEffect, Dnd5eEffectOperation[]>;
  remove: () => void;
};
export function Dnd5eEffectView({item, remove}: Dnd5eEffectViewProps) {
  const {enabled, name, modifiers} = useMemo(() => Dnd5eEffectSignals(item), [item]);
  const [expanded, toggleExpanded] = useToggle(false);

  const isEnabled = useRefValue(enabled);
  const nameValue = useRefValue(name);

  const [dragHandlerRef, dragRefPreview] = useDragListItem("legends/effect", item, remove);
  return <div role="list-item" ref={dragRefPreview} className={twMerge("flex flex-col")}>
    <ButtonBar>
      <ExpandableLabel size="small" className="w-full" expanded={expanded} toggleExpand={toggleExpanded}>
        <IconButton ref={dragHandlerRef} title="Move"><DragIndicator /></IconButton>
        <InputGroup className="pl-4 pr-2">
          <Checkbox checked={isEnabled} onChange={ev => {
            const next = ev.target.checked;
            enabled.apply(prev => BooleanFn.set(prev, next));
          }} />
        </InputGroup>
        <InputGroup className="pl-2 flex-1">
          <Input type="text" value={nameValue} onChange={ev => {
            const next = ev.target.value;
            name.apply(prev => StringFn.set(prev, next));
          }} />
        </InputGroup>
        <IconButton variant="destructive" onClick={remove}><FaTrash /></IconButton>
      </ExpandableLabel>
    </ButtonBar>
    {expanded && <Fieldset className="mx-0">
      <Dnd5eModifiersView value={modifiers} />
    </Fieldset>}
  </div>
}

export type Dnd5eEffectsViewProps = {
  value: ListSignal<Dnd5eEffect, Dnd5eEffectOperation>;
  label?: string;
};
export function Dnd5eEffectsView({value, label = "Effects"}: Dnd5eEffectsViewProps) {
  const addEffect = useCallback(() => value.apply(prev => {
    return ListOperation.insert(prev.length, {
      effectID: generateDnd5eEffectID(),
      name: "Effect",
      enabled: false,
      modifiers: []
    } satisfies Dnd5eEffect);
  }), [value.apply]);

  return <div className="flex flex-col gap-1">
    <div className="flex flex-row gap-0.5">
      <SectionHeader className="flex-1 bg-zinc-800/50">{label}</SectionHeader>
      <ButtonBar>
        <Button onClick={addEffect}><FaPlus /> Add Effect</Button>
      </ButtonBar>
    </div>
    <div className="flex flex-col gap-0.5">
      <InputList accept={"legends/effect"} items={value} itemKey={Dnd5eEffectFn.getEffectID} copy={copyDnd5eEffect} ListItem={Dnd5eEffectView} />
    </div>
  </div>
}
import {generateRollRequestID, RollRequests, RollVariables} from "common/qlab/index.ts";
import {encryptValue, Optional, RichText, RichTextFn} from "common/types/index.ts";
import {DiceExpression} from "common/dice/index.ts";
import {NodeId} from "common/legends/index.ts";
import {Dnd5eDamageType} from "common/legends/asset/sheet/dnd-5e/dnd-5e-damage-type.ts";
import {useGetNodeIcon} from "../../../../../common/use-get-node-icon.ts";
import {useSendDnd5eAction} from "../../dnd-5e/use-send-dnd-5e-action.ts";
import {Dnd5eActionDiceRoll, Dnd5eActionSegment} from "common/qlab/message/dnd-5e/dnd-5e-action-message.ts";

export function useSendFeatureMessage() {
  const getNodeIcon = useGetNodeIcon();
  const sendDnd5eAction = useSendDnd5eAction();

  return async (
    nodeID: Optional<NodeId>,
    title: string,
    damageExpressions: {
      damageType: Optional<Dnd5eDamageType>
      hitExpression: DiceExpression
    }[],
    description?: RichText,
    variables?: RollVariables
  ): Promise<void> => {
    const icon = getNodeIcon(nodeID);
    const rolls: Dnd5eActionDiceRoll[] = [];
    const rollRequests: RollRequests = {};
    for (const damageRoll of damageExpressions) {
      const rollID = generateRollRequestID();
      rolls.push({
        rollType: damageRoll.damageType,
        rollID: rollID
      });
      rollRequests[rollID] = {
        expression: damageRoll.hitExpression,
        variables: variables || {},
        visibility: {
          audience: {},
          default: {
            canViewExpression: true,
            canViewResult: true
          }
        }
      };
    }

    const encryptedRollRequests = await encryptValue({}, () => rollRequests);
    const actionSegments: Dnd5eActionSegment[] = [];
    if (description && !RichTextFn.isEmpty(description)) actionSegments.push({type: "text", data: description});
    if (rolls.length > 0) actionSegments.push({type: "roll", data: rolls});

    await sendDnd5eAction({
      title,
      nodeID, icon,
      referenceMessageID: undefined,
      rollRequests: encryptedRollRequests,
      segments: actionSegments
    });
  };
}

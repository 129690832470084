import {Select} from "#lib/components/index.ts";
import React, {ChangeEvent, useMemo} from "react";
import {ConstantOperation, Optional, ValueFn, ValueOperation} from "common/types/generic/index.ts";
import {Dnd5eEffectID} from "common/legends/asset/sheet/dnd-5e/dnd-5e-effect/index.ts";
import {MutableRef} from "common/ref";
import {useActionRef} from "../action-context.ts";
import {useRefValue} from "#lib/signal/index.ts";
import {Dnd5eActionTemplateSignals} from "common/legends/asset/sheet/dnd-5e/dnd-5e-action-definition/template/dnd-5e-action-template.ts";

export function InputEffectID({valueRef}: {
  valueRef: MutableRef<Optional<Dnd5eEffectID>, ValueOperation<Optional<Dnd5eEffectID>, ConstantOperation>[]>
}) {
  const actionRef = useActionRef()!;
  const {effectsRef} = useMemo(() => Dnd5eActionTemplateSignals(actionRef), [actionRef]);
  const effects = useRefValue(effectsRef);

  const effectID = useRefValue(valueRef);
  return (
    <Select value={effectID} onChange={(ev: ChangeEvent<HTMLSelectElement>) => {
      const effectID = (ev.target.value === "" ? undefined : ev.target.value) as Optional<Dnd5eEffectID>;
      valueRef.apply(prev => ValueFn.set(prev, effectID));
    }}>
      <option>N/A</option>
      {effects.map((effect) => <option key={effect.actionEffectID} value={effect.actionEffectID}>{effect.name}</option>)}
    </Select>
  );
}
import {useCallback} from "react";
import {NodeId} from "common/legends/index.ts";
import {SetFn, Transform, Tree, ValueFn} from "common/types/index.ts";
import {Vector2f} from "#lib/math/index.ts";
import {useEditor} from "./editor-context.ts";
import {userIDRef} from "#lib/auth/use-get-user-id.ts";
import {useDatabase, useStoreID} from "../../../../routes/game/model/store-context.tsx";
import {usePlayerController} from "../player/player-controller-provider.ts";
import {getNodePath} from "../../../common/node/use-get-node-path.ts";
import {ToolFn} from "../../../common/tool/tool.ts";
import {isNodeOwner} from "../../../common/node/use-is-party-node.ts";
import {TokenViewportPropertiesOperation} from "../../../viewport/token/token-viewport-properties.ts";

export function useGoToNode() {
  const gameID = useStoreID();
  const databaseRef = useDatabase();

  const editor = useEditor();
  const playerController = usePlayerController();

  return useCallback(async (nodeId: NodeId) => {
    const database = databaseRef.value;
    const nodePath = getNodePath(database, nodeId);
    if (nodePath?.type !== "scene") return;
    const sceneResource = database.resources[nodePath.sceneID];
    if (sceneResource?.type !== "scene") return;

    const node = Tree.getItemByPath(sceneResource.data.children, nodePath.path);

    // Generate view
    const path = Tree.getPath(sceneResource.data.children, node => node.data.id === nodeId)!;
    const p = path.map((_, i) => path.slice(0, i + 1));
    let view: Transform = Transform.DEFAULT;
    for (const path of p) {
      const pathNode = Tree.getNode(sceneResource.data.children, path);
      view = Transform.multiply(view, pathNode.data.transform);
    }

    if (editor !== undefined) {
      return editor.state.apply(prevValue => {
        const rotation = 0;
        const position: Vector2f = Vector2f.rotate(Vector2f.rotate(view.position, -view.rotation * Math.PI / 180), rotation * Math.PI / 180);

        return ValueFn.set(prevValue, {type: "scene", data: {
          sceneReference: {gameID: gameID, sceneID: nodePath.sceneID},
          toolMode: editor.state.value?.data.toolMode || ToolFn.DEFAULT,
          vision: editor.state.value?.data.vision || {senseID: undefined, override: false},
          selectedNodeIds: [{type: "scene-node", storeId: gameID, resourceId: nodePath.sceneID, nodeId}],
          view: {position, rotation, scale: view.scale}
        }});
      });
    } else if (playerController !== undefined) {
      if (!isNodeOwner(node, userIDRef.value!)) return;
      return playerController.state.apply((prevValue): TokenViewportPropertiesOperation[] => {
        const rotation = 0;
        const position: Vector2f = Vector2f.rotate(Vector2f.rotate(view.position, -view.rotation * Math.PI / 180), rotation * Math.PI / 180);
        return [
          {type: "update-active-controller", operations: ValueFn.set(prevValue.activeController, {
            view: {position, rotation, scale: view.scale},
            controllerNodeID: nodeId
          })},
          {type: "update-selected-controller-node-ids", operations: SetFn.set(prevValue.selectedControllerNodeIds, [{type: "scene-node", storeId: gameID, resourceId: nodePath.sceneID, nodeId}])}
        ];
      });
    }
  }, [editor, playerController, databaseRef, gameID]);
}
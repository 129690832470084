import {FaAlignCenter, FaAlignLeft, FaAlignRight} from "react-icons/fa6";
import {Button, ButtonBar} from "#lib/components/index.ts";
import {MutableRef} from "common/ref";
import {HorizontalTextAlign} from "common/legends/node/index.ts";
import {ConstantOperation, ValueFn, ValueOperation} from "common/types/generic/index.ts";
import {useRefValue} from "#lib/signal/index.ts";
import {Field, FieldLabel} from "#lib/components/panel-header.tsx";

export function InputHorizontalAlignment({value}: {value: MutableRef<HorizontalTextAlign, ValueOperation<HorizontalTextAlign, ConstantOperation>[]>}) {
  const currentValue = useRefValue(value);

  const onSetLeft = () => value.apply(prev => ValueFn.set(prev, "left"));
  const onSetCenter = () => value.apply(prev => ValueFn.set(prev, "center"));
  const onSetRight = () => value.apply(prev => ValueFn.set(prev, "right"));

  return (
    <Field>
      <FieldLabel>Horizontal Alignment</FieldLabel>
      <ButtonBar className="mx-2 rounded-md overflow-hidden">
        <Button className="flex-1" variant={currentValue === "left" ? "primary" : undefined} title="Align Left" onClick={onSetLeft}><FaAlignLeft /> Left</Button>
        <Button className="flex-1" variant={currentValue === "center" ? "primary" : undefined} title="Align Center" onClick={onSetCenter}><FaAlignCenter /> Center</Button>
        <Button className="flex-1" variant={currentValue === "right" ? "primary" : undefined} title="Align Right" onClick={onSetRight}><FaAlignRight /> Right</Button>
      </ButtonBar>
    </Field>
  );
}
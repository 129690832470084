import {Node} from "common/legends/index.ts";
import {WallToolView} from "./wall-tool-view.tsx";
import {AreaToolView} from "./area-tool-view.tsx";
import {MutableRef, Ref} from "common/ref";
import {Tool, ToolOperation} from "../../../../common/tool/tool.ts";

export function ToolView({value, nodes}: {
  value: MutableRef<Tool, ToolOperation[]>;
  nodes: Ref<Node[]>;
}) {
  return <>
    <WallToolView value={value} nodes={nodes} />
    <AreaToolView value={value} nodes={nodes} />
  </>
}

import {ButtonBar, InputGroup, InputGroupLabel} from "#lib/components/index.ts";
import React, {useMemo} from "react";
import {Dnd5eBackground, Dnd5eBackgroundOperation, Dnd5eBackgroundSignal, dnd5eBackgroundType} from "common/legends/index.ts";
import {InputString} from "#lib/components/input/input-string.tsx";
import {ImportButton} from "#lib/components/button/import-button.tsx";
import {ExportButton} from "#lib/components/button/export-button.tsx";
import {ValueFn, ValueOperation} from "common/types/index.ts";
import {InputDnd5eFeatures} from "../dnd-5e/dnd-5e-feature/input-dnd-5e-features.tsx";
import {exportFile} from "../../../../common/export-file.ts";
import {PanelHeader} from "#lib/components/panel-header.tsx";
import {InputGroupIcon} from "#lib/components/input/input-group-icon.tsx";
import {FaTag} from "react-icons/fa";
import {ExpandOptions} from "#lib/components/expand-options.tsx";
import {MutableRef} from "common/ref";

export type BackgroundProps = {
  value: MutableRef<Dnd5eBackground, ValueOperation<Dnd5eBackground, Dnd5eBackgroundOperation>[]>;
};

export function Background({value}: BackgroundProps) {
  const {name, features} = useMemo(() => Dnd5eBackgroundSignal(value), [value]);

  return (<div className="flex flex-col gap-1">
    <div className="flex flex-row gap-0.5">
      <PanelHeader className="flex-1">Background</PanelHeader>
      <ButtonBar>
        <ExpandOptions>
          <ImportButton type={dnd5eBackgroundType} onImport={(background: Dnd5eBackground) => {
            value.apply(prev => ValueFn.set(prev, background));
          }}> Import Background </ImportButton>
          <ExportButton onExport={async () => {
            exportFile(`BACKGROUND-${value.value.name}.lvtt`, new Blob([JSON.stringify(value.value, null, 2)]));
          }}> Export Background </ExportButton>
        </ExpandOptions>
      </ButtonBar>
    </div>
    <div className="flex flex-row">
      <InputGroup>
        <InputGroupIcon><FaTag /></InputGroupIcon>
        <InputGroupLabel>Name</InputGroupLabel>
      </InputGroup>
      <InputGroup className="flex-1">
        <InputString size="small" value={name}/>
      </InputGroup>
    </div>
    <InputDnd5eFeatures value={features} />
  </div>);
}
import {MutableRef} from "common/ref";
import {LightShapeSpotlight, LightShapeSpotlightOperation, LightShapeSpotlightRef} from "common/legends/node/light/light-shape-spotlight.ts";
import {InputGroup, InputNumber} from "#lib/components/index.ts";
import {useMemo} from "react";
import {ColorField} from "#lib/components/input/color-field.tsx";
import {Field, FieldLabel, SectionBody} from "#lib/components/panel-header.tsx";

export function LightShapeSpotlightFields({valueRef}: {
  valueRef: MutableRef<LightShapeSpotlight, LightShapeSpotlightOperation[]>
}) {
  const {colorRef, intensityRef, angleRef, falloffAngleRef, falloffRef, falloffStrengthRef, radiusRef} = useMemo(() => LightShapeSpotlightRef(valueRef), [valueRef]);

  return <SectionBody className="pt-2">
    <ColorField label="Color" value={colorRef}/>

    <div className="flex flex-row gap-0.5">
      <Field className="flex-1">
        <FieldLabel>Intensity</FieldLabel>
        <InputGroup className="ml-2 rounded-l-md overflow-hidden">
          <InputNumber value={intensityRef}/>
        </InputGroup>
      </Field>
      <Field className="flex-1">
        <FieldLabel>Falloff Strength</FieldLabel>
        <InputGroup className="mr-2 rounded-r-md overflow-hidden">
          <InputNumber value={falloffStrengthRef}/>
        </InputGroup>
      </Field>
    </div>


    <div className="flex flex-row gap-0.5">
      <Field className="flex-1">
        <FieldLabel>Radius</FieldLabel>
        <InputGroup className="ml-2 rounded-l-md overflow-hidden">
          <InputNumber value={radiusRef}/>
        </InputGroup>
      </Field>
      <Field className="flex-1">
        <FieldLabel>Falloff Radius</FieldLabel>
        <InputGroup className="mr-2 rounded-r-md overflow-hidden">
          <InputNumber value={falloffRef}/>
        </InputGroup>
      </Field>
    </div>


    <div className="flex flex-row gap-0.5">
      <Field className="flex-1">
        <FieldLabel>Angle</FieldLabel>
        <InputGroup className="ml-2 rounded-l-md overflow-hidden">
          <InputNumber value={angleRef}/>
        </InputGroup>
      </Field>
      <Field className="flex-1">
        <FieldLabel>Falloff Angle</FieldLabel>
        <InputGroup className="mr-2 rounded-r-md overflow-hidden">
          <InputNumber value={falloffAngleRef}/>
        </InputGroup>
      </Field>
    </div>
  </SectionBody>
}
import React, {useContext} from "react";
import {Node} from "common/legends/node/index.ts";
import {createValueRef, Ref} from "common/ref";

export const RootContext = React.createContext<Ref<Node[]>>(createValueRef([]));
export const RootProvider = RootContext.Provider;

export function useRootNodes(): Ref<Node[]> {
  return useContext(RootContext);
}

import {MapFn, Optional, Tree, TreeOperation, ValueFn} from "common/types/generic/index.ts";
import {QLabDatabase, QLabDatabaseOperation} from "common/qlab/q-lab-database.ts";
import {Sheet, SheetOperation} from "common/legends/asset/sheet/index.ts";
import {QLabResourceID, ResourceValue} from "common/qlab/resource/index.ts";
import {MutableRef, NOOP_SIGNAL} from "common/ref";
import {SheetReference} from "../sheet/sheet-reference.ts";
import {Loader} from "../loader.ts";

class SheetLoader extends Loader<SheetReference, MutableRef<Optional<Sheet>, SheetOperation[]>> {
  private resourcesCache = new WeakMap<ResourceValue, WeakMap<SheetReference, Optional<Sheet>>>();

  constructor(databaseRef: MutableRef<QLabDatabase, QLabDatabaseOperation[]>) {
    super(
      sheetReference => sheetReference.type === "link"
        ? `link:${sheetReference.assetID}:${sheetReference.sheetID}`
        : `copy:${sheetReference.nodeID}:${sheetReference.tokenID}`,
      sheetReference => databaseRef.map(
        database => {
          if (sheetReference?.type === "copy") {
            for (const value of Object.values(database.resources)) {
              if (!value) continue;
              let resourceCache = this.resourcesCache.has(value) ? this.resourcesCache.get(value)! : this.resourcesCache.set(value, new WeakMap()).get(value)!;
              if (resourceCache.has(sheetReference)) {
                return resourceCache.get(sheetReference)!;
              }

              if (value?.type !== "scene") continue;
              const node = Tree.getItemById(value.data.children, sheetReference.nodeID);
              if (node?.type !== "token") continue;
              const sheet = node.data.tokenSheets[sheetReference.tokenID];
              if (sheet?.type !== "copy") return undefined;
              resourceCache.set(sheetReference, sheet.data);
              return sheet.data;
            }
            return undefined;
          } else if (sheetReference?.type === "link") {
            const asset = database.resources[sheetReference.assetID];
            if (asset?.type !== "asset") return undefined;
            return asset.data.sheets[sheetReference.sheetID];
          } else {
            return undefined;
          }
        },
        (database: QLabDatabase, operations: SheetOperation[]): QLabDatabaseOperation[] => {
          if (operations.length === 0) return [];
          if (sheetReference?.type === "copy") {
            for (const [resourceID, resource] of Object.entries(database.resources)) {
              if (resource?.type !== "scene") continue;
              const nodePath = Tree.getPath(resource.data.children, node => node.data.id === sheetReference.nodeID);
              if (nodePath === undefined) continue;
              const node = Tree.getNode(resource.data.children, nodePath);
              if (node?.type !== "token") continue;
              const sheet = node?.data.tokenSheets[sheetReference.tokenID];
              if (sheet?.type !== "copy") continue;
              return [{
                type: "resource", resourceID: resourceID as QLabResourceID, operations: ValueFn.apply([{
                  type: "scene", operations: [{
                    type: "update-children", operations: TreeOperation.apply(nodePath, [{
                      type: "token", operations: [{
                        type: "update-token-sheets", operations: MapFn.apply(sheetReference.tokenID, operations)
                      }]
                    }])
                  }]
                }])
              }] satisfies QLabDatabaseOperation[];
            }
            return [];
          } else if (sheetReference?.type === "link") {
            const resource = database.resources[sheetReference.assetID];
            if (resource?.type !== "asset") return [];
            return [{
              type: "resource", resourceID: sheetReference.assetID, operations: ValueFn.apply([{
                type: "asset", operations: [{
                  type: "update-sheets", operations: MapFn.apply(sheetReference.sheetID, operations)
                }]
              }])
            }] satisfies QLabDatabaseOperation[];
          } else {
            return [];
          }
        }
      ).distinct()
    );
  }
}

const loader = new WeakMap<MutableRef<QLabDatabase, QLabDatabaseOperation[]>, Loader<SheetReference, MutableRef<Optional<Sheet>, SheetOperation[]>>>();
export function SheetRef(databaseRef: MutableRef<QLabDatabase, QLabDatabaseOperation[]>, sheetReference: Optional<SheetReference>): MutableRef<Optional<Sheet>, SheetOperation[]> {
  if (!sheetReference) return NOOP_SIGNAL;
  if (!loader.has(databaseRef)) loader.set(databaseRef, new SheetLoader(databaseRef));
  return loader.get(databaseRef)!.get(sheetReference);
}

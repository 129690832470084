import {useMemo} from "react";
import {listIdentity} from "common/observable";
import {Optional, VisitResult, walkTree} from "common/types/index.ts";
import {NodeId} from "common/legends/index.ts";
import {useDatabase} from "../../../routes/game/model/store-context.tsx";
import {QLabDatabase} from "common/qlab/index.ts";
import {MutableRef, Ref} from "common/ref";

export function useNodeTreeIDs(nodeRef: Ref<Optional<NodeId>>): Ref<NodeId[]> {
  const databaseRef = useDatabase();
  return useMemo((): Ref<NodeId[]> => {
    const valueFn = (database: QLabDatabase, nodeID: Optional<NodeId>) => {
      if (nodeID === undefined) return [];
      for (const [_resourceID, resource] of Object.entries(database.resources)) {
        if (resource?.type !== "scene") continue;
        let ancestors: NodeId[] = [];
        walkTree(resource.data.children, {
          preVisit(value) {
            ancestors.push(value.data.id);
          },
          visit(value) {
            if (value.data.id === nodeID) {
              return VisitResult.TERMINATE;
            }
          },
          postVisit(_value) {
            ancestors.splice(ancestors.length-1, 1);
          }
        });
        if (ancestors.length !== 0) return ancestors;
      }
      return [];
    }
    return MutableRef
      .all(databaseRef, nodeRef).map(([database, reference]) => valueFn(database, reference))
      .distinct(listIdentity);
  }, [databaseRef, nodeRef]);
}

import {z} from "zod";
import {Dnd5eModifierID} from "../../../dnd-5e-modifier/dnd-5e-modifier-i-d.ts";
import {DiceExpression} from "../../../../../../../dice/index.ts";
import {ConstantOperation, constantType, ObjectType, Type, ValueOperation, ValueType} from "../../../../../../../types/index.ts";

export const Dnd5eActionTemplateSegmentAttackRollCriticalRangeModifier: z.ZodType<{
  modifierID: string,
  expression: string
}> = z.object({
  modifierID: Dnd5eModifierID,
  expression: DiceExpression
});
export type Dnd5eActionTemplateSegmentAttackRollCriticalRangeModifier = z.infer<typeof Dnd5eActionTemplateSegmentAttackRollCriticalRangeModifier>;

export const Dnd5eActionTemplateSegmentAttackRollCriticalRangeModifierOperation = z.discriminatedUnion("type", [
  z.object({type: z.literal("update-modifier-i-d"), operations: z.array(ConstantOperation)}),
  z.object({type: z.literal("update-expression"), operations: z.array(ValueOperation(DiceExpression, ConstantOperation))})
]);
export type Dnd5eActionTemplateSegmentAttackRollCriticalRangeModifierOperation = z.infer<typeof Dnd5eActionTemplateSegmentAttackRollCriticalRangeModifierOperation>;

export const dnd5eActionTemplateSegmentAttackRollCriticalRangeType: Type<Dnd5eActionTemplateSegmentAttackRollCriticalRangeModifier, Dnd5eActionTemplateSegmentAttackRollCriticalRangeModifierOperation> = new ObjectType({
  modifierID: constantType,
  expression: new ValueType<DiceExpression, ConstantOperation>(constantType)
})
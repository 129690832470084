import {z} from "zod";
import {
  BooleanOperation,
  booleanType,
  ConstantOperation,
  constantType,
  ListPropertyRef,
  ListType,
  ObjectType,
  PropertyRef,
  StringOperation,
  stringType,
  Type,
  ZodListOperation
} from "../../../../../../types/index.ts";
import {MutableRef} from "#common/ref";
import {
  Dnd5eActionTemplateModifier,
  Dnd5eActionTemplateModifierFn,
  Dnd5eActionTemplateModifierOperation,
  dnd5eActionTemplateModifierType
} from "../modifier/dnd-5e-action-template-modifier.ts";
import {Dnd5eActionTemplateEffectID, generateDnd5eActionTemplateEffectID} from "./dnd-5e-action-template-effect-id.ts";

export const Dnd5eActionTemplateEffect = z.object({
  actionEffectID: Dnd5eActionTemplateEffectID,
  enabled: z.boolean(),
  name: z.string(),
  abbreviation: z.string(),
  modifiers: z.array(Dnd5eActionTemplateModifier)
});
export type Dnd5eActionTemplateEffect = z.infer<typeof Dnd5eActionTemplateEffect>;

export const Dnd5eActionTemplateEffectOperation = z.discriminatedUnion("type", [
  z.object({type: z.literal("update-action-effect-i-d"), operations: z.array(ConstantOperation)}),
  z.object({type: z.literal("update-enabled"), operations: z.array(BooleanOperation)}),
  z.object({type: z.literal("update-name"), operations: z.array(StringOperation)}),
  z.object({type: z.literal("update-abbreviation"), operations: z.array(StringOperation)}),
  z.object({type: z.literal("update-modifiers"), operations: z.array(ZodListOperation(Dnd5eActionTemplateModifier, Dnd5eActionTemplateModifierOperation))})
]);
export type Dnd5eActionTemplateEffectOperation = z.infer<typeof Dnd5eActionTemplateEffectOperation>;

export const dnd5eActionTemplateEffectType: Type<Dnd5eActionTemplateEffect, Dnd5eActionTemplateEffectOperation> = new ObjectType({
  actionEffectID: constantType,
  enabled: booleanType,
  name: stringType,
  abbreviation: stringType,
  modifiers: new ListType(dnd5eActionTemplateModifierType)
})

export const Dnd5eActionTemplateEffectFn = {
  getActionTemplateEffectID(value: Dnd5eActionTemplateEffect) {
    return value.actionEffectID;
  },
  copyActionEffect(value: Dnd5eActionTemplateEffect): Dnd5eActionTemplateEffect {
    return ({
      ...value,
      actionEffectID: generateDnd5eActionTemplateEffectID(),
      modifiers: value.modifiers.map(Dnd5eActionTemplateModifierFn.copyDnd5eActionTemplateModifier)
    });
  },
  getAbbreviation(effect: Dnd5eActionTemplateEffect): string {
    return (effect.abbreviation === "") ? effect.name : effect.abbreviation;
  },
  getGroup(effect: Dnd5eActionTemplateEffect): string | undefined {
    const abbreviation = Dnd5eActionTemplateEffectFn.getAbbreviation(effect);
    const i = abbreviation.indexOf(":");
    return (i !== -1)
      ? abbreviation.substring(0, i)
      : undefined;
  }
};

export function Dnd5eActionTemplateEffectSignal(valueRef: MutableRef<Dnd5eActionTemplateEffect, Dnd5eActionTemplateEffectOperation[]>) {
  return {
    enabledRef: PropertyRef<Dnd5eActionTemplateEffect, Dnd5eActionTemplateEffectOperation, boolean, BooleanOperation>(
      value => value.enabled,
      operations => [{type: "update-enabled", operations}]
    )(valueRef),
    abbreviationRef: PropertyRef<Dnd5eActionTemplateEffect, Dnd5eActionTemplateEffectOperation, string, StringOperation>(
      value => value.abbreviation,
      operations => [{type: "update-abbreviation", operations}]
    )(valueRef),
    nameRef: PropertyRef<Dnd5eActionTemplateEffect, Dnd5eActionTemplateEffectOperation, string, StringOperation>(
      value => value.name,
      operations => [{type: "update-name", operations}]
    )(valueRef),
    modifiersRef: ListPropertyRef<Dnd5eActionTemplateEffect, Dnd5eActionTemplateEffectOperation, Dnd5eActionTemplateModifier, Dnd5eActionTemplateModifierOperation>(
      value => value.modifiers,
      operations => [{type: "update-modifiers", operations}]
    )(valueRef)
  };
}
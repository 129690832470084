import React, {ForwardedRef, forwardRef} from 'react';
import {Button, ButtonProps} from "./button.tsx";
import {twMerge} from "tailwind-merge";

export const IconButton = forwardRef(function IconButton({className, size = "medium", ...props}: ButtonProps, ref: ForwardedRef<HTMLButtonElement>): JSX.Element {
  return (<Button ref={ref} type="button" className={twMerge(
    "px-0 shrink-0",
    size === "large" && "w-14 h-14 basis-14",
    size === "medium" && "w-12 h-12 basis-12",
    size === "small" && "w-10 h-10 basis-10",
    className
  )} size={size} {...props} />);
});

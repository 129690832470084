import {Optional, Tree} from "common/types/index.ts";
import {NodeId} from "common/legends/index.ts";
import {pipe} from "common/pipe";
import {combine, distinct, from, map, Observable, switchAll} from "common/observable";
import {useMemo} from "react";
import {SheetReference} from "../../../common/sheet/sheet-reference.ts";
import {useObservableSignal} from "#lib/signal/react/use-entity-signal.ts";
import {Ref} from "common/ref";
import {QLabDatabase} from "common/qlab/q-lab-database.ts";
import {useDatabase} from "../../../../routes/game/model/store-context.tsx";
import {EditorState} from "../../nav/editor/state";
import {selectionRefObservable} from "../../nav/editor/state/use-selection-ref-observable.ts";
import {SelectionRef} from "../../nav/editor/state/selection-ref.ts";
import {useEditor} from "../../nav/editor/editor-context.ts";

export function editorNodeIDObservable(state: Observable<EditorState>): Observable<NodeId | undefined> {
  return pipe(
    selectionRefObservable(state),
    map((ref): Observable<NodeId | undefined> => {
      if (ref?.type === "scene-node") {
        return from(ref?.nodeId);
      } else {
        return from(undefined);
      }
    }),
    switchAll()
  );
}

function getSelectionSheetReference(database: QLabDatabase, ref: SelectionRef): Optional<SheetReference> {
  if (ref?.type === "scene-node") {
    const resource = database.resources[ref.resourceId];
    if (resource?.type !== "scene") return undefined;
    const node = Tree.getItemById(resource.data.children, ref.nodeId);
    if (node === undefined) return undefined;
      if (node?.type !== "token") return undefined;
      const tokenSheet = node.data.tokenSheets[node.data.tokenReference.tokenID];
      if (tokenSheet === undefined) return undefined;
      if (tokenSheet.type === "link") return ({type: "link", assetID: node.data.tokenReference.assetID, sheetID: tokenSheet.data});
      return ({
        type: "copy",
        nodeID: ref.nodeId,
        tokenID: node.data.tokenReference.tokenID
      });
  } else if (ref?.type === "asset-token" || ref?.type === "asset-token-node") {
    const resource = database.resources[ref.resourceId];
    if (resource?.type !== "asset") return undefined;
    const token = resource.data.tokens.find(token => token.tokenID === ref.tokenId);
    if (token?.sheetId === undefined) return undefined;
    return {type: "link", assetID: ref.resourceId, sheetID: token.sheetId};
  } else {
    return undefined;
  }
}

export function editorSheetIdObservable(
  databaseRef: Ref<QLabDatabase>,
  state: Observable<EditorState>
): Observable<SheetReference | undefined> {
  return pipe(
    combine(databaseRef.observe, selectionRefObservable(state)),
    map(([database, selectionRef]) => getSelectionSheetReference(database, selectionRef)),
    distinct()
  );
}

export function useEditorSheetReference(pinnedReference: Ref<Optional<SheetReference>>): Ref<Optional<SheetReference>> {
  const editor = useEditor();
  const databaseRef = useDatabase();
  return useObservableSignal(useMemo(() => pipe(
    combine(pinnedReference.observe, editorSheetIdObservable(databaseRef, editor.state.observe)),
    map(([a, b]) => a === undefined ? b : a)
  ), [editor, databaseRef, pinnedReference])) || pinnedReference;
}

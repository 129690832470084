import {Modal, ModalBody, ModalTitle} from "#lib/components/modal/index.ts";
import {Button, ButtonBar, InputGroup, InputGroupLabel, InputNumber, Spacer} from "#lib/components/index.ts";
import {FaPaperPlane, FaTag, FaTrash} from "react-icons/fa";
import {ExportButton} from "#lib/components/button/export-button.tsx";
import {toPromise} from "common/observable";
import {exportFile} from "../../../../../common/export-file.ts";
import {InputGroupIcon} from "#lib/components/input/input-group-icon.tsx";
import {InputString} from "#lib/components/input/input-string.tsx";
import {RichTextFn} from "common/types/index.ts";
import {SectionHeader} from "#lib/components/panel-header.tsx";
import {InputRichText} from "#lib/components/input/input-rich-text.tsx";
import React, {useMemo, useRef} from "react";
import {useSelectedNodeID} from "../../dnd-5e-character/use-selected-sheet.ts";
import {useSendFeatureMessage} from "../../dnd-5e-character/dnd-5e-action/use-send-feature-message.ts";
import {ExpandOptions} from "#lib/components/expand-options.tsx";
import {Dnd5eInventoryItem, Dnd5eInventoryItemOperation, Dnd5eInventoryItemSignals} from "common/legends/asset/sheet/dnd-5e/character/dnd-5e-item.ts";
import {Dnd5eModifiersView} from "../dnd-5e-modifier/dnd-5e-modifiers-view.tsx";
import {useComputedValue} from "#lib/signal/index.ts";
import {Dnd5eResourcesList} from "../dnd-5e-resource/dnd-5e-resources-list.tsx";
import {Dnd5eItemEffectsView} from "./dnd-5e-item-effects-view.tsx";
import {MutableRef} from "common/ref";
import {Dnd5eActionTemplatesView} from "../dnd-5e-action/dnd-5e-action-templates-view.tsx";

export type ItemEditorProps = {
  value: MutableRef<Dnd5eInventoryItem, Dnd5eInventoryItemOperation[]>,
  onClose: () => void;
  remove?: () => void;
};

export function Dnd5eItemEditor({value, remove, onClose}: ItemEditorProps) {
  const {item, description, resources, modifiers, weight, actionsRef, effects} = useMemo(() => Dnd5eInventoryItemSignals(value), [value]);
  const isDescriptionEmpty = useComputedValue(description, description => !description || RichTextFn.isEmpty(description));

  const selectedNodeId = useSelectedNodeID();
  const sendMessage = useSendFeatureMessage();
  const onSend = async () => {
    const nodeID = await toPromise(selectedNodeId);
    const f = await toPromise(value.observe);
    await sendMessage(nodeID!, f.item, [], f.description);
  };

  const initialFocusRef = useRef<HTMLInputElement>(null);
  return (<Modal onClose={onClose} initialFocus={initialFocusRef}>
    <ModalTitle>
      <span>Item</span>
      <Spacer />
      <ButtonBar>
        <Button variant="primary" disabled={isDescriptionEmpty} title="Send to Chat" onClick={onSend}><FaPaperPlane/> Send to Chat</Button>
        <ExpandOptions>
          <ExportButton onExport={async () => {
            const output = await toPromise(value.observe);
            exportFile(`ITEM-${output.item}.lvtt`, new Blob([JSON.stringify(output, null, 2)]));
          }}> Export Item </ExportButton>
          {remove && <Button variant="destructive" onClick={remove}><FaTrash/> Delete Item</Button>}
        </ExpandOptions>
      </ButtonBar>
    </ModalTitle>
    <ModalBody className="pb-8">
      <div className="flex flex-row gap-0.5">
        <InputGroup className="flex-1">
          <InputGroupIcon><FaTag/></InputGroupIcon>
          <InputGroupLabel>Name</InputGroupLabel>
          <InputString ref={initialFocusRef} value={item} placeholder="Item Name"/>
        </InputGroup>
      </div>

      <InputGroup>
        <InputGroupLabel>Weight</InputGroupLabel>
        <InputNumber type="number" className="px-0" value={weight} min={0} />
        <InputGroupLabel className="px-0 text-white/30">lb.</InputGroupLabel>
        <InputGroupLabel className="flex-1" />
      </InputGroup>

      <div className="flex flex-col gap-0.5">
        <SectionHeader className="bg-zinc-800/50">Description</SectionHeader>
        <InputRichText placeholder="Description" value={description}/>
      </div>

      <Dnd5eActionTemplatesView valueRef={actionsRef}/>
      <Dnd5eModifiersView value={modifiers} />
      <Dnd5eResourcesList value={resources} />
      <Dnd5eItemEffectsView value={effects} />
    </ModalBody>
  </Modal>);
}
import {Grid} from "common/legends/scene/index.ts";
import {ClearCanvas} from "../../../routes/game/view/main/clear-canvas.tsx";
import {Color} from "common/types/generic/index.ts";
import {RootProvider} from "../common/context/root-context.ts";
import {ProjectionProvider, usePVM, ViewProvider} from "../common/context/pvm-context.ts";
import {AccessibilityProvider, VisibilityProvider} from "../common/context/visibility-context.ts";
import {ElementRenderPass} from "../common/render-pass/element";
import {MeasurementView} from "../common/hud-pass/measurement/measurement-view.tsx";
import {ToolView} from "../common/hud-pass/tool/tool-view.tsx";
import {WallShader} from "../common/shader/wall-shader.tsx";
import {GridProvider} from "../common/context/grid-context.ts";
import React, {useCallback, useMemo} from "react";
import {useComputedValue, useRefValue} from "#lib/signal/index.ts";
import {getNodesOrigin} from "../scene/scene-view.tsx";
import {useActiveController} from "../token/use-active-controller-node-reference.ts";
import {useNode} from "../token/use-node.ts";
import {Node} from "common/legends/node/index.ts";
import {MutableRef} from "common/ref";
import {Token, TokenOperation, TokenSignals} from "common/legends/asset/index.ts";
import {useResolution} from "#lib/gl-react/hooks/resolution-context.ts";
import {Measurement, MeasurementOperation} from "common/legends/measurement/index.ts";
import {useSheetSignal} from "../../common/sheet/use-sheet-signal.ts";
import {useSheetReference} from "../../common/sheet/sheet-reference-context.ts";
import {useRenderingContext, useTexture2D} from "#lib/gl-react/index.ts";
import {useFramebuffer} from "#lib/gl-react/hooks/use-framebuffer.ts";
import {ElementLightPass} from "../common/light-pass/element/element-light-pass.tsx";
import {RenderPassTextureProvider} from "../common/context/render-pass-texture-context.ts";
import {LightElementLightPass} from "../common/light-pass/element/light/light-element-light-pass.tsx";
import {defaultLocalNode} from "common/legends/node/local-node.ts";
import {LightNode} from "common/legends/node/light/light-node.ts";
import {HealthIndicatorView} from "../common/hud-pass/element/health-indicator-view.tsx";
import {SelectionIndicator} from "../common/hud-pass/element/selection-indicator.tsx";
import {useEditorTool} from "../../panel/nav/common/tool/tool-selector/use-editor-tool.ts";
import {ElementHUDPass} from "../common/hud-pass/element/element-h-u-d-pass.tsx";
import {BackgroundShader} from "../common/shader/background-shader.tsx";
import {Vision, VisionFn} from "common/legends/asset/token/vision/vision.ts";
import {CircleShader} from "../common/shader/circle-shader.tsx";
import {Vector2} from "common/math/vector/vector2.ts";

export function AssetView({token, vision, measurement}: {
  token: MutableRef<Token, TokenOperation[]>;
  vision: Vision[];
  measurement: MutableRef<Measurement, MeasurementOperation[]>;
}) {
  const {projection, view, model} = usePVM();

  const tokenOrigin = useComputedValue(token, token => token.origin);
  const tokenPivot = useComputedValue(token, token => token.pivot);
  const tokenSize = useComputedValue(token, token => token.size);
  const tokenLight = useComputedValue(token, token => token.light);
  const interfaceScale = useComputedValue(token, token => token.interfaceScale);
  const size = useResolution();

  const {children} = useMemo(() => TokenSignals(token), [token]);
  const childrenValue = useRefValue(children);
  const activeController = useActiveController();
  const activeNodeID = useComputedValue(activeController, activeController => activeController?.controllerNodeID);
  const isActiveControllerToken = useComputedValue(useNode(activeNodeID), node => node?.type === "token" && node.data.ownerIDs.length > 0);

  const origin = (activeNodeID && isActiveControllerToken) ? getNodesOrigin(activeNodeID, childrenValue) : undefined;

  const isElementVisible = useCallback((element: Node) => vision.some(vision => VisionFn.canSee(vision, element)), [vision]);
  const isElementAccessible = useCallback((_: Node) => false, []);
  const tool = useEditorTool();
  const sheet = useSheetSignal(useSheetReference());
  const context = useRenderingContext();

  const diffuseTexture = useTexture2D();
  useMemo(() => {
    if (size[0] > 0 && size[1] > 0) {
      const prev = context.getParameter(WebGL2RenderingContext.TEXTURE_BINDING_2D);
      context.bindTexture(WebGL2RenderingContext.TEXTURE_2D, diffuseTexture);
      context.texImage2D(WebGL2RenderingContext.TEXTURE_2D, 0, WebGL2RenderingContext.RGBA, size[0], size[1], 0, WebGL2RenderingContext.RGBA, WebGL2RenderingContext.UNSIGNED_BYTE, null);
      context.texParameteri(WebGL2RenderingContext.TEXTURE_2D, WebGL2RenderingContext.TEXTURE_MIN_FILTER, WebGL2RenderingContext.LINEAR);
      context.texParameteri(WebGL2RenderingContext.TEXTURE_2D, WebGL2RenderingContext.TEXTURE_MAG_FILTER, WebGL2RenderingContext.LINEAR);
      context.bindTexture(WebGL2RenderingContext.TEXTURE_2D, prev);
    }
  }, [diffuseTexture, size]);

  const normalTexture = useTexture2D();
  useMemo(() => {
    if (size[0] > 0 && size[1] > 0) {
      const prev = context.getParameter(WebGL2RenderingContext.TEXTURE_BINDING_2D);
      context.bindTexture(WebGL2RenderingContext.TEXTURE_2D, normalTexture);
      context.texImage2D(WebGL2RenderingContext.TEXTURE_2D, 0, WebGL2RenderingContext.RGBA, size[0], size[1], 0, WebGL2RenderingContext.RGBA, WebGL2RenderingContext.UNSIGNED_BYTE, null);
      context.texParameteri(WebGL2RenderingContext.TEXTURE_2D, WebGL2RenderingContext.TEXTURE_MIN_FILTER, WebGL2RenderingContext.LINEAR);
      context.texParameteri(WebGL2RenderingContext.TEXTURE_2D, WebGL2RenderingContext.TEXTURE_MAG_FILTER, WebGL2RenderingContext.LINEAR);
      context.bindTexture(WebGL2RenderingContext.TEXTURE_2D, prev);
    }
  }, [normalTexture, size]);

  const framebuffer = useFramebuffer();
  useMemo(() => {
    if (size[0] > 0 && size[1] > 0) {
      const prev = context.getParameter(WebGL2RenderingContext.FRAMEBUFFER_BINDING);
      context.bindFramebuffer(WebGL2RenderingContext.FRAMEBUFFER, framebuffer);
      context.framebufferTexture2D(WebGL2RenderingContext.FRAMEBUFFER, WebGL2RenderingContext.COLOR_ATTACHMENT0, WebGL2RenderingContext.TEXTURE_2D, diffuseTexture, 0);
      context.framebufferTexture2D(WebGL2RenderingContext.FRAMEBUFFER, WebGL2RenderingContext.COLOR_ATTACHMENT1, WebGL2RenderingContext.TEXTURE_2D, normalTexture, 0);
      context.drawBuffers([context.COLOR_ATTACHMENT0, context.COLOR_ATTACHMENT1]);
      context.bindFramebuffer(WebGL2RenderingContext.FRAMEBUFFER, prev);
    }
  }, [framebuffer, diffuseTexture, size]);

  const assetLight = useMemo((): LightNode => ({
    ...defaultLocalNode(),
    accessMask: undefined,
    shape: {
      type: "global",
      data: tokenLight
    },
    blinkLength: 1,
    blinkOffset: 0,
    blinkInterval: 1
  }), [tokenLight]);

  const resetBlend = useCallback((context: WebGL2RenderingContext) => {
    context.blendEquation(WebGL2RenderingContext.FUNC_ADD);
    context.blendFunc(WebGL2RenderingContext.SRC_ALPHA, WebGL2RenderingContext.ONE_MINUS_SRC_ALPHA);
  }, []);
  const interfaceModel = {...model, scale: model.scale * interfaceScale};

  if (size[0] === 0 || size[1] === 0) return <></>
  return <>
  <RootProvider value={children}>
    <ProjectionProvider value={projection}>
      <ViewProvider value={view}>
        <VisibilityProvider value={isElementVisible}>
          <AccessibilityProvider value={isElementAccessible}>
            <GridProvider value={Grid.DEFAULT}>
              <action onAction={resetBlend}/>
              <framebuffer value={framebuffer}>
                <ClearCanvas width={size[0]} height={size[1]}/>
                <BackgroundShader color={Color.GRAY}/>
                {[...childrenValue].reverse().map((element) => <ElementRenderPass key={element.data.id} value={element}/>)}
              </framebuffer>
              <RenderPassTextureProvider value={[diffuseTexture, normalTexture]}>
                <LightElementLightPass value={assetLight}/>
                <binder>
                  {[...childrenValue].reverse().map((element) => <ElementLightPass key={element.data.id} value={element}/>)}
                </binder>
              </RenderPassTextureProvider>
              <binder>
                {[...childrenValue].reverse().map((element) => <ElementHUDPass key={element.data.id} value={element}/>)}

                <HealthIndicatorView projection={projection} view={view} model={interfaceModel} sheetRef={sheet} origin={Vector2.multiply(tokenOrigin, 1 / interfaceScale)} size={Vector2.multiply(tokenSize, 1 / interfaceScale)} displayNumber={true}/>

                <SelectionIndicator projection={projection} view={view} model={interfaceModel} origin={[3, 3]} size={[6, 6]} color={Color.WHITE} repeatX={1} repeatY={1}/>
                <SelectionIndicator projection={projection} view={view} model={interfaceModel} origin={Vector2.multiply(tokenOrigin, 1 / interfaceScale)} size={Vector2.multiply(tokenSize, 1 / interfaceScale)} color={Color.WHITE} repeatX={1} repeatY={1}/>
                  <CircleShader
                      origin={Vector2.add(
                        [tokenOrigin[0] - tokenPivot[0], tokenOrigin[1] - tokenPivot[1]],
                        Vector2.multiply([2, 2], interfaceScale)
                      )}
                      size={Vector2.multiply([4, 4], interfaceScale)}
                      color={Color.BLACK75} />
                  <CircleShader
                    origin={Vector2.add(
                      [tokenOrigin[0] - tokenPivot[0], tokenOrigin[1] - tokenPivot[1]],
                      Vector2.multiply([1, 1], interfaceScale)
                    )}
                    size={Vector2.multiply([2, 2], interfaceScale)}
                      color={Color.GREEN} />
                <MeasurementView measurementRef={measurement}/>
                <ToolView value={tool} nodes={children}/>
              </binder>
              {origin && <WallShader origin={origin}/>}
            </GridProvider>
          </AccessibilityProvider>
        </VisibilityProvider>
      </ViewProvider>
    </ProjectionProvider>
  </RootProvider>
  </>
}
import {useCallback} from "react";
import {AssetID, SceneID} from "common/legends/index.ts";
import {Tree, walkTree} from "common/types/index.ts";
import {LegendsFile} from "./legends-file.ts";
import {useDatabase} from "../../../routes/game/model/store-context.tsx";

export function useExtractCharacter() {
  const database = useDatabase();
  const exportAsset = useCallback(async (assetID: AssetID): Promise<LegendsFile> => {
    const db = database.value;
    if (db.store?.type !== "game") return {scenes: {}, assets: {}};
    const asset = db.resources[assetID];
    if (asset?.type !== "asset") return {scenes: {}, assets: {}};

    const assets: {[assetID in AssetID]: Promise<LegendsFile>} = {};
    for (const [_, token] of Object.entries(asset.data.tokens)) {
      walkTree(token.children, {
        visit(value) {
          if (value.type === "token") {
            assets[value.data.tokenReference.assetID] = exportAsset(value.data.tokenReference.assetID);
          }
        }
      });
    }

    let assetFile = Tree.getItemById(db.store.data.assets, assetID)!;
    let file: LegendsFile = {
      "scenes": {},
      "assets": {
        [assetID]: {name: assetFile.data.name, tags: assetFile.data.tags, data: asset.data}
      }
    };
    for await (const asset of Object.values(assets)) {
      file = {
        "scenes": {...file.scenes, ...asset.scenes},
        "assets": {...file.assets, ...asset.assets}
      };
    }
    return file;
  }, [database]);
  return exportAsset;
}

export function useExtractScene() {
  const database = useDatabase();
  const exportCharacter = useExtractCharacter();
  return useCallback(async (sceneID: SceneID) => {
    const db = database.value;
    if (db.store?.type !== "game") return {assets: {}, scenes: {}};

    const scene = db.resources[sceneID];
    if (scene?.type !== "scene") return {assets: {}, scenes: {}};
    let characters: {[assetID in AssetID]: Promise<LegendsFile>} = {};
    walkTree(scene.data.children, {
      visit(value) {
        if (value.type === "token") {
          characters[value.data.tokenReference.assetID] = exportCharacter(value.data.tokenReference.assetID);
        }
      }
    });

    const sceneFile = Tree.getItemById(db.store.data.scenes, sceneID)!;

    let file: LegendsFile = {
      "scenes": {
        [sceneID]: {name: sceneFile.data.name, tags: sceneFile.data.tags, data: scene.data}
      },
      "assets": {}
    };
    for await (const character of Object.values(characters)) {
      file = {
        "scenes": {...file.scenes, ...character.scenes},
        "assets": {...file.assets, ...character.assets}
      };
    }
    return file;
  }, [database]);
}

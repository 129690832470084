import {useArrayBuffer, useAttribLocation, useBindVertexArribArray, useElementArrayBuffer, useProgram, useShader, useUniformLocation, useVertexBuffer} from "#lib/gl-react/index.ts";
import React, {useMemo} from "react";
import {Color, HSLA} from "common/types/index.ts";


const vertexShader = `#version 300 es
precision highp float;

in vec2 a_position;
in vec2 a_tex_coord;

out vec2 fragCoord;
out vec2 v_tex_coord;

void main()
{
  gl_Position = vec4(a_position, 0, 1);
  v_tex_coord = a_tex_coord;
}
`;

const fragmentShader = `#version 300 es
precision highp float;
precision highp sampler2DArray;

in vec2 v_tex_coord;
uniform sampler2D u_texture;
uniform vec4 u_color;

layout(location = 0) out vec4 outColor;
layout(location = 1) out vec4 normal;

void main() {
  vec4 tex_color = texture(u_texture, v_tex_coord);

  outColor = tex_color * u_color;
  normal = vec4(0.5, 0.5, 1.0, 0.0);
}
`;

export function ColorMaskShader({texture, color}: {
  texture: WebGLTexture;
  color: HSLA;
}) {
  const program = useProgram(
    useShader(WebGL2RenderingContext.VERTEX_SHADER, vertexShader),
    useShader(WebGL2RenderingContext.FRAGMENT_SHADER, fragmentShader)
  );
  const colorLocation = useUniformLocation(program, "u_color");
  const vbo = useArrayBuffer(useMemo(() => {
    return new Float32Array([
      -1, -1, 0, 0,
       1, -1, 1, 0,
       1,  1, 1, 1,
      -1,  1, 0, 1
    ]);
  }, []));
  const vao = useVertexBuffer();
  useBindVertexArribArray(vao, useAttribLocation(program, "a_position"), vbo, 2, WebGL2RenderingContext.FLOAT, false, 4 * 4, 0);
  useBindVertexArribArray(vao, useAttribLocation(program, "a_tex_coord"), vbo, 2, WebGL2RenderingContext.FLOAT, false, 4 * 4, 2 * 4);

  const ebo = useElementArrayBuffer(useMemo(() => new Uint16Array([0, 1, 2, 2, 3, 0]), []));
  const color4f = useMemo(() => new Float32Array(Color.toRGBA(color)), [color])

  return (<program value={program}>
    <uniform4fv location={colorLocation} data={color4f}/>
    <texture2d value={texture}>
      <vertexArray value={vao}>
        <elementArrayBuffer value={ebo}>
          <drawElements mode={WebGL2RenderingContext.TRIANGLES} type={WebGL2RenderingContext.UNSIGNED_SHORT} offset={0} count={6}/>
        </elementArrayBuffer>
      </vertexArray>
    </texture2d>
  </program>);
}

import {Optional} from "common/types/generic/index.ts";
import {Dnd5eActionCondition} from "common/legends/asset/sheet/dnd-5e/dnd-5e-action-definition/condition/dnd-5e-action-condition.ts";
import {Dnd5eFeature, Sheet} from "common/legends/asset/index.ts";
import {Dnd5eActionTemplate} from "common/legends/asset/sheet/dnd-5e/dnd-5e-action-definition/template/dnd-5e-action-template.ts";

export function conditionEvaluator(
  sheet: Optional<Sheet>,
  globalFeatures: Dnd5eFeature[],
  action: Optional<Dnd5eActionTemplate>
) {
  return (condition: Optional<Dnd5eActionCondition>): boolean => {
    if (condition?.type === "effect" && condition.data.effectID !== undefined) {
      if (action?.type === "custom") {
        const effect = action.data.effects.find(effect => effect.actionEffectID === condition.data.effectID);
        return effect !== undefined && effect.enabled;
      }
    }

    return true;
  };
}

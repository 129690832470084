import {
  ConstantOperation,
  constantType,
  ObjectType,
  PropertyRef,
  SetOperation,
  SetPropertySignal,
  SetType,
  Transform,
  TransformOperation,
  transformType,
  Type,
  ValueOperation,
  ValuePropertyRef,
  ValueType
} from "common/types/index.ts";
import {TokenReference} from "common/legends/index.ts";
import {MutableRef} from "common/ref";
import {Tool, ToolOperation, toolType} from "../../common/tool/tool.ts";
import {NodeSelectionRef, SelectionRef} from "../../panel/nav/editor/state/selection-ref.ts";
import {EditorVisionOperation, EditorVision, editorVisionType} from "../scene/editor-vision.ts";

export type AssetViewportProperties = {
  tokenReference: TokenReference;
  toolMode: Tool;
  vision: EditorVision;
  selectedNodeIds: NodeSelectionRef[];
  view: Transform;
};

export type AssetViewportPropertiesOperation =
  | {type: "update-token-reference", operations: ValueOperation<TokenReference, ConstantOperation>[]}
  | {type: "update-tool-mode", operations: ToolOperation[]}
  | {type: "update-vision", operations: EditorVisionOperation[]}
  | {type: "update-selected-node-ids", operations: SetOperation<NodeSelectionRef>[]}
  | {type: "update-view", operations: TransformOperation[]}
  ;

export const assetViewportPropertiesType: Type<AssetViewportProperties, AssetViewportPropertiesOperation> = new ObjectType({
  tokenReference: new ValueType(constantType),
  toolMode: toolType,
  vision: editorVisionType,
  selectedNodeIds: new SetType<NodeSelectionRef>(SelectionRef.equals),
  view: transformType
}, v => {
  if (v["viewConfiguration"]) delete v["viewConfiguration"];
  if (v["visibilityMask"]) {
    v["vision"] = {senseID: undefined, override: false} satisfies EditorVision;
    delete v["visibilityMask"];
  }
  return v;
});

export function AssetViewportPropertiesSignals(value: MutableRef<AssetViewportProperties, AssetViewportPropertiesOperation[]>) {
  return {
    tokenReference: ValuePropertyRef<AssetViewportProperties, AssetViewportPropertiesOperation, TokenReference, ConstantOperation>(
      value => value.tokenReference,
      operations => [{type: "update-token-reference", operations}]
    )(value),
    toolMode: PropertyRef<AssetViewportProperties, AssetViewportPropertiesOperation, Tool, ToolOperation>(
      value => value.toolMode,
      operations => [{type: "update-tool-mode", operations}]
    )(value),
    view: PropertyRef<AssetViewportProperties, AssetViewportPropertiesOperation, Transform, TransformOperation>(
      value => value.view,
      operations => [{type: "update-view", operations}]
    )(value),
    selectedNodeIds: SetPropertySignal<AssetViewportProperties, AssetViewportPropertiesOperation, NodeSelectionRef>(
      value => value.selectedNodeIds,
      operations => [{type: "update-selected-node-ids", operations}]
    )(value),
    visionRef: value.map<EditorVision, EditorVisionOperation[]>(
      value => value.vision,
      (_, operations) => [{type: "update-vision", operations}]
    )
  };
}
import React, {useMemo} from "react";
import {GameSignals, NodeId, UserID} from "common/legends/index.ts";
import {MapSignals} from "common/types/index.ts";
import {useToggle} from "#lib/components/index.ts";
import {useUserID} from "#lib/auth/use-get-user-id.ts";
import {useGoToNode} from "../use-go-to-node.ts";
import {useComputedValue, useRefValue} from "#lib/signal/index.ts";
import {useCharactersNodeSceneIDs} from "../../../../viewport/token/use-controller-node-ids.ts";
import {PlayerIcon} from "../../common/player-icon.tsx";
import {CharacterSelectionPanel} from "../../common/character-selection-panel.tsx";
import {useGame} from "../../../../../routes/game";
import {PlayerTokenSelector} from "../../common/player-token-selector.tsx";

type EditorPlayersViewProps = {
  userID: UserID;
};

function EditorGamePlayerView({userID}: EditorPlayersViewProps) {
  const nodeScenesRef = useCharactersNodeSceneIDs(userID);
  const controllerNodeIds = useComputedValue(nodeScenesRef, nodeScenes => Object.keys(nodeScenes) as NodeId[]);
  const goToNode = useGoToNode();

  const [expanded, toggleExpanded] = useToggle(false);
  return <div className="inline-flex gap-1 items-center">
    <PlayerIcon userID={userID} onClick={toggleExpanded} />
    {expanded && controllerNodeIds.length > 0 && <CharacterSelectionPanel userID={userID} values={controllerNodeIds} selected={undefined} onChange={goToNode} />}
  </div>
}

export function EditorGamePlayersView() {
  // Get all players
  const game = useGame();
  const {players} = useMemo(() => GameSignals(game), [game]);
  const playerEntities = useRefValue(useMemo(() => MapSignals.expand(players), [players]));
  const userID = useUserID()!;

  return <>
    {Object.keys(playerEntities).filter(playerID => playerID !== userID).map((playerID) => <EditorGamePlayerView key={playerID} userID={playerID as UserID} />)}
    <EditorGamePlayerView userID={userID} />
    <PlayerTokenSelector />
  </>
}
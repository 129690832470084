import {SetFn} from "common/types/index.ts";
import {ButtonBar, ExpandableLabel, IconButton, InputGroup, InputGroupLabel, useToggle} from "#lib/components/index.ts";
import React, {Ref, useCallback} from "react";
import {useObservable} from "#lib/qlab/index.ts";
import {Dnd5eDamageType, DND_5E_OFFENSIVE_DAMAGE_TYPES} from "common/legends/asset/sheet/dnd-5e/dnd-5e-damage-type.ts";
import {Fieldset} from "#lib/components/fieldset/fieldset.tsx";
import {twMerge} from "tailwind-merge";
import {Dnd5eDamageResistanceModifier, Dnd5eDamageResistanceModifierOperation} from "common/legends/asset/sheet/dnd-5e/dnd-5e-modifier/dnd-5e-damage-resistance-modifier.ts";
import {pipe} from "common/pipe";
import {distinct, map} from "common/observable";
import {FaTrash} from "react-icons/fa";
import {DAMAGE_TYPE_IMAGES} from "../../../../../common/systems/dnd5e/damage-types/index.ts";
import {DragIndicator} from "#lib/components/tree-view/drag-indicator.tsx";
import {Dnd5eDamageTypeView} from "./dnd-5e-damage-type-view.tsx";
import {MutableRef} from "common/ref";

export type DamageResistanceProps = {
  value: MutableRef<Dnd5eDamageResistanceModifier, Dnd5eDamageResistanceModifierOperation[]>;
  dragHandlerRef: Ref<HTMLButtonElement>;
  dragRefPreview: Ref<HTMLDivElement>;
  remove: () => void;
  readOnly?: boolean;
};
export function Dnd5eDamageResistanceModifierView({value, dragHandlerRef, dragRefPreview, remove, readOnly}: DamageResistanceProps) {
  const damageResistances = useObservable(pipe(
    value.observe,
    map(value => value.damageTypes),
    distinct()
  ), [], [value.observe]);
  const toggle = useCallback((damageType: Dnd5eDamageType) => {
    value.apply(prev => [{
      type: "update-damage-types",
      operations: prev.damageTypes.includes(damageType) ? SetFn.delete(damageType) : SetFn.insert(damageType)
    }]);
  }, [value.apply]);
  const [expanded, toggleExpanded] = useToggle(false);

  return <div role="list-item">
    <ButtonBar ref={dragRefPreview}>
      <ExpandableLabel expanded={expanded} toggleExpand={toggleExpanded}>
        <IconButton ref={dragHandlerRef} title="Move"><DragIndicator /></IconButton>
        <span className="px-4 font-bold flex items-center bg-zinc-800/50">Damage Resistances</span>
        <div className="shrink-0 flex flex-row gap-1 px-2 py-2 flex-1 overflow-hidden bg-zinc-800/50">
          {damageResistances.map(damageType => <Dnd5eDamageTypeView key={damageType} damageType={damageType} active={damageResistances.includes(damageType)} />)}
        </div>
      </ExpandableLabel>
      {!readOnly && <IconButton title="Remove" variant="destructive" onClick={() => remove()}><FaTrash /></IconButton>}
    </ButtonBar>
    {expanded && <Fieldset className="gap-0.5">
      {DND_5E_OFFENSIVE_DAMAGE_TYPES.map(damageType => <ButtonBar key={damageType}>
        <InputGroup className="flex-1 pl-0 rounded-l-full ml-2" onClick={() => toggle(damageType)}>
          <InputGroupLabel className="flex-1 flex items-center gap-2">
            <img className={twMerge(
              "w-10 h-10",
              !damageResistances.includes(damageType) ? "opacity-50" : ""
            )} alt={damageType} src={DAMAGE_TYPE_IMAGES[damageType]} />
            <span className="flex-1">{damageType.toUpperCase()}</span>
          </InputGroupLabel>
        </InputGroup>
      </ButtonBar>)}
    </Fieldset>}
  </div>
}
import {PropsWithChildren, useRef} from "react";
import {QLabFileId, QLabStoreID,} from "common/qlab/index.ts";
import {AssetID, GameID, Node, NodeId, SceneID, Sheet, SheetID, TokenID,} from "common/legends/index.ts";
import {FileReference, Point, Transform, Tree, ValueFn} from "common/types/index.ts";
import {getImageSize, getVideoSize} from "../image/index.ts";
import {useGetResource, useGetStore} from "#lib/qlab/index.ts";
import {fileToAsset} from "./file-to-asset.ts";
import {Embeddable} from "common/types/generic/embeddable/index.ts";
import {VisibilityLayerFn} from "common/legends/visibility/index.ts";
import assetManager from "#lib/qlab/asset/asset-manager.ts";
import {useDeleteNode} from "../../../legends/common/node/use-delete-node.ts";
import {useGetNode} from "../../../legends/common/node/use-get-node.ts";
import {useDrop} from "react-dnd";
import {NativeTypes} from "react-dnd-html5-backend";
import {ContextMenu, ContextMenuOperation} from "../../../routes/game/context-menu/context-menu.ts";
import {useUserID} from "#lib/auth/use-get-user-id.ts";
import {useIsGameMaster} from "../../../legends/common/game/use-is-game-master.ts";
import {MutableRef} from "common/ref";

export type DropZoneProps = PropsWithChildren<{
  storeId: QLabStoreID;
  sceneID?: SceneID;
  contextMenu: MutableRef<ContextMenu, ContextMenuOperation[]>;
  onDrop: (screenPos: Point, fn: (worldPos: Point) => Node) => void;
}>;

export function NodeDropZone({storeId, onDrop, children, contextMenu}: DropZoneProps) {
  const getResource = useGetResource();
  const getStore = useGetStore();
  const deleteNode = useDeleteNode();
  const getNode = useGetNode()
  const dropZoneRef = useRef<HTMLDivElement>(null);
  const userID = useUserID()!;
  const isGameMaster = useIsGameMaster();

  const [, assetDropRef] = useDrop<{id: AssetID}>(() => ({
    accept: "legends/asset",
    drop: async (item, monitor) => {
      const {x, y} = monitor.getClientOffset()!;
      const {left, right, top, bottom} = dropZoneRef.current!.getBoundingClientRect();
      const clientPos: Point = [x, y];
      const screenPos: Point = [x - left - (right - left) / 2, -(y - top - (bottom - top) / 2)];

      const asset = await getResource("asset", storeId, item.id);
      if (Object.keys(asset.tokens).length === 1) {
        const tokenID = asset.initialTokenId;
        const token = asset.tokens.find(token => token.tokenID === tokenID)!;
        const tokenSheets: {[tokenID: TokenID]: Embeddable<SheetID, Sheet>} = {};
        if (token.sheetPolicy === "copy" && token.sheetId !== undefined) {
          const sheet = asset.sheets[token.sheetId];
          tokenSheets[asset.initialTokenId] = {type: "copy", data: sheet};
        } else if (token.sheetPolicy === "link" && token.sheetId !== undefined) {
          tokenSheets[asset.initialTokenId] = {type: "link", data: token.sheetId};
        }

        const store = await getStore("game", storeId);
        const assetFile = Tree.getItemById(store.assets, item.id);
        const name = token.name ?? assetFile?.data.name ?? "Asset";

        onDrop(screenPos, (worldPos): Node => ({
          type: "token",
          data: {
            id: NodeId.generate(),
            name,
            tags: [],
            opacity: 1,
            selectionMask: asset.selectionMask,
            transform: {
              ...Transform.DEFAULT,
              position: worldPos
            },
            tokenReference: {gameID: storeId as GameID, assetID: item.id, tokenID: asset.initialTokenId},
            tokenSheets: tokenSheets,
            children: [],
            ownerIDs: isGameMaster ? asset.ownerIDs : [userID],
            origin: [0, 0],
            pivot: [0, 0],
            visibilityLayer: asset.visibilityLayer,
            mountable: token.mountable,
            attachable: token.attachable,
            conditions: []
          }
        }));
      } else {
        contextMenu.apply(prev => ValueFn.set(prev, {
          type: "asset",
          data: {
            clientPos: clientPos,
            screenPos: screenPos,
            assetID: item.id
          }
        }));
      }
    }
  }), [dropZoneRef, getStore, getResource, onDrop, isGameMaster, userID]);


  const [, tokenDropRef] = useDrop<{nodeID: NodeId}>(() => ({
    accept: "legends/token",
    drop: async (item, monitor) => {
      const {x, y} = monitor.getClientOffset()!;
      const {left, right, top, bottom} = dropZoneRef.current!.getBoundingClientRect();
      const screenPosition: Point = [x - left - (right - left) / 2, -(y - top - (bottom - top) / 2)];

      const node = getNode(item.nodeID)!;
      await deleteNode(item.nodeID);
      onDrop(screenPosition, (position): Node => {
        return {
          ...node,
          data: {
            ...node.data,
            transform: {
              ...node.data.transform,
              rotation: 0,
              position: position
            }
          }
        } as Node;
      });
    }
  }), [dropZoneRef, getNode, deleteNode, onDrop]);

  const [, fileDropRef] = useDrop<{files: any[]}>(() => ({
    accept: NativeTypes.FILE,
    drop: async (item, monitor) => {
      const {x, y} = monitor.getClientOffset()!;
      const {left, right, top, bottom} = dropZoneRef.current!.getBoundingClientRect();
      const screenPosition: Point = [x - left - (right - left) / 2, -(y - top - (bottom - top) / 2)];
      if (item.files) {
        for (let i = 0; i < item.files.length; i ++) {
          const file = item.files[i];
          if (!file) continue;
          if (file.type.startsWith("image/")) {
            const assetId = QLabFileId.generate();
            const {url, upload} = await assetManager.asset(assetId);
            const size = await getImageSize((await fileToAsset(file))[1]);
            upload(file)({
              next() {},
              error(e) {console.error(e)},
              complete() {
                onDrop(screenPosition, (position): Node => ({
                  type: "image",
                  data: {
                    id: NodeId.generate(),
                    tags: [],
                    name: file.name,
                    file: url as FileReference,
                    normal: undefined,
                    origin: [32, 32],
                    pivot: [32, 32],
                    size,
                    repeatX: 1,
                    repeatY: 1,
                    opacity: 1,
                    selectionMask: ["GM"],
                    mountable: false,
                    attachable: false,
                    transform: {
                      ...Transform.DEFAULT,
                      position: [
                        position[0] - Math.floor(size[0]/2),
                        position[1] - Math.floor(size[1]/2)
                      ]
                    },
                    children: [],
                    visibilityLayer: ["ALL"],
                    conditions: []
                  }
                }));
              }
            });
          } else if (file.type.startsWith("video/")) {
            const assetId = QLabFileId.generate();
            const {url, upload} = await assetManager.asset(assetId);
            const size = await getVideoSize((await fileToAsset(file))[1]);
            upload(file)({
              next() {},
              error(e) {console.error(e)},
              complete() {
                onDrop(screenPosition, (position): Node => ({
                  type: "video",
                  data: {
                    id: NodeId.generate(),
                    tags: [],
                    name: file.name,
                    file: url as FileReference,
                    normal: undefined,
                    origin: [32, 32],
                    pivot: [32, 32],
                    size,
                    repeatX: 1,
                    repeatY: 1,
                    opacity: 1,
                    selectionMask: ["GM"],
                    mountable: false,
                    attachable: false,
                    transform: {
                      ...Transform.DEFAULT,
                      position: [
                        position[0] - Math.floor(size[0]/2),
                        position[1] - Math.floor(size[1]/2)
                      ]
                    },
                    children: [],
                    visibilityLayer: VisibilityLayerFn.DEFAULT,
                    conditions: []
                  }
                }));
              }
            });
          }
        }
      }
    }
  }), [dropZoneRef, onDrop]);

  fileDropRef(tokenDropRef(assetDropRef(dropZoneRef)));

  return (
    <div ref={dropZoneRef} className="flex flex-1 justify-self-stretch">
      {children}
    </div>
  )
}
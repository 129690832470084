import {
  Dnd5eActionTemplateSegmentAttackRollModifier,
  Dnd5eActionTemplateSegmentAttackRollModifierOperation,
  Dnd5eActionTemplateSegmentAttackRollModifierTypes
} from "common/legends/asset/sheet/dnd-5e/dnd-5e-action-definition/segment/attack-roll/dnd-5e-action-template-segment-attack-roll-modifier.ts";
import {MutableRef} from "common/ref";
import {ListOperation} from "common/types/generic/index.ts";
import {InputList, InputListItemProps, useDragListItem} from "#lib/components/list/input-list.tsx";
import {useTypedRef} from "../../../../../../../common/use-typed-ref.ts";
import React from "react";
import {Dnd5eActionTemplateSegmentAttackRollConditionModifierListItem} from "./dnd-5e-action-template-segment-attack-roll-condition-modifier-view.tsx";
import {Dnd5eActionTemplateSegmentAttackRollHitModifierListItem} from "./dnd-5e-action-template-segment-attack-roll-hit-modifier-view.tsx";
import {Dnd5eVariableModifierListItem} from "../dnd-5e-variable-modifier-view.tsx";
import {Dnd5eActionTemplateSegmentAttackRollAttributeOverrideModifierListItem} from "./dnd-5e-action-template-segment-attack-roll-attribute-override-modifier-view.tsx";

function Dnd5eActionTemplateSegmentAttackRollModifierListItem({item, remove, duplicate}: InputListItemProps<Dnd5eActionTemplateSegmentAttackRollModifier, Dnd5eActionTemplateSegmentAttackRollModifierOperation>) {
  const [dragHandlerRef, dragRefPreview] = useDragListItem("legends/action-template-segment-attack-roll-modifier", item, remove);
  const [type, typedRef] = useTypedRef<Dnd5eActionTemplateSegmentAttackRollModifierTypes>(item);
  if (type === "variable") return <Dnd5eVariableModifierListItem item={typedRef} remove={remove} duplicate={duplicate} dragHandlerRef={dragHandlerRef} dragRefPreview={dragRefPreview} />;
  if (type === "action::attack-roll::condition") return <Dnd5eActionTemplateSegmentAttackRollConditionModifierListItem item={typedRef} remove={remove} duplicate={duplicate} dragHandlerRef={dragHandlerRef} dragRefPreview={dragRefPreview} />;
  if (type === "action::attack-roll::hit-modifier") return <Dnd5eActionTemplateSegmentAttackRollHitModifierListItem item={typedRef} remove={remove} duplicate={duplicate} dragHandlerRef={dragHandlerRef} dragRefPreview={dragRefPreview} />;
  if (type === "action::attack-roll::attribute-override") return <Dnd5eActionTemplateSegmentAttackRollAttributeOverrideModifierListItem item={typedRef} remove={remove} duplicate={duplicate} dragHandlerRef={dragHandlerRef} dragRefPreview={dragRefPreview} />;
  return <>{type}</>
}

export function Dnd5eActionTemplateSegmentAttackRollModifiersView({valueRef}: {valueRef: MutableRef<Dnd5eActionTemplateSegmentAttackRollModifier[], ListOperation<Dnd5eActionTemplateSegmentAttackRollModifier, Dnd5eActionTemplateSegmentAttackRollModifierOperation>[]>}) {
  return <InputList
    accept="legends/action-template-segment-attack-roll-modifier"
    items={valueRef}
    itemKey={(v) => v.data.modifierID}
    copy={v => v} ListItem={Dnd5eActionTemplateSegmentAttackRollModifierListItem} />;
}
import {
  ColorOperation, colorType,
  ConstantOperation,
  FileReference,
  FileReferenceOperation,
  fileReferenceType, HSLA,
  NumberOperation,
  numberType,
  ObjectType,
  Size,
  SizeOperation,
  sizeType,
  Type,
  ValueOperation,
  ZodSize,
  ZodSizeOperation
} from "../../../types/index.ts";
import {z} from "zod";
import {MutableRef} from "#common/ref";

export const LightShapeSprite = z.object({
  file: FileReference,
  color: HSLA,
  intensity: z.number(),
  size: ZodSize
});
export type LightShapeSprite = z.infer<typeof LightShapeSprite>;
export const LightShapeSpriteOperation = z.discriminatedUnion("type", [
  z.object({type: z.literal("update-file"), operations: z.array(ValueOperation(FileReference, ConstantOperation))}),
  z.object({type: z.literal("update-color"), operations: z.array(ColorOperation)}),
  z.object({type: z.literal("update-intensity"), operations: z.array(NumberOperation)}),
  z.object({type: z.literal("update-size"), operations: z.array(ZodSizeOperation)})
]);
export type LightShapeSpriteOperation = z.infer<typeof LightShapeSpriteOperation>;


export type LightShapeSpriteType = Type<LightShapeSprite, LightShapeSpriteOperation>;

export const lightShapeSpriteType: LightShapeSpriteType = new ObjectType({
  file: fileReferenceType,
  color: colorType,
  intensity: numberType,
  size: sizeType
});

export function LightShapeSpriteRef(valueRef: MutableRef<LightShapeSprite, LightShapeSpriteOperation[]>) {
  return {
    intensityRef: valueRef.map<number, NumberOperation[]>(value => value.intensity, (_, operations) => [{type: "update-intensity", operations}]),
    colorRef: valueRef.map<HSLA, ColorOperation[]>(value => value.color, (_, operations) => [{type: "update-color", operations}]),
    sizeRef: valueRef.map<Size, SizeOperation[]>(value => value.size, (_, operations) => [{type: "update-size", operations}]),
    fileRef: valueRef.map<FileReference, FileReferenceOperation[]>(value => value.file, (_, operations) => [{type: "update-file", operations}])
  };
}

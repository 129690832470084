import React, {useCallback} from "react";
import {Button, InputGroup, TagListInput, useTempRef} from "#lib/components/index.ts";
import {AssetFn, AssetID, AssetTreeFile, generateAssetID} from "common/legends/index.ts";
import {stringType, tagListType} from "common/types/index.ts";
import {useCreateResource} from "#lib/qlab/index.ts";
import {Modal, ModalBody, ModalTitle} from "#lib/components/modal/index.ts";
import {InputString} from "#lib/components/input/input-string.tsx";
import {useGameReference} from "../../../routes/game";
import {Field, FieldLabel} from "#lib/components/panel-header.tsx";

export type CreateAssetModalProps = {
  onClose: () => void;
  onCreate: (item: AssetTreeFile) => void;
};

export function CreateAssetModal({onClose, onCreate}: CreateAssetModalProps) {
  const name = useTempRef(stringType, "");
  const tags = useTempRef(tagListType, () => []);

  const gameReference = useGameReference();
  const createResource = useCreateResource();
  const onCreateCharacter = useCallback(async () => {
    const n = name.value;
    const t = tags.value;
    if (n.length === 0) return;
    const assetID: AssetID = generateAssetID();
    await createResource("asset", assetID, AssetFn.defaultAsset(n));
    onCreate({id: assetID, name: n, tags: t});
  }, [name, tags, gameReference, onCreate, createResource]);

  return <Modal onClose={onClose}>
    <ModalTitle>Create Asset</ModalTitle>
    <ModalBody>
      <Field>
        <FieldLabel>Name</FieldLabel>
        <InputGroup className="mx-2 rounded-md overflow-hidden">
          <InputString autoFocus value={name} />
        </InputGroup>
      </Field>
      <Field>
        <FieldLabel>Tags</FieldLabel>
        <TagListInput value={tags} />
      </Field>
    </ModalBody>
    <Button type="submit" variant="primary" className="grow" onClick={() => {
      onCreateCharacter().catch(console.error);
    }}>Create</Button>
  </Modal>
}
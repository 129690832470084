import {FaUser} from "react-icons/fa";
import {NodeId, UserID} from "common/legends/index.ts";
import {pipe} from "common/pipe";
import {combine, distinct, map} from "common/observable";
import {FileReference} from "common/types/index.ts";
import {useObservable} from "#lib/qlab/index.ts";
import {twMerge} from "tailwind-merge";
import {usePlayerName} from "../../../routes/game/use-player-name.ts";
import {useNode} from "../../viewport/token/use-node.ts";
import {useGame} from "../../../routes/game/index.ts";
import {usePlayerIcon} from "../../../routes/game/use-player-icon.ts";
import {useGoToNode} from "../nav/editor/use-go-to-node.ts";

export type UserNodeIconProps = {
  userID: UserID;
  nodeID?: NodeId;
  icon: FileReference;
};

export function UserNodeIcon({userID, nodeID, icon}: UserNodeIconProps) {
  const playerName = usePlayerName(userID);

  const node = useNode(nodeID);
  const game = useGame();
  const playerIcon = usePlayerIcon(userID);
  const displayIcon = icon || playerIcon;

  const name = useObservable(pipe(
    combine(node.observe, game.observe),
    map(([node, game]) => {
      if (game.owner.id === userID) {
        return node?.data.name;
      } else if (node?.type === "token") {
        if (node.data.ownerIDs.length > 0) {
          return node.data.name;
        }
      }
      return undefined;
    }),
    distinct()
  ), undefined, [node.observe]);

  const goToNode = useGoToNode();

  return (<div className="absolute -top-5 w-full flex items-center justify-center">
    <div className={twMerge(
      "rounded-full bg-zinc-900 w-10 h-10 inline-flex items-center justify-center pointer-events-auto",
      nodeID && "cursor-pointer"
    )} title={name ? `${name} (${playerName})` : playerName} onClick={() => {
      if (nodeID) goToNode(nodeID);
    }}>
      {displayIcon && <img alt={name ? `${name} (${playerName})` : playerName} src={displayIcon} crossOrigin="anonymous" className="rounded-full w-10 h-10 ring-1 ring-zinc-900" />}
      {!displayIcon && <FaUser size={20} />}
    </div>
  </div>);
}
import {
  useArrayBuffer,
  useAttribLocation,
  useBindVertexArribArray,
  useElementArrayBuffer,
  useProgram,
  useShader,
  useUniformLocation,
  useVertexBuffer
} from "#lib/gl-react/index.ts";
import React, {useMemo} from "react";
import {Color, HSLA, Point, Size} from "common/types/index.ts";
import {Matrix4f} from "#lib/math/index.ts";
import {usePVM} from "../../context/pvm-context.ts";
import {useRenderPassTexture} from "../../context/render-pass-texture-context.ts";
import {Vector2} from "common/math/vector/vector2.ts";


const vertexShader = `#version 300 es
precision highp float;

in vec2 a_position;
in vec2 a_tex_coord;
in vec2 u_origin;

uniform mat4 u_projection;
uniform mat4 u_view;
uniform mat4 u_model;

out vec2 fragCoord;
out vec2 v_tex_coord;
out vec2 v_normal_coord;
out vec2 v_world_pos;

void main()
{
  gl_Position = u_projection * u_view * u_model * vec4(a_position, 0, 1);
  v_normal_coord = (gl_Position.xy + 1.0) / 2.0;
  v_world_pos = (u_model * vec4(a_position, 0, 1)).xy;
  v_tex_coord = a_tex_coord;
}
`;

const fragmentShader = `#version 300 es
precision highp float;
precision highp sampler2DArray;

in vec2 v_normal_coord;
in vec2 v_tex_coord;
in vec2 v_world_pos;

uniform vec4 tint;
uniform sampler2D u_texture;
uniform sampler2D u_normal;
uniform vec2 u_origin;

out vec4 outColor;

void main() {
  vec2 f = fwidth(v_tex_coord);
  vec4 a = texture(u_texture, v_tex_coord + vec2(0.0, 0.0));
  vec4 b = texture(u_texture, v_tex_coord + vec2(f.x, 0.0)/2.)*sqrt(0.5);
  vec4 c = texture(u_texture, v_tex_coord - vec2(f.x, 0.0)/2.)*sqrt(0.5);
  vec4 d = texture(u_texture, v_tex_coord + vec2(0.0, f.y)/2.)*sqrt(0.5);
  vec4 e = texture(u_texture, v_tex_coord - vec2(0.0, f.y)/2.)*sqrt(0.5);
  outColor = ((a+b+c+d+e)/(1.+4.*sqrt(0.5))) * tint;

  vec2 coord = normalize(u_origin-v_world_pos);
  vec3 normal = texture(u_normal, v_normal_coord).rgb * 2.0 - 1.0;
  float n = mix(1.0, max(dot(normalize(normal.xy), coord.xy), 0.), max(0.0, 1.0 - normal.z));
  outColor = vec4(outColor.rgb, outColor.a * n);
}
`;

export function ImageLightShader({origin, size, texture, tint = Color.WHITE}: {
  texture: WebGLTexture;
  origin: Point;
  size: Size;
  tint?: HSLA
}) {
  if (texture === 0) throw new Promise(() => {});

  const program = useProgram(
    useShader(WebGL2RenderingContext.VERTEX_SHADER, vertexShader),
    useShader(WebGL2RenderingContext.FRAGMENT_SHADER, fragmentShader)
  );
  const vbo = useArrayBuffer(useMemo(() => {
    const [x, y] = origin;
    const [w, h] = size;
    return new Float32Array([
      -x - 0, -y - 0, 0, 1,
      -x - 0, -y + h, 0, 0,
      -x + w, -y + h, 1, 0,
      -x + w, -y - 0, 1, 1
    ]);
  }, [size, origin]));
  const vao = useVertexBuffer();
  useBindVertexArribArray(vao, useAttribLocation(program, "a_position"), vbo, 2, WebGL2RenderingContext.FLOAT, false, 4 * 4, 0);
  useBindVertexArribArray(vao, useAttribLocation(program, "a_tex_coord"), vbo, 2, WebGL2RenderingContext.FLOAT, false, 4 * 4, 2 * 4);

  const ebo = useElementArrayBuffer(useMemo(() => new Uint16Array([
    0, 1, 2,
    2, 3, 0
  ]), []));

  const {projection, view, model} = usePVM();
  const projectionMatrix4f = useMemo(() => new Float32Array(projection), [projection]);
  const viewMatrix4f = useMemo(() => new Float32Array(Matrix4f.transform(view)), [view]);
  const modelMatrix4f = useMemo(() => new Float32Array(Matrix4f.transform(model)), [model]);
  const tintArray = useMemo(() => new Float32Array(Color.toRGBA(tint)), [tint]);
  const origin2f = useMemo(() => new Float32Array(Vector2.multiplyTransform([0,0], model)), [origin, model])

  const [_, normalTexture] = useRenderPassTexture();

  return (<program value={program}>
    <uniformMat4fv location={useUniformLocation(program, "u_projection")} transpose data={projectionMatrix4f}/>
    <uniformMat4fv location={useUniformLocation(program, "u_view")} transpose data={viewMatrix4f}/>
    <uniformMat4fv location={useUniformLocation(program, "u_model")} transpose data={modelMatrix4f}/>
    <uniform2fv location={useUniformLocation(program, "u_origin")} data={origin2f} />
    <uniform4fv location={useUniformLocation(program, "tint")} data={tintArray}/>

    <activeTexture texture={WebGL2RenderingContext.TEXTURE0}/>
    <texture2d value={texture}>
      <uniform1i location={useUniformLocation(program, "u_texture")} data={0}/>
      <activeTexture texture={WebGL2RenderingContext.TEXTURE1}/>
      <texture2d value={normalTexture}>
        <uniform1i location={useUniformLocation(program, "u_normal")} data={1}/>
        <vertexArray value={vao}>
          <elementArrayBuffer value={ebo}>
            <drawElements mode={WebGL2RenderingContext.TRIANGLES} type={WebGL2RenderingContext.UNSIGNED_SHORT} offset={0} count={6}/>
          </elementArrayBuffer>
        </vertexArray>
      </texture2d>
    </texture2d>
    <activeTexture texture={WebGL2RenderingContext.TEXTURE0}/>
  </program>);
}

import {z} from "zod";

export type RGB = [number, number, number];
export type RGBA = [number, number, number, number];
export type HSL = [number, number, number];

export const HSL = z.tuple([z.number(), z.number(), z.number()]);
export const HSLA = z.tuple([z.number(), z.number(), z.number(), z.number()]).brand("HSLA");
export type HSLA = z.infer<typeof HSLA>;

export const Color = {
  rgbaToHex: (value: RGBA): string => {
    return `${value.map(v => Math.floor(v * 255).toString(16)).map(v => v.length < 2 ? `0${v}` : v).join("")}`;
  },
  rgbToHex: (value: RGB): string => {
    return `${value.map(v => Math.floor(v * 255).toString(16)).map(v => v.length < 2 ? `0${v}` : v).join("")}`;
  },
  hslToHex: (value: HSL): string => {
    return Color.rgbToHex(Color.toRGB(value));
  },
  hslaToHex: (value: HSLA): string => {
    return Color.rgbaToHex(Color.toRGBA(value));
  },
  fromHex: (value: string): RGBA => {
    if (value.length !== "000000".length && value.length !== "00000000".length) throw new Error("Must be proper length");
    const color: RGBA = [
      Number.parseInt(value.substring(0, 2), 16) / 255,
      Number.parseInt(value.substring(2, 4), 16) / 255,
      Number.parseInt(value.substring(4, 6), 16) / 255,
      value.length == 8 ? Number.parseInt(value.substring(6, 8), 16) / 255 : 1
    ];
    if (color.some(v => Number.isNaN(v))) throw new Error("Not a number");
    return color;
  },
  toRGB([h, s, l]: HSL | HSLA): RGB {
    let r, g, b;
    if (s === 0){
      r = g = b = l;
    } else {
      let hue2rgb = function hue2rgb(p: number, q: number, t: number){
        if(t < 0) t += 1;
        if(t > 1) t -= 1;
        if(t < 1/6) return p + (q - p) * 6 * t;
        if(t < 1/2) return q;
        if(t < 2/3) return p + (q - p) * (2/3 - t) * 6;
        return p;
      }

      const q = l < 0.5 ? l * (1 + s) : l + s - l * s;
      const p = 2 * l - q;
      r = hue2rgb(p, q, h + 1/3);
      g = hue2rgb(p, q, h);
      b = hue2rgb(p, q, h - 1/3);
    }
    if (Number.isNaN(r) || Number.isNaN(g) || Number.isNaN(b)) {
      throw new Error("Cannot convert number");
    }
    return [r, g, b];
  },
  toRGBA([h, s, l, a]: HSLA): RGBA {
    return [...Color.toRGB([h,s,l]), a];
  },
  toHSLA([r,g,b,a]: RGBA): HSLA {
    const max = Math.max(r, g, b);
    const min = Math.min(r, g, b);

    // hue
    let hue;
    if (min === max) {
      hue = 0;
    } else if (r === max) {
      hue = (g - b) / (max - min);
    } else if (g === max) {
      hue = 2 + (b - r) / (max - min);
    } else if (b === max) {
      hue = 4 + (r - g) / (max - min);
    } else {
      hue = 0;
    }
    hue *= 60;
    if (hue < 0) hue = hue + 360;

    // luminosity
    let luminosity = 0.5 * (max + min);

    // saturation
    let saturation;
    if (luminosity <= 0 || luminosity >= 1) {
      saturation = 0;
    } else {
      saturation = (max-min)/(1-Math.abs(2*luminosity-1));
    }

    return [hue / 360, saturation, luminosity, a] as HSLA;
  },
  toHSL([r, g, b]: RGB): HSL {
    const [h, s, l] = this.toHSLA([r, g, b, 1]);
    return [h, s, l];
  },
  CLEAR: [0, 0, 0, 0] as HSLA,
  RED: [0, 1, 0.5, 1] as HSLA,
  BLUE: [2/3, 0.5, 0.75, 1] as HSLA,
  WHITE: [0, 0, 1, 1] as HSLA,
  WHITE25: [0, 0, 1, 0.25] as HSLA,
  WHITE75: [0, 0, 1, 0.75] as HSLA,
  BLACK: [0, 0, 0, 1] as HSLA,
  BLACK25: [0, 0, 0, 0.25] as HSLA,
  BLACK75: [0, 0, 0, 0.75] as HSLA,
  GRAY: [0, 0, 0.5, 1] as HSLA,
  GREEN: [1/3, 1, 0.5, 1] as HSLA
}

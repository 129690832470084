import {useParams} from "react-router";
import {useMemo} from "react";
import {Game, GameID, GameOperation, GameSignal} from "common/legends/index.ts";
import {useUserID} from "#lib/auth/use-get-user-id.ts";
import {QLabDatabase, QLabDatabaseOperation, QLabStoreID} from "common/qlab/index.ts";
import {PlayerGameView} from "../../../../legends/panel/nav/player/player-game-view.tsx";
import {useSearchParams} from "react-router-dom";
import {DatabaseProvider} from "../../model/store-context.tsx";
import {useQLabSignal} from "#lib/qlab/react/use-q-lab-signal.ts";
import {useComputedValue, useRefValue} from "#lib/signal/index.ts";
import {GameProvider} from "../../model/index.ts";
import {useActionSourceEditors} from "../../../../legends/panel/nav/common/use-action-source-editors.ts";
import {ActionSourceEditor} from "../../../../legends/panel/nav/common/quick-access-menu/dnd-5e/action-source-editor.tsx";
import {ListOperation} from "common/types/index.ts";
import {MutableRef} from "common/ref";
import {EditorView} from "../../../../legends/panel/nav/editor";

export function LoadedGameRoute({store}: {
  store: MutableRef<QLabDatabase, QLabDatabaseOperation[]>;
}) {
  const {gameID} = useParams();
  const [searchParams] = useSearchParams();
  const userID = useUserID()!;
  const game = useMemo(() => GameSignal(store), [store]);
  const isLoaded = useComputedValue(game, game => game !== undefined);
  const isGameMaster = useComputedValue(game, game => game !== undefined && (game.owner.id === userID || game.players[userID]?.gameMaster), [game, userID]);
  const isPlayer = useComputedValue(game, game => game !== undefined && (game.players[userID] !== undefined), [game, userID]);

  const editors = useActionSourceEditors();
  const editorsValue = useRefValue(useActionSourceEditors());

  return (
    <DatabaseProvider storeID={gameID as GameID} database={store}>
      {isLoaded && <GameProvider value={game as MutableRef<Game, GameOperation[]>}>
        {(isGameMaster && !searchParams.has("player")) && <EditorView storeId={gameID as QLabStoreID} />}
        {(
          (isGameMaster && searchParams.has("player")) ||
          (!isGameMaster && isPlayer)
        ) && <PlayerGameView />}
        {editorsValue.map((editor, index) => <ActionSourceEditor key={index} reference={editor} onClose={() => {
          editors.apply(prev => ListOperation.delete(index, prev[index]));
        }} />)}
      </GameProvider>}
    </DatabaseProvider>
  );
}

export function GameRoute() {
  const {gameID} = useParams();
  const store = useQLabSignal(gameID as GameID);
  if (store === undefined) return <></>;
  return <LoadedGameRoute store={store} />
}

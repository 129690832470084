import {z} from "zod";
import {Dnd5eActionTemplateSegmentID} from "../dnd-5e-action-template-segment-i-d.ts";
import {
  BooleanOperation,
  booleanType,
  ConstantOperation,
  constantType,
  ListPropertyRef,
  ListType,
  ObjectType,
  Optional,
  PropertyRef,
  Type,
  ValueOperation,
  ValuePropertyRef,
  ValueType,
  ZodListOperation
} from "../../../../../../../types/index.ts";
import {Dnd5eAttribute} from "../../../character/dnd-5e-attribute.ts";
import {DiceExpression} from "../../../../../../../dice/index.ts";
import {Dnd5eActionTemplateSegment, Dnd5eActionTemplateSegmentOperation, dnd5eActionTemplateSegmentType} from "../dnd-5e-action-template-segment.ts";
import {MutableRef} from "#common/ref";
import {
  Dnd5eActionTemplateSegmentAttackRollModifier,
  Dnd5eActionTemplateSegmentAttackRollModifierOperation,
  dnd5eActionTemplateSegmentAttackRollModifierType
} from "./dnd-5e-action-template-segment-attack-roll-modifier.ts";

export const Dnd5eActionTemplateSegmentAttackRoll = z.lazy(() => z.object({
  actionSegmentID: Dnd5eActionTemplateSegmentID,
  attribute: z.optional(Dnd5eAttribute),
  proficient: z.boolean(),
  expression: DiceExpression,
  criticalRange: DiceExpression,
  modifiers: z.array(Dnd5eActionTemplateSegmentAttackRollModifier),
  onCriticalHit: z.array(Dnd5eActionTemplateSegment),
  onHit: z.array(Dnd5eActionTemplateSegment),
  onMiss: z.array(Dnd5eActionTemplateSegment)
}));
export type Dnd5eActionTemplateSegmentAttackRoll = z.infer<typeof Dnd5eActionTemplateSegmentAttackRoll>;

export const Dnd5eActionTemplateSegmentAttackRollOperation = z.lazy(() => z.discriminatedUnion("type", [
  z.object({type: z.literal("update-action-segment-i-d"), operations: z.array(ConstantOperation)}),
  z.object({type: z.literal("update-attribute"), operations: z.array(ValueOperation(z.optional(Dnd5eAttribute), ConstantOperation))}),
  z.object({type: z.literal("update-proficient"), operations: z.array(BooleanOperation)}),
  z.object({type: z.literal("update-expression"), operations: z.array(ValueOperation(DiceExpression, ConstantOperation))}),
  z.object({type: z.literal("update-critical-range"), operations: z.array(ValueOperation(DiceExpression, ConstantOperation))}),
  z.object({type: z.literal("update-modifiers"), operations: z.array(ZodListOperation(Dnd5eActionTemplateSegmentAttackRollModifier, Dnd5eActionTemplateSegmentAttackRollModifierOperation))}),
  z.object({type: z.literal("update-on-critical-hit"), operations: z.array(ZodListOperation(Dnd5eActionTemplateSegment, Dnd5eActionTemplateSegmentOperation))}),
  z.object({type: z.literal("update-on-hit"), operations: z.array(ZodListOperation(Dnd5eActionTemplateSegment, Dnd5eActionTemplateSegmentOperation))}),
  z.object({type: z.literal("update-on-miss"), operations: z.array(ZodListOperation(Dnd5eActionTemplateSegment, Dnd5eActionTemplateSegmentOperation))})
]));
export type Dnd5eActionTemplateSegmentAttackRollOperation = z.infer<typeof Dnd5eActionTemplateSegmentAttackRollOperation>;

export const dnd5eActionTemplateSegmentAttackRollType: Type<Dnd5eActionTemplateSegmentAttackRoll, Dnd5eActionTemplateSegmentAttackRollOperation> = new ObjectType(() => ({
  actionSegmentID: constantType,
  attribute: new ValueType(constantType),
  proficient: booleanType,
  expression: new ValueType(constantType),
  criticalRange: new ValueType(constantType),
  modifiers: new ListType(dnd5eActionTemplateSegmentAttackRollModifierType),
  onCriticalHit: new ListType(dnd5eActionTemplateSegmentType),
  onHit: new ListType(dnd5eActionTemplateSegmentType),
  onMiss: new ListType(dnd5eActionTemplateSegmentType)
}));

export function Dnd5eActionTemplateSegmentAttackRollSignals(valueRef: MutableRef<Dnd5eActionTemplateSegmentAttackRoll, Dnd5eActionTemplateSegmentAttackRollOperation[]>) {
  return {
    attributeRef: ValuePropertyRef<Dnd5eActionTemplateSegmentAttackRoll, Dnd5eActionTemplateSegmentAttackRollOperation, Optional<Dnd5eAttribute>, ConstantOperation>(
      value => value.attribute,
      operations => [{type: "update-attribute", operations}]
    )(valueRef),
    proficientRef: PropertyRef<Dnd5eActionTemplateSegmentAttackRoll, Dnd5eActionTemplateSegmentAttackRollOperation, boolean, BooleanOperation>(
      value => value.proficient,
      operations => [{type: "update-proficient", operations}]
    )(valueRef),
    expressionRef: ValuePropertyRef<Dnd5eActionTemplateSegmentAttackRoll, Dnd5eActionTemplateSegmentAttackRollOperation, DiceExpression, ConstantOperation>(
      value => DiceExpression.parse(value.expression),
      operations => [{type: "update-expression", operations}]
    )(valueRef),
    criticalRangeRef: ValuePropertyRef<Dnd5eActionTemplateSegmentAttackRoll, Dnd5eActionTemplateSegmentAttackRollOperation, DiceExpression, ConstantOperation>(
      value => DiceExpression.parse(value.criticalRange),
      operations => [{type: "update-critical-range", operations}]
    )(valueRef),
    modifiersRef: ListPropertyRef<Dnd5eActionTemplateSegmentAttackRoll, Dnd5eActionTemplateSegmentAttackRollOperation, Dnd5eActionTemplateSegmentAttackRollModifier, Dnd5eActionTemplateSegmentAttackRollModifierOperation>(
      value => value.modifiers,
      operations => [{type: "update-modifiers", operations}]
    )(valueRef),
    onCriticalHitRef: ListPropertyRef<Dnd5eActionTemplateSegmentAttackRoll, Dnd5eActionTemplateSegmentAttackRollOperation, Dnd5eActionTemplateSegment, Dnd5eActionTemplateSegmentOperation>(
      value => value.onCriticalHit,
      operations => [{type: "update-on-critical-hit", operations}]
    )(valueRef),
    onHitRef: ListPropertyRef<Dnd5eActionTemplateSegmentAttackRoll, Dnd5eActionTemplateSegmentAttackRollOperation, Dnd5eActionTemplateSegment, Dnd5eActionTemplateSegmentOperation>(
      value => value.onHit,
      operations => [{type: "update-on-hit", operations}]
    )(valueRef),
    onMissRef: ListPropertyRef<Dnd5eActionTemplateSegmentAttackRoll, Dnd5eActionTemplateSegmentAttackRollOperation, Dnd5eActionTemplateSegment, Dnd5eActionTemplateSegmentOperation>(
      value => value.onMiss,
      operations => [{type: "update-on-miss", operations}]
    )(valueRef)
  }
}

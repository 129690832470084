import {generateSheetID, Sheet, SheetID, SheetInterface, SheetOperation, SheetPolicy} from "common/legends/index.ts";
import {Button, ButtonBar, IconButton, InputGroup, Select, useToggle} from "#lib/components/index.ts";
import {FaCopy, FaFile, FaPlus, FaTrash} from "react-icons/fa";
import {CreateSheetModal} from "./create-sheet-modal.tsx";
import {useObservable, useObservableLoader} from "#lib/qlab/index.ts";
import {ConstantOperation, MapFn, MapRef, Optional, ValueFn, ValueOperation} from "common/types/index.ts";
import {InputGroupIcon} from "#lib/components/input/input-group-icon.tsx";
import {MutableRef} from "common/ref";
import {useCallback} from "react";
import {useRefValue} from "#lib/signal/index.ts";
import {Section} from "#lib/components/panel-header.tsx";

export type SheetFieldProps = {
  sheets: MapRef<SheetID, Sheet, SheetOperation>;
  sheetPolicy: MutableRef<SheetPolicy, ValueOperation<SheetPolicy, ConstantOperation>[]>;
  sheetID: MutableRef<Optional<SheetID>, ValueOperation<Optional<SheetID>, ConstantOperation>[]>;
};
export function SheetSection({sheets, sheetID: sheetIDRef, sheetPolicy}: SheetFieldProps) {
  function setSheetID(next: Optional<SheetID>) {
    sheetIDRef.apply(prev => ValueFn.set(prev, next));
  }
  async function deleteSheet(id: Optional<SheetID>) {
    if (id !== undefined) {
      sheets.apply(prev => MapFn.delete(id, prev[id]!));
      setSheetID(undefined);
    }
  }

  const [createSheet, toggleCreateSheet, setCreateSheet] = useToggle(false);
  const onCreate = (sheetID: SheetID, sheet: Sheet) => {
    sheets.apply(_ => MapFn.put(sheetID, sheet));
    setSheetID(sheetID);
    setCreateSheet(false);
  };

  const duplicateSheet = useCallback((sheetID: Optional<SheetID>) => {
    if (!sheetID) return;
    sheets.apply(prev => {
      const sheet = prev[sheetID];
      if (!sheet) return [];
      return MapFn.put(generateSheetID(), sheet);
    });
  }, [])

  const sheetID = useRefValue(sheetIDRef);
  const sheetPolicyLoader = useObservableLoader(sheetPolicy.observe);

  const sheetValues = useObservable(sheets.observe, {}, [sheets.observe]);

  return <Section title="Sheet">
    <ButtonBar className="mx-2 rounded-md overflow-hidden">
      <InputGroup className="flex-1 pr-0 gap-2" title="Sheet">
        <InputGroupIcon><FaFile/></InputGroupIcon>
        <Select aria-placeholder="Sheet" value={sheetID} onChange={ev => setSheetID(ev.target.value === "" ? undefined : ev.target.value as SheetID)}>
          <option value={""}>&nbsp;</option>
          {(Object.keys(sheetValues) as SheetID[]).map((sheetId) =>
            <option key={sheetId} value={sheetId}>{SheetInterface.getName(sheetValues[sheetId]!)}</option>
          )}
        </Select>
      </InputGroup>
      <IconButton title="Create Sheet" onClick={toggleCreateSheet}><FaPlus/></IconButton>
      <IconButton disabled={!sheetID} title="Duplicate Sheet" onClick={() => duplicateSheet(sheetID)}><FaCopy/></IconButton>
      <IconButton disabled={!sheetID} variant="destructive" title="Delete Sheet" onClick={() => deleteSheet(sheetID)}><FaTrash/></IconButton>
      {createSheet && <CreateSheetModal onClose={toggleCreateSheet} onCreate={onCreate}/>}
    </ButtonBar>

    <label className="px-4 py-1 text-sm font-bold">
      Policy
    </label>
    <div className="flex">
      {sheetPolicyLoader.isSuccess && <ButtonBar className="mx-2 rounded-md overflow-hidden ">
          <Button variant={sheetPolicyLoader.data === "link" ? "primary" : "tertiary"}
                  onClick={() => sheetPolicy.apply(prev => prev !== "link" ? ValueFn.set(prev, "link") : [])}>
              <span>Unique Creature</span>
          </Button>
          <Button variant={sheetPolicyLoader.data === "copy" ? "primary" : "tertiary"}
                  onClick={() => sheetPolicy.apply(prev => prev !== "copy" ? ValueFn.set(prev, "copy") : [])}>
              <span>Creature Template</span>
          </Button>
      </ButtonBar>}
    </div>
  </Section>
}
import {Sheet} from "common/legends/index.ts";
import {listIdentity} from "common/observable";
import {Optional} from "common/types/index.ts";
import {Dnd5eEffect} from "common/legends/asset/sheet/dnd-5e/dnd-5e-effect/index.ts";
import {useMemo} from "react";
import {getActiveFeatures} from "common/legends/asset/sheet/dnd-5e/dnd-5e-modifier-helper.ts";
import {MutableRef, Ref} from "common/ref";
import {useGlobalFeatures} from "./use-global-features.ts";

export function useSheetEffects(sheetRef: Ref<Optional<Sheet>>): Ref<Dnd5eEffect[]> {
  const globalFeaturesRef = useGlobalFeatures();
  return useMemo(() => MutableRef.all(sheetRef, globalFeaturesRef)
    .map(([sheet, globalFeatures]) => getActiveFeatures(sheet, globalFeatures).flatMap(feature => feature.effects))
    .distinct(listIdentity), [sheetRef, globalFeaturesRef]);
}

import {observableValue, subscribe, throttle} from "common/observable";
import {PlayerStackOperation, playerStackType, PlayerStackValue} from "./player-stack-type.ts";
import {pipe} from "common/pipe";
import {QLabStoreID} from "common/qlab/index.ts";
import {UUID} from "common/utils";
import {TokenViewportProperties, TokenViewportPropertiesOperation, tokenViewportPropertiesType} from "../../../viewport/token/token-viewport-properties.ts";
import {Container, DialogIDFn, SplitterId, StackId} from "#lib/container/index.ts";
import {ContainerOperation} from "#lib/container/modal/container-operation.ts";
import {MutableRef} from "common/ref";
import {ToolFn} from "../../../common/tool/tool.ts";

function defaultState(): TokenViewportProperties {
  return ({
    toolMode: ToolFn.DEFAULT,
    activeController: undefined,
    selectedControllerNodeIds: []
  });
}

export function defaultPlayerContainerState(): Container<PlayerStackValue> {
  const chatID = StackId.generate();
  const dialogId = DialogIDFn.generate();
  const sheetStackID = UUID.generate();
  const turnTrackerID = StackId.generate();
  return ({
    layout: {
      type: "column",
      items: [
        {id: SplitterId.generate(), weight: 13/16, content: {type: "empty"}},
        {id: SplitterId.generate(), weight: 3/16, content: {
          type: "row",
          items: [{id: SplitterId.generate(), weight: 1/4, content: {
            type: "stack",
            activeId: turnTrackerID,
            items: [
              {id: turnTrackerID, content: {type: "turn-tracker", data: {channelReference: undefined}}}
            ]
          }}, {id: SplitterId.generate(), weight: 3/4, content: {
            type: "stack",
            activeId: chatID,
            items: [
              {id: chatID, content: {type: "chat", data: {channelReference: undefined}}},
            ]
          }}]
        }}
      ]
    },
    dialogs: [{
      id: dialogId,
      value: {
        type: "layout",
        layout: {
          type: "stack",
          activeId: sheetStackID,
          items: [{
            id: sheetStackID,
            content: {type: "sheet", data: {}}
          }]
        },
      },
      minimized: false,
      external: false,
      position: [10, 10],
      size: [720, 480]
    }]
  });
}

export class PlayerController {
  readonly state: MutableRef<TokenViewportProperties, TokenViewportPropertiesOperation[]>;
  readonly container: MutableRef<Container<PlayerStackValue>, ContainerOperation<PlayerStackValue, PlayerStackOperation>[]>;
  readonly setContainer: (value: Container<PlayerStackValue>) => void;
  resetContainer() {
    this.setContainer(defaultPlayerContainerState());
  }

  constructor(storeId: QLabStoreID) {
    const initialState = (() => {
      try {
        const initial = localStorage.getItem(`player-${storeId}`);
        if (initial === null) return defaultState();
        return tokenViewportPropertiesType.migrateValue(JSON.parse(initial));
      } catch (e) {
        return defaultState();
      }
    })();

    let [state, applyToState] = observableValue<
      TokenViewportProperties, TokenViewportPropertiesOperation[]
    >(initialState, (value, operations) => operations.reduce(tokenViewportPropertiesType.apply, value));
    pipe(
      state.observe,
      throttle(1000),
      subscribe(value => localStorage.setItem(`player-${storeId}`, JSON.stringify(value)))
    );
    this.state = new MutableRef({
      value() {return state.value},
      observe: state.observe,
      apply: applyToState
    });

    const initialContainer = (() => {
      try {
        const initial = localStorage.getItem(`player-container-${storeId}`);
        if (initial === null) return defaultPlayerContainerState();
        return JSON.parse(initial);
      } catch (e) {
        return defaultPlayerContainerState();
      }
    })();

    let [container, applyToContainer, setContainer] = observableValue<
      Container<PlayerStackValue>, ContainerOperation<PlayerStackValue, PlayerStackOperation>[]
    >(initialContainer, (value, operations) => {
      return operations.reduce((container, operation) => Container.applyToValue(playerStackType, container, operation), value);
    });
    this.setContainer = setContainer;
    this.container = new MutableRef({
      value(): Container<PlayerStackValue> {
        return container.value;
      },
      observe: container.observe,
      apply: applyToContainer
    });

    pipe(
      container.observe,
      throttle(1000),
      subscribe(value => localStorage.setItem(`player-container-${storeId}`, JSON.stringify(value)))
    );
  }
}
import {Menu} from "@headlessui/react";
import {Button} from "#lib/components/index.ts";
import {FaPlus} from "react-icons/fa";
import {ExternalPortal} from "#lib/container/react/external-window/external-portal.tsx";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCirclesOverlap, faLambda, faSwap, faSword} from "@awesome.me/kit-c9bc6845cd/icons/classic/solid";
import React, {useCallback, useState} from "react";
import {usePopper} from "react-popper";
import {ListOperation} from "common/types/generic/index.ts";
import {generateDnd5eModifierID} from "common/legends/asset/sheet/dnd-5e/dnd-5e-modifier/dnd-5e-modifier-i-d.ts";
import {DiceExpression} from "common/dice/dice-expression.ts";
import {
  Dnd5eActionTemplateSegmentAttackRollModifier,
  Dnd5eActionTemplateSegmentAttackRollModifierOperation
} from "common/legends/asset/sheet/dnd-5e/dnd-5e-action-definition/segment/attack-roll/dnd-5e-action-template-segment-attack-roll-modifier.ts";
import {MutableRef} from "common/ref";

export function Dnd5eActionTemplateSegmentAttackRollAddModifierButton({valueRef, onAddedModifier}: {
  valueRef: MutableRef<Dnd5eActionTemplateSegmentAttackRollModifier[], ListOperation<Dnd5eActionTemplateSegmentAttackRollModifier, Dnd5eActionTemplateSegmentAttackRollModifierOperation>[]>;
  onAddedModifier?: (modifier: Dnd5eActionTemplateSegmentAttackRollModifier) => void;
}) {
  const [referenceElement, setReferenceElement] = useState<HTMLElement | null>(null);
  const [popperElement, setPopperElement] = useState<HTMLElement | null>(null);
  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    placement: "bottom-end"
  });

  const addModifier = useCallback((modifier: Dnd5eActionTemplateSegmentAttackRollModifier) => {
    valueRef.apply(prev => ListOperation.insert(prev.length, modifier)).then(() => {
      if (onAddedModifier) onAddedModifier(modifier);
    });
  }, [valueRef, onAddedModifier]);

  const addConditionModifier = useCallback(() => addModifier({
    type: "action::attack-roll::condition",
    data: {
      modifierID: generateDnd5eModifierID(),
      condition: {type: "effect", data: {effectID: undefined}},
      modifiers: []
    }
  }), [addModifier]);

  const addHitModifier = useCallback(() => addModifier({
    type: "action::attack-roll::hit-modifier",
    data: {
      modifierID: generateDnd5eModifierID(),
      expression: DiceExpression.parse("0")
    }
  }), [addModifier]);

  const addVariableModifier = useCallback(() => addModifier({
    type: "variable",
    data: {
      modifierID: generateDnd5eModifierID(),
      name: "",
      expression: DiceExpression.parse("0")
    }
  }), [addModifier]);

  const addAttributeOverrideModifier = useCallback(() => addModifier({
    type: "action::attack-roll::attribute-override",
    data: {
      modifierID: generateDnd5eModifierID(),
      attribute: undefined
    }
  }), [addModifier]);

  return (<Menu ref={ref => setReferenceElement(ref)} as="div">
    <Menu.Button as={Button}>
      <FaPlus /> Add Modifier
    </Menu.Button>
    <Menu.Items as={ExternalPortal} ref={ref => setPopperElement(ref)} style={styles.popper} {...attributes.popper} className="text-white flex flex-col items-stretch rounded-md m-0.5 overflow-hidden">
      <Menu.Item as={Button} className="justify-start" onClick={addConditionModifier}>
        <FontAwesomeIcon icon={faCirclesOverlap} /> <span>Condition</span>
      </Menu.Item>
      <Menu.Item as={Button} className="justify-start" onClick={addHitModifier}>
        <FontAwesomeIcon icon={faSword} /> <span>Hit</span>
      </Menu.Item>
      <Menu.Item as={Button} className="justify-start" onClick={addVariableModifier}>
        <FontAwesomeIcon icon={faLambda} /> <span>Variable</span>
      </Menu.Item>
      <Menu.Item as={Button} className="justify-start" onClick={addAttributeOverrideModifier}>
        <FontAwesomeIcon icon={faSwap} /> <span>Attribute Override</span>
      </Menu.Item>
    </Menu.Items>
  </Menu>);
}
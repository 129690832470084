import {z} from "zod";
import {
  Dnd5eActionTemplateSegmentAttackRollModifier,
  Dnd5eActionTemplateSegmentAttackRollModifierOperation,
  dnd5eActionTemplateSegmentAttackRollModifierType
} from "./dnd-5e-action-template-segment-attack-roll-modifier.ts";
import {
  ConstantOperation,
  constantType,
  ListOperation, ListPropertyRef,
  ListType,
  ObjectType,
  PropertyRef,
  Type,
  ValueOperation, ValuePropertyRef,
  ValueType,
  ZodListOperation
} from "../../../../../../../types/index.ts";
import {Dnd5eActionCondition} from "../../condition/dnd-5e-action-condition.ts";
import {Dnd5eModifierID} from "../../../dnd-5e-modifier/dnd-5e-modifier-i-d.ts";
import {MutableRef} from "#common/ref";

export const Dnd5eActionTemplateSegmentAttackRollConditionModifier: z.ZodType<{
  modifierID: string,
  condition: Dnd5eActionCondition,
  modifiers: Dnd5eActionTemplateSegmentAttackRollModifier[]
}> = z.lazy(() => z.object({
  modifierID: Dnd5eModifierID,
  condition: Dnd5eActionCondition,
  modifiers: z.array(Dnd5eActionTemplateSegmentAttackRollModifier)
}));
export type Dnd5eActionTemplateSegmentAttackRollConditionModifier = z.infer<typeof Dnd5eActionTemplateSegmentAttackRollConditionModifier>;

export const Dnd5eActionTemplateSegmentAttackRollConditionModifierOperation: z.ZodType<
  | {type: "update-modifier-i-d", operations: ConstantOperation[]}
  | {type: "update-condition", operations: ValueOperation<Dnd5eActionCondition, ConstantOperation>[]}
  | {type: "update-modifiers", operations: ListOperation<Dnd5eActionTemplateSegmentAttackRollModifier, Dnd5eActionTemplateSegmentAttackRollModifierOperation>[]}
> = z.lazy(() => z.discriminatedUnion("type", [
  z.object({type: z.literal("update-modifier-i-d"), operations: z.array(ConstantOperation)}),
  z.object({type: z.literal("update-condition"), operations: z.array(ValueOperation(Dnd5eActionCondition, ConstantOperation))}),
  z.object({type: z.literal("update-modifiers"), operations: z.array(ZodListOperation(Dnd5eActionTemplateSegmentAttackRollModifier, Dnd5eActionTemplateSegmentAttackRollModifierOperation))})
]));
export type Dnd5eActionTemplateSegmentAttackRollConditionModifierOperation = z.infer<typeof Dnd5eActionTemplateSegmentAttackRollConditionModifierOperation>;

export const dnd5eActionTemplateSegmentAttackRollConditionModifierType: Type<Dnd5eActionTemplateSegmentAttackRollConditionModifier, Dnd5eActionTemplateSegmentAttackRollConditionModifierOperation> = new ObjectType(() => ({
  modifierID: constantType,
  condition: new ValueType(constantType),
  modifiers: new ListType(dnd5eActionTemplateSegmentAttackRollModifierType)
}));

export function Dnd5eActionTemplateSegmentAttackRollConditionModifierSignals(valueRef: MutableRef<Dnd5eActionTemplateSegmentAttackRollConditionModifier, Dnd5eActionTemplateSegmentAttackRollConditionModifierOperation[]>) {
  return {
    conditionRef: ValuePropertyRef<Dnd5eActionTemplateSegmentAttackRollConditionModifier, Dnd5eActionTemplateSegmentAttackRollConditionModifierOperation, Dnd5eActionCondition, ConstantOperation>(
      value => value.condition,
      operations => [{type: "update-condition", operations}]
    )(valueRef),
    modifiersRef: ListPropertyRef<Dnd5eActionTemplateSegmentAttackRollConditionModifier, Dnd5eActionTemplateSegmentAttackRollConditionModifierOperation, Dnd5eActionTemplateSegmentAttackRollModifier, Dnd5eActionTemplateSegmentAttackRollModifierOperation>(
      value => value.modifiers,
      operations => [{type: "update-modifiers", operations}]
    )(valueRef)
  };
}
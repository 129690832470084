import {GridOptions} from "./grid-options.tsx";
import {Grid, GridOperation} from "common/legends/index.ts";
import React from "react";
import {MutableRef} from "common/ref";
import {Section} from "#lib/components/panel-header.tsx";

export type GridInputProps = {
  value: MutableRef<Grid, GridOperation[]>;
};

export function GridSection({value}: GridInputProps) {
  return (<Section title="Grid">
    <GridOptions value={value} />
  </Section>);
}
import {PropsWithChildren} from "react";
import {PlayerStackView} from "./player-stack-view.tsx";
import {PlayerStackTabView} from "./player-stack-tab.tsx";
import {PlayerStackOperation, PlayerStackValue} from "./player-stack-type.ts";
import {usePlayerController} from "./player-controller-provider.ts";
import {LayoutContentViewProps} from "#lib/container/react/layout/layout-content-view.ts";
import {ContainerView, LayoutView} from "#lib/container/index.ts";
import {PlayerNavigation} from "./player-navigation.tsx";
import {ErrorBoundary} from "#lib/components/error-boundary.tsx";
import {PlayerStacks} from "./player-stacks.ts";
import {ContainerProvider} from "#lib/container/react/container-context.ts";

function PlayerLayoutContentView({dialogId, value, apply}: LayoutContentViewProps<PlayerStackValue, PlayerStackOperation>) {
  return <LayoutView dialogId={dialogId} layoutPath={[]} value={value} apply={apply} StackContentView={PlayerStackView} StackTabView={PlayerStackTabView} Stacks={PlayerStacks} />
}

export function PlayerContainer({children}: PropsWithChildren<object>) {
  const playerController = usePlayerController();
  return (<ContainerProvider value={playerController.container}>
    <div className="flex flex-col h-[100vh] max-h-[100vh] overflow-hidden">
      <PlayerNavigation />
      <div className="flex relative flex-1 overflow-hidden bg-zinc-900">
        {children && <ErrorBoundary>
          {children}
        </ErrorBoundary>}
        <ContainerView containerRef={playerController.container} LayoutContentView={PlayerLayoutContentView} labelFor={(item) => PlayerStacks[item.content.type].label} StackTabView={PlayerStackTabView} StackContentView={PlayerStackView} Stacks={PlayerStacks} />
      </div>
    </div>
  </ContainerProvider>)
}
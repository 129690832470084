import {z} from "zod";
import {Dnd5eModifierID} from "../../../dnd-5e-modifier/dnd-5e-modifier-i-d.ts";
import {DiceExpression} from "../../../../../../../dice/index.ts";
import {ConstantOperation, constantType, ObjectType, Optional, Type, ValueOperation, ValuePropertyRef, ValueType} from "../../../../../../../types/index.ts";
import {MutableRef} from "#common/ref";
import {Dnd5eActionCondition} from "../../condition/dnd-5e-action-condition.ts";

export const Dnd5eActionTemplateSegmentSavingThrowDifficultyClassModifier: z.ZodType<{
  modifierID: string,
  condition?: Optional<Dnd5eActionCondition>,
  expression: string
}> = z.object({
  modifierID: Dnd5eModifierID,
  condition: z.optional(Dnd5eActionCondition),
  expression: DiceExpression
});
export type Dnd5eActionTemplateSegmentSavingThrowDifficultyClassModifier = z.infer<typeof Dnd5eActionTemplateSegmentSavingThrowDifficultyClassModifier>;

export const Dnd5eActionTemplateSegmentSavingThrowDifficultyClassModifierOperation = z.discriminatedUnion("type", [
  z.object({type: z.literal("update-modifier-i-d"), operations: z.array(ConstantOperation)}),
  z.object({type: z.literal("update-condition"), operations: z.array(ValueOperation(z.optional(Dnd5eActionCondition), ConstantOperation))}),
  z.object({type: z.literal("update-expression"), operations: z.array(ValueOperation<string, ConstantOperation>(DiceExpression, ConstantOperation))}),
]);
export type Dnd5eActionTemplateSegmentSavingThrowDifficultyClassModifierOperation = z.infer<typeof Dnd5eActionTemplateSegmentSavingThrowDifficultyClassModifierOperation>;

export const dnd5eActionTemplateSegmentSavingThrowDifficultyClassModifierType: Type<Dnd5eActionTemplateSegmentSavingThrowDifficultyClassModifier, Dnd5eActionTemplateSegmentSavingThrowDifficultyClassModifierOperation> = new ObjectType({
  modifierID: constantType,
  condition: new ValueType(constantType),
  expression: new ValueType<DiceExpression, ConstantOperation>(constantType)
});

export function Dnd5eActionTemplateSegmentSavingThrowDifficultyClassModifierSignals(valueRef: MutableRef<Dnd5eActionTemplateSegmentSavingThrowDifficultyClassModifier, Dnd5eActionTemplateSegmentSavingThrowDifficultyClassModifierOperation[]>) {
  return {
    conditionRef: ValuePropertyRef<Dnd5eActionTemplateSegmentSavingThrowDifficultyClassModifier, Dnd5eActionTemplateSegmentSavingThrowDifficultyClassModifierOperation, Optional<Dnd5eActionCondition>, ConstantOperation>(
      value => value.condition,
      operations => [{type: "update-condition", operations}]
    )(valueRef),
    expressionRef: ValuePropertyRef<Dnd5eActionTemplateSegmentSavingThrowDifficultyClassModifier, Dnd5eActionTemplateSegmentSavingThrowDifficultyClassModifierOperation, DiceExpression, ConstantOperation>(
      value => DiceExpression.parse(value.expression),
      operations => [{type: "update-expression", operations}]
    )(valueRef)
  };
}

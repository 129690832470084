import {MutableRef} from "common/ref";
import {LightShapeSprite, LightShapeSpriteOperation, LightShapeSpriteRef} from "common/legends/node/light/light-shape-sprite.ts";
import {InputGroup, InputNumber} from "#lib/components/index.ts";
import {useMemo} from "react";
import {InputFile} from "#lib/components/input/input-file.tsx";
import {SizeField} from "../../size-field.tsx";
import {ColorField} from "#lib/components/input/color-field.tsx";
import {Field, FieldLabel, SectionBody} from "#lib/components/panel-header.tsx";

export function LightShapeSpriteFields({valueRef}: {
  valueRef: MutableRef<LightShapeSprite, LightShapeSpriteOperation[]>
}) {
  const {intensityRef, colorRef, fileRef, sizeRef} = useMemo(() => LightShapeSpriteRef(valueRef), [valueRef]);

  return <SectionBody className="pt-2">
    <ColorField label="Color" value={colorRef} />

    <Field>
      <FieldLabel>Intensity</FieldLabel>
      <InputGroup className="mx-2 rounded-md overflow-hidden">
        <InputNumber value={intensityRef} />
      </InputGroup>
    </Field>

    <Field>
      <FieldLabel>Image</FieldLabel>
      <InputFile value={fileRef} />
    </Field>

    <SizeField value={sizeRef} />
  </SectionBody>
}
import {ButtonBar, ExpandableLabel, IconButton, InputGroup, InputGroupLabel, useToggle} from "#lib/components/index.ts";
import {SectionHeader} from "#lib/components/panel-header.tsx";
import {InputListItemProps} from "#lib/components/list/input-list.tsx";
import {Fieldset} from "#lib/components/fieldset/fieldset.tsx";
import {ConnectDragPreview, ConnectDragSource} from "react-dnd";
import {DragIndicator} from "#lib/components/tree-view/drag-indicator.tsx";
import React, {useMemo} from "react";
import {FaCopy, FaTrash} from "react-icons/fa";
import {InputMathExpression} from "#lib/components/input/input-math-expression.tsx";
import {InputString} from "#lib/components/input/input-string.tsx";
import {RollVariables} from "common/qlab/message/index.ts";
import {VariablesProvider} from "../dnd-5e-variables.ts";
import {useComputedValue} from "#lib/signal/index.ts";
import {Dnd5eModifierID} from "common/legends/asset/sheet/dnd-5e/dnd-5e-modifier/dnd-5e-modifier-i-d.ts";
import {useActionRef} from "../action-context.ts";
import {useSheetReference} from "../../../../../../common/sheet/sheet-reference-context.ts";
import {useSheetSignal} from "../../../../../../common/sheet/use-sheet-signal.ts";
import {useGlobalFeatures} from "../../../dnd-5e-character/dnd-5e-action/use-global-features.ts";
import {MutableRef, NOOP_SIGNAL, Ref} from "common/ref";
import {getActiveModifierVariables} from "../../../dnd-5e-character/dnd-5e-action/get-active-variables.ts";
import {
  Dnd5eVariableModifier,
  Dnd5eVariableModifierOperation,
  Dnd5eVariableOverrideModifierSignals
} from "common/legends/asset/sheet/dnd-5e/dnd-5e-modifier/dnd-5e-variable-modifier.ts";
import {mapIdentity} from "common/observable";

function useModifierVariables(modifierID: Dnd5eModifierID): Ref<RollVariables> {
  const sheetRef = useSheetSignal(useSheetReference());
  const globalFeaturesRef = useGlobalFeatures();
  const actionRef = useActionRef();
  return useMemo(() => MutableRef.all(sheetRef, globalFeaturesRef, actionRef ?? NOOP_SIGNAL)
    .map(([sheet, globalFeatures, action]) => getActiveModifierVariables(sheet, globalFeatures, action, modifierID))
    .distinct(mapIdentity), [sheetRef, globalFeaturesRef, actionRef, modifierID]);
}

export function Dnd5eVariableModifierListItem({item, remove, duplicate, dragRefPreview, dragHandlerRef}: InputListItemProps<Dnd5eVariableModifier, Dnd5eVariableModifierOperation> & {
  dragHandlerRef: ConnectDragSource | ConnectDragPreview,
  dragRefPreview: ConnectDragSource | ConnectDragPreview
}) {
  const [expanded, toggleExpanded] = useToggle(false);
  const modifierID = useComputedValue(item, item => item.modifierID);

  const modifierVariablesRef = useModifierVariables(modifierID);
  const {name, expression} = useMemo(() => Dnd5eVariableOverrideModifierSignals(item), [item]);
  return <div className="flex flex-col">
    <ButtonBar ref={dragRefPreview}>
      <IconButton ref={dragHandlerRef} title="Move"><DragIndicator /></IconButton>
      <ExpandableLabel expanded={expanded} toggleExpand={toggleExpanded}>
        <SectionHeader className="flex-1 bg-zinc-800/50" onClick={toggleExpanded}>Variable</SectionHeader>
      </ExpandableLabel>
      {duplicate && <IconButton title="Duplicate" onClick={duplicate}><FaCopy /></IconButton>}
      <IconButton variant="destructive" title="Remove Effect" onClick={remove}><FaTrash /></IconButton>
    </ButtonBar>

    {expanded && <Fieldset>
      <InputGroup>
        <InputGroupLabel>Variable</InputGroupLabel>
        <InputString placeholder="Variable" value={name} />
      </InputGroup>
      <InputGroup>
        <InputGroupLabel>Expression</InputGroupLabel>
        <VariablesProvider value={modifierVariablesRef}>
          <InputMathExpression value={expression} />
        </VariablesProvider>
      </InputGroup>
    </Fieldset>}
  </div>
}

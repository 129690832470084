import {IconButton} from "#lib/components/index.ts";
import {twMerge} from "tailwind-merge";
import {ValueFn} from "common/types/generic/index.ts";
import React, {useCallback, useState} from "react";
import {MutableRef} from "common/ref";
import {useComputedValue} from "#lib/signal/index.ts";
import {usePopper} from "react-popper";
import {Menu} from "@headlessui/react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCircle, faRectangle, faRuler, faSlashForward, faTriangle} from "@awesome.me/kit-c9bc6845cd/icons/classic/solid";
import {MeasurementPanel} from "./measurement-panel.tsx";
import {Tool, ToolOperation, ToolTypes} from "../../../../../common/tool/tool.ts";
import {useTypedRef} from "../../../../../common/use-typed-ref.ts";
import {useUnwrapValueRef} from "../../../../../common/use-unwrap-value-ref.ts";

export function MeasurementButton({toolRef}: {toolRef: MutableRef<Tool, ToolOperation[]>}) {
  const isMeasurementMode = useComputedValue(toolRef, tool => tool?.type === "measurement");
  const [referenceElement, setReferenceElement] = useState<HTMLElement | null>(null);
  const [popperElement, setPopperElement] = useState<HTMLElement | null>(null);
  const { styles, attributes } = usePopper(referenceElement, popperElement, {placement: "bottom-end"});

  const setDefaultMeasurement = useCallback(() => {
    toolRef.apply(prevValue => ValueFn.set(prevValue, {type: "measurement", data: {type: "path", data: {}}}))
  }, [toolRef]);

  const typeIcon = useComputedValue(toolRef, tool => {
    if (tool?.type !== "measurement") return undefined;
    return tool.data.type;
  }) ?? "path";

  const [mode, modeRef] = useTypedRef<ToolTypes>(useUnwrapValueRef(toolRef));
  return (<Menu as="div" ref={ref => setReferenceElement(ref)}>
    <Menu.Button title="Measure" as={IconButton} className={twMerge(!isMeasurementMode && "opacity-50 hover:opacity-80")} onClick={!isMeasurementMode ? setDefaultMeasurement : undefined}>
      {typeIcon === "path" && <FontAwesomeIcon icon={faRuler} />}
      {typeIcon === "cone" && <FontAwesomeIcon icon={faTriangle} />}
      {typeIcon === "rectangle" && <FontAwesomeIcon icon={faRectangle} />}
      {typeIcon === "circle" && <FontAwesomeIcon icon={faCircle} />}
      {typeIcon === "beam" && <FontAwesomeIcon icon={faSlashForward} />}
    </Menu.Button>
    {mode === "measurement" && <MeasurementPanel ref={setPopperElement} style={styles.popper} {...attributes.popper} measurementRef={modeRef} />}
  </Menu>);
}

import {useCallback} from "react";
import {Apply, walkTree} from "common/types/index.ts";
import {Scene} from "common/legends/index.ts";
import {SceneViewportProperties, SceneViewportPropertiesOperation} from "../scene-viewport-properties.ts";
import {useMutableRef} from "#lib/components/index.ts";
import {NodeSelectionRef, SceneSelectionRef} from "../../../panel/nav/editor/state/selection-ref.ts";

export function useSelectAll(rootSelectionRef: SceneSelectionRef, scene: Scene | undefined, apply: Apply<SceneViewportProperties, SceneViewportPropertiesOperation[]>) {
  const activeLayerRef = useMutableRef(scene);
  return useCallback(() => {
    const activeLayer = activeLayerRef.current;
    apply((prevState: SceneViewportProperties): SceneViewportPropertiesOperation[] => {
      const ids: NodeSelectionRef[] = [];
      walkTree(activeLayer?.children || [], {
        visit: value => {
          if (value.type === "image" || value.type === "group") {
            if (value.data.visibilityLayer.length > 0 && value.data.selectionMask.length > 0) {
              ids.push({...rootSelectionRef, type: "scene-node", nodeId: value.data.id})
            }
          }
        }
      });
      return [{
        type: "update-selected-node-ids",
        operations: [{
          type: "set",
          prevItems: prevState.selectedNodeIds,
          nextItems: ids
        }]
      }];
    });
  }, [apply, activeLayerRef]);
}
import {InputListItemProps} from "#lib/components/list/input-list.tsx";
import {
  Dnd5eActionTemplateSegmentAttackRoll,
  Dnd5eActionTemplateSegmentAttackRollOperation,
  Dnd5eActionTemplateSegmentAttackRollSignals
} from "common/legends/asset/sheet/dnd-5e/dnd-5e-action-definition/segment/attack-roll/dnd-5e-action-template-segment-attack-roll.ts";
import {ConnectDragPreview, ConnectDragSource} from "react-dnd";
import React, {useMemo} from "react";
import {ButtonBar, ExpandableLabel, IconButton, InputGroup, InputGroupLabel, useToggle} from "#lib/components/index.ts";
import {DragIndicator} from "#lib/components/tree-view/drag-indicator.tsx";
import {FaCopy, FaTrash} from "react-icons/fa";
import {Fieldset} from "#lib/components/fieldset/fieldset.tsx";
import {InputOptionalAttribute} from "../../../../dnd-5e-character/input-attribute.tsx";
import {InputCheckbox} from "#lib/components/input/input-checkbox.tsx";
import {InputDiceExpression} from "../../../../dice-expression/input-dice-expression.tsx";
import {Dnd5eActionTemplateSegmentsView} from "../../dnd-5e-action-template-segments-view.tsx";
import {Dnd5eActionTemplateSegmentAttackRollModifiersView} from "./dnd-5e-action-template-segment-attack-roll-modifiers-view.tsx";
import {Dnd5eActionTemplateAddSegmentButton} from "../dnd-5e-action-template-add-segment-button.tsx";
import {Dnd5eActionTemplateSegmentAttackRollAddModifierButton} from "./dnd-5e-action-template-segment-attack-roll-add-modifier-button.tsx";
import {SectionHeader} from "#lib/components/panel-header.tsx";

export function Dnd5eActionTemplateSegmentAttackRollView({item, remove, duplicate, dragRefPreview, dragHandlerRef}: InputListItemProps<Dnd5eActionTemplateSegmentAttackRoll, Dnd5eActionTemplateSegmentAttackRollOperation> & {
  dragHandlerRef: ConnectDragSource | ConnectDragPreview,
  dragRefPreview: ConnectDragSource | ConnectDragPreview
}) {
  const {attributeRef, proficientRef, criticalRangeRef, expressionRef, modifiersRef, onCriticalHitRef, onHitRef, onMissRef} = useMemo(() => Dnd5eActionTemplateSegmentAttackRollSignals(item), [item])
  const [expanded, toggleExpanded] = useToggle(false);

  const [modifiersExpanded, toggleModifiersExpanded, setModifiersExpanded] = useToggle(true);
  const [onHitExpanded, toggleOnHitExpanded, setOnHitExpanded] = useToggle(true);
  const [onCriticalHitExpanded, toggleOnCriticalHitExpanded, setOnCriticalHitExpanded] = useToggle(true);
  const [onMissExpanded, toggleOnMissExpanded, setOnMissExpanded] = useToggle(true);
  return <div role="list-item" className="flex flex-col">
    <ButtonBar ref={dragRefPreview}>
      <IconButton ref={dragHandlerRef} title="Move"><DragIndicator /></IconButton>
      <ExpandableLabel expanded={expanded} toggleExpand={toggleExpanded}>
        <SectionHeader className="flex-1 bg-zinc-800/50" onClick={toggleExpanded}>Attack Roll</SectionHeader>
      </ExpandableLabel>
      {duplicate && <IconButton title="Duplicate" onClick={duplicate}><FaCopy /></IconButton>}
      <IconButton variant="destructive" title="Remove Effect" onClick={remove}><FaTrash /></IconButton>
    </ButtonBar>
    {expanded && <Fieldset>
      <InputGroup>
        <InputGroupLabel>Attribute</InputGroupLabel>
        <InputOptionalAttribute valueRef={attributeRef} />
      </InputGroup>
      <InputGroup className="pl-0">
        <InputCheckbox value={proficientRef} />
        Proficient
      </InputGroup>
      <InputGroup>
        <InputGroupLabel>Hit Modifier</InputGroupLabel>
        <InputDiceExpression value={expressionRef} />
      </InputGroup>
      <InputGroup>
        <InputGroupLabel>Critical Range</InputGroupLabel>
        <InputDiceExpression value={criticalRangeRef} />
      </InputGroup>
      <div className="flex flex-col">
        <ExpandableLabel expanded={modifiersExpanded} toggleExpand={toggleModifiersExpanded}>
          <SectionHeader className="flex-1 bg-zinc-800/50" onClick={toggleModifiersExpanded}>Modifiers</SectionHeader>
          <Dnd5eActionTemplateSegmentAttackRollAddModifierButton valueRef={modifiersRef} onAddedModifier={() => setModifiersExpanded(true)} />
        </ExpandableLabel>
        {modifiersExpanded && <Fieldset>
          <Dnd5eActionTemplateSegmentAttackRollModifiersView valueRef={modifiersRef}/>
        </Fieldset>}
      </div>
      <div className="flex flex-col">
        <ExpandableLabel expanded={onHitExpanded} toggleExpand={toggleOnHitExpanded}>
          <SectionHeader className="flex-1 bg-zinc-800/50" onClick={toggleOnHitExpanded}>On Hit</SectionHeader>
          <Dnd5eActionTemplateAddSegmentButton valueRef={onHitRef} onAddedSegment={() => setOnHitExpanded(true)} />
        </ExpandableLabel>
        {onHitExpanded && <Fieldset>
          <Dnd5eActionTemplateSegmentsView valueRef={onHitRef}/>
        </Fieldset>}
      </div>
      <div className="flex flex-col">
        <ExpandableLabel expanded={onCriticalHitExpanded} toggleExpand={toggleOnCriticalHitExpanded}>
          <SectionHeader className="flex-1 bg-zinc-800/50" onClick={toggleOnCriticalHitExpanded}>On Critical Hit</SectionHeader>
          <Dnd5eActionTemplateAddSegmentButton valueRef={onCriticalHitRef} onAddedSegment={() => setOnCriticalHitExpanded(true)} />
        </ExpandableLabel>
        {onCriticalHitExpanded && <Fieldset>
          <Dnd5eActionTemplateSegmentsView valueRef={onCriticalHitRef}/>
        </Fieldset>}
      </div>
      <div className="flex flex-col">
        <ExpandableLabel expanded={onMissExpanded} toggleExpand={toggleOnMissExpanded}>
          <SectionHeader className="flex-1 bg-zinc-800/50" onClick={toggleOnMissExpanded}>On Miss</SectionHeader>
          <Dnd5eActionTemplateAddSegmentButton valueRef={onMissRef} onAddedSegment={() => setOnMissExpanded(true)} />
        </ExpandableLabel>
        {onMissExpanded && <Fieldset>
          <Dnd5eActionTemplateSegmentsView valueRef={onMissRef}/>
        </Fieldset>}
      </div>
    </Fieldset>}
  </div>;
}

import {Menu} from "@headlessui/react";
import {Button} from "#lib/components/index.ts";
import {FaPlus} from "react-icons/fa";
import {ExternalPortal} from "#lib/container/react/external-window/external-portal.tsx";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faBlockBrick, faBorderAll, faChartArea, faCloud, faFont, faImage, faLightbulb, faShapes, faFilm} from "@awesome.me/kit-c9bc6845cd/icons/classic/solid";
import {useState} from "react";
import {usePopper} from "react-popper";
import {defaultGridNode, Node, NodeOperation, ShapeNode, TextNode} from "common/legends/node/index.ts";
import {Color, HSLA, Tree, TreeOperation, TreePath} from "common/types/generic/index.ts";
import {defaultLocalNode} from "common/legends/node/local-node.ts";
import {defaultParallaxNode} from "common/legends/node/parallax-node.ts";
import {defaultLightNode} from "common/legends/node/light/light-node.ts";
import {MutableRef, Ref} from "common/ref";
import {NodeSelectionRef} from "../../panel/nav/editor/state/selection-ref.ts";

export function AddElementButton({valueRef, selectedNodeIdsRef}: {
  valueRef: MutableRef<Node[], TreeOperation<Node, NodeOperation>[]>,
  selectedNodeIdsRef: Ref<NodeSelectionRef[]>
}) {
  const addElement = (node: Node) => valueRef.apply(prevNodes => {
    const nodePaths = selectedNodeIdsRef.value.map(nodeRef => Tree.getPath(prevNodes, child => child.data.id === nodeRef.nodeId)).filter(path => path !== undefined).map(path => path as TreePath).sort(TreePath.sort);
    const path = (nodePaths.length === 0) ? [0] : nodePaths[0];
    return TreeOperation.insert(path, node);
  });

  const createGrid = () => addElement({type: "grid", data: defaultGridNode()});
  const createImage = () => addElement({
    type: "image",
    data: {
      ...defaultLocalNode(),
      name: "Image",
      file: undefined,
      normal: undefined,
      opacity: 1,
      origin: [32, 32],
      size: [64, 64],
      mountable: false,
      attachable: false,
      repeatX: 1,
      repeatY: 1,
      selectionMask: ["GM"]
    }
  });
  const createWall = () => addElement({
    type: "wall",
    data: {
      ...defaultLocalNode(),
      name: "Wall",
      selectionMask: ["GM"],
      color: [1, 0, 0, 1] as HSLA,
      opacity: 0.75,
      graph: {vertices: [], edges: []}
    }
  });
  const createArea = () => addElement({
    type: "area",
    data: {
      ...defaultLocalNode(),
      name: "Area",
      selectionMask: ["GM"],
      suppressWalls: false,
      color: Color.GREEN,
      opacity: 0.25,
      areas: [],
      interactions: []
    }
  });
  const createText = () => addElement({
    type: "text",
    data: {
      ...defaultLocalNode(),
      name: "Text",
      selectionMask: ["GM"],
      size: 32,
      text: "Text",
      vTextAlign: "middle",
      hTextAlign: "center",
      fillColor: Color.WHITE,
      outlineColor: Color.BLACK
    } satisfies TextNode
  });
  const createShape = () => addElement({
    type: "shape",
    data: {
      ...defaultLocalNode(),
      name: "Shape",
      selectionMask: ["GM"],
      origin: [32, 32],
      pivot: [32, 32],
      shape: {
        type: "rectangle",
        data: {
          width: 64,
          height: 64
        }
      },
      fillColor: Color.WHITE75
    } satisfies ShapeNode
  });
  const createParallax = () => addElement({type: "parallax", data: defaultParallaxNode()});
  const createLight = () => addElement({type: "light", data: defaultLightNode()});
  const createVideo = () => addElement({
    type: "video",
    data: {
      ...defaultLocalNode(),
      name: "Video",
      file: undefined,
      normal: undefined,
      opacity: 1,
      origin: [32, 32],
      size: [64, 64],
      mountable: false,
      attachable: false,
      selectionMask: ["GM"],
      repeatX: 1,
      repeatY: 1
    }
  });

  const [referenceElement, setReferenceElement] = useState<HTMLElement | null>(null);
  const [popperElement, setPopperElement] = useState<HTMLElement | null>(null);
  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    placement: "bottom-end"
  });

  return (
    <Menu ref={ref => setReferenceElement(ref)} as="div">
      <Menu.Button as={Button}>
        <FaPlus /> Add Element
      </Menu.Button>
      <Menu.Items as={ExternalPortal} ref={ref => setPopperElement(ref)} style={styles.popper} {...attributes.popper} className="text-white flex flex-col items-stretch rounded-md m-0.5 overflow-hidden">
        <Menu.Item as={Button} className="justify-start" title="Image" onClick={createImage}>
          <FontAwesomeIcon icon={faImage} />
          <span>Image</span>
        </Menu.Item>
        <Menu.Item as={Button} className="justify-start" title="Grid" onClick={createGrid}>
          <FontAwesomeIcon icon={faBorderAll} />
          <span>Grid</span>
        </Menu.Item>
        <Menu.Item as={Button} className="justify-start" title="Group" onClick={createWall}>
          <FontAwesomeIcon icon={faBlockBrick} />
          <span>Wall</span>
        </Menu.Item>
        <Menu.Item as={Button} className="justify-start" title="Area" onClick={createArea}>
          <FontAwesomeIcon icon={faChartArea} />
          <span>Area</span>
        </Menu.Item>
        <Menu.Item as={Button} className="justify-start" title="Text" onClick={createText}>
          <FontAwesomeIcon icon={faFont} />
          <span>Text</span>
        </Menu.Item>
        <Menu.Item as={Button} className="justify-start" title="Shape" onClick={createShape}>
          <FontAwesomeIcon icon={faShapes} />
          <span>Shape</span>
        </Menu.Item>
        <Menu.Item as={Button} className="justify-start" title="Paralax" onClick={createParallax}>
          <FontAwesomeIcon icon={faCloud} />
          <span>Parallax</span>
        </Menu.Item>
        <Menu.Item as={Button} className="justify-start" title="Light" onClick={createLight}>
          <FontAwesomeIcon icon={faLightbulb} />
          <span>Light</span>
        </Menu.Item>
        <Menu.Item as={Button} className="justify-start" title="Light" onClick={createVideo}>
          <FontAwesomeIcon icon={faFilm} />
          <span>Video</span>
        </Menu.Item>
      </Menu.Items>
    </Menu>
  );
}
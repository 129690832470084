import {PropsWithChildren, useEffect} from "react";
import {ErrorBoundary} from "#lib/components/error-boundary.tsx";
import {WallEditorNavigation} from "./wall-editor-navigation.tsx";
import {useSetSelectTool} from "../tool-selector/use-set-select-tool.ts";

export function WallEditorContainer({children}: PropsWithChildren<object>) {
  const exit = useSetSelectTool();
  useEffect(() => {
    const escapeHandler = (e: KeyboardEvent) => {
      if (e.defaultPrevented) return true;
      if (e.key === "Escape") {
        exit();
        e.preventDefault();
        e.stopPropagation();
        return false;
      }
      return true;
    };
    document.addEventListener("keydown", escapeHandler)
    return () => {
      document.removeEventListener("keydown", escapeHandler);
    };
  }, []);


  return (<div className="flex flex-col h-[100vh] max-h-[100vh] overflow-hidden">
    <WallEditorNavigation />
    <div className="flex relative flex-1 overflow-hidden bg-zinc-900">
      {children && <ErrorBoundary>
        {children}
      </ErrorBoundary>}
    </div>
  </div>)
}
import {HSLA, Point, Rectangle} from "common/types/generic/index.ts";
import {Spline} from "common/types/generic/spline/index.ts";
import {useMemo} from "react";
import {AreaShader} from "./area-shader.tsx";

export type RectangleShaderProps = {
  origin: Point,
  color: HSLA;
  rectangle: Rectangle,
};
export function RectangleShader({rectangle, origin, ...props}: RectangleShaderProps) {
  const spline = useMemo((): Spline => {
    const w = rectangle.width;
    const h = rectangle.height;
    return ({
      start: [0, 0],
      controlPoint1: [0, 0],
      controlPoint2: [0, 0],
      closed: true,
      curves: [
        {end: [w, 0], controlPoint1: [0, 0], controlPoint2: [0, 0]},
        {end: [w, h], controlPoint1: [0, 0], controlPoint2: [0, 0]},
        {end: [0, h], controlPoint1: [0, 0], controlPoint2: [0, 0]},
      ]
    })
  }, [rectangle]);
  return <AreaShader {...props} origin={origin} spline={spline} scale={1} />
}

import {Point} from "../../types/index.ts";
import {Vector2} from "../vector/vector2.ts";

export type Line = Point[];
export const LineFn = {
  distance(line: Point[]): number {
    let distance = 0;
    for (let i = 0; i < line.length - 1; i ++) {
      distance += Vector2.distance(line[i], line[i+1]);
    }
    return distance;
  }
};

import {useCallback} from "react";
import {Node, NodeId} from "common/legends/index.ts";
import {Optional, Point, Transform, Tree} from "common/types/index.ts";
import {Ref} from "common/ref";
import {Vector2} from "common/math/vector/vector2.ts";
import {Matrix4x4} from "common/math/matrix/matrix4x4.ts";

export function getNodeTransform(nodes: Node[], nodeID: Optional<NodeId>): Transform {
  let transform: Transform = Transform.DEFAULT;
  const path = Tree.getPath(nodes, node => node.data.id === nodeID);
  if (path === undefined || path.length === 0) return transform;
  let node = nodes[path[0]];
  let ancestors = [node];
  for (let i = 1; i < path.length; i++) {
    node = node.data.children[path[i]];
    ancestors.push(node);
  }
  for (let i = ancestors.length - 1; i >= 0; i--) {
    if (ancestors[i].type !== "parallax") {
      transform = Transform.divide(transform, ancestors[i].data.transform);
    }
  }
  return transform;
}

export function getNodeTransformMatrix(nodes: Node[], nodeID: Optional<NodeId>): Matrix4x4 {
  return Transform.toMatrix4x4(getNodeTransform(nodes, nodeID));
}

export function getWorldPositionFromNodePosition(nodes: Node[], nodeID: Optional<NodeId>, nodePos: Vector2): Vector2 {
  const matrix = getNodeTransformMatrix(nodes, nodeID);
  return Vector2.multiplyMatrix4x4(nodePos, matrix);
}

export function useNodePosToWorldPos(nodesRef: Ref<Node[]>) {
  return useCallback((nodeID: NodeId | undefined, nodePos: Point) => getWorldPositionFromNodePosition(nodesRef.value, nodeID, nodePos), [nodesRef]);
}


import {applyAll, ExtractOperation, ExtractValue, invertAll, transformAll, Type} from "../../type/index.ts";
import {CamelCaseToKebabCase, kebabCaseToCamelCase, thunkRecord, ThunkRecord} from "../../../utils/index.ts";
import {ValidationError} from "#common/types/type/validation/validation.ts";
import {QLabError} from "#common/error/QLabError.ts";
import {pipe} from "#common/pipe";
import {distinct, map} from "#common/observable";
import {MutableRef} from "#common/ref";
import {Optional} from "../optional/index.ts";

export type MultiTypeValue<K> = {
  [Property in keyof K]: {
    type: CamelCaseToKebabCase<string & Property>;
    data: ExtractValue<K[Property]>
  }
}[keyof K];
export type MultiTypeOperation<K> = {
  [Property in keyof K]: {
    type: CamelCaseToKebabCase<string & Property>;
    operations: ExtractOperation<K[Property]>[]
  }
}[keyof K];

export class MultiType<K extends Record<string, Type<any, any>>> implements Type<MultiTypeValue<K>, MultiTypeOperation<K>>{
  constructor(private readonly types: ThunkRecord<K>, private readonly updateFn: (value: any) => MultiTypeValue<K> = (v) => v) {
  }

  apply = (value: MultiTypeValue<K>, operation: MultiTypeOperation<K>): MultiTypeValue<K> => {
    if (value?.type === operation?.type) {
      try {
        const valueType = thunkRecord(this.types)[kebabCaseToCamelCase(value.type)];
        return ({
          ...value,
          data: applyAll(valueType, value.data, operation.operations)
        });
      } catch (e: any) {
        throw new QLabError(e.message, e.path ? [value.type, ...e.path] : [value.type]);
      }
    } else {
      throw new QLabError(`Unsupported Type: ${operation.type}.`, [value.type]);
    }
  }

  invert = (operation: MultiTypeOperation<K>): MultiTypeOperation<K>[] => {
    const valueType = thunkRecord(this.types)[kebabCaseToCamelCase(operation.type)];
    return [{
      type: operation.type,
      operations: invertAll(valueType, operation.operations)
    }];
  }

  transform = (leftOperation: MultiTypeOperation<K>, topOperation: MultiTypeOperation<K>, tieBreaker: boolean): MultiTypeOperation<K>[] => {
    if (leftOperation.type === topOperation.type) {
      const valueType = thunkRecord(this.types)[kebabCaseToCamelCase(leftOperation.type)];
      return [{
        type: leftOperation.type,
        operations: transformAll(valueType, leftOperation.operations, topOperation.operations, tieBreaker)[0]
      }];
    } else {
      throw new Error("Unsupported Operation");
    }
  }

  migrateValue = (value: any): MultiTypeValue<K> => {
    value = this.updateFn(value);
    const valueTypes = thunkRecord(this.types);
    const valueType = valueTypes[kebabCaseToCamelCase(value.type)];
    if (valueType === undefined) {
      throw new Error(`Unrecognized type: ${kebabCaseToCamelCase(value.type)}. Expected types: ${Object.keys(valueTypes).join(", ")}`)
    }

    return ({
      type: value.type,
      data: valueType.migrateValue(value.data)
    }) as MultiTypeValue<K>;
  }

  migrateOperation = (operation: any): MultiTypeOperation<K>[] => {
    const valueType = thunkRecord(this.types)[kebabCaseToCamelCase(operation.type)];
    return [{
      ...operation,
      operations: operation.operations.flatMap((operation: any) => valueType.migrateOperation(operation))
    }];
  }

  validate = (value: any): ValidationError[] => {
    if (typeof value !== "object") return [{path: [], data: {message: "Invalid type.", value}}];
    const type = thunkRecord(this.types)[kebabCaseToCamelCase(value.type)];
    if (!type) return [{path: [], data: {message: `Unrecognized type.`, type}}];
    return type.validate(value.data);
  }
}

//@ts-ignore
export function MultiTypeRef<K extends Record<string, Type<any, any>>, T extends keyof K>(type: CamelCaseToKebabCase<T>, ref: MutableRef<MultiTypeValue<K>,  MultiTypeOperation<K>[]>): MutableRef<Optional<ExtractValue<K[T]>>, ExtractOperation<K[T]>[]> {
  return new MutableRef({
    value() {
      const value = ref.value;
      if (value.type === type) return value.data;
      return undefined;
    },
    observe: pipe(
      ref.observe,
      map(v => v.type === type ? v.data : undefined),
      distinct()
    ),
    apply: fn => ref.apply(prev => {
      if (prev.type !== type) return [];
      const operations = fn(prev.data);
      if (operations.length === 0) return [];
      return [{type, operations: fn(prev.data)}] as any[]
    }).then(value => value.type === type ? value.data : undefined)
  })
}

import {Dnd5eActionTemplateSegment, Dnd5eActionTemplateSegmentOperation, dnd5eActionTemplateSegmentType} from "../../segment/dnd-5e-action-template-segment.ts";
import {Dnd5eActionTemplateEffect, Dnd5eActionTemplateEffectOperation, dnd5eActionTemplateEffectType} from "../../effect/dnd-5e-action-template-effect.ts";
import {z} from "zod";
import {Dnd5eActionTemplateID} from "../dnd-5e-action-template-i-d.ts";
import {
  BooleanOperation,
  booleanType,
  ConstantOperation,
  constantType,
  ListPropertyRef,
  ListType,
  ObjectType,
  PropertyRef,
  StringOperation,
  stringType,
  Type,
  ValueOperation,
  ValueType,
  ZodListOperation
} from "../../../../../../../types/index.ts";
import {MutableRef} from "#common/ref";
import {Dnd5eActionTemplateModifier, Dnd5eActionTemplateModifierOperation, dnd5eActionTemplateModifierType} from "../../modifier/dnd-5e-action-template-modifier.ts";
import {Dnd5eActionType} from "../../dnd-5e-action-type.ts";

export const Dnd5eActionTemplateCustom = z.object({
  actionTemplateID: Dnd5eActionTemplateID,
  actionType: Dnd5eActionType,
  favorite: z.boolean(),
  name: z.string(),
  abbreviation: z.string(),
  segments: z.array(Dnd5eActionTemplateSegment),
  modifiers: z.array(Dnd5eActionTemplateModifier),
  effects: z.array(Dnd5eActionTemplateEffect)
})
export type Dnd5eActionTemplateCustom = z.infer<typeof Dnd5eActionTemplateCustom>;

export const Dnd5eActionTemplateCustomOperation = z.discriminatedUnion("type", [
  z.object({type: z.literal("update-action-template-i-d"), operations: z.array(ConstantOperation)}),
  z.object({type: z.literal("update-action-type"), operations: z.array(ValueOperation(Dnd5eActionType, ConstantOperation))}),
  z.object({type: z.literal("update-favorite"), operations: z.array(BooleanOperation)}),
  z.object({type: z.literal("update-name"), operations: z.array(StringOperation)}),
  z.object({type: z.literal("update-abbreviation"), operations: z.array(StringOperation)}),
  z.object({type: z.literal("update-segments"), operations: z.array(ZodListOperation(Dnd5eActionTemplateSegment, Dnd5eActionTemplateSegmentOperation))}),
  z.object({type: z.literal("update-modifiers"), operations: z.array(ZodListOperation(Dnd5eActionTemplateModifier, Dnd5eActionTemplateModifierOperation))}),
  z.object({type: z.literal("update-effects"), operations: z.array(ZodListOperation(Dnd5eActionTemplateEffect, Dnd5eActionTemplateEffectOperation))})
]);
export type Dnd5eActionTemplateCustomOperation = z.infer<typeof Dnd5eActionTemplateCustomOperation>;
export const dnd5eActionTemplateCustomType: Type<Dnd5eActionTemplateCustom, Dnd5eActionTemplateCustomOperation> = new ObjectType({
  actionTemplateID: constantType,
  actionType: new ValueType(constantType),
  favorite: booleanType,
  name: stringType,
  abbreviation: stringType,
  segments: new ListType(dnd5eActionTemplateSegmentType),
  modifiers: new ListType(dnd5eActionTemplateModifierType),
  effects: new ListType(dnd5eActionTemplateEffectType)
});

export function Dnd5eActionTemplateCustomSignals(valueRef: MutableRef<Dnd5eActionTemplateCustom, Dnd5eActionTemplateCustomOperation[]>) {
  return {
    nameRef: PropertyRef<Dnd5eActionTemplateCustom, Dnd5eActionTemplateCustomOperation, string, StringOperation>(
      value => value.name,
      operations => [{type: "update-name", operations}]
    )(valueRef),
    abbreviationRef: PropertyRef<Dnd5eActionTemplateCustom, Dnd5eActionTemplateCustomOperation, string, StringOperation>(
      value => value.abbreviation,
      operations => [{type: "update-abbreviation", operations}]
    )(valueRef),
    favoriteRef: PropertyRef<Dnd5eActionTemplateCustom, Dnd5eActionTemplateCustomOperation, boolean, BooleanOperation>(
      value => value.favorite,
      operations => [{type: "update-favorite", operations}]
    )(valueRef),
    segmentsRef: ListPropertyRef<Dnd5eActionTemplateCustom, Dnd5eActionTemplateCustomOperation, Dnd5eActionTemplateSegment, Dnd5eActionTemplateSegmentOperation>(
      value => value.segments,
      operations => [{type: "update-segments", operations}]
    )(valueRef),
    modifiersRef: ListPropertyRef<Dnd5eActionTemplateCustom, Dnd5eActionTemplateCustomOperation, Dnd5eActionTemplateModifier, Dnd5eActionTemplateModifierOperation>(
      value => value.modifiers,
      operations => [{type: "update-modifiers", operations}]
    )(valueRef),
    effectsRef: ListPropertyRef<Dnd5eActionTemplateCustom, Dnd5eActionTemplateCustomOperation, Dnd5eActionTemplateEffect, Dnd5eActionTemplateEffectOperation>(
      value => value.effects,
      operations => [{type: "update-effects", operations}]
    )(valueRef)
  };
}
import {Optional} from "common/types/generic/index.ts";
import {Dnd5eFeature, Sheet} from "common/legends/asset/index.ts";
import {Dnd5eActionTemplate} from "common/legends/asset/sheet/dnd-5e/dnd-5e-action-definition/template/dnd-5e-action-template.ts";
import {Dnd5eActionTemplateSegment} from "common/legends/asset/sheet/dnd-5e/dnd-5e-action-definition/segment/dnd-5e-action-template-segment.ts";
import {getActiveSavingThrowModifiers} from "./get-active-saving-throw-modifiers.ts";
import {
  Dnd5eActionTemplateSegmentSavingThrowAttributeOverrideModifier
} from "common/legends/asset/sheet/dnd-5e/dnd-5e-action-definition/segment/saving-throw/dnd-5e-action-template-segment-saving-throw-attribute-override-modifier.ts";

export function getActiveSavingThrowAttributeOverrideModifiers(
  sheet: Optional<Sheet>,
  globalFeatures: Dnd5eFeature[],
  action: Optional<Dnd5eActionTemplate>,
  segment: Optional<Dnd5eActionTemplateSegment>
): Dnd5eActionTemplateSegmentSavingThrowAttributeOverrideModifier[] {
  return getActiveSavingThrowModifiers(sheet, globalFeatures, action, segment)
    .flatMap(modifier => modifier.type === "action::saving-throw::attribute-override" ? [modifier.data] : []);
}


import {Optional} from "common/types/generic/index.ts";
import {Dnd5eFeature, Sheet} from "common/legends/asset/index.ts";
import {Dnd5eActionTemplate} from "common/legends/asset/sheet/dnd-5e/dnd-5e-action-definition/template/dnd-5e-action-template.ts";
import {Dnd5eActionTemplateSegment} from "common/legends/asset/sheet/dnd-5e/dnd-5e-action-definition/segment/dnd-5e-action-template-segment.ts";
import {
  Dnd5eActionTemplateSegmentRollExpressionModifier
} from "common/legends/asset/sheet/dnd-5e/dnd-5e-action-definition/segment/damage/dnd-5e-action-template-segment-roll-expression-modifier.ts";
import {Dnd5eActionTemplateModifier} from "common/legends/asset/sheet/dnd-5e/dnd-5e-action-definition/modifier/dnd-5e-action-template-modifier.ts";
import {conditionEvaluator} from "../condition/condition-evaluator.ts";
import {getActiveModifiers} from "common/legends/asset/sheet/dnd-5e/dnd-5e-modifier-helper.ts";
import {
  Dnd5eActionTemplateSegmentRollModifier
} from "common/legends/asset/sheet/dnd-5e/dnd-5e-action-definition/segment/damage/dnd-5e-action-template-segment-roll-modifier.ts";

export function getActiveDiceRollExpressionModifiers(
  sheet: Optional<Sheet>,
  globalFeatures: Dnd5eFeature[],
  action: Optional<Dnd5eActionTemplate>,
  segment: Optional<Dnd5eActionTemplateSegment>
): Dnd5eActionTemplateSegmentRollExpressionModifier[] {
  const evaluateCondition = conditionEvaluator(sheet, globalFeatures, action);
  const getSheetModifiers = (): Dnd5eActionTemplateSegmentRollExpressionModifier[] => {
    return getActiveModifiers(sheet, globalFeatures).flatMap(modifier => modifier.type === "action::roll::expression" ? [modifier.data] : []);
  }
  const getActionModifiers = (modifier: Dnd5eActionTemplateModifier): Dnd5eActionTemplateSegmentRollExpressionModifier[] => {
    if (modifier.type === "action::condition") {
      return evaluateCondition(modifier.data.condition)
        ? modifier.data.modifiers.flatMap(getActionModifiers, [])
        : [];
    } else if (modifier.type === "action::roll::expression") {
      return [modifier.data];
    } else {
      return [];
    }
  };
  const getSegmentModifiers = (modifier: Dnd5eActionTemplateSegmentRollModifier): Dnd5eActionTemplateSegmentRollExpressionModifier[] => {
    if (modifier.type === "action::roll::condition") {
      return evaluateCondition(modifier.data.condition) ? modifier.data.modifiers.flatMap(getSegmentModifiers) : [];
    } else if (modifier.type === "action::roll::expression") {
      return [modifier.data];
    } else {
      return [];
    }
  };

  const effectModifiers = action?.type === "custom" ? [
    ...action.data.modifiers,
    ...action.data.effects.filter(effect => effect.enabled).flatMap(effect => effect.modifiers)
  ].flatMap(getActionModifiers) : [];
  const segmentModifiers = segment?.type === "roll" ? segment.data.modifiers.flatMap(getSegmentModifiers) : [];
  return [
    ...getSheetModifiers(),
    ...effectModifiers,
    ...segmentModifiers
  ];
}
import {Button, ButtonBar, IconButton, InputGroup, InputGroupLabel, useToggle} from "#lib/components/index.ts";
import {FaPaperPlane, FaPlus} from "react-icons/fa";
import {ListOperation, ListSignal, RichTextFn} from "common/types/index.ts";
import {Dnd5eFeature, Dnd5eFeatureOperation, Dnd5eFeatureSignal, dnd5eFeatureType, getFeatureID} from "common/legends/index.ts";
import React, {useMemo, useState} from "react";
import {ImportButton} from "#lib/components/button/import-button.tsx";
import {PanelHeader} from "#lib/components/panel-header.tsx";
import {ExpandOptions} from "#lib/components/expand-options.tsx";
import {twMerge} from "tailwind-merge";
import {useSelectedNodeID} from "../../dnd-5e-character/use-selected-sheet.ts";
import {useSendFeatureMessage} from "../../dnd-5e-character/dnd-5e-action/use-send-feature-message.ts";
import {toPromise} from "common/observable";
import {DragIndicator} from "#lib/components/tree-view/drag-indicator.tsx";
import {InputCheckbox} from "#lib/components/input/input-checkbox.tsx";
import {useRefValue} from "#lib/signal/index.ts";
import {Dnd5eFeatureEditor} from "./dnd-5e-feature-editor.tsx";
import {Dnd5eFeatureID, generateDnd5eFeatureID} from "common/legends/asset/sheet/dnd-5e/dnd-5e-feature/dnd-5e-feature-id.ts";
import {InputList, InputListItemProps, useDragListItem} from "#lib/components/list/input-list.tsx";
import {useFeatureByID} from "./use-feature-by-i-d.ts";
import {copyDnd5eFeature} from "common/legends/asset/sheet/dnd-5e/dnd-5e-feature/copy-dnd-5e-feature.ts";
import {usePresent} from "../../../../../common/use-optional-signal.ts";

export type InputDnd5eFeatureProps = InputListItemProps<Dnd5eFeature, Dnd5eFeatureOperation>;
export const InputDnd5eFeature = function InputDnd5eFeature({item, remove}: InputDnd5eFeatureProps) {
  const {enabled, title} = useMemo(() => Dnd5eFeatureSignal(item), [item]);
  const selectedNodeId = useSelectedNodeID();
  const sendMessage = useSendFeatureMessage();
  const onSend = async () => {
    const nodeID = await toPromise(selectedNodeId);
    await sendMessage(nodeID!, item.value.title, [], item.value.description);
  };

  const [dragHandlerRef, dragRefPreview] = useDragListItem("legends/feature", item, remove);
  const [expanded, toggleExpanded] = useToggle(false);
  return (<div role="list-item" ref={dragRefPreview} className={twMerge("flex flex-col")}>
    <ButtonBar>
      <IconButton ref={dragHandlerRef} title="Move"><DragIndicator /></IconButton>
      <InputCheckbox value={enabled} />
      <InputGroup className="flex-1 cursor-pointer" onClick={toggleExpanded}>
        <InputGroupLabel>{useRefValue(title)}</InputGroupLabel>
      </InputGroup>
      <IconButton variant="primary" title="Send to Chat" onClick={onSend}><FaPaperPlane /></IconButton>
    </ButtonBar>
    {expanded && <Dnd5eFeatureEditor value={item} onClose={toggleExpanded} remove={remove} />}
  </div>);
}

export type InputDnd5eFeaturesProps = {
  label?: string;
  value: ListSignal<Dnd5eFeature, Dnd5eFeatureOperation>;
};
export function InputDnd5eFeatures({value, label = "Features"}: InputDnd5eFeaturesProps) {
  const [editFeatureID, setEditFeatureID] = useState<Dnd5eFeatureID | undefined>(undefined);
  const onAddFeature = () => {
    const featureID = generateDnd5eFeatureID();
    value.apply(prev => ListOperation.insert(
      prev.length,
      {featureID, enabled: true, title: "", source: "", description: RichTextFn.EMPTY, actions: [], actionTemplates: [], modifiers: [], resources: [], variables: [], effects: []}
    )).then(_ => setEditFeatureID(featureID));
  };

  const editSignal = usePresent(useFeatureByID(value, editFeatureID));
  return (<div className="flex flex-col gap-1">
    <div className="flex flex-row gap-0.5">
      <PanelHeader className="flex-1">{label}</PanelHeader>
      <ButtonBar>
        <Button onClick={onAddFeature}><FaPlus /> New Feature</Button>
        <ExpandOptions>
          <ImportButton type={dnd5eFeatureType} onImport={(next) => {
            value.apply(prev => ListOperation.insert(prev.length, copyDnd5eFeature(next)));
          }}>Import Feature</ImportButton>
        </ExpandOptions>
      </ButtonBar>
    </div>

    <InputList<Dnd5eFeature, Dnd5eFeatureOperation>
      accept="legends/feature"
      items={value}
      itemKey={getFeatureID}
      copy={copyDnd5eFeature}
      ListItem={InputDnd5eFeature} />

    {editSignal && <Dnd5eFeatureEditor value={editSignal} onClose={() => setEditFeatureID(undefined)} />}

    <ButtonBar>
      <Button className="pl-2 flex-1" onClick={onAddFeature}><FaPlus /> New Feature</Button>
      <ImportButton type={dnd5eFeatureType} onImport={(next) => {
        value.apply(prev => ListOperation.insert(prev.length, copyDnd5eFeature(next)));
      }}>Import Feature</ImportButton>
    </ButtonBar>
  </div>);
}

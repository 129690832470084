import {KeyboardEvent, MouseEvent, RefObject, useCallback, useMemo, useRef} from "react";
import {applyAll, BooleanFn, ListOperation, Optional, Point, PointFn, Transform, TransformOperation, Tree, TreeOperation, ValueFn} from "common/types/index.ts";
import {combine, distinct, map} from "common/observable";
import {pipe} from "common/pipe";
import {Grid, Node, NodeId, NodeOperation} from "common/legends/index.ts";
import {Vector2f} from "#lib/math/index.ts";
import {WallGraph, WallGraphFn, WallGraphOperation, wallGraphType, WallNode, WallNodeOperation} from "common/legends/node/wall-node.ts";
import {Vector2} from "common/math/vector/vector2.ts";
import {useMouseEventHandlers, useWheelEventHandlers} from "../tool-selector/use-mouse-event-handlers.ts";
import {MutableRef, Ref} from "common/ref";
import {WallToolSelection, WallToolSelectMode, WallToolSelectModeOperation} from "../../../../../common/tool/wall/wall-tool-select-mode.ts";
import {WallToolData, WallToolDataOperation} from "../../../../../common/tool/wall/wall-tool-data.ts";
import {getWorldPositionFromScreenPosition} from "../../../../../viewport/tool/use-get-world-pos.ts";
import {getScreenPosition} from "../../../../../viewport/tool/use-get-screen-pos.ts";
import {WallToolCreateMode, WallToolCreateModeOperation} from "../../../../../common/tool/wall/wall-tool-create-mode.ts";
import {WallToolDestroyMode, WallToolDestroyModeOperation} from "../../../../../common/tool/wall/wall-tool-destroy-mode.ts";
import {WallToolSlicerMode, WallToolSlicerModeOperation} from "../../../../../common/tool/wall/wall-tool-slicer-mode.ts";
import {Tool, ToolOperation} from "../../../../../common/tool/tool.ts";
import {ToolHandlers} from "../../../../../../routes/game/use-tool-handlers.ts";
import {usePanHandlers} from "../../../../../viewport/tool/use-pan-handlers.ts";
import {useTouchHandlers} from "../../../../../viewport/tool/use-touch-handlers.ts";
import {useZoomHandlers} from "../../../../../viewport/tool/use-zoom-tool-handlers.ts";
import {cubicBezier, lerpBezier, perpendicularBezier} from "common/math/bezier/bezier.ts";
import {WallLineFn} from "../../../../../viewport/scene/scene-view.tsx";
import {getNodeTransform} from "../../../../../viewport/tool/use-node-pos-to-world-pos.ts";
import {useIsSpaceDown} from "#lib/components/use-is-space-down.ts";
import {useKeyEventHandlers} from "../tool-selector/use-key-event-handlers.ts";


function getWallSelections(graph: WallGraph, nodePos: Point, distance: number = 16): WallToolSelection[] {
  const controlPointSelections = graph.edges.flatMap((edge, edgeIndex): WallToolSelection[] => {
    const selections: WallToolSelection[] = [];
    const s = graph.vertices[edge.start].coordinate;
    const e = graph.vertices[edge.end].coordinate;
    const cp1 = Vector2.add(s, edge.controlPoint1);
    if (Vector2.distance(nodePos, cp1) < distance) {
      selections.push({type: "control-point", edgeIndex, controlPointIndex: 0});
    }
    const cp2 = Vector2.add(e, edge.controlPoint2);
    if (Vector2.distance(nodePos, cp2) < distance) {
      selections.push({type: "control-point", edgeIndex, controlPointIndex: 1});
    }
    return selections;
  });
  const vertexSelections = graph.vertices.flatMap((vertex, vertexIndex): WallToolSelection[] => {
    const dist = Vector2.distance(vertex.coordinate, nodePos);
    if (dist > distance) return [];
    return [{type: "vertex", vertexIndex: vertexIndex}];
  });
  const edgeSelections = graph.edges.flatMap((edge, edgeIndex): WallToolSelection[] => {
    const selections: WallToolSelection[] = [];
    const s = graph.vertices[edge.start].coordinate;
    const e = graph.vertices[edge.end].coordinate;
    const lines = WallLineFn.toLines(cubicBezier(s, e, edge.controlPoint1, edge.controlPoint2, edge.resolution), !edge.data.blockVisibilityLeft, !edge.data.blockVisibilityRight, edge.data.blockMovementLeft, edge.data.blockMovementRight, edge.data.tint);
    if (lines.some(line => {
      const t = Vector2.pointIntersect(line.start, line.end, nodePos);
      const intersection = Vector2.lerp(line.start, line.end, t);
      if (t < 0.001 || t > 0.999) return false;
      const dist = Vector2.distance(intersection, nodePos);
      return (dist <= distance);
    })) {
      selections.push({type: "edge", edgeIndex: edgeIndex});
    }
    return selections;
  });

  return [
    ...controlPointSelections,
    ...vertexSelections,
    ...edgeSelections
  ];
}

function useWallToolSelectMode(
  wallTool: MutableRef<Optional<WallToolData>, WallToolDataOperation[]>,
  canvasRef: RefObject<HTMLCanvasElement>,
  view: MutableRef<Transform, TransformOperation[]>,
  grid: Grid,
  nodesRef: Ref<Node[]>,
  wallNodeRef: MutableRef<Optional<WallNode>, WallNodeOperation[]>
) {
  const select = useMemo((): MutableRef<WallToolSelectMode, WallToolSelectModeOperation[]> => {
    const valueFn = (toolMode: Optional<WallToolData>): WallToolSelectMode => toolMode?.mode?.type === "select" ? toolMode.mode.data : undefined;
    return new MutableRef({
      value() {return valueFn(wallTool.value)},
      observe: pipe(wallTool.observe, map(value => valueFn(value)), distinct()),
      apply: fn => wallTool.apply(prev => {
        if (prev?.mode?.type !== "select") return [];
        return [{type: "update-mode", operations: [{type: "apply", operations: [{type: "select", operations: fn(prev.mode.data)}]}]}];
      }).then(valueFn)
    })
  }, [wallTool]);

  const onSelectMouseDown = useCallback((ev: MouseEvent): boolean => {
    if (ev.button !== 0) return true;
    if (wallTool.value?.mode?.type !== "select") return true;
    const wallNode = wallNodeRef.value;
    if (wallNode === undefined) return true;
    const graph = wallNode.graph;

    const worldPos = getWorldPositionFromScreenPosition(view.value, getScreenPosition(canvasRef.current!, [ev.clientX, ev.clientY]));
    const model = getNodeTransform(nodesRef.value, wallNode.id);
    const nodePos = Vector2.divideTransform(worldPos, model);

    const selection = select.value;
    if (selection?.type === "edge" || selection?.type === "control-point") {
      // check visibility indicators
      const wallNode = wallNodeRef.value;
      if (wallNode === undefined) return true;
      const graph = wallNode.graph;
      const edge = graph.edges[selection.edgeIndex];
      const a = graph.vertices[edge.start].coordinate;
      const b = graph.vertices[edge.end].coordinate;
      const mid = lerpBezier(a, b, edge.controlPoint1, edge.controlPoint2, edge.resolution, 0.5);
      const p = perpendicularBezier(a,b, edge.controlPoint1, edge.controlPoint2, edge.resolution, 0.5);
      const u = Vector2.normalize([p[1], -p[0]]);

      const v1 = Vector2.add(Vector2.add(mid, Vector2.multiply(u, 18)), Vector2.multiply(p, -48));
      if (Vector2.distance(v1, nodePos) < 16) {
        wallNodeRef.apply(_ => [{type: "update-graph", operations: [{
          type: "update-edge",
          index: selection.edgeIndex,
          operations: [{type: "update-data", operations: [{
            type: "update-block-visibility-left",
              operations: BooleanFn.set(edge.data.blockVisibilityLeft, !edge.data.blockVisibilityLeft)
            }]
          }]
        }]}]);
        select.apply(prev => ValueFn.set(prev, prev?.type === "control-point" ? ({type: "edge", edgeIndex: prev.edgeIndex}) : prev));
        return false;
      }
      const v2 = Vector2.add(Vector2.add(mid, Vector2.multiply(u, 18)), Vector2.multiply(p, 48));
      if (Vector2.distance(v2, nodePos) < 16) {
        wallNodeRef.apply(_ => [{type: "update-graph", operations: [{
          type: "update-edge",
          index: selection.edgeIndex,
          operations: [{type: "update-data", operations: [{
              type: "update-block-visibility-right",
              operations: BooleanFn.set(edge.data.blockVisibilityRight, !edge.data.blockVisibilityRight)
            }]
          }]
        }]}]);
        select.apply(prev => ValueFn.set(prev, prev?.type === "control-point" ? ({type: "edge", edgeIndex: prev.edgeIndex}) : prev));
        return false;
      }

      const m1 = Vector2.add(Vector2.add(mid, Vector2.multiply(u, -18)), Vector2.multiply(p, -48));
      if (Vector2.distance(m1, nodePos) < 16) {
        wallNodeRef.apply(_ => [{type: "update-graph", operations: [{
            type: "update-edge",
            index: selection.edgeIndex,
            operations: [{type: "update-data", operations: [{
              type: "update-block-movement-left",
              operations: BooleanFn.set(edge.data.blockMovementLeft, !edge.data.blockMovementLeft)
            }]
          }]
        }]}]);
        select.apply(prev => ValueFn.set(prev, prev?.type === "control-point" ? ({type: "edge", edgeIndex: prev.edgeIndex}) : prev));
        return false;
      }

      const m2 = Vector2.add(Vector2.add(mid, Vector2.multiply(u, -18)), Vector2.multiply(p, 48));
      if (Vector2.distance(m2, nodePos) < 16) {
        wallNodeRef.apply(_ => [{type: "update-graph", operations: [{
            type: "update-edge",
            index: selection.edgeIndex,
            operations: [{type: "update-data", operations: [{
              type: "update-block-movement-right",
              operations: BooleanFn.set(edge.data.blockMovementRight, !edge.data.blockMovementRight)
            }]
          }]
        }]}]);
        select.apply(prev => ValueFn.set(prev, prev?.type === "control-point" ? ({type: "edge", edgeIndex: prev.edgeIndex}) : prev));
        return false;
      }
    }

    select.apply(prev => {
      const selections = getWallSelections(graph, nodePos)
        .filter(selection => selection.type !== "control-point" || ((prev?.type === "edge" || prev?.type === "control-point") && selection.edgeIndex === prev.edgeIndex));
      if (selections.length > 0) {
        return ValueFn.set(prev, selections[0]);
      } else return ValueFn.set(prev, undefined);
    });
    return false;
  }, [canvasRef, wallNodeRef, view, select, nodesRef]);

  const onSelectVertexMouseUp = useCallback((ev: MouseEvent) => {
    const value = select.value;
    if (value?.type !== "vertex") return true;
    const wallNode = wallNodeRef.value;
    const model = getNodeTransform(nodesRef.value, wallNode?.id);
    const worldPos = getWorldPositionFromScreenPosition(view.value, getScreenPosition(canvasRef.current!, [ev.clientX, ev.clientY]));
    const nodePos = Vector2.divideTransform(worldPos, model);
    const snapPos = !ev.shiftKey ? Grid.snap(grid, nodePos) : nodePos;

    wallNodeRef.apply(prev => {
      if (!prev) return [];
      const vertex = prev.graph.vertices[value.vertexIndex];
      if (!vertex) return [];
      const operations = PointFn.set(vertex.coordinate, snapPos);
      if (operations.length === 0) return [];
      return [{type: "update-graph", operations: WallGraphFn.moveVertex(prev.graph, value.vertexIndex, snapPos)}];
    }).then(node => {
      if (!node) return;
      const vertexIndex = node.graph.vertices.findIndex(vertex => PointFn.equals(snapPos, vertex.coordinate));
      select.apply(prev => prev?.type === "vertex" && prev.vertexIndex === vertexIndex ? [] : ValueFn.set(prev, {type: "vertex", vertexIndex: vertexIndex}));
    });
    ev.preventDefault();
    return false;
  }, [select, view, canvasRef, wallNodeRef]);


  const onSelectControlPointMouseUp = useCallback((ev: MouseEvent) => {
    const selection = select.value;
    if (selection?.type !== "control-point") return true;
    const {edgeIndex, controlPointIndex} = selection;
    const worldPos = getWorldPositionFromScreenPosition(view.value, getScreenPosition(canvasRef.current!, [ev.clientX, ev.clientY]));
    const wallNode = wallNodeRef.value;
    const model = getNodeTransform(nodesRef.value, wallNode?.id);
    const nodePos = Vector2.divideTransform(worldPos, model);
    const snapPos = !ev.shiftKey ? Grid.snap(grid, nodePos) : nodePos;
    wallNodeRef.apply(prev => {
      if (!prev) return [];
      const edge = prev.graph.edges[edgeIndex];
      if (!edge) return [];
      const offset = prev.graph.vertices[controlPointIndex === 0 ? edge.start : edge.end].coordinate;
      const snapPosOffset = Vector2.subtract(snapPos, offset);
      const operations = PointFn.set(controlPointIndex === 0 ? edge.controlPoint1 : edge.controlPoint2, snapPosOffset);
      if (operations.length === 0) return [];
      return [{type: "update-graph", operations: [{
          type: "update-edge",
          index: edgeIndex,
          operations: [{
            type: controlPointIndex === 0 ? "update-control-point-1" : "update-control-point-2",
            operations
          }]
        }]
      }];
    });
    ev.preventDefault();
    return false;
  }, [select, view, canvasRef, wallNodeRef]);
  const onSelectMouseUp = useMouseEventHandlers(onSelectVertexMouseUp, onSelectControlPointMouseUp);


  const onSelectKeyDown = useCallback((ev: KeyboardEvent): boolean => {
    const value = select.value;
    if (ev.key === "Delete" || ev.key === "Backspace") {
      if (value?.type === "vertex") {
        wallNodeRef.apply(prev => !prev ? [] : [{type: "update-graph", operations: WallGraphFn.deleteVertex(prev.graph, value.vertexIndex)}]);
        select.apply(prev => ValueFn.set(prev, undefined));
        return false;
      } else if (value?.type === "edge") {
        wallNodeRef.apply(prev => !prev ? [] : [{type: "update-graph", operations: WallGraphFn.deleteEdge(prev.graph, value.edgeIndex)}]);
        select.apply(prev => ValueFn.set(prev, undefined));
        return false;
      }
    }
    return true;
  }, [select, wallNodeRef])

  return {
    onSelectMouseDown,
    onSelectMouseUp,
    onSelectKeyDown
  };
}

function useWallToolCreateMode(
  wallTool: MutableRef<Optional<WallToolData>, WallToolDataOperation[]>,
  canvasRef: RefObject<HTMLCanvasElement>,
  view: MutableRef<Transform, TransformOperation[]>,
  grid: Grid,
  nodesRef: Ref<Node[]>,
  wallNodeRef: MutableRef<Optional<WallNode>, WallNodeOperation[]>
) {
  const create = useMemo((): MutableRef<Optional<WallToolCreateMode>, WallToolCreateModeOperation[]> => {
    const valueFn = (toolMode: Optional<WallToolData>): Optional<WallToolCreateMode> => toolMode?.mode?.type === "create" ? toolMode.mode.data : undefined;
    return new MutableRef({
      value() {return valueFn(wallTool.value)},
      observe: pipe(wallTool.observe, map(value => valueFn(value)), distinct()),
      apply: fn => wallTool.apply(prev => {
        if (prev?.mode?.type !== "create") return [];
        return [{type: "update-mode", operations: [{type: "apply", operations: [{type: "create", operations: fn(prev.mode.data)}]}]}];
      }).then(valueFn)
    })
  }, [wallTool]);

  const completeSpline = useCallback(() => {
    wallNodeRef.apply(prev => {
      if (prev === undefined) return [];
      const spline = create.value?.spline;
      if (spline === undefined) return [];
      return [{
        type: "update-graph",
        operations: WallGraphFn.mergeOperations(prev.graph, WallGraphFn.fromSpline(spline))
      }];
    });
    create.apply(mode => {
      if (mode?.spline === undefined) return [];
      return [{type: "update-spline", operations: ValueFn.set(mode.spline, undefined)}];
    });
  }, [wallNodeRef, create]);


  const onCreateMouseDown = useCallback((ev: MouseEvent): boolean => {
    if (create.value === undefined) return true;
    if (ev.button === 0 && ev.detail === 2) {
      completeSpline();
      return false;
    } else if (ev.button === 0) {
      const worldPos = getWorldPositionFromScreenPosition(view.value, getScreenPosition(canvasRef.current!, [ev.clientX, ev.clientY]));
      const wallNode = wallNodeRef.value;
      const model = getNodeTransform(nodesRef.value, wallNode?.id);
      const nodePos = Vector2.divideTransform(worldPos, model);
      const snapPos = !ev.shiftKey ? Grid.snap(grid, nodePos) : nodePos;
      // create point in spline
      create.apply(prev => {
        if (prev === undefined) return [];
        if (prev.spline === undefined) {
          return [{type: "update-spline", operations: ValueFn.set(undefined, {
            start: snapPos,
            controlPoint1: [0, 0],
            controlPoint2: [0, 0],
            curves: [],
            closed: false
          })}] satisfies WallToolCreateModeOperation[];
        } else {
          return [{type: "update-spline", operations: ValueFn.apply([{
            type: "update-curves",
            operations: ListOperation.insert(prev.spline.curves.length, {
              end: snapPos,
              controlPoint1: [0, 0],
              controlPoint2: [0, 0]
            })
          }])}] satisfies WallToolCreateModeOperation[];
        }
      });
      isDragging.current = true;
      return false;
    }
    return true;
  }, [view, create, grid, completeSpline]);

  let isDragging = useRef(false);
  const onCreateMouseUp = useCallback((_: MouseEvent) => {
    isDragging.current = false;
    return true;
  }, [isDragging]);

  const onCreateMouseMove = useCallback((ev: MouseEvent) => {
    if (isDragging.current) return true;
    const worldPos = getWorldPositionFromScreenPosition(view.value, getScreenPosition(canvasRef.current!, [ev.clientX, ev.clientY]));
    const wallNode = wallNodeRef.value;
    const model = getNodeTransform(nodesRef.value, wallNode?.id);
    const nodePos = Vector2.divideTransform(worldPos, model);
    const snapPos = !ev.shiftKey ? Grid.snap(grid, nodePos) : nodePos;
    if (create.value?.spline) return true;
    create.apply(mode => {
      if (!mode?.spline) return [];
      if (mode.spline.curves.length > 0) {
        const curve = mode.spline.curves[mode.spline.curves.length - 1];
        const amount: Point = Vector2f.subtract(curve.end, snapPos);
        return [{type: "update-spline", operations: ValueFn.apply([{
            type: "update-curves",
            operations: ListOperation.apply(mode.spline.curves.length - 1, [
              {type: "update-control-point-1", operations: PointFn.set(mode.spline.controlPoint1, amount)},
              {type: "update-control-point-2", operations: PointFn.set(mode.spline.controlPoint2, Vector2f.multiply(amount, -1))},
            ])
          }])}];
      } else {
        const amount: Point = Vector2f.subtract(mode.spline.start, snapPos);
        return [{type: "update-spline", operations: ValueFn.apply([
          {type: "update-control-point-1", operations: PointFn.set(mode.spline.controlPoint1, amount)},
          {type: "update-control-point-2", operations: PointFn.set(mode.spline.controlPoint2, Vector2f.multiply(amount, -1))},
        ])}];
      }
    });
    return false;
  }, [view, grid, isDragging, create]);

  const onCreateKeyDown = useCallback((ev: KeyboardEvent): boolean => {
    if (create.value === undefined) return true;
    if (ev.key === "Escape") {
      if (!create.value?.spline) return true;
      create.apply(mode => {
        if (!mode?.spline) return [];
        return [{type: "update-spline", operations: ValueFn.set(mode.spline, undefined)}]
      });
      ev.preventDefault();
      return false;
    } else if (ev.key.toLowerCase() === "z" && ev.ctrlKey) {
      if (!create.value?.spline) return true;
      create.apply(mode => {
        if (!mode?.spline) return [];
        if (mode.spline.curves.length > 0) {
          return [{type: "update-spline", operations: ValueFn.apply([{
              type: "update-curves",
              operations: ListOperation.delete(mode.spline.curves.length - 1, mode.spline.curves[mode.spline.curves.length - 1])
            }])}]
        } else {
          return [{type: "update-spline", operations: ValueFn.set(mode.spline, undefined)}]
        }
      });
      return false;
    } else if (ev.key === "Enter") {
      completeSpline();
      return false;
    } else {
      return true;
    }
  }, [create, completeSpline]);

  return {
    onCreateMouseDown,
    onCreateMouseMove,
    onCreateMouseUp,
    onCreateKeyDown
  };
}

function useWallToolDestroyMode(
  wallTool: MutableRef<Optional<WallToolData>, WallToolDataOperation[]>,
  canvasRef: RefObject<HTMLCanvasElement>,
  view: MutableRef<Transform, TransformOperation[]>,
  grid: Grid,
  nodesRef: Ref<Node[]>,
  wallNodeRef: MutableRef<Optional<WallNode>, WallNodeOperation[]>
) {
  const destroy = useMemo((): MutableRef<Optional<WallToolDestroyMode>, WallToolDestroyModeOperation[]> => {
    const valueFn = (toolMode: Optional<WallToolData>): Optional<WallToolDestroyMode> => toolMode?.mode?.type === "destroy" ? toolMode.mode.data : undefined;
    return new MutableRef({
      value() {return valueFn(wallTool.value)},
      observe: pipe(wallTool.observe, map(value => valueFn(value)), distinct()),
      apply: fn => wallTool.apply(prev => {
        if (prev?.mode?.type !== "destroy") return [];
        return [{type: "update-mode", operations: [{type: "apply", operations: [{type: "destroy", operations: fn(prev.mode.data)}]}]}];
      }).then(valueFn)
    })
  }, [wallTool]);

  const destroySpline = useCallback(() => {
    wallNodeRef.apply(prev => {
      if (!prev) return [];
      const spline = destroy.value?.spline;
      if (spline === undefined) return [];
      const graph = prev.graph.vertices.reduce(
        (graph, vertex) => applyAll(wallGraphType, graph, WallGraphFn.insertVertex(graph, vertex)),
        WallGraphFn.fromSpline(spline)
      );

      const ops: WallGraphOperation[] = [];
      let out = prev.graph;
      for (const vertex of graph.vertices) {
        const insertVertexOps = WallGraphFn.insertVertex(out, vertex);
        out = applyAll(wallGraphType, out, insertVertexOps);
        ops.push(...insertVertexOps);
      }
      for (const edge of graph.edges) {
        const start = graph.vertices[edge.start].coordinate;
        const end = graph.vertices[edge.end].coordinate;
        const edgeIndex = out.edges.findIndex(b => {
          const bStart = out.vertices[b.start].coordinate;
          const bEnd = out.vertices[b.end].coordinate;
          return (PointFn.equals(bStart, start) && PointFn.equals(bEnd, end)) || (PointFn.equals(bStart, end) && PointFn.equals(bEnd, start));
        })
        if (edgeIndex !== -1) {
          const deleteEdgeOps = WallGraphFn.deleteEdge(out, edgeIndex);
          out = applyAll(wallGraphType, out, deleteEdgeOps);
          ops.push(...deleteEdgeOps);
        }
      }
      ops.push(...WallGraphFn.deleteStrayVertices(out));
      return [{
        type: "update-graph",
        operations: ops
      }];
    });
    destroy.apply(mode => {
      if (mode?.spline === undefined) return [];
      return [{type: "update-spline", operations: ValueFn.set(mode.spline, undefined)}];
    });
  }, [wallNodeRef, destroy]);


  const onDestroyMouseDown = useCallback((ev: MouseEvent): boolean => {
    if (destroy.value === undefined) return true;
    if (ev.button === 0 && ev.detail === 2) {
      destroySpline();
      return false;
    } else if (ev.button === 0) {
      const worldPos = getWorldPositionFromScreenPosition(view.value, getScreenPosition(canvasRef.current!, [ev.clientX, ev.clientY]));
      const wallNode = wallNodeRef.value;
      const model = getNodeTransform(nodesRef.value, wallNode?.id);
      const nodePos = Vector2.divideTransform(worldPos, model);
      const snapPos = !ev.shiftKey ? Grid.snap(grid, nodePos) : nodePos;
      // destroy point in spline
      destroy.apply(prev => {
        if (prev === undefined) return [];
        if (prev.spline === undefined) {
          return [{type: "update-spline", operations: ValueFn.set(undefined, {
              start: snapPos,
              controlPoint1: [0, 0],
              controlPoint2: [0, 0],
              curves: [],
              closed: false
            })}] satisfies WallToolDestroyModeOperation[];
        } else {
          return [{type: "update-spline", operations: ValueFn.apply([{
              type: "update-curves",
              operations: ListOperation.insert(prev.spline.curves.length, {
                end: snapPos,
                controlPoint1: [0, 0],
                controlPoint2: [0, 0]
              })
            }])}] satisfies WallToolDestroyModeOperation[];
        }
      });
      isDragging.current = true;
      return false;
    }
    return true;
  }, [view, destroy, grid, destroySpline, nodesRef, wallNodeRef]);

  let isDragging = useRef(false);
  const onDestroyMouseUp = useCallback((_: MouseEvent) => {
    isDragging.current = false;
    return true;
  }, [isDragging]);

  const onDestroyMouseMove = useCallback((ev: MouseEvent) => {
    if (isDragging.current) return true;
    const worldPos = getWorldPositionFromScreenPosition(view.value, getScreenPosition(canvasRef.current!, [ev.clientX, ev.clientY]));
    const wallNode = wallNodeRef.value;
    const model = getNodeTransform(nodesRef.value, wallNode?.id);
    const nodePos = Vector2.divideTransform(worldPos, model);
    const snapPos = !ev.shiftKey ? Grid.snap(grid, nodePos) : nodePos;
    if (destroy.value?.spline) return true;
    destroy.apply(mode => {
      if (!mode?.spline) return [];
      if (mode.spline.curves.length > 0) {
        const curve = mode.spline.curves[mode.spline.curves.length - 1];
        const amount: Point = Vector2f.subtract(curve.end, snapPos);
        return [{type: "update-spline", operations: ValueFn.apply([{
            type: "update-curves",
            operations: ListOperation.apply(mode.spline.curves.length - 1, [
              {type: "update-control-point-1", operations: PointFn.set(mode.spline.controlPoint1, amount)},
              {type: "update-control-point-2", operations: PointFn.set(mode.spline.controlPoint2, Vector2f.multiply(amount, -1))},
            ])
          }])}];
      } else {
        const amount: Point = Vector2f.subtract(mode.spline.start, snapPos);
        return [{type: "update-spline", operations: ValueFn.apply([
            {type: "update-control-point-1", operations: PointFn.set(mode.spline.controlPoint1, amount)},
            {type: "update-control-point-2", operations: PointFn.set(mode.spline.controlPoint2, Vector2f.multiply(amount, -1))},
          ])}];
      }
    });
    return false;
  }, [view, grid, isDragging, destroy, nodesRef, wallNodeRef]);

  const onDestroyKeyDown = useCallback((ev: KeyboardEvent): boolean => {
    if (destroy.value === undefined) return true;
    if (ev.key === "Escape") {
      if (!destroy.value?.spline) return true;
      destroy.apply(mode => {
        if (!mode?.spline) return [];
        return [{type: "update-spline", operations: ValueFn.set(mode.spline, undefined)}]
      });
      ev.preventDefault();
      return false;
    } else if (ev.key.toLowerCase() === "z" && ev.ctrlKey) {
      if (!destroy.value?.spline) return true;
      destroy.apply(mode => {
        if (!mode?.spline) return [];
        if (mode.spline.curves.length > 0) {
          return [{type: "update-spline", operations: ValueFn.apply([{
              type: "update-curves",
              operations: ListOperation.delete(mode.spline.curves.length - 1, mode.spline.curves[mode.spline.curves.length - 1])
            }])}]
        } else {
          return [{type: "update-spline", operations: ValueFn.set(mode.spline, undefined)}]
        }
      });
      return false;
    } else if (ev.key === "Enter") {
      destroySpline();
      return false;
    } else {
      return true;
    }
  }, [destroy, destroySpline]);

  return {
    onDestroyMouseDown,
    onDestroyMouseMove,
    onDestroyMouseUp,
    onDestroyKeyDown
  };
}



function useWallToolSlicerMode(
  wallTool: MutableRef<Optional<WallToolData>, WallToolDataOperation[]>,
  canvasRef: RefObject<HTMLCanvasElement>,
  view: MutableRef<Transform, TransformOperation[]>,
  grid: Grid,
  nodesRef: Ref<Node[]>,
  wallNodeRef: MutableRef<Optional<WallNode>, WallNodeOperation[]>
) {
  const slicer = useMemo((): MutableRef<Optional<WallToolSlicerMode>, WallToolSlicerModeOperation[]> => {
    const valueFn = (toolMode: Optional<WallToolData>): Optional<WallToolSlicerMode> => toolMode?.mode?.type === "slicer" ? toolMode.mode.data : undefined;
    return new MutableRef({
      value() {return valueFn(wallTool.value)},
      observe: pipe(wallTool.observe, map(value => valueFn(value)), distinct()),
      apply: fn => wallTool.apply(prev => {
        if (prev?.mode?.type !== "slicer") return [];
        return [{type: "update-mode", operations: [{type: "apply", operations: [{type: "slicer", operations: fn(prev.mode.data)}]}]}];
      }).then(valueFn)
    })
  }, [wallTool]);

  const sliceSpline = useCallback(() => {
    wallNodeRef.apply(prev => {
      if (!prev) return [];
      const spline = slicer.value?.spline;
      if (spline === undefined) return [];
      const graph = prev.graph.vertices.reduce(
        (graph, vertex) => applyAll(wallGraphType, graph, WallGraphFn.insertVertex(graph, vertex)),
        WallGraphFn.fromSpline(spline)
      );

      const ops: WallGraphOperation[] = [];
      let out = prev.graph;
      for (const vertex of graph.vertices) {
        const insertVertexOps = WallGraphFn.insertVertex(out, vertex);
        out = applyAll(wallGraphType, out, insertVertexOps);
        ops.push(...insertVertexOps);
      }
      for (const edge of graph.edges) {
        const start = out.vertices.findIndex(v => PointFn.equals(v.coordinate, graph.vertices[edge.start].coordinate));
        const end = out.vertices.findIndex(v => PointFn.equals(v.coordinate, graph.vertices[edge.end].coordinate));
        const sliceEdges = WallGraphFn.sliceEdge(out, {...edge, start, end});
        out = applyAll(wallGraphType, out, sliceEdges);
        ops.push(...sliceEdges);
      }
      ops.push(...WallGraphFn.deleteStrayVertices(out));
      return [{
        type: "update-graph",
        operations: ops
      }];
    });
    slicer.apply(mode => {
      if (mode?.spline === undefined) return [];
      return [{type: "update-spline", operations: ValueFn.set(mode.spline, undefined)}];
    });
  }, [wallNodeRef, slicer]);


  const onSlicerMouseDown = useCallback((ev: MouseEvent): boolean => {
    if (slicer.value === undefined) return true;
    if (ev.button === 0 && ev.detail === 2) {
      sliceSpline();
      return false;
    } else if (ev.button === 0) {
      const wallNode = wallNodeRef.value;
      const worldPos = getWorldPositionFromScreenPosition(view.value, getScreenPosition(canvasRef.current!, [ev.clientX, ev.clientY]));
      const model = getNodeTransform(nodesRef.value, wallNode?.id);
      const nodePos = Vector2.divideTransform(worldPos, model);
      const snapPos = !ev.shiftKey ? Grid.snap(grid, nodePos) : nodePos;
      // slicer point in spline
      slicer.apply(prev => {
        if (prev === undefined) return [];
        if (prev.spline === undefined) {
          return [{type: "update-spline", operations: ValueFn.set(undefined, {
              start: snapPos,
              controlPoint1: [0, 0],
              controlPoint2: [0, 0],
              curves: [],
              closed: false
            })}] satisfies WallToolSlicerModeOperation[];
        } else {
          return [{type: "update-spline", operations: ValueFn.apply([{
              type: "update-curves",
              operations: ListOperation.insert(prev.spline.curves.length, {
                end: snapPos,
                controlPoint1: [0, 0],
                controlPoint2: [0, 0]
              })
            }])}] satisfies WallToolSlicerModeOperation[];
        }
      });
      isDragging.current = true;
      return false;
    }
    return true;
  }, [view, slicer, grid, sliceSpline]);

  let isDragging = useRef(false);
  const onSlicerMouseUp = useCallback((_: MouseEvent) => {
    isDragging.current = false;
    return true;
  }, [isDragging]);

  const onSlicerMouseMove = useCallback((ev: MouseEvent) => {
    if (isDragging.current) return true;
    const worldPos = getWorldPositionFromScreenPosition(view.value, getScreenPosition(canvasRef.current!, [ev.clientX, ev.clientY]));
    const snapPos = !ev.shiftKey ? Grid.snap(grid, worldPos) : worldPos;
    if (slicer.value?.spline) return true;
    slicer.apply(mode => {
      if (!mode?.spline) return [];
      if (mode.spline.curves.length > 0) {
        const curve = mode.spline.curves[mode.spline.curves.length - 1];
        const amount: Point = Vector2f.subtract(curve.end, snapPos);
        return [{type: "update-spline", operations: ValueFn.apply([{
            type: "update-curves",
            operations: ListOperation.apply(mode.spline.curves.length - 1, [
              {type: "update-control-point-1", operations: PointFn.set(mode.spline.controlPoint1, amount)},
              {type: "update-control-point-2", operations: PointFn.set(mode.spline.controlPoint2, Vector2f.multiply(amount, -1))},
            ])
          }])}];
      } else {
        const amount: Point = Vector2f.subtract(mode.spline.start, snapPos);
        return [{type: "update-spline", operations: ValueFn.apply([
            {type: "update-control-point-1", operations: PointFn.set(mode.spline.controlPoint1, amount)},
            {type: "update-control-point-2", operations: PointFn.set(mode.spline.controlPoint2, Vector2f.multiply(amount, -1))},
          ])}];
      }
    });
    return false;
  }, [view, grid, isDragging, slicer]);

  const onSlicerKeyDown = useCallback((ev: KeyboardEvent): boolean => {
    if (slicer.value === undefined) return true;
    if (ev.key === "Escape") {
      if (!slicer.value?.spline) return true;
      slicer.apply(mode => {
        if (!mode?.spline) return [];
        return [{type: "update-spline", operations: ValueFn.set(mode.spline, undefined)}]
      });
      ev.preventDefault();
      return false;
    } else if (ev.key.toLowerCase() === "z" && ev.ctrlKey) {
      if (!slicer.value?.spline) return true;
      slicer.apply(mode => {
        if (!mode?.spline) return [];
        if (mode.spline.curves.length > 0) {
          return [{type: "update-spline", operations: ValueFn.apply([{
              type: "update-curves",
              operations: ListOperation.delete(mode.spline.curves.length - 1, mode.spline.curves[mode.spline.curves.length - 1])
            }])}]
        } else {
          return [{type: "update-spline", operations: ValueFn.set(mode.spline, undefined)}]
        }
      });
      return false;
    } else if (ev.key === "Enter") {
      sliceSpline();
      return false;
    } else {
      return true;
    }
  }, [slicer, sliceSpline]);

  return {
    onSlicerMouseDown,
    onSlicerMouseMove,
    onSlicerMouseUp,
    onSlicerKeyDown
  };
}

export function useWallToolHandlers(
  tool: MutableRef<Tool, ToolOperation[]>,
  canvasRef: RefObject<HTMLCanvasElement>,
  view: MutableRef<Transform, TransformOperation[]>,
  grid: Grid,
  nodesRef: MutableRef<Node[], TreeOperation<Node, NodeOperation>[]>
): ToolHandlers {
  const wallTool = useMemo((): MutableRef<Optional<WallToolData>, WallToolDataOperation[]> => {
    const valueFn = (tool: Optional<Tool>): Optional<WallToolData> => tool?.type === "wall" ? tool.data : undefined;
    return new MutableRef({
      value() {return valueFn(tool.value)},
      observe: pipe(tool.observe, map(valueFn), distinct()),
      apply: fn => tool.apply(prev => {
        if (prev?.type !== "wall") return [];
        const operations = fn(prev.data);
        if (operations.length === 0) return [];
        return ValueFn.apply([{type: "wall", operations}]);
      }).then(valueFn)
    })
  }, [tool]);
  const wallNode = useMemo((): MutableRef<Optional<WallNode>, WallNodeOperation[]> => {
    const valueFn = (nodes: Node[], nodeID: Optional<NodeId>): Optional<WallNode> => {
      const node = nodeID ? Tree.getItemById(nodes, nodeID) : undefined;
      if (node?.type === "wall") return node?.data;
      return undefined;
    }
    return new MutableRef({
      value(): Optional<WallNode> {return valueFn(nodesRef.value, wallTool.value?.nodeID)},
      observe: pipe(
        combine(nodesRef.observe, wallTool.observe),
        map(([nodes, wallTool]) => valueFn(nodes, wallTool?.nodeID)),
        distinct()
      ),
      apply: fn => {
        const nodeID = wallTool.value?.nodeID;
        return nodesRef.apply(prev => {
          const nodePath = Tree.getPath(prev, node => node.data.id === nodeID);
          if (nodePath === undefined) return [];
          const node = Tree.getNode(prev, nodePath);
          if (node?.type !== "wall") return [];
          const operations = fn(node.data);
          if (operations.length === 0) return [];
          return TreeOperation.apply(nodePath, [{type: "wall", operations}]);
        }).then(nodes => valueFn(nodes, nodeID))
      }
    });
  }, [nodesRef, wallTool]);

  const spaceDownRef = useIsSpaceDown();
  const {onPanMouseDown, onPanMouseMove, onPanMouseUp, onPanMouseEnter, onPanWheel, onPanContextMenu, onPanKeyDown, onPanKeyUp} = usePanHandlers(canvasRef, view,
    useCallback(ev => (ev.buttons === 1 && spaceDownRef.value) || ev.buttons === 2 || ev.buttons === 4, [spaceDownRef])
  );
  const {onTouchStart, onTouchMove, onTouchEnd} = useTouchHandlers(canvasRef, view);
  const {onZoomWheel, onZoomKeyDown} = useZoomHandlers(canvasRef, view);
  const {onSelectMouseDown, onSelectMouseUp, onSelectKeyDown} = useWallToolSelectMode(wallTool, canvasRef, view, grid, nodesRef, wallNode);
  const {onCreateMouseDown, onCreateMouseUp, onCreateKeyDown, onCreateMouseMove} = useWallToolCreateMode(wallTool, canvasRef, view, grid, nodesRef, wallNode);
  const {onDestroyMouseDown, onDestroyMouseUp, onDestroyKeyDown, onDestroyMouseMove} = useWallToolDestroyMode(wallTool, canvasRef, view, grid, nodesRef, wallNode);
  const {onSlicerMouseDown, onSlicerMouseUp, onSlicerKeyDown, onSlicerMouseMove} = useWallToolSlicerMode(wallTool, canvasRef, view, grid, nodesRef, wallNode);

  const onWheel = useWheelEventHandlers(onPanWheel, onZoomWheel);
  const onMouseDown = useMouseEventHandlers(onPanMouseDown,onCreateMouseDown, onDestroyMouseDown, onSlicerMouseDown,onSelectMouseDown);
  const onMouseUp = useMouseEventHandlers(onPanMouseUp, onCreateMouseUp, onDestroyMouseUp, onSlicerMouseUp, onSelectMouseUp);
  const onMouseMove = useMouseEventHandlers(onPanMouseMove,onCreateMouseMove,onDestroyMouseMove,onSlicerMouseMove);
  const onKeyDown = useKeyEventHandlers(onPanKeyDown, onZoomKeyDown, onCreateKeyDown, onSelectKeyDown, onDestroyKeyDown, onSlicerKeyDown);
  const onKeyUp = useKeyEventHandlers(onPanKeyUp);

  return {
    onMouseDown,
    onMouseMove,
    onMouseUp,
    onMouseEnter: onPanMouseEnter,
    onWheel,
    onKeyDown,
    onContextMenu: onPanContextMenu,
    onKeyUp,
    onDrop: useCallback(() => {}, []),
    onTouchStart,
    onTouchMove,
    onTouchEnd
  };
}

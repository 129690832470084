import {
  BooleanOperation,
  booleanType,
  ListOperation,
  ListType,
  ObjectType,
  Point,
  PointFn,
  PointOperation,
  pointType,
  PropertyRef,
  Type,
  ZodListOperation
} from "#common/types/index.ts";
import {z} from "zod";
import {MutableRef} from "#common/ref";
import {Vector2} from "../../../math/vector/vector2.ts";
import {cubicBezier} from "../../../math/bezier/bezier.ts";
import {Size} from "../size/index.ts";

export const Curve = z.object({
  end: Point,
  controlPoint1: Point,
  controlPoint2: Point
});
export type Curve = z.infer<typeof Curve>;
export const CurveOperation = z.discriminatedUnion("type", [
  z.object({type: z.literal("update-end"), operations: z.array(PointOperation)}),
  z.object({type: z.literal("update-control-point-1"), operations: z.array(PointOperation)}),
  z.object({type: z.literal("update-control-point-2"), operations: z.array(PointOperation)}),
]);
export type CurveOperation = z.infer<typeof CurveOperation>;
export const curveType: Type<Curve, CurveOperation> = new ObjectType({
  end: pointType,
  controlPoint1: pointType,
  controlPoint2: pointType
});
export function CurveSignals(value: MutableRef<Curve, CurveOperation[]>) {
  return ({
    end: PropertyRef<Curve, CurveOperation, Point, PointOperation>(
      value => value.end,
      operations => [{type: "update-end", operations}]
    )(value),
    controlPoint1: PropertyRef<Curve, CurveOperation, Point, PointOperation>(
      value => value.controlPoint1,
      operations => [{type: "update-control-point-1", operations}]
    )(value),
    controlPoint2: PropertyRef<Curve, CurveOperation, Point, PointOperation>(
      value => value.controlPoint2,
      operations => [{type: "update-control-point-2", operations}]
    )(value)
  })
}


export const Spline = z.object({
  start: Point,
  controlPoint1: Point,
  controlPoint2: Point,
  closed: z.boolean(),
  curves: z.array(Curve)
});
export type Spline = z.infer<typeof Spline>;
export const SplineOperation = z.discriminatedUnion("type", [
  z.object({type: z.literal("update-start"), operations: z.array(PointOperation)}),
  z.object({type: z.literal("update-control-point-1"), operations: z.array(PointOperation)}),
  z.object({type: z.literal("update-control-point-2"), operations: z.array(PointOperation)}),
  z.object({type: z.literal("update-closed"), operations: z.array(BooleanOperation)}),
  z.object({type: z.literal("update-curves"), operations: z.array(ZodListOperation(Curve, CurveOperation))}),
]);
export type SplineOperation = z.infer<typeof SplineOperation>;
export const splineType: Type<Spline, SplineOperation> = new ObjectType({
  start: pointType,
  controlPoint1: pointType,
  controlPoint2: pointType,
  closed: booleanType,
  curves: new ListType(curveType)
}, (v: any) => {
  if (v.controlPoint1 === undefined) v.controlPoint1 = [0, 0];
  if (v.controlPoint2 === undefined) v.controlPoint2 = [0, 0];
  if (v.closed === undefined) v.closed = true;
  return v;
});

export const SplineFn = {
  map: (spline: Spline, fn: (point: Vector2) => Vector2): Spline => {
    return ({
      ...spline,
      start: fn(spline.start),
      curves: spline.curves.map(curve => ({...curve, end: fn(curve.end)}))
    });
  },

  deleteVertex: (spline: Spline, vertexIndex: number): SplineOperation[] => {
    if (vertexIndex === 0) {
      if (spline.curves.length > 0) {
        return [
          {type: "update-start", operations: PointFn.set(spline.start, spline.curves[0].end)},
          {type: "update-control-point-1", operations: PointFn.set(spline.controlPoint1, spline.curves[0].controlPoint1)},
          {type: "update-control-point-1", operations: PointFn.set(spline.controlPoint2, spline.curves[0].controlPoint2)},
          {type: "update-curves", operations: ListOperation.delete(0, spline.curves[0])
        }];
      } else {
        return [];
      }
    } else {
      return [
        {type: "update-curves", operations: ListOperation.delete(vertexIndex - 1, spline.curves[vertexIndex - 1])}
      ];
    }
  },

  getLines: (spline: Spline, n: number = 8): Point[]  => {
    let lines: Point[] = [];
    let startCurve: Curve = {
      end: spline.start,
      controlPoint1: spline.controlPoint1,
      controlPoint2: spline.controlPoint2
    };
    if (spline.curves.length === 0) return cubicBezier(startCurve.end, startCurve.end, startCurve.controlPoint1, startCurve.controlPoint2, n);

    let lastCurve = startCurve;
    for (let i = 0; i < spline.curves.length; i ++) {
      const curve = spline.curves[i];
      lines.push(...cubicBezier(lastCurve.end, curve.end, lastCurve.controlPoint2, curve.controlPoint1, n));
      lastCurve = curve;
    }
    if (spline.closed) lines.push(...cubicBezier(lastCurve.end, startCurve.end, lastCurve.controlPoint2, startCurve.controlPoint1, n));
    return lines;
  },

  getSplineOrigin(spline: Spline, n: number = 8): Point {
    const points = SplineFn.getLines(spline, n);
    return [
      Math.min(...points.map(p => p[0])),
      Math.min(...points.map(p => p[1]))
    ];
  },
  getSplineSize(spline: Spline, n: number = 8): Size {
    const points = SplineFn.getLines(spline, n);
    const minX = Math.min(...points.map(p => p[0]));
    const maxX = Math.max(...points.map(p => p[0]));
    const minY = Math.min(...points.map(p => p[1]));
    const maxY = Math.max(...points.map(p => p[1]));
    return [
      maxX - minX,
      maxY - minY
    ];
  }
};

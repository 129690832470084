import {Dnd5eAttribute} from "#common/legends/index.ts";
import {z} from "zod";
import {Optional} from "../../../../../types/index.ts";

export const Dnd5eSkill = z.union([
  z.literal("acrobatics"),
  z.literal("acrobatics"),
  z.literal("animal handling"),
  z.literal("arcana"),
  z.literal("athletics"),
  z.literal("deception"),
  z.literal("history"),
  z.literal("insight"),
  z.literal("intimidation"),
  z.literal("investigation"),
  z.literal("medicine"),
  z.literal("nature"),
  z.literal("perception"),
  z.literal("performance"),
  z.literal("persuasion"),
  z.literal("religion"),
  z.literal("sleight of hand"),
  z.literal("stealth"),
  z.literal("survival")
]);
export type Dnd5eSkill = z.infer<typeof Dnd5eSkill>;
export const DND_5E_SKILLS = [
  "acrobatics",
  "animal handling",
  "arcana",
  "athletics",
  "deception",
  "history",
  "insight",
  "intimidation",
  "investigation",
  "medicine",
  "nature",
  "perception",
  "performance",
  "persuasion",
  "religion",
  "sleight of hand",
  "stealth",
  "survival"
] as const satisfies readonly Dnd5eSkill[];

export const DND_5E_SKILL_ATTRIBUTE: Record<Dnd5eSkill, Dnd5eAttribute> = {
  "acrobatics": "dex",
  "animal handling": "wis",
  "arcana": "int",
  "athletics": "str",
  "deception": "cha",
  "history": "int",
  "insight": "wis",
  "intimidation": "cha",
  "investigation": "int",
  "medicine": "wis",
  "nature": "int",
  "perception": "wis",
  "performance": "cha",
  "persuasion": "cha",
  "religion": "int",
  "sleight of hand": "dex",
  "stealth": "dex",
  "survival": "wis"
} as const;

export function isDnd5eSkill(value: Optional<string>): value is Dnd5eSkill {
  if (value === undefined) return false;
  return DND_5E_SKILLS.includes(value as Dnd5eSkill);
}


export const DND_5E_SKILL_TITLE: {[skill in Dnd5eSkill]: string} = {
  "acrobatics": "Acrobatics",
  "animal handling": "Animal Handling",
  "arcana": "Arcana",
  "athletics": "Athletics",
  "deception": "Deception",
  "history": "History",
  "insight": "Insight",
  "intimidation": "Intimidation",
  "investigation": "Investigation",
  "medicine": "Medicine",
  "nature": "Nature",
  "perception": "Perception",
  "performance": "Performance",
  "persuasion": "Persuasion",
  "religion": "Religion",
  "sleight of hand": "Sleight of Hand",
  "stealth": "Stealth",
  "survival": "Survival",
};

import {InputGroup, InputNumber} from "#lib/components/index.ts";
import {Point, PointOperation, PointSignals} from "common/types/index.ts";
import {useMemo} from "react";
import {MutableRef} from "common/ref";
import {Field, FieldLabel} from "#lib/components/panel-header.tsx";
import {InputGroupIcon} from "#lib/components/input/input-group-icon.tsx";

export type OriginFieldProps = {
  valueRef: MutableRef<Point, PointOperation[]>;
};

export function OriginField({valueRef}: OriginFieldProps) {
  const {x, y} = useMemo(() => PointSignals(valueRef), [valueRef]);

  return (
    <Field>
      <FieldLabel className="px-4 py-1 text-sm font-bold">
        Origin
      </FieldLabel>
      <div className="flex flex-row gap-0.5 mx-2 rounded-md overflow-hidden">
        <InputGroup size="medium" className="flex-1">
          <InputGroupIcon>X</InputGroupIcon>
          <InputNumber size="small" value={x}/>
        </InputGroup>
        <InputGroup size="medium" className="flex-1">
          <InputGroupIcon>Y</InputGroupIcon>
          <InputNumber size="small" value={y}/>
        </InputGroup>
      </div>
    </Field>
  );
}
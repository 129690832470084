import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCircleDot, faGlobe, faImage, faVectorPolygon} from "@awesome.me/kit-c9bc6845cd/icons/classic/solid";
import {Button, ButtonBar} from "#lib/components/index.ts";
import {MutableRef} from "common/ref";
import {Color, Optional, ValueFn, ValueOperation} from "common/types/generic/index.ts";
import {LightShape, LightShapeOperation} from "common/legends/node/light/light-shape.ts";
import {useComputedValue} from "#lib/signal/index.ts";

export function LightShapeButtonBar({valueRef}: {
  valueRef: MutableRef<Optional<LightShape>, ValueOperation<Optional<LightShape>, LightShapeOperation>[]>
}) {
  const type = useComputedValue(valueRef, value => value?.type);
  const setGlobal = () => {
    valueRef.apply(prev => ValueFn.set(prev, {type: "global", data: {
      color: Color.WHITE,
      intensity: 1
    }}));
  }
  const setSpotlight = () => {
    valueRef.apply(prev => ValueFn.set(prev, {type: "spotlight", data: {
      angle: 360,
      falloffAngle: 0,
      radius: 64,
      falloffStrength: 2.71,
      falloff: 32,
      color: Color.WHITE,
      intensity: 1
    }}));
  }
  const setFreeform = () => {
    valueRef.apply(prev => ValueFn.set(prev, {type: "freeform", data: {
      areas: [],
      falloffStrength: 2.71,
      falloff: 32,
      color: Color.WHITE,
      intensity: 1
    }}));
  }
  const setSprite = () => {
    valueRef.apply(prev => ValueFn.set(prev, {type: "sprite", data: {
      file: undefined,
      color: Color.WHITE,
      size: [64, 64],
      intensity: 1
    }}));
  }

  return (
    <ButtonBar>
      <Button variant={type === "global" ? "primary" : undefined} className="flex-1" onClick={type !== "global" ? setGlobal : undefined}>
        <FontAwesomeIcon icon={faGlobe} /> Global
      </Button>
      <Button variant={type === "spotlight" ? "primary" : undefined} className="flex-1" onClick={type !== "spotlight" ? setSpotlight : undefined}>
        <FontAwesomeIcon icon={faCircleDot} /> Spotlight
      </Button>
      <Button variant={type === "freeform" ? "primary" : undefined} className="flex-1" onClick={type !== "freeform" ? setFreeform : undefined}>
        <FontAwesomeIcon icon={faVectorPolygon} /> Freeform
      </Button>
      <Button variant={type === "sprite" ? "primary" : undefined} className="flex-1" onClick={type !== "sprite" ? setSprite : undefined}>
        <FontAwesomeIcon icon={faImage} /> Sprite
      </Button>
    </ButtonBar>
  );
}
import {MutableRef} from "common/ref";
import {Nullable} from "common/types/generic/nullable/index.ts";
import {BooleanOperation, booleanType, NumberOperation, numberType, ValueFn, ValueOperation} from "common/types/generic/index.ts";
import {useMemo} from "react";
import {pipe} from "common/pipe";
import {distinct, map} from "common/observable";
import {ApplyAction} from "common/types/apply.ts";
import {useObservable} from "#lib/qlab/index.ts";
import {Field, FieldLabel} from "#lib/components/panel-header.tsx";
import {InputGroup, InputNumber} from "#lib/components/index.ts";
import {InputCheckbox} from "#lib/components/input/input-checkbox.tsx";
import {InputGroupIcon} from "#lib/components/input/input-group-icon.tsx";

export function RepeatField({repeatXRef, repeatYRef}: {
  repeatXRef: MutableRef<Nullable<number>, ValueOperation<Nullable<number>, NumberOperation>[]>,
  repeatYRef: MutableRef<Nullable<number>, ValueOperation<Nullable<number>, NumberOperation>[]>
}) {
  return (<Field>
    <FieldLabel>Repeat</FieldLabel>
    <div className="flex flex-row gap-0.5 mx-2 rounded-md overflow-hidden">
      <InputGroup className="pr-0 flex-1">
        <InputGroupIcon>X</InputGroupIcon>
        <InputRepeat value={repeatXRef}/>
      </InputGroup>
      <InputGroup className="pr-0 flex-1">
        <InputGroupIcon>Y</InputGroupIcon>
        <InputRepeat value={repeatYRef}/>
      </InputGroup>
    </div>
  </Field>);
}

export function InputRepeat({value}: {
  value: MutableRef<Nullable<number>, ValueOperation<Nullable<number>, NumberOperation>[]>;
}) {
  const isInfinite = useMemo((): MutableRef<boolean, BooleanOperation[]> => {
    return new MutableRef<boolean, BooleanOperation[]>({
      value() {
        return value === null;
      },
      observe: pipe(value.observe, map(value => value === null), distinct()),
      apply: (fn: ApplyAction<boolean, BooleanOperation[]>) => value.apply((prev): ValueOperation<number | null, NumberOperation>[] => {
        const current = prev === null;
        const operations = fn(current);
        if (operations.length === 0) return [];
        const final = operations.reduce(booleanType.apply, current);
        if (current === final) return [];
        return ValueFn.set<number | null, NumberOperation>(prev, final ? null : 1);
      }).then(value => value === null)
    });
  }, [value]);

  const numberValue = useMemo((): MutableRef<number, NumberOperation[]> => {
    return new MutableRef({
      value() {
        const v = value.value;
        return v === null ? Number.POSITIVE_INFINITY : v;
      },
      observe: pipe(value.observe, map(value => value === null ? Number.POSITIVE_INFINITY : value), distinct()),
      apply: (fn: ApplyAction<number, NumberOperation[]>) => value.apply(prev => {
        if (prev !== null) {
          const operations = fn(prev);
          return ValueFn.apply(operations);
        } else {
          const operations = fn(1);
          const final = operations.reduce(numberType.apply, 1);
          return ValueFn.set(prev, final);
        }
      }).then(value => value === null ? Number.POSITIVE_INFINITY : value)
    })
  }, [value]);


  const isInfiniteValue = useObservable(isInfinite.observe, false, [isInfinite]);
  return (<>
      <InputNumber disabled={isInfiniteValue} value={numberValue} placeholder={isInfiniteValue ? "Infinite" : ""} />
      <InputCheckbox title="Infinite?" value={isInfinite} />
    </>)
}

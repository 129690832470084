import {z} from "zod";
import {Dnd5eVariableModifier, Dnd5eVariableModifierOperation, dnd5eVariableModifierType} from "../../../dnd-5e-modifier/dnd-5e-variable-modifier.ts";

import {MultiType, Type} from "../../../../../../../types/index.ts";
import {
  Dnd5eActionTemplateSegmentAbilityCheckConditionModifier,
  Dnd5eActionTemplateSegmentAbilityCheckConditionModifierOperation,
  dnd5eActionTemplateSegmentAbilityCheckConditionModifierType
} from "./dnd-5e-action-template-segment-ability-check-condition-modifier.ts";
import {generateDnd5eModifierID} from "../../../dnd-5e-modifier/dnd-5e-modifier-i-d.ts";

export const Dnd5eActionTemplateSegmentAbilityCheckModifier: z.ZodType<
  | {type: "variable", data: Dnd5eVariableModifier}
  | {type: "action::ability-check::condition", data: Dnd5eActionTemplateSegmentAbilityCheckConditionModifier}
> = z.lazy(() => z.discriminatedUnion("type", [
  z.object({type: z.literal("variable"), data: Dnd5eVariableModifier}),
  z.object({type: z.literal("action::ability-check::condition"), data: Dnd5eActionTemplateSegmentAbilityCheckConditionModifier}),
]));
export type Dnd5eActionTemplateSegmentAbilityCheckModifier = z.infer<typeof Dnd5eActionTemplateSegmentAbilityCheckModifier>;

export const Dnd5eActionTemplateSegmentAbilityCheckModifierOperation: z.ZodType<
  | {type: "variable", operations: Dnd5eVariableModifierOperation[]}
  | {type: "action::ability-check::condition", operations: Dnd5eActionTemplateSegmentAbilityCheckConditionModifierOperation[]}
> = z.lazy(() => z.discriminatedUnion("type", [
  z.object({type: z.literal("variable"), operations: z.array(Dnd5eVariableModifierOperation)}),
  z.object({type: z.literal("action::ability-check::condition"), operations: z.array(Dnd5eActionTemplateSegmentAbilityCheckConditionModifierOperation)}),
]));
export type Dnd5eActionTemplateSegmentAbilityCheckModifierOperation = z.infer<typeof Dnd5eActionTemplateSegmentAbilityCheckModifierOperation>;

export type Dnd5eActionTemplateSegmentAbilityCheckModifierTypes = {
  "variable": Type<Dnd5eVariableModifier, Dnd5eVariableModifierOperation>;
  "action::abilityCheck::condition": Type<Dnd5eActionTemplateSegmentAbilityCheckConditionModifier, Dnd5eActionTemplateSegmentAbilityCheckConditionModifierOperation>;
};

export const dnd5eActionTemplateSegmentAbilityCheckModifierType: Type<Dnd5eActionTemplateSegmentAbilityCheckModifier, Dnd5eActionTemplateSegmentAbilityCheckModifierOperation> = new MultiType<Dnd5eActionTemplateSegmentAbilityCheckModifierTypes>({
  "variable": dnd5eVariableModifierType,
  "action::abilityCheck::condition": dnd5eActionTemplateSegmentAbilityCheckConditionModifierType
});

export const Dnd5eActionTemplateSegmentAbilityCheckModifierFn = {
  copyDnd5eActionTemplateSegmentAbilityCheckModifier: (value: Dnd5eActionTemplateSegmentAbilityCheckModifier): Dnd5eActionTemplateSegmentAbilityCheckModifier => {
    switch (value.type) {
      case "variable": return {...value, data: {...value.data, modifierID: generateDnd5eModifierID()}};
      case "action::ability-check::condition": return {...value, data: {...value.data, modifierID: generateDnd5eModifierID(), modifiers: value.data.modifiers.map(Dnd5eActionTemplateSegmentAbilityCheckModifierFn.copyDnd5eActionTemplateSegmentAbilityCheckModifier)}};
    }
  }
};
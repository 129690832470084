import {NodeId} from "common/legends/index.ts";
import {useMemo} from "react";
import {pipe} from "common/pipe";
import {combine, distinct, from, map} from "common/observable";
import {useObservable} from "#lib/qlab/index.ts";
import {twMerge} from "tailwind-merge";
import {FaQuestion} from "react-icons/fa6";
import {useNode} from "../../../../viewport/token/use-node.ts";
import {useNodeIcon} from "../../../../common/use-get-node-icon.ts";
import {useIsGameMaster} from "../../../../common/game/use-is-game-master.ts";
import {useGoToNode} from "../../../nav/editor/use-go-to-node.ts";

export type UserNodeIconProps = {
  nodeID?: NodeId;
};

export function NodeIcon({nodeID}: UserNodeIconProps) {
  const node = useNode(nodeID);
  const nodeIcon = useNodeIcon(useMemo(() => from(nodeID), [nodeID]));
  const resolvedNodeIcon = useObservable(nodeIcon, undefined, [nodeIcon]);
  const isGameMaster = useIsGameMaster();
  const name = useObservable(pipe(
    combine(node.observe),
    map(([node]) => {
      if (isGameMaster) {
        return node?.data.name;
      } else if (node?.type === "token") {
        if (node.data.ownerIDs.length > 0) {
          return node.data.name;
        }
      }
      return undefined;
    }),
    distinct()
  ), undefined, [node.observe, isGameMaster]) ?? "???";

  const goToNode = useGoToNode();
  return (<div className="flex items-center justify-center">
    <div className={twMerge(
      "rounded-full bg-zinc-900 w-8 h-8 inline-flex items-center justify-center pointer-events-auto",
      nodeID && "cursor-pointer"
    )} title={name} onClick={() => {if (nodeID) goToNode(nodeID);}}>
      {resolvedNodeIcon && <img alt={name} src={resolvedNodeIcon} crossOrigin="anonymous" className="rounded-full w-8 h-8 ring-1 ring-zinc-900" />}
      {!resolvedNodeIcon && <FaQuestion size={20} />}
    </div>
  </div>);
}
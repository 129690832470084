import {z} from "zod";
import {Dnd5eModifierID} from "../../../dnd-5e-modifier/dnd-5e-modifier-i-d.ts";
import {DiceExpression} from "../../../../../../../dice/index.ts";
import {ConstantOperation, constantType, ObjectType, Optional, Type, ValueOperation, ValuePropertyRef, ValueType} from "../../../../../../../types/index.ts";
import {MutableRef} from "#common/ref";
import {Dnd5eActionCondition} from "../../condition/dnd-5e-action-condition.ts";

export const Dnd5eActionTemplateSegmentAttackRollHitModifier: z.ZodType<{
  modifierID: string,
  condition?: Optional<Dnd5eActionCondition>,
  expression: string
}> = z.object({
  modifierID: Dnd5eModifierID,
  condition: z.optional(Dnd5eActionCondition),
  expression: DiceExpression
});
export type Dnd5eActionTemplateSegmentAttackRollHitModifier = z.infer<typeof Dnd5eActionTemplateSegmentAttackRollHitModifier>;
export const Dnd5eActionTemplateSegmentAttackRollHitModifierOperation = z.discriminatedUnion("type", [
  z.object({type: z.literal("update-modifier-i-d"), operations: z.array(ConstantOperation)}),
  z.object({type: z.literal("update-condition"), operations: z.array(ValueOperation(z.optional(Dnd5eActionCondition), ConstantOperation))}),
  z.object({type: z.literal("update-expression"), operations: z.array(ValueOperation(DiceExpression, ConstantOperation))})
]);
export type Dnd5eActionTemplateSegmentAttackRollHitModifierOperation = z.infer<typeof Dnd5eActionTemplateSegmentAttackRollHitModifierOperation>;
export const dnd5eActionTemplateSegmentAttackRollHitModifierType: Type<Dnd5eActionTemplateSegmentAttackRollHitModifier, Dnd5eActionTemplateSegmentAttackRollHitModifierOperation> = new ObjectType({
  modifierID: constantType,
  condition: new ValueType(constantType),
  expression: new ValueType(constantType)
});

export function Dnd5eActionTemplateSegmentAttackRollHitModifierSignals(valueRef: MutableRef<Dnd5eActionTemplateSegmentAttackRollHitModifier, Dnd5eActionTemplateSegmentAttackRollHitModifierOperation[]>) {
  return {
    expressionRef: ValuePropertyRef<Dnd5eActionTemplateSegmentAttackRollHitModifier, Dnd5eActionTemplateSegmentAttackRollHitModifierOperation, DiceExpression, ConstantOperation>(
      value => DiceExpression.parse(value.expression),
      operations => [{type: "update-expression", operations}]
    )(valueRef)
  }
}
import {LegendsIcon} from "../../../../legends-icon.tsx";
import {twMerge} from "tailwind-merge";
import React from "react";
import {useConnected, useHasOutstandingChangesets} from "#lib/qlab/index.ts";

export function LegendsConnectionIndicator() {
  const connected = useConnected();
  const hasOutstandingChangesets = useHasOutstandingChangesets();
  return <LegendsIcon className={twMerge(
    "w-12 h-12 p-1",
    "text-white",
    hasOutstandingChangesets ? "text-yellow-300" : "",
    !connected ? "text-red-300" : ""
  )} />;
}
import {z} from "zod";
import {
  Dnd5eActionTemplateSegmentSavingThrowModifier,
  Dnd5eActionTemplateSegmentSavingThrowModifierOperation,
  dnd5eActionTemplateSegmentSavingThrowModifierType
} from "./dnd-5e-action-template-segment-saving-throw-modifier.ts";
import {
  ConstantOperation,
  constantType,
  ListOperation, ListPropertyRef,
  ListType,
  ObjectType,
  PropertyRef,
  Type,
  ValueOperation, ValuePropertyRef,
  ValueType,
  ZodListOperation
} from "../../../../../../../types/index.ts";
import {Dnd5eActionCondition} from "../../condition/dnd-5e-action-condition.ts";
import {Dnd5eModifierID} from "../../../dnd-5e-modifier/dnd-5e-modifier-i-d.ts";
import {MutableRef} from "#common/ref";

export const Dnd5eActionTemplateSegmentSavingThrowConditionModifier: z.ZodType<{
  modifierID: string,
  condition: Dnd5eActionCondition,
  modifiers: Dnd5eActionTemplateSegmentSavingThrowModifier[]
}> = z.lazy(() => z.object({
  modifierID: Dnd5eModifierID,
  condition: Dnd5eActionCondition,
  modifiers: z.array(Dnd5eActionTemplateSegmentSavingThrowModifier)
}));
export type Dnd5eActionTemplateSegmentSavingThrowConditionModifier = z.infer<typeof Dnd5eActionTemplateSegmentSavingThrowConditionModifier>;

export const Dnd5eActionTemplateSegmentSavingThrowConditionModifierOperation: z.ZodType<
  | {type: "update-modifier-i-d", operations: ConstantOperation[]}
  | {type: "update-condition", operations: ValueOperation<Dnd5eActionCondition, ConstantOperation>[]}
  | {type: "update-modifiers", operations: ListOperation<Dnd5eActionTemplateSegmentSavingThrowModifier, Dnd5eActionTemplateSegmentSavingThrowModifierOperation>[]}
> = z.lazy(() => z.discriminatedUnion("type", [
  z.object({type: z.literal("update-modifier-i-d"), operations: z.array(ConstantOperation)}),
  z.object({type: z.literal("update-condition"), operations: z.array(ValueOperation(Dnd5eActionCondition, ConstantOperation))}),
  z.object({type: z.literal("update-modifiers"), operations: z.array(ZodListOperation(Dnd5eActionTemplateSegmentSavingThrowModifier, Dnd5eActionTemplateSegmentSavingThrowModifierOperation))})
]));
export type Dnd5eActionTemplateSegmentSavingThrowConditionModifierOperation = z.infer<typeof Dnd5eActionTemplateSegmentSavingThrowConditionModifierOperation>;

export const dnd5eActionTemplateSegmentSavingThrowConditionModifierType: Type<Dnd5eActionTemplateSegmentSavingThrowConditionModifier, Dnd5eActionTemplateSegmentSavingThrowConditionModifierOperation> = new ObjectType(() => ({
  modifierID: constantType,
  condition: new ValueType(constantType),
  modifiers: new ListType(dnd5eActionTemplateSegmentSavingThrowModifierType)
}));

export function Dnd5eActionTemplateSegmentSavingThrowConditionModifierSignals(valueRef: MutableRef<Dnd5eActionTemplateSegmentSavingThrowConditionModifier, Dnd5eActionTemplateSegmentSavingThrowConditionModifierOperation[]>) {
  return {
    conditionRef: ValuePropertyRef<Dnd5eActionTemplateSegmentSavingThrowConditionModifier, Dnd5eActionTemplateSegmentSavingThrowConditionModifierOperation, Dnd5eActionCondition, ConstantOperation>(
      value => value.condition,
      operations => [{type: "update-condition", operations}]
    )(valueRef),
    modifiersRef: ListPropertyRef<Dnd5eActionTemplateSegmentSavingThrowConditionModifier, Dnd5eActionTemplateSegmentSavingThrowConditionModifierOperation, Dnd5eActionTemplateSegmentSavingThrowModifier, Dnd5eActionTemplateSegmentSavingThrowModifierOperation>(
      value => value.modifiers,
      operations => [{type: "update-modifiers", operations}]
    )(valueRef)
  };
}
import {useCallback} from "react";
import {Node, NodeId, NodeOperation} from "common/legends/index.ts";
import {ApplyAction, useInstance} from "#lib/qlab/index.ts";
import {Transform, Tree, TreeOperation, TreePath} from "common/types/index.ts";
import {useGetNodePath} from "./use-get-node-path.ts";
import {useStoreID} from "../../../routes/game/model/store-context.tsx";

export function useAttachNode() {
  const gameID = useStoreID();
  const instance = useInstance();
  const getNodePath = useGetNodePath();
  return useCallback(async (parentNodeID: NodeId, childNodeID: NodeId) => {
    const parentLocation = getNodePath(parentNodeID);
    const childLocation = getNodePath(childNodeID);

    if (parentLocation?.type === "scene" && childLocation?.type === "scene" && parentLocation.sceneID === childLocation.sceneID) {
      const applyToNodes = (fn: ApplyAction<Node[], TreeOperation<Node, NodeOperation>[]>) => instance.applyToResource(gameID, parentLocation.sceneID, prev => {
        if (prev?.type !== "scene") return [];

        return [{type: "apply", operations: [{type: "scene", operations: [{
              type: "update-children",
              operations: typeof fn === "function" ? fn(prev.data.children) : fn
            }]}]}];
      });

      return applyToNodes(prev => {
        const fromPath = Tree.getPath(prev, node => node.data.id === childNodeID);
        if (fromPath === undefined) return [];

        const parentPath = Tree.getPath(prev, node => node.data.id === parentNodeID);
        if (parentPath === undefined) return [];
        const parentNode = Tree.getNode(prev, parentPath);
        const toPath = [...parentPath, parentNode.data.children.length];

        // No Circular Attachments!
        if (TreePath.isAncestor(fromPath, toPath)
          || TreePath.equals(fromPath, toPath)) {
          return [];
        }

        const node = Tree.getNode(prev, fromPath);
        const oldTransform = node.data.transform;
        let rootTransform = fromPath.slice(0, fromPath.length-1).map((_, i) => fromPath.slice(0, i+1))
          .map(path => Tree.getNode(prev, path))
          .reverse()
          .reduce((a, b) => b.type !== "parallax" ? Transform.divide(a, b.data.transform) : a, oldTransform);
        let newTransform = toPath.slice(0, toPath.length - 1).map((_, i) => toPath.slice(0, i+1))
          .map(path => Tree.getNode(prev, path))
          .reduce((a, b) => (b.type !== "parallax") ? Transform.multiply(a, b.data.transform) : a, rootTransform);
        return [{
          type: "apply", path: fromPath, operations: [{type: node.type, operations: [{
              type: "update-transform",
              operations: [{type: "set", prevValue: oldTransform, nextValue: newTransform}]
            }]}]
        }, {type: "move", fromPath, toPath}];
      });
    }
  }, [instance, getNodePath]);
}




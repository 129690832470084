import {z} from "zod";
import {AccessMask, AccessMaskFn} from "../../../visibility/index.ts";
import {Node, NodeID} from "../../../node/index.ts";
import {listIdentity} from "#common/observable";
import {TokenVisionLimit, TokenVisionLimitFn} from "../token-vision-limit.ts";
import {Point, PointFn} from "../../../../types/index.ts";

export const Vision = z.object({
  offset: Point,
  accessMask: AccessMask,
  accessibleNodeIDs: z.array(NodeID),
  visibilityNodeIDs: z.array(NodeID),
  grayscale: z.boolean(),
  limit: z.optional(TokenVisionLimit)
});
export type Vision = z.infer<typeof Vision>;

export const VisionFn = {
  canAccess: (vision: Vision, node: Node): boolean => {
    return vision.accessibleNodeIDs.includes(node.data.id);
  },
  canSee: (vision: Vision, node: Node): boolean => {
    return AccessMaskFn.canSee(vision.accessMask, node.data.visibilityLayer) || vision.visibilityNodeIDs.includes(node.data.id);
  },
  equals: (a: Vision, b: Vision): boolean => {
    return (
      a.accessMask === b.accessMask &&
      a.grayscale === b.grayscale &&
      PointFn.equals(a.offset, b.offset) &&
      listIdentity(a.accessibleNodeIDs, b.accessibleNodeIDs) &&
      listIdentity(a.visibilityNodeIDs, b.visibilityNodeIDs) &&
      TokenVisionLimitFn.equals(a.limit, b.limit)
    );
  }
};
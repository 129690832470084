import {NodeId, UserID} from "common/legends/index.ts";
import {twMerge} from "tailwind-merge";
import {NodeIcon} from "../../../common/node-icon.tsx";

export type CharacterSelectionPanelProps = {
  userID: UserID;
  values: NodeId[];
  selected?: NodeId;
  onChange: (value: NodeId) => void;
};

export function CharacterSelectionPanel({values, selected, userID, onChange}: CharacterSelectionPanelProps) {
  return (<div className="flex flex-row gap-1">
    {values.map((value) => <NodeIcon key={value} userID={userID} nodeID={value} className={twMerge(
      "w-8 h-8",
      selected !== value && "opacity-50"
    )} size={16} onClick={() => onChange(value)} />)}
  </div>)
}